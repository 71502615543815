import React, { useState, useEffect } from 'react'
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Select from '../../../components/Filters/SingleSelect';
import ToggleComponent from '../../../components/Toggle/ToggleComponent';
import * as Notify from '../../../components/Notification/Notifications'
import moment from "moment";
import "../ProductProfile.css"
import { connect } from 'react-redux';
import { fetchFilterData, resetFiltersOptions } from '../ProductProfileAction';
import { PROFILE_PAGE } from '../../../constants/routeConstants';
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping"
import { handleSelectAll } from '../../../utils/SelectAllHandler/SelectAllHander';
import { intersectionWith, isEqual } from 'lodash';
import MultiSelect from '../../../components/Filters/MultiSelect';

function LevelFilters({ level1Options, periodOptions, ...props }) {

  const [min_date, setStartDate] = useState(null);
  const [max_date, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isDynamic, setisDynamic] = useState(true)
  const [profileName, setProfileName] = useState('')
  const [profileDescription, setProfileDescription] = useState('')
  const [level1, setLevel1] = useState(null)
  const [level2, setLevel2] = useState(null)
  const [days, setPeriod] = useState(null)
  const [showButton, setShowButton] = useState(true)
  const [static_dynamic, setstaticDynamic] = useState('Dynamic')
  const [isEditing, setIsEditing] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [primaryToggleText, setPrimaryToggleText] = useState('Static')
  const [secondaryToggleText, setSecondaryToggleText] = useState('Dynamic')
  const [disableFilters, setDisableFilters] = useState({ level1: false, level2: false })
  const [level2Options, setLevel2Options] = useState([])

  useEffect(() => {
    props.fetchFilterData({}, { key: 'l1' });
  }, []);

  useEffect(() => {
    setLevel2Options(props.level2Options)
  }, [props.level2Options])

  useEffect(() => {
    if (Object.keys(props.existingData).length) {
      let existingData = props.existingData
      let savedData = props.existingData?.savedData
      setProfileName(existingData?.name)
      setProfileDescription(existingData?.description)
      setLevel1(existingData?.savedData?.level1)
      setLevel2(savedData?.level2)
      setLevel2Options(savedData?.level2Options)
      setDisableFilters({ level1: true, level2: true })
      if (savedData?.static_dynamic === "Static") {
        setisDynamic(false)
        setStartDate(moment(savedData?.min_date))
        setEndDate(moment(savedData?.max_date))
        setPrimaryToggleText('Dynamic')
        setSecondaryToggleText('Static')
        setstaticDynamic('Static')
      }
      else {
        setisDynamic(true)
        setPeriod(savedData?.days)
        setPrimaryToggleText('Static')
        setSecondaryToggleText('Dynamic')
      }
      setIsEdit(true)
    }
  }, [props.existingData])

  const prepareReq = () => {
    if (level1 && level2 && profileName && profileDescription && (days || (min_date && max_date))) {
      setShowButton(false)
      setIsEditing(false)
      props.prepareReq({
        profileName,
        profileDescription,
        level1: level1?.filter(val => val.value != "*"),
        level2: level2?.filter(val => val.value != "*"),
        days,
        min_date: min_date ? moment(min_date).format('YYYY-MM-DD') : null,
        max_date: max_date ? moment(max_date).format('YYYY-MM-DD') : null,
        static_dynamic,
      }, 'levelFilter')
    }
    else {
      Notify.error("Please Select Mandatory Option!!");
    }
  }

  const resetRestFilters = (p_index) => {
    if (p_index == 0) {
      level2 && setLevel2(null);
    }
    if (p_index == 0 || p_index == 1) {
      days && setPeriod(null);
    }
  }

  const onPeriodChange = (val) => {
    // if(isEdit){
    setIsEditing(true)
    props.onChanged('levelFilter')
    // }
    setStartDate(null);
    setEndDate(null);
    setstaticDynamic('Dynamic')
    setPeriod(val)
  }

  useEffect(() => {
    if (props.isEdit) {
      setIsEditing(false)
    }
  }, [props.isEdit])

  const onDepartmentChange = (val, event, that) => {
    // if(isEdit){
    setIsEditing(true)
    let valuesArray = handleSelectAll(val, event, that)
    setLevel1(valuesArray[1]);
    let req_val = intersectionWith(valuesArray[0], level1Options, isEqual);
    console.log('ss1', req_val)
    props.onChanged('levelFilter')
    // }
    // setLevel1(val)
    resetRestFilters(0)
    props.fetchFilterData({ level1: req_val }, { key: 'l2' })
  }

  const onGenderChange = (val, event, that) => {
    setIsEditing(true)
    let valuesArray = handleSelectAll(val, event, that)
    setLevel2(valuesArray[1]);
    let req_val = intersectionWith(valuesArray[0], level2Options, isEqual);
    // setDepartment(valuesArray[0]);
    resetRestFilters(1)
    props.fetchFilterData({ level1: level1, level2: req_val }, { key: 'l3' })
    //  setIsEditing(true)
    //  props.onChanged('levelFilter')

    //  setLevel2(val)
    //  resetRestFilters(1)
    //  //API call to get next filter
    //  props.fetchFilterData({level1:[level1],level2:[val]},{key:'l3'})

  }

  const profileNameChangeHandler = (val) => {
    //  if(isEdit){
    props.onProfileNameChange(val)
    setIsEditing(true)
    props.onChanged('levelFilter')
    //  }
    setProfileName(val)
  }

  const profileDescriptionChangeHanlder = (val) => {
    // if(isEdit){
    props.onProfileDescriptionChange(val)
    setIsEditing(true)
    props.onChanged('levelFilter')
    // }
    setProfileDescription(val)
  }

  const setDates = (p_startDate, p_endDate, p_static) => {
    // if(isEdit){
    setIsEditing(true)
    props.onChanged('levelFilter')
    // }
    setStartDate(p_startDate);
    setEndDate(p_endDate);
    setstaticDynamic(p_static);
    setPeriod(null)
  }

  const isOutsideRange = day => day.isAfter(moment());

  useEffect(() => {
    if (Object.keys(props.selectedFilters).length) {
      let filterObj = {
        level1: true,
        level2: false
      }
      if (props.selectedFilters?.level1) {
        setLevel1(props.selectedFilters?.level1)
        props.fetchFilterData({ level1: [props.selectedFilters?.level1] }, { key: 'l2' })
      }
      if (props.selectedFilters?.level2) {
        setLevel2(props.selectedFilters?.level2)
        filterObj.level2 = true
      }
      setDisableFilters(filterObj)
    }
  }, [props.selectedFilters])

  return (
    <React.Fragment>
      <Notify.Notification />
      <div className="container__header">
        <h1 className="fnt-lg fnt-bold">
          {isEdit ? "Edit Product Profile" : "Create Product Profile"}
        </h1>
      </div>
      <div className="container__body">
        <div className="top__filters">
          <div className="row">
            <div className="ml-3 mr-2 p-2 required">
              <label className="fnt-md fnt-bold fnt-bold">Profile Name</label>
              <input
                className="form-input form-control w-auto"
                type={"text"}
                onChange={(e) => profileNameChangeHandler(e.target.value)}
                value={profileName}
                placeholder="Profile Name"
              />
            </div>
            <div className="ml-2 mr-2 p-2 required">
              <label className="fnt-md fnt-bold fnt-bold">
                Profile Description
              </label>
              <input
                className="form-input form-control w-auto"
                type={"text"}
                onChange={(e) =>
                  profileDescriptionChangeHanlder(e.target.value)
                }
                value={profileDescription}
                placeholder="Profile Description"
              />
            </div>
            <div className="row section">
              <div className="required fnt-md pl-4" style={{ color: "red" }}>
                <label></label>Fields are mandatory
              </div>
            </div>
          </div>
          <hr />
          <div className="row section">
            <div className="col-md-3 required">
              <label className="fnt-md fnt-bold fnt-bold">{props.filterlabels?.level1}</label>
              <MultiSelect
                isDisabled={disableFilters.level1}
                name="level1"
                dropdownValue={level1}
                onDropDownChange={onDepartmentChange}
                placeHolder={`Select ${props.filterlabels?.level1}`}
                optionsArray={level1Options.length ? level1Options : []}
              />
            </div>
            <div className="col-md-3 required">
              <label className="fnt-md fnt-bold fnt-bold ">{props.filterlabels?.level2}</label>
              <MultiSelect
                isDisabled={disableFilters.level2}
                name="level2"
                dropdownValue={level2}
                onDropDownChange={onGenderChange}
                placeHolder={`Select ${props.filterlabels?.level2}`}
                optionsArray={level2Options?.length ? level2Options : []}
              />
            </div>
            <div className="col-md-3 required">
              <label className="fnt-md fnt-bold fnt-bold ">Period</label>
              {!isDynamic ? (
                <DateRangePicker
                  startDate={min_date}
                  startDateId="s_id"
                  endDate={max_date}
                  endDateId="e_id"
                  onDatesChange={({ startDate, endDate }) => {
                    setDates(startDate, endDate, "Static");
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(e) => setFocusedInput(e)}
                  displayFormat="MM-DD-YYYY"
                  isOutsideRange={isOutsideRange}
                  showClearDates
                  withPortal
                  numberOfMonths={3}
                />
              ) : (
                <Select
                  name="days"
                  value={days}
                  onChange={onPeriodChange}
                  placeHolder={"Select Period"}
                  options={periodOptions.length ? periodOptions : []}
                />
              )}
            </div>
            <div className="col-md-3 profile__toggle">
              <ToggleComponent
                primaryToggleText={primaryToggleText}
                secondaryToggleText={secondaryToggleText}
                // primaryToggleState={!isDefaultDynamic}
                onToggle={() => setisDynamic((old) => !old)}
              />
            </div>
          </div>
        </div>
      </div>
      {(showButton && !isEdit) || isEditing ? (
        <div
          style={{ margin: "auto", marginTop: "3rem" }}
          className="create__edit_product_profile_proceed__button"
        >
          <button
            onClick={prepareReq}
            className="btn btn-primary"
            title="Proceed"
          >
            Proceed
          </button>
          <button
            onClick={() => props.selectedFilters?.level1 ? props.goBackToCreateConfig() : props.history.push(`${PROFILE_PAGE}`)}
            className="btn btn-primary"
            title="Back"
          >
            Back
          </button>
        </div>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = ({ productProfile }) => ({
  level1Options: productProfile.level1Options,
  level2Options: productProfile.level2Options,
  periodOptions: productProfile.periodOptions,
  filterlabels: productProfile.filterlabels
});

const mapDispatchToProps = (dispatch) => ({
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  fetchFilterData: (payload, filterType) => dispatch(fetchFilterData(payload, filterType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LevelFilters)
