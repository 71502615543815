import React, { useEffect } from "react"
import { useHistory } from "react-router";
import { connect } from "react-redux";
import PageLoader from "../../../../components/Loader/PageLoader";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import EditIconCell from '../../../../components/Table/Cellrenderer/EditIconCell';
import NumberRangeColumnFilter from "../../../../components/Filters/NumberRangeColumnFilter";
import { getNewStoreList, resetReservedData } from "../../actions/DemandAction/DemandAction"
import { HEADER, TABLE_HEADERS } from "./NewStoreListConstants"
import { PRODUCT_STORE_STATUS } from "../../../../constants/routeConstants";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const NewStoreList = (props) => {
    const history = useHistory()
    const { getNewStoreList, resetReservedData, data, dataLoading, dataError } = props

    const columns = [
        {
            sticky: "left",
            Header: TABLE_HEADERS.PLAN_NAME,
            accessor: 'allocation_name',
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: TABLE_HEADERS.CREATION_DATE,
            accessor: 'created_on',
            Filter: ColumnFilter,
        },
        {
            Header: TABLE_HEADERS.STYLE_COUNT,
            accessor: 'style_color_cnt',
            Filter: NumberRangeColumnFilter,
        },
        {
            Header: TABLE_HEADERS.STORE_NAME,
            accessor: 'store_name',
            Filter: ColumnFilter
        },
        {
            Header: TABLE_HEADERS.ESTIMATED_ALLOCATION,
            accessor: 'allocated_units',
            Filter: NumberRangeColumnFilter
        },
        {
            Header: TABLE_HEADERS.OPENING_DATE,
            accessor: 'opening_date',
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: "Actions",
            accessor: "actions",
            sticky: "right",
            Cell: (instance) => (
                <div className="row m-0">
                    <EditIconCell
                        handleEdit={handleEdit}
                        instance={instance}
                    ></EditIconCell>
                    <button
                        className={"btn btn-outline-secondary btn-sm px-2 mr-4 rounded-lg text-body"}
                        onClick={() => { release(instance?.row?.original) }}
                    >
                        Release
                        <i
                            className="fa fa-angle-right ml-2"
                            title="Configure"
                            aria-hidden="true"
                        ></i>
                    </button>
                </div>
            ),
            disableFilters: true,
            resizable: true,
            width: 200
        }
    ]

    useEffect(() => {
        getNewStoreList({
            "level1": [
                "Apparel"
            ],
            "level2": [
                "Infant"
            ],
            "storesFilter": "Allocation Required - New In DC",
            "dc": [
                9
            ],
            "new_store": true
        })
    }, [])

    useEffect(() => {
        return () => {
            resetReservedData()
        }
    }, [])

    const handleEdit = (instance) => {
        let storeDetails = {
            allocation_id: instance.allocation_id,
            allocation_name: instance.allocation_name,
            store_code: instance.store_id,
            sister_store_code: instance.sister_store_id,
            opening_date: instance.opening_date,
            is_edit: true
        }
        history.push(`${PRODUCT_STORE_STATUS}`, { storeDetails })
    }

    const release = (instance) => {
        let storeDetails = {
            isRelease: true,
            allocation_id: instance.allocation_id,
            sister_store_code: instance.sister_store_id,

        }
        history.push(`${PRODUCT_STORE_STATUS}`, {storeDetails} )
    }

    return (
        <div className="container__wrapper">
            <div className="container__header m-0">
                <h3 className="fnt-bold">{HEADER}</h3>
            </div>
            <section className="section mt-4 m-0">
                <PageLoader loader={dataLoading} gridLoader={true}>
                    {/* {(dataError) ? (<div className="error">{dataError}</div>) : */}
                    {/* (data ? ( */}
                    <ReactTableWithPlugins
                        shouldPagination
                        data={(data ? data : [])}
                        columns={columns}
                        renderMarkup='TableMarkup'
                        keyRT="sortAndSearch"
                        tableId="newstore_reserved"
                    />
                    {/* ) : null)} */}
                </PageLoader>
            </section>
        </div>
    )
}

const mapStateToProps = ({ demandConstraints }) => ({
    data: demandConstraints.newStoreData,
    dataLoading: demandConstraints.newStoreDataLoading,
    dataError: demandConstraints.newStoreDataError
});

const mapDispatchToProps = (dispatch) => ({
    resetReservedData: () => dispatch(resetReservedData()),
    getNewStoreList: (payload) => dispatch(getNewStoreList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewStoreList);