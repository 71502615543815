import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import {
  FETCH_FILTER_DATA,
  fetchFilterDataSuccesss,
  fetchFilterDataError,
  FETCH_USER_CREATED_DATA,
  fetchUserCreatedDataSuccess,
  fetchUserCreatedDataError,
  FETCH_STORES_DATA,
  fetchStoresDataSuccess,
  fetchStoresDataError,
  fetchCreateFilterData,
  FETCH_CREATEFILTER_DATA,
  fetchCreateFilterSuccesss,
  fetchCreateFilterError,
  FETCH_PRODUCT_LIST_CONTRIBUTION_DATA,
  fetchProductListContributionSuccesss,
  fetchProductListContributionError,
  FETCH_PROFILED_PRODUCT_DATA,
  fetchProfiledProductSuccess,
  fetchProfiledProductError,
  SAVE_PRODUCT_PROFILE_DATA,
  saveProductProfileSuccess,
  saveProductProfileError,
  FETCH_SAVED_DATA,
  fetchSavedDataSuccess,
  fetchSavedDataError,
  DELETE_PRODUCT_PROFILE,
  deleteProductProfileSuccess,
  deleteProductProfileError
} from "./ProductProfileAction";

import {
  getProductProfileFilterData,
  getProductProfileData,
  getStoresData,
  getProductProfileCreateFilterData,
  getProductListContributionData,
  getProfiledProductData,
  saveProductProfile,
  getSavedData,
  deleteProductProile,
} from "../../routes/api";

import { isEmpty, map } from "lodash";
import React from 'react';
import ColumnFilter from "../UserManagement/ColumnFilter";
import { dateFormat } from "../../utils/commonUtilities";
import moment from "moment";


function* fetchStoresDataWorker(action) {
  let req_body = action.payload
  
  try {
    const response = yield call(getStoresData,req_body)

    let l_sizeArr = response?.data?.sizeArr.reduce((a,b)=> (a[b]='-',a),{});
    let l_responseWithAllSizeArr = response?.data?.storeData.map(val => {
      return{
            ...val,
            'size_data':{...l_sizeArr,...val.size_data}
          }
      })

    let func = (p_val) => {
      for (let i in p_val) {
        p_val[i] = Number(p_val[i])?(Number(p_val[i])*100).toFixed(1)+" %":"-"
      }
      return p_val
    }

    let resposneWithPercentage = l_responseWithAllSizeArr.map(val => {
      return {
        ...val,
        overall: Number(val.overall)?(Number(val.overall)*100).toFixed(1)+" %":"-",
        size_data:func(val.size_data)
      }
    })


    let responseWithSizeData = resposneWithPercentage?.map(val => {
      return {
        ...val,
        ...val.size_data
      }
    })


    let userCreatedProfileTabsColumns = []

    userCreatedProfileTabsColumns.push({
      Header:'Store ID',
      accessor:'store_code',
      Filter:(instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
      filter: 'bulkFilterSpaceSeperated',
    },
    {
      Header:'Overall',
      accessor:'overall',
      Filter: ColumnFilter
    })

      for (let val of response?.data?.sizeArr) {
        userCreatedProfileTabsColumns.push({
          Header: val,
          accessor: (data) => data[val],
          Filter: ColumnFilter
        })
      }


    let resp = {
      data: {'storeData': responseWithSizeData, 'articleData': response?.data?.articleData, 'column': userCreatedProfileTabsColumns,},
      status: response?.data?.status,
      error: response?.data?.error
    }


    if (response?.data?.status) {
      yield put(fetchStoresDataSuccess({ data: resp.data }));
    }
    else{
      yield put(fetchStoresDataError({ error: resp.error }));
    }

  } catch (error) {
      yield put(fetchStoresDataError(error));
  }
}

function* fetchUserCreatedDataWorker(action) {
  let l_payload = action.payload
  let arrayProperty = {};
  for (var key in l_payload) {
    if(Array.isArray(l_payload[key])){
      arrayProperty[key] = l_payload[key].map((ele) => ele.value)
    }
  }
    let req_body = {
       "level1":[l_payload?.level1?.value],
    }

    if(l_payload?.page){
      req_body["page"] = l_payload?.page
    }

    if(l_payload?.level2?.value){
      req_body["level2"] = [l_payload?.level2?.value]
    }

    if(arrayProperty['level3']?.length > 0) {
      req_body["level3"] = arrayProperty['level3']
    }

    if(arrayProperty['level4']?.length > 0) {
      req_body["level4"] = arrayProperty['level4']
    }

    if(arrayProperty['level5']?.length > 0) {
      req_body["level5"] = arrayProperty['level5']
    }

    if(arrayProperty['colour']?.length > 0) {
      req_body["color_description"] = arrayProperty['colour']
    }

    if(arrayProperty['style_name']?.length > 0) {
      req_body["style_name"] = arrayProperty['style_name']
    }

  try {
    const response = yield call(getProductProfileData,req_body)

    let getSalesAttribute = val => {
      let l_salesattribute = ""
      if(Number(val?.clearance)){
        l_salesattribute+='Clearance, '
      }
      if(Number(val?.regular)){
        l_salesattribute+='Regular, '
      }
      if(Number(val?.promo)){
        l_salesattribute+='Promo'
      }
      return l_salesattribute
    }

    let responseWithSalesAttribute
    
    if(!l_payload?.page){
       responseWithSalesAttribute = response.data.data.map(val => {
        let timeStamp = moment.utc(val.updated_at)
        return {
          ...val,
          'sales_attribute': getSalesAttribute(val),
          'l3_name': (val.l3_name.replace("{","").replace("}","").replaceAll('"'," ")),
          'l4_name': (val.l4_name.replace("{","").replace("}","").replaceAll('"'," ")),
          'creation_date': val.updated_at ? dateFormat(val.updated_at) : '',
          'creation_time': val.updated_at ? timeStamp.format('hh:mm:ss') : ''
        }
      })
    }
    else{
      responseWithSalesAttribute = response.data.data
    }

    let resp = {
      data: responseWithSalesAttribute,
      status: {...response.status},
      error: {...response.error}
    }

    if (response.status) {
      yield put(fetchUserCreatedDataSuccess({ data: resp.data }));
    }
    else{
      yield put(fetchUserCreatedDataError({ error: resp.error }));
    }

  } catch (error) {
      yield put(fetchUserCreatedDataError(error));
  }
}

function* fetchFilterDataWorker(action) {
  try {
    const { payload,filterType } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        if(!isEmpty(payload?.[key]?.[0])) {
            req[key] = payload[key].map((ele) => ele.value)
        }
      }
    }
    if(filterType){
      req["filter_type"] = filterType;
  }
    const res = yield call(getProductProfileFilterData, req);
    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data?.filterMapping
      // let topObject = Object.keys(res.data.data[0])[0]
      // if(filterType != "style"){
        for (const key in res.data.data[0]) {
        let k = "";
          if (key === "level1") {
            k = "level1Options";
          } else if (key === "level2") {
            k = "level2Options";
          } else if (key === "level3") {
            k = "level3Options";
          } else if (key === "level4") {
            k = "level4Options";
          } else if (key === "level5") {
            k = "level5Options";
          } 
          else if(key === "style_name") {
            k = "styleOptions";
          } 
          else if(key === "color") {
              k = "colourOptions";
          }
          if(key==="color" || key === "style_name") {
              data[k] = res.data.data.map((element) => ({
                  value: element[key],
                  label: element[key],
                }));
          }
          else{
              data[k] = res.data.data[0][key].filter(element => element).map((element) => ({
                  value: element,
                  label: element,
                }));
          }
        }     
      // }
      // else{
      //     data["styleOptions"] = res.data.data
      // }
      yield put(fetchFilterDataSuccesss({ data: data, key: action?.filterKey?.key  }));
    }
    else {
      yield put(fetchFilterDataError({ error: res.error}));
    }
  } catch (error) {
    console.log(error,'sdcds')
    yield put(fetchFilterDataError({ error: "Something went wrong!" }));
  }
}

function* fetchCreateFilterDataWorker(action) {
  try {
    const { payload } = action;
    let req = {};
    if (!isEmpty(payload)) {
        for (const key in payload) {
                req[key] = payload[key]?.map((ele) => ele && ele.value)
            
        }
    }
    const res = yield call(getProductProfileCreateFilterData, req);
    if (res.data.status) { 
      let data = {};
      for(let i in res.data) {
        if(i === "colorFilterData" || i === "launchDateFilterData"){
        let property = res.data[i][0] && Object.keys(res.data[i][0])[0]
         data[i] = res.data[i]
        //  .slice(1,100)
         .map(val => {
          return {
            label: val[property],
            value: val[property]
          }
        })
      }
      else if (i === "pmFilterData") { 
        let l_pmFilterData = res.data[i][0]
          for(let j in l_pmFilterData) {
            if(j !== "max_price") {
              data[j] = l_pmFilterData?.[j]?.map(val => {
                  return {
                    label: val,
                    value: val
                  }
              })
            }
          }
          data = {...data,...payload}   
          console.log(data,payload,'l_pmFilterData') 
      }
        // else if (i === "pmFilterData" && !res.data[i][0]['l5_name']) {
        //   if(!payload.hasOwnProperty('level4')){
        //     data['l4_name'] = res.data[i][0]['l4_name'].filter(element => element).map(val => {
        //       return {
        //           label: val,
        //           value: val
        //       }
        //     })
        //     data['l3_name'] = payload?.level3Options
        //   }
        //   if(payload.hasOwnProperty('level4')){
        //     data['l4_name'] = payload?.level4Options
        //     data['l3_name'] = payload?.level3Options
        //   }
        //     if(!payload.hasOwnProperty('level3') && !payload.hasOwnProperty('level4')){
        //     data['l3_name'] = res.data[i][0]['l3_name'].filter(element => element).map(val => {
        //       return {
        //           label: val,
        //           value: val
        //       }
        //     })
        //   }
        // }
        // else if (i === "pmFilterData" && res.data[i][0]['l5_name']){
        //   if(!payload.hasOwnProperty('level4')){
        //     data['l4_name'] = res.data[i][0]['l4_name'].filter(element => element).map(val => {
        //       return {
        //           label: val,
        //           value: val
        //       }
        //     })
        //     data['l3_name'] = payload?.level3Options
        //   }
        //   if(!payload.hasOwnProperty('level5')){
        //     data['l5_name'] = res.data[i][0]['l5_name'].filter(element => element).map(val => {
        //       return {
        //           label: val,
        //           value: val
        //       }
        //     })
        //     data['l3_name'] = payload?.level3Options
        //   }
        //   if(payload.hasOwnProperty('level4')){
        //     data['l4_name'] = payload?.level4Options
        //     data['l3_name'] = payload?.level3Options
        //   }
        //     if(!payload.hasOwnProperty('level3') && !payload.hasOwnProperty('level4')){
        //     data['l3_name'] = res.data[i][0]['l3_name'].filter(element => element).map(val => {
        //       return {
        //           label: val,
        //           value: val
        //       }
        //     })
        //   }

        // }
      }

      data['max_price'] = res.data['pmFilterData'][0]['max_price']
      
      yield put(fetchCreateFilterSuccesss({ data: data }));
    }
    else {
      yield put(fetchCreateFilterError({ error: res.error}));
    }
  } catch (error) {
    console.log(error,'l_pmFilterData')
    yield put(fetchCreateFilterError({ error: "Something went wrong!" }));
  }
}

function* fetchProductListContributionDataWorker(action) {
  try {
    const { payload } = action;

    let l_req_body = {}

    for(let i in payload){
      if((payload[i] || payload[i] === 0)){
        if(Array.isArray(payload[i]) && payload[i].length){
          l_req_body[i] = payload[i].map(val => {
            return val.value
          })
        } 
        else if(((typeof(payload[i])) === "object")){
          if(i === "sales_attribute"){
            let sales_attribute = []
            for(let j in payload[i]){
              if(payload[i][j]){
                sales_attribute.push(j)
              }
             }
             if(sales_attribute.length){
              l_req_body[i] = sales_attribute
             }
          }
          else if(! (payload[i].value == "All")){
            l_req_body[i] = payload[i].value
          }
        }
        else {
          l_req_body[i] = payload[i]
        }
      }
    }


    const res = yield call(getProductListContributionData, l_req_body);

    if (res.data.status) {
      yield put(fetchProductListContributionSuccesss({ data: res.data.data }));
    }
    else {
      yield put(fetchProductListContributionError({ error: res.data?.message }));
    }
  } catch (error) {
    yield put(fetchProductListContributionError({ error: "Something went wrong!" }));
  }
}

function* fetchProfiledProductWorker(action) {
  try {
    const { payload } = action;
    let l_req_body = {}
    let req_level1 ={}
    if(!isEmpty(payload))
    {
      for (const key in payload) {
        if(key==='level1')
        {
        l_req_body[key] = payload[key]?.map((ele) => ele && ele.value)
        }
     }
    }
    console.log('ss1',l_req_body)

    l_req_body['product_code'] = payload.product_code
    l_req_body['static_dynamic'] = payload.static_dynamic
    // l_req_body['level1'] = req_level1
    if(payload.sales_attribute){
      let sales_attribute = []
      for(let j in payload.sales_attribute){
        if(payload.sales_attribute[j])
        sales_attribute.push(j)
       }
       if(sales_attribute.length){
        l_req_body['sales_attribute'] = sales_attribute
       }
    }
    if(payload.days){
      l_req_body['days'] = payload.days.value
    }
    else{
      l_req_body['min_date'] = payload.min_date
      l_req_body['max_date'] = payload.max_date
    }


    const response = yield call(getProfiledProductData, l_req_body);
    

    let l_sizeArr = response?.data?.unique_sizes.reduce((a,b)=> (a[b]='-',a),{});
    let l_responseWithAllSizeArr = response?.data?.data.map(val => {
      return{
            ...val,
            'size_data':{...l_sizeArr,...val.size_level_proportion}
          }
      })

    let func = (p_val) => {
      for (let i in p_val) {
        p_val[i] = Number(p_val[i])?(Number(p_val[i])*100).toFixed(1)+" %":"-"
      }
      return p_val
    }

    let resposneWithPercentage = l_responseWithAllSizeArr.map(val => {
      return {
        ...val,
        overall: Number(val.overall_proportion)?(Number(val.overall_proportion)*100).toFixed(1)+" %":"-",
        "size_data":func(val.size_data)
      }
    })


    let responseWithSizeData = resposneWithPercentage?.map(val => {
      return {
        ...val,
        ...val.size_data
      }
    })


    let userCreatedProfileTabsColumns = []

    userCreatedProfileTabsColumns.push({
      Header:'Store ID',
      accessor:'store_code',
      Filter:(instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
      filter: 'bulkFilterSpaceSeperated',
      Cell: ({ value, row }) =>
        <div className="row min-100">
          <span className={"min-100 pt-2 "+ (row?.original?.impute_store_tag ? "sister-store__map": "")}>
            {value}
          </span>
        </div>
    },
    {
      Header:'Overall',
      accessor:'overall',
      Filter: ColumnFilter,
      Cell: ({ value, row }) =>
      <div className="row min-100">
        <span className={"min-100 pt-2 "+ (row?.original?.impute_store_tag ? "sister-store__map": "")}>
          {value}
        </span>
      </div>
    })

      for (let val of response?.data?.unique_sizes) {
        userCreatedProfileTabsColumns.push({
          Header: val,
          accessor: (data) => data[val],
          Filter: ColumnFilter,
          Cell: ({ value, row }) =>
          <div className="row min-100">
            <span className={"min-100 pt-2 "+ (row?.original?.impute_store_tag ? "sister-store__map": "")}>
              {value}
            </span>
          </div>
        })
      }



    let resp = {
      data: {'profiledData': responseWithSizeData, 'column': userCreatedProfileTabsColumns, 'originalData':response?.data?.data},
      status: response?.data?.status,
      error: response?.data?.error
    }


    if (response.data.status) {
      yield put(fetchProfiledProductSuccess({ data: resp.data }));
    }
    else {
      yield put(fetchProfiledProductError({ error: resp.error }));
    }
  } catch (error) {
    yield put(fetchProfiledProductError({ error: "Something went wrong!" }));
  }
}

function* saveProductProfileWorker(action) {
  try {
    const { payload } = action;
    let payloadFilters = payload.filters
    let l_req_body = {}
console.log('ss1',payloadFilters)
    for(let i in payloadFilters){

        if(Array.isArray(payloadFilters[i]) ){
          console.log('ss2')
          if(i ==="color_description"){
            l_req_body["color"] = payloadFilters[i].map(val => {
              return val.value
            })
          }
          if(i ==="season_code"){
            l_req_body["launchDateFilterData"] = payloadFilters[i].map(val => {
              return val.value
            })
          }
          if(i === "level1"){
            l_req_body["level1"] = payloadFilters[i].map(val => {
              return val.value
            })
          }
          if(i === "level2"){
            l_req_body["level2"] = payloadFilters[i].map(val => {
              return val.value
            })
          }
          if(i ==="level3"){
            l_req_body["level3"] = payloadFilters[i].map(val => {
              return val.value
            })
          }
          if(i ==="level4"){
            l_req_body["level4"] = payloadFilters[i].map(val => {
              return val.value
            })
          }
        } 
        else if(((typeof(payloadFilters[i])) === "object") && payloadFilters[i]!= null){
          console.log('ss3')
          if(i === "sales_attribute"){
            l_req_body['regular'] = payloadFilters[i].Regular?1:0
            l_req_body['clearance'] = payloadFilters[i].Clearance?1:0
            l_req_body['promo'] = payloadFilters[i].Promo?1:0
          }
          else if( i === "season_code"){
            l_req_body["launchDateFilterData"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else if( i === "level3"){
            l_req_body["level3"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else if( i === "level4"){
            l_req_body["level4"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          if(i == "color_description"){
            l_req_body["color"] = payloadFilters[i]?payloadFilters[i]:[]
          }
          else if(i === "days"){
            l_req_body["period"] = payloadFilters[i]?.value?payloadFilters[i]?.label:""
          }
          else{
            l_req_body[i] = payloadFilters[i]?.value?payloadFilters[i]?.value:""
          }
        }
        else {
          console.log('ss4')
          if(i == "profileDescription"){
            l_req_body["description"] = payloadFilters[i] 
          }
          else if(i == "profileName"){
            l_req_body["name"] = payloadFilters[i] 
          }
          else if(i == "season_code") {
            l_req_body["launchDateFilterData"] = payloadFilters[i]?payloadFilters[i]:[]
          }
          else if(i == "color_description"){
            l_req_body["color"] = payloadFilters[i]?payloadFilters[i]:[]
          }
          else if( i === "level3"){
            l_req_body["level3"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else if( i === "level4"){
            l_req_body["level4"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else{
            l_req_body[i] = (payloadFilters[i] || payloadFilters[i] == 0)?payloadFilters[i]:""
          }
        }
    }

    let savedData = {}

    for(let i in payloadFilters){
      if(Array.isArray(payloadFilters[i]) && i ==="color_description"){
          savedData[i] = payloadFilters[i].map(val => {
            return val.value
          })
      } 
      else if(((typeof(payloadFilters[i])) === "object") && payloadFilters[i]!= null){
        if(i === "sales_attribute"){
          savedData[i] = payloadFilters[i]
          for( let j in savedData[i]){
            savedData[i][j] = 1
          }
        }
        else{
          savedData[i] = payloadFilters[i]
        }
          savedData[i] = payloadFilters[i]
      }
      else {
        savedData[i] = (payloadFilters[i] || payloadFilters[i] == 0)?payloadFilters[i]:""
      }
  }

  savedData['profiledProductData'] = payload?.profiledProductData
  savedData['profiledProductColumns'] = payload?.profiledProductColumns
  savedData['colourOptions'] = payload?.colourOptions
  savedData['seasonCodeOptions'] = payload?.seasonCodeOptions
  savedData['level4Options'] = payload?.level4Options
  savedData['level3Options'] = payload?.level3Options
  savedData['level2Options'] = payload?.level2Options

  let updated_req_body = {
    ...l_req_body,
    'articleData': payload?.filters.articleData,
    'storeData': payload?.storeData,
    'savedData': savedData,
    "user_created_by": payload?.user_created_by
  }


    const res = yield call(saveProductProfile, updated_req_body);

    if (res.data.status) {
      yield put(saveProductProfileSuccess({ data: res.data.data }));
    }
    else if(res.data?.status === false) {
      yield put(saveProductProfileError({ error: "Duplicate" }));
    }
    else {
      yield put(saveProductProfileError({ error: res.error }));
    }
  } catch (error) {
    yield put(saveProductProfileError({ error: "Something went wrong!" }));
  }
}

function* fetchSavedDataWorker(action) {
  try {
    const { payload } = action;
    let l_req_body = {
      "product_profile_code" : payload
    }

    const res = yield call(getSavedData, l_req_body);

    if (res.data.status) {
      yield put(fetchSavedDataSuccess({ data: res.data.data }));
    }
    else {
      yield put(fetchSavedDataError({ error: res.error }));
    }
  } catch (error) {
    yield put(fetchSavedDataError({ error: "Something went wrong!" }));
  }
}

function* deleteProductProfileWorker(action){
  try {
    const { payload } = action;
    let l_req_body = {
      "product_profile_code" : payload
    }

    const res = yield call(deleteProductProile, l_req_body);

    if (res.data.status) {
      yield put(deleteProductProfileSuccess({ data: res.data.data }));
    }
    else {
      yield put(deleteProductProfileError({ error: res.error }));
    }
  } catch (error) {
    yield put(deleteProductProfileError({ error: "Something went wrong!" }));
  }
  
}


function* fetchStoresDataWatcher() {
  yield takeLatest(FETCH_STORES_DATA, fetchStoresDataWorker);
}

function* fetchUserCreatedDataWatcher() {
  yield takeLatest(FETCH_USER_CREATED_DATA, fetchUserCreatedDataWorker);
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* fetchCreateFilterDataWatcher() {
  yield takeLatest(FETCH_CREATEFILTER_DATA, fetchCreateFilterDataWorker);
}

function* fetchProductListContributionDataWatcher() {
  yield takeLatest(FETCH_PRODUCT_LIST_CONTRIBUTION_DATA, fetchProductListContributionDataWorker)
}

function* fetchProfiledProductWatcher() {
  yield takeLatest(FETCH_PROFILED_PRODUCT_DATA, fetchProfiledProductWorker)
}

function* saveProductProfileWatcher() {
  yield takeLatest(SAVE_PRODUCT_PROFILE_DATA, saveProductProfileWorker)
}

function* fetchSavedDataWatcher() {
  yield takeLatest(FETCH_SAVED_DATA, fetchSavedDataWorker)
}

function* deleteProductProfileWatcher(){
  yield takeLatest(DELETE_PRODUCT_PROFILE, deleteProductProfileWorker)
}

export function* productProfileSaga() {
  yield all([
    fetchUserCreatedDataWatcher(),
    fetchFilterDataWatcher(),
    fetchStoresDataWatcher(),
    fetchCreateFilterDataWatcher(),
    fetchProductListContributionDataWatcher(),
    fetchProfiledProductWatcher(),
    saveProductProfileWatcher(),
    fetchSavedDataWatcher(),
    deleteProductProfileWatcher(),
  ]);
}
