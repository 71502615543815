import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from 'lodash';
import AdaVisualFilters from './AdaVisualFilters';
import AdaVisualView from './AdaVisualView';
import PageLoader from "../../components/Loader/PageLoader";
import moment from "moment";
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications";
import * as adaVisualActions from './actions/AdaVisualActions';
import { getEditedWeeklyAdaData, getEditedWeeklyHierarchyData } from './AdaUtilities';
import { NO_REVIEW_MSG, NO_SAVE_MSG, REVIEW_FAILED_MSG, REVIEW_SUCCESS_MSG, SAVE_SUCCESS_MSG, SAVE_FAILED_MSG } from './AdaVisualConstants';
import "./AdaVisual.css";

/**
 * Stand Alone Ada container with respective state
 * and handler functions
 * @returns AdaVisualVIew with Filters section
 */
class StandAloneContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleFilterRequest = this.handleFilterRequest.bind(this);
        this.handleReviewRequest = this.handleReviewRequest.bind(this);
        this.handleSaveRequest = this.handleSaveRequest.bind(this);
        this.resetAdaDisplayState = this.resetAdaDisplayState.bind(this);
        this.getLoaderMessage = this.getLoaderMessage.bind(this);
        this.state = {
            displayAda: false,
        };
    }

    componentWillUnmount() {
        const { adaVisualActions } = this.props;
        adaVisualActions.resetAdaVisual();
    }

    componentDidUpdate() {
        const { isReviewReq, isReviewSuccess, isReviewFailed, isSaveReq, isSaveSuccess, isSaveFailed } = this.props;
        if (!isReviewReq && !isReviewFailed && isReviewSuccess && !isSaveReq && !isSaveFailed) {
            Notify.success(REVIEW_SUCCESS_MSG);
        } else if (!isReviewReq && isReviewFailed && !isReviewSuccess && !isSaveReq && !isSaveFailed) {
            Notify.error(REVIEW_FAILED_MSG);
        } else if (!isSaveReq && isSaveSuccess && !isSaveFailed) {
            Notify.success(SAVE_SUCCESS_MSG);
        } else if (!isSaveReq && !isSaveSuccess && isSaveFailed) {
            Notify.error(SAVE_FAILED_MSG);
        } else {
            //No code here
        }
    }

    /**
     * Function to handle Filter request
     * @param {*} request 
     */
    handleFilterRequest(request) {
        const { adaVisualActions, startFiscalYearWeek, endFiscalYearWeek } = this.props;
        const currentDate = moment().format("YYYYWW");
        adaVisualActions.setAdaCurrentDate(currentDate);
        let reqBody = {};
        if (request) {
            reqBody = {
                start_fw: startFiscalYearWeek,
                end_fw: endFiscalYearWeek,
                current_fw: currentDate,
                level1: [request?.department.value],
                level2: request?.gender.map(item => item.value),
                level3: request?.subCat?.map(item => item.value),
                level4: request?.dcs?.map(item => item.value),
                articles: request?.articleId?.map(item => item.value),
            };
            adaVisualActions.setAdaStandAloneRequest(reqBody);
            adaVisualActions.getAdaStandAloneData(reqBody);
            this.setState({
                displayAda: true,
            });
        }
        else {
            //implement Negative scenarios
        }
    }

    /**
     * Function to handle Review Ada Visual Request
     * @param {event} e 
     */
    handleReviewRequest(e) {
        if (e) {
            const { adaVisualActions, startFiscalYearWeek, endFiscalYearWeek, isReviewSuccess,
                adaStandAloneReqBody, isForecastModified, isHierarchyModified, adaVisualData, adaVisualDataCopy,
                hierarchyDataCopy, hierarchyData } = this.props;
            const { current_fw, level1, level2, level3, level4, articles } = adaStandAloneReqBody;
            const updatedAdaWeekData = getEditedWeeklyAdaData(adaVisualData, adaVisualDataCopy, current_fw);
            const updtedHierarchyWeekData = getEditedWeeklyHierarchyData(hierarchyData, hierarchyDataCopy, current_fw);
            let reviewReq = {};

            if (!isReviewSuccess && ((isHierarchyModified && !isEmpty(updtedHierarchyWeekData)) || (isForecastModified && !isEmpty(updatedAdaWeekData)))) {

                const tabValue = isForecastModified ? 'all' : (isHierarchyModified ? 'individual' : '');
                reviewReq = {
                    user_id: localStorage.getItem("user_id"),
                    screen: "stand alone",
                    tab: tabValue,
                    review_save_tag: "review",
                    month_or_week: "week",
                    time_stamp: moment().toISOString(),
                    start_fw: startFiscalYearWeek,
                    end_fw: endFiscalYearWeek,
                    current_fw: current_fw,
                    level1: [...level1],
                    level2: [...level2],
                    level3: level3 ? [...level3] : null,
                    level4: level4 ? [...level4] : null,
                    selected_articles: [...articles],
                    article_store_combination: null,
                    changed_combinations_lower_hierarchy_week:
                        (isHierarchyModified && !isEmpty(updtedHierarchyWeekData)) ?
                            { ...updtedHierarchyWeekData } : null,
                    changed_combinations_adjusted_forecast_week: isForecastModified && !isEmpty(updatedAdaWeekData) ? updatedAdaWeekData : null,
                };

                adaVisualActions.reviewAdaVisual(reviewReq);
            } else {
                Notify.error(NO_REVIEW_MSG);
            }
        }
    }

    /**
     * Function to handle Save Ada Visual Request
     * @param {*} e 
     */
    handleSaveRequest(e) {
        if (e) {
            const { adaVisualActions, startFiscalYearWeek, endFiscalYearWeek,
                adaStandAloneReqBody, isReviewSuccess, isForecastModified, isHierarchyModified } = this.props

            let saveReq = {};

            if (isReviewSuccess) {
                const { current_fw, level1, level2, level3, level4, articles } = adaStandAloneReqBody;
                const tabValue = isForecastModified ? 'all' : (isHierarchyModified ? 'individual' : '');
                saveReq = {
                    user_id: localStorage.getItem("user_id"),
                    screen: "stand alone",
                    tab: tabValue,
                    review_save_tag: "save",
                    month_or_week: "week",
                    start_fw: startFiscalYearWeek,
                    end_fw: endFiscalYearWeek,
                    current_fw: current_fw,
                    level1: [...level1],
                    level2: [...level2],
                    level3: level3 ? [...level3] : null,
                    level4: level4 ? [...level4] : null,
                    selected_articles: [...articles],
                    article_store_combination: null,
                    changed_combinations_lower_hierarchy_week: null,
                    changed_combinations_adjusted_forecast_week: null,
                    time_stamp: moment().toISOString(),
                };

                adaVisualActions.saveAdaVisual(saveReq);
            } else {
                Notify.error(NO_SAVE_MSG);
            }
        }
    }

    resetAdaDisplayState() {
        this.setState({
            displayAda: false,
        });
    }

    getLoaderMessage() {
        const { isReviewReq, isSaveReq } = this.props;
        let loadingMsg = '';
        isReviewReq ? loadingMsg = 'Reviewing...'
            : (isSaveReq ? loadingMsg = 'Saving...' : loadingMsg = 'Loading...');

        return loadingMsg;
    }

    render() {
        const { isAdaDataLoading, isReviewReq, isSaveReq } = this.props;
        const { displayAda } = this.state;

        return (
            <React.Fragment>
                <Notification />
                <div className="filter row" style={{ marginTop: "8%" }}>
                    <AdaVisualFilters handleFilterRequest={this.handleFilterRequest} resetAdaDisplayState={this.resetAdaDisplayState} />
                </div>
                <PageLoader loader={isAdaDataLoading || isReviewReq || isSaveReq} loaderMessage={this.getLoaderMessage()}>
                    {displayAda ? <AdaVisualView handleReviewRequest={this.handleReviewRequest}
                        handleSaveRequest={this.handleSaveRequest} /> : null}
                </PageLoader>
            </React.Fragment >
        )
    }
}

const mapStateToProps = ({ adaVisual }) => ({
    startFiscalYearWeek: adaVisual.startFiscalYearWeek,
    endFiscalYearWeek: adaVisual.endFiscalYearWeek,
    isAdaDataLoading: adaVisual.isAdaDataLoading,
    isAdaReqFailed: adaVisual.isAdaReqFailed,
    isAdaLoadingSuccess: adaVisual.isAdaLoadingSuccess,
    adaStandAloneReqBody: adaVisual.adaStandAloneReqBody,
    isReviewReq: adaVisual.isReviewReq,
    isReviewFailed: adaVisual.isReviewFailed,
    isReviewSuccess: adaVisual.isReviewSuccess,
    isForecastModified: adaVisual.isForecastModified,
    isHierarchyModified: adaVisual.isHierarchyModified,
    adaVisualData: adaVisual.adaVisualData,
    adaVisualDataCopy: adaVisual.adaVisualDataCopy,
    hierarchyData: adaVisual.hierarchyData,
    hierarchyDataCopy: adaVisual.hierarchyDataCopy,
    isSaveReq: adaVisual.isSaveReq,
    isSaveSuccess: adaVisual.isSaveSuccess,
    isSaveFailed: adaVisual.isSaveFailed,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandAloneContainer);