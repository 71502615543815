import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { EMPTY_FORECAST_MSG, EMPTY_DATA_HELPER_MSG } from './AdaVisualConstants';
import "./TableStyle.css";

/**
 * Forecast Table View component
 * @param {table data and headers} props 
 * @returns 
 */
const ForecastTableView = (props) => {
    const { adaData, keys, headers, currentWeek, handleAdjustedOnChange, handleForecastOnChange } = props;
    return (
        <div style={{ padding: "2px" }}>
            {adaData.length === 0 ?
                <div className="empty">{EMPTY_FORECAST_MSG}
                    <p>{EMPTY_DATA_HELPER_MSG}</p>
                </div>
                :
                <table className="table-set">
                    <thead>
                        <tr>
                            <th className="th-set sticky-col" key="0">
                                <span></span>
                            </th>
                            {adaData.map((weekHead) => (
                                <th className="th-set" key={weekHead.week}>
                                    <span style={{ border: "none" }}>{`W${(weekHead.week)}`}</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="">
                        {keys.map((key, keyIndex) => (
                            <tr key={keyIndex}>
                                <td className="td-set sticky-col" style={{ minWidth: "115px" }} key={0}>
                                    {headers[key]}
                                </td>
                                {adaData.map((value, index) => (
                                    value.fy_fw < currentWeek ?
                                        <td className="td-set data_disabled" key={index + 1}>
                                            <InputNumber
                                                className="span-data"
                                                value={key === 'forecast_multiplier' ? value[key].toFixed(1) : value[key]}
                                                mode='decimal'
                                                minFractionDigits={1}
                                                maxFractionDigits={1}
                                                key={value.fy_fw}
                                                disabled
                                                format={key === 'forecast_multiplier' ? true : false}
                                            />
                                        </td>
                                        : <td className="td-set data_input" key={index + 1}>
                                            <InputNumber
                                                mode='decimal'
                                                minFractionDigits={1}
                                                maxFractionDigits={1}
                                                value={key === 'forecast_multiplier' ? value[key].toFixed(1) : value[key]}
                                                disabled={key === 'ia_forecast'}
                                                key={value.fy_fw}
                                                name={value.fy_fw}
                                                onBlur={key === 'adjusted_forecast' ? handleAdjustedOnChange : handleForecastOnChange}
                                                className={(key === 'ia_forecast')
                                                    ? "span-data" : "edit-span"}
                                                format={key === 'forecast_multiplier' ? true : false}
                                            />
                                        </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div>
    )
}

export default ForecastTableView;