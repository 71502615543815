import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import {
  FETCH_STRATEGY_TABLE_DATA,
  FETCH_FILTER_DATA,
  fetchStrategyTableDataSuccess,
  fetchStrategyTableDataError,
  fetchFilterDataSuccesss,
  fetchFilterDataError,
  fetchStrategyTableDropdownSuccess,
  fetchStrategyTableDropdownError,
  FETCH_STRATEGY_TABLE_DROPDOWN,
  FETCH_SG_MIN_STOCK,
  fetchStoregroupMinStockSuccess,
  fetchStoregroupMinStockError,
  fetchStoreTableError,
  FETCH_STORE_TABLE_DATA,
  fetchStoreTableSuccess,
  FETCH_DC_DATA,
  fetchDCError,
  fetchDCSuccess,
  GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD,
  getStrategyTableDataFromDashboardSuccess,
  getStrategyTableDataFromDashboardError,
  CREATE_ALLOCATION,
  createAllocationSuccess,
  createAllocationError,
  UPDATE_RESERVED_QTY,
  updateResQtySuccess,
  updateResQtyError,
  SAVE,
  saveSuccess,
  saveError,
  GETSTOREGROUPMINSTOCK,
  getstoregroupminstockSuccess,
  getstoregroupminstockError,
  SAVE_DRAFT,
  saveDraftSuccess,
  saveDraftError,
  DELETE_DRAFT,
  deleteDraftSuccess,
  deleteDraftError,
  VALIDATE_PLAN_NAME,
  validatePlanNameSuccess,
  validatePlanNameError
} from "./actions";

import {
  getFilterData,
  getStrategyDropdownData,
  getStrategyTableData,
  getStrategyTableMinStockData,
  getStoreTableData,
  getDcTableData,
  createAllocation,
  updateResQty,
  save,
  saveDraft,
  getDrafts,
  deleteDraft,
  validateDraftName,
  optimizationAlgoInputData
  // getstoregroupminstock,
} from "../../routes/apis";
import { cloneDeep, isEmpty, isEqual } from "lodash";

function* fetchStrategyTableDataWorker(action) {
  try {
    const { payload } = action;
    let req = {};
    if (payload.d) {
      req = payload
    }
    else {
      if (!isEmpty(payload)) {
        req.Attributes = {};
        req.Operators = { NonAttributeColumn: [] };
        for (const key in payload) {
          if (!isEmpty(payload[key])) {
            req[key] = payload[key]?.map((ele) => ele.value);
            req.Attributes[key] = req[key];
            req.Operators.NonAttributeColumn.push(key);
          }
        }
      }
      req["dc"] = payload.dc
    }
    const res = yield call(getStrategyTableData, req);
    let storeGroupStoreResult = res?.data?.storeGroupStoreResult
    let storeMinStockResult = res?.data?.storeMinStockResult
    // let l_noOfStores
    // const getNoofStores = (p_storeGroupCode) => {
    //   l_noOfStores = []
    //   // for(let i in storeGroupStoreResult){
    //   //   if(p_storeGroupCode.includes(Number(i))){
    //   //     l_noOfStores.push(storeGroupStoreResult[i])
    //   //   }
    //   // }
    //   // l_noOfStores = [... new Set(l_noOfStores.flat(Infinity))]
    //   l_noOfStores = storeGroupStoreResult[p_storeGroupCode]
    //   return l_noOfStores.length;
    // }

    let l_noOfStores
    const getNoofStores = (p_storeGroupCode) => {
      l_noOfStores = []
      for (let i in storeGroupStoreResult) {
        if (p_storeGroupCode.includes(Number(i))) {
          l_noOfStores.push(storeGroupStoreResult[i])
        }
      }
      l_noOfStores = [... new Set(l_noOfStores.flat(Infinity))]
      return l_noOfStores.length;
    }
  

    const getMinPresQty = (p_article) => {
      return storeMinStockResult.filter(val => l_noOfStores.includes(val.store_code)).filter(val => val.article == p_article).reduce((acc, curr) => {
        return acc = acc + curr.min_stock
      }, 0)
    }

    const article = {
      article: "89648201",
      style_desc: "PUMA EVERCAT THE CHILL YOUTH ADJUSTABLE",
      color_desc: "GREY/BLACK",
      upc: 193524111151,
      size_desc: "UA",
      oh: 3,
      it: 0,
      oo: 2,
      reserve_quantity: 0,
      store_eligibility: "All Stores",
      store_group_code: [51, 70, 68, 61, 56],
      store_group: [
        "dum title 45",
        "testing5",
        "testing_26112020",
        "Raghu test 04 edited 01",
        "Raghu-07 edited 02",
      ],
      min_stock: ["4"],
      size: ["UA"],
      min_stock_arr: [4, 4, 4, 4, 4],
      min_stock_obj: {
        51: 4,
        70: 4,
        68: 4,
        61: 4,
        56: 4,
      },
    };
    const object = {
      product_type: "New",
      product_code: 1000,
      product_description: "very big product",
      product_profile: "IA recommended",
      color: "PUMA Black",
      size_desc: "N/A",
      store_eligibility: { label: "All", value: 0 },
      no_of_stores: "N/A",
      phase_in_date: new Date(),
      phase_out_date: new Date(),
      // demand_type: "APS",
      min_stock: "N/A",
      max_stock: "N/A",
      dc_inv_on_hand: "N/A",
      dc_inv_on_order: "N/A",
      dc_inv_in_transit: "N/A",
      total_avail_inv: "N/A",
      dc_res_qty: "N/A",
      min_pres_qty: "N/A",
      inv_src: "ASN",
      ros: 30,
      wos: 40,
      safety_stock: 50,
    };
    let data = [];
    data = res.data.data?.map((row) => {
      let store_groups = [];
      row.store_group?.forEach((group, idx) => {
        store_groups.push({
          value: row.store_group_code[idx],
          label: group,
        });
      });

      let size_groups = [];
      row.size?.forEach((group, idx) => {
        size_groups.push({
          value: group,
          label: group,
        });
      });

      let product_profiles = []
      row.product_profile_titles?.forEach((productProfile, idx) => {
        product_profiles.push({
          value: row.product_profile_codes[idx],
          label: productProfile
        })
      })

      let upcSizeMapping = {}
      row.size?.forEach((val, index) => {
        upcSizeMapping[val] = row['upc'][index]
      })

      row.size_options = row.size?.map((sz) => {
        return {
          label: sz,
          value: sz,
        };
      });
      row.size_desc = [
        {
          value: row.size_desc,
          label: row.size_desc,
        },
      ];
      row.size_desc = size_groups

      if (row.store_eligibility === "All Stores") {
        row.store_eligibility = store_groups;
      } else {
        row.store_eligibility = {
          value: row.store_eligibility,
          label: row.store_eligibility,
        };
      }
      row.no_of_stores = (isEmpty(row['default_store_groups']) ? 0 : getNoofStores(row['default_store_groups']?.map(val => val.value)))
      // row.min_pres_qty = getMinPresQty(row.article)
      row.delivery_date = new Date(Date.now() + row.transit_time * 24 * 60 * 60 * 1000)
      let finalInv =  row.edit_total_inventory ? ((Number(row.edit_total_inventory) / 100) * (row.total_inventory)).toFixed(0) : "-"

      return {
        ...object,
        ...row,
        // store_groups,
        // product_profiles,
        // 'dc_stores': res.data.dcavailResult,
        // 'dc_options': res.data.dcavailResult,
        'total_inventory': row['total_inventory'],
        // 'edit_total_inventory': Math.max(((Number(row.oh) + Number(row.oo) + Number(row.it)) - Number(row.reserve_quantity)),0),
        'edit_total_inventory': row["edit_total_inventory"] || "",
        // 'final_total_inventory': Math.max(((Number(row.oh) + Number(row.oo) + Number(row.it)) - Number(row.reserve_quantity)),0),
        'store_eligibility_default': [row['store_eligibility'][0]],
        'Demand_Type_Filter_Default': [{ label: (row['Demand_Type_Filter_Default'] == "IA" ? "IA Forecast" : row['Demand_Type_Filter_Default']), value: row['Demand_Type_Filter_Default'] }],
        'final_total_inventory': finalInv,
        // 'default_product_profile': row['default_product_profile'].value ? row['default_product_profile'] : product_profiles,
        'default_inventory_sorce_filter': row['Inventory_Source_Filter'],
        'default_store_eligibility_default': isEmpty(row['default_store_groups']) ? [] : row['default_store_groups'],
        'upcSizeMapping': upcSizeMapping
      };
    })
    let response = {
      data: data,
      storeGroupStoreResult: res?.data?.storeGroupStoreResult,
      storeMinStockResult
    }
    // data.push({storeGroupStoreResult: res?.data?.storeGroupStoreResult})
    // console.log("aslfq8y3r8hlaw", response,data, data[0].delivery_date);
    if (res.data.status) {
      yield put(fetchStrategyTableDataSuccess({ data: response }));
    } else {
      yield put(
        fetchStrategyTableDataError({ error: "Something went wrong!" })
      );
    }
  } catch (error) {
    yield put(fetchStrategyTableDataError({ error: error }));
  }
}

function* fetchFilterDataWorker(action) {
  try {
    const { payload } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        if (key !== "key") {
          req[key] = payload[key]?.map((ele) => ele.value);
        }
      }
    }
    const res = yield call(getFilterData, req);
    console.log(req,res,'kuuyfdrx')
    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data.filterMapping;
      for (const key in res.data.data[0]) {
        let k = "";
        if (key === "level1") {
          k = "divisions";
        } else if (key === "level2") {
          k = "departments";
        } else if (key === "level3") {
          k = "subdepartments";
        } else if (key === "level4") {
          k = "level4";
        } else if (key === "level5") {
          k = "level5";
        } else if (key === "store_code") {
          k = "store_code"
        }

        data[k] = res.data.data[0][key]?.filter(val => val)?.map((element) => ({
          value: element,
          label: element,
        }));
      }
      console.log(data,action.payload.key,'sdvd')
      yield put(
        fetchFilterDataSuccesss({ data: data, key: action.payload.key })
      );
    }
  } catch (error) {
    yield put(fetchFilterDataError({ error: "Something went wrong!" }));
  }
}

function* fetchStrategyTableDropdowWorker() {
  try {
    // get store groups
    const storeGroups = yield call(getStrategyDropdownData, {
      type: "store-group",
    });
    // get size
    const sizes = yield call(getStrategyDropdownData, { type: "size" });
    const data = {
      storeGroups: storeGroups.data.data?.map((group) => ({
        label: group.title,
        value: group.store_group_code,
      })),
      sizes: sizes.data.data?.map((size) => ({
        value: size.size,
        label: size.size,
      })),
    };
    yield put(fetchStrategyTableDropdownSuccess({ data: data }));
  } catch (error) {
    yield put(
      fetchStrategyTableDropdownError({ error: "Something went wrong!" })
    );
  }
}

function* fetchStoregroupMinStockWorker(action) {
  try {
    const { rowIdx, colId, value, article, valueColId } = action.payload;
    const req = {
      store_group_code: value?.map((val) => val.value),
      Attributes: { article: [article] },
      Operators: {
        NonAttributeColumn: ["article"],
      },
    };
    const res = yield call(getStrategyTableMinStockData, req);
    yield put(
      fetchStoregroupMinStockSuccess({
        data: {
          rowIdx,
          colId,
          colValue: value,
          value: res.data.data.min_stock,
          valueColId,
        },
      })
    );
  } catch (error) {
    yield put(fetchStoregroupMinStockError({ error: "Something went wrong!" }));
  }
}

function* fetchStoreTableDataWorker(action) {
  try {
    const res = yield call(getStoreTableData, action.payload);
    if (res.data.status) {
      yield put(fetchStoreTableSuccess({ data: res.data.data }));
    } else {
      yield put(fetchStoreTableError({ error: res.data.message }));
    }
  } catch (error) {
    yield put(fetchStoreTableError({ error: "Something went wrong!" }));
  }
}

function* fetchDcDataWorker(action) {
  try {
    const res = yield call(getDcTableData, action.payload);
    if (res.data.status) {
      yield put(fetchDCSuccess({ data: res.data.data }));
    } else {
      yield put(fetchDCError({ error: res.data.message }));
    }
  } catch (error) {
    yield put(fetchDCError({ error: "Something went wrong!" }));
  }
}

function* createAllocationWorker(action) {
  try {
    let {payload, isSSE} = action
    console.log(isSSE,'gettingenvId')
    let apiEndPoint = optimizationAlgoInputData
    let req = {}
    for(let i in payload) {
      if(payload[i]) {
        req[i] = payload[i]
      }
    }
    if(isSSE) {
      apiEndPoint = createAllocation
      req = {"input" : payload}
    }
    console.log(apiEndPoint,req,'gettingenvId')
    const res = yield call(apiEndPoint, req);
    if (res.data.status) {
      let res_data = res.data?.uuid_str ? res.data?.uuid_str : res.data?.allocation_code
      yield put(createAllocationSuccess({ data: res_data }));
    } else {
      yield put(createAllocationError({ error: res.data }));
    }
  } catch (error) {
    yield put(createAllocationError({ error: "Something went wrong!" }));
  }
}

function* updateResQtyWorker(action) {
  try {
    const res = yield call(updateResQty, { data: action.payload });
    if (res.data.status) {
      yield put(updateResQtySuccess({ data: res.data.data }));
    } else {
      yield put(updateResQtyError({ error: "Something went wrong!" }));
    }
  } catch (error) {
    yield put(updateResQtyError({ error: "Something went wrong!" }));
  }
}

function* fetchStrategyTableFromDashboardWorker(action) {
  try {
    const res = yield call(getStrategyTableData, action.payload);

    if (res.data.status) {
      if( res.data.data.length == 0) {
        yield put(getStrategyTableDataFromDashboardSuccess({ data: [] }));
      }

      let storeGroupStoreResult = res?.data?.storeGroupStoreResult
      let storeMinStockResult = res?.data?.storeMinStockResult
      // let l_noOfStores
      // const getNoofStores = (p_storeGroupCode) => {
      //   l_noOfStores = []
      //   // for(let i in storeGroupStoreResult){
      //   //   if(p_storeGroupCode.includes(Number(i))){
      //   //     l_noOfStores.push(storeGroupStoreResult[i])
      //   //   }
      //   // }
      //   // l_noOfStores = [... new Set(l_noOfStores.flat(Infinity))]
      //   l_noOfStores = storeGroupStoreResult[p_storeGroupCode]
      //   return l_noOfStores.length;
      // }

      let l_noOfStores
      const getNoofStores = (p_storeGroupCode) => {
        l_noOfStores = []
        for (let i in storeGroupStoreResult) {
          if (p_storeGroupCode.includes(Number(i))) {
            l_noOfStores.push(storeGroupStoreResult[i])
          }
        }
        l_noOfStores = [... new Set(l_noOfStores.flat(Infinity))]
        return l_noOfStores.length;
      }
    

      
      const getMinPresQty = (p_article) => {
        return storeMinStockResult.filter(val => l_noOfStores.includes(val.store_code)).filter(val => val.article == p_article).reduce((acc, curr) => {
          return acc = acc + curr.min_stock
        }, 0)
      }

      const object = {
        product_type: "New",
        product_code: 1000,
        product_description: "very big product",
        product_profile: "IA recommended",
        color: "PUMA Black",
        size_desc: "N/A",
        store_eligibility: { label: "All", value: 0 },
        no_of_stores: "N/A",
        phase_in_date: new Date(),
        phase_out_date: new Date(),
        delivery_date: new Date(),
        // demand_type: "APS",
        min_stock: "N/A",
        max_stock: "N/A",
        dc_inv_on_hand: "N/A",
        dc_inv_on_order: "N/A",
        dc_inv_in_transit: "N/A",
        total_avail_inv: "N/A",
        dc_res_qty: "N/A",
        min_pres_qty: "N/A",
        inv_src: "ASN",
        ros: 30,
        wos: 40,
        safety_stock: 50,
      };
      let data = [];
      data = res?.data?.data?.map((row) => {
        let totalInv = Math.max(((Number(row.oh) + Number(row.oo) + Number(row.it)) - Number(row.reserve_quantity) - Number(row.allocated_units)), 0)
        let finalInv =  row.edit_total_inventory ? ((Number(row.edit_total_inventory) / 100) * (row.total_inventory)).toFixed(0) : "-"
        let store_groups = [];
        row.store_group?.forEach((group, idx) => {
          store_groups.push({
            value: row.store_group_code[idx],
            label: group,
          });
        });

        let size_groups = [];
        row.size?.forEach((group, idx) => {
          size_groups.push({
            value: group,
            label: group,
          });
        });

        let product_profiles = []
        row.product_profile_titles?.forEach((productProfile, idx) => {
          product_profiles.push({
            value: row.product_profile_codes[idx],
            label: productProfile
          })
        })

        let upcSizeMapping = {}
        row.size?.forEach((val, index) => {
          upcSizeMapping[val] = row['upc'][index]
        })

        row.size_options = row.size?.map((sz) => {
          return {
            label: sz,
            value: sz,
          };
        });
        row.size_desc = [
          {
            value: row.size_desc,
            label: row.size_desc,
          },
        ];
        row.size_desc = size_groups
        if (row.store_eligibility === "All Stores") {
          row.store_eligibility = store_groups;
        } else {
          row.store_eligibility = {
            value: row.store_eligibility,
            label: row.store_eligibility,
          };
        }
        row.delivery_date = new Date(Date.now() + row.transit_time * 24 * 60 * 60 * 1000)
        row.no_of_stores = (isEmpty(row['default_store_groups']) ? 0 : getNoofStores(row['default_store_groups']?.map(val => val.value)))
        // row.min_pres_qty = getMinPresQty(row.article)
        return {
          ...object,
          ...row,
          // store_groups,
          // product_profiles,
          // 'total_inventory': Math.max(((Number(row.oh) + Number(row.oo) + Number(row.it)) - Number(row.reserve_quantity)),0),
          // // 'edit_total_inventory': Math.max(((Number(row.oh) + Number(row.oo) + Number(row.it)) - Number(row.reserve_quantity)),0),
          // 'edit_total_inventory': 100,
          // 'final_total_inventory': Math.max(((Number(row.oh) + Number(row.oo) + Number(row.it)) - Number(row.reserve_quantity)),0),
          // 'store_eligibility_default' : [row['store_eligibility'][0]],
          // 'dc_stores': res.data.dcavailResult,
          // 'dc_options': res.data.dcavailResult,
          'total_inventory': isNaN(totalInv) ? row.total_inventory : totalInv,
          // 'edit_total_inventory': Math.max(((Number(row.oh) + Number(row.oo) + Number(row.it)) - Number(row.reserve_quantity)),0),
          'edit_total_inventory': row?.edit_total_inventory || '',
          'final_total_inventory': finalInv,
          // 'final_total_inventory': Math.max(((Number(row.oh) + Number(row.oo) + Number(row.it)) - Number(row.reserve_quantity)),0),
          'store_eligibility_default': [row['store_eligibility'][0]],
          'Demand_Type_Filter_Default': [{ label: (row['Demand_Type_Filter_Default'] == "IA" ? "IA Forecast" : row['Demand_Type_Filter_Default']), value: row['Demand_Type_Filter_Default'] }],
          // 'final_total_inventory': '-',
          // 'default_product_profile': row['default_product_profile'].value ? row['default_product_profile'] : product_profiles,
          'default_inventory_sorce_filter': row['Inventory_Source_Filter'],
          'default_store_eligibility_default': isEmpty(row['default_store_groups']) ? [] : row['default_store_groups'],
          'upcSizeMapping': upcSizeMapping
        };
      });
      let response = {
        data: data,
        storeGroupStoreResult: res?.data?.storeGroupStoreResult,
        storeMinStockResult
      }
      yield put(getStrategyTableDataFromDashboardSuccess({ data: response }));
    } else {
      yield put(
        getStrategyTableDataFromDashboardError({
          error: "Something went wrong!",
        })
      );
    }
  } catch (error) {
    yield put(
      getStrategyTableDataFromDashboardError({ error: "Something went wrong!" })
    );
  }
}

function* saveWorker(action) {
  try {
    const res = yield call(save, { data: action.payload });
    if (res.data.status) {
      yield put(saveSuccess({ data: res.data.allocation_code }));
    } else {
      yield put(saveError({ error: "Something went wrong!" }));
    }
  } catch (error) {
    yield put(saveError({ error: "Something went wrong!" }));
  }
}

function* getstoregroupminstockWorker(action) {
  try {
    const res = yield call('', { ...action.payload });
    if (res.data.status) {
      yield put(getstoregroupminstockSuccess({ data: (res.data.data) }));
    } else {
      yield put(getstoregroupminstockError({ error: "Something went wrong!" }));
    }
  } catch (error) {
    yield put(getstoregroupminstockError({ error: "Something went wrong!" }));
  }
}

// function* getDraftWorker(action) {
//   try {
//     const res = yield call(getDrafts, action.payload);
//     if (1) {
//       yield put(getDraftsSuccess({ data: (res.data) }));
//     } else {
//       yield put(getDraftsError({ error: "Something went wrong!" }));
//     }
//   } catch (error) {
//     yield put(getDraftsError({ error: "Something went wrong!" }));
//   }
// }

function* saveDraftWorker(action) {
  try {
    const res = yield call(saveDraft, action.payload);
    if (res.data.status) {
      yield put(saveDraftSuccess({ data: (res.data) }));
    } else {
      yield put(saveDraftError({ error: "Something went wrong!" }));
    }
  } catch (error) {
    yield put(saveDraftError({ error: "Something went wrong!" }));
  }
}

function* deleteDraftWorker(action) {
  try {
    const res = yield call(deleteDraft, action.payload);
    if (res.data.status) {
      yield put(deleteDraftSuccess({ data: (res.data) }));
    } else {
      yield put(deleteDraftError({ error: "Something went wrong!" }));
    }
  } catch (error) {
    yield put(deleteDraftError({ error: "Something went wrong!" }));
  }
}

function* validatePlanNameWorker(action) {
  try {
    const res = yield call(validateDraftName, action.payload)
    if (res.data.status) {
      yield put(validatePlanNameSuccess({ data: (res.data) }));
    } else {
      yield put(validatePlanNameError({ error: `Draft Name ${action.payload.planname} Already Exists. Please Enter Another Name` }));
    }
  } catch (error) {
    yield put(validatePlanNameError({ error: "Something went wrong!" }));
  }
}

function* fetchStrategyTableDataWatcher() {
  yield takeLatest(FETCH_STRATEGY_TABLE_DATA, fetchStrategyTableDataWorker);
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* fetchStrategyTableDropdownWatcher() {
  yield takeLatest(
    FETCH_STRATEGY_TABLE_DROPDOWN,
    fetchStrategyTableDropdowWorker
  );
}

function* fetchStoregroupMinStockWatcher() {
  yield takeEvery(FETCH_SG_MIN_STOCK, fetchStoregroupMinStockWorker);
}

function* fetchStoreTableDataWatcher() {
  yield takeEvery(FETCH_STORE_TABLE_DATA, fetchStoreTableDataWorker);
}

function* fetchDcDataWatcher() {
  yield takeLatest(FETCH_DC_DATA, fetchDcDataWorker);
}

function* fetchStrategyTableFromDashboardWatcher() {
  yield takeLatest(
    GET_STRATEGY_TABLE_DATA_FROM_DASHBOARD,
    fetchStrategyTableFromDashboardWorker
  );
}

function* createAllocationWatcher() {
  yield takeLatest(
    CREATE_ALLOCATION,
    createAllocationWorker
  );
}

function* updateResQtyWatcher() {
  yield takeLatest(
    UPDATE_RESERVED_QTY,
    updateResQtyWorker
  );
}

function* saveWatcher() {
  yield takeLatest(
    SAVE,
    saveWorker
  );
}

function* getstoregroupminstockWatcer() {
  yield takeLatest(
    GETSTOREGROUPMINSTOCK,
    getstoregroupminstockWorker
  );
}

// function* getDraftWatcher() {
//   yield takeLatest(
//     GET_DRAFTS,
//     getDraftWorker
//   );
// }

function* saveDraftWatcher() {
  yield takeLatest(
    SAVE_DRAFT,
    saveDraftWorker
  );
}

function* deleteDraftWatcher() {
  yield takeLatest(
    DELETE_DRAFT,
    deleteDraftWorker
  );
}

function* validatePlanNameWatcher() {
  yield takeLatest(
    VALIDATE_PLAN_NAME,
    validatePlanNameWorker
  )
}


export function* strategySaga() {
  yield all([
    fetchStrategyTableDataWatcher(),
    fetchFilterDataWatcher(),
    fetchStrategyTableDropdownWatcher(),
    fetchStoregroupMinStockWatcher(),
    fetchStoreTableDataWatcher(),
    fetchDcDataWatcher(),
    fetchStrategyTableFromDashboardWatcher(),
    createAllocationWatcher(),
    updateResQtyWatcher(),
    saveWatcher(),
    getstoregroupminstockWatcer(),
    saveDraftWatcher(),
    deleteDraftWatcher(),
    validatePlanNameWatcher()
    // getDraftWatcher()
  ]);
}
