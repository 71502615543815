import React, { useState, useEffect, createRef } from "react";
import Modal from "react-awesome-modal";
import "../../ProductStoreStatus.css"
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ReactTable from "../../../../components/Table/ReactTable"
import { useHistory } from "react-router-dom";
import { CREATE_CONFIGURATION } from "../../../../constants/routeConstants";
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const MissingConfigDetail = (props) => {

    const [data, setData] = useState([])

    const history = useHistory()

    const columns = [
        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: "l1_name",
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: "l2_name",
            // Cell: ({ value, row }) =>
            //     <span className={props.noData?.l2_name ? 'red-text' : 'green-text'}>
            //         {value}
            //     </span>
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            // Cell: ({ value, row }) =>
            //     <span className={props.noData?.l3_name? 'red-text' : 'green-text'}>
            //         {value}
            //     </span>

        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: "l4_name",
            // Cell: ({ value, row }) =>
            //     <span className={props.noData?.l4_name ? 'red-text' : 'green-text'}>
            //         {value}
            //     </span>
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: "l5_name",
        },
        {
            Header: "Product Type",
            accessor: "product_type",
            Cell: ({ value, row }) =>
                <span className={row.original.noData ? 'red-text' : 'green-text'}>
                    {value}
                </span>
        },
        {
            Header: "Actions",
            accessor: "actions",
            Cell: (instance) => (
                <div>
                    <button
                        className={"btn btn-outline-secondary btn-sm px-2 rounded-lg text-body " + (instance.row.original.noData ? 'red-btn' : 'green-btn')}
                        onClick={() => { createConfig(instance) }}
                    >
                        Create Config
                        <i
                            className="fa fa-angle-right ml-2"
                            title="Configure"
                            aria-hidden="true"
                        ></i>
                    </button>
                </div>
            ),
            // width: 150,
            disableFilters: true
        },
    ]

    useEffect(() => {
        let data = []
        let productTypes = ["In Season", "Clearance", "New In DC"]
        productTypes.map((item, index) => {
            data.push({
                l1_name: props.filters?.l1_name,
                l2_name: props.filters?.l2_name,
                l3_name: props.filters?.l3_name,
                l4_name: props.filters?.l4_name,
                product_type: item,
                noData: index == 1
            })
        })
        setData(data)
    }, [])

    const createConfig = (ins) => {
        
        history.push(`${CREATE_CONFIGURATION}`, { filters: { product_type: ins.row.original.product_type, ...props.filters }, isCreateMissing: true });
    }


    return (
        <div className="modal__div">
            <Modal
                visible={true}
                width="80%"
                // height="95%"
                effect="fadeInDown"
                onClickAway={props.closeModal}
            >
                {/* <Notification /> */}
                <div className="modal-container">
                    <div className="modal-header">
                        <h5 className="page-heading text-center m-0">
                            Missing Config Details
                        </h5>
                        <button type="button" className="close" data-dismiss="modal">
                            <span style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-times text-right m-2 "
                                    aria-hidden="true"
                                    onClick={props.closeModal}
                                />
                            </span>
                        </button>
                    </div>
                    {/* <PageLoader loader={props.dataLoading} gridLoader={true}> */}
                    <section className="missing-config__table section" style={{ marginTop: "5rem" }}>
                        <ReactTableWithPlugins
                            data={data}
                            columns={columns}
                            renderMarkup='TableMarkup'
                            tableId="product_config_missing_details"
                        />
                    </section>
                    {/* </PageLoader> */}

                    <div className="text-center" style={{ padding: "1rem" }}>
                        <div className="center">
                            <button
                                className="btn btn-primary store-grp-modal__btn"
                                onClick={props.closeModal}
                            >
                                Close
                            </button>

                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}


export default MissingConfigDetail