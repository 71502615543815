import {
    GET_ALLOCATION_PLANS,
    GET_ALLOCATION_PLANS_SUCCESS,
    GET_ALLOCATION_PLANS_ERROR,
    RESET_ALL
} from './ViewAllocationActions'

import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export const viewAllocationState = {
    allocationPlansLoading: false,
    allocationPlans: [],
    allocationPlansError: null
}

export default {
    viewAllocation: handleActions(
        {
            [GET_ALLOCATION_PLANS]: (state) => {
                return immutable(state, {
                    allocationPlansLoading: { $set: true },
                    allocationPlans: { $set: [] },
                });
            },
            [GET_ALLOCATION_PLANS_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                    allocationPlans: { $set: (payload && payload.data) || [] },
                    allocationPlansLoading: { $set: false },
                    allocationPlansError: { $set: null }
                })
            },
            [GET_ALLOCATION_PLANS_ERROR]: (state, { payload }) => {
                return immutable(state, {
                    allocationPlansError: { $set: (payload && payload.message) || null },
                    allocationPlansLoading: { $set: false },
                    allocationPlans: { $set: [] }
                })
            },
            [RESET_ALL]: (state => {
                return immutable(state, {
                    allocationPlansLoading: { $set: false },
                    allocationPlans: { $set: [] },
                    allocationPlansError: { $set: null }
                })
            })

        },
        viewAllocationState
    )
}