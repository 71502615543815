import { intersectionWith, isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MultiSelect from "../../../components/Filters/MultiSelect";
import Select from "../../../components/Filters/SingleSelect";
import Notification from "../../../components/Notification/Notifications";
import Notify from "../../../components/Notification/Notifications";
import { getUserPreferenceFilter, setUserPreferenceFilter } from "../../../utils/commonUtilities";
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping";
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import { fetchFilterData, resetFiltersOptions } from "../../Constraints/ConstraintsAction";
import { PRODUCT_DIMENSTION, STORE_DIMENSTION} from "./ConstraintsConfigConstants"

const ConstraintsConfigFilters = React.memo((props) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [rbu, setRbu] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [style, setStyle] = useState(null);
    const [colour, setColour] = useState(null);
    const [size, setSize] = useState(null);
    const [storeGrade, setStoreGrade] = useState(null)
    const [request,setRequest] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [rbuOptions, setRbuOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [styleOptions, setStyleOptions] = useState(null);
    const [colourOptions, setColourOptions] = useState(null);
    const [sizeOptions, setSizeOptions] = useState(null);
    const [storeGradeOptions, setStoreGradeOptions] = useState(null);

    useEffect(() => {
      setDepartmentOptions(() => props.departmentOptions);
    }, [props.departmentOptions]);
    

    useEffect(() => {
      setGenderOptions(() => props.genderOptions);
    }, [props.genderOptions]);
    
    useEffect(() => {
     setRbuOptions(() => props.rbuOptions);
    }, [props.rbuOptions]);
    
    useEffect(() => {
      setDCSOptions(() => props.dcsOptions);
    }, [props.dcsOptions]);
    
    useEffect(() => {
      setStyleOptions(() => props.styleOptions);
    }, [props.styleOptions]);
    
    useEffect(() => {   
      setColourOptions(() => props.colourOptions);
    }, [ props.colourOptions]);
    
    useEffect(() => {
      setSizeOptions(() => props.sizeOptions);
    }, [props.sizeOptions]);

    useEffect(() => {
      setStoreGradeOptions(() => props.storeGradeOptions);
    }, [props.storeGradeOptions]);
    
    useEffect(() => {
      getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, onDepartmentChange)
      props.fetchFilterData({},{key:'l1'});
    }, []);

    const resetRestFilters = (p_index) => {
      colour && setColour(null);
      style && setStyle(null);
      size && setSize(null);
      storeGrade && setStoreGrade(null);
      colourOptions && setColourOptions(null)
      styleOptions && setStyleOptions(null)
      sizeOptions && setSizeOptions(null)
      storeGradeOptions && setStoreGradeOptions(null)
      if (p_index == 0) {
        gender && setGender(null);
        genderOptions && setGenderOptions(null)
      }
      if (p_index == 0 || p_index == 1) {
        rbu && setRbu(null);
        rbuOptions && setRbuOptions(null)
      }
      if (p_index == 0 || p_index == 1 || p_index == 2) {
        dcs && setDCS(null);
        dcsOptions && setDCSOptions(null)
      }
    };

    const onDepartmentChange = (val) => {
      setRequest(old => {
      return {
        ...old,
        'level2': null,
        'level3': null,
        'level4': null,
        'style': null,
        'color': null,
        'size': null,
        'storeGrade': null,
      }
      })
      setDepartment(val);
      resetRestFilters(0);
      props.fetchFilterData({ level1: [val] },{key:'l2'});
      props.fetchFilterData(
        { level1: [val] },
        {key:'l5'},"style"
      );
      props.fetchFilterData(
        { level1: [val] },
        {key:'l5'},"color"
      );
      props.fetchFilterData(
        { level1: [val] },
        {key:'l5'},"size"
      );
      props.fetchFilterData(
        { level1: [val] },
        {key:'l5'},"store_grade"
      );
    };
    const onGenderChange = (val) => {
      setGender(val);
      setRequest(old => {
        return {
          ...old,
          'level2': val,
          'level3': null,
          'level4': null,
          'style': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      resetRestFilters(1);
      props.fetchFilterData({ level1: [department], level2: [val] },{key:'l3'});
      props.fetchFilterData(
        { level1: [department], level2: [val] },
        {key:'l5'},"style"
      );
      props.fetchFilterData(
        { level1: [department], level2: [val] },
        {key:'l5'},"color"
      );
      props.fetchFilterData(
        { level1: [department], level2: [val] },
        {key:'l5'},"size"
      );
      props.fetchFilterData(
        { level1: [department], level2: [val] },
        {key:'l5'},"store_grade"
      );
    };
    const onRbuChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      setRbu(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'level3': valuesArray[0],
          'level4': null,
          'style': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      let req_val = intersectionWith(valuesArray[0], rbuOptions, isEqual);
      resetRestFilters(2)
      if (valuesArray[0].length) {  
        let req = {
          level1: [department],
          level3: [req_val]
        }
        if(gender) {
          req['level2'] = [gender]
        }
        props.fetchFilterData(req,{key:'l4'});
        props.fetchFilterData(
          req,
          {key:'l5'},"style"
        );
        props.fetchFilterData(
          req,
          {key:'l5'},"color"
        );
        props.fetchFilterData(
          req,
          {key:'l5'},"size"
        );
        props.fetchFilterData(
          req,
          {key:'l5'},"store_grade"
        );
      }
    };

    const onDCSChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      setDCS(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'level4': valuesArray[0],
          'style': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
      resetRestFilters(3)
      if (valuesArray[0].length) {  
        let req = {
          level1: [department],
          level4: [req_val]
        }
        if(gender) {
          req['level2'] = [gender]
        }
        if(rbu) {
          req['level3'] = [rbu]
        }
  
        props.fetchFilterData(
          req,
          {key:'l5'},"style"
        );
        props.fetchFilterData(
          req,
          {key:'l5'},"color"
        );
        props.fetchFilterData(
          req,
          {key:'l5'},"size"
        );
        props.fetchFilterData(
          req,
          {key:'l5'},"store_grade"
        );
      }
    };

    const onStyleChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      setRequest(old => {
        return {
          ...old,
          'style': valuesArray[0]
        }
      })
      setStyle(valuesArray[1]);
    };
    const onColourChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      setRequest(old => {
        return {
          ...old,
          'color': valuesArray[0]
        }
      })
      setColour(valuesArray[1]);
    };
    const onSizeChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      setRequest(old => {
        return {
          ...old,
          'size': valuesArray[0]
        }
      })
      setSize(valuesArray[1]);
    };
    const onStoreGradeChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      setRequest(old => {
        return {
          ...old,
          'storeGrade': valuesArray[0]
        }
      })
      setStoreGrade(valuesArray[1]);
    };

    const applyFilters = () => {
      if (department) {
        setUserPreferenceFilter({"user_id":localStorage.getItem("user_id"),"department":department})
        props.applyFilters({
          department,
          gender: request?.level2,
          rbu: request?.level3,
          dcs: request?.level4,
          style: request?.style,
          colour: request?.color,
          size: request?.size,
          store_grade: request?.storeGrade
        });
      } else {
        Notify.error("Please Select All Mandatory Options!!");
      }
    };

    const resetFilters = () => {
      setRequest(null)
      setDepartment(null);
      setGender(null);
      setRbu(null);
      setDCS(null);
      setStyle(null);
      setColour(null);
      setSize(null);
      props.resetTableData();
      props.resetFiltersOptions([]);
      props.setFilters(null)
    };

    return (
      <>
        <div className="filter__heading fnt-lg fnt-bold"> 
          {PRODUCT_DIMENSTION}
        </div>
        <section className="row section" style={{margin:"0.5rem 1rem"}}>
          <Notification/>
          <div className="col-md-3 required">
            <label className="fnt-md fnt-bold fnt-bold">{levelFiltersLabelFormatter("level1")}</label>
            <Select
              name="department"
              value={department}
              onChange={onDepartmentChange}
              placeHolder={`Select ${levelFiltersLabelFormatter("level1")}`}
              options={departmentOptions?.length ? departmentOptions : []}
            />
          </div>
          <div className="col-md-3 fill">
            <label className="fnt-md fnt-bold">{props.filterLabels?.level2}</label>
            <Select
              name="gender"
              value={gender}
              onChange={onGenderChange}
              placeHolder={`Select ${props.filterLabels?.level2}`}
              options={genderOptions?.length ? genderOptions : []}
            />
          </div>
          <div className="col-md-3 fill">
            <label className="fnt-md fnt-bold">{props.filterLabels?.level3}</label>
            <MultiSelect
              name="rbu"
              dropdownValue={rbu}
              onDropDownChange={onRbuChange}
              placeHolder={`Select ${props.filterLabels?.level3}`}
              optionsArray={rbuOptions?.length ? rbuOptions : []}
            />
          </div>
          <div className="col-md-3 fill">
            <label className="fnt-md fnt-bold">{props.filterLabels?.level4}</label>
            <MultiSelect
              name="dcs"
              dropdownValue={dcs}
              onDropDownChange={onDCSChange}
              placeHolder={`Select ${props.filterLabels?.level4}`}
              optionsArray={dcsOptions?.length ? dcsOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Style</label>
            <MultiSelect
              name="style"
              dropdownValue={style}
              onDropDownChange={onStyleChange}
              placeHolder={"Select Style"}
              optionsArray={styleOptions?.length ? styleOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Color</label>
            <MultiSelect
              name="colour"
              dropdownValue={colour}
              onDropDownChange={onColourChange}
              placeHolder={"Select Color"}
              optionsArray={colourOptions?.length ? colourOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Size</label>
            <MultiSelect
              name="size"
              dropdownValue={size}
              onDropDownChange={onSizeChange}
              placeHolder={"Select Size"}
              optionsArray={sizeOptions?.length ? sizeOptions : []}
            />
          </div>
        </section>
        <hr style={{margin:0}}/>
          <div className="filter__heading fnt-lg fnt-bold"> 
              {STORE_DIMENSTION}
          </div>
          <section className="row section" style={{margin:"0.5rem 1rem"}}>
            <div className="col-md-3">
              <label className="fnt-md fnt-bold">Store Group</label>
              <MultiSelect
                name="storeGrade"
                dropdownValue={storeGrade}
                onDropDownChange={onStoreGradeChange}
                placeHolder={"Select Store Grade"}
                optionsArray={storeGradeOptions?.length ? storeGradeOptions : []}
              />
            </div>
            <div className="col-md-3">
              <label className="fnt-md fnt-bold">Store Grade</label>
              <MultiSelect
                name="storeGrade"
                dropdownValue={storeGrade}
                onDropDownChange={onStoreGradeChange}
                placeHolder={"Select Store Grade"}
                optionsArray={storeGradeOptions?.length ? storeGradeOptions : []}
              />
            </div>
            <div className="col-md-3">
              <label className="fnt-md fnt-bold">Store</label>
              <MultiSelect
                name="storeGrade"
                dropdownValue={storeGrade}
                onDropDownChange={onStoreGradeChange}
                placeHolder={"Select Store Grade"}
                optionsArray={storeGradeOptions?.length ? storeGradeOptions : []}
              />
            </div>
            <div className="col-md-3">
              <button
                onClick={applyFilters}
                className="btn btn-primary filter-constraints-mode"
                title="Apply filters"
              >
                <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
              </button>
              <button
                onClick={() => resetFilters("All")}
                className="btn undo-constraints-mode"
                title="Reset filters"
              >
                <i className="fa fa-undo mr-2"></i>Reset
              </button>
            </div>
        </section>
      </>
    );
  }
);

const mapStateToProps = ({ constraints }) => ({
  filterLabels: constraints.filterlabels,
  departmentOptions: constraints.departmentOptions,
  genderOptions: constraints.genderOptions,
  rbuOptions: constraints.rbuOptions,
  dcsOptions: constraints.dcsOptions,
  styleOptions: constraints.styleOptions,
  colourOptions: constraints.colourOptions,
  sizeOptions: constraints.sizeOptions,
  storeGradeOptions : constraints.storeGradeOptions
});

const mapDispatchToProps = (dispatch) => ({
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  fetchFilterData: (payload,filterKey,filterType) =>
    dispatch(fetchFilterData(payload,filterKey,filterType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConstraintsConfigFilters);