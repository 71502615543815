import React from 'react'
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import StyleInventory from '../StyleInventory/StyleInventory';

const ReviewRecommendation = ({ showAlert, closeAlert, data, filters }) => {
    return (
        <div className="modal_opacity">
        <ModalComponent
            showAlert={showAlert}
            closeAlert={closeAlert}
            width="90%"
        >
            <div className="modal-container" >
                <div className="modal-header">
                    <div className="d-flex justify-content-between"
                        style={{ width: '100%' }}
                    >
                        <div>
                            <h5 className="page-heading m-0">Review Recommendation</h5>
                        </div>
                        <div>
                            <button type="button" className="close" data-dismiss="modal">
                                <i
                                    className="fa fa-times text-right m-2 "
                                    aria-hidden="true"
                                    onClick={closeAlert}
                                    style={{ cursor: "pointer" }}
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="modal-middle-container modal-body"
                    style={{padding:'auto',}}
                >
                    <StyleInventory 
                        data={data}
                        filters={filters}
                    />
                </div>
            </div>
        </ModalComponent>
        </div>
    )
}

export default ReviewRecommendation
