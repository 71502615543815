export const GET_LOST_SALES_FILTER_REQUEST = "@@reporting/GET_LOST_SALES_FILTER_REQUEST";
export const GET_LOST_SALES_FILTER_FAILED = "@@reporting/GET_LOST_SALES_FILTER_FAILED";
export const GET_LOST_SALES_FILTER_SUCCESS = "@@reporting/GET_LOST_SALES_FILTER_SUCCESS";
export const GET_LOST_SALES_REPORT_ALL_REQUEST = "@@reporting/GET_LOST_SALES_REPORT_ALL_REQUEST";
export const GET_LOST_SALES_REPORT_ALL_FAILED = "@@reporting/GET_LOST_SALES_REPORT_ALL_FAILED";
export const GET_LOST_SALES_REPORT_ALL_SUCCESS = "@@reporting/GET_LOST_SALES_REPORT_ALL_SUCCESS";
export const GET_LOST_SALES_REPORT_DETAILS_REQUEST = "@@reporting/GET_LOST_SALES_REPORT_DETAILS_REQUEST";
export const GET_LOST_SALES_REPORT_DETAILS_FAILED = "@@reporting/GET_LOST_SALES_REPORT_DETAILS_FAILED";
export const GET_LOST_SALES_REPORT_DETAILS_SUCCESS = "@@reporting/GET_LOST_SALES_REPORT_DETAILS_SUCCESS";
export const RESET_LOST_SALES_REPORT = "@@reporting/RESET_LOST_SALES_REPORT";
export const SET_LOST_SALES_REQ_BODY = "@@reporting/SET_LOST_SALES_REQ_BODY";
export const RESET_LOST_SALES_FILTER = "@@reporting/RESET_LOST_SALES_FILTER";