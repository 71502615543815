import React, { useEffect, useState } from "react"
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import Modal from "react-awesome-modal";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

function RecommendedActionItemModal(props) {
    
    const { showModal, closeModal, data, columns, type, status } = props

    const [initialHiddenColumns, setHiddenColumns] = useState([])
    const [intialColumns , setColumns] = useState([])

    useEffect(() => {
        if(columns.length){
            setColumns(columns)
        }
    },[columns])

    useEffect(() => {
        console.log(type,status,'vvdfvdfvd')
        if (type === "ASN" && status === "Pending") {
            setHiddenColumns(["quantity_variance", "latest_receipt_date","creation_date",...props.hidableColumnsForPopUp])
        }
        else if (type === "POASN" && status === "Mismatch") {
            setHiddenColumns(["asn_id","creation_date",...props.hidableColumnsForPopUp])
        }
        else if (type === "PO" && status === "Pending") {
            setHiddenColumns(["asn_id","quantity_variance", "latest_receipt_date","creation_date",...props.hidableColumnsForPopUp])
        }
        else if (type === "POASN" && status === "Error") {
            setHiddenColumns(["asn_id","creation_date",...props.hidableColumnsForPopUp])
        }
    }, [type, status])


    return (
        <div className="modal__div modal_opacity">
            <Modal
                visible={showModal}
                width="80%"
                effect="fadeInDown"
                onClickAway={closeModal}
            >
                <div className="modal-container">
                    <div className="modal-header">
                        {/* <h5 className="page-heading text-center m-0">
                            PO ID: {poData.po}
                        </h5> */}
                        <button type="button" className="close" data-dismiss="modal">
                            <span style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-times text-right m-2 "
                                    aria-hidden="true"
                                    onClick={closeModal}
                                />
                            </span>
                        </button>
                    </div>
                    <div className="modal-middle-container modal-body">
                        <div className="mx-2 p-2">
                        <ReactTableWithPlugins
                            shouldPagination
                            data={(data ? data : [])}
                            columns={intialColumns}
                            renderMarkup='TableMarkup'
                            keyRT= 'sortAndSearch'
                            hideColumnsFilter
                            initialHiddenColumns={initialHiddenColumns}
                            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: 4 }}
                            // tableId="po_table"
                        />
                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default RecommendedActionItemModal
