import { isEmpty } from "lodash"
import Api from "../configs/api_config"
import { GET_USER_PREFERENCE, SAVE_USER_PREFERENCE_COLUMNS, SET_USER_PREFERENCE, UPDATE_TOKEN } from "../constants/apiConstants"
import moment from "moment";
import ColumnFilter from "../containers/UserManagement/ColumnFilter";
import * as StoreDetailsConsts from '../containers/NewStoreSetUp/views/StoreDetailsConfig/StoreDetailsDcConfigConstants';
import axios from "axios";
import jwt_decode from 'jwt-decode'
import * as Notify from "../components/Notification/Notifications";


const clientIds = [2, 3, 4, 5, 6]
export const currentClientName = process.env.REACT_APP_CLIENT
export const clientEnvId = process.env.REACT_APP_ENV_ID

export const scrollIntoView = (ref) => {
    ref?.current?.scrollIntoView({
        behavior: "smooth",
    })
}

export const dateFormat = (val, format = "MM-DD-YYYY") => {
    return val ? moment(val).format(format) : ""
}

export const appendPerc = (val) => isNaN(val) ? val : val + '%'


export const setUserPreferenceFilter = async (p_req) => {
    let getFilter = JSON.parse(localStorage.getItem("user_preference"))
    if (getFilter && !isEmpty(getFilter.filter) && getFilter.user_id == p_req.user_id && getFilter.filter.value == p_req.department.value) {
        return;
    }
    else {
        let response = await Api.post(SET_USER_PREFERENCE, { "user_id": p_req.user_id, "department": p_req.department.value })
        let prepData = { "user_id": p_req.user_id, "filter": p_req.department }
        localStorage.setItem("user_preference", JSON.stringify(prepData))
    }
}

export const getUserPreferenceFilter = async (p_req, callBack) => {
    let getFilter = JSON.parse(localStorage.getItem("user_preference"))
    if (getFilter && !isEmpty(getFilter.filter) && getFilter.user_id == p_req.user_id) {
        callBack(getFilter.filter)
    }
    else {
        let response = await Api.post(GET_USER_PREFERENCE, p_req)
        let prepData = { ...p_req, "filter": { label: response?.data?.data?.[0]?.['department'], value: response?.data?.data?.[0]?.['department'] } }
        localStorage.setItem("user_preference", JSON.stringify(prepData))
        if (response?.data?.data && response?.data?.data?.[0]?.['department']) {
            callBack({ label: response?.data?.data?.[0]?.['department'], value: response?.data?.data?.[0]?.['department'] })
        }
    }
}

export const removeUserPreference = async (p_req) => {
    let getFilter = JSON?.parse(localStorage.getItem("user_preference"))
    let filter =  getFilter?.filter?.value;
    if(!p_req?.includes(filter)) {
        localStorage.removeItem("user_preference")
    }
    return;
}

export const hideToProd = () => {
    return !clientIds.includes(Number(clientEnvId))
}

export const role = {
    super_user: 1,
    admin: 2,
    allocator: 3
}

export const getUserRole = (...roles) => {
    return roles.map(user_role => role[user_role])
}

export const USER_ROLE_CONSTANTS = {
    SUPER_USER: "super_user",
    ADMIN: "admin",
    ALLOCATOR: "allocator"
}

// List of Columns in the table to be hidden for clients

export const HIDE_TABLE_COLUMNS = {
    "puma": ["l5_name", "level5", "dummyId","po_number","asn","source","released_by","released_date_picked",
    "released_time","article_status_tag"],
    "pandora": ["dummyId", "region", "district", "l3_name", "l4_name", "l5_name", "level3", "level4", "level5", "subcategory", "dcs","po_number","asn","source","released_by","released_date_picked","released_time","article_status_tag"],
    "ashley": ["l5_name", "level5", "dummyId","season_code_active"]
}

// List of Filters to be hidden for clients

export const HIDABLE_FILTERS = {
    "puma": ["l5_name"],
    "pandora": ["l3_name", "l4_name", "l5_name", "region", "district", "season_code", "dc"],
    "ashley": ["dc", "store_grade_source", "l5_name","season_code_active"],
}

export const HIDABLE_FILTERS_MODULE = {
    "puma": { "storeEligibilityGroup": ["l2_name", "l3_name", "l4_name", "l5_name"] },
    "pandora": { "storeEligibilityGroup": ["l2_name", "l3_name", "l4_name", "l5_name"] },
    "ashley": { "storeEligibilityGroup": ["l5_name"] },
}

// List of Columns to be hidden for clients in specific table based on tableID 

export const HIDABLE_COLUMNS_FOR_CLIENTS_BASED_ON_TABLEID = {
    "puma": {  },
    "pandora": {  },
    "ashley": { "finalize_store_details": [{"label": "Store Grade", "value": "store_grade"}] },
    "coach": {  },
}

// List of Tabs to be hidden for clients

export const HIDABLE_TABS = {
    1: [],
    2: ["newstore", "strategyConfig"],
    3: [],
    4: [],
    5: [],
    6: ["newstore"],
}


// List of Level Filters for clients

export const LEVEL_FILTER_LABELS = {
    "pandora": [{ label: "Department", key: "department" }, { label: "Sub-Department", key: "sub_department" }],
    "puma": [{ label: "Department", key: "department" }, { label: "Gender", key: "gender" },
    { label: "Sub-Category", key: "subcat" }, { label: "DCS", key: "dcs" }],
    "ashley": [{ label: "Department", key: "department" }, { label: "Sub-Department", key: "sub_department" },
    { label: "Class", key: "class" }, { label: "Sub-Class", key: "sub_class" }]
}

// List of Level Filter Column Names in table for clients

export const LEVEL_FILTER_COLUMN_NAMES = {
    "puma": {
        level1: "Department",
        level2: "Gender",
        level3: "Sub Category",
        level4: "DCS",
    },
    "pandora": {
        level1: "Department",
        level2: "Sub-Department",
    },
    "ashley": {
        // level1: "Department Grouping",
        level1: "Department",
        level2: "Sub-Department",
        level3: "Class",
        level4: "Sub-Class"
    }
}

// List of Store Grade Columns clients

export const STORE_GRADE_COLUMNS = {
    "pandora": {
        Header: 'Store Grade',
        accessor: 'store_grade_ft',
        width: 210,
        Filter: ColumnFilter,
        filter: 'exactText'
    },
    "ashley": {
        Header: 'Store Grade',
        accessor: 'store_grade_ft',
        width: 210,
        Filter: ColumnFilter,
        filter: 'exactText'
    },
    "puma": {
        Header: "Store Grade",
        columns: [
            {
                Header: "Accessories",
                accessor: 'store_grade_ac',
                Filter: ColumnFilter,
                width: 120,
                filter: 'exactText',
            },
            {
                Header: "Apparel",
                accessor: 'store_grade_ap',
                Filter: ColumnFilter,
                width: 120,
                filter: 'exactText',
            },
            {
                Header: "Footwear",
                accessor: 'store_grade_ft',
                Filter: ColumnFilter,
                width: 120,
                filter: 'exactText',
            },
        ],
    }
}

// func to check if filter can be hidden for current client

export const shouldHideForCurrentClient = (p_level, p_module) => {
    let hide;
    if (p_module) {
        hide = HIDABLE_FILTERS_MODULE?.[currentClientName]?.[p_module]?.includes(p_level);
    }
    else {
        hide = HIDABLE_FILTERS?.[currentClientName]?.includes(p_level);
    }
    return hide;
}

export const hideTabForClientID = (p_tab) => {
    let hide;
    hide = HIDABLE_TABS?.[clientEnvId]?.includes(p_tab)
    return hide;
}

export const getLabel = (p_label) => {
    let labelMapping = {
        "season_code_open" : {
            "ashley" : "Season Code"
        }
    }
    return (labelMapping?.[p_label]?.[currentClientName] || "Season Code Open")
}

// func to get the list of table columns to be hidden for current client
export const getHideColumns = (p_initialHidenColumns, tableId, hideColumnsFilter) => {
    let hideColumns = HIDE_TABLE_COLUMNS[currentClientName]
        ? HIDE_TABLE_COLUMNS[currentClientName]
        : [];
    hideColumns = hideColumns.concat(p_initialHidenColumns);
    return hideColumns.concat(getUserHiddenColumns(1, tableId, hideColumnsFilter));
}

export const getDefaultHiddenColumns = (tableId) => {
    let globalHiddenColumns = HIDE_TABLE_COLUMNS[currentClientName]
    ? HIDE_TABLE_COLUMNS[currentClientName]
    : [];
    let localHiddenColumns = HIDABLE_COLUMNS_FOR_CLIENTS_BASED_ON_TABLEID[currentClientName]?.[tableId]?.map(col => col.value) || []
    return globalHiddenColumns.concat(localHiddenColumns)
}

// func to get store grade columns to be shown for current client

export const getStoreGradeColumnNamesByClient = () => {
    return STORE_GRADE_COLUMNS[currentClientName]
}

// func to get capacity columns of current client

export const getCapacityColumnsByClient = () => {
    const CAPACITY_COLUMNS = {
        "puma": [{
            Header: StoreDetailsConsts.CAPACITY_AP_FIELD_LABEL,
            accessor: 'ap_capacity',
            width: 120,
        },
        {
            Header: StoreDetailsConsts.CAPACITY_FT_FIELD_LABEL,
            accessor: 'ft_capacity',
            width: 130,
        },
        {
            Header: StoreDetailsConsts.CAPACITY_ACC_FIELD_LABEL,
            accessor: 'ac_capacity',
            width: 110,
        }],
        "ashley": {
            Header: "Capacity",
            accessor: 'size',
            width: 120,
        },
        "pandora": {
            Header: "Capacity",
            accessor: 'size',
            width: 120,
        }
    }
    return CAPACITY_COLUMNS[currentClientName]

}

// func to get level filters of current client

export const getLevelFilterLabelsForCurrentClient = () => {
    return LEVEL_FILTER_LABELS[currentClientName]
}

//func to capitalize the first letter of a string

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isAshley = () => {
    return process.env.REACT_APP_CLIENT === "ashley"
}

export const hideFeatureForCurrentClient = (feature) => {
    const featureObj = {
        "puma": ["demand_type", "finalize_csv", "InvComponent", "packs", "upload_file"],
        "pandora": ["demand_type", "finalize_csv", "InvComponent", "packs", "upload_file"],
        "ashley": ["finalize_excel", "userLevelAccess", "retailProId", "style_inventory"]
    }
    return featureObj[currentClientName]?.includes(feature)
}

//SSO login check

export const isSSOLogin = () => {
    let ssoLogin_clients_ids = [2,4]
    return ssoLogin_clients_ids?.includes(Number(clientEnvId))
        // && process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
        // ? false
        // : false;
}

export const isApplicationLogin = () => {
    let appLogin_clients_ids = [1,2,3,4,5,6] 
    return appLogin_clients_ids?.includes(Number(clientEnvId))
        // && process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
        // ? false
        // : false;
}

export const callRefreshTokenApi =  () => {
    let headers = {authorizationkey: localStorage.getItem('access_token')}
    return axios.post(UPDATE_TOKEN,{token:localStorage.getItem('access_token')}, {headers: headers})
}

export const nextStep = () => {
    const isActive = sessionStorage.getItem("isActive");
    if(isActive === "true") {
        return 'refresh'
    }
    
    const jwt = localStorage.getItem("access_token");

    if (jwt) {
      const decodedJwt = jwt_decode(jwt)

      if (decodedJwt.exp * 1000 < Date.now()) {
          return 'logout';
      }
      else {
        return 'doNothing';
      }
    }
    return 'doNothing';
}

export const logoutUser = () => {
    localStorage.clear()
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("group_id");
    // localStorage.removeItem("userName");
    // localStorage.removeItem("user_id");
    // localStorage.removeItem("email");
    // localStorage.removeItem("user_preference");
    // localStorage.removeItem("column_preference")

    sessionStorage.removeItem("isActive")
}

export const setLocalStorage = (token) => {
    localStorage.setItem('access_token', token);

    let decoded = jwt_decode(token)

    localStorage.setItem('group_id', decoded.group_id)
    localStorage.setItem('userName', decoded.name)
    localStorage.setItem('email', decoded.email)
    localStorage.setItem('user_id', decoded.user_id)
    localStorage.setItem('retail_pro_id', decoded.retail_pro_id)
}

export const getUserHiddenColumns = (type, tableId, hideColumnsFilter) => {
    // let userHiddenCols = JSON.parse(localStorage.getItem("hiddenColumns"))?.map(col => col.value) || []
    // if(columns) {
    //     columns = columns.map(col => col?.accessor)
    //     return columns?.filter(item => userHiddenCols?.includes(item?.value)) || []

    // }
    let hidableColumns =  HIDABLE_COLUMNS_FOR_CLIENTS_BASED_ON_TABLEID?.[currentClientName]?.[tableId] || []
    let colPref = JSON.parse(localStorage.getItem("column_preferences"))?.["hiddenColumns"] || []
    let userHiddenCols = colPref?.[tableId] ? colPref[tableId] : colPref?.["global"] 
    userHiddenCols = (userHiddenCols || []).concat(hidableColumns)
    return hideColumnsFilter ? [] : (type === 1 ? (userHiddenCols?.map(col => col.value) || [])
        : (userHiddenCols || []))
}

// export const getUserPrefrenceColumns = () => {
//     return JSON.parse(localStorage.getItem("column_preference"))
// }

export const saveColumnOrderApi = async(column_preferences) => {
    let response = await Api.post(SAVE_USER_PREFERENCE_COLUMNS,
        {
            user_id: localStorage.getItem("user_id"),
            column_preferences: [column_preferences]
        })
        if (response?.data?.status) {
            setTimeout(() => {
                Notify.success("Column order saved successfully!");
            }, 200)
        }
        return response
}
export const reloadPageWithUpdateToken = async () => {
    // try{
    let res = await callRefreshTokenApi();
    if (res.data.status) {
      setLocalStorage(res?.data?.token);
      setTimeout(() => {
        window.location.reload();
      }, 200);
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
    // else{
    //   redirectToLogin();
    // }
    // }
    // catch{
    //   redirectToLogin();
    // }
  };
