import React, { useEffect, useState } from 'react'
import '../../ProductStoreStatus.css'
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ProductStatusFilters from "../ProductStatusFilters"
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import PageLoader from '../../../../components/Loader/PageLoader';
import EditIconCell from "../../../../components/Table/Cellrenderer/EditIconCell";
import { HIDE_TABLE_COLUMNS, currentClientName } from "../../../../utils/commonUtilities"

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StoreGroupMappingTable = (props) => {
    const [keyRT, setKeyRT] = useState("finalize")
    const [initialHiddenColumns, setInitialHiddenColumns] = useState([])

    const tableColumns = React.useMemo(() => [
        {
            id: 'expander', // Make sure it has an ID
            Header: 'Store Group',
            accessor: 'store_group_name',
            Cell: ({ value, row }) =>
                // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                // to build the toggle for expanding a row
                row.canExpand ? (
                    <div className="row"
                        {...row.getToggleRowExpandedProps()}
                    >
                        <span
                            className="expand__text"
                        >
                            {value}
                        </span>
                        {
                            row.isExpanded ? (
                                <div
                                    className="cursor-pointer"
                                    style={{ color: "#50778e" }}
                                >
                                    <i className="fa fa-angle-down fa-lg ml-2 expand-collapse" title="Collpase" ></i>
                                </div>) :
                                <div
                                    className="cursor-pointer"
                                    style={{ color: "#50778e" }}
                                >
                                    <i className="fa fa-angle-right fa-lg ml-2 expand-collapse" title="Expand" ></i>
                                </div>
                        }
                    </div>
                ) : <span style={{ backgroundColor: "rgb(224,224,224)", minHeight: "100%", minWidth: "100%" }}> </span>,
            width: 250,
            Filter: ColumnFilter
        },
        {
            Header: '#Stores',
            accessor: "store_count",
            Cell: ({ value, row }) =>
                row.canExpand ? (
                    <span
                        {...row.getToggleRowExpandedProps()}
                    >
                        {value}
                    </span>
                ) : <span style={{ backgroundColor: "rgb(224,224,224)", minHeight: "100%", minWidth: "100%" }}> </span>,
            width: 100,
            Filter: ColumnFilter
        },
        {
            Header: 'Store Number',
            accessor: "store_code",
            Filter: ColumnFilter
        },
        {
            Header: 'Store Name',
            accessor: "store_name",
            Filter: ColumnFilter,
            width: 200
        },
        // {
        //     Header: 'Store Grade',
        //     accessor: "storeGrade",
        //     Filter: ColumnFilter
        // },
        {
            Header: 'Region Description',
            accessor: "region",
            width: 250,
            Filter: ColumnFilter
        },
        // {
        //     Header: "Actions",
        //     accessor: "actions",
        //     sticky: "right",
        //     Cell: (instance) => (
        //         <div>
        //             {
        //                 instance.row.canExpand &&
        //                 <button className="btn-primary-rounded create-config-btn add-action-btn">
        //                     +
        //                 </button>
        //             }
        //             {/* {
        //                 instance.row.canExpand &&
        //                 <button className="btn-primary-rounded create-config-btn cross-action-btn">
        //                     x
        //                 </button>
        //             } */}
        //         </div>
        //     ),
        //     disableFilters: true,
        //     resizable: true,
        //     width: 100,
        // },
    ])

    useEffect(() => {
        let hiddenColumns = HIDE_TABLE_COLUMNS[currentClientName]
        hiddenColumns && setInitialHiddenColumns(hiddenColumns)
    }, [])

    const handleEdit = () => {
        console.log("Edit")
    }

    useEffect(() => {
        if(props.isAddActionColumn) {
            setKeyRT("sortAndSearch")
        }
    }, [props.isAddActionColumn])

    return (
        <ReactTableWithPlugins
            initialHiddenColumns={initialHiddenColumns}
            shouldPagination
            totalRecordsLen={props.data ? props.data.length : 0}
            data={(props.data ? props.data : [])}
            columns={props?.isAddActionColumn ? tableColumns : tableColumns.slice(0, tableColumns.length - 1)}
            // features={!props?.isAddActionColumn ? ["CHECKBOX_FOR_FIRSTCOLUMN"] : []}
            features={["CHECKBOX_FOR_FIRSTCOLUMN"] }
            renderMarkup='TableMarkup'
            keyRT={props.hideCheckBox ? "sortAndSearch" : "storeMapping"}
            containSubRow={true}
            initialSelectedRows={props.initialSelectedRows}
            tableWrapperStyle={{ height: 'fit-content', maxHeight: '30rem' }}
            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: 4 }}
            getInstanceOnMount={(instance) => {
                props.setRTinstance(instance)
            }}
            tableId="product_config_store_grouping"
        />
    )
}

export default StoreGroupMappingTable