import { handleActions, combineActions } from "redux-actions";
import immutable from "immutability-helper";
import { styleIndex } from "../Constraints/Constraints"
import { cloneDeep } from "lodash";  
import { FETCH_FILTER_SUCCESS,
         FETCH_FILTER_DATA,
         FETCH_FILTER_ERROR,
         RESET_FILTERS_DATA, 
         FETCH_STORE_GROUP_DATA_SUCCESS, 
         FETCH_STORE_GROUP_DATA,
         FETCH_STORE_GRADE_DATA_SUCCESS, 
         FETCH_STORE_GRADE_DATA,
         FETCH_STORE_DATA_SUCCESS, 
         FETCH_STORE_DATA,
         FETCH_CONSTRAINTS_POPUP_ERROR,
         FETCH_CONSTRAINTS_POPUP_SUCCESS,
         FETCH_CONSTRAINTS_POPUP,
         UPDATE_TABLEDATA_ERROR,
         UPDATE_TABLEDATA,
         UPDATE_TABLEDATA_SUCCESS,
         FETCH_STORE_GROUP_DATA_ERROR,
         FETCH_STORE_GRADE_DATA_ERROR,
         FETCH_STORE_DATA_ERROR,
         RESET_ALL,
         FETCH_MIN_PER_STORE,
         FETCH_MIN_PER_STORE_SUCCESS,
         FETCH_MIN_PER_STORE_ERROR,
         SET_PREV_STOREGROUP_DATA,
         RESET_STYLE_INDEX,
         } from "./ConstraintsAction";

const RESET_FETCH_FILTERS_DATA = combineActions(RESET_FILTERS_DATA,FETCH_FILTER_DATA)

const getPageIndex = (p_row) => {
  let sum = p_row?.length
  p_row.forEach(val => {
    sum += val.subRows.length
  })
  return sum;
}

export const constraintsState = {
  storeGroupData: [],
  storeGradeData: [],
  storeData: [],
  storeGroupError: false,
  storeGradeError: false,
  storeError: false,
  popUpError: false,
  storeGrouploading: false,
  storeGradeloading: false,
  storeloading: false,
  departmentOptions: [],
  totalMinimum: null,
  genderOptions: [],
  rbuOptions: [],
  dcsOptions: [],
  level5Options: [],
  styleOptions: [],
  colourOptions: [],
  sizeOptions: [],
  storeGradeOptions: [],
  popUpData: [],
  popUpLoader: false,
  isUpdated: false,
  error: false,
  filterlabels : {},
  storeTotalCount: null,
  storeGroupTotalCount: null,
  storeGradeTotalCount: null,
  storeIndex: 0,
  storeGroupIndex: 0,
  storeGradeIndex: 0,
  storeRequest: [],
  callNext: false,
  styleIndex: 0,
  out_of_data: false,
  pageIndex: 0,
  batchIdReqMapping: {},
};

export default {
constraints: handleActions(
{
  [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
    switch (payload.key) {
      case "l1":
        return immutable(state, {
          departmentOptions: {
            $set:
              payload && payload.data.departmentOptions
                ? payload.data.departmentOptions
                : state.departmentOptions,
          },
          genderOptions: [],
          rbuOptions: [],
          filterlabels : {
            $set: 
              payload && payload.data.filterMapping
                ? payload.data.filterMapping
                : state.filterMapping
          }
        });
        case "l2":
          return immutable(state, {
            genderOptions: {
              $set:
                payload && payload.data.genderOptions
                  ? payload.data.genderOptions
                  : state.genderOptions,
            },
            rbuOptions: {
              $set:
                payload && payload.data.rbuOptions
                  ? payload.data.rbuOptions
                  : state.rbuOptions,
            },
                dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
              sizeOptions: {
                $set:
                  payload && payload.data.sizeOptions
                    ? payload.data.sizeOptions
                    : state.sizeOptions,
              },
              storeGradeOptions: {
                $set:
                  payload && payload.data.storeGradeOptions
                    ? payload.data.storeGradeOptions
                    : state.storeGradeOptions,
              },
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
          case "l3":
            return immutable(state, {
              rbuOptions: {
                $set:
                  payload && payload.data.rbuOptions
                    ? payload.data.rbuOptions
                    : state.rbuOptions,
              },
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
              sizeOptions: {
                $set:
                  payload && payload.data.sizeOptions
                    ? payload.data.sizeOptions
                    : state.sizeOptions,
              },
              storeGradeOptions: {
                $set:
                  payload && payload.data.storeGradeOptions
                    ? payload.data.storeGradeOptions
                    : state.storeGradeOptions,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
            case "l4":
          return immutable(state, {
             dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
              sizeOptions: {
                $set:
                  payload && payload.data.sizeOptions
                    ? payload.data.sizeOptions
                    : state.sizeOptions,
              },
              storeGradeOptions: {
                $set:
                  payload && payload.data.storeGradeOptions
                    ? payload.data.storeGradeOptions
                    : state.storeGradeOptions,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
          });
          case "l6":
          return immutable(state, {
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
              sizeOptions: {
                $set:
                  payload && payload.data.sizeOptions
                    ? payload.data.sizeOptions
                    : state.sizeOptions,
              },
              storeGradeOptions: {
                $set:
                  payload && payload.data.storeGradeOptions
                    ? payload.data.storeGradeOptions
                    : state.storeGradeOptions,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
          });
          case "l5":
            return immutable(state, {
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              colourOptions: {
                $set:
                  payload && payload.data.colourOptions
                    ? payload.data.colourOptions
                    : state.colourOptions,
              },
              sizeOptions: {
                $set:
                  payload && payload.data.sizeOptions
                    ? payload.data.sizeOptions
                    : state.sizeOptions,
              },
              storeGradeOptions: {
                $set:
                  payload && payload.data.storeGradeOptions
                    ? payload.data.storeGradeOptions
                    : state.storeGradeOptions,
              },
              filterlabels : {
                $set: 
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          default:
          return immutable(state, {
            filterlabels : {
              $set: 
                payload && payload.data.filterMapping
                  ? payload.data.filterMapping
                  : state.filterMapping
            }
          });
    };
  },
 [RESET_ALL]: (state, {payload }) => {
  return immutable(state, {
    storeGroupData: { $set:  []},
    storeGradeData: { $set:  []},
    storeData: { $set:  []},
    storeGroupError: { $set:  false},
    storeGradeError: { $set:  false},
    storeError: { $set:  false},
    popUpError: { $set:  false},
    storeGrouploading: { $set:  false},
    storeGradeloading: { $set:  false},
    storeloading: { $set:  false},
    departmentOptions: { $set:  []},
    genderOptions: { $set:  []},
    rbuOptions: { $set:  []},
    dcsOptions: { $set:  []},
    level5Options: { $set:  []},
    styleOptions: { $set:  []},
    colourOptions: { $set:  []},
    sizeOptions: { $set:  []},
    storeGradeOptions: { $set:  []},
    popUpData: { $set:  []},
    popUpLoader: { $set:  false},
    isUpdated: { $set:  false},
    error: { $set:  false},
    totalMinimum: { $set: null},
    storeTotalCount: { $set: null},
    storeGroupTotalCount: { $set: null},
    storeGradeTotalCount: { $set: null},
    storeGroupIndex: { $set: 0},
    storeGradeIndex: { $set: 0},
    storeRequest: { $set: []},
    storeIndex: { $set: 0},
    callNext: { $set: false},
    out_of_data: { $set: false},
    styleIndex: { $set: 0},
    pageIndex: { $set: 0},
  });
 },
 [RESET_FILTERS_DATA]: (state, {payload }) => {
   return immutable(state, {
    storeTotalCount: { $set: null},
    storeGroupTotalCount: { $set: null},
    storeGradeTotalCount: { $set: null},
    storeGroupIndex: { $set: 0},
    storeGradeIndex: { $set: 0},
    storeRequest: { $set: []},
    storeIndex: { $set: 0},
    storeData: { $set: [] },
    storeGradeData: { $set: [] },
    storeGroupData: { $set: [] },
    genderOptions: { $set: [] },
    rbuOptions : { $set: []},
    dcsOptions: { $set:  []},
    level5Options: { $set:  []},
    styleOptions : { $set: []},
    colourOptions : { $set: []},
    sizeOptions : { $set: []},
    storeGradeOptions: { $set: []},
    totalMinimum: { $set: null},
    error: { $set:  false},
    callNext: { $set: false},
    out_of_data: { $set: false},
    styleIndex: { $set: 0},
    pageIndex: { $set: 0},
   });
 },
 [RESET_STYLE_INDEX]: (state, {payload }) => {
  return immutable(state, {
    callNext: { $set: false},
    out_of_data: { $set: false},
    styleIndex: { $set: 0},
    pageIndex: { $set: 0},
  });
},
  [FETCH_STORE_GROUP_DATA]: (state, {payload }) => {
    payload.pageIndex = state.pageIndex
    return immutable(state, {
      error: { $set:  false},
    storeGrouploading: { $set : true },
    storeGroupError: { $set: false }
    });
  },
  [FETCH_STORE_GROUP_DATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    storeGroupData: { $set: (payload && payload.data) || [] },
    callNext : { $set: (state.storeGroupData.length && !payload?.data?.length) ? true : false },
    // styleIndex : { $set: (state.storeGroupData.length && !payload?.data?.length) ? state.styleIndex + styleIndex : state?.styleIndex },
    out_of_data : { $set: (payload && payload.out_of_data) || false }, 
    styleIndex : { $set: (payload && payload.styleIndex) || 0 },
    // pageIndex : { $set: (state.storeGroupData.length && !payload?.data?.length) ? state.pageIndex  : state.pageIndex + getPageIndex(payload?.data)},
    pageIndex : { $set: (payload &&  state.pageIndex + getPageIndex(payload?.data)) || 0 },
    batchIdReqMapping : { $set : (payload && {...state?.batchIdReqMapping ,...payload?.mapping}) || {}},
    storeGroupTotalCount: { $set: (payload && payload.count) || 0 },
    storeGroupIndex: { $set: (payload && payload.index) || 0 },
    storeGrouploading: { $set : false },
    storeGroupError: { $set: false }
    });
  },
  [FETCH_STORE_GROUP_DATA_ERROR]: (state, {payload }) => {
    return immutable(state, {
    storeGroupData: { $set: [] },
    storeGrouploading: { $set : false },
    storeGroupError: { $set: true }
    });
  },
  [FETCH_MIN_PER_STORE_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    totalMinimum: { $set: (payload && payload.data) || [] },
    });
  },
  [FETCH_MIN_PER_STORE_ERROR]: (state, {payload }) => {
    return immutable(state, {
      totalMinimum: { $set: ('N/A')}
    });
  },
  [FETCH_STORE_GRADE_DATA]: (state, {payload }) => {
    return immutable(state, {
    error: { $set:  false},
    storeGradeloading: { $set: true},
    storeGradeError: { $set: false }
    });
  },
  [FETCH_STORE_GRADE_DATA_ERROR]: (state, {payload }) => {
    return immutable(state, {
    storeGradeData: { $set: [] },
    storeGradeloading: { $set : false },
    storeGradeError: { $set: true }
    });
  },
  [FETCH_STORE_GRADE_DATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    storeGradeData: { $set: (payload && payload.data) || [] },
    callNext : { $set: (state.storeGradeData.length && !payload?.data?.length) ? true : false },
    // styleIndex : { $set: (state.storeGradeData.length && !payload?.data?.length) ? state.styleIndex + styleIndex : state?.styleIndex },
    styleIndex : { $set: (payload && payload.styleIndex) || 0 },
    // pageIndex : { $set: (state.storeGradeData.length && !payload?.data?.length) ? state.pageIndex  : state.pageIndex + getPageIndex(payload?.data)},
    pageIndex : { $set: (payload &&  state.pageIndex + getPageIndex(payload?.data)) || 0 },
    storeGradeTotalCount: { $set: (payload && payload.count) || 0 },
    storeGradeIndex:  { $set: (payload && payload.index) || 0 },
    storeGradeloading: { $set : false },
    storeGradeError: { $set: false }
    });
  },
  [FETCH_STORE_DATA]: (state, {payload }) => {
    return immutable(state, {
      error: { $set:  false},
      storeError: { $set: false },
      storeloading: { $set: true},
      storeRequest: { $set: []}
    });
  },
  [FETCH_STORE_DATA_ERROR]: (state, {payload }) => {
    return immutable(state, {
      storeData: { $set: [] },
      storeloading: { $set : false },
      storeError: { $set: true },
      storeRequest: { $set: []}
    });
  },
  [FETCH_STORE_DATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    storeData: { $set: (payload && payload.data) || [] },
    callNext : { $set: (state.storeData.length && !payload?.data?.length) ? true : false },
    // styleIndex : { $set: (state.storeData.length && !payload?.data?.length) ? state.styleIndex + styleIndex : state?.styleIndex },
    styleIndex : { $set: (payload && payload.styleIndex) || 0 },
    // pageIndex : { $set: (state.storeData.length && !payload?.data?.length) ? state.pageIndex  : state.pageIndex + payload?.data?.length},
    pageIndex : { $set: (payload &&  state.pageIndex + payload?.data?.length) || 0 },
    storeTotalCount: { $set: (payload && payload.count) || 0 },
    storeIndex: { $set: (payload && payload.index) || 0 },
    storeloading: { $set : false },
    storeError: { $set : false },
    storeRequest: { $set :  (payload && payload.req) || []}
    });
  },
  [FETCH_CONSTRAINTS_POPUP]: (state, {payload}) => {
    return immutable(state, {
      popUpLoader: { $set: true },
      popUpError: { $set : false }
    })
  },
  [FETCH_CONSTRAINTS_POPUP_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
    popUpLoader: { $set: false},
    popUpData: { $set: (payload && payload.data ) || [] },
    });
  },
  [FETCH_CONSTRAINTS_POPUP_ERROR]: (state, {payload }) => {
    return immutable(state, {
    popUpLoader: { $set: false},
    popUpData: { $set: [] },
    popUpError: { $set: true}
    });
  },
  [UPDATE_TABLEDATA]: (state, {payload }) => {
    return immutable(state, {
      isUpdated: { $set: false},
      error: { $set:  false},
    })
  },
  [UPDATE_TABLEDATA_SUCCESS]: (state, {payload }) => {
    return immutable(state, {
      error: { $set:  false},
      isUpdated: { $set: true},
    })
  },
  [UPDATE_TABLEDATA_ERROR]: (state, {payload}) => {
    return immutable(state, {
      storeGrouploading: { $set:  false},
      storeGradeloading: { $set:  false},
      storeloading: { $set:  false},
      isUpdated: { $set: true},
      error: { $set:  true},
    })
  } 
},

constraintsState
),
};


