import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ForecastAdjustmentView from './ForecastAdjustmentView';
import ForecastHierarchyView from './ForecastHierarchyView';
import ModifyWarningDialogView from './ModifyWarningDialogView';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { RadioButton } from 'primereact/radiobutton';
import "primereact/resources/primereact.css";
import * as adaVisualActions from './actions/AdaVisualActions';
import * as AdaConst from './AdaVisualConstants';
import "./AdaVisual.css";

/**
 * Radio Selection Views Container
 * @returns Adjust and Hierarchy Views
 */
const EditRadioSection = (props) => {
    const [isAdjustmentSelected, setSelectAdjustment] = useState(true);
    const [showEditWarningDialog, setShowEditWarningDialog] = useState(false);
    const { isAdaLoadingSuccess, isForecastModified, isHierarchyModified, adaVisualActions, isSaveSuccess, isReviewSuccess } = props;
    const { restoreAdaData, restoreHierarchyData } = adaVisualActions;

    //check true adjustment false means hierarchy
    function handleRadioSelectionChange(check) {
        if (!isReviewSuccess && !isSaveSuccess && (isHierarchyModified || isForecastModified)) {
            setShowEditWarningDialog(true);
        } else {
            setSelectAdjustment(check);
        }
    }

    /**
     * function to discard edited changes
     */
    function handleDiscardEdit() {
        if (isAdjustmentSelected) {
            restoreAdaData();
            setSelectAdjustment(false);
            setShowEditWarningDialog(false);
        } else {
            restoreHierarchyData();
            setSelectAdjustment(true);
            setShowEditWarningDialog(false);
        }
    }

    return (
        <>
            {isAdaLoadingSuccess ?
                <>
                    <div className="radio-selection-container">
                        <RadioButton className="radio-selector" inputId="adjustment" onChange={(e) => handleRadioSelectionChange(true)} checked={isAdjustmentSelected} />
                        <div className={`adjustment_container shadow-sm ${!isAdjustmentSelected ? 'disable-content' : ''}`} disabled={!isAdjustmentSelected}>
                            <Accordion activeIndex={[0]}>
                                <AccordionTab header={AdaConst.FORECAST_ADJUSTMENT_HEADER} disabled={!isAdjustmentSelected}>
                                    <ForecastAdjustmentView />
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>
                    <div className="radio-selection-container">
                        <RadioButton className="radio-selector" inputId="hierarchy" onChange={(e) => handleRadioSelectionChange(false)} checked={!isAdjustmentSelected} />
                        <div className={`hierarchy_container shadow-sm ${isAdjustmentSelected ? 'disable-content' : ''}`} disabled={isAdjustmentSelected}>
                            <Accordion activeIndex={[0]}>
                                <AccordionTab header={AdaConst.EDIT_HIERARCHY_HEADER} disabled={isAdjustmentSelected}>
                                    <ForecastHierarchyView />
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>
                    {showEditWarningDialog ?
                        (<ModifyWarningDialogView
                            showAlert={true} setShowEditWarningDialog={setShowEditWarningDialog} handleDiscardEdit={handleDiscardEdit} />)
                        : null}
                </>
                : null}
        </>
    );
}

const mapStateToProps = ({ adaVisual }) => ({
    isAdaLoadingSuccess: adaVisual.isAdaLoadingSuccess,
    isForecastModified: adaVisual.isForecastModified,
    isHierarchyModified: adaVisual.isHierarchyModified,
    isReviewSuccess: adaVisual.isReviewSuccess,
    isSaveSuccess: adaVisual.isSaveSuccess,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRadioSection);