import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../../components/Filters/NumberRangeColumnFilter";
import SetAllModal from "../../../../components/ErrorModal/SetAllModal";
import { DEMAND_TABLE_HEADERS } from "./DemandConstants"
import * as Notify from "../../../../components/Notification/Notifications";
import { cloneDeep } from "lodash";
import { fetchStoresData } from '../../../ProductProfile/ProductProfileAction'
import ProductProfileDetailsTable from "./ProductProfileDetailTable";
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const DemandConstraintsTable = (props) => {
    const ppTableRef = useRef()
    const { data, demandSelectedRows, setSelectedRows, isAddProduct, store,
        ppData, ppLoading, ppError, fetchStoresData } = props

    const [tableData, setTableData] = useState([])
    const [demadTableInstance, setDemandTableInstance] = useState({})
    const [initialSelectedRows, setInitialSelectedRows] = useState({})
    const [showSetAll, setShowSetAll] = useState(false)
    const [minSA, setMinSA] = useState()
    const [maxSA, setMaxSA] = useState()
    const [apsSA, setAPSSA] = useState()
    const [wosSA, setWOSSA] = useState()
    const [actualROSSA, setActualROSSA] = useState()
    const [demandSA, setDemandSA] = useState()
    const [showPPTable, setShowPPTable] = useState(false)
    const [selectedPPName, setPPName] = useState(null)

    const setAllValues = [{ varName: minSA, key: "min_stock", setFunction: setMinSA },
    { varName: maxSA, key: "max_stock", setFunction: setMaxSA },
    { varName: apsSA, key: "aps", setFunction: setAPSSA },
    { varName: wosSA, key: "wos", setFunction: setWOSSA }]


    const demandConstraintsColumns = [
        {
            sticky: "left",
            Header: DEMAND_TABLE_HEADERS.ARTICLE,
            accessor: 'article',
            Filter: ColumnFilter
        },
        {
            Header: DEMAND_TABLE_HEADERS.DESC,
            accessor: 'color_desc',
            Filter: ColumnFilter,
            width: 300
        },
        {
            Header: DEMAND_TABLE_HEADERS.STYLE_NAME,
            accessor: 'style_name',
            Filter: ColumnFilter,
            width: 300
        },
        {
            Header: DEMAND_TABLE_HEADERS.GLOBAL_COLOR,
            accessor: 'color',
            Filter: ColumnFilter
        },
        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: "l1_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: "l2_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: "l4_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: "l5_name",
            Filter: ColumnFilter,
        },
        {
            Header: DEMAND_TABLE_HEADERS.PRODUCT_TYPE,
            accessor: 'product_type',
            Filter: ColumnFilter
        },
        {
            Header: DEMAND_TABLE_HEADERS.MIN,
            accessor: 'min_stock',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: DEMAND_TABLE_HEADERS.MAX,
            accessor: 'max_stock',
            filter: 'between',
            Filter: NumberRangeColumnFilter,
        },
        {
            Header: DEMAND_TABLE_HEADERS.WOS,
            accessor: 'wos',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: DEMAND_TABLE_HEADERS.APS,
            accessor: 'aps',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        // {
        //     Header: DEMAND_TABLE_HEADERS.ACTUAL_ROS,
        //     accessor: 'ros',
        //     Filter: NumberRangeColumnFilter,
        //     filter: 'between'
        // },
        {
            Header: DEMAND_TABLE_HEADERS.INV,
            accessor: 'inv_available',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
            // width: 180
        },
        {
            Header: DEMAND_TABLE_HEADERS.DEMAND_ESTIMATED,
            accessor: 'demand_estimated',
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: DEMAND_TABLE_HEADERS.PRODUCT_PROFILE,
            accessor: "product_profile",
            Cell: (instance) => (
                <div>
                    <div className="clickable" onClick={() => getContribution(instance.row?.original?.product_profile)}>{instance.row?.original?.product_profile?.label}</div>
                </div>
            ),
            Filter: ColumnFilter,
            sticky: "right",
            width: 200
        }
    ]

    const prepFilter = [
        {
            id: 1,
            isRequired: false,
            label: "Min",
            name: "min",
            className: "setAll__input__center_3",
            value: minSA,
            onChange: (e) => {
                if (maxSA) {
                    if (!Number(maxSA))
                        e.target.value = 0
                    else
                        e.target.value = Math.min(Number(maxSA) - 1, e.target.value)
                }
                setMinSA(e.target.value)
            },

            input: true,
            type: "number",
            min: 0
        },
        {
            id: 2,
            isRequired: false,
            label: "Max",
            name: "max",
            className: "setAll__input__center_3",
            value: maxSA,
            onChange: (e) => {
                if (minSA) {
                    e.target.value = Math.max(Number(minSA) + 1, e.target.value)
                }
                setMaxSA(e.target.value)
            },
            input: true,
            type: "number",
            min: 0
        },
        {
            id: 3,
            isRequired: false,
            label: "WOS",
            name: "wos",
            className: "setAll__input__center_3",
            value: wosSA,
            onChange: (e) => {
                let val = e.target.value
                if (val < 0) {
                    val = 0
                }
                setWOSSA(val)
            },
            input: true,
            type: "number",
            min: 0
        },
        {
            id: 4,
            isRequired: false,
            label: "APS",
            name: "aps",
            className: "setAll__input__center_3",
            value: apsSA,
            onChange: (e) => {
                let val = e.target.value
                if (val < 0) {
                    val = 0
                }
                setAPSSA(val)
            },
            input: true,
            type: "number",
            min: 0
        }
    ]

    const getContribution = (pp) => {
        setPPName(pp.label)
        setShowPPTable(true)
        setTimeout(() => {
            ppTableRef.current.scrollIntoView()
        }, 200)
        fetchStoresData({ product_profile_code: pp.value })
    }

    useEffect(() => {
        setShowPPTable(false)
        let selectedRows = {}
        demandSelectedRows?.map((item, index) => {
            selectedRows[index] = true
        })
        let demandData = cloneDeep(data)
        demandData.map(item => {
            let est = Number(item.demand_estimated)
            let min = Number(item.min_stock)
            let max = Number(item.max_stock)
            est = est < min ? min : (est > max ? max : est)
            item.demand_estimated = est > max ? max : est.toFixed(0)
        })
        setTableData(demandData)
        setInitialSelectedRows(selectedRows)
    }, [data])

    // useEffect(() => {
    //     setTableRows(demandSelectedRows)
    // }, [demandSelectedRows])

    const setTableRows = (rows) => {
        let selectedRows = {}
        rows?.map((item, index) => {
            selectedRows[index] = true
        })
        setInitialSelectedRows(selectedRows)
    }

    const handleSetAllOk = () => {
        setTableInstance(demadTableInstance)
        let setAllRows = Object.keys(demadTableInstance.state.selectedRowIds)
        showSetAll && setShowSetAll(false)

        setTableData((oldData) => {
            let newData = cloneDeep(oldData);
            setAllRows.forEach(setAllRow => {
                setAllValues.forEach(item => {
                    if (item.varName || item.varName == 0)
                        newData[setAllRow][item.key] = parseInt(item.varName)
                })

                // let wos = parseInt(wosSA) >= 0 ? parseInt(wosSA) : 0
                // let aps = parseInt(apsSA) >= 0 ? parseInt(apsSA) : 0
                let est = newData[setAllRow].wos * newData[setAllRow].aps;
                est = est < newData[setAllRow].min_stock ? newData[setAllRow].min_stock : (est > newData[setAllRow].max_stock ? newData[setAllRow].max_stock : est)
                newData[setAllRow]["demand_estimated"] = est
            })
            return newData;
        });

    }

    const handleSetAllCancel = () => {
        setShowSetAll(false);
    }

    const setAllHandler = () => {
        if (Object.keys(demadTableInstance.state.selectedRowIds).length > 0) {
            let selectedIds = demadTableInstance.selectedFlatRows.map(item => item.original.article)
            let remainingRows = []
            let selectedRows = []
            setAllValues.map(item => {
                item.setFunction(null)
            })
            setShowSetAll(true);

            demadTableInstance.data.map(item => {
                if (selectedIds.includes(item.article)) {
                    selectedRows.push(item)
                }
                else {
                    remainingRows.push(item)
                }
            })
            setTableRows(demadTableInstance.getSelectedRows)
            setTableData([...selectedRows, ...remainingRows])

        }
        else {
            Notify.error(`Please select atleast 1 product to use Set All functionality!!`)
        }
    }

    const setTableInstance = (instance) => {
        if (isAddProduct) {
            props.setRTInstance(instance)
        }
        else {
            setSelectedRows(instance.data, instance.getSelectedRows)
            setDemandTableInstance(instance)
        }
    }

    return (
        // <>
        <div>

            <ReactTableWithPlugins
                shouldPagination
                data={(tableData ? tableData : [])}
                columns={demandConstraintsColumns}
                initialSelectedRows={initialSelectedRows}
                renderMarkup='TableMarkup'
                keyRT="StrategyPageTable"
                features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                getInstanceOnMount={(instance) => {
                    setTableInstance(instance)
                }}
                tableId="newstore_demand"
            />
            {
                !isAddProduct &&
                <button
                    style={{ marginLeft: "45%" }}
                    className="btn btn-primary"
                    onClick={setAllHandler}
                >
                    Set All
                </button>
            }
            {
                showSetAll &&
                <SetAllModal filter={prepFilter}
                    handleOk={handleSetAllOk} handleCancel={handleSetAllCancel}
                    handleClose={handleSetAllCancel}
                />
            }
            {
                showPPTable &&
                <>
                    <span ref={ppTableRef}></span>
                    <ProductProfileDetailsTable data={ppData} loading={ppLoading}
                        store={store} ppName={selectedPPName} error={ppError} />
                </>
            }
        </div>

        // </>
    )
}

const mapStateToProps = ({ productProfile }) => {
    return {
        ppLoading: productProfile.storeloading,
        ppData: productProfile.storeData,
        ppError: productProfile.storeError,
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchStoresData: (payload) => dispatch(fetchStoresData(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DemandConstraintsTable)