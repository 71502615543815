import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import * as inventoryReservationActionTypes from '../Actions/ActionTypes';
import * as Notify from "../../../../components/Notification/Notifications";

import {getInventoryReservationFiltersData,getInventoryReservationTableData,editInventoryReservationTableData} from "../../../../routes/api"



function* fetchInventoryReservationFilters(action) {
    try {
        
        const { payload, filterType } = action;
        let req = {};

        if (payload) {
            for (const key in payload) {
                if (payload[key] && payload[key].length > 0)
                    req[key] = payload[key]?.map((ele) => ele.value);
            }
        }

        if (filterType) {
            req["filter_type"] = filterType;
        }

            
        const res = yield call(getInventoryReservationFiltersData, req);
        console.log('123',res)
        if (res?.data.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping;
            for (const key in res.data.data[0]) {
                let k = "";
                if (key === "level1") {
                    k = "level1Options";
                } else if (key === "level2") {
                    k = "level2Options";
                } else if (key === "level3") {
                    k = "level3Options";
                } else if (key === "level4") {
                    k = "level4Options";
                 } 
                data[k] = res.data.data[0][key]?.filter(val => val).map((element) => ({
                    value: element,
                    label: element,
                }));
            }

            let payload = {
                data: data,
                key: filterType,
            };

            yield put({ type: inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_FILTER_SUCCESS, payload });
        }
    } catch (error) {
        yield put({ type: inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_FILTER_FAILED, error: "Something went wrong!" });
    }
}

function* fetchInventoryReservationTableData(action) {
    try {
        const { payload } = action;
        const res = yield call(getInventoryReservationTableData, payload);
        if (res?.data.status) {
            let response = res?.data.data;
            yield put({ type: inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_TABLE_SUCCESS, response });
        }
        else{
            yield put(({ type: inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_TABLE_FAILED, error: "Something went wrong!" }));
        }
    } catch (error) {
        yield put(({ type: inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_TABLE_FAILED, error: "Something went wrong!" }));
    }
}

function* SaveInventoryReservationEditTableData(action){
    try {
        const { payload } = action;
    
        const res = yield call(editInventoryReservationTableData,payload);
        if (res?.data.status) {
            let response = res?.data.data;
            yield put({ type: inventoryReservationActionTypes.SAVE_INVENTORY_RESERVATION_DATA_SUCCESS, response });
            Notify.success("Success")
        }
    } catch (error) {
        console.log('ss1',error)
        Notify.error("Something went wrong")
        yield put(({ type: inventoryReservationActionTypes.SAVE_INVENTORY_RESERVATION_DATA_FAILED, error: "Something went wrong!" }));
    }
}


function* watchInventoryReservationFiltersRequest() {
    yield takeEvery(inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_FILTER_REQUEST, fetchInventoryReservationFilters);
}

function* watchInventoryReservationTableDetailsRequest() {
    yield takeLatest(inventoryReservationActionTypes.GET_INVENTORY_RESERVATION_TABLE_REQUEST, fetchInventoryReservationTableData);
}

function* watchEditInventoryReservationTableDetailsRequest() {
    yield takeLatest(inventoryReservationActionTypes.SAVE_INVENTORY_RESERVATION_DATA_REQUEST, SaveInventoryReservationEditTableData);
}

export function* InventoryReservationSagas() {
    yield all([
        watchInventoryReservationFiltersRequest(),
        watchInventoryReservationTableDetailsRequest(),
        watchEditInventoryReservationTableDetailsRequest(),
    ]);
}
