import React, { useState, useEffect, createRef } from "react";
import Modal from "react-awesome-modal";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import { isNull } from "lodash-es";
import { connect } from "react-redux";
import * as InventoryReservationActions from './Actions/InventoryReservationActions';
import ColumnFilter from "../../UserManagement/ColumnFilter";
import PageLoader from "../../../components/Loader/PageLoader";
import Filter from "../../ProductProfile/ProductProfileTable/Filter";
import { bindActionCreators } from "redux";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

var a =[]
var reqdata=[]
var packSize=[]

const EditInventoryReservation=(props) =>{
    const [columns, setColumns] = useState([])
    const [editdata, setEditData] = useState([])
    const [request, setRequest] = useState([])
    const [reserveData,setReserveData] = useState({})
    const [packValue,setPackValue]=useState(null)
    const {data,InventoryReservationActionsProp,filter,dataRefresh} = props
   

    const closeAlert = () => {
        // props.setDcAvailableError(false)
        props.closeAlert()
        a=[]
        reqdata=[]
    }
 

    const columnsDataPack = React.useMemo(
        () => [
            {
                Header: "Pack Allocate Quantity",
                accessor: (data) => data.reserveQuantityPack,
                Cell: (instance) => <InputCell {...instance} style={{ textAlign: "center" }} min={0} max={data.dc_pack_max} type="number" changeHandler={(rowIdx, columnId, values) => inputPackHandler(rowIdx, columnId, values)} />,
                Filter: ColumnFilter,
               
            },
            
        ]
    ) 

    function inputPackHandler(index,abc,value){
        setPackValue(value)
    }

    function inputChangeHandler(index,abc,value) {
        setReserveData({...reserveData,[a[index].size]:Number(value)})
        setRequest({...request,[a[index].inventory_available]:Number(value)})
    }

    

    const columnDataEaches = React.useMemo( () => [
        {
            Header:"Size",
            accessor: "size",
            Filter: ColumnFilter
        },
        {
            Header:"Inventory Available",
            accessor:"inventory_available",
            Filter: ColumnFilter
        },
        {
            Header:"Reserved Quantity",
            accessor:"reserved_quantity",
            Cell: (instance) => <InputCell {...instance} style={{ textAlign: "center" }} min={0} max={instance.row.original.inventory_available} type="number" changeHandler={(rowIdx, columnId, values) => inputChangeHandler(rowIdx,columnId,values)} />,
            Filter:ColumnFilter
        }
    ])

    const onSave = ()=>{
        if(data.is_pack)
        {
            var value=[]
            data.packColoumn.map(val=>{value.push(packValue)})
            console.log('ss',value)
            reqdata.push({
                "article":data.article,
                "store_code":data.store_code,
                "pack_id":data.dc_pack_id,
                "units_in_pack":data.units_in_pack,
                "size":data.packColoumn,
                "reserve_quantity":value,
                "is_percentage":false,
                // "inv_available":data.availableQuantity,
                "is_pack":data.is_pack
            })
        }
        else{
        reqdata.push({
            "article": a[0].article,
            "store_code":a[0].store_code ,
            "size":Object.keys(reserveData),
            "reserve_quantity":Object.values(reserveData),
            // "inv_available":Object.keys(request),
            "is_percentage":false,
            "is_pack":data.is_pack
            
        })  
        }
        var number = reqdata.length
        let req ={
            "data":[reqdata[number-1]]
        }
        InventoryReservationActionsProp.saveInventoryReservationData(req)  
        // closeAlert() 
        // setTimeout(InventoryReservationActionsProp.resetSaveInventoryReservationScreen(),50000) 
    }
    console.log('ss',data)
   
    useEffect(()=>{
        if(data?.packColoumn?.length && !data.is_pack){
            data.packColoumn.map(val=>{
                a.push({
                    size:val,
                    article:data.article,
                    store_code:data.store_code,
                    reserved_quantity:data?.reserveQuantity[val],
                    inventory_available:data.availableQuantity[val],
                })
            })
        }
        if(a.length!==0){
            setEditData(a)
        }
    },[props.data])

    useEffect(() => {
        if(data?.packColoumn?.length && data.is_pack) {
            let columns = []
            data.packColoumn.forEach(val => {
                columns.push(
                    {
                        Header: val,
                        accessor: (data) => data.pack_config[val],
                    }
                )})
                let finalColumns = [...columns,...columnsDataPack]
            setColumns(finalColumns)
        }
    },[props.data])

    useEffect(()=>{
        if(props.saveSuccess)
        {
            console.log('ss1234')
            dataRefresh(filter)
            closeAlert()
            InventoryReservationActionsProp.resetSaveInventoryReservationScreen()
        }
    },[props.saveSuccess])

    return (
       <>
        <Modal
            visible={props.showAlert}
            width="80%"
            // height="90%"
            effect="fadeInDown"
            onClickAway={closeAlert}
            alignItems="flexStart"
        >
            
        <div className="modal-container">
            <div className="modal-header">
                <h5 className="page-heading text-center m-0">
                    Edit Inventory Reservation Units
                </h5>
                <button type="button" className="close" data-dismiss="modal">
                    <span style={{ cursor: "pointer" }}>
                        <i
                            className="fa fa-times text-right m-2 "
                            aria-hidden="true"
                            onClick={closeAlert}
                        />
                    </span>
                </button>
            </div>
            <div className="modal-middle-container">
                <div className="mx-2 p-2 row justify-content-center">
                    <PageLoader loader={props.loading} gridLoader={true}>
                        {(props.packError) ?  (<div className="error">Something Went Wrong!!</div>) :
                        <ReactTableWithPlugins
                            // getInstanceOnMount={(instance) => {
                            //     setRTInstance(instance)
                            // }}
                            style={{maxWidth:'100%', margin:'auto'}}
                            data={data.is_pack ? [data] : editdata }
                            columns={data.is_pack ? columns : columnDataEaches}
                            // loading={props.loading}
                            renderMarkup='TableMarkup'
                            // shouldPagination
                            tableId="edit_packs"
                            />}
                    </PageLoader>                    
                </div>
            </div>
                <div className="text-center">
                    <button
                        style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                        onClick={onSave}
                        className="btn btn-primary"
                    // disabled={isDisabled}
                    >
                        Save
                    </button>
                </div>
        </div>
    </Modal>

       </>
    )
}
const mapStateToProps = ({ InventoryReservation }) => ({
   saveSuccess:InventoryReservation.isInventoryReservationSaveSuccess
});

const mapDispatchToProps = (dispatch) => ({
    InventoryReservationActionsProp: bindActionCreators(InventoryReservationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps) (EditInventoryReservation)