import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import moment from "moment";
import * as StrategyConsts from './StrategyConstants';
import classNames from "classnames";
import debounce from "lodash.debounce"

import StrategyCSS from "./Strategy.module.scss";
import {
  fetchFilterData,
  fetchStrategyTableData,
  fetchStrategyTableDropdown,
  fetchStoregroupMinStock,
  setTableInstance,
  fetchStoreTableData,
  fetchDCData,
  enableFilters,
  resetFiltersOptions,
  createAllocation,
  updateResQty,
  save,
  resetAll,
  saveDraft,
  getDrafts,
  resetSaveDraftData,
  deleteDraft,
  validatePlanName,
  setLoaderToFalse
  // getstoregroupminstock,
} from "./actions";
import { setAdaAllocationRequest, setAdaCurrentDate } from '../AdaVisual/actions/AdaVisualActions';
import Api from "../../configs/apiConfig"
import ReactTable from "../../components/Table/ReactTable";
import WithDynamicPlugins from "../../components/Table/WithPlugins";
import SelectCell from "../../components/Table/Cellrenderer/SelectCell";
import DatePicker from "../../components/Table/Cellrenderer/DatePicker";
import MultiSelectCell from "../../components/Table/Cellrenderer/MultiSelectCell";
import MultiSelect from "../../components/Filters/MultiSelect";
import SingleSelect from "../../components/Filters/SingleSelect";
import { clone, cloneDeep, filter, intersectionWith, isEmpty, isEqual, isNull } from "lodash";
import { Tab, Tabs } from "react-bootstrap";
import InputCell from "../../components/Table/Cellrenderer/InputCell";
import ToggleComponent from "../../components/Toggle/ToggleComponent";
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications";
import SelectCellComponent from "../../components/Table/Cellrenderer/SelectCellComponent"
import PageLoader from "../../components/Loader/PageLoader";
import Select from "../../components/Filters/SingleSelect";
import ErrorModal from "../../components/ErrorModal/ErrorModal"
import { FINALIZE_PAGE } from "../../constants/routeConstants";
import ColumnFilter from "../UserManagement/ColumnFilter";
import { GET_DC_DATA, GET_SIZE_DC, GET_APS, GET_STORE_GROUP_MIN_STOCK, SSE_CREATE_ALLOCATON } from "../../constants/apiConstants";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import { addPer, zeroDecimal } from "../../utils/formatters/valueFormatters";
import SelectColumnFilter from "../../components/Filters/MultiSelectColumnFilter";
import { levelFiltersLabelFormatter, updateArticleName } from "../../utils/filterLevelMapping";
import AdaVisualContainer from '../AdaVisual/AdaVisualContainer';
import { getUserPreferenceFilter, shouldHideForCurrentClient, hideToProd, scrollIntoView, setUserPreferenceFilter, isAshley, dateFormat, reloadPageWithUpdateToken } from "../../utils/commonUtilities";
import Drafts from "../Drafts/Drafts"
import NumberRangeColumnFilter from "../../components/Filters/NumberRangeColumnFilter";
import SetAllModal from "../../components/ErrorModal/SetAllModal";
import { oneDecimal } from "../../utils/formatters/valueFormatters";
import ConfirmationAlert from "../../components/ConfirmationAlert/ConfirmationAlert";
import ReactTollTip from "../../components/Table/Cellrenderer/ReactToolTip";


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);
const recordsPerPage = 10;
let eventSource

let getUPC = (p_rowData, p_sizeValues) => {
  return (p_sizeValues.map(val => p_rowData[val]))
}

let getEstimatedDemand = (p_rowData, p_columnId, p_Value) => {
  try {
    let l_otherColumnId
    if (p_columnId == "WOS_rounded")
      l_otherColumnId = "APS/ROS_rounded"
    else
      l_otherColumnId = "WOS_rounded"
    return (zeroDecimal(Math.max(0, Math.max(Number(p_rowData.min_stock), (p_rowData[l_otherColumnId] * p_Value)) -
      (Number(p_rowData.onhand) + Number(p_rowData.onorder) + Number(p_rowData.intransit)))));
  }
  catch {
    return 0;
  }
}

const Strategy = (props) => {
  const [tableData, setTableData] = useState([]);
  const [storeGroupStoreResult, setStoreGroupStoreResult] = useState([]);
  const [storeMinStockResult, setStoreMinStockResult] = useState([]);
  const [tableInstance, setTableInstance] = useState(null);
  const [storeGroupTableInstance, setStoreGroupTableInstance] = useState(null);
  const [dcTableInstace, setDcTableInstance] = useState(null);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [level4Options, setLevel4Options] = useState([])
  const [level5Options, setLevel5Options] = useState([])
  const [subdepartmentOptions, setSubdepartmentOptions] = useState([]);
  const [division, setDivision] = useState(null);
  const [department, setDepartment] = useState(null);
  const [storeCode, setStoreCode] = useState(null)
  const [storeCodeOptions, setStoreCodeOptions] = useState(null)
  const [subdepartment, setSubdepartment] = useState(null);
  const [level4, setLevel4] = useState(null);
  const [level5, setLevel5] = useState(null);
  const [storeTableData, setStoreTableData] = useState(null);
  const [storeTableDataCopy, setStoreTableDataCopy] = useState(null);
  const [dcTableData, setDcTableData] = useState(null);
  const [reviewBtnDisabled, setReviewBtnDisabled] = useState(true);
  const [articles, setArticles] = useState({});
  const [articleOptions, setArticleOptions] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedArticleDcData, setSelectedArticleDcData] = useState(null);
  const [selectedArticleStoreConst, setSelectedArticleStoreConst] = useState(
    null
  );
  const [editStoreIndex, setEditStoreIndex] = useState(true);
  const [disableFilters, setDisableFilters] = useState(false);
  const [refStoreDropDownOptions, setRefStoreDropDownOptions] = useState(true);
  const [storeRequest, setStoreRequest] = useState([])
  const [dcRequest, setDcRequest] = useState([])
  const [selectedArticleWithData, setSelectedArticleWithData] = useState([])
  const [saveIsDisabled, setSaveIsDisabled] = useState(false)
  const [level1Label, setLevel1Label] = useState('')
  const [level2Label, setLevel2Label] = useState('')
  const [level3Label, setLevel3Label] = useState('')
  const [level4Label, setLevel4Label] = useState('')
  const [level5Label, setLevel5Label] = useState('')
  const [myInstance, setMyInstance] = useState(null)
  const [isPercent, setIsPercent] = useState(true)
  const [isReviewChangesDisabled, setIsReviewChangesDisabled] = useState(true)
  const [errorModalMessage, setErrorModalMessage] = useState(null)
  const [createAllocationReq, setCreateAllocationReq] = useState(null)
  const [failedArticleId, setFailedArticleId] = useState(null)
  const [request, setRequest] = useState(null)
  const [initialIndex, setInitialIndex] = useState(0)
  const [allocationName, setAllocationName] = useState(null)
  const [sortBy, setSortBy] = useState([])
  const [buttonLabel, setButtonLabel] = useState("Ok")
  const [showSetAll, setShowSetAll] = useState(false)
  const [showSetAllStoreDetails, setShowSetAllStoreDetails] = useState(false)
  const [storeEligibiltyGroupSA, setstoreEligibiltyGroupSA] = useState(null);
  const [inventorySourceSA, setInventorySourceSA] = useState(null);
  const [dcSA, setDcSA] = useState(null);
  const [demandTypeSA, setDemandTypeSA] = useState(null);
  const [productProfileSA, setProductProfileSA] = useState(null);
  const [userDefinedInvSA, setUserDefinedInvSA] = useState(null);
  const [plannedAPSSA, setPlannedAPSSA] = useState(null)
  const [plannedWOSSA, setPlannedWOSSA] = useState(null)
  const [apsSA, setapsSA] = useState(null)
  const [wosSA, setwosSA] = useState(null)
  const [setAllLoading, setSetAllLoading] = useState(false)
  const inputRef = useRef(null);
  const adaVisualRef = useRef(null);
  const [isForecastrRevBtnDisable, setForecastRevDisableState] = useState(true);
  const [plannedAPSDisabled, setPlannedAPSDisabled] = useState(false)

  // const tableRef = useRef(null);
  const [isShowDrafts, setShowDrafts] = useState(false)
  const [selectedArticlesDraftData, setSelectedArticlesDraftData] = useState([])
  const [currentDraftallocationCode, setCurrentDraftallocationCode] = useState("")
  const [isNewDraftSaved, setIsNewDraftSaved] = useState(false)
  const [isContinueAllocation, setIsContinueAllocation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialSelectedRows, setInitialSelectedRows] = useState({})
  const [intitalSelectedStoreRows, setIntitalSelectedStoreRows] = useState({})
  const [draftInstance, setDraftInstance] = useState({})
  const [shouldDisplayFilter, setShouldDisplayFilter] = useState(true)
  const [hideDcStoreSection, setHideDcStoreSection] = useState(false)
  const [hideMainTable, setHideMainTable] = useState(false)
  const [loaderdts, setLoader] = useState(false)
  const [isFirstDraftsCall, setIsFirstDraftsCall] = useState(true)
  const [selectedFilters, setSelectedFilters] = useState({})
  const [articleStoreMapping, setArticleStoreMapping] = useState({})
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [isShowStoreAlert, setShowStoreAlert] = useState(false)
  const [storeAlertMessage, setStoreAlertMessage] = useState(null)
  const [totalEstimatedDemad, setTotalEstimatedDemad] = useState(null)
  const [useSSE, setUseSSE] = useState(false)
  const [isPOASNFlow, setIsPOASNFlow] = useState(false)
  const [POASNHiddenColumns, setPOASNHiddenColumns] = useState([])
  const [poDetails, setPODetails] = useState({})

  useEffect(() => {
    if(process.env.REACT_APP_CLIENT == "ashley") {
      setUseSSE(true)
    }
  },[process.env.REACT_APP_CLIENT])

  // const [searchTerm, setSearchTerm] = useState("")

  let l_noOfStores
  const getNoofStores = (p_storeGroupCode) => {
    l_noOfStores = []
    for (let i in storeGroupStoreResult) {
      if (p_storeGroupCode.includes(Number(i))) {
        l_noOfStores.push(storeGroupStoreResult[i])
      }
    }
    l_noOfStores = [... new Set(l_noOfStores.flat(Infinity))]
    return l_noOfStores.length;
  }

  let getInvComponent = (p_req) => {
    let invCompList
    if(isAshley()) {
      if(props?.location?.state?.isPOASNFlow) {
        invCompList = ['oh_map', 'rq_map']
      }
      else{
        invCompList = ['oh_map', 'rq_map', 'au_map', 'po_map']
      }
    }
    else {
      invCompList = ['oh_map', 'it_map', 'oo_map', 'rq_map', 'au_map']
    }
    const finalMapping = {}
    while (invCompList.length) {
      let invComp = invCompList[invCompList.length - 1]
      let articleSizStoreMapping = Object.fromEntries(Object.entries(p_req.data?.[invComp]).filter(([key, value]) => p_req.size.includes(key)))
      let sum = 0
      for (let size in articleSizStoreMapping) {
        for (let code of p_req.store_code) {
          sum += articleSizStoreMapping?.[size]?.[code] ? Number(articleSizStoreMapping[size][code]) : 0
        }
      }
      finalMapping[invComp] = sum;
      invCompList.pop();
    }
    // console.log(finalMapping,'ugbubjbv')
    return (finalMapping)
  }

  const getMinPresQty = (p_article) => {
    return storeMinStockResult.filter(val => l_noOfStores.includes(val.store_code)).filter(val => val.article == p_article).reduce((acc, curr) => {
      return acc = acc + curr.min_stock
    }, 0)
  }

  const retainCheckboxState = () => {
    let initialSelectedRows = {}
    Object.keys(tableInstance.state.selectedRowIds).forEach((item, index) => {
      initialSelectedRows[`${item}`] = true
    })
    setInitialSelectedRows(initialSelectedRows)
  }

  // below code is for optimiztion

  useEffect(() => {
    if ((selectedArticle && articleOptions[0]?.value != selectedArticle?.value && !selectedArticleWithData.includes(selectedArticle?.value))) {
      props.fetchStoreTableData(storeRequest.filter(val => val.Product_Code == selectedArticle?.value));
      props.fetchDcTableData(dcRequest.filter(val => val.Product_Code == selectedArticle?.value));
      setSelectedArticleWithData(old => [...old, selectedArticle?.value])
    }
  }, [selectedArticle])

  useEffect(() => {
    console.log(articleStoreMapping, intitalSelectedStoreRows, 'sdcdscs')
  }, [articleStoreMapping, intitalSelectedStoreRows])

  useEffect(() => {
    if (storeTableDataCopy?.[selectedArticle?.value]) {
      let totalEstimatedDemad = 0
      storeTableDataCopy[selectedArticle?.value].forEach(val => {
        totalEstimatedDemad += Number(val.estimated_demand)
      })
      setTotalEstimatedDemad(totalEstimatedDemad)
    }
  }, [storeTableDataCopy, selectedArticle])

  // useEffect(()=> {
  //   console.log(props.min_stock_1,myInstance,'kdfjdsfjn')
  //   myInstance?.updateMyData(myInstance.rowIdx, "min_stock", props?.min_stock_1?.min_stock)
  // },[props.min_stock_1])

  // useEffect(() => {
  //   if(selectedArticleWithData.reduce((a, v) => (v == selectedArticle ? a + 1 : a), 0) == 1) {
  //     props.fetchStoreTableData(storeRequest.filter(val => val.Product_Code == selectedArticle?.value));
  //     props.fetchDcTableData(dcRequest.filter(val => val.Product_Code == selectedArticle?.value));
  //   }
  // },[selectedArticleWithData])

  // Col defs
  const cols = [
    {
      Header: "Product Type",
      accessor: "product_type",
      sticky: "left",
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      width: 110
    },
    {
      Header: `${updateArticleName(false)} ID`,
      accessor: "article",
      sticky: "left",
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} placeholder="Search in bulk..." />,
      filter: 'bulkFilterSpaceSeperated',
      Cell: ({ value, row }) =>
        (failedArticleId?.includes(value)) ? (
          <span style={{ color: "red" }} >{value}</span>
        ) : value,
      width: 120
    },
    {
      Header: "Style Description",
      accessor: "style_desc",
      sticky: "left",
      width: 250,
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    },
    {
      Header: "Style Color",
      accessor: "color_desc",
      sticky: "left",
      width: 180,
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    },
    {
      Header: "Pack ID",
      accessor: "pack_id",
      Cell: (instance) => <ReactTollTip {...instance} />,
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    },
    {
      Header: levelFiltersLabelFormatter("level1"),
      accessor: "l1_name",
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    },
    {
      Header: levelFiltersLabelFormatter("level2"),
      accessor: "l2_name",
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    },
    {
      Header: levelFiltersLabelFormatter("level3"),
      accessor: "l3_name",
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    },
    {
      Header: levelFiltersLabelFormatter("level4"),
      accessor: "l4_name",
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    },
    {
      Header: levelFiltersLabelFormatter("level5"),
      accessor: "l5_name",
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    },
    {
      Header: "Demand Type",
      accessor: "Demand_Type_Filter_Default",
      Cell: (instance) => <SelectCell {...instance}
        dropdownOptions={tableData[instance.row.index]?.Demand_Type_Filter}
      />,
      // disableFilters: true,
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      filter: 'filterSingleSelectCell',
    },
    {
      Header: "Planned APS",
      accessor: "article_aps",
      Cell: (instance) => ((instance.row.original?.Demand_Type_Filter_Default?.[0]?.value == "APS" || instance.row.original?.Demand_Type_Filter_Default?.value == "APS") ?
        <InputCell {...instance} style={{ textAlign: "center" }} min={0} type="number" /> : (instance.row.original?.article_aps != undefined ? instance.row.original?.article_aps : '')
      ),
      Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      filter: 'between',
      disableSortBy: true
    },
    {
      Header: "Planned WOS",
      accessor: "article_wos",
      Cell: (instance) => <InputCell {...instance} style={{ textAlign: "center" }} min={0} type="number" />,
      Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      filter: 'between',
      disableSortBy: true
    },
    {
      Header: "Product Profile",
      accessor: "default_product_profiles",
      Cell: (instance) => <div className={StrategyCSS.multiselectDiv}>
        <SelectCell
          dropdownOptions={tableData[instance.row.index]?.product_profiles}
          {...instance} />
      </div>,
      disableFilters: true,
      width: 180,
    },
    {
      Header: "Size Profile",
      accessor: "size_desc",
      Cell: (instance) => (
        <div className={StrategyCSS.multiselectDiv}>
          <MultiSelectCell
            {...instance}
            dropdownOptions={tableData[instance.row.index]?.size_options}
            onBlurEvent={(rowIdx, columnId, values, article) => {
              let sizes = values?.map(val => val?.value)
              const callApi = async (req, apsReq) => {
                retainCheckboxState()
                setInitialIndex(Math.floor(rowIdx / recordsPerPage))
                try {
                  const [dcResponse, apsResponse] = await Promise.all([
                    // Api.post(GET_DC_DATA, req),
                    getInvComponent(req),
                    Api.post(GET_APS, { data: [apsReq] })
                  ]);
                  // let res = await Api.post(GET_SIZE_DC, req)
                  let data = await dcResponse
                  let apsData = await apsResponse?.data?.data?.[0]?.aps
                  let wosData = await apsResponse?.data?.data?.[0]?.wos
                  let total_inventory
                  if(isAshley()){
                    total_inventory = Math.max( Number(data?.oh_map) - (Number(data?.po_map) ? Number(data?.po_map) : 0) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
                  }
                  else {
                    total_inventory = Math.max(Number(data?.it_map) + Number(data?.oh_map) + Number(data?.oo_map) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
                  }
                  instance.updateMyData(rowIdx, "it", data?.it_map ? data?.it_map : 0);
                  instance.updateMyData(rowIdx, "oh", data?.oh_map ? data?.oh_map : 0);
                  instance.updateMyData(rowIdx, "oo", data?.oo_map ? data?.oo_map : 0);
                  instance.updateMyData(rowIdx, "reserve_quantity", data?.rq_map ? data?.rq_map : 0);
                  instance.updateMyData(rowIdx, "total_inventory", total_inventory ? total_inventory : 0);
                  instance.updateMyData(rowIdx, "final_total_inventory", '-');
                  instance.updateMyData(rowIdx, "edit_total_inventory", '');
                  instance.updateMyData(rowIdx, "article_aps", apsData ? apsData : 0);
                  instance.updateMyData(rowIdx, "article_wos", wosData ? wosData : 0);
                  instance.updateMyData(rowIdx, "allocated_units", data?.au_map ? data?.au_map : 0);
                }
                catch (e) {
                  instance.updateMyData(rowIdx, "it", 0);
                  instance.updateMyData(rowIdx, "oh", 0);
                  instance.updateMyData(rowIdx, "oo", 0);
                  instance.updateMyData(rowIdx, "reserve_quantity", 0);
                  instance.updateMyData(rowIdx, "total_inventory", 0);
                  instance.updateMyData(rowIdx, "final_total_inventory", '-');
                  instance.updateMyData(rowIdx, "edit_total_inventory", '');
                  instance.updateMyData(rowIdx, "article_aps", 0);
                  instance.updateMyData(rowIdx, "article_wos", 0);
                  instance.updateMyData(rowIdx, "allocated_units", 0);
                }
              }
              if (sizes.length) {
                let sizeValues = values?.map(val => val.value)
                let req = {
                  "size": sizeValues,
                  "store_code": instance.row.original?.default_dcs?.map(val => val.value),
                  "data": tableData[rowIdx],
                }
                let plannedApsReq = {
                  "size": sizeValues,
                  "article": [tableData[rowIdx].article],
                  "upc": getUPC(tableData[rowIdx].upcSizeMapping, sizeValues),
                  "store_group_code": instance.row.original?.default_store_eligibility_default?.map(val => val.value)
                }
                callApi(req, plannedApsReq);
              }
              else {
                instance.updateMyData(rowIdx, "it", 0);
                instance.updateMyData(rowIdx, "oh", 0);
                instance.updateMyData(rowIdx, "oo", 0);
                instance.updateMyData(rowIdx, "reserve_quantity", 0);
                instance.updateMyData(rowIdx, "total_inventory", 0);
                instance.updateMyData(rowIdx, "article_aps", 0);
                instance.updateMyData(rowIdx, "article_wos", 0);
                instance.updateMyData(rowIdx, "final_total_inventory", '-');
                instance.updateMyData(rowIdx, "edit_total_inventory", '');
                instance.updateMyData(rowIdx, "allocated_units", 0);
              }
            }}
          />
        </div>
      ),
      disableFilters: true
    },
    {
      Header: "Store Eligibility Groups",
      accessor: "default_store_eligibility_default",
      width: 250,
      Cell: (instance) => (
        <div className={StrategyCSS.multiselectDiv}>
          <MultiSelectCell
            {...instance}
            dropdownOptions={tableData[instance.row.index]?.store_groups}
            // defaultOptions={tableData[instance.row.index]?.store_groups}
            onBlurEvent={(rowIdx, columnId, values, article) => {
              let storeGroupCodes = values?.map(val => val?.value)
              const callApi = async (req, apsReq) => {
                retainCheckboxState()
                setInitialIndex(Math.floor(rowIdx / recordsPerPage))
                try {

                  const [minStockResponse, apsResponse] = await Promise.all([
                    Api.post(GET_STORE_GROUP_MIN_STOCK, req),
                    Api.post(GET_APS, { data: [apsReq] })
                  ]);
                  let data = await minStockResponse?.data?.data?.[0]
                  let apsData = await apsResponse?.data?.data?.[0]?.aps
                  let wosData = await apsResponse?.data?.data?.[0]?.wos

                  // let res = await Api.post(GET_STORE_GROUP_MIN_STOCK, req);
                  instance.updateMyData(rowIdx, "min_stock", data?.min_stock ? data?.min_stock : 0);
                  // instance.updateMyData(rowIdx, "delivery_date", data?.transit_time ? new Date(Date.now() + data?.transit_time * 24 * 60 * 60 * 1000) : new Date(Date.now()));
                  instance.updateMyData(rowIdx, "article_aps", apsData ? apsData : 0);
                  instance.updateMyData(rowIdx, "article_wos", wosData ? wosData : 0);
                }
                catch {
                  instance.updateMyData(rowIdx, "min_stock", 0);
                  instance.updateMyData(rowIdx, "article_aps", 0);
                  instance.updateMyData(rowIdx, "article_wos", 0);
                }
              }
              if (storeGroupCodes.length) {
                let sizeValues = instance.row.original?.size_desc?.map(val => val.value)
                let req = {
                  "store_group_code": values?.map(val => val?.value),
                  "article": [tableData[rowIdx].article],
                }
                let plannedApsReq = {
                  "store_group_code": values?.map(val => val?.value),
                  "article": [tableData[rowIdx].article],
                  "size": sizeValues,
                  "upc": getUPC(tableData[rowIdx].upcSizeMapping, sizeValues),
                }
                callApi(req, plannedApsReq);
              }
              else {
                instance.updateMyData(rowIdx, "min_stock", 0);
                instance.updateMyData(rowIdx, "article_aps", 0);
                instance.updateMyData(rowIdx, "article_wos", 0);
              }
              // let total = 0;
              // values.forEach((group) => {
              //   total += tableData[rowIdx].min_stock_obj[group?.value];
              // });
              let checkedStoreGroupCode = values?.map(val => val?.value)
              let noOfStores = getNoofStores(checkedStoreGroupCode)
              // let minPresQty = getMinPresQty(tableData[rowIdx].article)
              instance.updateMyData(rowIdx, "no_of_stores", noOfStores)
              // instance.updateMyData(rowIdx, "min_stock", total);
              // instance.updateMyData(rowIdx, "min_pres_qty", minPresQty);
            }}
          />
        </div>
      ),
      // disableFilters: true
      Filter: (instance) => <SelectColumnFilter {...instance} />,
      filter: 'filterMultiSelectCell',
    },
    {
      Header: "DC",
      accessor: "default_dcs",
      width: 250,
      Cell: (instance) => (
        <div className={StrategyCSS.multiselectDiv}>
          <MultiSelectCell
            {...instance}
            dropdownOptions={tableData[instance.row.index]?.dcs}
            onBlurEvent={(rowIdx, columnId, values, article) => {
              let dcStores = values?.map(val => val?.value)
              const callApi = async (req) => {
                // retainCheckboxState()
                // setInitialIndex(Math.floor(rowIdx / recordsPerPage))
                try {
                  // let res = await Api.post(GET_DC_DATA, req);
                  let data = getInvComponent(req)
                  let total_inventory
                  if(isAshley()){
                    total_inventory = Math.max( Number(data?.oh_map) - (Number(data?.po_map) ? Number(data?.po_map) : 0) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
                  }
                  else {
                    total_inventory = Math.max(Number(data?.it_map) + Number(data?.oh_map) + Number(data?.oo_map) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
                  }
                  instance.updateMyData(rowIdx, "default_inventory_sorce_filter", tableData[instance.row.index]?.Inventory_Source_Filter)
                  instance.updateMyData(rowIdx, "it", data?.it_map ? data?.it_map : 0);
                  instance.updateMyData(rowIdx, "oh", data?.oh_map ? data?.oh_map : 0);
                  instance.updateMyData(rowIdx, "oo", data?.oo_map ? data?.oo_map : 0);
                  instance.updateMyData(rowIdx, "reserve_quantity", data?.rq_map ? data?.rq_map : 0);
                  instance.updateMyData(rowIdx, "total_inventory", total_inventory ? total_inventory : 0);
                  instance.updateMyData(rowIdx, "final_total_inventory", '-');
                  instance.updateMyData(rowIdx, "edit_total_inventory", '');
                  instance.updateMyData(rowIdx, "allocated_units", data?.au_map ? data?.au_map : 0);
                }
                catch (e) {
                  instance.updateMyData(rowIdx, "it", 0);
                  instance.updateMyData(rowIdx, "oh", 0);
                  instance.updateMyData(rowIdx, "oo", 0);
                  instance.updateMyData(rowIdx, "reserve_quantity", 0);
                  instance.updateMyData(rowIdx, "total_inventory", 0);
                  instance.updateMyData(rowIdx, "final_total_inventory", '-');
                  instance.updateMyData(rowIdx, "edit_total_inventory", '');
                  instance.updateMyData(rowIdx, "allocated_units", 0);
                }
              }
              if (dcStores.length) {
                let req = {
                  "store_code": values?.map(val => val?.value),
                  "data": tableData[rowIdx],
                  "size": instance.row.original?.size_desc?.map(val => val.value),
                }
                callApi(req);
              }
              else {
                instance.updateMyData(rowIdx, "it", 0);
                instance.updateMyData(rowIdx, "oh", 0);
                instance.updateMyData(rowIdx, "oo", 0);
                instance.updateMyData(rowIdx, "reserve_quantity", 0);
                instance.updateMyData(rowIdx, "total_inventory", 0);
                instance.updateMyData(rowIdx, "final_total_inventory", '-');
                instance.updateMyData(rowIdx, "edit_total_inventory", '');
                instance.updateMyData(rowIdx, "allocated_units", 0);
              }
            }}
          />
        </div>
      ),
      disableFilters: true
    },
    {
      Header: "No. Of Stores",
      accessor: "no_of_stores",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      filter: 'between',
    },
    // {
    //   Header: "Phase In Date",
    //   accessor: "phase_in_date",
    //   Cell: (instance) => (
    //     <div id="phase_in_date">
    //       <DatePicker {...instance} domId={"phase_in_date"} />
    //     </div>
    //   ),
    //   disableFilters: true
    // },
    // {
    //   Header: "Phase Out Date",
    //   accessor: "phase_out_date",
    //   Cell: (instance) => <DatePicker {...instance} />,
    //   disableFilters: true
    // },
    // {
    //   Header: "Delivery Date",
    //   accessor: "delivery_date",
    //   Cell: (instance) => <DatePicker {...instance} />,
    //   disableFilters: true
    // },
    {
      Header: "Inventory Available",
      accessor: "oh",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      filter: 'between',
    },
    // {
    //   Header: "DC inv. On Order",
    //   accessor: "oo",
    //   Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    //   filter: 'between',
    // },
    // {
    //   Header: "DC inv. in Transit",
    //   accessor: "it",
    //   Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    //   filter: 'between',
    // },
    {
      Header: "User Reserved",
      accessor: "reserve_quantity",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      filter: 'between',
    },
    {
      Header: "PO/ASN Reserved",
      accessor: "po_reserve",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      filter: 'between',
    },
    {
      Header: "Allocated Units",
      accessor: "allocated_units",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      filter: 'between',
    },
    {
      Header: "Inv. Source",
      accessor: "default_inventory_sorce_filter",
      Cell: (instance) => <div className={StrategyCSS.multiselectDiv}>
        <MultiSelectCell
          isDisabled = {isAshley()}
          {...instance}
          dropdownOptions={tableData[instance.row.index]?.Inventory_Source_Filter}
          onDropDownChange={(rowIdx, columnId, values, article) => {
            let invSorce = 0;
            values.forEach((group) => {
              invSorce += Number(tableData[rowIdx][group?.value]);
            });
            invSorce -= tableData[rowIdx].reserve_quantity
            invSorce -= tableData[rowIdx].allocated_units
            invSorce = Math.max(invSorce, 0)
            instance.updateMyData(rowIdx, "total_inventory", invSorce)
            instance.updateMyData(rowIdx, "final_total_inventory", '-')
            instance.updateMyData(rowIdx, "edit_total_inventory", '');
          }}
        />
      </div>,
      disableFilters: true
    },
    {
      Header: "Net Available Inv.",
      accessor: "total_inventory",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      filter: 'between',
    },
    {
      Header: "User defined Inv in %",
      accessor: "edit_total_inventory",
      width: 180,
      Cell: (instance) => (
        instance.row.original?.Demand_Type_Filter_Default?.value === "Fixed" || instance.row.original?.Demand_Type_Filter_Default?.[0]?.value === "Fixed"  ?
        <InputCell {...instance} min={0} max={isPercent ? 100 : instance.row.original['total_inventory']} type="number"
          changeHandler={(rowIdx, columnId, values) => {
            let final_total_inventory = values != 0 ? Math.round(values * Number(instance.row.original['total_inventory']) / 100) : '-'
            instance.updateMyData(rowIdx, 'final_total_inventory', final_total_inventory);
          }}
        />
        : ""
      ),
      Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      filter: 'between',
      disableSortBy: true
    },
    {
      Header: "Final Total Available Inv.",
      accessor: "final_total_inventory",
      width: 180,
      Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    },
    // {
    //   Header: "Min Presentation Quantity",
    //   accessor: "min_pres_qty",
    //   Filter: ColumnFilter
    // },
    {
      Header: "Safety Stock at Store",
      accessor: "min_stock",
      Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      filter: 'between',
    },
  ];

  const cellRenderer = (inst, classname, multiply = null) => {
    let storeFlagClassName = inst.row.original.delta_store_flag ? StrategyCSS.storeFlagInfoCol : ''
    if (multiply) {
      return (<div className={classnames(StrategyCSS.cell, classname, storeFlagClassName)}>{(inst?.value * multiply).toFixed(1) + ' %'}</div>)
    }
    else {
      return (<div className={classnames(StrategyCSS.cell, classname, storeFlagClassName)}>{inst?.value}</div>)
    }

  };

  const setSortByHelperFunction = (p_instance) => {
    let overall = p_instance?.allColumns.filter(val => val.id === "overall_proportion_rounded")
    if (overall.length) {
      if (!overall[0].isSorted) {
        setSortBy([])
      }
      if (overall[0].isSortedDesc == true) {
        setSortBy([{ id: 'overall_proportion_rounded', desc: true }])
      }
      if (overall[0].isSortedDesc == false) {
        setSortBy([{ id: 'overall_proportion_rounded', desc: false }])
      }
    }
  }

  const storeDetailsCols = [
    {
      Header: 'DummyID',
      accessor: 'dummyId',
      Filter: ColumnFilter,
      sticky: "left",
    },
    {
      Header: "Store Information",
      sticky: "left",
      columns: [
        {
          Header: "Store ID",
          accessor: "store_code",
          Cell: (inst) => cellRenderer(inst, StrategyCSS.storeInfoCol),
          Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
          filter: 'bulkFilterSpaceSeperated',
        },
        {
          Header: "Store Name",
          accessor: 'store_name',
          Cell: (inst) => cellRenderer(inst, StrategyCSS.storeInfoCol),
          Filter: ColumnFilter,
          width: 180
        },
        {
          Header: "Store Grade",
          accessor: "store_grade",
          Cell: (inst) => cellRenderer(inst, StrategyCSS.storeInfoCol),
          Filter: ColumnFilter,
          filter: 'exactText',
        },
        {
          Header: "Store Index",
          accessor: "Store_Index",
          // Cell: (instance) => (
          //   <InputCell {...instance} min={1} roundOffOnChange type="number" disabled={!editStoreIndex} 
          //       changeHandler={(rowIdx, columnId, values) => {
          //         console.log(instance,rowIdx,columnId,storeTableData,selectedArticle?.value,'dbucdb')
          //       instance.updateMyData(rowIdx, 'APS/ROS', values*Number(storeTableData[selectedArticle?.value][rowIdx]['APS/ROS']));
          //     }}
          //   />
          // ),
          Filter: ColumnFilter
        },
      ],
    },
    {
      Header: "Reference Store",
      accessor: "ref_store",
      Cell: (instance) => (
        <SelectCellComponent
          defaultValue={instance.row.original?.ref_store}
          options={refStoreDropDownOptions}
          changeHandler={(rowIdx, columnId, values) => {
            if (isReviewChangesDisabled) {
              setIsReviewChangesDisabled(false)
            }
            setStoreTableDataCopy((oldData) => {
              let newData = cloneDeep(oldData);
              newData[selectedArticle?.value][rowIdx][columnId] = values;
              return newData;
            });
          }}
          {...instance} />
      ),
      disableFilters: true
    },
    {
      Header: "Product Profile Pen %",
      columns: [
        {
          Header: "Overall",
          accessor: "overall_proportion_rounded",
          Cell: (inst) => cellRenderer(inst, StrategyCSS.productProfileCol, 1),
          Filter: NumberRangeColumnFilter,
          filter: 'between',
          sortType: "basic"
        },
      ],
    },
    {
      Header: "Constraints",
      columns: [
        {
          Header: "APS/User Defined ROS",
          accessor: "APS/ROS_rounded",
          Cell: (instance) => (
            <InputCell {...instance} min={0} type="number"
              debouncedChangeHandler={(rowIdx, columnId, values) => {
                // setSortByHelperFunction(instance)
                let l_estimatedDemand = getEstimatedDemand(instance?.data[rowIdx], columnId, values)
                instance.updateMyData(rowIdx, 'estimated_demand', l_estimatedDemand);
                setStoreTableDataCopy((oldData) => {
                  let newData = cloneDeep(oldData);
                  newData[selectedArticle?.value][rowIdx][columnId] = values;
                  newData[selectedArticle?.value][rowIdx]['APS/ROS'] = Number(values);
                  newData[selectedArticle?.value][rowIdx]['estimated_demand'] = l_estimatedDemand;
                  return newData;
                });
              }} />
          ),
          Filter: NumberRangeColumnFilter,
          filter: 'between',
          // disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: "Min",
          accessor: "min_stock",
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "Max",
          accessor: "max_stock",
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "WOS",
          accessor: "WOS_rounded",
          Cell: (instance) => <InputCell min={0} {...instance} type="number"
            debouncedChangeHandler={(rowIdx, columnId, values) => {
              //   setSortByHelperFunction(instance)
              let l_estimatedDemand = getEstimatedDemand(instance?.data[rowIdx], columnId, values)
              instance.updateMyData(rowIdx, 'estimated_demand', l_estimatedDemand);
              setStoreTableDataCopy((oldData) => {
                let newData = cloneDeep(oldData);
                newData[selectedArticle?.value][rowIdx][columnId] = values;
                newData[selectedArticle?.value][rowIdx]['WOS'] = Number(values);
                newData[selectedArticle?.value][rowIdx]['estimated_demand'] = l_estimatedDemand;
                return newData;
              });
            }}
          />,
          Filter: NumberRangeColumnFilter,
          filter: 'between',
          // disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: "Estimated Demand",
          accessor: "estimated_demand",
          Filter: ColumnFilter,
          disableSortBy: true,
        },
        {
          Header: "Transit Time",
          accessor: (data) => data["transit_time"].toFixed(1),
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "Store On Hand",
          accessor: "onhand",
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "Store On Order",
          accessor: "onorder",
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "Store In Transit",
          accessor: "intransit",
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
      ],
    },
  ];

  const selectedArticleStoreTableData = () => {
    if (storeTableData[selectedArticle?.value]) {
      storeTableData[selectedArticle?.value][0].size_desc.forEach((d) => {
        storeDetailsCols[3].columns.push({
          Header: d,
          accessor: data => data[d],
          Cell: (inst) => cellRenderer(inst, StrategyCSS.productProfileCol, 1),
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        });
      });
      const data = storeTableData[selectedArticle?.value]?.map((d) => {
        d.size_desc.forEach((s, idx) => {
          d[s] = (d.size_level_proportion_rounded[idx])
          // * 100).toFixed(1);
        });
        return d;
      });
      if (!isEqual(selectedArticleStoreConst, data)) {
        console.log(data, articleStoreMapping[selectedArticle?.value], storeCode, 'd,jvbidfhvu')
        if (!isEmpty(storeCode))
          setSortBy([{ id: 'dummyId', desc: true }])
        else
          setSortBy([{ id: 'overall_proportion_rounded', desc: true }])

        if (!isEmpty(storeCode) && isEmpty(articleStoreMapping[selectedArticle?.value])) {
          let initialSelectedRows = {}
          let storeCodes = storeCode.map(val => val.value).filter(val => val != "*")
          data.forEach((item, index) => {
            if (storeCodes.includes(item.store_code)) {
              initialSelectedRows[index] = true
              data[index].dummyId = 1
            }
          })
          setIntitalSelectedStoreRows(initialSelectedRows)
        }
        else {
          setIntitalSelectedStoreRows(articleStoreMapping[selectedArticle?.value])
        }
        setSelectedArticleStoreConst(() => data);
        const refStores = data?.map((d) => ({
          label: d.store_code,
          value: d.store_code,
        }));
        setRefStoreDropDownOptions(() => refStores);
      }
      return true;
    }
    return false;
  };

  const dcTableCols = [
    {
      Header: "Inv. Source Code",
      accessor: "dc_code",
    },
    {
      Header: "Inv. Source Name",
      accessor: "dc_name",
      width: 200,
    },
    {
      Header: "Available Inv.",
      columns: [],
    },
    // {
    //   Header: "Single On Hand",
    //   columns: [],
    // },
    // {
    //   Header: "Single On Order",
    //   columns: [],
    // },
    // {
    //   Header: "Single In Transit",
    //   columns: [],
    // },
    {
      Header: "Quantity Reserved",
      columns: [],
    },
    {
      Header: "Allocated Units",
      columns: [],
    },
  ];

  const selectedArticleDcTableData = () => {
    if (dcTableData[selectedArticle?.value]) {
      let shouldDisable = storeRequest?.filter(val => val.Product_Code == selectedArticle?.value)?.[0]?.hasEdited
      // if(shouldDisable)
      //   setSaveIsDisabled(true)
      // else
      //   setSaveIsDisabled(false)
      dcTableData[selectedArticle?.value][0].size.forEach((d) => {
        dcTableCols[2].columns.push({
          Header: d,
          accessor: (data) => data[`Total_Available_Inventory_${d}`],
          width: 150,
        });

        dcTableCols[4].columns.push({
          Header: d + '  ',
          accessor: (data) => data[`Allocated_Reserve_Quantity_${d}`],
          width: 150,
        });

        dcTableCols[3].columns.push({
          Header: d + ' ',
          width: 150,
          disableSortBy: true,
          accessor: (data) => data[`Reserve_Quantity_${d}`],
          Cell: (instance) => <InputCell {...instance} disabled={shouldDisable} min={0} type="number" max={instance.row.original[`Total_Available_Inventory_${d}`]} />,
        });
        // dcTableCols[2].columns.push({
        //   Header: d,
        //   accessor: `onorder_${d}`,
        // });
        // dcTableCols[3].columns.push({
        //   Header: d,
        //   accessor: `intransit_${d}`,
        // });
      });
      const data = dcTableData[selectedArticle?.value]?.map((d) => {
        d.size.forEach((s, idx) => {
          d[`Total_Available_Inventory_${s}`] = d.Total_Available_Inventory[idx];
          d[`Reserve_Quantity_${s}`] = d.Reserve_Quantity[idx];
          d[`Allocated_Reserve_Quantity_${s}`] = d.Allocated_Reserve_Quantity[idx];
          // d[`onhand_${s}`] = d.onhand[idx];
          // d[`onorder_${s}`] = d.onorder[idx];
          // d[`intransit_${s}`] = d.intransit[idx];
        });
        return d;
      });
      if (!isEqual(selectedArticleDcData, data)) {
        setSelectedArticleDcData(() => data);
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isEmpty(props?.location?.state?.filters)) {
      let filters = props?.location?.state?.filters
      if(props?.location?.state?.isPOASNFlow) {
        setIsPOASNFlow(true)
        setPODetails(props?.location?.state?.poDetails)
        setPOASNHiddenColumns(["default_dcs"])
      }
      setLevel1Label(props?.location?.state?.filters?.level1Label ? props?.location?.state?.filters?.level1Label[0] : levelFiltersLabelFormatter("level1"))
      setLevel2Label(props?.location?.state?.filters?.level2Label ? props?.location?.state?.filters?.level2Label[0] : levelFiltersLabelFormatter("level2"))
      setLevel3Label(props?.location?.state?.filters?.level3Label ? props?.location?.state?.filters?.level3Label[0] : levelFiltersLabelFormatter("level3"))
      setLevel5Label(props?.location?.state?.filters?.level5Label ? props?.location?.state?.filters?.level5Label[0] : levelFiltersLabelFormatter("level5"))
      setLevel4Label(props?.location?.state?.filters?.level4Label ? props?.location?.state?.filters?.level4Label[0] : levelFiltersLabelFormatter("level4"))
      setDivisionOptions(filters.level1)
      setDepartmentOptions(filters?.level2?.length && filters.level2[0])
      setLevel4Options(filters?.level4?.length && filters.level4[0])
      setLevel5Options(filters?.level5?.length && filters.level5[0])
      setSubdepartmentOptions(filters?.level3?.length && filters.level3[0])
      setDivision(filters.level1)
      setDepartment(filters?.level2?.length && filters.level2[0])
      setSubdepartment(filters?.level3?.length && filters.level3[0])
      setLevel4(filters?.level4?.length && filters.level4[0])
      setLevel5(filters?.level5?.length && filters.level5[0])
      setDisableFilters(true);
      setLoader(true)
      let filtersObj = { level1: filters.level1 }
      if (filters?.level2?.length && filters.level2[0]) {
        filtersObj["level2"] = filters?.level2?.length && filters.level2[0]
      }
      if (filters?.level3?.length && filters.level3[0]) {
        filtersObj["level3"] = filters?.level3?.length && filters.level3[0]
      }
      if (filters?.level4?.length && filters.level4[0]) {
        filtersObj["level4"] = filters?.level4?.length && filters.level4[0]
      }
      if (filters?.level5?.length && filters.level5[0]) {
        filtersObj["level5"] = filters?.level5?.length && filters.level5[0]
      }
      setSelectedFilters(filtersObj)
    }
    else {
      getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDivisionChange)
      props.fetchFilterData({ key: "divisions" });
    }
    return () => {
      props.resetFiltersOptions([]);
    }
  }, []);

  useEffect(() => {
    props.divisions.length && setDivisionOptions(() => props.divisions);
    props.storeCode.length && setStoreCodeOptions(() => props.storeCode);
    props.departments.length && setDepartmentOptions(() => props.departments);
    props.subdepartments.length && setSubdepartmentOptions(() => props.subdepartments);
    props.level4.length && setLevel4Options(() => props.level4);
    props.level5?.length && setLevel5Options(() => props.level5);
  }, [props.divisions, props.departments, props.subdepartments, props.level4, props.level5, props.storeCode]);

  useEffect(() => {
    if(props.error) {
      setLoader(false)
    }
    if (props.tableData && loaderdts) {
      setLoader(false)
    }
    if (isContinueAllocation) {
      let reviewTableData = cloneDeep(draftInstance?.data?.reviewTableData || [])
      let articleIds = reviewTableData.map(item => item.article)
      props.tableData.map(item => {
        if (!(articleIds.includes(item.article))) {
          reviewTableData.push(item)
        }
      })

      setTableData(() => reviewTableData);
      setTimeout(() => {
        populateStoreDetailsDropdown(true, false, draftInstance)
      }, 1000)
      // setTimeout(() => {
      setIsLoading(false)
      // }, 1000)
    }
    else {
      setTableData(() => props?.tableData)
    }
    setStoreGroupStoreResult(() => props.storeGroupStoreResult)
    setStoreMinStockResult(() => props.storeMinStockResult)
  }, [props.tableData, props.storeGroupStoreResult, props.storeMinStockResult, props.error]);

  useEffect(() => {
    if (!isEmpty(props.storeTableData)) {
      setStoreTableData((old) => {
        return { ...old, ...props.storeTableData }
      });
      setStoreTableDataCopy((old) => {
        return { ...old, ...props.storeTableData }
      });
    }
    else {
      setStoreTableData((old) => {
        return old
      })
      setStoreTableDataCopy((old) => {
        return old
      })
    }
  }, [props.storeTableData])

  useEffect(() => {
    if (props.dcTableData) {
      setDcTableData((old) => {
        return { ...old, ...props.dcTableData }
      });
    }
    else {
      setDcTableData((old) => {
        return old
      })
    }
  }, [props.dcTableData])

  useEffect(() => {
    if (props.saveError) {
      Notify.error("Error in saving reserve quantity!!")
      setSaveIsDisabled(false)
    }
    if (props.saveSuccess) {
      props.fetchDcTableData(dcRequest.filter(val => val["Product_Code"] == selectedArticle?.value))
      // console.log(selectedArticle?.value,dcRequest,'sbdhbsj')
      Notify.success("Saved Successfully!!")
      setSaveIsDisabled(false)
    }
  }, [props.saveError, props.saveSuccess])

  useEffect(() => {
    if (props.createAllocationError) {
      setErrorModalMessage(props.createAllocationError?.message)
      if (!isEmpty(props.createAllocationError?.data)) {
        setFailedArticleId(props.createAllocationError?.data)
        setButtonLabel("Exclude All")
      }
      else {
        setFailedArticleId(null)
        setButtonLabel("Ok")
      }
    }

    // below code is for Ashleys with Gurobi Integration

    if(useSSE) {
      if (props.createAllocationSuccess && props.createAllocationData) {
        // eventSource = new EventSource("http://localhost:8000/api/simulation/allocate-stream?uuid=1000");
        eventSource = new EventSource(`${SSE_CREATE_ALLOCATON}?uuid_str=${props.createAllocationData}`)
        eventSource.onmessage = (event) => {
          const result = JSON.parse(event.data).data;
          console.log("received:", result);
          if (result.status && result.allocation_code) {
            let user_id = Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : ""
            props.deleteDraft({ allocation_id: currentDraftallocationCode, user_id })
            setIsNewDraftSaved(true)
            Notify.success("Success in creating allocation!!")
            setTimeout(() => {
              props.history.push(FINALIZE_PAGE, { planDetails: {allocationCode: result.allocation_code, allocationName: inputRef?.current?.value, isPOASN: isPOASNFlow, po_number: poDetails?.po_id, asn: poDetails?.asn_id, creation_date: dateFormat(new Date()) } });
              reloadPageWithUpdateToken()
            }, 3000);
  
          }
          if(!result.status) {
            props.setLoaderToFalse()
            Notify.error(result?.message || "Something Went Wrong!!")
          }
          eventSource.close();
          // call function with allocation id the navigate to finalize screen
        }
  
        eventSource.onerror = (event) => {
          console.log(event.target.readyState)
          if (event.target.readyState === EventSource.CLOSED) {
            console.log('SSE closed (' + event.target.readyState + ')')
          }
          eventSource.close();
          props.setLoaderToFalse()
          Notify.error("Something Went Wrong!!")
        }
  
        eventSource.onopen = (event) => {
          console.log("connection opened")
        }
      }
    }
    else {
      // below code is for Puma and other clients without Gurobi Integration
        if (props.createAllocationSuccess && props.createAllocationData) {
          let user_id = Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : ""
          props.deleteDraft({ allocation_id: currentDraftallocationCode, user_id })
          setIsNewDraftSaved(true)
          Notify.success("Success in creating allocation!!")
          setTimeout(() => {
            props.history.push(FINALIZE_PAGE, { planDetails: {allocationCode: props.createAllocationData, allocationName: inputRef?.current?.value, isPOASN: isPOASNFlow, po_number: poDetails?.po_id, asn: poDetails?.asn_id, creation_date: dateFormat(new Date()) } });
          }, 3000);
        }
    }
  }, [props.createAllocationError, props.createAllocationSuccess, props.createAllocationData, useSSE])

  // useEffect(() => {
  //   if (props.createAllocationSuccess && props.createAllocationData) {
  //     // eventSource = new EventSource("http://localhost:8000/api/simulation/allocate-stream?uuid=1000");
  //     eventSource = new EventSource(`${SSE_CREATE_ALLOCATON}?uuid_str=${props.createAllocationData}`)
  //     eventSource.onmessage = (event) => {
  //       const result = JSON.parse(event.data).data;
  //       console.log("received:", result);
  //       if (result.status && result.allocation_code) {
  //         let user_id = Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : ""
  //         props.deleteDraft({ allocation_id: currentDraftallocationCode, user_id })
  //         setIsNewDraftSaved(true)
  //         Notify.success("Success in creating allocation!!")
  //         setTimeout(() => {
  //           props.history.push(FINALIZE_PAGE, { allocationCode: result.allocation_code, allocationName: inputRef?.current?.value });
  //         }, 3000);

  //       }
  //       eventSource.close();
  //       // call function with allocation id the navigate to finalize screen
  //     }

  //     eventSource.onerror = (event) => {
  //       console.log(event.target.readyState)
  //       if (event.target.readyState === EventSource.CLOSED) {
  //         console.log('SSE closed (' + event.target.readyState + ')')
  //       }
  //       eventSource.close();
  //     }

  //     eventSource.onopen = (event) => {
  //       console.log("connection opened")
  //     }
  //   }
  //     // eventSource.close();

  // }, [props.createAllocationSuccess,props.createAllocationData])

  useEffect(() => {
    if (props.allocationCode && props.allocationCodeSuccess) {
      setTimeout(() => {
        props.history.push(FINALIZE_PAGE, { planDetails: {allocationCode: props.allocationCode, allocationName: inputRef?.current?.value, isPOASN: isPOASNFlow, po_number: poDetails?.po_id, asn: poDetails?.asn_id, creation_date: dateFormat(new Date()) } });
      }, 2000);
    }
    if (props.allocationCodeError) {
      Notify.error("Error in saving allocation!!")
    }
  }, [props.allocationCode, props.allocationCodeSuccess, props.allocationCodeError]);

  // useEffect(() => {
  //   if (!isContinueAllocation && !isShowDrafts) {
  //     return () => {
  //       props.enableFilters();
  //     };
  //   }
  // });

  useEffect(() => {

    return () => {
      props.resetAll()
    }
  }, [])


  const onDivisionChange = (val) => {
    // showDrafts(false)
    let payload = {};
    if (val) {
      payload = { level1: [val], key: "departments" };
    }
    setDivision(val);
    resetRestFilters(0)
    // API call
    props.fetchFilterData(payload);

  };

  const onDepartmentChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that)
    setDepartment(valuesArray[1])
    setRequest(old => {
      return {
        ...old,
        'department': valuesArray[0]
      }
    })
    let req_val = intersectionWith(valuesArray[0], departmentOptions, isEqual);
    // setDepartment(valuesArray[0]);
    resetRestFilters(1)
    // API call
    if (valuesArray[0].length) {
      props.fetchFilterData({
        level1: [division],
        level2: req_val,
        key: "subdepartments",
      });
    }
  };

  const onSubdepartmentChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that)
    // let value = intersectionWith(val, subdepartmentOptions, isEqual);
    setSubdepartment(valuesArray[1]);
    setRequest(old => {
      return {
        ...old,
        'subDepartment': valuesArray[0]
      }
    })
    let req_val = intersectionWith(valuesArray[0], subdepartmentOptions, isEqual);
    resetRestFilters(2)
    if (valuesArray[0].length) {
      props.fetchFilterData({
        level1: [division],
        level2: request?.department,
        level3: req_val,
        key: "level4",
      });
    }
    // setSubdepartment(() => {
    //   // API call for fetch table data
    //   // setTableData([]);
    //   setTableInstance(null);
    //   return value;
    // });
  };

  const onLevel4Change = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that)
    // let value = intersectionWith(val, level4Options, isEqual);
    setLevel4(() => {
      setTableInstance(null);
      return valuesArray[1];
    });
    setRequest(old => {
      return {
        ...old,
        'level4': valuesArray[0]
      }
    })
    let req_val = intersectionWith(valuesArray[0], level4Options, isEqual);
    resetRestFilters()
    if (valuesArray[0].length) {
      props.fetchFilterData({
        level1: [division],
        level2: request?.department,
        level3: request?.subDepartment,
        level4: req_val,
        key: "level5",
      });
    }
  }

  const onLevel5Change = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that)
    // let value = intersectionWith(val, level4Options, isEqual);
    setLevel5(() => {
      setTableInstance(null);
      return valuesArray[1];
    });
    setRequest(old => {
      return {
        ...old,
        'level5': valuesArray[0]
      }
    })
  }

  const onStoreCodeChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that)
    console.log(valuesArray, 'fvrvfdcs')
    setStoreCode(() => {
      setTableInstance(null);
      return (!isEmpty(valuesArray[1]) ? valuesArray[1] : null);
    });
    setRequest(old => {
      return {
        ...old,
        'store_code': valuesArray[0]
      }
    })
  }

  const applyFilters = () => {
    setErrorModalMessage(null)
    setShowSetAll(false)
    setInitialIndex(0)
    setArticleStoreMapping({})
    setDcTableData([])
    setStoreTableData([])
    setHideDcStoreSection(true)
    if ((division
      // && request?.department?.length && request?.subDepartment?.length
    )) {
      setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": division })
      // setAllocationName(null)
      setCurrentDraftallocationCode("")
      setIsContinueAllocation(false)
      setIsNewDraftSaved(false)
      setInitialSelectedRows({})
      setShowDrafts(false)
      setSelectedFilters({
        level1: [division],
        level2: request?.department,
        level3: request?.subDepartment,
        level4: request?.level4,
        level5: request?.level5,
        dc: [],
      })
      props.fetchStrategyTableData({
        level1: [division],
        level2: request?.department,
        level3: request?.subDepartment,
        level4: request?.level4,
        level5: request?.level5,
        dc: [],
      });
    }
    else {
      Notify.error("Please select all mandatory options!!")
    }
  };



  const setAllHandler = (p_instance, p_table) => {
    let msg;
    if (Object.keys(p_instance.state.selectedRowIds).length > 0) {
      if (p_table === "main_table") {
        setShowSetAll(true);
        shouldDisabled('Demand_Type_Filter_Default')
      }
      else {
        setShowSetAllStoreDetails(true);
        setapsSA(null)
        setwosSA(null)
      }
    }
    else {
      if (p_table === "main_table")
        msg = "product"
      else
        msg = "store"
      Notify.error(`Please select atleast 1 ${msg} to use Set All functionality!!`)
    }
  }


  const shouldShowAlert = (isInitialDraftCall, isCallApi, instance) => {
    if (storeCode) {
      let selectedArticles = {};
      Object.keys(tableInstance.state.selectedRowIds).forEach((rowId) => {
        selectedArticles[tableInstance.preFilteredRowsById[rowId].original.article] =
          tableInstance.preFilteredRowsById[rowId].original;
      });

      let l_storeGroups = []

      Object.keys(selectedArticles)?.forEach((o) => {
        l_storeGroups.push(...selectedArticles[o].default_store_eligibility_default)
      })

      let l_distinctStoreGroups = [...new Map(l_storeGroups.map(item => [item['value'], item])).values()]
      let l_messageObject = {}
      let l_showAlert = false;
      let l_storeMapping, l_storeCodes, l_excludedStores
      l_distinctStoreGroups.forEach(val => {
        l_storeMapping = storeGroupStoreResult[val?.value]
        l_storeCodes = storeCode.map(val => val.value).filter(val => val != "*")
        l_excludedStores = l_storeCodes.filter(x => !l_storeMapping.includes(x));
        if (l_excludedStores.length) {
          l_messageObject[val?.label] = l_excludedStores
          l_showAlert = true
        }
      })
      if (l_showAlert) {
        let msg = []
        msg.push(<h4 style={{ margin: '5px 0 1.5rem' }}>The selected stores are excluded from the following store groups:</h4>)
        for (let i in l_messageObject) {
          msg.push(<li><span><b>{`${i} : # ${l_messageObject[i]}`}</b></span></li>)
        }
        msg.push(<h4 style={{ margin: '1.5rem 0 5px' }}> Are you sure you wish to continue?</h4>)
        setShowStoreAlert(true)
        setStoreAlertMessage(msg)
      }
      else {
        populateStoreDetailsDropdown(isInitialDraftCall, isCallApi, instance)
      }
    }
    else
      populateStoreDetailsDropdown(isInitialDraftCall, isCallApi, instance)
  }

  const populateStoreDetailsDropdown = (isInitialDraftCall, isCallApi, instance) => {
    setTotalEstimatedDemad(null)
    setErrorModalMessage(null)
    setStoreTableData(instance?.data?.DetailsTableData?.storeTableData || [])
    setStoreTableDataCopy(instance?.data?.DetailsTableData?.storeTableData || [])
    setDcTableData(instance?.data?.DetailsTableData?.dcTableData || [])
    setSelectedArticleStoreConst(null);
    setSelectedArticleDcData(null);
    if (isCallApi) {
      setSelectedArticleWithData([])
    }
    else {
      setSelectedArticleWithData(Object.keys(instance?.data?.DetailsTableData?.storeTableData));
    }
    setReviewBtnDisabled(true)
    setIsReviewChangesDisabled(true)
    if (Object.keys(tableInstance.state.selectedRowIds).length > 0) {
      const selectedArticles = {};
      let articleOptions = [];
      Object.keys(tableInstance.state.selectedRowIds).forEach((rowId) => {
        selectedArticles[tableInstance.preFilteredRowsById[rowId].original.article] =
          tableInstance.preFilteredRowsById[rowId].original;
        articleOptions.push({
          label: tableInstance.preFilteredRowsById[rowId].original.article,
          value: tableInstance.preFilteredRowsById[rowId].original.article,
        });
      });

      setSelectedArticlesDraftData(Object.values(selectedArticles))

      let isTotInvZero = false;
      let isWosZero = false;
      let isProductProfileNotMapped = false;
      let selectedArticleIdsWithTotInvZero = []
      let selectedArticleIdsWithNoPP = []
      let selectedArticleIdsWithZeroWOS = []
      let selectedArticleIdsWithNoSG = []
      let selectedArticleIdsWithNoDC = []
      let selectedArticlesWithNoFixedInv = []
      Object.keys(selectedArticles)?.map((o) => {
        if (!selectedArticles[o].total_inventory) {
          isTotInvZero = true;
          selectedArticleIdsWithTotInvZero.push(selectedArticles[o].article)
        }
        if (isEmpty(selectedArticles[o].default_product_profiles)) {
          isProductProfileNotMapped = true;
          selectedArticleIdsWithNoPP.push(selectedArticles[o].article)
        }
        if (!selectedArticles[o].article_wos) {
          isWosZero = true;
          selectedArticleIdsWithZeroWOS.push(selectedArticles[o].article)
        }
        if (!selectedArticles[o].default_store_eligibility_default.length) {
          selectedArticleIdsWithNoSG.push(selectedArticles[o].article)
        }
        if (!selectedArticles[o].default_dcs.length) {
          selectedArticleIdsWithNoDC.push(selectedArticles[o].article)
        }
        if((selectedArticles[o].Demand_Type_Filter_Default?.[0]?.value === "Fixed" || selectedArticles[o].Demand_Type_Filter_Default?.value === "Fixed") && !Number(selectedArticles[o].edit_total_inventory)) {
          selectedArticlesWithNoFixedInv.push(selectedArticles[o].article)
        }
      })
      if (isTotInvZero) {
        setErrorModalMessage(`Total Available Inventory for ${selectedArticleIdsWithTotInvZero} article(s) is zero!!`)
        return;
      }
      if (isProductProfileNotMapped) {
        setErrorModalMessage(`Product Profile for ${selectedArticleIdsWithNoPP} article(s) is not Mapped!!`)
        return;
      }
      if (isWosZero) {
        setErrorModalMessage(`Planned WOS for ${selectedArticleIdsWithZeroWOS} article(s) is Zero!!`)
        return;
      }
      if(selectedArticleIdsWithNoSG.length) {
        setErrorModalMessage(`Store Eligibility for ${selectedArticleIdsWithNoSG} article(s) is not Mapped!!`)
        return;
      }
      if(selectedArticleIdsWithNoDC.length) {
        setErrorModalMessage(`DC for ${selectedArticleIdsWithNoDC} article(s) is not Mapped!!`)
        return;
      }
      if(selectedArticlesWithNoFixedInv.length) {
        setErrorModalMessage(`Please Enter User Defined Inv % for ${selectedArticlesWithNoFixedInv} article(s) !!`)
        return
      }
      if (isInitialDraftCall) {
        let draftsArticleOptions = instance?.data.reviewTableData
          .map(item => ({ label: item.article, value: item.article }))

        if (draftsArticleOptions[0].value != Object.keys(instance?.data?.DetailsTableData?.storeTableData)[0]) {
          let index = draftsArticleOptions?.findIndex(op => op.value == Object.keys(instance?.data?.DetailsTableData?.storeTableData)[0])
          let first = draftsArticleOptions[0]
          draftsArticleOptions[0] = draftsArticleOptions[index]
          draftsArticleOptions[index] = first
          articleOptions = draftsArticleOptions
        }
      }
      setReviewBtnDisabled(false);
      setArticles(() => selectedArticles);
      setArticleOptions(() => articleOptions);
      setSelectedArticle(() => articleOptions[0]);

      const dcRequest = [];

      const request = Object.keys(selectedArticles)?.map((o) => {
        const size_lst = selectedArticles[o].size_desc?.map((o) => o?.value);
        const dc_codes = selectedArticles[o].default_dcs?.map((o) => o?.value);
        const selectedStores = storeCode?.map((o) => o?.value).filter(val => val != "*")

        let demandType, productProfileCode, inventorySource
        if (Array.isArray(selectedArticles[o].Demand_Type_Filter_Default)) {
          demandType = selectedArticles[o].Demand_Type_Filter_Default[0]?.value
        }
        else {
          demandType = selectedArticles[o].Demand_Type_Filter_Default?.value
        }

        if (Array.isArray(selectedArticles[o].default_product_profiles)) {
          productProfileCode = selectedArticles[o].default_product_profiles[0]?.value
        }
        else {
          productProfileCode = selectedArticles[o].default_product_profiles?.value
        }

        inventorySource = selectedArticles[o]?.default_inventory_sorce_filter?.map(val => val?.value)
        let inventoryMapping = {
          "oo": "onorder",
          "oh": "onhand",
          "it": "intransit"
        }
        let selectedInvsource = []
        inventorySource?.forEach((val, index) => {
          if(inventoryMapping[val]) {
            selectedInvsource.push(inventoryMapping[val])
          }
        })
        let dcReqObj = {
          Size_List: size_lst,
          Product_Code: selectedArticles[o].article,
          Inventory_Source: selectedInvsource,
        }
        if(isPOASNFlow) {
          dcReqObj.source = tableData[0]?.Inventory_Source_Filter[0]?.label
          // dcReqObj.po_ids = poDetails.po_id
          // dcReqObj.asn_id = poDetails.asn_id

          if(poDetails?.inventory_source === "PO" && poDetails?.status === "Pending" ) {
            dcReqObj.po_ids = poDetails.po_id
          }
          if(poDetails?.inventory_source === "ASN" && poDetails?.status === "Pending" ){
            dcReqObj.asn_ids = poDetails.asn_id
            dcReqObj.po_ids = poDetails.po_id
          }
          if(poDetails?.status === "Error" || poDetails?.status === "Mismatch"){
            dcReqObj.po_ids = poDetails.po_id
          }
        }
        else {
          dcReqObj.DC_Codes = dc_codes
        }
        dcRequest.push(dcReqObj);


        let requestObj =  {
          Product_Profile_Code: productProfileCode,
          Product_Code: selectedArticles[o].article,
          Store_Group_Code: selectedArticles[o].default_store_eligibility_default?.map(
            (o) => o?.value
          ),
          Size_List: size_lst,
          Demand_Type: isNaN(selectedArticles[o].final_total_inventory) || selectedArticles[o].final_total_inventory == 0 ? demandType : "Fixed",
          // Delivery_Date: moment(selectedArticles[o]["delivery_date"]).format("YYYY-MM-DD"),
          Inventory_Source: selectedInvsource,
          Original_Total_Inventory: Number(selectedArticles[o].total_inventory),
          Planned_APS: Number(selectedArticles[o].article_aps),
          Planned_WOS: Number(selectedArticles[o].article_wos),
          User_Defined_Inventory: Number(selectedArticles[o].final_total_inventory),
          hasEdited: isNaN(selectedArticles[o].final_total_inventory) ? false : true,
          User_Selected_Stores: selectedStores,
        };
        if(isPOASNFlow) {
          console.log(poDetails,'cdcs')
          requestObj.source = tableData[0]?.Inventory_Source_Filter[0]?.label
          // requestObj.po_ids = poDetails.po_id
          // requestObj.asn_id = poDetails.asn_id
          if(poDetails?.inventory_source === "PO" && poDetails?.status === "Pending" ) {
            requestObj.po_ids = poDetails.po_id
          }
          if(poDetails?.inventory_source === "ASN" && poDetails?.status === "Pending" ){
            requestObj.asn_ids = poDetails.asn_id
            requestObj.po_ids = poDetails.po_id
          }
          if(poDetails?.status === "Error" || poDetails?.status === "Mismatch"){
            requestObj.po_ids = poDetails.po_id
          }
        }
        else {
          requestObj.DC_Codes = dc_codes
        }
        return requestObj
      });
      setStoreRequest(request)
      setDcRequest(dcRequest)

      if (!isInitialDraftCall) {
        props.fetchStoreTableData(request.filter(val => val.Product_Code == articleOptions[0]?.value));
        props.fetchDcTableData(dcRequest.filter(val => val.Product_Code == articleOptions[0]?.value));
      }
      // props.fetchStoreTableData(request);
      // props.fetchDcTableData(dcRequest);
    }
    else {
      Notify.error("Please select atleast 1 product to review!!")
    }
  };

  /**
   * Review Forecast handler function to render Ada Section 
   */
  const handleReviewForecast = () => {
    const { selectedRowIds } = tableInstance.state;
    const { preFilteredRowsById } = tableInstance;
    const { setAdaAllocationRequest, setAdaCurrentDate } = props;
    const currentDate = moment().format("YYYYWW");
    setAdaCurrentDate(currentDate);

    if (tableInstance && Object.keys(selectedRowIds).length > 0) {
      setReviewBtnDisabled(true);
      setForecastRevDisableState(false);
      const articles = {};
      const selectedArticles = [];
      const selectedStoreIds = [];

      Object.keys(selectedRowIds).forEach((rowId) => {
        articles[preFilteredRowsById[rowId].original.article] = {
          store: preFilteredRowsById[rowId].original.store_groups.map(i =>
            storeGroupStoreResult[i.value]).flat(1),
        }

        selectedArticles.push(preFilteredRowsById[rowId].original.article);
        selectedStoreIds.push(preFilteredRowsById[rowId].original.store_groups.map(i =>
          storeGroupStoreResult[i.value]).flat(1));
      });

      const { startFiscalYearWeek, endFiscalYearWeek } = props;
      let req = {
        adaReq: {
          start_fw: startFiscalYearWeek,
          end_fw: endFiscalYearWeek,
          current_fw: currentDate,
          articles: {
            ...articles,
          }
        },
        selectedArticles: [...selectedArticles],
        selectedStoreIds: [...selectedStoreIds.flat(1)],
        level1: [division["value"]],
        level2: request?.department?.map(i => i.value),
        level3: request?.subDepartment?.map(i => i.value),
        level4: request?.level4?.map(i => i.value),
        level5: request?.level5?.map(i => i.value),
      };

      setAdaAllocationRequest(req);
      scrollIntoView(adaVisualRef);
    } else {
      Notify.error(StrategyConsts.REVIEW_FORECAST_NOTIFIER);
    }
  }

  const resetRestFilters = (p_index) => {
    level5 && setLevel5(null);
    setLevel5Options([])
    setRequest(old => {
      return {
        ...old,
        'level5': null
      }
    })
    if (p_index == 0) {
      department && setDepartment(null);
      setDepartmentOptions([])
      setRequest(old => {
        return {
          ...old,
          'department': null
        }
      })
    }
    if (p_index == 1 || p_index == 0) {
      subdepartment && setSubdepartment(null);
      setSubdepartmentOptions([])
      setRequest(old => {
        return {
          ...old,
          'subDepartment': null
        }
      })
    }
    if (p_index == 2 || p_index == 1 || p_index == 0) {
      level4 && setLevel4(null)
      setLevel4Options([])
      setRequest(old => {
        return {
          ...old,
          'level4': null
        }
      })
    }
    !isNull(myInstance) && setMyInstance(null)
    !isNull(selectedArticleStoreConst) && setSelectedArticleStoreConst(null);
    !isNull(selectedArticleDcData) && setSelectedArticleDcData(null);
    !reviewBtnDisabled && setReviewBtnDisabled(true)
    setForecastRevDisableState(true);
    !isEmpty(selectedArticleWithData) && setSelectedArticleWithData([]);
  }

  const reviewChangesHandler = () => {
    const store_list = [];
    const ref_store_list = [];
    const store_idx = [];
    const ros_list = [];
    const wos_list = [];
    const delta_store_flag = [];
    let storeGroupTableInstanceCopy = { ...storeGroupTableInstance }
    storeTableDataCopy[selectedArticle?.value]?.forEach((d) => {
      store_list.push(d.store_code);
      ref_store_list.push(d.ref_store);
      store_idx.push(parseInt(d.Store_Index));
      ros_list.push((d['APS/ROS']));
      wos_list.push((d['WOS']));
      delta_store_flag.push((d['delta_store_flag']))
    });
    const selectedStores = storeCode?.map((o) => o?.value).filter(val => val != "*")

    const request = Object.keys(articles).filter(val => val == selectedArticle?.value)?.map((o) => {

      let demandType, productProfileCode
      if (Array.isArray(articles[o].Demand_Type_Filter_Default)) {
        demandType = articles[o].Demand_Type_Filter_Default[0]?.value
      }
      else {
        demandType = articles[o].Demand_Type_Filter_Default?.value
      }

      if (Array.isArray(articles[o].default_product_profiles)) {
        productProfileCode = articles[o].default_product_profiles[0]?.value
      }
      else {
        productProfileCode = articles[o].default_product_profiles?.value
      }

      if (
        selectedArticle?.value === storeGroupTableInstance.data[0].product_code
      ) {
        return {
          Product_Profile_Code: productProfileCode,
          Planned_APS: articles[o].article_aps,
          Planned_WOS: articles[o].article_wos,
          Store_Group_Code: articles[o].default_store_eligibility_default?.map((o) => o?.value),
          Product_Code: storeGroupTableInstance.data[0].product_code,
          Size_List: articles[o].size_desc?.map((o) => o?.value),
          DC_Codes: articles[o].default_dcs?.map((o) => o?.value),
          Demand_Type: demandType,
          Store_List: store_list,
          Reference_Store_List: ref_store_list,
          Store_Index: store_idx,
          ROS_List: ros_list,
          WOS_List: wos_list,
          delta_store_flag: delta_store_flag,
          User_Selected_Stores: selectedStores,
        };
      }
      return {
        Product_Profile_Code: productProfileCode,
        Planned_APS: articles[o].article_aps,
        Planned_WOS: articles[o].article_wos,
        Product_Code: articles[o].article,
        Store_Group_Code: articles[o].default_store_eligibility_default?.map((o) => o?.value),
        Size_List: articles[o].size_desc?.map((o) => o?.value),
        DC_Codes: articles[o].default_dcs?.map((o) => o?.value),
        Demand_Type: demandType,
        delta_store_flag: delta_store_flag,
        User_Selected_Stores: selectedStores,
      };
    });
    setIsReviewChangesDisabled(true)
    props.fetchStoreTableData(request);
  };

  const createAllocation = () => {
    let req = []
    // if (!inputRef?.current?.value) {
    //   Notify.error("Please Enter Allocation Plan Name!!")
    //   inputRef?.current?.focus()
    // }
    
      req = storeRequest?.map(val => {
        if (selectedArticleWithData.includes(val.Product_Code) || val.Product_Code == articleOptions[0]?.value) {
          if (val.Product_Code == storeGroupTableInstance.data?.[0]?.product_code && isEmpty(storeGroupTableInstance?.state?.selectedRowIds)) {
            return {
              ...val,
              "User_Name": localStorage?.getItem("email"),
              "User_ID": Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : "",
              ["Modified_Flag"]: true,
              "Store_List": storeTableDataCopy[val.Product_Code]?.map(val => val["store_code"]),
              "APS_ROS": storeTableDataCopy[val.Product_Code]?.map(val => val["APS/ROS"]),
              "Min_Stock": storeTableDataCopy[val.Product_Code]?.map(val => val["min_stock"]),
              "Max_Stock": storeTableDataCopy[val.Product_Code]?.map(val => val["max_stock"]),
              "WOS": storeTableDataCopy[val.Product_Code]?.map(val => val["WOS"]),
              "onhand": storeTableDataCopy[val.Product_Code]?.map(val => val["onhand"]),
              "intransit": storeTableDataCopy[val.Product_Code]?.map(val => val["intransit"]),
              "onorder": storeTableDataCopy[val.Product_Code]?.map(val => val["onorder"]),
              "Transit_Time": storeTableDataCopy[val.Product_Code]?.map(val => val["transit_time"]),
              "Allocation_Name": inputRef?.current?.value,
              "Allocation_Code": currentDraftallocationCode ? currentDraftallocationCode : '',
              "Department": division?.value ? division?.value : division?.[0]?.value,
              "Store_Size_List": storeTableDataCopy[val.Product_Code]?.map(val => {
                return {
                  "Store": val["store_code"],
                  "Store_Level_Propotion": val["overall_proportion"],
                  "Size": val["size_desc"],
                  "Size_Level_Proportion": val["size_level_proportion"]
                }
              }),
              // "po_ids": poDetails?.po_id,
              // "asn_ids": poDetails?.asn_id,
              "source": poDetails?.inventory_source && tableData[0]?.Inventory_Source_Filter[0]?.label
            }
          }
          else {
            let filterArray = {}
            if (val.Product_Code == storeGroupTableInstance.data?.[0]?.product_code) {
              filterArray = storeGroupTableInstance?.state?.selectedRowIds
            }
            else if (isEmpty(articleStoreMapping?.[val.Product_Code])) {
              let length = storeTableDataCopy[val.Product_Code]?.length
              filterArray = [...Array(length).keys()].reduce((x, y) => (x[y] = 1, x), {})
            }
            else {
              filterArray = articleStoreMapping?.[val.Product_Code]
            }
            return {
              ...val,
              "User_Name": localStorage?.getItem("email"),
              "User_ID": Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : "",
              ["Modified_Flag"]: true,
              "Store_List": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["store_code"]),
              "APS_ROS": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["APS/ROS"]),
              "Min_Stock": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["min_stock"]),
              "Max_Stock": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["max_stock"]),
              "WOS": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["WOS"]),
              "onhand": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["onhand"]),
              "intransit": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["intransit"]),
              "onorder": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["onorder"]),
              "Transit_Time": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["transit_time"]),
              "Allocation_Name": inputRef?.current?.value,
              "Allocation_Code": currentDraftallocationCode ? currentDraftallocationCode : '',
              "Department": division?.value ? division?.value : division?.[0]?.value,
              "Store_Size_List": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => {
                return {
                  "Store": val["store_code"],
                  "Store_Level_Propotion": val["overall_proportion"],
                  "Size": val["size_desc"],
                  "Size_Level_Proportion": val["size_level_proportion"]
                }
              }),
              // "po_ids": poDetails?.po_id,
              // "asn_ids": poDetails?.asn_id,
              "source": poDetails?.inventory_source && tableData[0]?.Inventory_Source_Filter[0]?.label
            }
          }
        }
        else {
          return {
            ...val,
            ["Modified_Flag"]: false,
            "Allocation_Name": inputRef?.current?.value,
            "Allocation_Code": currentDraftallocationCode ? currentDraftallocationCode : '',
            "Department": division?.value ? division?.value : division?.[0]?.value,
            "User_Name": localStorage?.getItem("email"),
            "User_ID": Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : "",
            // "po_ids": poDetails?.po_id,
            // "asn_ids": poDetails?.asn_id,
            "source": poDetails?.inventory_source && tableData[0]?.Inventory_Source_Filter[0]?.label
          }
        }
      })

      if(poDetails?.inventory_source === "PO" && poDetails?.status === "Pending" ) {
        req.po_ids = poDetails.po_id
      }
      if(poDetails?.inventory_source === "ASN" && poDetails?.status === "Pending" ){
        req.asn_ids = poDetails.asn_id
        req.po_ids = poDetails.po_id
      }
      if(poDetails?.status === "Error" || poDetails?.status === "Mismatch"){
        req.po_ids = poDetails.po_id
      }

      setCreateAllocationReq(req)
      props.createAllocation(req,useSSE)
      setErrorModalMessage(null)
  };


  const saveQtyChanges = () => {
    setSaveIsDisabled(true)
    let req = dcTableInstace.data?.map((val) => {
      return {
        "article": val.product_code,
        "size": val.size,
        "store_code": val.dc_code,
        "reserve_quantity": val.size?.map(value => val[value + ' '] ? val[value + ' '] : val['Reserve_Quantity_' + value]),
        // "reserve_quantity": val.size?.map(value => val['Reserve_Quantity_'+value]?val['Reserve_Quantity_'+value]:0)
      }
    });
    props.updateResQty(req)
  }

  const handleSetAllOk = () => {
    // setTableData(tableInstance?.data)
    const updateTableData = (res) => {
      setTableData((oldData) => {
        let newData = cloneDeep(oldData);
        setAllRows.forEach(setAllRow => {
          if (!isEmpty(storeEligibiltyGroupSA)) {
            newData[setAllRow]['default_store_eligibility_default'] = storeEligibiltyGroupSA
            newData[setAllRow]["no_of_stores"] = noOfStores
          }
          if (!isEmpty(storeEligibiltyGroupSA) && res?.[0]?.data?.status) {
            newData[setAllRow]["min_stock"] = !isEmpty(res?.[0]?.data?.data) ? res?.[0]?.data?.data?.filter(val => val.article == newData[setAllRow]['article'])[0].min_stock : 0
            // newData[setAllRow]["delivery_date"] = !isEmpty(res?.[0]?.data?.data) ? new Date(Date.now() + res?.[0]?.data?.data?.filter(val => val.article == newData[setAllRow]['article'])[0].transit_time * 24 * 60 * 60 * 1000) : new Date(Date.now())
          }
          if (!isEmpty(storeEligibiltyGroupSA) && !res?.[0]?.data?.status) {
            newData[setAllRow]["min_stock"] = 0
            // newData[setAllRow]["delivery_date"] = new Date(Date.now())
          }

          if (!isEmpty(storeEligibiltyGroupSA) && res?.[1]?.data?.status) {
            let data = res?.[1]?.data?.data
            if (isEmpty(plannedAPSSA) && plannedAPSSA !== 0) {
              newData[setAllRow]["article_aps"] = !isEmpty(data) ? data?.filter(val => val.article == newData[setAllRow]['article'])[0].aps : 0
            }
            newData[setAllRow]["article_wos"] = !isEmpty(data) ? data?.filter(val => val.article == newData[setAllRow]['article'])[0].wos : 0
          }

          if (!isEmpty(dcSA)) {
            let data = getInvComponent({
              "store_code": dcSA?.map(val => val?.value),
              "data": tableData[setAllRow],
              "size": tableData[setAllRow]?.size_desc?.map(val => val.value),
            })
            let total_inventory
            if(isAshley()){
              total_inventory = Math.max( Number(data?.oh_map) - (Number(data?.po_map) ? Number(data?.po_map) : 0) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
            }
            else {
              total_inventory = Math.max(Number(data?.it_map) + Number(data?.oh_map) + Number(data?.oo_map) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
            }
            newData[setAllRow]["default_inventory_sorce_filter"] = tableData[setAllRow]?.Inventory_Source_Filter
            newData[setAllRow]["it"] = data?.it_map ? data?.it_map : 0
            newData[setAllRow]["oh"] = data?.oh_map ? data?.oh_map : 0
            newData[setAllRow]["oo"] = data?.oo_map ? data?.oo_map : 0
            newData[setAllRow]["reserve_quantity"] = data?.rq_map ? data?.rq_map : 0
            newData[setAllRow]["total_inventory"] = total_inventory ? total_inventory : 0
            newData[setAllRow]["final_total_inventory"] = '-'
            newData[setAllRow]["edit_total_inventory"] = ''
            newData[setAllRow]["allocated_units"] = data?.au_map ? data?.au_map : 0
          }

          if (!isEmpty(inventorySourceSA)) {
            newData[setAllRow]["default_inventory_sorce_filter"] = inventorySourceSA
            let invSorce = 0;
            inventorySourceSA.forEach((group) => {
              invSorce += Number(newData[setAllRow][group?.value]);
            });
            invSorce -= newData[setAllRow].reserve_quantity
            invSorce -= newData[setAllRow].allocated_units
            invSorce = Math.max(invSorce, 0)
            newData[setAllRow]["total_inventory"] = invSorce
            newData[setAllRow]["final_total_inventory"] = '-'
            newData[setAllRow]["edit_total_inventory"] = ''
          }
          if (!isEmpty(dcSA)) {
            newData[setAllRow]["default_dcs"] = dcSA
          }
          !isEmpty(demandTypeSA) && (newData[setAllRow]["Demand_Type_Filter_Default"] = demandTypeSA)
          !isEmpty(productProfileSA) && (productProfileSA?.label == "IA Recommended" ? newData[setAllRow]["default_product_profiles"] = oldData[setAllRow]["product_profiles"].filter(pp => pp.label === "IA Recommended" )[0] : newData[setAllRow]["default_product_profiles"] = productProfileSA)
          if (userDefinedInvSA) {
            newData[setAllRow]['edit_total_inventory'] = userDefinedInvSA
            let final_total_inventory = userDefinedInvSA != 0 ? Math.round(userDefinedInvSA * Number(newData[setAllRow]['total_inventory']) / 100) : '-'
            newData[setAllRow]['final_total_inventory'] = final_total_inventory;
          }
          if (plannedAPSSA || plannedAPSSA === 0) {
            newData[setAllRow]['article_aps'] = plannedAPSSA
          }
          if (plannedWOSSA || plannedWOSSA === 0) {
            newData[setAllRow]['article_wos'] = plannedWOSSA
          }
        })
        return newData;
      });
    }
    setSetAllLoading(true)
    let setAllRows = Object.keys(tableInstance.state.selectedRowIds)
    let initialSelectedRows = {}
    setAllRows.forEach((item, index) => {
      initialSelectedRows[`${item}`] = true
    })
    setInitialSelectedRows(initialSelectedRows)
    let articles = setAllRows?.map(val => {
      return tableInstance?.data?.[val]?.article
    })
    let selectedRows = setAllRows?.map(val => {
      return tableInstance?.data?.[val]
    })
    let noOfStores = 0;
    if (!isEmpty(storeEligibiltyGroupSA) || !isEmpty(dcSA)) {
      let checkedStoreGroupCode = storeEligibiltyGroupSA?.map(val => val?.value)
      !isEmpty(storeEligibiltyGroupSA) && (noOfStores = getNoofStores(checkedStoreGroupCode))

      const callApi = async (req, apsReq) => {
        try {
          const result = [!isEmpty(storeEligibiltyGroupSA) ? await Api.post(GET_STORE_GROUP_MIN_STOCK, req.storeReq) : 0,
          (!isEmpty(storeEligibiltyGroupSA) && ((isEmpty(plannedAPSSA) && plannedAPSSA !== 0) || (isEmpty(plannedWOSSA) && plannedWOSSA !== 0))) ? await Api.post(GET_APS, { "data": apsReq }) : 0];
          if ((result[0] != 0 && !result[0]?.data?.status) || (result[1] != 0 && !result[1]?.data?.status)) {
            Notify.error("Error in Set All operation!!")
          }
          else {
            Notify.success("Changes are applied for selected articles!!")
          }
          updateTableData(result);
        }
        catch (err) {
          Notify.error("Error in Set All operation!!")
          updateTableData(null);
        }
      }
      let req = {
        storeReq: {
          "store_group_code": storeEligibiltyGroupSA?.map(val => val?.value),
          "article": articles
        },
        dcReq: {
          "article": articles,
          "store_code": dcSA?.map(val => val?.value)
        },
      }

      let apsReq = selectedRows.map(val => {
        return {
          "article": val.article,
          "store_group_code": storeEligibiltyGroupSA?.map(val => val?.value),
          "size": val.size_desc.map(val => val.value),
          "upc": getUPC(val.upcSizeMapping, val.size_desc.map(val => val.value))
        }
      })
      callApi(req, apsReq);
    }
    else {
      setSetAllLoading(false)
      updateTableData(null);
    }

    setShowSetAll(false);
  }

  const handleSetAllStoreDetailsOk = () => {
    if (apsSA || wosSA) {
      let setAllRows = Object.keys(storeGroupTableInstance.state.selectedRowIds)
      setStoreTableData((oldData) => {
        let newData = cloneDeep(oldData);
        let l_available, l_required, l_row, l_estimatedDemand
        setAllRows.forEach(setAllRow => {
          l_row = newData[selectedArticle?.value][setAllRow]
          l_available = Number(l_row['onhand']) + Number(l_row['onorder']) + Number(l_row['intransit'])
          l_required = (apsSA ? apsSA : l_row['APS/ROS_rounded']) * (wosSA ? wosSA : l_row['WOS_rounded'])
          l_estimatedDemand = zeroDecimal(Math.max(0, Math.max(Number(l_required), l_row['min_stock']) - l_available))
          l_row['estimated_demand'] = l_estimatedDemand;
          if (apsSA) {
            l_row['APS/ROS_rounded'] = apsSA
            l_row['APS/ROS'] = apsSA
          }
          if (wosSA) {
            l_row['WOS_rounded'] = wosSA
            l_row['WOS'] = wosSA
          }
        })
        return newData;
      });


      setStoreTableDataCopy((oldData) => {
        let newData = cloneDeep(oldData);
        let l_available, l_required, l_row, l_estimatedDemand
        setAllRows.forEach(setAllRow => {
          l_row = newData[selectedArticle?.value][setAllRow]
          l_available = Number(l_row['onhand']) + Number(l_row['onorder']) + Number(l_row['intransit'])
          l_required = (apsSA ? apsSA : l_row['APS/ROS_rounded']) * (wosSA ? wosSA : l_row['WOS_rounded'])
          l_estimatedDemand = zeroDecimal(Math.max(0, Math.max(Number(l_required), l_row['min_stock']) - l_available))
          l_row['estimated_demand'] = l_estimatedDemand;
          if (apsSA) {
            l_row['APS/ROS_rounded'] = apsSA
            l_row['APS/ROS'] = apsSA
          }
          if (wosSA) {
            l_row['WOS_rounded'] = wosSA
            l_row['WOS'] = wosSA
          }
        })
        return newData;
      });
    }
    return;
  }

  useEffect(() => {
    if (setAllLoading) {
      setSetAllLoading(false)
    }
  }, [tableData])


  const handleSetAllStoreDetailsCancel = () => {
    setShowSetAllStoreDetails(false)
  }

  const handleSetAllCancel = () => {
    setShowSetAll(false);
  }

  const handleClose = () => {
    showSetAll && setShowSetAll(false);
    showSetAllStoreDetails && setShowSetAllStoreDetails(false)
    setErrorModalMessage(null)
  }

  const handleOk = () => {
    if (failedArticleId) {
      let newReq = createAllocationReq.filter(val => !failedArticleId.includes(val.Product_Code))
      props.createAllocation(newReq,useSSE)
    }
    setErrorModalMessage(null)
  }

  const handleCancel = () => {
    setErrorModalMessage(null)
  }

  useEffect(() => {
    console.log(selectedArticleStoreConst, 'sdkcdus')
  }, [selectedArticleStoreConst])

  const allocationNameChangeHandler = (val) => {
    setAllocationName(val)
  }

  const showDrafts = () => {
    setShowSetAll(false)
    setShowSetAllStoreDetails(false)
    setHideDcStoreSection(false)
    setHideMainTable(false)
    resetRestFilters(0)
    setDivision(null);
    setTableData([])
    if (isShowDrafts) {
      setShouldDisplayFilter(true)
      setAllocationName('')
      inputRef.current.value = ''
      setIsContinueAllocation(false)
      setCurrentDraftallocationCode("")
      setIsFirstDraftsCall(false)
    }
    else {
      setShouldDisplayFilter(false)
    }
    setShowDrafts(!isShowDrafts)

  }

  const saveDraft = () => {
    // if (!inputRef?.current?.value) {
    //   Notify.error("Please Enter Allocation Plan Name!!")
    //   inputRef?.current?.focus()
    // }
  
      let user_id = Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : ""
      let allocation_id
      if (!currentDraftallocationCode) {
        let level1Value = Array.isArray(division) ? division[0].value.slice(0, 3) : division.value.slice(0, 3)
        allocation_id = `${level1Value}_${user_id}_${moment().unix()}`
      }
      let draftFilters = {}
      Object.keys(selectedFilters).forEach(key => {
        if (selectedFilters[key]) {
          draftFilters[key] = selectedFilters[key]
        }
      })
      let draftData = {
        data: {
          reviewTableData: selectedArticlesDraftData,
          DetailsTableData: { storeTableData: storeTableDataCopy, dcTableData },
          filters: draftFilters
        },
        user_id,
        allocation_id: currentDraftallocationCode ? currentDraftallocationCode : allocation_id,
        allocation_name: inputRef?.current?.value
      }
      if (!currentDraftallocationCode) {
        setCurrentDraftallocationCode(allocation_id)
      }
      let data = cloneDeep(draftData)
      props.saveDraft(data)
   
  }

  const validatePlanName = () => {
    setArticleStoreMapping({})
    if (isContinueAllocation) {
      saveDraft()
    }
    // else {
    //   if (!inputRef?.current?.value) {
    //     Notify.error("Please Enter Allocation Plan Name!!")
    //     inputRef?.current?.focus()
    //   }
      else {
        props.validatePlanName({ planname: inputRef?.current?.value })
      }
    // }

  }

  useEffect(() => {
    if (props.draftNameError) {
      setErrorModalMessage(props.draftNameError)
      setButtonLabel("Ok")
    }
    else if (props.draftNameSuccess) {
      saveDraft()
    }
  }, [props.draftNameError, props.draftNameSuccess])

  useEffect(() => {
    if (props.saveDraftData?.status) {
      setAllocationName('')
      inputRef.current.value = ''
      setIsNewDraftSaved(true)
      Notify.success(props.saveDraftData?.message + "!!")
      setShowDrafts(true)
      setShouldDisplayFilter(false)
      setHideDcStoreSection(true)
      setHideMainTable(true)
      setTableData([])
      setDcTableData([])
      setStoreTableData([])
    }
    if (!props.saveDraftData?.status && props?.saveDraftData?.message) {
      Notify.error(props?.saveDraftData?.message)
    }
    // setTimeout(() => {
    //   props.resetSaveDraftData()
    // }, 3000)
  }, [props.saveDraftData])

  const continueAllocation = (instance) => {
    setSelectedFilters(instance.data.filters)
    setHideDcStoreSection(false)
    setHideMainTable(false)
    setDraftInstance(instance)
    setIsContinueAllocation(true)
    props.fetchStrategyTableData(instance.data.filters);
    setIsLoading(true)
    let initialSelectedRows = {}

    instance.data.reviewTableData.forEach((item, index) => {
      initialSelectedRows[`${index}`] = true
    })
    setInitialSelectedRows(initialSelectedRows)
    // setArticleOptions(() => options);
    // setSelectedArticle(() => options[0]);
    setCurrentDraftallocationCode(instance.allocation_code)
    setAllocationName(instance.allocation_name)
    inputRef.current.value = instance.allocation_name
    // setTableData(instance.data.reviewTableData)

    setSelectedArticlesDraftData(instance.data.reviewTableData)

    // setArticles(() => options);
  }
  const onDemandTypeSAChange = (val) => {
    if (val.value === "IA") {
      setPlannedAPSDisabled(true)
      setPlannedAPSSA(null)
    }
    else {
      setPlannedAPSDisabled(false)
    }
    setDemandTypeSA(val);
  };

  const onProductProfileSAChange = (val) => {
    setProductProfileSA(val);
  };

  const onStoreEligibiltyGroupSAChange = (val) => {
    let value = intersectionWith(val, tableData?.[0]?.store_groups, isEqual);
    setstoreEligibiltyGroupSA(value)
  }
  const onInventorySourceSAChange = (val) => {
    let value = intersectionWith(val, tableData?.[0]?.Inventory_Source_Filter, isEqual);
    setInventorySourceSA(value)
  }

  const onDcSAChange = (val) => {
    let value = intersectionWith(val, tableData?.[0]?.dcs, isEqual);
    setDcSA(value)
  }

  const onUserDefinedInvSAChange = (val) => {
    setUserDefinedInvSA(val)
  }

  const onPlannedAPSSAChange = (val) => {
    setPlannedAPSSA(val)
  }

  const onPlannedWOSSAChange = (val) => {
    setPlannedWOSSA(val)
  }

  const getIntersectionOptions = (p_filter) => {
    let setAllRows = tableInstance?.state?.selectedRowIds && Object.keys(tableInstance?.state?.selectedRowIds)?.map(val => {
      return tableData?.[val]?.[p_filter]
    })

    // Using _.intersectionWith() method
    let newArray = setAllRows?.length && intersectionWith(
      ...setAllRows, isEqual);
    //pusing IA recommended pp explicitely as it has unique id's for each articles
    if (p_filter == 'product_profiles')
      Array.isArray(newArray) && (Object.keys(tableInstance?.state?.selectedRowIds).length != 1) && newArray?.push({ label: 'IA Recommended', value: 'IA Recommended' })
    return newArray;
  }

  const shouldDisabled = (p_filter) => {
    let selectedRows = tableInstance?.state?.selectedRowIds && Object.keys(tableInstance?.state?.selectedRowIds)
    let bool = false
    if (selectedRows?.length) {
      for (let i of selectedRows) {
        let value = tableInstance?.data?.[Number(i)]?.[p_filter]
        if (Array.isArray(value)) {
          if (value[0]?.value === "IA") {
            bool = true
            break;
          }
        }
        else {
          if (value?.value === "IA") {
            bool = true
            break;
          }
        }
      }
    }
    setPlannedAPSDisabled(bool);
  }

  const prepInput = React.useMemo(() => [
    {
      id: 1,
      isRequired: false,
      label: "APS/User Defined ROS",
      name: "aps",
      className: "setAll__input__center",
      value: apsSA,
      onChange: (e) => setapsSA(e.target.value),
      input: true,
      type: "number",
      min: 0,
    },
    {
      id: 2,
      isRequired: false,
      label: "WOS",
      name: "wos",
      className: "setAll__input__center",
      value: wosSA,
      onChange: (e) => setwosSA(e.target.value),
      input: true,
      type: "number",
      min: 0,
    },
  ], [apsSA, wosSA])

  const prepFilter = React.useMemo(() => [
    {
      id: 1,
      isRequired: false,
      label: "Store eligibility Group",
      name: "storeEligibilityGroup",
      className: "setAll__filter",
      dropdownValue: storeEligibiltyGroupSA,
      onDropDownChange: onStoreEligibiltyGroupSAChange,
      placeHolder: `Select Store eligibility Group`,
      optionsArray: getIntersectionOptions('store_groups'),
      isMultiSelect: true,
      hideSelectAll: true,
    },
    {
      id: 2,
      isRequired: false,
      label: "DC",
      name: "dc",
      className: "setAll__filter",
      dropdownValue: dcSA,
      onDropDownChange: onDcSAChange,
      placeHolder: `Select DC`,
      optionsArray: getIntersectionOptions('dcs'),
      isMultiSelect: true,
      hideSelectAll: true,
      isDisabled: isPOASNFlow
    },
    // {
    //   id: 3,
    //   isRequired: false,
    //   label: "Inv. Source",
    //   name: "invSource",
    //   className: "setAll__filter",
    //   dropdownValue: inventorySourceSA,
    //   onDropDownChange: onInventorySourceSAChange,
    //   placeHolder: `Select Inv. Source`,
    //   optionsArray: getIntersectionOptions('Inventory_Source_Filter'),
    //   isMultiSelect: true,
    //   hideSelectAll: true,
    //   isDisabled: isPOASNFlow
    // },
    {
      id: 5,
      label: "Product Profile",
      name: "productProfile",
      className: "setAll__filter",
      value: productProfileSA,
      onChange: onProductProfileSAChange,
      placeHolder: `Select Product Profile`,
      options: getIntersectionOptions('product_profiles'),
    },
    {
      id: 4,
      label: "Demand Type",
      name: "demandType",
      className: "setAll__filter",
      value: demandTypeSA,
      onChange: onDemandTypeSAChange,
      placeHolder: `Select Demand Type`,
      options: getIntersectionOptions('Demand_Type_Filter'),
    },
    {
      id: 6,
      isRequired: false,
      label: "User-Defined Inv in %",
      name: "userDefinedInv",
      className: "setAll__input",
      value: userDefinedInvSA,
      onChange: (e) => {
        let val = e.target.value
        if (val < 0) {
          val = 0
        }
        onUserDefinedInvSAChange(val)
      },
      input: true,
      type: "number",
      min: 0,
      max: 100
    },
    {
      id: 7,
      isRequired: false,
      label: "Planned APS",
      name: "plannedAPS",
      className: "setAll__input",
      value: plannedAPSSA,
      onChange: (e) => {
        let val = e.target.value
        if (val < 0) {
          val = 0
        }
        onPlannedAPSSAChange(val)
      },
      disabled: plannedAPSDisabled,
      input: true,
      type: "number",
      min: 0
    },
    {
      id: 8,
      isRequired: false,
      label: "Planned WOS",
      name: "plannedWOS",
      className: "setAll__input",
      value: plannedWOSSA,
      onChange: (e) => {
        let val = e.target.value
        if (val < 0) {
          val = 0
        }
        onPlannedWOSSAChange(val)
      },
      input: true,
      type: "number",
      min: 0
    },
  ], [storeEligibiltyGroupSA, inventorySourceSA, plannedAPSDisabled, dcSA, demandTypeSA, productProfileSA, userDefinedInvSA, plannedAPSSA, plannedWOSSA, tableInstance?.state?.selectedRowIds]);

  const handleAlertOk = () => {
    reviewChangesHandler()
    setIsShowAlert(false)
  }

  const handleAlertCancel = () => {
    setIsShowAlert(false)
  }

  const handleAlertClose = () => {
    setIsShowAlert(false)
  }

  const resetAlert = () => {
    setShowStoreAlert(false)
    setStoreAlertMessage(null)
  }

  const handleStoreAlertOk = () => {
    populateStoreDetailsDropdown(false, true, null);
    resetAlert()
  }

  const handleStoreAlertCancel = () => {
    resetAlert()
  }

  const handleStoreAlertClose = () => {
    resetAlert()
  }

  //Function to Notify on review and save operations
  const { isReviewReq, isReviewSuccess, isReviewFailed, isSaveReq, isSaveSuccess, isSaveFailed, isAdaAllocationModeSelected } = props;
  useEffect(() => {
    if (isAdaAllocationModeSelected) {
      if (!isReviewReq && !isReviewFailed && isReviewSuccess && !isSaveReq && !isSaveFailed) {
        Notify.success('Review Successful!!');
      } else if (!isReviewReq && isReviewFailed && !isReviewSuccess && !isSaveReq && !isSaveFailed) {
        Notify.error('Review Failed!!');
      } else if (!isSaveReq && isSaveSuccess && !isSaveFailed) {
        Notify.success('Save Successful!!');
      } else if (!isSaveReq && !isSaveSuccess && isSaveFailed) {
        Notify.error('Save Failed!!');
      }
    } else {
      //No code here
    }
  }, [isReviewSuccess, isReviewFailed, isSaveSuccess, isSaveFailed]);

  const getLoaderMessage = () => {
    let loadingMsg = '';
    isReviewReq ? loadingMsg = 'Reviewing...'
      : (isSaveReq ? loadingMsg = 'Saving...' : loadingMsg = 'Loading...');
    return loadingMsg;
  }

  return (
    <main className={StrategyCSS.strategyContainer}>
      <Notification />
      {isShowAlert && <ConfirmationAlert
        modalStyle={{ width: '45rem' }}
        message="APS values will be overwritten, Are you sure you want to continue?"
        handleOk={handleAlertOk}
        handleCancel={handleAlertCancel}
        handleClose={() => handleAlertClose()} />}
      {isShowStoreAlert && storeAlertMessage && <ConfirmationAlert
        message={storeAlertMessage}
        handleOk={() => handleStoreAlertOk()}
        handleCancel={() => handleStoreAlertCancel()}
        handleClose={() => handleStoreAlertClose()} />}
      {showSetAll && <SetAllModal modalBodyStyle={{ height: '45rem' }} filter={prepFilter} handleOk={handleSetAllOk} handleCancel={handleSetAllCancel} handleClose={() => handleClose()} />}
      {errorModalMessage && <ErrorModal message={errorModalMessage} buttonLabel={buttonLabel} handleCancel={() => handleCancel()} handleOk={() => handleOk()} handleClose={() => handleClose()} />}
      {showSetAllStoreDetails && <SetAllModal filter={prepInput} handleOk={handleSetAllStoreDetailsOk} handleCancel={handleSetAllStoreDetailsCancel} handleClose={() => handleClose()} />}
      <div className="container__header">
        <h1 className="fnt-lg fnt-bold">Create New Allocation</h1>
      </div>
      <section className={classnames("row", StrategyCSS.card)}>
        <div className="col-md-3 ">
          <label className="fnt-md fnt-bold fnt-bold">Allocation Plan Name</label>
          <input
            className="form-input form-control w-auto"
            type={"text"}
            ref={inputRef}
            placeholder="Allocation Plan Name"
          />
        </div>
       {!isPOASNFlow  && (<div className="col-md-3">
          <button
            onClick={showDrafts}
            className={classNames("btn", "btn-progress", "filter-constraints-mode",
              isShowDrafts ? "active" : "")}
          >
            <span style={{ fontWeight: 'bolder', fontSize: '1.2rem' }}>{isShowDrafts ? "Close" : "Show"} WIP Allocations</span>
          </button>
        </div>)}
      </section>
      <Notification />
      {
        isShowDrafts &&

        <section
          className={classnames(StrategyCSS.tableSection, StrategyCSS.card)}
          id="strategy-page-table"
        >
          <div className={StrategyCSS.table}>
            <Drafts continueAllocation={continueAllocation} isNewDraftSaved={isNewDraftSaved} setIsNewDraftSaved={setIsNewDraftSaved}
              isFirstDraftsCall={isFirstDraftsCall} />
          </div>
        </section>
      }
      {shouldDisplayFilter && <section className={classnames("row", StrategyCSS.card)}>
        <div className="col-md-3 required mb-2">
          <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1 || level1Label}</label>
          <Select
            name="department"
            value={division}
            onChange={onDivisionChange}
            isDisabled={disableFilters}
            placeHolder={props.filterLabels?.level1}
            options={divisionOptions.length ? divisionOptions : []}
          />
        </div>
        <div className="col-md-3 fill mb-2">
          <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level2 || level2Label}</label>
          <MultiSelect
            name="department"
            dropdownValue={department}
            isDisabled={disableFilters || !division}
            onDropDownChange={onDepartmentChange}
            placeHolder={'Select '}
            optionsArray={
              // division && 
              !isEmpty(departmentOptions) ? departmentOptions : []
            }
          />
        </div>
        {
          !shouldHideForCurrentClient("l3_name") &&
          <>
        <div className="col-md-3 fill mb-2">
          <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level3 || level3Label}</label>
          <MultiSelect
            name="subdepartment"
            dropdownValue={subdepartment}
            isDisabled={disableFilters}
            onDropDownChange={onSubdepartmentChange}
            placeHolder={'Select '}
            optionsArray={
              !isEmpty(subdepartmentOptions)
                ? subdepartmentOptions
                : []
            }
          />
        </div>
        <div className="col-md-3 fill">
          <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level4 || level4Label}</label>
          <MultiSelect
            name="level4"
            dropdownValue={level4}
            isDisabled={disableFilters}
            onDropDownChange={onLevel4Change}
            placeHolder={'Select '}
            optionsArray={
              !isEmpty(level4Options)
                ? level4Options
                : []
            }
          />
        </div>
        {
          !shouldHideForCurrentClient("l5_name") &&
          <div className="col-md-3 fill">
            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level5 || level5Label}</label>
            <MultiSelect
              name="level5"
              dropdownValue={level5}
              isDisabled={disableFilters}
              onDropDownChange={onLevel5Change}
              placeHolder={'Select '}
              optionsArray={
                !isEmpty(level5Options)
                  ? level5Options
                  : []
              }
            />
          </div>
        }
        </>
}
        <div className="col-md-3">
          <label className="fnt-md fnt-bold fnt-bold">Store ID</label>
          <MultiSelect
            name="storeId"
            dropdownValue={storeCode}
            isDisabled={disableFilters}
            onDropDownChange={onStoreCodeChange}
            placeHolder={'Select '}
            optionsArray={
              !isEmpty(storeCodeOptions) ? storeCodeOptions : []
            }
          />
        </div>
        <div className="col-md-3 mt-2">
          <button
            disabled={disableFilters}
            onClick={applyFilters}
            className="btn btn-primary filter-constraints-mode"
            title="Apply filters"
          >
            <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
          </button>
          <button
            disabled={disableFilters}
            onClick={() => {
              setInitialIndex(0)
              setRequest(null);
              setDivision(null);
              setStoreCode(null);
              setDepartment(null);
              setSubdepartment(null);
              setLevel4(null);
              setLevel5(null);
              setDepartmentOptions([]);
              setLevel4Options([]);
              setLevel5Options([]);
              setSubdepartmentOptions([]);
              setSelectedArticleStoreConst(null);
              setSelectedArticleDcData(null);
              setReviewBtnDisabled(true)
              setForecastRevDisableState(true);
              setSelectedArticleWithData([]);
              setMyInstance(null)
              setErrorModalMessage(null)
              setShowSetAll(false)
              setShowDrafts(false)
              setIsContinueAllocation(false)
              setHideDcStoreSection(false)
              setHideMainTable(false)
              setArticleStoreMapping({})
              setAllocationName('')
              inputRef.current.value = ''
              props.resetFiltersOptions([]);
            }}
            className="btn undo-constraints-mode"
            title="Reset filters"
          >
            <i className="fa fa-undo mr-2"></i>Reset
          </button>
        </div>
        <div style={{ marginTop: "3rem" }} className="col-md-3">
          <div className="required fnt-md pt-2" style={{ color: "red" }}>
            <label></label>Fields are mandatory
          </div>
        </div>
      </section>}
      {<section
        className={classnames(StrategyCSS.tableSection, StrategyCSS.card)}
        id="strategy-page-table"
      >
        <div className={StrategyCSS.table}>
          <PageLoader loader={loaderdts} gridLoader={true} />
          {tableData && (
            <>
              <PageLoader loader={props.loading || setAllLoading || isLoading} gridLoader={true}>
                {(props.error) ? (<div className="error">Something Went Wrong!!</div>) :
                  <ReactTableWithPlugins
                    getInstanceOnMount={(inst) => {
                      setTableInstance(() => inst);
                    }}
                    columns={cols}
                    data={tableData}
                    initialPageIndex={initialIndex}
                    initialHiddenColumns={["min_stock", ...POASNHiddenColumns]}
                    renderMarkup="TableMarkup"
                    keyRT="StrategyPageTable"
                    shouldPagination
                    totalRecordsLen={tableData.length}
                    features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                    initialSelectedRows={initialSelectedRows}
                    // tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                    // headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}

                    tableId="create_allocation_1"
                  />}
              </PageLoader>
              <div style={{ textAlign: "center", margin: '2rem' }}>
                <button
                  className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                  onClick={() => {
                    setHideDcStoreSection(false)
                    setArticleStoreMapping({})
                    shouldShowAlert(false, isContinueAllocation ? false : true, isContinueAllocation ? draftInstance : null);
                    setForecastRevDisableState(true);
                  }}
                >
                  {StrategyConsts.REVIEW_STOREDC_BUTTON}
                </button>
                {
                  hideToProd() &&
                  <button
                    className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                    onClick={() => {
                      handleReviewForecast();
                    }}
                  >
                    {StrategyConsts.REVIEW_FORECAST_BUTTON}
                  </button>
                }
                <button
                  className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                  onClick={() => {
                    setAllHandler(tableInstance, 'main_table');
                    setstoreEligibiltyGroupSA(null);
                    setInventorySourceSA(null);
                    setDcSA(null)
                    setDemandTypeSA(null);
                    setProductProfileSA(null);
                    setUserDefinedInvSA(null);
                    setPlannedAPSSA(null);
                    setPlannedWOSSA(null);
                    setTableData(tableInstance?.data)
                  }}
                >
                  Set All
                </button>
              </div>
            </>
          )}
        </div>
      </section>}
      {/* DC and Store details */}
      {(!reviewBtnDisabled || isContinueAllocation) && (!hideDcStoreSection) && (
        <section
          className={classnames(StrategyCSS.dcStoreDetails, StrategyCSS.card)}
        >
          <div className={StrategyCSS.storeAndDC}>
            <div style={{ width: "30.2rem" }}>
              <SingleSelect
                value={selectedArticle}
                options={articleOptions}
                onChange={(val) => {
                  // setIsReviewChangesDisabled(true)
                  setArticleStoreMapping((old) => {
                    return {
                      ...old,
                      [selectedArticle?.value]: storeGroupTableInstance?.state?.selectedRowIds
                    }
                  })
                  setSelectedArticle(val);
                  setSelectedArticleStoreConst(null);
                  setRefStoreDropDownOptions(null);
                  setIsReviewChangesDisabled(true);
                  setStoreTableData(cloneDeep(storeTableDataCopy))
                  // setSortBy([{ id: 'overall_proportion_rounded', desc: true }])
                }}
              />
            </div>
            {(totalEstimatedDemad === 0 || totalEstimatedDemad) && <span className={StrategyCSS.ted__sum}>Total Estimated Demand for All Stores : {totalEstimatedDemad}</span>}
          </div>
          <Tabs defaultActiveKey="details">
            <Tab eventKey="details" title="Store Details">
              <PageLoader loader={props.storeDataLoading || isLoading} gridLoader={true}>
                {(props.storeDataError) ? (<div className="error">{props.storeDataError}</div>) :
                  <div className={classnames(StrategyCSS.table, "mt-1")}>
                    {!isEmpty(storeTableData) &&
                      selectedArticleStoreTableData() &&
                      selectedArticleStoreConst && (
                        <div
                        >
                          <ReactTableWithPlugins
                            hideOptions={["overall_proportion_rounded", ...storeTableData[selectedArticle?.value][0].size_desc]}
                            initialHiddenColumns={["Store_Index", "dummyId"]}
                            getInstanceOnMount={(inst) => {
                              setStoreGroupTableInstance(inst);
                            }}
                            columns={storeDetailsCols}
                            sortBy={sortBy}
                            data={selectedArticleStoreConst}
                            renderMarkup="TableMarkup"
                            keyRT="StrategyPageTable"
                            features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                            shouldPagination
                            totalRecordsLen={selectedArticleStoreConst.length}
                            initialSelectedRows={intitalSelectedStoreRows}
                            tableId="create_allocation_review"
                          />
                          <div style={{ textAlign: "center" }}>
                            <button
                              className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                              onClick={() => setIsShowAlert(true)}
                              disabled={isReviewChangesDisabled}
                            >
                              Apply Changes
                            </button>
                            {!isPOASNFlow &&
                              <button
                                className={classnames("btn undo-constraints-mode", StrategyCSS.adjacent__buttons)}
                                onClick={validatePlanName}
                              >
                                Save as Draft
                              </button>
                            }
                            <button
                              className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                              onClick={createAllocation}
                            >
                              Create Allocation
                            </button>
                            <button
                              className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                              onClick={() => setAllHandler(storeGroupTableInstance, 'store_table')}
                            >
                              Set All
                            </button>
                          </div>
                        </div>
                      )}
                  </div>}
              </PageLoader>
            </Tab>
            {
              // !isPOASNFlow &&
              <Tab eventKey="dc" title="Inv. Source">
                <PageLoader loader={props.dcDataLoading} gridLoader={true}>
                  {(props.dcDataError) ? (<div className="error">{props.dcDataError}</div>) :
                    <div className={classnames(StrategyCSS.table, "mt-1")}>
                      {!isEmpty(dcTableData) &&
                        selectedArticleDcTableData() &&
                        selectedArticleDcData && (
                          <>
                            <ReactTableWithPlugins
                              hideColumnsFilter
                              getInstanceOnMount={(inst) => {
                                setDcTableInstance(inst);
                              }}
                              style={{ minWidth: '50rem', maxWidth: 'fit-content', margin: 'auto' }}
                              columns={dcTableCols}
                              data={selectedArticleDcData}
                              renderMarkup="TableMarkup"
                              // keyRT="sortAndSearch"
                              shouldPagination
                              totalRecordsLen={selectedArticleDcData.length}
                              tableId="create_allocation_dc"
                            />
                            <div style={{ textAlign: "center", marginTop: '10px' }}>
                              <button
                                className="btn btn-primary"
                                onClick={saveQtyChanges}
                                disabled={saveIsDisabled}
                              >
                                Save Changes
                              </button>
                            </div>
                          </>
                        )}
                    </div>}
                </PageLoader>
              </Tab>
            }
          </Tabs>
        </section>
      )}

      {!isForecastrRevBtnDisable && (
        <section className={classnames(StrategyCSS.card)} id="ADA_VISUAL_SECTION">
          <PageLoader loader={props.isAdaDataLoading || props.isReviewReq || props.isSaveReq} loaderMessage={getLoaderMessage()}>
            <div ref={adaVisualRef}><AdaVisualContainer /></div>
          </PageLoader>
        </section>
      )}
    </main>
  );
};

const mapStateToProps = ({ strategyTable, adaVisual }) => ({
  loading: strategyTable.loading,
  storeDataLoading: strategyTable.storeDataLoading,
  dcDataLoading: strategyTable.dcDataLoading,
  allocationCode: strategyTable.allocationCode,
  allocationCodeSuccess: strategyTable.allocationCodeSuccess,
  allocationCodeError: strategyTable.allocationCodeError,
  // createAllocationLoading: strategyTable.createAllocationLoading
  createAllocationError: strategyTable.createAllocationError,
  createAllocationSuccess: strategyTable.createAllocationSuccess,
  createAllocationData: strategyTable.createAllocationData,
  saveSuccess: strategyTable.saveSuccess,
  error: strategyTable.error,
  storeDataError: strategyTable.storeDataError,
  dcDataError: strategyTable.dcDataError,
  saveError: strategyTable.saveError,
  tableData: strategyTable.data,
  storeGroupStoreResult: strategyTable.storeGroupStoreResult,
  storeMinStockResult: strategyTable.storeMinStockResult,
  divisions: strategyTable.divisions,
  storeCode: strategyTable.storeCode,
  departments: strategyTable.departments,
  subdepartments: strategyTable.subdepartments,
  level4: strategyTable.level4,
  level5: strategyTable.level5,
  storeTableData: (strategyTable.storeTableData),
  dcTableData: cloneDeep(strategyTable.dcTableData),
  disableFilters: strategyTable.disableFilters,
  filterLabels: strategyTable.filterlabels,
  // min_stock_1: strategyTable?.changed_min_stock,
  isAdaDataLoading: adaVisual.isAdaDataLoading,
  isAdaReqFailed: adaVisual.isAdaReqFailed,
  errorMsg: adaVisual.errorMsg,
  startFiscalYearWeek: adaVisual.startFiscalYearWeek,
  endFiscalYearWeek: adaVisual.endFiscalYearWeek,
  isAdaAllocationModeSelected: adaVisual.isAdaAllocationModeSelected,
  isReviewReq: adaVisual.isReviewReq,
  isReviewFailed: adaVisual.isReviewFailed,
  isReviewSuccess: adaVisual.isReviewSuccess,
  isSaveReq: adaVisual.isSaveReq,
  isSaveSuccess: adaVisual.isSaveSuccess,
  isSaveFailed: adaVisual.isSaveFailed,
  saveDraftData: strategyTable.saveDraftData,
  draftNameError: strategyTable.draftNameError,
  draftNameSuccess: strategyTable.draftNameSuccess
  // min_stock_1: strategyTable?.changed_min_stock
});

const mapDispatchToProps = (dispatch) => ({
  resetAll: () => dispatch(resetAll()),
  setLoaderToFalse: () => dispatch(setLoaderToFalse()),
  // getstoregroupminstock: (payload) => dispatch(getstoregroupminstock(payload)),
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  createAllocation: (payload,isSSE) => dispatch(createAllocation(payload,isSSE)),
  updateResQty: (payload) => dispatch(updateResQty(payload)),
  save: (payload) => dispatch(save(payload)),
  fetchStrategyTableData: (payload) =>
    dispatch(fetchStrategyTableData(payload)),
  fetchFilterData: (payload) => dispatch(fetchFilterData(payload)),
  fetchStoregroupMinStock: (payload) =>
    dispatch(fetchStoregroupMinStock(payload)),
  setTableInstance: (payload) => dispatch(setTableInstance(payload)),
  fetchStoreTableData: (payload) => dispatch(fetchStoreTableData(payload)),
  fetchDcTableData: (payload) => dispatch(fetchDCData(payload)),
  enableFilters: () => dispatch(enableFilters()),
  setAdaAllocationRequest: (payload) => dispatch(setAdaAllocationRequest(payload)),
  setAdaCurrentDate: (payload) => dispatch(setAdaCurrentDate(payload)),
  saveDraft: (payload) => dispatch(saveDraft(payload)),
  getDrafts: (payload) => dispatch(getDrafts(payload)),
  deleteDraft: (payload) => dispatch(deleteDraft(payload)),
  resetSaveDraftData: () => dispatch(resetSaveDraftData()),
  validatePlanName: (payload) => dispatch(validatePlanName(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Strategy);
