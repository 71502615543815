import React, { useState, useEffect } from "react";
import Modal from "react-awesome-modal";
import PageLoader from '../Loader/PageLoader';

const NameingModal = (props) => {
    const {save, errorModalMessage, showModal, closeModal, label, loading, name} = props

    const [errorText, setErrorText] = useState("")
    const [planName, setName] = useState("")

    const saveName = () => {
        if (planName) {
            save(planName)
        }
        else {
            setErrorText("Please enter name!!")
        }
    }

    const handleNameChange = (value) => {
        setErrorText("")
        setName(value)
    }

    useEffect(() => {
        if (errorModalMessage) {
            setErrorText(errorModalMessage)
        }
        if(name) {
            setName(name)
        }
    }, [errorModalMessage, name])

    return (
        <div className="modal__div">
            <Modal
                visible={showModal}
                width="27%"
                effect="fadeInDown"
                onClickAway={closeModal}
            >
                <div className="modal-container">

                    <div className="modal-header">
                        <h5 className="page-heading text-center m-0">
                            {label}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal">
                            <span style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-times text-right m-2 "
                                    aria-hidden="true"
                                    onClick={closeModal}
                                />
                            </span>
                        </button>
                    </div>
                    <PageLoader loader={loading} gridLoader={true}>

                        <div className="modal-middle-container modal-body">
                            <div className="required row mx-2 p-2">
                                {/* <label className="input-label mt-2">
                                    Configuration Name
                                </label> */}

                                <input
                                    className="form-input form-control w-auto ml-2"
                                    type={"text"}
                                    value={planName}
                                    placeholder='Enter here'
                                    onChange={(e) => {
                                        handleNameChange(e.target.value);
                                    }}
                                />
                                {errorText && (
                                    <div className="errorClass">{errorText}</div>
                                )}
                            </div>
                        </div>
                        <div className="text-center" style={{ padding: "1rem" }}>
                            <div className="center">
                                <button
                                    style={{ margin: '1rem' }}
                                    onClick={closeModal}
                                    className="btn btn-secondary"
                                >
                                    Close
                                </button>
                                <button
                                    style={{ margin: '1rem' }}
                                    onClick={saveName}
                                    className="btn btn-primary"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </PageLoader>
                </div>
            </Modal>
        </div>
    )
}

export default NameingModal