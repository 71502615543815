import React, { useState, useEffect } from "react";
import TransferTableRecords from "../Table/Shared/TransferTableRecords";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageLoader from "../Loader/PageLoader";
import ColumnFilter from "../../containers/UserManagement/ColumnFilter";

const CreateStoreGroupModalRT = React.memo(props => {
  const [firstTableData, setFirstTableData] = useState([]);
  const [secondTableData, setsecondTableData] = useState([]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Store ID",
        accessor: "store_code",
        Filter: ColumnFilter,
      },
      {
        Header: "Store Name",
        accessor: "store_name",
        Filter: ColumnFilter,
      },
      {
        Header: "Store Grade",
        accessor: "store_grade",
        Filter: ColumnFilter,
      },
      {
        Header: "Region Description",
        accessor: "region",
        Filter: ColumnFilter,
      }
    ],
    []
  );

  const getStoreGrade = (p_storeGrade) => {
    const gradeMapping = {
        Apparel : 'store_grade_ap',
        Accessories : 'store_grade_ac',
        Footwear : 'store_grade_ft'
    }
        return gradeMapping[p_storeGrade]
  }

  const getStoresStoreData = (p_storesStoreData,p_leftOrRightTable) => {
    if(p_leftOrRightTable === "firstTable"){
      let l_createStoreData = p_storesStoreData.map(val => {
        return {
           ...val,
           'region': val.region?.[0],
           'store_grade': val[getStoreGrade(props.callBackData.data?.l1_name?.[0])]?.[0]
        }
    })
      setFirstTableData(l_createStoreData);
    }
    else if (p_leftOrRightTable === "secondTable") {
      let l_editStoreData = p_storesStoreData.map(val => {
        return {
           ...val,
           'region': val.region?.[0],
           'store_grade': val[getStoreGrade(props.storeGroupDesc)]?.[0]
        }
    })
    setsecondTableData(l_editStoreData);
    }
    
  }

  useEffect(() => {
    if (
      props.create_storeData?.status &&
      props.storeDataInFirstOrSecondTable === "firstTable"
    ) {
      getStoresStoreData(props.create_storeData.data,"firstTable");
    } else if (
      props.edit_storeData?.status &&
      props.storeDataInFirstOrSecondTable === "secondTable"
    ) {
      getStoresStoreData(props.edit_storeData.data,"secondTable");
    }
  }, [
    props.edit_storeData,
    props.create_storeData,
    props.storeDataInFirstOrSecondTable,
  ]);

  const getSelectedRows = React.useCallback((ins1, ins2) => {
    props.onRowSelectChange(ins1, ins2);
  }, []);



  return (
    <PageLoader loader={props.loading} popUp={true}>
      <div style={{marginBottom: "50px" }}>
        <TransferTableRecords
          keyRT="TransferTableRecords"
          firstTableData={firstTableData}
          secondTableData={secondTableData}
          shouldResetTableData = {props.shouldResetTableData}
          storeGroupName={props.storeGroupName}
          columns={columns}
          height={"250px"}
          sendSelectedRows={getSelectedRows}
          storeDataInFirstOrSecondTable={props.storeDataInFirstOrSecondTable}
          callBackData={props.callBackData}
        ></TransferTableRecords>
        <div>{props.children}</div>
      </div>
    </PageLoader>
  );
});

const mapStateToProps = store => {
  return {
    create_storeData: store.storeGroup?.createStoreData,
    edit_storeData: store.storeGroup?.editStoreData,
    loading: store.storeGroup?.createStoreDataLoading,
    storeDataInFirstOrSecondTable: store.storeGroup?.storeDataInFirstOrSecondTable
  };
};


export default connect(mapStateToProps,null)(withRouter(CreateStoreGroupModalRT));
