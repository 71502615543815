import React from 'react';
import { EMPTY_DRIVERS_MSG, DRIVERS_FORECAST_HEADER, IMPORTANCE_FORECAST_HEADER, EMPTY_DATA_HELPER_MSG } from './AdaVisualConstants';
import "./TableStyle.css";

/**
 * Forecast Table View component
 * @param {table data and headers} props 
 * @returns 
 */
const DriversTableView = (props) => {
    const { impData, driverData, keys, header } = props;
    return (
        <div style={{ padding: "2px" }}>
            {driverData && driverData.length > 0 ?
                <table className="table-set">
                    <thead>
                        <tr>
                            <th className="th-set sticky-col" key="0">
                                <span style={{ border: "none" }}>{DRIVERS_FORECAST_HEADER}</span>
                            </th>
                            <th className="th-set sticky-col2" key="1">
                                <span style={{ border: "none" }}>{IMPORTANCE_FORECAST_HEADER}</span>
                            </th>
                            {driverData.map((weekHead) => (
                                <th className="th-set" key={weekHead.fw}>
                                    <span style={{ border: "none" }}>{`W${(weekHead.fw)}`}</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {keys.map((key, index) => (
                            <tr className="tr-set" key={index}>
                                <td className="td-set sticky-col">{header[key]}</td>
                                <td className="td-set sticky-col2">{`${impData[key].toFixed(1)}%`}</td>
                                {driverData.map((value) => (
                                    <td className="td-set" key={value.fw}>
                                        <span className="span-data">{key === 'baseline' && !value[key] ? '--' : value[key].toFixed(1)}</span>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                : <div className="empty">{EMPTY_DRIVERS_MSG}
                    <p>{EMPTY_DATA_HELPER_MSG}</p>
                </div>
            }
        </div>
    )
}

export default DriversTableView;