import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from '../../../../components/Table/ReactTable';
import moment from "moment";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from '../../../UserManagement/ColumnFilter';
import EditIconCell from "../../../../components/Table/Cellrenderer/EditIconCell";
import InputCell from "../../../../components/Table/Cellrenderer/InputCell";
import { isEmpty, cloneDeep } from 'lodash';
import ColumnFilterServerSide from '../../../../components/Filters/ColumnFilterServerSide';
import NumberRangeColumnFilterServerSide from '../../../../components/Filters/NumberRangeColumnFilterServerSide';
import * as StrategyConfigConstants from '../../StrategyConfigConstants';
import * as strategyConfigActions from '../../actions/StrategyConfigActions';
import "../../StrategyConfiguration.css";
import { dateFormat } from '../../../../utils/commonUtilities';
import Notification from '../../../../components/Notification/Notifications';
import * as Notify from "../../../../components/Notification/Notifications";



/**
 * Table Plugin
 */
export const rowCount = 100
const pageSize = 10
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StrategyConfigurationTableView = (props) => {
    const [strategyCongfigTabInst, setStrategyConfigTabInst] = useState(null);
    const [prevIndex, setPrevIndex] = useState(0)
    const [nextIndex, setNextIndex] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [initialIndex, setInitialIndex] = useState(0)
    const [searchTermReq, setSearchTermReq] = useState({})
    const [sortReq, setSortReq] = useState({})
    const [isFirstCall, setisFirstCall] = useState(true)
    const [strategyTableData, setStrategyTableDate] = useState([])
    const [isFilterClicked, setIsFilterClicked] = useState(false)

    const { handleSetStrategyConfiguration, strategyConfigActionsProp, handleRowsSelection, tableRowsSelected, filter ,searchTermValue, handleFilterRequest } = props;

    const onStrategyConfigMount = (inst) => {
        setStrategyConfigTabInst(inst);
    }

    useEffect(() => {
        console.log(props.strategyTableData,'sdcsdcscsd')
        if (props.strategyTableData?.length > 0) {
            if (strategyCongfigTabInst && strategyCongfigTabInst.data) {
                setStrategyTableDate([...strategyCongfigTabInst.data, ...props.strategyTableData]);
            }
            else {
                setStrategyTableDate([...props.strategyTableData]);
            }
        }
        else {
            setStrategyTableDate([])
            setStrategyConfigTabInst(null)
        }
        if (isFilterClicked) {
            setInitialIndex(0)
            setPrevIndex(0)
            setIsFilterClicked(false)
        }
    },[props.strategyTableData])

    // useEffect(() => {
    //     return () => {
    //         console.log('inside useeffect')
    //         const selectedRows = strategyCongfigTabInst?.selectedFlatRows?.map(row => row.original);
    //         if (!isEmpty(selectedRows)) {
    //             strategyConfigActionsProp.setRowsSelected(selectedRows);
    //             // handleRowsSelection(strategyCongfigTabInst?.selectedFlatRows);
    //         } else {
    //             //No code here
    //         }
    //     };
    // });

    useEffect(()=> {
        if(props.filterClicked){
            setPrevIndex(0)
            setInitialIndex(0)
            setIsFilterClicked(true)
            setStrategyTableDate([])
            setStrategyConfigTabInst([])
            setisFirstCall(true)
            setSearchTermReq({})
            setSortReq({})
            strategyConfigActionsProp.resetRowsSelected()    
        }
    },[props.filterClicked])

    const handleEdit = (instance) => {
        // console.log(instance);
    }

    const handleSet = () => {
        handleSetStrategyConfiguration(strategyCongfigTabInst);
    }

    const handleDelete = () => {
        console.log(strategyCongfigTabInst,'csdcdscds')
        if(Object.keys(strategyCongfigTabInst.state.selectedRowIds).length > 0){
            let l_request = strategyCongfigTabInst.selectedFlatRows?.map(val => val.original.article)
            if (window.confirm("Are you sure you wish to delete this strategy configuration?"))
            strategyConfigActionsProp.deleteStrategyConfigData({"article":l_request})
        }
        else {
            Notify.error("Please select atleast one style color to delete strategy configuration!!")
        }
    }


    useEffect(() => {
        if (props.isStrategyConfigDeleteSuccess) {
            Notify.success("Strategy Configuration deleted Successfully!!");
            handleFilterRequest(filter);
        }
        if (props.isStrategyConfigDeleteFailed) {
            Notify.error("Error in deleting Strategy Configuration!!");
        }
    }, [props.isStrategyConfigDeleteFailed, props.isStrategyConfigDeleteSuccess]);

    useEffect(() => {
        setTotalCount(props.totalCount)
        setNextIndex(props.nextIndex)
    }, [props.totalCount, props.nextIndex])


    const fetchData = (index) => {
        if (prevIndex >= index || nextIndex >= totalCount)
            return;

        let initialSelectedRows = {}
        Object.keys(strategyCongfigTabInst.state.selectedRowIds).forEach((item, index) => {
          initialSelectedRows[`${item}`] = true
        })

        strategyConfigActionsProp.setRowsSelected(initialSelectedRows);
        setPrevIndex(index)
        setInitialIndex(Math.floor(nextIndex / pageSize - 1))
        strategyConfigActionsProp.getStrategyConfigTableDetails({ ...filter, row_index: nextIndex, row_count: rowCount, searchColumns: searchTermReq, sortColumn: sortReq?.[0] });
    }

    const fecthDataWithSearchTerm = (p_term, p_id, p_type) => {
        if (p_type === "array") {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                var id = p_term.split(" ")
                if (p_term?.trim() == 0) {
                    console.log('ss11111', l_searchTermReq)
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "values": id,
                        "search": p_term
                    }
                }
                return {
                    ...l_searchTermReq
                }
            })
        }
        else if (Array.isArray(p_term)) {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                let min = p_term[0] == "" ? null : p_term[0]
                let max = p_term[1] == "" ? null : p_term[1]
                if (min == null && max == null) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": {
                            "min": min,
                            "max": max,
                        }
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        else {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": p_term
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        isFirstCall && setisFirstCall(false)
    }

    const fecthDataWithSorting = (p_sort) => {
        if (!isEmpty(sortReq))
            isFirstCall && setisFirstCall(false)
        setSortReq(p_sort)
    }

    useEffect(() => {
        if ((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(filter)) {
            setStrategyTableDate([])
            setStrategyConfigTabInst(null)
            setInitialIndex(0)
            setPrevIndex(0)
            strategyConfigActionsProp.getStrategyConfigTableDetails({ ...filter, row_index: 0, row_count: rowCount, searchColumns: searchTermReq, sortColumn: sortReq?.[0] })
        }
    }, [searchTermReq, sortReq])


    const strategyConfigTableColumns = React.useMemo(
        () => [
            {
                Header: " ",
                sticky: "left",
                columns: [
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_STYLE_COLOR_ID,
                        accessor: "article",
                        width: 120,
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.article?.search} placeholder={"search in bulk..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array")} />
                        ),
                    },
                    // {
                    //     Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_STYLE_COLOR_DESC,
                    //     accessor: "article",
                    //     width: 120,
                    //     Filter: ColumnFilter,
                    // },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_CATEGORY,
                        accessor: "l1_name",
                        width: 100,
                        Filter:  (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l1_name?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                        ),
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_DEPARTMENT,
                        accessor: "l2_name",
                        width: 120,
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l2_name?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                        ),
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_CLASS,
                        accessor: "l3_name",
                        width: 100,
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l3_name?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                        ),
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_SUBCLASS,
                        accessor: "l4_name",
                        width: 100,
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l4_name?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                        ),
                    },
                ],
            },
            {
                Header: "  ",
                columns: [
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_CREATED_BY,
                        accessor: 'created_by',
                        Filter:  (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.created_by?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                        ),
                        width: 250
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_RELEASED_BY,
                        accessor: 'updated_by',
                        Filter:  (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.released_by?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                        ),
                        width: 250
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_CREATED_DATE,
                        accessor: 'created_at',
                        Cell: ({ value, row }) => dateFormat(value),
                        Filter:  (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.creation_date?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                        ),
                        sortType: "basic"
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_RELEASED_DATE,
                        accessor: 'updated_at',
                        Cell: ({ value, row }) => dateFormat(value),
                        Filter:  (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.released_date?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                        ),
                        sortType: "basic"
                    },
                ],
            },
            // {
            //     Header: " ",
            //     columns: [
            //         {
            //             Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_PRE_SEASON_DATE,
            //             accessor: "pre_season_date",
            //             width: 100,
            //             Cell: (instance) => (instance.row.original.pre_season_date === null ? "" : moment(instance.row.original.pre_season_date).format("YYYY-MM-DD")),
            //             // Filter: ColumnFilter,
            //             disableFilters: true,
            //         },
            //         {
            //             Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_IN_SEASON_DATE,
            //             accessor: "in_season_date",
            //             width: 100,
            //             Cell: (instance) => (instance.row.original.in_season_date === null ? "" : moment(instance.row.original.in_season_date).format("YYYY-MM-DD")),
            //             // Filter: ColumnFilter,
            //             disableFilters: true,
            //         },
            //         {
            //             Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_CLEARANCE_DATE,
            //             accessor: "clearance_date",
            //             width: 100,
            //             Cell: (instance) => (instance.row.original.clearance_date === null ? "" : moment(instance.row.original.clearance_date).format("YYYY-MM-DD")),
            //             // Filter: ColumnFilter,
            //             disableFilters: true,
            //         },
            //     ],
            // },
            {
                Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_HEADER_PRE_SEASON,
                columns: [
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_DEMAND_TYPE,
                        accessor: "pre_season_demand_type",
                        width: 100,
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.pre_season_demand_type?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                        ),
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_APS,
                        accessor: "pre_season_aps",
                        // width: 80,
                        Filter:(instance) => (
                            <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.pre_season_aps?.value?.min} maxSearchTermValue={searchTermReq?.pre_season_aps?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                        ),
                        filter: 'between',
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_WOS,
                        accessor: "pre_season_wos",
                        // width: 80,
                        Filter: (instance) => (
                            <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.pre_season_wos?.value?.min} maxSearchTermValue={searchTermReq?.pre_season_wos?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                        ),
                        filter: 'between',
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_STORE_INV,
                        accessor: "pre_season_store_inv_push",
                        // width: 80,
                        Filter: (instance) => (
                            <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.pre_season_store_inv_push?.value?.min} maxSearchTermValue={searchTermReq?.pre_season_store_inv_push?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                        ),
                        filter: 'between',
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_EXECUTION_DATE,
                        accessor: "pre_season_execution_date",
                        width: 100,
                        // Filter: ColumnFilter,
                        disableFilters: true,
                        Cell: (instance) => (instance.row.original.pre_season_execution_date === null ? "" : moment(instance.row.original.pre_season_execution_date).format("MM-DD-YYYY")),
                    },
                    // {
                    //     Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_FREQUENCY,
                    //     accessor: "pre_season_frequency",
                    //     width: 100,
                    //     Filter: ColumnFilter,
                    // },
                ]
            },
            {
                Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_HEADER_IN_SEASON,
                columns: [
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_DEMAND_TYPE,
                        accessor: "in_season_demand_type",
                        width: 100,
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.in_season_demand_type?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                        ),
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_APS,
                        accessor: "in_season_aps",
                        // width: 80,
                        Filter: (instance) => (
                            <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.in_season_aps?.value?.min} maxSearchTermValue={searchTermReq?.in_season_aps?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                        ),
                        filter: 'between',
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_WOS,
                        accessor: "in_season_wos",
                        // width: 80,
                        Filter: (instance) => (
                            <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.in_season_wos?.value?.min} maxSearchTermValue={searchTermReq?.in_season_wos?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                        ),
                        filter: 'between',
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_STORE_INV,
                        accessor: "in_season_store_inv_push",
                        // width: 80,
                        Filter: (instance) => (
                            <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.in_season_store_inv_push?.value?.min} maxSearchTermValue={searchTermReq?.in_season_store_inv_push?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                        ),
                        filter: 'between',
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_EXECUTION_DATE,
                        accessor: "in_season_execution_date",
                        width: 100,
                        // Filter: ColumnFilter,
                        disableFilters: true,
                        Cell: (instance) => (instance.row.original.in_season_execution_date === null ? "" : moment(instance.row.original.in_season_execution_date).format("MM-DD-YYYY")),
                    },
                    // {
                    //     Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_FREQUENCY,
                    //     accessor: "in_season_frequency",
                    //     width: 100,
                    //     Filter: (instance) => (
                    //         <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.in_season_frequency?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                    //     ),
                    // },
                ]
            },
            {
                Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_HEADER_CLEARANCE,
                columns: [
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_DEMAND_TYPE,
                        accessor: "clearance_season_demand_type",
                        width: 100,
                        Filter:(instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.clearance_season_demand_type?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                        ),
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_APS,
                        accessor: "clearance_season_aps",
                        // width: 80,
                        Filter: (instance) => (
                            <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.clearance_season_aps?.value?.min} maxSearchTermValue={searchTermReq?.clearance_season_aps?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                        ),
                        filter: 'between',
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_WOS,
                        accessor: "clearance_season_wos",
                        // width: 80,
                        Filter: (instance) => (
                            <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.clearance_season_wos?.value?.min} maxSearchTermValue={searchTermReq?.clearance_season_wos?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                        ),
                        filter: 'between',
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_STORE_INV,
                        accessor: "clearance_season_store_inv_push",
                        // width: 80,
                        Filter: (instance) => (
                            <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue={searchTermReq?.clearance_season_store_inv_push?.value?.min} maxSearchTermValue={searchTermReq?.clearance_season_store_inv_push?.value?.max} clickHandler={(min, max, id) => fecthDataWithSearchTerm([min, max], id, "range")} />
                        ),
                        filter: 'between',
                    },
                    {
                        Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_EXECUTION_DATE,
                        accessor: "clearance_season_execution_date",
                        width: 100,
                        // Filter: ColumnFilter,
                        disableFilters: true,
                        Cell: (instance) => (instance.row.original.clearance_season_execution_date === null ? "" : moment(instance.row.original.clearance_season_execution_date).format("MM-DD-YYYY")),
                    },
                    // {
                    //     Header: StrategyConfigConstants.STRATEGY_CONFIG_TABLE_COL_FREQUENCY,
                    //     accessor: "clearance_season_frequency",
                    //     width: 100,
                    //     Filter:(instance) => (
                    //         <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.clearance_season_frequency?.value} placeholder={"search..."} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
                    //     ),
                    // },
                ]
            },
            // {
            //     Header: "Actions",
            //     accessor: "actions",
            //     sticky: "right",
            //     Cell: (instance) => (
            //         <div>
            //             <EditIconCell
            //                 handleEdit={handleEdit}
            //                 instance={instance}
            //             ></EditIconCell>
            //         </div>
            //     ),
            //     disableFilters: true,
            //     resizable: true,
            //     width: 100,
            // },
        ],
        [strategyCongfigTabInst]
    );
    return (
        <>
            <Notification/>
            <ReactTableWithPlugins
                getInstanceOnMount={(inst) => onStrategyConfigMount(inst)}
                fetchData={(data) => fetchData(data)}
                shouldPagination
                hideDropdown
                pageSize={pageSize}
                totalCount={totalCount}
                initialPageIndex={initialIndex}
                totalRecordsLen={strategyTableData.length}
                data={strategyTableData}
                columns={strategyConfigTableColumns}
                initialSelectedRows={tableRowsSelected}
                renderMarkup='TableMarkup'
                keyRT="StrategyPageTable"
                features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                getManualSortByOptions={(instance) => {
                    fecthDataWithSorting(instance)
                }}
                manualSortBy={true}
                tableId="strategy_config"
            />
            <div className="text-center">
                <button
                    style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                    onClick={handleSet}
                    className="btn btn-primary"
                >
                    {StrategyConfigConstants.SET_STRATEGY_BUTTON}
                </button>
                <button
                    style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                    onClick={handleDelete}
                    className="btn btn-primary"
                >
                    {StrategyConfigConstants.DELETE_STRATEGY_BUTTON}
                </button>
            </div>
      
        </>
    );
}

const mapStateToProps = ({ strategyConfiguration }) => ({
    searchTermValue: strategyConfiguration.searchTermRequest,
    isStrategyConfigDeleteFailed: strategyConfiguration.isStrategyConfigDeleteFailed,
    isStrategyConfigDeleteSuccess: strategyConfiguration.isStrategyConfigDeleteSuccess,
    filterClicked: strategyConfiguration.filterClicked
                
});

const mapDispatchToProps = (dispatch) => ({
    strategyConfigActionsProp: bindActionCreators(strategyConfigActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StrategyConfigurationTableView);
