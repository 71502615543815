import { cloneDeep, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { connect } from 'react-redux'
import Card from '../../../components/Card/Card'
import ExportFile from '../../../components/ExportFile/ExportFile'
import ColumnFilterServerSide from '../../../components/Filters/ColumnFilterServerSide'
import NumberRangeColumnFilterServerSide from '../../../components/Filters/NumberRangeColumnFilterServerSide'
import PageLoader from '../../../components/Loader/PageLoader'
import ReactTable from '../../../components/Table/ReactTable'
import WithDynamicPlugins from '../../../components/Table/WithPlugins'
import { scrollIntoView } from '../../../utils/commonUtilities'
import { fetchDeepDiveData, generateExcel } from './DeepDiveAction'
import { DEEP_DIVE_TABLE_HEADERS } from './DeepDiveConstants'
import DeepDiveFilters from './DeepDiveFilters'
import "../Reporting.css"
import Notification from '../../../components/Notification/Notifications'
import * as Notify from "../../../components/Notification/Notifications";
import { levelFiltersLabelFormatter } from '../../../utils/filterLevelMapping'


export const rowCount = 100
const pageSize = 10
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const DeepDive = (props) => {

    const tableRef = useRef()

    const [req, setReq] = useState({})
    const [prevIndex, setPrevIndex] = useState(0)
    const [nextIndex, setNextIndex] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [initialIndex, setInitialIndex] = useState(0)
    const [RTinstance, setRTinstance] = useState([])
    const [isFilterClicked, setIsFilterClicked] = useState(false)
    const [isFirstCall, setisFirstCall] = useState(true)
    const [tableData, setTableData] = useState([])
    const [searchTermReq, setSearchTermReq] = useState({})
    const [sortReq, setSortReq] = useState({})
    const [downloadExcel, setDownloadExcel] = useState(false)
    const [refreshAggData, setRefreshAggData] = useState(true)

    useEffect(() => {
        if(props.tableData?.length)
            scrollIntoView(tableRef)
    }, [props.tableData])

    useEffect(() => {
        setDownloadExcel(false)
        if (props.tableData?.length > 0) {
            if (RTinstance && RTinstance.data) {
                setTableData([...RTinstance.data, ...props.tableData]);
            }
            else {
                setTableData([...props.tableData]);
            }
        }
        else {
            setTableData([])
            setRTinstance(null)
        }
        if (isFilterClicked) {
            setInitialIndex(0)
            setPrevIndex(0)
            setIsFilterClicked(false)
        }
    }, [props.tableData])


    useEffect(() => {
        setTotalCount(props.totalCount)
        setNextIndex(props.nextIndex)
    }, [props.totalCount, props.nextIndex])


    const fetchData = (index) => {
        if (prevIndex >= index || nextIndex >= totalCount)
        return;
        setPrevIndex(index)
        setInitialIndex(Math.floor(nextIndex / pageSize - 1))
        props.fetchDeepDiveData({ request:req, rowIndex: nextIndex, rowCount, searchTermReq,sortReq,refresh_aggregate_data: false })
    }


    const handleFilterRequest = React.useCallback((req) => {
        setDownloadExcel(false)
        setReq(req)
        props.fetchDeepDiveData({request:req,rowIndex: 0, rowCount: rowCount ,refresh_aggregate_data: refreshAggData})
        setPrevIndex(0)
        setInitialIndex(0)
        setIsFilterClicked(true)
        setTableData([])
        setRTinstance([])
        setisFirstCall(true)
        setSearchTermReq({})
        setSortReq({})
    },[rowCount])


    const fecthDataWithSearchTerm = (p_term,p_id,p_type) => {
        setRefreshAggData(true)
        if(p_type === "array") {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                if(p_term?.trim() == 0) {
                  l_searchTermReq?.[p_id] &&  delete l_searchTermReq[p_id]
                }
                else{
                  let bulkTerm = p_term.replace(/\s/g, ' ').split(" ").filter(val => val)
                  l_searchTermReq[p_id] = {
                    "type": p_type,
                    "values": bulkTerm,
                    "rawText": p_term
                  }
                }
                return {
                  ...l_searchTermReq,
                }
              })
        }
        else if(Array.isArray(p_term)){
          setSearchTermReq((old) => {
            let l_searchTermReq = cloneDeep(old)
            let min =  p_term[0] == "" ? null : p_term[0]
            let max =  p_term[1] == "" ? null : p_term[1]
            if(min == null && max == null){
              l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
            }
            else{
              l_searchTermReq[p_id] = {
                "type": p_type,
                "value": {
                  "min": min,
                  "max": max,
                }
              }
            }
            return {
              ...l_searchTermReq,
            }
          })
        }
        else{
          setSearchTermReq((old) => {
            let l_searchTermReq = cloneDeep(old)
            if(p_term?.trim() == 0) {
              l_searchTermReq?.[p_id] &&  delete l_searchTermReq[p_id]
            }
            else{
              l_searchTermReq[p_id] = {
                "type": p_type,
                "value": p_term
              }
            }
            return {
              ...l_searchTermReq,
            }
          })
        }
        isFirstCall && setisFirstCall(false)
    }


    const fecthDataWithSorting = (p_sort) => {
        if(!isEmpty(sortReq))
            isFirstCall && setisFirstCall(false)
        setSortReq(p_sort)
        setRefreshAggData(false)
    }

    const generateExcel = () => {
        setDownloadExcel(false)
        props.generateExcel({request:req,searchTermReq })
    }

    useEffect(() => {
        if((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(req)){
          setTableData([])
          setRTinstance(null)
          setInitialIndex(0)
          setPrevIndex(0)          
          props.fetchDeepDiveData({request:req, rowIndex: 0, rowCount,searchTermReq,sortReq,refresh_aggregate_data: refreshAggData })
        }
    },[searchTermReq,sortReq])

    useEffect(() => {
        if (props.excelData.length) {
            setDownloadExcel(true)
        }
    }, [props.excelData])

    useEffect(() => {
        if(props.excelError){
            Notify.error("Error in downloading Excel!!")
        }
    },[props.excelError])

    const deepDiveTableColumns = React.useMemo(
        () => [
            {
                Header: " ",
                sticky: "left",
                columns: [
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.ARTICLE_ID,
                        accessor: "article",
                        Filter: (instance) => (
                            <ColumnFilterServerSide placeholder="Search in bulk.." {...instance} searchTermValue= {searchTermReq?.article?.rawText} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"array")}/>
                        ),
                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.STYLE_DESCRIPTION,
                        accessor: "style_name",
                        width:300,
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.style_name?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"string")}/>
                        ),
                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.STYLE_COLOR,
                        accessor: "color_desc",
                        width:200,
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.color_desc?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"string")}/>
                        ),
                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.COLOR,
                        accessor: "color",
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.color?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"string")}/>
                        ),

                    },
            ]},
            {
                Header: "  ",
                columns: [
                    {
                        Header: levelFiltersLabelFormatter("level1"),
                        accessor: "l1_name",
                        disableFilters: true
                    },
                    {
                        Header: levelFiltersLabelFormatter("level2"),
                        accessor: "l2_name",
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.l2_name?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"string")}/>
                        ),

                    },
                    {
                        Header: levelFiltersLabelFormatter("level3"),
                        accessor: "l3_name",
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.l3_name?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"string")}/>
                        ),

                    },
                    {
                        Header: levelFiltersLabelFormatter("level4"),
                        accessor: "l4_name",
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.l4_name?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"string")}/>
                        ),

                    },
                    {
                        Header: levelFiltersLabelFormatter("level5"),
                        accessor: "l5_name",
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.l5_name?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"string")}/>
                        ),

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.STORE_ID,
                        accessor: "store_code",
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.store_code?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"string")}/>
                        ),

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.STORE_NAME,
                        accessor: "store_name",
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.store_name?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"string")}/>
                        ),

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.SEASON_CODE_ACTIVE,
                        accessor: "season_code_active",
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.season_code_active?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"string")}/>
                        ),

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.SEASON_CODE_OPEN,
                        accessor: "season_code_opened",
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.season_code_opened?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"string")}/>
                        ),

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.STORE_GRADE,
                        accessor: "store_grade",
                        Filter: (instance) => (
                            <ColumnFilterServerSide  {...instance} searchTermValue= {searchTermReq?.store_grade?.value} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"exact")}/>
                        ),

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.PLANNED_ALLOCATION,
                        accessor: "planned_units",
                        Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.planned_units?.value?.min} maxSearchTermValue= {searchTermReq?.planned_units?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.ACTUAL_ALLOCATION,
                        accessor: "allocated_units",
                         Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.allocated_units?.value?.min} maxSearchTermValue= {searchTermReq?.allocated_units?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.UNIT_SALES,
                        accessor: "units",
                         Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.units?.value?.min} maxSearchTermValue= {searchTermReq?.units?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',

                    },
                ],
            },
            {
                Header: "Plan vs Actual Allocation",
                columns: [
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.QTY_MATCH_PER,
                        accessor: "planned_perc",
                         Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.planned_perc?.value?.min} maxSearchTermValue= {searchTermReq?.planned_perc?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.SIZE_MATCH_PER,
                        accessor: "size_perc_planned",
                         Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.size_perc_planned?.value?.min} maxSearchTermValue= {searchTermReq?.size_perc_planned?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',

                    },
                ],
            },
            {
                Header: "Actual Allocation vs Sales",
                columns: [
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.ALLOCATION_MATCH,
                        accessor: "match_perc",
                         Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.match_perc?.value?.min} maxSearchTermValue= {searchTermReq?.match_perc?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.SIZE_MATCH_PER_SALES,
                        accessor: "match_perc_size",
                         Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.match_perc_size?.value?.min} maxSearchTermValue= {searchTermReq?.match_perc_size?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.SKU_DEPTH,
                        accessor: "sku_depth_store",
                         Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.sku_depth_store?.value?.min} maxSearchTermValue= {searchTermReq?.sku_depth_store?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',

                    },
                ],
            },
            {
                Header: "Sales Performance",
                columns: [
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.AVG_GRADE_REVENUE,
                        accessor: "grade_revenue",
                         Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.grade_revenue?.value?.min} maxSearchTermValue= {searchTermReq?.grade_revenue?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.STORE_REVENUE,
                        accessor: "revenue",
                         Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.revenue?.value?.min} maxSearchTermValue= {searchTermReq?.revenue?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',
                    
                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.AVG_GRADE_MARGIN,
                        accessor: "grade_margin",
                         Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.grade_margin?.value?.min} maxSearchTermValue= {searchTermReq?.grade_margin?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',

                    },
                    {
                        Header: DEEP_DIVE_TABLE_HEADERS.STORE_MARGIN,
                        accessor: "margin",
                         Filter: (instance) => <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.margin?.value?.min} maxSearchTermValue= {searchTermReq?.margin?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>,
                         filter: 'between',
                    },
                ],
            },
        ],
        [searchTermReq]
    );

    return (
        <div>
            <Notification/>
            <DeepDiveFilters activeTab={props.activeTab} handleFilterRequest={(p_req) => handleFilterRequest(p_req)}/>
            <div>
                <PageLoader loader={props.loading} gridLoader={true}>
                    {
                        props.error ? <div className="error">{props.tableDataError || "Something Went Wrong!!"}</div>
                        :  <>
                            <div className="card__wrapper">
                                {props?.cardData?.map((val,ind) => (
                                    <Card key={ind} title={val.title} value={val.value}/>)
                                )}
                            </div>
                            <div className="deepdive__export__wrapper">
                                {tableData.length ? <ExportFile text={"Excel"} downloadExcelData={downloadExcel} data={props.excelData} sheetName={"Allocation Deep Dive"} callFunc={generateExcel} fileName={"allocation_deep_dive"} type={1} /> : null}
                            </div>
                            <div style={{paddingTop:'2rem'}} ref={tableRef}>
                                <ReactTableWithPlugins
                                    columns={deepDiveTableColumns}
                                    data={tableData ? tableData : []}
                                    // keyRT="deepDive"
                                    renderMarkup='TableMarkup'
                                    keyRT="sortAndSearch"
                                    shouldPagination
                                    fetchData={(data) => fetchData(data)}
                                    totalCount={totalCount}
                                    totalRecordsLen={tableData?.length}
                                    pageSize={pageSize}
                                    initialPageIndex={initialIndex}
                                    getInstanceOnMount={(instance) => {
                                        setRTinstance(instance)
                                    }}
                                    getManualSortByOptions={(instance) => {
                                        fecthDataWithSorting(instance)
                                    }}
                                    manualSortBy={true}
                                    tableId="deepdive"
                                />
                            </div>
                        </>
                    }
                </PageLoader>
            </div>
        </div>
    )
}

const mapStateToProps = ({ deepDive }) => ({
    error: deepDive.error,
    success: deepDive.success,
    loading: deepDive.loading,
    cardData: deepDive.cardData,
    errorMessage: deepDive.errorMessage,
    tableData: deepDive.tableData,
    totalCount: deepDive.totalCount,
    nextIndex: deepDive.nextIndex,
    excelData: deepDive.excelData,
    excelError: deepDive.excelError,
  });
  
const mapDispatchToProps = (dispatch) => ({
    fetchDeepDiveData:  (payload) => dispatch(fetchDeepDiveData(payload)),
    generateExcel: (payload) => dispatch(generateExcel(payload)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(DeepDive);
