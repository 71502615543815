// Action types

//Login API declaration
export const FETCH_LOGIN_API = '@@login/FETCH_LOGIN_API';
export const FETCH_LOGIN_API_SUCCESS = '@@login/FETCH_LOGIN_API_SUCCESS';
export const FETCH_LOGIN_API_ERROR = '@@login/FETCH_LOGIN_API_ERROR';

//User permission action
export const FETCH_USER_PERMISSION = '@@login/FETCH_USER_PERMISSION';
export const FETCH_USER_ROLE = '@@login/FETCH_USER_ROLE';

export const fetchLoginApi = (payload) => ({
  type: FETCH_LOGIN_API,
  payload,
});

export const fetchLoginApiSuccess = (payload) => ({
  type: FETCH_LOGIN_API_SUCCESS,
  payload,
});

export const fetchLoginApiError = (payload) => ({
  type: FETCH_LOGIN_API_ERROR,
  payload,
});

export const fetchUserPermission = (payload) => ({
  type: FETCH_USER_PERMISSION,
  payload,
});

export const fetchUserRole = (payload) => ({
  type: FETCH_USER_ROLE,
  payload,
});
