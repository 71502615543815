import axios from "axios";
import { LOGIN_ROUTE } from "../constants/routeConstants"
import * as Notify from "../components/Notification/Notifications"
import { BASE_URL, REFRESH_API } from "../constants/apiConstants";

class Api {
  static headers(headersparams) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorizationkey: localStorage.getItem('access_token'),
      ...headersparams,
    };
  }

  static get(route) {
    return this.request(route, null, "GET");
  }

  static post(route, params,source) {
    return this.request(route, params, "POST",source);
  }

  static put(route, params) {
    return this.request(route, params, "PUT");
  }

  static delete(route, params) {
    return this.request(route, params, "DELETE");
  }


  static request(route, params, verb,source) {
    const url = `${route}`;
    const options = { method: verb, data: params ,cancelToken: source?.token};
    options.headers = Api.headers();
    return axios(url, options).catch((err) => {
      console.log(err)
      // if (
      //   err.response.status === 401 &&
      //   err.response.data.code === "token_not_valid"
      // ) {
      //   //refresh token API call here
      //   axios
      //     .post(REFRESH_API, {
      //       refresh: localStorage.getItem("refresh_token"),
      //     })
      //     .then((res) => {
      //       if (res?.data?.access) {
      //         localStorage.setItem("access_token", res.data.access);
      //         return axios(url, options);
      //       } else {
      //         throw new Error("Refresh token not valid");
      //       }
      //     });
      // }
      // else 
      if (err?.response?.status === 403) {
        Notify.error("User access restricted. Redirecting to login", 2500);
        setTimeout(() => {
          let url = LOGIN_ROUTE;
          window.history.pushState({ urlPath: url }, "", url);
          window.location.reload();
          //redirect to login page
        }, 2500);
      }
      if (err?.response?.status === 524) {
        Notify.error("Timeout Error!");
        // return Promise.reject(522)
      }

    });;
  }
}

export default Api;
