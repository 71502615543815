import { takeLatest, put, call, all } from "redux-saga/effects";
import * as actionTypes from "./POAllocationAction/actionTypes"
import {
    getPOASNDetails
} from "../../../routes/api";
import { dateFormat } from "../../../utils/commonUtilities";
import { cloneDeep } from "lodash";


function* getPOAllocationDataWorker(action) {
    try {
        const { payload } = action;

        const res = yield call(getPOASNDetails, payload);
        let { data, message, status } = res?.data
        if (status) {
            let poData = cloneDeep(data)
            for (let index = 0; index < poData.length; index++) {
                poData[index].inventory_date = dateFormat(poData[index].inventory_date)
                poData[index].creation_date = dateFormat(poData[index].creation_date)
                poData[index].latest_receipt_date = dateFormat(poData[index].latest_receipt_date)
            }
            yield put({ type: actionTypes.GET_PO_DATA_SUCCESS, data: poData });
        }
        else {
            yield put({ type: actionTypes.GET_PO_DATA_ERROR, error: message ? message : "Something went wrong!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_PO_DATA_ERROR, error: "Something went wrong!" });
    }
}

function* getPOAllocationDataWatcher() {
    yield takeLatest(actionTypes.GET_PO_DATA, getPOAllocationDataWorker)
}

export function* poAllocationSaga() {
    yield all([
        getPOAllocationDataWatcher()
    ])
}