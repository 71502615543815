import { cloneDeep, isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import PageLoader from "../../../components/Loader/PageLoader"
import SetAllTable from "../../../components/SetAllTable/SetAllTable"
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import { updateArticleName } from "../../../utils/filterLevelMapping";
import { getPacksForBulkEdit, updatePacksEachesForMultipleStores } from "../FinalizeAction";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const BulkEditPacksAndEaches = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [column, setColumn] = useState([])
    const [data, setData] = useState([])
    const [allocated, setAllocated] = useState({})
    const [available, setAvailable] = useState({})

    const {packConfig, selectedFlatRows, buttonLabel, sizes, packsAvailable, eachesAvailable, article, allocationCode, allocationCodeAfterUpdate} = props
    
    console.log(props.selectedFlatRows,props.packConfig,props.buttonLabel,eachesAvailable,'selectedRows')

    useEffect(() => {
        if(buttonLabel === "Packs" && selectedFlatRows?.length) {
            let selectedStores = selectedFlatRows?.map(val => val.values.store)
            props.getPacksForBulkEdit({"allocation_code":allocationCodeAfterUpdate,"article":article,"store":selectedStores})
        }
    },[buttonLabel,selectedFlatRows])

    useEffect(() => {
        if(!isEmpty(selectedFlatRows)) {
            let column = [], l_data= {}, packsAvailablePerStore = 0, eachesAvailablePerStore = {}, no_of_stores = selectedFlatRows.length
            
            if(buttonLabel === "Packs") {
                if(props?.packDataForBulkUpdate?.data?.length && props?.packDataForBulkUpdate?.pack_sizes?.length) {
                    const { data, pack_sizes } = props?.packDataForBulkUpdate
                    
                                    // packsAvailablePerStore = Number(packsAvailable) || 0;
                                    // console.log(props.packDataForBulkUpdate,'packDataForBulkUpdate')
                                    
                                    // selectedFlatRows.forEach(val => {
                                    //     packsAvailablePerStore+= (Number(val.values?.['packs_allocated']) || 0)  
                                    // })
                                    
                                    // packsAvailablePerStore = Math.floor(packsAvailablePerStore/no_of_stores)
                    
                                    let sizes_column = pack_sizes?.map(val => {
                                        return {
                                            Header:'Pack Configuration',
                                            columns: [{
                                                Header: val,
                                                accessor: val
                                            }]
                                        }
                                    })
                    
                                    column.push({
                                        Header: 'Packs per store',
                                        accessor: 'packs_allocated',
                                        Cell: (instance) => (
                                        <InputCell {...instance} style={{ textAlign: "center" }} 
                                                min={0} type="number" step={1} max={instance?.row?.original?.packs_available}
                                                changeHandler={(rowIdx, columnId, values) => {
                                                    let l_available_units = Number(instance?.row?.original?.packs_available) - Number(values)
                                                    let l_available_in_req = Number(instance?.row?.original?.total_available) - (Number(values) * Number(no_of_stores))
                                                    instance.updateMyData(rowIdx, 'pack_available_per_store', l_available_units);
                                                    setAllocated((old) => {
                                                        return {
                                                            ...old,
                                                            [instance.row.original['pack_id']]: values,
                                                        }
                                                    })
                    
                                                    setAvailable((old) => {
                                                        return {
                                                            ...old,
                                                            [instance.row.original['pack_id']]: l_available_in_req,
                                                        //    'available': l_available_units,
                                                        }
                                                    })
                                                }}
                                        /> 
                                        ),
                                    },
                                    {
                                        Header: 'Packs avaialble per store',
                                        accessor: 'pack_available_per_store'
                                    })
                    
                                    column.push({
                                        Header: 'Pack Id',
                                        accessor: 'pack_id',
                                    },
                                    ...sizes_column
                                    )
                    
                                    // let packConfigCopy = {...packConfig} 
                                    // packConfigCopy['packs_available'] = packsAvailablePerStore
                                    // packConfigCopy['packs_allocated'] = null
                                    // packConfigCopy['pack_id'] = selectedFlatRows[0]?.values?.['packs']
                                    // data = [{...packConfigCopy}]
                                    let prep_data = data?.map(val => {
                                        return {
                                            "packs_available": Math.floor((val.pack_available|| 0)/no_of_stores),
                                            "total_available": val.pack_available,
                                            "pack_available_per_store": val.pack_available_per_store,
                                            "pack_id": val.pack_id,
                                            "packs_allocated": null,
                                            ...val?.pack_config
                                        }
                                    })
                                    console.log(prep_data,'acascascc')
                                    l_data = prep_data
                }

            }
            else {

                eachesAvailablePerStore = cloneDeep(eachesAvailable) || {};
                let l_sizes = Object.keys(eachesAvailable)
                selectedFlatRows.forEach((val) => {
                    l_sizes.forEach((s) => {
                        eachesAvailablePerStore[s] = (Number(eachesAvailablePerStore[s]) || 0) + (Number(val.values[s]) || 0)
                    })
                });

                Object.keys(eachesAvailablePerStore).forEach(val => {
                    eachesAvailablePerStore[val] = Math.floor(Number(eachesAvailablePerStore[val])/no_of_stores)
                })

                let eachesAvailablePerStorePerSize = cloneDeep(eachesAvailablePerStore)
                column = sizes?.map(val => {
                    return {
                        Header: val,
                        accessor: val,
                        Cell: (instance) => (
                            instance.row?.original?.['id'] === 'edit' ? (
                            <InputCell {...instance} style={{ textAlign: "center" }} 
                                min={0} type="number" step={1} max={eachesAvailablePerStorePerSize?.[val]}
                                changeHandler={(rowIdx, columnId, values) => {
                                    let l_available_units = eachesAvailablePerStore[val] - Number(values)
                                    instance.updateMyData(rowIdx+1, val, l_available_units);

                                    setAllocated((old) => {
                                        return {
                                            ...old,
                                            [val]: values,
                                        }
                                    })

                                    setAvailable((old) => {
                                        return {
                                            ...old,
                                            [val]: l_available_units,
                                        }
                                    })
                                }}
                            /> 
                        ): (
                            <div>
                                {instance.row?.original?.[val]}
                            </div>
                        )),
                    }
                })

                column.unshift({
                    Header: ' ',
                    accessor: 'label'
                })  
                
                l_data = [{id:'edit', label:'Eaches per store'},{id:'view', label:'Eaches available per store', ...eachesAvailablePerStore}]
            }
            setColumn(column)
            console.log(l_data,'acascascc')
            setData(l_data)
        }

    },[packConfig,selectedFlatRows,buttonLabel,sizes,packsAvailable,eachesAvailable,props.packDataForBulkUpdate])

    useEffect(() => {
        if(props.buttonClicked) {
            setShowModal(true);
        }
    }, [props.buttonClicked])

    const handleSetAllOk = () => {
        let packId = selectedFlatRows[0]?.values?.['packs']
        let selectedStores = selectedFlatRows?.map(val => val.values.store)
        let req = {}
        if(buttonLabel === "Packs") {
           let stores = []
           selectedStores.forEach(val => {
               stores.push({
                   "store": val,
                   "updated_packs": {
                        // [packId] : allocated.packs_allocated
                        ...allocated
                    }
               })
           })
           req = {
                "allocation_code": allocationCodeAfterUpdate,
                "available_packs" : {
                    // [packId] : available.available
                    ...available
                },
                "article" : article,
                stores
           }
        }
        else {
            let stores = []
            selectedStores.forEach(val => {
                stores.push({
                    "store": val,
                    "updated_eaches": {
                        ...allocated
                     }
                })
            })
            req = {
                 "allocation_code": allocationCodeAfterUpdate,
                 "available_eaches" : {
                      ...available
                 },
                 "article" : article,
                 stores
            }

        }

        let { originalAllocationCode } = props
        let l_original_allocation_code = allocationCodeAfterUpdate === originalAllocationCode ? "" : originalAllocationCode

        console.log(allocationCodeAfterUpdate,originalAllocationCode,"compoent Mount finlaize bulkedit")

        req["original_allocation_code"] = l_original_allocation_code

        props.updatePacksEachesForMultipleStores(req)
        props.resetButtonClick();
    }
    
    const handleSetAllCancel = () => {
        props.resetButtonClick();
        setShowModal(false);
    }

    const handleClose = () => {
        props.resetButtonClick();
        showModal && setShowModal(false);
    }

      
    return (
        showModal &&
        <div>
            <SetAllTable 
                handleOk={handleSetAllOk} 
                handleCancel={handleSetAllCancel} 
                handleClose={() => handleClose()} 
                heading= {`Bulk Edit ${buttonLabel}`}
                ignoreCloseOnSave={true}
            >
                <div className="mx-2 pr-4 pl-4 row justify-content-center mb-4">
                    <div className="detail__div mr-4">
                        <span className="fnt-bold mr-2">Number of Stores :</span>
                        <span>{selectedFlatRows.length}</span>
                    </div>

                    <div className="detail__div mr-4">
                        <span className="fnt-bold mr-2">{updateArticleName(false)} : </span>
                        <span>{article}</span>
                    </div>
                </div>
                <div className="mx-2 p-2 row justify-content-center">
                    <PageLoader loader={props.packsForBulkUpdateLoading} gridLoader={true}>
                        {props.packForBulkUpdateError ? (
                        <div className="error">Something Went Wrong!!</div>
                        ) : (
                        data.length && <ReactTableWithPlugins
                            hideColumnsFilter
                            style={{ maxWidth: "100%", margin: "auto" }}
                            data={data}
                            columns={column}
                            renderMarkup="TableMarkup"
                        />
                        )}
                    </PageLoader>
                </div>
            </SetAllTable>
        </div>
    )
}

const mapStateToProps = ({ finalize }) => {
    return {
        packsForBulkUpdateLoading: finalize.packsForBulkUpdateLoading,
        packDataForBulkUpdate: finalize.packDataForBulkUpdate,
        packForBulkUpdateError: finalize.packForBulkUpdateError,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updatePacksEachesForMultipleStores: (payload) => dispatch(updatePacksEachesForMultipleStores(payload)),
    getPacksForBulkEdit: (payload) => dispatch(getPacksForBulkEdit(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BulkEditPacksAndEaches)
