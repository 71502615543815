import React, { useEffect, useState } from 'react';
import MultiSelect from "../../components/Filters/MultiSelect";
import Select from "../../components/Filters/SingleSelect";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { intersectionWith, isEqual } from 'lodash'
import * as adaVisualActions from './actions/AdaVisualActions';
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications";
import * as AdaConst from './AdaVisualConstants';
import { levelFiltersLabelFormatter } from "../../utils/filterLevelMapping";
import { getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter } from "../../utils/commonUtilities";

/**
 * View Component with Ada Stand Alone Filters
 */
const AdaVisualFilters = React.memo((props) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [subCat, setSubCat] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [articleId, setArticleId] = useState(null);
    const [request, setRequest] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [subCatOptions, setSubCatOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [articleIdOptions, setArticleIdOptions] = useState(null);

    const { adaVisualActions, handleFilterRequest, resetAdaDisplayState } = props;

    useEffect(() => {
        adaVisualActions.getAdaFiltersData({});
    }, []);

    useEffect(() => {
        setDepartmentOptions(() => props.departmentOptions);
    }, [props.departmentOptions]);


    useEffect(() => {
        setGenderOptions(() => props.genderOptions);
    }, [props.genderOptions]);

    useEffect(() => {
        setSubCatOptions(() => props.subCatOptions);
    }, [props.subCatOptions]);

    useEffect(() => {
        setDCSOptions(() => props.dcsOptions);
    }, [props.dcsOptions]);

    useEffect(() => {
        setArticleIdOptions(() => props.articleIdOptions);
        if (department && gender) {
            setArticleId(() => props.articleIdOptions);
        }
        else {
            //No code here
        }
    }, [props.articleIdOptions]);

    const resetRestFilters = (p_index) => {
        if (p_index == 0) {
            gender && setGender(null);
            genderOptions && setGenderOptions(null);
        }
        if (p_index == 0 || p_index == 1) {
            subCat && setSubCat(null);
            subCatOptions && setSubCatOptions(null);
        }
        if (p_index == 0 || p_index == 1 || p_index == 2) {
            dcs && setDCS(null);
            dcsOptions && setDCSOptions(null);
        }
    };

    const onDepartmentChange = (val) => {
        setDepartment(val);
        adaVisualActions.getAdaFiltersData({ level1: [val] });
        resetRestFilters(0);
    };

    const onGenderChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
            setArticleId(null);
            setGender(valuesArray[1]);
            adaVisualActions.getAdaFiltersData({ level1: [department], level2: [...req_val] });
            setRequest(old => {
                return {
                    ...old,
                    'level2': valuesArray[0],
                    'level3': null,
                    'level4': null,
                    // 'article': null
                }
            })
        }
        else {
            setGender(val);
            adaVisualActions.getAdaFiltersData({ level1: [department], level2: [val] })
        }
        resetRestFilters(1);
    };

    const onSubCatChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that)
            let req_val = intersectionWith(valuesArray[0], subCatOptions, isEqual);
            setArticleId(null);
            setSubCat(valuesArray[1]);
            adaVisualActions.getAdaFiltersData({ level1: [department], level2: [...gender], level3: [...req_val] });
            setRequest(old => {
                return {
                    ...old,
                    'level3': valuesArray[0],
                    'level4': null,
                    // 'article': null
                }
            });
        }
        else {
            setSubCat(val);
            adaVisualActions.getAdaFiltersData({ level1: [department], level2: [...gender], level3: [val] });
        }
        resetRestFilters(2);
    };

    const onDCSChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that)
            let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
            setArticleId(null);
            setDCS(valuesArray[1]);
            adaVisualActions.getAdaFiltersData({
                level1: [department], level2: [...gender], level3: [...subCat],
                level4: [...req_val]
            });
            setRequest(old => {
                return {
                    ...old,
                    'level4': valuesArray[0],
                    // 'article': null
                }
            });
        }
        else {
            setDCS(val);
            adaVisualActions.getAdaFiltersData(
                { level1: [department], level2: [gender], level3: [subCat], level4: [val] },
                "articleId"
            );
        }
        resetRestFilters(3);
    };

    const onArticleIdChange = (val, event, that) => {
        if (Array.isArray(val)) {
            setArticleId(null);
            let valuesArray = handleSelectAll(val, event, that)
            let req_val = intersectionWith(valuesArray[0], articleIdOptions, isEqual);
            setArticleId(valuesArray[1]);
            adaVisualActions.getAdaFiltersData({
                level1: [department], level2: [...gender], level3: subCat ? [...subCat] : null,
                level4: dcs ? [...dcs] : null, article: [...req_val],
            });

            setRequest(old => {
                return {
                    ...old,
                    'article': valuesArray[0]
                }
            })
        }
        else {
            setArticleId(val);
            adaVisualActions.getAdaFiltersData({
                level1: [department], level2: [gender], level3: [subCat], level4: [dcs],
                article: [val],
            });
        }
        resetRestFilters(4);
    };

    const applyFilters = () => {
        if (department && gender && articleId) {
            handleFilterRequest({
                department,
                gender,
                subCat,
                dcs,
                articleId,
            });
            setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department });
        } else {
            Notify.error("Please Select All Mandatory Options!!");
        }
    };

    const resetFilters = () => {
        setRequest(null)
        setDepartment(null);
        setGender(null);
        setSubCat(null);
        setDCS(null);
        setArticleId(null);
        adaVisualActions.resetAdaFilterData();
        adaVisualActions.resetAdaVisual();
        adaVisualActions.getAdaFiltersData({});
        resetAdaDisplayState();
    };

    useEffect(() => {
        getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
    }, []);

    return (
        <>
            <section className="row section">
                <Notification />
                <div className="col-md-3 required">
                    <label className="fnt-md fnt-bold fnt-bold">{levelFiltersLabelFormatter("level1")}</label>
                    <Select
                        name="department"
                        value={department}
                        onChange={onDepartmentChange}
                        placeHolder={`Select ${levelFiltersLabelFormatter("level1")}`}
                        options={departmentOptions?.length ? departmentOptions : []}
                    />
                </div>
                <div className="col-md-3 required">
                    <label className="fnt-md fnt-bold">{AdaConst.ADA_FILTER_GENDER}</label>
                    <MultiSelect
                        name="gender"
                        dropdownValue={gender}
                        onDropDownChange={onGenderChange}
                        placeHolder={`Select ${props.filterLabels?.level2}`}
                        optionsArray={genderOptions?.length ? genderOptions : []}
                    />
                </div>
                {
                !shouldHideForCurrentClient("l3_name") &&
                <>
                    <div className="col-md-3 fill">
                        <label className="fnt-md fnt-bold">{AdaConst.ADA_FILTER_SUBCATEGORY}</label>
                        <MultiSelect
                            name="subCat"
                            dropdownValue={subCat}
                            onDropDownChange={onSubCatChange}
                            placeHolder={`Select ${props.filterLabels?.level3}`}
                            optionsArray={subCatOptions?.length ? subCatOptions : []}
                        />
                    </div>
                    <div className="col-md-3 fill">
                        <label className="fnt-md fnt-bold">{AdaConst.ADA_FILTER_DCS}</label>
                        <MultiSelect
                            name="dcs"
                            dropdownValue={dcs}
                            onDropDownChange={onDCSChange}
                            placeHolder={`Select ${props.filterLabels?.level4}`}
                            optionsArray={dcsOptions?.length ? dcsOptions : []}
                        />
                    </div>
                </>
                }
                <div className="col-md-3 fill">
                    <label className="fnt-md fnt-bold">{AdaConst.ADA_FILTER_ARTICLE_ID}</label>
                    <MultiSelect
                        name="articleId"
                        dropdownValue={articleId}
                        onDropDownChange={onArticleIdChange}
                        placeHolder={"Select articleId"}
                        optionsArray={articleIdOptions?.length ? articleIdOptions : []}
                    />
                </div>
                <div className="col-md-3">
                    <button
                        onClick={applyFilters}
                        className="btn btn-primary filter-constraints-mode"
                        title="Apply filters"
                    >
                        <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                    </button>
                    <button
                        onClick={() => resetFilters("All")}
                        className="btn undo-constraints-mode"
                        title="Reset filters"
                    >
                        <i className="fa fa-undo mr-2"></i>Reset
                    </button>
                </div>
                <div className="col-md-3 required fnt-md pt-5" style={{ color: "red" }}>
                    <label></label>Fields are mandatory
                </div>
            </section>
        </>
    );
}
);

const mapStateToProps = ({ adaVisual }) => ({
    departmentOptions: adaVisual.departmentOptions,
    genderOptions: adaVisual.genderOptions,
    subCatOptions: adaVisual.subCatOptions,
    dcsOptions: adaVisual.dcsOptions,
    articleIdOptions: adaVisual.articleIdOptions,
    filterLabels: adaVisual.filterLabels,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdaVisualFilters);