import React, { useEffect, useState } from "react";
import "../../components/Filters/Filters.css";
import MultiSelect from "../../components/Filters/MultiSelect";
import Select from "../../components/Filters/SingleSelect";
import { connect } from "react-redux";
import {
    resetFiltersOptions,
    fetchFilterData,
} from "./ReportingAction";
import * as Notify from "../../components/Notification/Notifications";
import Notification from "../../components/Notification/Notifications";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import { levelFiltersLabelFormatter, updateArticleName } from "../../utils/filterLevelMapping"
import { intersectionWith, isEqual } from "lodash";
import { getUserPreferenceFilter, scrollIntoView, setUserPreferenceFilter, shouldHideForCurrentClient } from "../../utils/commonUtilities";


const ReportingFilters = React.memo(
    ({
        // departmentOptions,
        // genderOptions,
        // rbuOptions,
        // dcsOptions,
        // styleOptions,
        // colourOptions,
        // sizeOptions,
        ...props
    }) => {
        const [request, setRequest] = useState(null)

        const [department, setDepartment] = useState(null);
        const [gender, setGender] = useState(null);
        const [rbu, setRbu] = useState(null);
        const [dcs, setDCS] = useState(null);
        const [level5, setLevel5] = useState(null);
        const [articleStatus, setArticleStatus] = useState(null);
        const [articleId, setArticleId] = useState(null)
        const [clearance, setClearance] = useState({ value: null, label: "All" })

        const [channel, setChannel] = useState(null);
        const [storeId, setStoreId] = useState(null);
        const [storeGradeSource, setStoreGradeSource] = useState(null)
        const [storeGrade, setStoreGrade] = useState(null);
        const [region, setRegion] = useState(null)
        const [climate, setClimate] = useState(null)
        const [storeStatus, setStoreStatus] = useState(null)

        const [departmentOptions, setDepartmentOptions] = useState(null);
        const [genderOptions, setGenderOptions] = useState(null);
        const [rbuOptions, setRbuOptions] = useState(null);
        const [dcsOptions, setDCSOptions] = useState(null);
        const [level5Options, setLevel5Options] = useState(null);
        const [articleStatusOptions, setArticleStatusOptions] = useState(null);
        const [articleIdOptions, setArticleIdOptions] = useState(null)
        const [clearanceOptions, setClearanceOptions] = useState([{ value: null, label: "All" }, { value: "Yes", label: "Yes" }, { value: "No", label: "No" }])

        const [channelOptions, setChannelOptions] = useState(null);
        const [storeOptions, setStoreOptions] = useState(null);
        const [storeGradeOptions, setStoreGradeOptions] = useState(null);
        const [regionOptions, setRegionOptions] = useState(null)
        const [climateOptions, setClimateOptions] = useState(null)
        const [storeStatusOptions, setStoreStatusOptions] = useState(null)
        const storeGradeSourceOptions = {
            "Accessories": [{ value: "Department", label: "Department" }, { value: "socks", label: "Socks & Body" }],
            "Apparel": [{ value: "Department", label: "Department" }, { value: "kids", label: "Kids" }, { value: "tee", label: "Tee" }],
            "Footwear": [{ value: "Department", label: "Department" }]
        }

        useEffect(() => {
            setDepartmentOptions(() => props.department);
            setGenderOptions(() => props.gender);
            setRbuOptions(() => props.rbu);
            setDCSOptions(() => props.dcs);
            setLevel5Options(() => props.level5);
            setArticleStatusOptions(() => props.articleStatus);
            setArticleIdOptions(() => props.articleId);

            setStoreOptions(() => props.store);
            setStoreGradeOptions(() => props.storeGrade);
            setRegionOptions(() => props.region);
            setClimateOptions(() => props.climate);
            setStoreStatusOptions(() => props.storeStatus);
            setChannelOptions(() => props.channel);

        },
            [props.department, props.gender, props.rbu, props.dcs, props.level5, props.articleStatus,
            props.articleId, props.store, props.storeGrade, props.region, props.climate,
            props.storeStatus, props.channel]);


        useEffect(() => {
            props.fetchFilterData({ level1: [] }, "department");
        }, []);

        const resetRestFilters = (p_index) => {
            if (p_index == 0) {
                gender && setGender(null);
                genderOptions && setGenderOptions(null)
                storeId && setStoreId(null)
                storeOptions && setStoreOptions(null)
                storeGradeSource && setStoreGradeSource(null)
                channel && setChannel(null)
                channelOptions && setChannelOptions(null)
            }
            if (p_index == 0 || p_index == 1) {
                rbu && setRbu(null);
                rbuOptions && setRbuOptions(null)
                channel && setChannel(null)
                channelOptions && setChannelOptions(null)
            }
            if (p_index == 0 || p_index == 1 || p_index == 2) {
                dcs && setDCS(null);
                dcsOptions && setDCSOptions(null)
                articleStatus && setArticleStatus(null)
                articleStatusOptions && setArticleStatusOptions(null)
                channel && setChannel(null)
                channelOptions && setChannelOptions(null)
            }
            if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3) {
                level5 && setLevel5(null);
                level5Options && setLevel5Options(null);
                articleStatus && setArticleStatus(null)
                articleStatusOptions && setArticleStatusOptions(null)
                articleId && setArticleId(null)
                articleIdOptions && setArticleIdOptions(null)
                channel && setChannel(null)
                channelOptions && setChannelOptions(null)
            }
            if (p_index == 11) {
                articleStatus && setArticleStatus(null)
                articleStatusOptions && setArticleStatusOptions(null)
                channel && setChannel(null)
                channelOptions && setChannelOptions(null)
            }
            if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4 || p_index == 11) {
                articleId && setArticleId(null)
                articleIdOptions && setArticleIdOptions(null)
                storeId && setStoreId(null)
                storeOptions && setStoreOptions(null)
                channel && setChannel(null)
                channelOptions && setChannelOptions(null)
            }
            if (p_index == 5 || p_index == 0) {
                storeGrade && setStoreGrade(null)
                storeGradeOptions && setStoreGradeOptions(null)
                region && setRegion(null)
                regionOptions && setRegionOptions(null)
                climate && setClimate(null)
                climateOptions && setClimateOptions(null)
                storeStatus && setStoreStatus(null)
                storeStatusOptions && setStoreStatusOptions(null)
            }
            if (p_index == 6 || p_index == 0) {
                region && setRegion(null)
                regionOptions && setRegionOptions(null)
                climate && setClimate(null)
                climateOptions && setClimateOptions(null)
                storeStatus && setStoreStatus(null)
                storeStatusOptions && setStoreStatusOptions(null)
            }
            if (p_index == 7 || p_index == 0) {
                climate && setClimate(null)
                climateOptions && setClimateOptions(null)
                storeStatus && setStoreStatus(null)
                storeStatusOptions && setStoreStatusOptions(null)
            }
            if (p_index == 8 || p_index == 0) {
                storeStatus && setStoreStatus(null)
                storeStatusOptions && setStoreStatusOptions(null)
            }
            if (p_index == 9) {
                channel && setChannel(null)
                channelOptions && setChannelOptions(null)
            }
            if (p_index == 9 || p_index == 0 || p_index == 14 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 11 || p_index == 12 || p_index == 13 || p_index == 4) {
                storeId && setStoreId(null)
                storeOptions && setStoreOptions(null)
                storeGrade && setStoreGrade(null)
                storeGradeOptions && setStoreGradeOptions(null)
                region && setRegion(null)
                regionOptions && setRegionOptions(null)
                climate && setClimate(null)
                climateOptions && setClimateOptions(null)
                storeStatus && setStoreStatus(null)
                storeStatusOptions && setStoreStatusOptions(null)
            }
            if (p_index == 10) {
                storeGrade && setStoreGrade(null)
            }
        };

        const onDepartmentChange = (val) => {
            setDepartment(val);
            resetRestFilters(0);
            props.fetchFilterData({ level1: [val] }, "gender");
        };
        const onGenderChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that)
            setGender(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'gender': valuesArray[0]
                }
            })
            let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
            // setDepartment(valuesArray[0]);
            resetRestFilters(1)
            // API call
            // if (valuesArray[0].length) {
            props.fetchFilterData({
                level1: [department],
                level2: req_val
            },
                "sub_category"
            );
            // }
        };
        const onRbuChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that)
            setRbu(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'rbu': valuesArray[0]
                }
            })
            let req_val = intersectionWith(valuesArray[0], rbuOptions, isEqual);
            resetRestFilters(2);
            //API call to get next filter
            // if (valuesArray[0].length) {
            props.fetchFilterData(
                { level1: [department], level2: gender, level3: req_val }, "dcs"
            );
            // }
        };

        const onDCSChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that)
            setDCS(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'dcs': valuesArray[0]
                }
            })
            let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
            resetRestFilters(3);
            //API call to get next filter
            // if (valuesArray[0].length) {
            props.fetchFilterData(
                { level1: [department], level2: gender, level3: rbu, level4: req_val },
                "article_status"
            );
            // }

        };

        const onLevel5Change = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that)
            setLevel5(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level5': valuesArray[0]
                }
            })
            let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
            resetRestFilters(11);
            //API call to get next filter
            // if (valuesArray[0].length) {
            props.fetchFilterData(
                { level1: [department], level2: gender, level3: rbu, level4: dcs, level5: req_val },
                "level5"
            );
            // }

        };

        const onArticleStatusChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that)
            setArticleStatus(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'article_status': valuesArray[0]
                }
            })
            let req_val = intersectionWith(valuesArray[0], articleStatusOptions, isEqual);
            resetRestFilters(4);
            // API call to get next filter
            // if (valuesArray[0].length) {
            props.fetchFilterData(
                {
                    level1: [department],
                    level2: gender,
                    level3: rbu,
                    level4: dcs,
                    level5: level5,
                    "article_status": req_val
                },
                "articleid"
            );
            // }
        }

        const onArticleIdChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that)
            setArticleId(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'article_id': valuesArray[0]
                }
            })
            let req_val = intersectionWith(valuesArray[0], articleIdOptions, isEqual);
            resetRestFilters(9);
            // if (valuesArray[0].length) {
            props.fetchFilterData(
                {
                    level1: [department],
                    level2: gender,
                    level3: rbu,
                    level4: dcs,
                    level5: level5,
                    "article_status": articleStatus,
                    "articleid": req_val
                },
                "store_id"
            );
            // }
        }

        const onChannelChange = (val) => {
            setChannel(val);
            resetRestFilters(14);
            props.fetchFilterData(
                {
                    level1: department && [department],
                    level2: gender,
                    level3: rbu,
                    level4: dcs,
                    level5: level5,
                    "article_status": articleStatus,
                    "store_type": [val]
                },
                "store_id"
            );
        }

        const onStoreChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that)
            setStoreId(valuesArray[1])
            setRequest(old => {
                return {
                    ...old,
                    "store_id": valuesArray[0]
                }
            })
            let req_val = intersectionWith(valuesArray[0], storeOptions, isEqual);
            resetRestFilters(5);
            // API call to get next filter
            // if (valuesArray[0].length) {
            props.fetchFilterData(
                {
                    level1: [department],
                    level2: gender,
                    level3: rbu,
                    level4: dcs,
                    level5: level5,
                    article_status: articleStatus,
                    "store_id": req_val
                },
                "store_grade"
            );
            // }
        }

        const onStoreGradeSourceChange = (val) => {
            setStoreGradeSource(val)
            resetRestFilters(10);
            // API call to get next filter
            // if (valuesArray[0].length) {
            props.fetchFilterData(
                {
                    level1: [department],
                    level2: gender,
                    level3: rbu,
                    level4: dcs,
                    level5: level5,
                    article_status: articleStatus,
                    "store_id": storeId,
                    "store_grade_source": val.value
                },
                "store_grade"
            );
        }

        const onStoreGradeChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that)
            setStoreGrade(valuesArray[1])
            setRequest(old => {
                return {
                    ...old,
                    "store_grade": valuesArray[0]
                }
            })
            let req_val = intersectionWith(valuesArray[0], storeGradeOptions, isEqual);
            resetRestFilters(6);
            // API call to get next filter
            // if (valuesArray[0].length) {
            props.fetchFilterData(
                {
                    level1: [department],
                    level2: gender,
                    level3: rbu,
                    level4: dcs,
                    level5: level5,
                    article_status: articleStatus,
                    "store_id": storeId,
                    "store_grade": req_val
                },
                "region"
            );
            // }
        }

        const onRegionChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that)
            setRegion(valuesArray[1])
            setRequest(old => {
                return {
                    ...old,
                    "region": valuesArray[0]
                }
            })
            let req_val = intersectionWith(valuesArray[0], regionOptions, isEqual);
            resetRestFilters(7);
            // API call to get next filter
            // if (valuesArray[0].length) {
            props.fetchFilterData(
                {
                    level1: [department],
                    level2: gender,
                    level3: rbu,
                    level4: dcs,
                    level5: level5,
                    article_status: articleStatus,
                    "store_id": storeId,
                    "store_grade": storeGrade,
                    region: req_val
                },
                "climate"
            );
            // }
        }

        const onClimateChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that)
            setClimate(valuesArray[1])
            setRequest(old => {
                return {
                    ...old,
                    "climate": valuesArray[0]
                }
            })
            let req_val = intersectionWith(valuesArray[0], climateOptions, isEqual);
            resetRestFilters(8);
            // API call to get next filter
            // if (valuesArray[0].length) {
            props.fetchFilterData(
                {
                    level1: [department],
                    level2: gender,
                    level3: rbu,
                    level4: dcs,
                    level5: level5,
                    article_status: articleStatus,
                    "store_id": storeId,
                    "store_grade": storeGrade,
                    region: region,
                    climate: req_val
                },
                "store_status"
            );
            // }
        }

        const onStoreStatusChange = (val, event, that) => {
            let valuesArray = handleSelectAll(val, event, that)
            setStoreStatus(valuesArray[1])
            setRequest(old => {
                return {
                    ...old,
                    "store_status": valuesArray[0]
                }
            })
        }

        const onClearanceChange = (val) => {
            setClearance(val)
        }

        const applyFilters = () => {
            if (department) {
                props.applyFilters({
                    "level1": [department],
                    "level2": gender,
                    "level3": rbu,
                    "level4": dcs,
                    "level5": level5,
                    "article_status": articleStatus,
                    "store_id": storeId,
                    [storeGradeSource?.value ? (storeGradeSource.value != "Department" ? storeGradeSource.value : "store_grade") : "store_grade"]: storeGrade,
                    "region": region,
                    "climate": climate,
                    "store_status": storeStatus,
                    "articleid": articleId,
                    store_type: channel && [channel]
                }, clearance?.value, storeGradeSource?.value ? (storeGradeSource.value != "Department" ? storeGradeSource.value : "store_grade") : "store_grade");
                setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department })
            } else {
                Notify.error("Please Select All Mandatory Options!!");
            }
        };

        const resetFilters = () => {
            setRequest(null)
            setDepartment(null);
            setGender(null);
            setRbu(null);
            setDCS(null);
            setArticleStatus(null);
            setArticleId(null);
            setStoreId(null)
            setStoreGrade(null)
            setRegion(null)
            setClimate(null)
            setStoreStatus(null)
            setStoreGradeSource(null)
            setChannel(null)
            setClearance(null)
            props.resetFiltersOptions([]);
        };

        useEffect(() => {
            getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange)
        }, [])


        return (
            <>
                <Notification />

                <section className="row section" style={{ flexDirection: "column" }}>
                    <div className="row" style={{ flexDirection: "column", marginTop: "0" }}>
                        <div>
                            <label className="fnt-md fnt-bold filter-heading">Product Hierarchy</label>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-md-3-dd required mb-3">
                                <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
                                <Select
                                    name="department"
                                    value={department}
                                    onChange={onDepartmentChange}
                                    placeHolder={`Select ${props.filterLabels?.level1}`}
                                    options={departmentOptions?.length ? departmentOptions : []}
                                />
                            </div>
                            <div className="col-md-3 fill col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold">{props.filterLabels?.level2}</label>
                                <MultiSelect
                                    name="gender"
                                    dropdownValue={gender}
                                    onDropDownChange={onGenderChange}
                                    placeHolder={`Select ${props.filterLabels?.level2}`}
                                    optionsArray={genderOptions?.length ? genderOptions : []}
                                />
                            </div>
                            {
                                !shouldHideForCurrentClient("l3_name") &&
                                <>
                                    <div className="col-md-3 fill col-md-3-dd mb-3">
                                        <label className="fnt-md fnt-bold">{props.filterLabels?.level3}</label>
                                        <MultiSelect
                                            name="rbu"
                                            dropdownValue={rbu}
                                            onDropDownChange={onRbuChange}
                                            placeHolder={`Select ${props.filterLabels?.level3}`}
                                            optionsArray={rbuOptions?.length ? rbuOptions : []}
                                        />
                                    </div>
                                    <div className="col-md-3 fill col-md-3-dd">
                                        <label className="fnt-md fnt-bold">{props.filterLabels?.level4}</label>
                                        <MultiSelect
                                            name="dcs"
                                            dropdownValue={dcs}
                                            onDropDownChange={onDCSChange}
                                            placeHolder={`Select ${props.filterLabels?.level4}`}
                                            optionsArray={dcsOptions?.length ? dcsOptions : []}
                                        />
                                    </div>
                                </>
                            }
                            {
                                !shouldHideForCurrentClient("l5_name") &&
                                <div className="col-md-3 fill col-md-3-dd">
                                    <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                                    <MultiSelect
                                        name="level5"
                                        dropdownValue={level5}
                                        onDropDownChange={onLevel5Change}
                                        placeHolder={`Select ${props.filterLabels?.level5}`}
                                        optionsArray={level5Options?.length ? level5Options : []}
                                    />
                                </div>
                            }
                            <div className="col-md-3 col-md-3-dd">
                                <label className="fnt-md fnt-bold">Article Status</label>
                                <MultiSelect
                                    name="Article Status"
                                    dropdownValue={articleStatus}
                                    onDropDownChange={onArticleStatusChange}
                                    placeHolder={"Select Article Status"}
                                    optionsArray={articleStatusOptions?.length ? articleStatusOptions : []}
                                />
                            </div>
                            <div className="col-md-3 col-md-3-dd">
                                <label className="fnt-md fnt-bold"> {updateArticleName(false)} ID</label>
                                <MultiSelect
                                    name="Article ID"
                                    dropdownValue={articleId}
                                    onDropDownChange={onArticleIdChange}
                                    placeHolder={`Select ${updateArticleName(false)} ID`}
                                    optionsArray={articleIdOptions?.length ? articleIdOptions : []}
                                />
                            </div>
                            <div className="col-md-3 col-md-3-dd">
                                <label className="fnt-md fnt-bold">Clearance Status</label>
                                <Select
                                    name="Clearance Status"
                                    onChange={onClearanceChange}
                                    value={clearance}
                                    placeHolder={"Select Clearance Status"}
                                    options={clearanceOptions ? clearanceOptions : []}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ flexDirection: "column", marginTop: "2rem" }}>
                        <div>
                            <label className="fnt-md fnt-bold filter-heading">Store Filter</label>
                        </div>
                        {/* <Notification /> */}
                        <div className="row">
                            <div className="col-md-3 col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold fnt-bold">Channel</label>
                                <Select
                                    name="channel"
                                    value={channel}
                                    onChange={onChannelChange}
                                    placeHolder={`Select Channel`}
                                    options={channelOptions?.length ? channelOptions : []}
                                />
                            </div>
                            <div className="col-md-3 col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold fnt-bold">Store ID</label>
                                <MultiSelect
                                    name="storeid"
                                    dropdownValue={storeId}
                                    onDropDownChange={onStoreChange}
                                    placeHolder={`Select Store`}
                                    optionsArray={storeOptions?.length ? storeOptions : []}
                                />
                            </div>
                            {
                                !shouldHideForCurrentClient("store_grade_source") &&
                                <div className="col-md-3 col-md-3-dd mb-3">
                                    <label className="fnt-md fnt-bold">Store Grade Source</label>
                                    <Select
                                        name="grade"
                                        value={storeGradeSource}
                                        onChange={onStoreGradeSourceChange}
                                        placeHolder={`Select Store Grade`}
                                        options={storeGradeSourceOptions[department?.value]}
                                    />
                                </div>
                            }
                            <div className="col-md-3 col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold">Store Grade</label>
                                <MultiSelect
                                    name="grade"
                                    dropdownValue={storeGrade}
                                    onDropDownChange={onStoreGradeChange}
                                    placeHolder={`Select Store Grade`}
                                    optionsArray={storeGradeOptions?.length ? storeGradeOptions : []}
                                />
                            </div>
                            {
                                !shouldHideForCurrentClient("region") &&
                                <div className="col-md-3 col-md-3-dd mb-3">
                                    <label className="fnt-md fnt-bold">Region</label>
                                    <MultiSelect
                                        name="region"
                                        dropdownValue={region}
                                        onDropDownChange={onRegionChange}
                                        placeHolder={`Select Region`}
                                        optionsArray={regionOptions?.length ? regionOptions : []}
                                    />
                                </div>
                            }
                            <div className="col-md-3 col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold">Climate</label>
                                <MultiSelect
                                    name="climate"
                                    dropdownValue={climate}
                                    onDropDownChange={onClimateChange}
                                    placeHolder={`Select Climate`}
                                    optionsArray={climateOptions?.length ? climateOptions : []}
                                />
                            </div>
                            <div className="col-md-3 col-md-3-dd">
                                <label className="fnt-md fnt-bold">Store Status</label>
                                <MultiSelect
                                    name="article"
                                    dropdownValue={storeStatus}
                                    onDropDownChange={onStoreStatusChange}
                                    placeHolder={"Select Store Status"}
                                    optionsArray={storeStatusOptions?.length ? storeStatusOptions : []}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "0" }}>
                        <button
                            onClick={applyFilters}
                            className="btn btn-primary filter-constraints-mode"
                            title="Apply filters"
                        >
                            <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                        </button>
                        <button
                            onClick={() => resetFilters("All")}
                            className="btn undo-constraints-mode"
                            title="Reset filters"
                        >
                            <i className="fa fa-undo mr-2"></i>Reset
                        </button>
                    </div>
                    <div className="required fnt-md pt-5" style={{ color: "red" }}>
                        <label></label>Fields are mandatory
                    </div>
                </section>
            </>
        );
    }
);

const mapStateToProps = ({ reporting }) => ({
    filterLabels: reporting.filterlabels,
    department: reporting.departmentOptions,
    gender: reporting.genderOptions,
    rbu: reporting.rbuOptions,
    dcs: reporting.dcsOptions,
    level5: reporting.level5Options,
    articleStatus: reporting.articleStatusOptions,
    store: reporting.storeOptions,
    storeGrade: reporting.storeGradeOptions,
    region: reporting.regionOptions,
    climate: reporting.climateOptions,
    storeStatus: reporting.storeStatusOptions,
    articleId: reporting.articleIdOptions,
    channel: reporting.channelOptions
});

const mapDispatchToProps = (dispatch) => ({
    resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
    fetchFilterData: (payload, filterKey, filterType) =>
        dispatch(fetchFilterData(payload, filterKey, filterType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportingFilters);
