import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { intersectionWith, isEqual } from 'lodash';
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import MultiSelect from "../../../components/Filters/MultiSelect";
import Select from "../../../components/Filters/SingleSelect";
import * as strategyConfigActions from '../actions/StrategyConfigActions';
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import {
    STRATEGY_CONFIG_FILTER_DEPARTMENT, STRATEGY_CONFIG_FILTER_GENDER, STRATEGY_CONFIG_FILTER_SUBCATEGORY, STRATEGY_CONFIG_FILTER_DCS,
    STRATEGY_CONFIG_FILTER_STYLE, STRATEGY_CONFIG_FILTER_COLOR, STRATEGY_CONFIG_FILTER_SEASON, 
} from '../StrategyConfigConstants';
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping";
import { getUserPreferenceFilter, setUserPreferenceFilter } from "../../../utils/commonUtilities";
import AsyncSingle from '../../../components/Filters/AsyncSingle';

/**
 * View Component with Ada Stand Alone Filters
 */
const StrategyConfigFilters = React.memo((props) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [subCat, setSubCat] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [style, setStyle] = useState(null);
    const [color, setColor] = useState(null);
    const [size, setSize] = useState(null);
    const [request, setRequest] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [subCatOptions, setSubCatOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [styleOptions, setStyleOptions] = useState(null);
    const [colorOptions, setColorOptions] = useState(null);
    const [sizeOptions, setsizeOptions] = useState(null);

    const { strategyConfigActionsProp, handleFilterRequest, activeTab, handleResetLostSales ,filterData } = props;

    useEffect(() => {
        if (activeTab === "strategyConfig") {
            getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
            strategyConfigActionsProp.getStrategyConfigFiltersData({}, "department");
        } else {
            resetFilters();
            getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
        }
    }, [activeTab]);

    useEffect(() => {
        strategyConfigActionsProp.getStrategyConfigFiltersData({}, "department");
    }, []);

    useEffect(() => {
        setDepartmentOptions(() => props.departmentOptions);
        setGenderOptions(() => props.genderOptions);
        setSubCatOptions(() => props.subCatOptions);
        setDCSOptions(() => props.dcsOptions);
        setStyleOptions(() => props.styleOptions);
        setColorOptions(() => props.colorOptions);
        setsizeOptions(() => props.sizeOptions);
    }, [props.departmentOptions, props.genderOptions, props.subCatOptions, props.dcsOptions,
    props.styleOptions, props.colorOptions, props.sizeOptions]);

    const resetRestFilters = (p_index) => {
        if (p_index === 0) {
            gender && setGender(null);
            genderOptions && setGenderOptions(null);
        }
        if (p_index === 0 || p_index === 1) {
            subCat && setSubCat(null);
            subCatOptions && setSubCatOptions(null);
        }
        if (p_index === 0 || p_index === 1 || p_index === 2) {
            dcs && setDCS(null);
            dcsOptions && setDCSOptions(null);
        }

        if (p_index === 0 || p_index === 1 || p_index === 2 || p_index === 3) {
            style && setStyle(null);
            styleOptions && setStyleOptions(null);
        }

        if (p_index === 0 || p_index === 1 || p_index === 2 || p_index === 3 || p_index === 4) {
            color && setColor(null);
            colorOptions && setColorOptions(null);
        }

        if (p_index === 0 || p_index === 1 || p_index === 2 || p_index === 3 || p_index === 4 || p_index === 5) {
            size && size(null);
            sizeOptions && setsizeOptions(null);
        }
    };

    const onDepartmentChange = (val) => {
        setDepartment(val);
        strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [val] }, "gender");
        resetRestFilters(0);
    };

    const onGenderChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
            setGender(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'l2_name': valuesArray[0],
                }
            });

            strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [department], l2_name: req_val }, "sub_category");
        }
        else {
            setGender(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [department], l2_name: [val] }, "sub_category");
        }
        resetRestFilters(1);
    };

    const onSubCatChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], subCatOptions, isEqual);
            setSubCat(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'l3_name': valuesArray[0],
                }
            });
            strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [department], l2_name: gender, l3_name: req_val }, "dcs");
        }
        else {
            setSubCat(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData({ l1_name: [department], l2_name: gender?.value && [gender], l3_name:[val] }, "dcs");
        }
        resetRestFilters(2);
    };

    const onDCSChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
            setDCS(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'l4_name': valuesArray[0],
                }
            });

            strategyConfigActionsProp.getStrategyConfigFiltersData({
                l1_name: [department], l2_name: gender, l3_name: subCat,
                l4_name: req_val,
            }, "style_name");
        }
        else {
            setDCS(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData(
                { l1_name: [department], l2_name: gender?.value && [gender], l3_name:  subCat?.value && [subCat], l4_name: [val] },
                "style_name"
            );
        }
        resetRestFilters(3);
    };

    const onStyleChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], styleOptions, isEqual);
            setStyle(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'style': valuesArray[0],
                }
            });
            strategyConfigActionsProp.getStrategyConfigFiltersData({
                l1_name: [department], l2_name: gender, l3_name: subCat,
                l4_name: dcs, style: req_val,
            }, "color");
        }
        else {
            setStyle(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData(
                { l1_name: [department], l2_name: gender?.value && [gender], l3_name: subCat?.value && [subCat], l4_name: dcs?.value && [dcs], style: [val] },
                "color"
            );
        }
        resetRestFilters(4);
    };

    const onColorChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], colorOptions, isEqual);
            setColor(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'color': valuesArray[0],
                }
            });

            strategyConfigActionsProp.getStrategyConfigFiltersData({
                l1_name: [department], l2_name: gender, l3_name: subCat,
                l4_name: dcs, style: style, color: req_val,
            }, "size");
        }
        else {
            setColor(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData(
                {
                    l1_name: [department], l2_name: gender?.value && [gender], l3_name: subCat?.value && [subCat], l4_name: dcs?.value && [dcs], 
                    style: style?.value && [style], color: [val],
                },
                "season"
            );
        }
        resetRestFilters(5);
    };

    const onSizeChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], sizeOptions, isEqual);
            setSize(valuesArray[1]);
            strategyConfigActionsProp.getStrategyConfigFiltersData({
                l1_name: [department], l2_name: gender, l3_name: subCat,
                l4_name: dcs, style: style, color: color, size: req_val,
            });
            setRequest(old => {
                return {
                    ...old,
                    'size': valuesArray[0],
                }
            });
        }
        else {
            setSize(val);
            strategyConfigActionsProp.getStrategyConfigFiltersData(
                {
                    l1_name: [department], l2_name: gender?.value && [gender], l3_name: subCat?.value && [subCat], l4_name: dcs?.value && [dcs], 
                    style: style?.value && [style],
                    color: color?.value && [color], season: [val],
                },
            );
        }
        resetRestFilters(7);
    };

    const applyFilters = () => {
        if (department) {
            handleFilterRequest({
                "l1_name": [department.value],
                "l2_name":  gender?.value && [gender.value],
                "l3_name": subCat?.value && [subCat.value],
                "l4_name": dcs?.value && [dcs.value],
                "style_name": style?.value && [style.value],
                "color": color?.value && [color.value],
                "season": size?.value && [size.value]
            });
            filterData({
                "l1_name": [department.value],
                "l2_name":  gender?.value && [gender.value],
                "l3_name": subCat?.value && [subCat.value],
                "l4_name": dcs?.value && [dcs.value],
                "style_name": style?.value && [style.value],
                "color": color?.value && [color.value],
                "season": size?.value && [size.value]
            });
            setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department });
        } else {
            Notify.error("Please Select All Mandatory Options!!");
        }
    };

    const resetFilters = () => {
        setRequest(null);
        setDepartment(null);
        setGender(null);
        setSubCat(null);
        setDCS(null);
        setStyle(null);
        setColor(null);
        setSize(null);

        // handleResetLostSales();
        strategyConfigActionsProp.getStrategyConfigFiltersData({}, "department");
        strategyConfigActionsProp.resetStrategyConfigScreen();
    };

    useEffect(() => {
        getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
    }, []);

    const getDDLabel = (label) => label ? label : "";

    return (
        <>
            <Notification />
            <section className="row section" style={{ flexDirection: "column" }}>
                <div className="row" style={{ flexDirection: "column", marginTop: "0" }}>
                    <div className="row">
                        <div className="col-md-3 col-md-3-dd required">
                            <label className="fnt-md fnt-bold fnt-bold">{levelFiltersLabelFormatter("level1")}</label>
                            <Select
                                name="department"
                                value={department}
                                onChange={onDepartmentChange}
                                placeHolder={`Select ${levelFiltersLabelFormatter("level1")}`}
                                options={departmentOptions?.length ? departmentOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{levelFiltersLabelFormatter("level2")}</label>
                            <Select
                                name="gender"
                                value={gender}
                                onChange={onGenderChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level2)}`}
                                options={genderOptions?.length ? genderOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{levelFiltersLabelFormatter("level3")}</label>
                            <Select
                                name="subCat"
                                value={subCat}
                                onChange={onSubCatChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level3)}`}
                                options={subCatOptions?.length ? subCatOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{levelFiltersLabelFormatter("level4")}</label>
                            <Select
                                name="dcs"
                                value={dcs}
                                onChange={onDCSChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level4)}`}
                                options={dcsOptions?.length ? dcsOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{STRATEGY_CONFIG_FILTER_STYLE}</label>
                            <AsyncSingle
                                name="Style Name"
                                dropdownValue={style}
                                onDropDownChange={onStyleChange}
                                placeHolder={'Select Style'}
                                optionsArray={styleOptions?.length ? styleOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd mt-3">
                            <label className="fnt-md fnt-bold">{STRATEGY_CONFIG_FILTER_COLOR}</label>
                            <Select
                                name="color"
                                value={color}
                                onChange={onColorChange}
                                placeHolder={'Select Color'}
                                options={colorOptions?.length ? colorOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd mt-3">
                            <label className="fnt-md fnt-bold">{STRATEGY_CONFIG_FILTER_SEASON}</label>
                            <Select
                                name="season"
                                value={size}
                                onChange={onSizeChange}
                                placeHolder={"Select season"}
                                options={sizeOptions?.length ? sizeOptions : []}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <button
                                onClick={applyFilters}
                                className="btn btn-primary filter-constraints-mode"
                                title="Apply filters"
                            >
                                <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                            </button>
                            <button
                                onClick={() => resetFilters()}
                                className="btn undo-constraints-mode"
                                title="Reset filters"
                            >
                                <i className="fa fa-undo mr-2"></i>Reset
                            </button>
                        </div>
                        <div className="col-md-3 required fnt-md pt-5" style={{ color: "red" }}>
                            <label></label>Fields are mandatory
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
);

const mapStateToProps = ({ strategyConfiguration }) => ({
    departmentOptions: strategyConfiguration.level1Options,
    genderOptions: strategyConfiguration.level2Options,
    subCatOptions: strategyConfiguration.level3Options,
    dcsOptions: strategyConfiguration.level4Options,
    filterLabels: strategyConfiguration.filterLabels,
    styleOptions: strategyConfiguration.styleOptions,
    colorOptions: strategyConfiguration.colorOptions,
    sizeOptions: strategyConfiguration.seasonOptions,
});

const mapDispatchToProps = (dispatch) => ({
    strategyConfigActionsProp: bindActionCreators(strategyConfigActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StrategyConfigFilters);