import { isEmpty, pickBy } from "lodash";
import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import { colors } from "../../constants/colorConstants";
import {
  GET_CHART_DATA,
  GET_STYLE_INV_POPUP_DATA,
  GET_REVIEW_RECOMMEND_DATA,
  getChartDataSuccess,
  getChartDataError,
  FETCH_FILTER_DATA,
  fetchFilterDataError,
  fetchFilterDataSuccesss,
  getStyleInvPopupDataSuccess,
  getStyleInvPopupDataError,

  getReviewRecommendDataSuccess,
  getReviewRecommendDataError,
  getReviewRecommendDataOnProgress,
  GET_TRANSACTION_DATA,
  getTransactionDetailsError,
  getTransactionDetailsSuccess,
  GET_RECOMMENDED_ACTIONS_DATA,
  getRecommendedActionstDataSuccess,
  getRecommendedActionstDataError,
} from "./DashboardActions";

import {
  getDashboardFilterData,
  getChartsData,
  getStyleInvPopupData,
  getReviewRecommendData,
  getTransactionDetails,
  getRecommendeActionsData
} from "../../routes/api";
import { poAllocationSaga } from "./POAllocation/POAllocationSaga";

function* getChartDataWorker(action) {
  try {
    const { payload } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        req[key] = payload[key].map(function mapper(ele) {
          if (Array.isArray(ele)) {
            return ele.map(mapper);
          } else {
            return ele.value;
          }
        });
      }
      for (let i in req) {
        req[i] = req[i].flat(Infinity);
      }
    }
    else {
      // req = {level1: ["Accessories"]}
    }

    const res = yield call(getChartsData, req);

    if (res.data.status) {
      const weekSupply = res.data.wosData;
      const storeStock = res.data.storeStockData;
      const overallStock = res.data.overallStockData;

      const getPieChartColors = (p_labels) => {
        let l_colors = [];
        p_labels.forEach((element) => {
          l_colors.push(colors[element]);
        });
        return l_colors;
      };

      weekSupply.datasets.forEach((element) => {
        element.backgroundColor = colors[element.label];
      });

      storeStock.datasets.forEach((element) => {
        element.backgroundColor = colors[element.label];
        element.type = "horizontalBar";
      });

      overallStock.forEach((element) => {
        element.datasets[0].backgroundColor = getPieChartColors(element.labels);
      });

      let pieLegends = overallStock[0]?.labels?.map((val) => {
        return {
          text: val,
          backgroundColor: colors[val],
        };
      });

      yield put(
        getChartDataSuccess({
          weekSupply,
          overallStock,
          storeStock,
          pieLegends,
          styleInventoryData: res.data.styleInventoryData,
        })
      );
    } else {
      yield put(getChartDataError({ error: "Something went wrong!" }));
    }
  } catch (error) {
    yield put(getChartDataError({ error: "Something went wrong!" }));
  }
}

function* getRecommendedActionsWorker(action) {
  try {
    const { payload } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        req[key] = payload[key].map(function mapper(ele) {
          if (Array.isArray(ele)) {
            return ele.map(mapper);
          } else {
            return ele.value;
          }
        });
      }
      for (let i in req) {
        req[i] = req[i].flat(Infinity);
      }
    }
    else {
      // req = {level1: ["Accessories"]}
    }

    const res = yield call(getRecommendeActionsData, req);

    if (res.data.status) {
      yield put(
        getRecommendedActionstDataSuccess({
          recommendedData: res.data.recommendedData,
        })
      );
    } else {
      yield put(getRecommendedActionstDataError({ error: "Something went wrong!" }));
    }
  } catch (error) {
    yield put(getRecommendedActionstDataError({ error: "Something went wrong!" }));
  }
}

function* fetchFilterDataWorker(action) {
  try {
    const { payload, filterType } = action;
    let req = {};
    let l_req = {};

    if (payload['filter_type'] && filterType["key"] === 'dc') {
      l_req = payload;
    } else if (!isEmpty(payload)) {
      for (const key in payload) {
        if (!isEmpty(payload?.[key]?.[0])) {
          req[key] = payload[key].map(function mapper(ele) {
            if (Array.isArray(ele)) {
              return ele.map(mapper);
            } else {
              return ele.value;
            }
          })
        };
      }
      for (let i in req) {
        req[i] = req[i].flat(Infinity);
      }
      l_req = pickBy(req, (value) => value.length > 0);
    }

    const res = yield call(getDashboardFilterData, l_req);

    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data.filterMapping;
      if (filterType["key"] === 'dc') {
        data["data"] = res.data.data.map((element) => ({
          value: element['store_code'],
          label: element['store_name'],
        }));;
      } else {
        // let topObject = Object.keys(res.data.data[0])[0];
        for (const key in res.data.data[0]) {
          let k = "";
          if (key === "level1") {
            k = "level1Options";
          } else if (key === "level2") {
            k = "level2Options";
          } else if (key === "level3") {
            k = "level3Options";
          } else if (key === "level4") {
            k = "level4Options";
          } else if (key === "level5") {
            k = "level5Options";
          } else if (key === "style") {
            k = "styleOptions";
          } else if (key === "color") {
            k = "colourOptions";
          }

          if (key === "style" || key === "color") {
            data[k] = res.data.data.map((element) => ({
              value: element[key],
              label: element[key],
            }));
          } else {
            data[k] = res.data.data[0][key]
              .filter((element) => element)
              .map((element) => ({
                value: element,
                label: element,
              }));
          }
        }
      }
      yield put(fetchFilterDataSuccesss({ data: data, key: action?.filterType?.key }));
    } else {
      yield put(fetchFilterDataError({ error: res.error }));
    }
  } catch (error) {
    yield put(fetchFilterDataError({ error: "Something went wrong!" }));
  }
}

function* getStyleInvPopupDataWorker(action) {
  try {
    const res = yield call(getStyleInvPopupData, action.payload);
    if (res.data.status) {
      yield put(
        getStyleInvPopupDataSuccess({ data: res.data.styleInventoryPopUpData })
      );
    } else {
      yield put(getStyleInvPopupDataError({ error: "Something went wrong!" }));
    }
  } catch (error) {
    yield put(getStyleInvPopupDataError({ error: "Something went wrong!" }));
  }
}


function* getReviewRecommendDataWorker(action) {
  try {
    yield put(getReviewRecommendDataOnProgress({}))
    const res = yield call(getReviewRecommendData, action.payload);
    if (res.data.status) {
      yield put(
        getReviewRecommendDataSuccess({ data: res.data.recommendData })
      )
    } else {
      yield put(getReviewRecommendDataError({ error: "Something went wrong" }));
    }

  } catch (error) {
    yield put(getReviewRecommendDataError({ error: "Something went wrong" }))
  }
}

function* getTransactionDetailsWorker(){
  try {
    const res = yield call(getTransactionDetails);
    if (res.data.status) {
      yield put(getTransactionDetailsSuccess({ data: res.data.data }))
    } else {
      yield put(getTransactionDetailsError({ error: "Something went wrong" }));
    }

  } catch (error) {
    yield put(getTransactionDetailsError({ error: "Something went wrong" }))
  }
}

function* getChartDataWatcher() {
  yield takeLatest(GET_CHART_DATA, getChartDataWorker);
}

function* getRecommendedActionsWatcher() {
  yield takeLatest(GET_RECOMMENDED_ACTIONS_DATA, getRecommendedActionsWorker);
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* getStyleInvPopupDataWatcher() {
  yield takeLatest(GET_STYLE_INV_POPUP_DATA, getStyleInvPopupDataWorker);
}

function* getReviewRecommendDataWatcher() {
  yield takeLatest(GET_REVIEW_RECOMMEND_DATA, getReviewRecommendDataWorker)
}

function* getTransactionDetailsWathcer() {
  yield takeLatest(GET_TRANSACTION_DATA,getTransactionDetailsWorker)
}



export function* dashboardSaga() {
  yield all([
    getChartDataWatcher(),
    getRecommendedActionsWatcher(),
    getStyleInvPopupDataWatcher(),
    fetchFilterDataWatcher(),
    getReviewRecommendDataWatcher(),
    getTransactionDetailsWathcer(),
    poAllocationSaga()
  ]);
}
