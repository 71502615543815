export const PO_ALLOCATION_TABLE_HEADERS = {
    ASN_ID: "ASN ID",
    PO_ID: "PO ID",
    DC: "DC ID",
    INVENTORY: "Inventory", 
    STYLE_COUNT: "#Style colors",
    STYLE: "Style Color ID",
    DESC: "Description",
    EXPECTED_DATE: "Expected Date",
    CREATION_DATE: "Creation Date",
    RECEIPT_DATE: "Receipt Date",
    TOTAL_QTY: "Total Qty",
    EACHES: "# Eaches",
    PACKS: "# Packs",
    TOTAL: "Total",
    QTY_VARIANCE: "Qty Variance",
    RECEIPT_QTY: "Receipt Qty",
    ALLOCATION_STATUS: "Allocation Status",
    VENDOR_ID: "Vendor ID",
    VENDOR_NAME: "Vendor Name",
    PACK_NAME: "Pack ID"
}