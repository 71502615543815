import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { DASHBOARD_PAGE } from "../../constants/routeConstants";
import jwt_decode from 'jwt-decode'
import { useHistory } from 'react-router';

const cookies = new Cookies();

const IntermediatePage = () => {
  const history = useHistory()

  useEffect(() => {
    let accessToken = cookies.get("access_token");
    if (accessToken) {
        localStorage.setItem('access_token', accessToken);
        var decoded = jwt_decode(accessToken)
        localStorage.setItem('group_id', decoded.group_id)
        localStorage.setItem('userName', decoded.name)
        localStorage.setItem('email', decoded.email)
        localStorage.setItem('user_id', decoded.user_id)
        localStorage.setItem('retail_pro_id', decoded.retail_pro_id)
        history.push(`${DASHBOARD_PAGE}`);
      }
  }, [])

    return (
      <div>
        <div className="mainDIv35 text-center">
          <div className="intermideateClass">
            {/* <img className="imgLoad" src={Loadinggif} alt="" /> */}
            <i
              className="fa fa-spinner fa-spin"
              aria-hidden="true"
              style={{ fontSize: "25px" }}
            ></i>
          </div>
          <p className="font-size-15 mt-2 font-bold">Loading please wait...</p>
        </div>
      </div>
    );
  }

export default IntermediatePage

// const mapStateToProps = (store) => ({
//   loading: store.loginReducer.loading,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(IntermediatePage));
