import React, { useState } from 'react'
import { connect } from "react-redux";
import '../ProductStoreStatus.css'
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import PageLoader from '../../../components/Loader/PageLoader';
import ColumnFilter from "../../UserManagement/ColumnFilter";
import StoreGroupMappingModal from "./Popups/StoreGroupMappingModal"
import AddStoreGroup from "./Popups/AddStoreGroupModal"
import { getDcData, createConfig, resetAll, resetCreateConfigData } from "./Actions/CreateConfigAction"
import * as Notify from '../../../components/Notification/Notifications'
import Notification from '../../../components/Notification/Notifications'
import Modal from "react-awesome-modal";
import { useEffect } from 'react';
import { levelFiltersLabelFormatter, updateArticleName } from '../../../utils/filterLevelMapping';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ArticleConfigTable = (props) => {
    const [showStoreGroupModal, setShowStoreGroupModal] = useState(false)
    const [showProductProfile, setshowProductProfile] = useState(false)
    const [showDCModal, setShowDCModal] = useState(false)
    const [dcInstacne, setDCRTInstance] = useState({})
    const [dcSelectedRows, setDcSelectedRows] = useState({})
    const [selectedArticle, setSelectedArticle] = useState({})
    const [dcTableData, setDCTableData] = useState([])
    const [request, setRequest] = useState({})

    const articleConfigColumns = [
        {
            Header:  `${updateArticleName(false)} ID`,
            accessor: "article",
            Filter: ColumnFilter,
            sticky: "left"
        },
        // {
        //     Header: "Description",
        //     accessor: "desc",
        //     Filter: ColumnFilter
        // },
        {
            Header: "Style",
            accessor: "style",
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: "Style Color",
            accessor: "color_desc",
            Filter: ColumnFilter,
        },
        {
            Header: "Global Color",
            accessor: "color",
            Filter: ColumnFilter
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: "l2_name",
            Filter: ColumnFilter
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            Filter: ColumnFilter
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: "l4_name",
            Filter: ColumnFilter
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: "l5_name",
            Filter: ColumnFilter
        },
        {
            Header: "Product Type",
            accessor: "product_type",
            Filter: ColumnFilter
        },
        {
            Header: "Store Groups/ Stores Mapped",
            accessor: "store_group_count",
            Cell: (instance) => (
                <div>
                    <div className="clickable" onClick={() => { openStoreGroupModal(instance) }}>
                        {instance.value} / {instance.row?.original.store_count}
                    </div>
                </div>
            ),
            Filter: ColumnFilter
        },
        {
            Header: "Product Profile Mapped",
            accessor: "product_profiles",
            Cell: (instance) => (
                <div>
                    <div className="clickable" onClick={() => { openProductProfileModal(instance) }}>
                        {instance.value}
                    </div>
                </div>
            ),
            Filter: ColumnFilter,
            width: 300
        },
        {
            Header: "DC Mapped",
            accessor: "dcs_count",
            Cell: (instance) => (
                <div>
                    <div className="clickable" onClick={() => { openDcModal(instance) }}>{instance.value}</div>
                </div>
            ),
            Filter: ColumnFilter
        },
    ]

    const dcColumns = [
        // {
        //     Header: "DC ID",
        //     accessor: "dcId",
        //     Filter: ColumnFilter
        // },
        {
            Header: "DC Name",
            accessor: "store_name",
            Filter: ColumnFilter
        },
        // {
        //     Header: "City",
        //     accessor: "city",
        //     Filter: ColumnFilter
        // },
        {
            Header: "State",
            accessor: "state",
            Filter: ColumnFilter
        },
        {
            Header: "Country",
            accessor: "country",
            Filter: ColumnFilter
        }
    ]

    const closeStoreGroupModal = (status, isEdited) => {
        setShowStoreGroupModal(false)
        if (isEdited) {
            callApis()
        }
    }

    const openStoreGroupModal = (instance) => {
        setSelectedArticle(instance.row?.original)
        setShowStoreGroupModal(true)
        props.setIsPopupOpened(true)
    }

    const openProductProfileModal = (instance) => {
        setSelectedArticle(instance.row?.original)
        setshowProductProfile(true)
        props.setIsPopupOpened(true)
    }

    const closeProductProfileModal = (status, isEdited) => {
        setshowProductProfile(false)
        // props.setIsPopupOpened(false)
        if (isEdited) {
            callApis()
        }
    }

    const openDcModal = (instance) => {
        let req = { configuration_id: props.configuration_id }
        if (instance.row?.original?.article) {
            req.article = instance.row?.original?.article
        }

        setRequest(req)
        props.getDcData(req)
        setShowDCModal(true)
        props.setIsPopupOpened(true)
    }

    const closeDcModal = (status, isEdited) => {
        setShowDCModal(false)
        // props.setIsPopupOpened(false)
        if (isEdited) {
            callApis()
        }
    }

    const callApis = () => {
        props.getProductStatusData()
        props.getArticleConfigData()
    }

    useEffect(() => {

        if ((Object.keys(props.dcData).length) && props.dcData?.selected_dcs) {
            let data = []
            let remaining = []
            let selected = props.dcData?.selected_dcs?.length ? props.dcData?.selected_dcs : []
            props.dcData.dcs.map(dc => {
                if (selected.includes(dc.store_code.toString())) {
                    data.push(dc)
                }
                else {
                    remaining.push(dc)
                }
            });
            let initialSelectedRows = {}
            for (let i = 0; i < data.length; i++) {
                initialSelectedRows[i] = true
            }
            setDcSelectedRows(initialSelectedRows)
            remaining = data.concat(remaining)
            setDCTableData(remaining)
        }
    }, [props.dcData])

    const dcMapping = () => {
        if (dcInstacne?.selectedFlatRows?.length) {
            let filters = {}
            let req = {
                ...request, filters
            }
            req.dc = dcInstacne.selectedFlatRows
            props.createConfig(req)
        }
        else {
            Notify.error("Select Atleaset one row!!")
        }
    }

    useEffect(() => {
        if (props.createConfigData?.configuration_id && showDCModal) {
            props.resetCreateConfigData()
            Notify.success(`DC Mapped Successfully!!`)
            setTimeout(() => {
                closeDcModal(true, true)
            }, 2000)
        }
        else if (props.createConfigDataError) {
            props.resetCreateConfigData()
            Notify.error("Something Went Wrong!!")
        }
    }, [props.createConfigData, props.createConfigDataError])

    useEffect(() => {
        return () => {
            props.resetAll()
        }
    }, [])

    return (
        <>
            <PageLoader loader={props.dataLoading} gridLoader={true}>
                <h1 className="fnt-md fnt-bold mb-4">{props.config_name}</h1>
                <ReactTableWithPlugins
                    shouldPagination
                    totalRecordsLen={props.data?.length}
                    data={props.data ? props.data : []}
                    columns={articleConfigColumns}
                    renderMarkup='TableMarkup'
                    keyRT="sortAndSearch"
                    tableId="product_config_article1"
                />
            </PageLoader>
            {
                showStoreGroupModal &&
                <StoreGroupMappingModal
                    filters={props.selectedConfigFilters}
                    callApis={callApis}
                    showModal={showStoreGroupModal} closeModal={closeStoreGroupModal}
                    selectedArticle={selectedArticle} configuration_id={props.configuration_id} />
            }
            {
                showProductProfile &&
                <AddStoreGroup
                    request={{ ...selectedArticle, configuration_id: props.configuration_id }}
                    filters={props.selectedConfigFilters}
                    selectedArticle={selectedArticle} configuration_id={props.configuration_id}
                    isProductProfileCall={true}
                    showModal={showProductProfile}
                    closeModal={closeProductProfileModal}
                />
            }
            {
                showDCModal &&
                <div className="modal__div">
                    <Modal
                        visible={showDCModal}
                        width="85%"
                        // height="95%"
                        effect="fadeInDown"
                        onClickAway={closeDcModal}
                    >
                        <Notification />
                        <div className="modal-container">
                            <div className="modal-header">
                                <h5 className="page-heading text-center m-0">
                                    Select DC Sources
                                </h5>
                                <button type="button" className="close" data-dismiss="modal">
                                    <span style={{ cursor: "pointer" }}>
                                        <i
                                            className="fa fa-times text-right m-2 "
                                            aria-hidden="true"
                                            onClick={closeDcModal}
                                        />
                                    </span>
                                </button>
                            </div>
                            <PageLoader loader={props.dcDataLoading || props.createConfigDataLoading} gridLoader={true}>
                                <div className="modal-middle-container modal-body">
                                    <div className="mx-2 p-2">
                                        <ReactTableWithPlugins
                                            shouldPagination
                                            totalRecordsLen={dcTableData.length}
                                            data={dcTableData ? dcTableData : []}
                                            columns={dcColumns}
                                            initialSelectedRows={dcSelectedRows}
                                            features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                                            renderMarkup='TableMarkup'
                                            keyRT="StrategyPageTable"
                                            getInstanceOnMount={(instance) => {
                                                setDCRTInstance(instance)
                                            }}
                                            tableId="product_config_article2"
                                        />
                                    </div>
                                </div>
                            </PageLoader>

                            <div className="text-center" style={{ padding: "1rem" }}>
                                <div className="center">
                                    <button
                                        className="btn btn-primary store-grp-modal__btn"
                                        onClick={closeDcModal}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="btn btn-primary store-grp-modal__btn fn__btn"
                                        onClick={dcMapping}
                                    >
                                        Edit DC Mapping
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            }
        </>
    )
}

const mapStateToProps = ({ createConfig }) => ({
    dcData: createConfig.dcData,
    dcDataLoading: createConfig.dcDataLoading,
    dcDataError: createConfig.dcDataError,
    createConfigData: createConfig.createConfigData,
    createConfigDataLoading: createConfig.createConfigDataLoading,
    createConfigDataError: createConfig.createConfigDataError
})

const mapDispatchToProps = (dispatch) => ({
    getDcData: (payload) => dispatch(getDcData(payload)),
    createConfig: (payload) => dispatch(createConfig(payload)),
    resetCreateConfigData: () => dispatch(resetCreateConfigData()),
    resetAll: () => dispatch(resetAll())
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticleConfigTable)
