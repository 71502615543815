// import React from "react";
// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
// import "./Filters.css";

// const MultiSelect = (props) => {
//   console.log("asfjasufn;as", props);
//   let dropdownValue = props.dropdownValue || {
//     value: props.placeHolder,
//     label: props.placeHolder,
//     options: [],
//   };
//   let dropdownDisabled =
//     !props.optionsArray || !props.optionsArray.length || props.isDisabled
//       ? true
//       : false;
//   console.log("asfdas", dropdownDisabled);
//   return (
//     <>
//       {
//         <ReactMultiSelectCheckboxes
//           placeholderButtonLabel={props.placeHolder}
//           placeHolder = {props.placeHolder}
//           options={props.optionsArray}
//           isDisabled={dropdownDisabled}
//           onChange={props.onDropDownChange}
//           onBlur={props.onBlur}
//           onFocus={props.onFocus}
//           onKeyDown={props.onKeyDown}
//           value={dropdownValue}
//           className={props.className || ""}
//         />
//       }
//     </>
//   );
// };

// export default MultiSelect;

import { isEmpty } from "lodash";
import React from "react";
import { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "./Filters.css";

const MultiSelect = (props) => {
  let dropdownValue = props.dropdownValue || {
    value: props.placeHolder,
    label: props.placeHolder,
    options: [],
  };
  let dropdownDisabled =
    !props.optionsArray || !props.optionsArray.length || props.isDisabled
      ? true
      : false;

  const [optionsArray, setOptionArray] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if(!props.optionsArray || !props.optionsArray.length || props.isDisabled){
      setOptionArray(props.optionsArray)
    }
  },[props.isDisabled,props.optionsArray])

  useEffect(()=> {
    if(isEmpty(props.optionsArray))
      setOptionArray(props.optionsArray)
    if(!isEmpty(props.dropdownValue))
      setSelectedOptions(props.dropdownValue)
    else
      setSelectedOptions([])
  },[
    props.optionsArray, 
    props.dropdownValue])

  let onFocus = () => {
    if(props.dropdownValue?.length && props.optionsArray?.length){
      let optionsArray
      if(props.hideSelectAll){
        optionsArray = [...new Set([...props.dropdownValue,...props.optionsArray])]
      } 
      else{
        optionsArray = [...new Set([{ label: "Select All", value: "*" },...props.dropdownValue,...props.optionsArray])]
      }
      const uniqueObjects = [...new Map(optionsArray.map(item => [item.value, item])).values()]

      setOptionArray(uniqueObjects)
    }
    else{
      let optionsArray
      if(props.hideSelectAll){
        optionsArray = [...props.optionsArray]
      }
      else {
        optionsArray = [{ label: "Select All", value: "*" },...props.optionsArray]
      }
      setOptionArray(optionsArray)
    }
  }


  function onChange(value, event) {
    props.onDropDownChange(value,event, this)
  }

  return (
    <>
      {
        <ReactMultiSelectCheckboxes
          placeholderButtonLabel={props.placeHolder}
          placeHolder = {props.placeHolder}
          // options={optionsArray?.length ? optionsArray : props?.optionsArray}
          options = {optionsArray}
          isDisabled={dropdownDisabled}
          onChange={onChange}
          onBlur={props.onBlur}
          onFocus={onFocus}
          onKeyDown={props.onKeyDown}
          value={selectedOptions}
          className={props.className || ""}
          setState={setSelectedOptions}
        />
      }
    </>
  );
};

export default MultiSelect;


