import React from "react"
import ConstraintsFilter from "../../../components/Filters/ConstraintsFilter"
import { useHistory } from 'react-router-dom';
import { SET_CONSTRAINTS } from "../../../constants/routeConstants";
import ConstraintsConfigFilters from "./ConstraintsConfigFilters";

const ConstraintsConfig = (props) => {

    const history = useHistory();

    const goToSetConstraints = () => {
        history.push(SET_CONSTRAINTS);
    }

    return (
        <div className="product__store__status__container">
             <div className="container__header__with__button config-header">
                <h1 className="fnt-lg fnt-bold">Constraints</h1>
                <div className="row config-btn-container" onClick={goToSetConstraints}>
                    <button className="btn-primary-rounded create-config-btn mr-2">
                        <i
                            className="fa fa-plus"
                            title="Set Constraints"
                            aria-hidden="true"
                        ></i>
                    </button>
                    <p className="fnt-sm m-0">Set Constraints</p>
                </div>
            </div>
            <hr style={{margin:0}}/>
            <div>
                <ConstraintsConfigFilters/>
            </div>
        </div>
    )
}

export default ConstraintsConfig
