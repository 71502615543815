import * as actionTypes from "../Actions/ActionTypes"
import { productStatusInitialState } from "./InitialState"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export default {
    createConfig: handleActions(
        {
            [actionTypes.GET_STORE_GROUP_DATA]: (state) => {
                return immutable(state, {
                    storeGroupDataLoading: { $set: true },
                    storeGroupData: { $set: {} },
                    storeGroupDataError: { $set: null },
                })
            },
            [actionTypes.GET_STORE_GROUP_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    storeGroupDataLoading: { $set: false },
                    storeGroupData: { $set: (payload.data && payload.data) || {} },
                    storeGroupDataError: { $set: null },
                })
            },
            [actionTypes.GET_STORE_GROUP_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    storeGroupDataLoading: { $set: false },
                    storeGroupData: { $set: {} },
                    storeGroupDataError: { $set: (payload && payload.message) || null },
                })
            },

            [actionTypes.GET_PRODUCT_PROFILES]: (state) => {
                return immutable(state, {
                    productProfilesDataLoading: { $set: true },
                    productProfilesData: { $set: {} },
                    productProfilesDataError: { $set: null },
                })
            },
            [actionTypes.GET_PRODUCT_PROFILES_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    productProfilesDataLoading: { $set: false },
                    productProfilesData: { $set: (payload.data && payload.data) || {} },
                    productProfilesDataError: { $set: null },
                })
            },
            [actionTypes.GET_PRODUCT_PROFILES_ERROR]: (state, payload) => {
                return immutable(state, {
                    productProfilesDataLoading: { $set: false },
                    productProfilesData: { $set: {} },
                    productProfilesDataError: { $set: (payload && payload.message) || null },
                })
            },

            [actionTypes.GET_STORE_SIZE_CONTRIBUTIONS]: (state) => {
                return immutable(state, {
                    storeSizeContributionDataLoading: { $set: true },
                    storeSizeContributionData: { $set: {} },
                    storeSizeContributionDataError: { $set: null },
                })
            },
            [actionTypes.GET_STORE_SIZE_CONTRIBUTIONS_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    storeSizeContributionDataLoading: { $set: false },
                    storeSizeContributionData: { $set: (payload.data && payload.data) || {} },
                    storeSizeContributionDataError: { $set: null },
                })
            },
            [actionTypes.GET_STORE_SIZE_CONTRIBUTIONS_ERROR]: (state, payload) => {
                return immutable(state, {
                    storeSizeContributionDataLoading: { $set: false },
                    storeSizeContributionData: { $set: {} },
                    storeSizeContributionDataError: { $set: (payload && payload.message) || null },
                })
            },

            [actionTypes.GET_DC]: (state) => {
                return immutable(state, {
                    dcDataLoading: { $set: true },
                    dcData: { $set: {} },
                    dcDataError: { $set: null },
                })
            },
            [actionTypes.GET_DC_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    dcDataLoading: { $set: false },
                    dcData: { $set: (payload.data && payload.data) || {} },
                    dcDataError: { $set: null },
                })
            },
            [actionTypes.GET_DC_ERROR]: (state, payload) => {
                return immutable(state, {
                    dcDataLoading: { $set: false },
                    dcData: { $set: {} },
                    dcDataError: { $set: (payload && payload.message) || null },
                })
            },

            [actionTypes.CREATE_CONFIG]: (state, payload) => {
                return immutable(state, {
                    createConfigDataLoading: { $set: true },
                    createConfigData: { $set: {} },
                    createConfigDataError: { $set: null },
                    noArticleError: { $set: null }
                })
            },
            [actionTypes.CREATE_CONFIG_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    createConfigDataLoading: { $set: false },
                    createConfigData: { $set: (payload.data && payload.data) || {} },
                    createConfigDataError: { $set: null },
                    noArticleError: { $set: null }
                })
            },
            [actionTypes.CREATE_CONFIG_ERROR]: (state, payload) => {
                return immutable(state, {
                    createConfigDataLoading: { $set: false },
                    createConfigData: { $set: {} },
                    createConfigDataError: { $set: (payload && payload.error) || null },
                })
            },
            
            [actionTypes.NO_ARTICLE_ERROR]: (state, payload) => {
                return immutable(state, {
                    noArticleError: { $set: (payload && payload.error) || null }
                })
            },

            [actionTypes.SAVE_STORE_GROUP_DATA]: (state, payload) => {
                return immutable(state, {
                    selectedStoreData: { $set: payload.payload }
                })
            },

            [actionTypes.RESET_TABLE_DATA]: (state) => {
                return immutable(state, {
                    storeGroupData: { $set: {} },
                    storeGroupDataLoading: { $set: false },
                    storeGroupDataError: { $set: null },
                })
            },

            [actionTypes.RESET_CREATE_CONFIG_DATA]: (state) => {
                return immutable(state, {
                    createConfigDataLoading: { $set: false },
                    createConfigData: { $set: {} },
                    createConfigDataError: { $set: null },
                    deleteConfigDataLoading: { $set: false },
                    deleteConfigData: { $set: {} },
                    deleteConfigDataError: { $set: null },
                    noArticleError: { $set: null }
                })
            },

            [actionTypes.RESET_FILTERS_DATA]: (state) => {
                return immutable(state, {
                    storeGroupData: { $set: {} },
                    storeGroupDataLoading: { $set: false },
                    storeGroupDataError: { $set: null },
                })
            },

            [actionTypes.RESET_ALL]: (state) => {
                return immutable(state, {
                    storeGroupData: { $set: [] },
                    storeGroupDataLoading: { $set: false },
                    storeGroupDataError: { $set: null },
                    productProfilesDataLoading: { $set: false },
                    productProfilesData: { $set: [] },
                    productProfilesDataError: { $set: null },
                    storeSizeContributionDataLoading: { $set: false },
                    storeSizeContributionData: { $set: [] },
                    storeSizeContributionDataError: { $set: null },
                    dcDataLoading: { $set: false },
                    dcData: { $set: [] },
                    dcDataError: { $set: null },
                    createConfigDataLoading: { $set: false },
                    createConfigData: { $set: {} },
                    createConfigDataError: { $set: null },
                    deleteConfigDataLoading: { $set: false },
                    deleteConfigData: { $set: {} },
                    deleteConfigDataError: { $set: null },
                    noArticleError: { $set: null }
                })

            }
        },
        productStatusInitialState
    )
}