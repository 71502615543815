import { takeLatest, takeEvery, put, all, call, delay } from "redux-saga/effects";
import * as actionTypes from "./Actions/ActionTypes"
import {
    getProductConfigFilters, getProductConfiguration, getArticleMapping,
    getStoreGroupMapping, getPPMapping, getDCMapping, getStoresData,
    createProductConfig, deleteProductConfig
} from "../../../routes/api";
import moment from "moment";
import missingConfigData from "./missingConfigData.json"

function* fetchFilterDataWorker(action) {
    try {
        const { payload, filterKey } = action;
        let req = payload;
        // if (!isEmpty(payload)) {
        //     for (const key in payload) {
        //         req[key] = payload[key]?.map((ele) => ele.value)
        //     }
        // }

        if (filterKey === "style_name" || filterKey === "color" || filterKey === "product_type") {
            req["filter_type"] = filterKey;
        }

        const res = yield call(getProductConfigFilters, req);

        if (res?.data.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping
            // let topObject = Object.keys(res.data.data[0])[0]
            for (const key in res.data.data[0]) {
                let k = "";
                if (key === "level1") {
                    k = "departmentOptions";
                } else if (key === "level2") {
                    k = "genderOptions";
                } else if (key === "level3") {
                    k = "rbuOptions";
                } else if (key === "level4") {
                    k = "dcsOptions";
                } else if (key === "level5") {
                    k = "level5Options";
                } else if (key === "product_type") {
                    k = "productTypeOptions";
                } else if (key === "color") {
                    k = "colorOptions";
                } else if(key === "style_name") {
                    k = "styleOptions"
                }

                // else{
                // if (res?.data?.sentFilters?.filter_type == "style_name") {
                //     data["styleOptions"] = res.data?.data
                // }
                // else {
                if (key === "style_name" || key === "color" || key === "product_type") {
                    data[k] = res.data.data.map((element) => ({
                        value: element[key],
                        label: element[key],
                    }));
                }
                else {
                    data[k] = res.data.data[0][key]?.filter(val => val).map((element) => ({
                        value: element,
                        label: element,
                    }));
                }
                // }
                //   }


                // data[k] = res.data.data[0][key]?.filter(val => val)?.map((element) => ({
                //     value: element,
                //     label: element,
                // }));
            }
            // console.log("Filters data")
            // console.log(data)
            yield put({ type: actionTypes.FETCH_FILTER_SUCCESS, data: data, key: filterKey });
        }
    } catch (error) {
        yield put({ type: actionTypes.FETCH_FILTER_ERROR, error: "Something went wrong!" });
    }
}

function* getProductStatusDataWorker(action) {
    try {
        const { payload } = action;
        const res = yield call(getProductConfiguration, payload);
        if (res?.data.status) {
            let data = res.data?.data
            data.forEach(item => {
                item.created_on = moment(item.created_on).format("MM-DD-YYYY hh:mm:ss")
                item.last_modified = moment(item.last_modified).format("MM-DD-YYYY hh:mm:ss")
            });
            yield put({ type: actionTypes.GET_PRODUCT_STATUS_DATA_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.GET_PRODUCT_STATUS_DATA_ERROR, error: "Something went wrong!" });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_PRODUCT_STATUS_DATA_ERROR, error: "Something went wrong!" });
    }
}

function* getArticleConfigDataWorker(action) {
    try {
        const { payload } = action;
        const res = yield call(getArticleMapping, payload);
        if (res?.data.status) {
            let data = res.data?.data
            data.map(item => {
                if (!item.product_profiles) {
                    item.product_profiles = "-"
                }
                else {
                    item.product_profiles = item.product_profiles.join(", ")
                }

            })
            yield put({ type: actionTypes.GET_ARTICLE_CONFIG_DATA_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.GET_ARTICLE_CONFIG_DATA_ERROR, error: "Something went wrong!" });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_ARTICLE_CONFIG_DATA_ERROR, error: "Something went wrong!" });
    }
}

function* getStoreGroupDataWorker(action) {
    try {
        const { payload } = action;
        const res = yield call(getStoreGroupMapping, payload);
        if (res?.data.status) {
            let data = res.data

            yield put({ type: actionTypes.GET_STORE_GROUP_DATA_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.GET_STORE_GROUP_DATA_ERROR, error: "Something went wrong!" });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_STORE_GROUP_DATA_ERROR, error: "Something went wrong!" });
    }
}

function* getProductProfilesWorker(action) {
    try {
        const { payload } = action;
        const res = yield call(getPPMapping, payload);
        if (res?.data.status) {
            let data = res.data

            yield put({ type: actionTypes.GET_PRODUCT_PROFILES_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.GET_PRODUCT_PROFILES_ERROR, error: "Something went wrong!" });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_PRODUCT_PROFILES_ERROR, error: "Something went wrong!" });
    }
}

function* getDcDataWorker(action) {
    try {
        const { payload } = action;
        const res = yield call(getDCMapping, payload);
        if (res?.data.status) {
            let data = res.data

            yield put({ type: actionTypes.GET_DC_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.GET_DC_ERROR, error: "Something went wrong!" });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_DC_ERROR, error: "Something went wrong!" });
    }
}

function* getContributionWorker(action) {
    try {
        const { payload } = action;
        const res = yield call(getStoresData, { product_profile_code: payload });
        if (res?.data.status) {
            let data = res.data
            data.storeData.map(item => {
                item.overall = Number(item.overall) ? (Number(item.overall) * 100).toFixed(1) + " %" : "-"
            })
            data.articleData.map(item => {
                item.contribution = Number(item.contribution) ? Number(item.contribution).toFixed(1) : "-"
            })
            yield put({ type: actionTypes.GET_STORE_SIZE_CONTRIBUTIONS_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.GET_STORE_SIZE_CONTRIBUTIONS_ERROR, error: "Something went wrong!" });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_STORE_SIZE_CONTRIBUTIONS_ERROR, error: "Something went wrong!" });
    }
}

function* createProductConfigWorker(action) {
    try {
        const { payload } = action;
        // filters, dc, pp, storeGroups, name, configuration_id,
        //     article 
        let req = {
        }
        if (payload.storeGroups) {
            req.store_groups = []
            payload.storeGroups.map(sg => {
                req.store_groups.push(sg.original.store_group_code)
            })
        }
        if (payload.pp) {
            req.product_profiles = []
            payload.pp.map(product => {
                req.product_profiles.push(product.original.product_profile_code)
            })
        }
        if (payload.dc) {
            req.dcs = []
            payload.dc.map(item => {
                req.dcs.push(item.original.store_code)
            })
        }
        if (payload.name) {
            req.configuration_name = payload.name
        }
        if (payload.article) {
            req.article = payload.article
        }
        if (payload.configuration_id) {
            req.configuration_id = payload.configuration_id
        }
        if (Object.keys(payload).includes("user_consent")) {
            req.user_consent = payload.user_consent
        }
        Object.keys(payload.filters).map(key => {
            if (payload.filters[key]) {
                req[key] = payload.filters[key]
            }
        })

        req.auto_apply = payload.auto_apply
        req.created_by = payload.created_by

        const res = yield call(createProductConfig, req);
        if (res?.data.status) {
            let data = res.data?.data

            yield put({ type: actionTypes.CREATE_CONFIG_SUCCESS, data: data });
        }
        else {
            if (res?.data?.no_articles) {
                yield put({ type: actionTypes.NO_ARTICLE_ERROR, error: "No articles available for selected data, Still you want to create configuration?" });
            }
            else {
                yield put({ type: actionTypes.CREATE_CONFIG_ERROR, error: res.data?.message ? res.data?.message : "Something went wrong!" });
            }
        }
    }
    catch (error) {
        yield put({ type: actionTypes.CREATE_CONFIG_ERROR, error: "Something went wrong!" });
    }
}

function* deleteProductConfigWorker(action) {
    try {
        const { payload } = action;

        const res = yield call(deleteProductConfig, payload);
        if (res?.data.status) {
            let data = res.data

            yield put({ type: actionTypes.DELETE_CONFIG_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.DELETE_CONFIG_ERROR, error: "Something went wrong!" });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.DELETE_CONFIG_ERROR, error: "Something went wrong!" });
    }
}

function* getMissingConfigDataWorker() {

    yield put({ type: actionTypes.GET_MISSING_CONFIG_SUCCESS, data: missingConfigData.data });

}

function* fetchFilterDataWatcher() {
    yield takeEvery(actionTypes.FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* getProductStatusDataWatcher() {
    yield takeLatest(actionTypes.GET_PRODUCT_STATUS_DATA, getProductStatusDataWorker);
}

function* getArticleConfigDataWatcher() {
    yield takeLatest(actionTypes.GET_ARTICLE_CONFIG_DATA, getArticleConfigDataWorker)
}

function* getStoreGroupDataWatcher() {
    yield takeLatest(actionTypes.GET_STORE_GROUP_DATA, getStoreGroupDataWorker);
}

function* getProductProfilesWatcher() {
    yield takeLatest(actionTypes.GET_PRODUCT_PROFILES, getProductProfilesWorker)
}

function* getDcDataWatcher() {
    yield takeLatest(actionTypes.GET_DC, getDcDataWorker)
}

function* getContributionWatcher() {
    yield takeLatest(actionTypes.GET_STORE_SIZE_CONTRIBUTIONS, getContributionWorker)
}

function* createProductConfigWatcher() {
    yield takeLatest(actionTypes.CREATE_CONFIG, createProductConfigWorker)
}

function* deleteProductConfigWatcher() {
    yield takeLatest(actionTypes.DELETE_CONFIG, deleteProductConfigWorker)
}

function* getMissingConfigDataWatcher() {
    yield takeLatest(actionTypes.GET_MISSING_CONFIG, getMissingConfigDataWorker)
}


export function* productStatusSaga() {
    yield all([
        fetchFilterDataWatcher(),
        getProductStatusDataWatcher(),
        getStoreGroupDataWatcher(),
        getArticleConfigDataWatcher(),
        getProductProfilesWatcher(),
        getDcDataWatcher(),
        createProductConfigWatcher(),
        getContributionWatcher(),
        deleteProductConfigWatcher(),
        getMissingConfigDataWatcher()
    ])
}