import React from 'react';
import SalesView from './SalesView';
import ForecastDriversView from './ForecastDriversView';
import EditRadioSection from './EditRadioSection';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { SAVE_BUTTON, REVIEW_BUTTON, DRIVERS_TABLE_HEADER } from './AdaVisualConstants';
import "primereact/resources/primereact.css";
import "./AdaVisual.css";

/**
 * Ada Visual View component
 * @returns Sales Graph View, Forecasts Driver, Adjustment, Hierachy tabular view.
 */
const AdaVisualView = (props) => {
    const { handleReviewRequest, handleSaveRequest } = props;

    return (
        <React.Fragment>
            <div className="drivers_container shadow-sm">
                <SalesView />
            </div>
            <div className="drivers_container shadow-sm">
                <Accordion activeIndex={0}>
                    <AccordionTab header={DRIVERS_TABLE_HEADER}>
                        <ForecastDriversView />
                    </AccordionTab>
                </Accordion>
            </div>
            <div>
                <EditRadioSection />
                <span style={{ display: "flex", justifyContent: "center" }}>
                    <button
                        className="btn btn-primary"
                        style={{ padding: "2px" }}
                        onClick={handleReviewRequest}
                    >
                        {REVIEW_BUTTON}
                    </button>
                    <button
                        className="btn btn-primary"
                        style={{ marginLeft: "4px", padding: "2px" }}
                        onClick={handleSaveRequest}
                    >
                        {SAVE_BUTTON}
                    </button>
                </span>
            </div>
        </React.Fragment >
    )
}

export default AdaVisualView;