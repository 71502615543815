import {
    GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_ERROR, RESET_ALL, RESET_FILTERS_DATA,
    FETCH_FILTER_SUCCESS,
    GET_POPUP,
    GET_POPUP_SUCCESS,
    GET_POPUP_ERROR
} from "./ReportingAction"

import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export const reportingState = {
    dataLoading: false,
    data: [],
    dataError: null,
    departmentOptions: [],
    genderOptions: [],
    rbuOptions: [],
    dcsOptions: [],
    level5Options: [],
    articleStatusOptions: [],
    storeOptions: [],
    storeGradeOptions: [],
    regionOptions: [],
    climateOptions: [],
    storeStatusOptions: [],
    articleIdOptions: [],
    channelOptions: [],
    totalCount: 0,
    nextIndex: 0,
    popupLoading: false,
    popupColumn : [],
    popupData : [],
    popupError: null
}

export default {
    reporting: handleActions(
        {
            [GET_REPORT]: (state, { payload }) => {
                return immutable(state, {
                    dataLoading: { $set: true },
                    totalCount: { $set: 0 },
                    nextIndex: { $set: 0 }
                });
            },
            [GET_REPORT_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    dataError: { $set: null },
                    data: { $set: (payload && payload.data) || [] },
                    totalCount: { $set: (payload && payload.totalCount) || 0 },
                    nextIndex: { $set: (payload && payload.nextIndex) || 0 }
                });
            },
            [GET_REPORT_ERROR]: (state, { payload }) => {
                return immutable(state, {
                    dataError: { $set: (payload && payload.message) || null },
                    dataLoading: { $set: false },
                    data: { $set: [] },
                });
            },

            [GET_POPUP]: (state, { payload }) => {
                return immutable(state, {
                    popupLoading: { $set: true },
                    popupColumn: { $set: [] },
                    popupData: { $set: [] },
                    popupError: { $set: null },
                });
            },
            [GET_POPUP_SUCCESS]: (state, { payload }) => {
                return immutable(state, {
                    popupLoading: { $set: false },
                    popupColumn: { $set: (payload && payload.popupColumn) || [] },
                    popupData: { $set: (payload && payload.popupData) || [] },
                    popupError: { $set: null },
                });
            },
            [GET_POPUP_ERROR]: (state, { payload }) => {
                return immutable(state, {
                    popupLoading: { $set: false },
                    popupColumn: { $set:  [] },
                    popupData: { $set: [] },
                    popupError:  { $set: (payload && payload.error) || null },
                });
            },

            [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
                switch (payload.key) {
                    case "department":
                        return immutable(state, {
                            departmentOptions: {
                                $set:
                                    payload && payload.data.departmentOptions
                                        ? payload.data.departmentOptions
                                        : state.departmentOptions,
                            },
                            channelOptions: {
                                $set:
                                    payload && payload.data.channelOptions
                                        ? payload.data.channelOptions
                                        : state.channelOptions,
                            }, 
                            genderOptions: [],
                            rbuOptions: [],
                            dcsOptions: [],
                            level5Options: [],
                            storeGradeOptions: [],
                            regionOptions: [],
                            climateOptions: [],
                            storeStatusOptions: [],
                            articleStatusOptions: [],
                            articleIdOptions: [],
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "gender":
                        return immutable(state, {
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            genderOptions: {
                                $set:
                                    payload && payload.data.genderOptions
                                        ? payload.data.genderOptions
                                        : state.genderOptions,
                            },
                            rbuOptions: {
                                $set:
                                    payload && payload.data.rbuOptions
                                        ? payload.data.rbuOptions
                                        : state.rbuOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            regionOptions: {
                                $set:
                                    payload && payload.data.regionOptions
                                        ? payload.data.regionOptions
                                        : state.regionOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "sub_category":
                        return immutable(state, {
                            rbuOptions: {
                                $set:
                                    payload && payload.data.rbuOptions
                                        ? payload.data.rbuOptions
                                        : state.rbuOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "dcs":
                        return immutable(state, {
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level5":
                        return immutable(state, {
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "article_status":
                        return immutable(state, {
                            articleStatusOptions: {
                                $set:
                                    payload && payload.data.articleStatusOptions
                                        ? payload.data.articleStatusOptions
                                        : state.articleStatusOptions,
                            },
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "store_id":
                        return immutable(state, {
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            regionOptions: {
                                $set:
                                    payload && payload.data.regionOptions
                                        ? payload.data.regionOptions
                                        : state.regionOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "store_grade":
                        return immutable(state, {
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            regionOptions: {
                                $set:
                                    payload && payload.data.regionOptions
                                        ? payload.data.regionOptions
                                        : state.regionOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "region":
                        return immutable(state, {
                            regionOptions: {
                                $set:
                                    payload && payload.data.regionOptions
                                        ? payload.data.regionOptions
                                        : state.regionOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "climate":
                        return immutable(state, {
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "store_status":
                        return immutable(state, {
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "articleid":
                        return immutable(state, {
                            articleIdOptions: {
                                $set:
                                    payload && payload.data.articleIdOptions
                                        ? payload.data.articleIdOptions
                                        : state.articleIdOptions,
                            },
                            storeOptions: {
                                $set:
                                    payload && payload.data.storeOptions
                                        ? payload.data.storeOptions
                                        : state.storeOptions,
                            },
                            storeGradeOptions: {
                                $set:
                                    payload && payload.data.storeGradeOptions
                                        ? payload.data.storeGradeOptions
                                        : state.storeGradeOptions,
                            },
                            regionOptions: {
                                $set:
                                    payload && payload.data.regionOptions
                                        ? payload.data.regionOptions
                                        : state.regionOptions,
                            },
                            climateOptions: {
                                $set:
                                    payload && payload.data.climateOptions
                                        ? payload.data.climateOptions
                                        : state.climateOptions,
                            },
                            storeStatusOptions: {
                                $set:
                                    payload && payload.data.storeStatusOptions
                                        ? payload.data.storeStatusOptions
                                        : state.storeStatusOptions,
                            },
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    default:
                        return immutable(state, {
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                };
            },
            [RESET_ALL]: (state => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    data: { $set: [] },
                    dataError: { $set: null },
                    departmentOptions: { $set: [] },
                    genderOptions: { $set: [] },
                    rbuOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    level5Options: { $set: [] },
                    articleStatusOptions: { $set: [] },
                    storeOptions: { $set: [] },
                    storeGradeOptions: { $set: [] },
                    regionOptions: { $set: [] },
                    climateOptions: { $set: [] },
                    storeStatusOptions: { $set: [] },
                    articleIdOptions: { $set: [] },
                    totalCount: { $set: 0 },
                    nextIndex: { $set: 0 },
                    popupLoading: { $set: false },
                    popupColumn : { $set: [] }, 
                    popupData : { $set: [] },
                    popupError: { $set: null },
                })
            }),
            [RESET_FILTERS_DATA]: (state => {
                return immutable(state, {
                    data: { $set: [] },
                    genderOptions: { $set: [] },
                    rbuOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    level5Options: { $set: [] },
                    articleStatusOptions: { $set: [] },
                    storeOptions: { $set: [] },
                    storeGradeOptions: { $set: [] },
                    regionOptions: { $set: [] },
                    climateOptions: { $set: [] },
                    storeStatusOptions: { $set: [] },
                    articleIdOptions: { $set: [] },
                    totalCount: { $set: 0 },
                    nextIndex: { $set: 0 },
                    popupLoading: { $set: false },
                    popupColumn : { $set: [] }, 
                    popupData : { $set: [] },
                    popupError: { $set: null },
                })
            })
        },
        reportingState
    )
}