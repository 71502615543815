import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/lib/constants';

// Action types
import {
  FETCH_LOGIN_API,
  FETCH_LOGIN_API_SUCCESS,
  FETCH_LOGIN_API_ERROR,
  FETCH_USER_PERMISSION,
  FETCH_USER_ROLE,
} from './LoginActions';

export const loginState = {
  userData: [],
  success: false,
  loading: false,
  error: null,
  loginData: [],
  userPermission: [],
  userRole: [],
};

export default {
  login: handleActions(
    {
      [REHYDRATE]: (state) =>
        immutable(state, {
          userData: { $set: [] },
          success: { $set: false },
          loading: { $set: false },
          error: { $set: null },
        }),

      //Login API integration
      [FETCH_LOGIN_API]: (state) => {
        return immutable(state, {
          loading: { $set: true },
        });
      },

      [FETCH_LOGIN_API_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          loading: { $set: false },
          loginData: { $set: payload || [] },
          error: {$set : null}
        });
      },

      [FETCH_LOGIN_API_ERROR]: (state, { payload }) => {
        return immutable(state, {
          loading: { $set: false },
          loginData: { $set: [] },
          error: { $set: payload || '' },
        });
      },

      [FETCH_USER_PERMISSION]: (state, { payload }) => {
        return immutable(state, {
          userPermission: { $set: payload || [] },
        });
      },

      [FETCH_USER_ROLE]: (state, { payload }) => {
        return immutable(state, {
          userRole: { $set: payload || [] },
        });
      },
    },
    loginState,
  ),
};
