import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import useSelectFilters from "../../customHooks/useSelectFilters";
import Filter from "../ProductProfile/ProductProfileTable/Filter";
import {
  fetchFilterData,
  getChartData,
  getRecommendedActionstData,
  resetFiltersOptions,
} from "./DashboardActions";
import * as Notify from "../../components/Notification/Notifications";
import Notification from "../../components/Notification/Notifications";
import { propTypes } from "react-bootstrap/esm/Image";
import { levelFiltersLabelFormatter } from "../../utils/filterLevelMapping"
import { getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter } from "../../utils/commonUtilities";
import { isEmpty } from "lodash";
import { DateRangePicker } from 'react-dates';
import moment from "moment";


function DashboardFilters({
  // level1Options,
  // level2Options,
  // level3Options,
  // level4Options,
  ...props
}) {
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [level1Options, setlevel1Options] = useState(null);
  const [level2Options, setlevel2Options] = useState(null);
  const [level3Options, setlevel3Options] = useState(null);
  const [level4Options, setlevel4Options] = useState(null);
  const [level5Options, setlevel5Options] = useState(null);
  const [dcOptions, setDcOptions] = useState(null);
  const [min_date, setStartDate] = useState(null);
  const [max_date, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isEditing, setIsEditing] = useState(false)

  const [
    level1,
    level2,
    level3,
    level4,
    setLevel1,
    setLevel2,
    setLevel3,
    setLevel4,
    onLevel1Change,
    onLevel2Change,
    onLevel3Change,
    onLevel4Change,
    dynamicHeader,
    request,
    resestRequest,
    dc,
    setDc,
    onDcChange,
    level5,
    setLevel5,
    onLevel5Change
  ] = useSelectFilters(
    props.fetchFilterData,
    true,
    level2Options,
    level3Options,
    level4Options,
    setlevel2Options,
    setlevel3Options,
    setlevel4Options,
    dcOptions,
    setDcOptions,
    level5Options,
    setlevel5Options
  );


  useEffect(() => {
    setlevel1Options(() => props.level1Options);
  }, [props.level1Options]);


  useEffect(() => {
    setlevel2Options(() => props.level2Options);
  }, [props.level2Options]);

  useEffect(() => {
    setlevel3Options(() => props.level3Options);
  }, [props.level3Options]);

  useEffect(() => {
    setlevel4Options(() => props.level4Options);
  }, [props.level4Options]);

  useEffect(() => {
    setlevel5Options(() => props.level5Options);
  }, [props.level5Options]);

  useEffect(() => {
    setDcOptions(() => props.dcOptions);
  }, [props.dcOptions]);

  useEffect(() => {
    if (props.isBackClicked) {
      setLevel1(props.viewAllocationFilters?.level1?.[0])
      setLevel2(props.viewAllocationFilters?.level2?.[0])
      setLevel3(props.viewAllocationFilters?.level3?.[0])
      setLevel4(props.viewAllocationFilters?.level4?.[0])
      setLevel5(props.viewAllocationFilters?.level5?.[0])
      props.setIsBackclicked(false)
    }
  }, [props.isBackClicked])

  const resetFilters = () => {
    // setLevel1(null);
    setLevel2(null);
    setLevel3(null);
    setLevel4(null);
    setLevel5(null)
    setDc(null);
    resestRequest();
    setStartDate(null)
    setEndDate(null)
    props.resetFiltersOptions([]);
    if (!props.isViewAllocation) {
      // setLevel1(level1Options[0]);
      props.fetchFilterData({ level1: [level1] }, { key: 'l2' });
      // props.fetchFilterData({ filter_type: "dc" }, { key: 'dc' });
      props.getChartData({ level1: [level1], dc: props.dcOptions });
      props.getRecommendedActionstData({ level1: [level1], dc: props.dcOptions });
    }
    props?.resetFilters()
  };

  const prepReq = (...rest) => {
    let req = {};
    rest.forEach((val) => {
      for (let i in val) {
        if (val[i]) {
          if (Array.isArray(val[i])) {
            if (val[i].length) req[i] = [val[i]];
          } else {
            req[i] = [val[i]];
          }
        }
      }
    });
    return req;
  };

  const applyFilters = () => {
    if (level1) {
      let req = prepReq({
        level1,
        level2: request?.level2 || level2,
        level3: request?.level3 || level3,
        level4: request?.level4 || level4,
        level5: request?.level5 || level5,
        level2Label: props.filterLabels?.level2,
        level3Label: props.filterLabels?.level3,
        dc: request?.dc || props.dcOptions,
      });
      setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": level1 })
      if (props.isViewAllocation) {
        let viewAllocationReq = prepReq({
          start_date: min_date ? moment(min_date).format('YYYY-MM-DD') : null,
          end_date: max_date ? moment(max_date).format('YYYY-MM-DD') : null,
          level1,
          level2: request?.level2 || level2,
          level3: request?.level3 || level3,
          level4: request?.level4 || level4,
          level5: request?.level5 || level5,
        });
        props.getAllocations(viewAllocationReq)
      }
      else {
        props.getChartData(req);
        props.getRecommendedActionstData(req);
      }
      props.getFilters(req)
    } else {
      Notify.error("Please Select Mandatory Option!!");
    }
  };

  useEffect(() => {
    getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onLevel1Change)
    props.fetchFilterData({}, { key: 'l1' });
    if (!props.isViewAllocation) {
      props.fetchFilterData({ filter_type: "dc" }, { key: 'dc' });
    }
  }, []);

  useEffect(() => {
    let getFilter = JSON.parse(localStorage.getItem("user_preference"))
    if (level1Options?.length && !props.isViewAllocation && isEmpty(getFilter?.filter)) {
      setLevel1(level1Options[0]);
      props.fetchFilterData({ level1: [level1Options[0]] }, { key: 'l2' });
      setIsFirstCall(false);
    }
    // else if(!props.isViewAllocation){
    //   setLevel1(null)
    // }
  }, [level1Options]);

  const setDates = (p_startDate, p_endDate, p_static) => {
    // if(isEdit){
    setIsEditing(true)
    // props.onChanged('levelFilter')
    // }
    setStartDate(p_startDate);
    setEndDate(p_endDate);
  }

  const isOutsideRange = day => day.isAfter(moment());


  const viewAllocationPrep = [
    {
      id: 1,
      isRequired: true,
      label: props.filterLabels?.level1,
      name: "level1",
      value: level1,
      onChange: onLevel1Change,
      placeHolder: `Select ${props.filterLabels?.level1}`,
      options: level1Options?.length ? level1Options : [],
      style: { "marginBottom": "10px" },
    },
    {
      id: 2,
      isRequired: false,
      label: props.filterLabels?.level2,
      name: "level2",
      dropdownValue: level2,
      onDropDownChange: onLevel2Change,
      placeHolder: `Select ${props.filterLabels?.level2 ? props.filterLabels?.level2 : ""
        }`,
      optionsArray: level2Options?.length ? level2Options : [],
      isMultiSelect: true,
      style: { "marginBottom": "10px" },
    },
    {
      id: 3,
      isRequired: false,
      label: props.filterLabels?.level3,
      name: "level3",
      dropdownValue: level3,
      onDropDownChange: onLevel3Change,
      placeHolder: `Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""
        }`,
      optionsArray: level3Options?.length ? level3Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l3_name"),
      style: { "marginBottom": "10px" },
    },
    {
      id: 4,
      isRequired: false,
      label: props.filterLabels?.level4,
      name: "level4",
      dropdownValue: level4,
      onDropDownChange: onLevel4Change,
      placeHolder: `Select ${props.filterLabels?.level4 ? props.filterLabels?.level4 : ""
        }`,
      optionsArray: level4Options?.length ? level4Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l4_name"),
      style: { "marginBottom": "10px" },
    },
    {
      id: 5,
      isRequired: false,
      label: props.filterLabels?.level5,
      name: "level5",
      dropdownValue: level5,
      onDropDownChange: onLevel5Change,
      placeHolder: `Select ${props.filterLabels?.level5 ? props.filterLabels?.level5 : ""
        }`,
      optionsArray: level5Options?.length ? level5Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l5_name")
    },

  ]

  const prep = [
    {
      id: 1,
      isRequired: true,
      label: props.filterLabels?.level1,
      name: "level1",
      value: level1,
      onChange: onLevel1Change,
      placeHolder: `Select ${props.filterLabels?.level1}`,
      options: level1Options?.length ? level1Options : [],
      style: { "marginBottom": "10px" },
    },
    {
      id: 2,
      isRequired: false,
      label: props.filterLabels?.level2,
      name: "level2",
      dropdownValue: level2,
      onDropDownChange: onLevel2Change,
      placeHolder: `Select ${props.filterLabels?.level2 ? props.filterLabels?.level2 : ""
        }`,
      optionsArray: level2Options?.length ? level2Options : [],
      isMultiSelect: true,
      style: { "marginBottom": "10px" },
    },
    {
      id: 3,
      isRequired: false,
      label: props.filterLabels?.level3,
      name: "level3",
      dropdownValue: level3,
      onDropDownChange: onLevel3Change,
      placeHolder: `Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""
        }`,
      optionsArray: level3Options?.length ? level3Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l3_name"),
      style: { "marginBottom": "10px" },
    },
    {
      id: 4,
      isRequired: false,
      label: props.filterLabels?.level4,
      name: "level4",
      dropdownValue: level4,
      onDropDownChange: onLevel4Change,
      placeHolder: `Select ${props.filterLabels?.level4 ? props.filterLabels?.level4 : ""
        }`,
      optionsArray: level4Options?.length ? level4Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l4_name"),
      style: { "marginBottom": "10px" },

    },
    {
      id: 6,
      isRequired: false,
      label: props.filterLabels?.level5,
      name: "level5",
      dropdownValue: level5,
      onDropDownChange: onLevel5Change,
      placeHolder: `Select ${props.filterLabels?.level5 ? props.filterLabels?.level5 : ""
        }`,
      optionsArray: level5Options?.length ? level5Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l5_name")
    },
    {
      id: 5,
      isRequired: false,
      label: "DC",
      name: "dc",
      dropdownValue: dc,
      onDropDownChange: onDcChange,
      placeHolder: `Select ${props.filterLabels?.dc ? props.filterLabels?.dc : ""}`,
      optionsArray: dcOptions?.length ? dcOptions : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("dc")
    },
  ];

  return (
    <>
      <Notification />
      <Filter data={props.isViewAllocation ? viewAllocationPrep : prep} />
      {
        props.isViewAllocation &&
        <div className="col-md-3">
          <label className="fnt-md fnt-bold fnt-bold ">Period</label>
          <DateRangePicker
            startDate={min_date}
            startDateId="s_id"
            endDate={max_date}
            endDateId="e_id"
            onDatesChange={({ startDate, endDate }) => {
              setDates(startDate, endDate, "Static");
            }}
            focusedInput={focusedInput}
            onFocusChange={(e) => setFocusedInput(e)}
            displayFormat="MM-DD-YYYY"
            isOutsideRange={isOutsideRange}
            showClearDates
            withPortal
            numberOfMonths={3}
          />
        </div>
      }
      <div className="col-md-3 mt-2">
        <button
          onClick={applyFilters}
          className="btn btn-primary filter-constraints-mode"
          title="Apply filters"
        >
          <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
        </button>
        <button
          onClick={() => resetFilters("All")}
          className="btn undo-constraints-mode"
          title="Reset filters"
        >
          <i className="fa fa-undo mr-2"></i>Reset
        </button>
      </div>
    </>
  );
}

const mapStateToProps = ({ dashboard }) => ({
  level1Options: dashboard.level1Options,
  level2Options: dashboard.level2Options,
  level3Options: dashboard.level3Options,
  level4Options: dashboard.level4Options,
  level5Options: dashboard.level5Options,
  dcOptions: dashboard.dcOptions,
  filterLabels: dashboard.filterlabels,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFilterData: (payload, filterType) => dispatch(fetchFilterData(payload, filterType)),
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  getChartData: (payload) => dispatch(getChartData(payload)),
  getRecommendedActionstData: (payload) => dispatch(getRecommendedActionstData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFilters);
