import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Calendar } from "primereact/calendar";
import { INDIVIDUAL_DROP_SECTION_LABEL, SELECT_TIME_PERIOD_LABEL, SELECT_INVENTORY_SRC_LABEL } from './IndividualDropConstants';
import MultiSelect from "../../../../components/Filters/MultiSelect";
import InventoryDropTableView from './InventoryDropTableView';
import { handleSelectAll } from "../../../../utils/SelectAllHandler/SelectAllHander";
import { isEqual, intersectionWith } from 'lodash';
import * as inventoryDropActions from '../../actions/InventoryDropActions/InventoryDropActions';
import './IndividualDrop.css';
import { clientEnvId } from '../../../../utils/commonUtilities';

const InventoryDropView = (props) => {
    const { inventoryDropActionsProp } = props;

    const [datesValue, setDatesValue] = useState(null);
    const [displaySection, setDisplaySection] = useState(false);

    useEffect(() => {
        inventoryDropActionsProp.checkInventory();
        setDisplaySection(process.env.REACT_APP_ENV_ID === 2);
    }, []);

    const [inventorySrcValue, setInventorySrcValue] = useState({
        value: 'DC',
        label: 'DC',
    });
    const [inventorySrcOptions, setInventorySrcOptions] = useState(
        [{
            value: 'DC',
            label: 'DC',
        },
        {
            value: 'PO',
            label: 'PO',
        },
        {
            value: 'ASN',
            label: 'ASN',
        }]
    );

    const onInvSrcChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], inventorySrcOptions, isEqual);
            setInventorySrcValue(valuesArray[1]);
        }
        else {
            setInventorySrcValue(val);
        }
    };

    function handleDateChange(e) {
        setDatesValue(e.value);
    }

    return (
        <div className="section_container">
            <h2 className="font-weight-bold mx-auto p-3 text-left">
                {INDIVIDUAL_DROP_SECTION_LABEL}
            </h2>
            <hr />
            <div className="inv-source-container">
                <div className="p-field p-grid">
                    <label className="p-col-fixed inv-src-labels">
                        {SELECT_INVENTORY_SRC_LABEL}
                    </label>
                    <div className="p-col col-md-3 col-md-3-dd" id="INVENTORY_SOURCE_SELECTOR" style={{ marginTop: "1%" }}>
                        <MultiSelect
                            name="invSrc"
                            dropdownValue={inventorySrcValue}
                            onDropDownChange={onInvSrcChange}
                            placeHolder="Select Source..."
                            optionsArray={inventorySrcOptions?.length ? inventorySrcOptions : []}
                            isDisabled={clientEnvId != 4}
                        />
                    </div>
                </div>
                {displaySection ?
                    <>
                        <div className="p-field p-grid" style={{ marginTop: "2%" }}>
                            <label className="date-range-label p-col-fixed">
                                {SELECT_TIME_PERIOD_LABEL}
                            </label>
                            <div className="p-col">
                                <Calendar
                                    id="INVENTORY_DATE_RANGE"
                                    className="p-mb-2 range-date-cal"
                                    value={datesValue}
                                    selectionMode="range"
                                    onChange={(e) => handleDateChange(e)}
                                    readOnlyInput
                                    showIcon
                                />
                            </div>
                        </div>
                        <InventoryDropTableView />
                    </>
                    : null}
            </div>
        </div>
    )
}

const mapStateToProps = ({ inventoryDrop }) => ({

});

const mapDispatchToProps = (dispatch) => ({
    inventoryDropActionsProp: bindActionCreators(inventoryDropActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDropView);