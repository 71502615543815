import React, { useState, useEffect, useRef } from "react";
import moment from "moment"
import "../../components/Table/Cellrenderer/CellRenderer.scss"

const DateInput = React.memo((props) => {
    let ref = useRef()

    const [date, setDate] = useState(null);
    const today = moment(new Date()).format("YYYY-MM-DD")

    useEffect(() => {
        setDate("Select Date")
    },[])

    const onDateChange = (e) => {
        e.target.setAttribute("data-date",
            moment(e.target.value, "YYYY-MM-DD")
                .format(props.dateFormat))
        setDate(e.target.value)
        props.onChange(e.target.value)
    }

    const onFocusHandler = (e) => {
        e.target.setAttribute("data-date", "MM-DD-YYYY")
        e.target.type = "date"
    }

    return (
        <div>
            <input type="text"
                onFocus={e => onFocusHandler(e)} 
                id="date" ref={ref} 
                className="date-input" 
                min={today} 
                data-date=""
                value={date || ""} 
                onChange={onDateChange}
            />
        </div>
    );
});

export default DateInput;
