import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as storeConfigDetailsActions from '../../actions/StoreDetailsConfigActions/StoreConfigActions';
import PageLoader from "../../../../components/Loader/PageLoader";
import StoreDetailsDcConfigView from "./StoreDetailsDcConfigView";
import { STORE_DETAILS_NO_RECORDS_MSG, STORE_DETAILS_NO_RECORDS_HELPER_MSG, STORE_DETAILS_NETWORK_ERROR_MSG } from "./StoreDetailsDcConfigConstants";
import "./Storedetailsconfig.css";

/**
 * Store Details Config Container with lifecycle and handler methods
 */
class StoreDetailsConfigContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { storeConfigDetailsActionsProp } = this.props;
        storeConfigDetailsActionsProp.getStoreConfigDetails({});
    }

    render() {
        const { isStoreConfigDetailsLoading, isStoreConfigDetailsFailed, isStoreConfigDetailsSuccess, newStoreDetailsData, sisterStoreDetailsData, setDcSrcRowsSelection } = this.props;
        let content = isStoreConfigDetailsSuccess ?
            ((newStoreDetailsData && newStoreDetailsData.length > 0)
                && (sisterStoreDetailsData && sisterStoreDetailsData.length > 0))
                ?
                <StoreDetailsDcConfigView setDcSrcRowsSelection={setDcSrcRowsSelection} />
                : (isStoreConfigDetailsFailed ?
                    <div className="error">{STORE_DETAILS_NETWORK_ERROR_MSG}</div>
                    :
                    (<div className="empty">{STORE_DETAILS_NO_RECORDS_MSG}
                        <p>{STORE_DETAILS_NO_RECORDS_HELPER_MSG}</p>
                    </div>)
                )
            : null;


        return (
            <React.Fragment>
                <PageLoader loader={isStoreConfigDetailsLoading} loaderMessage='Loading...'>
                    {content}
                </PageLoader>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ newStoreDetailConfig }) => ({
    isStoreConfigDetailsLoading: newStoreDetailConfig.isStoreConfigDetailsLoading,
    isStoreConfigDetailsFailed: newStoreDetailConfig.isStoreConfigDetailsFailed,
    isStoreConfigDetailsSuccess: newStoreDetailConfig.isStoreConfigDetailsSuccess,
    newStoreDetailsData: newStoreDetailConfig.newStoreDetailsData,
    sisterStoreDetailsData: newStoreDetailConfig.sisterStoreDetailsData,
});

const mapDispatchToProps = (dispatch) => ({
    storeConfigDetailsActionsProp: bindActionCreators(storeConfigDetailsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetailsConfigContainer);