import { takeLatest, put, all, call } from "redux-saga/effects";
import * as Api from "../../routes/api"
import moment from "moment"
import { fetchFilterDataError, fetchFilterDataSuccesss, fetchStoreStatusDataError, fetchStoreStatusDataSuccesss, FETCH_FILTER_DATA, FETCH_STORE_STATUS_DATA, updateStoreStatusDataError, updateStoreStatusDataSuccesss, UPDATE_STORE_STATUS_DATA } from "./ProductStoreAction";


const options = [{
  label: "Open",
  value: "open"
},
{
  label: "Closed",
  value: "closed"
},
{
  label: "Under Renovation",
  value: "under_renovation"
}]

// Worker Saga

function* fetchFilterWorkerSaga(action) {
  try {
    const response = yield call(Api.getStoreLevelFilterApi,action.payload)
    if (response.status) {
      let reponse_data = response?.data?.data[0]
      Object.keys(reponse_data).forEach(key => reponse_data[key] = reponse_data[key].filter(val => val != null));
      yield put(fetchFilterDataSuccesss({'data':response.data}))
    }
    else{
      yield put(fetchFilterDataError({'error':response.message}));
    }

  } catch (error) {
      yield put(fetchFilterDataError(error));
  }
}

function* fetchStoreStatusWorkerSaga(action) {
    try {
        const response = yield call(Api.getStoreStatus,action.payload)
        console.log(action.payload)
        if (response.status) {
            let responseWithOptions = response?.data?.data?.map(val => {
                return{
                    ...val,
                    'store_titles': (val.store_titles?.join(' , ')),
                    'options': options,
                    'store_status': val?.under_renovation ? "under_renovation" : val.store_status,
                    'disable_status': val?.store_status === 'closed' ? true : false 
                }
            })
            yield put(fetchStoreStatusDataSuccesss({'data':responseWithOptions}))
        }
        else{
          yield put(fetchStoreStatusDataError({'error':response.message}));
        }
    
      } catch (error) {
          yield put(fetchStoreStatusDataError(error));
      }
  }

  function* updateStoreStatusWorkerSaga(action) {
    let req = action?.payload
    let req_body = req.map(val => {
      return {
          "store_code": val.store_code,
          "store_status": val.store_status,
          "inactive_start": val.store_status === "under_renovation" ? moment(val.inactive_start).format("MM-DD-YYYY") : val.inactive_start,
          "inactive_end": val.store_status === "under_renovation" ? moment(val.inactive_end).format("MM-DD-YYYY") : val.inactive_end,
          "updated_by": localStorage?.getItem("email")
      }
    })
    try {
      const response = yield call(Api.updateStoreStatus,{"changes": req_body})
      if (response.status) {
        yield put(updateStoreStatusDataSuccesss({'data':response.data}))
      }
      else{
        yield put(updateStoreStatusDataError({'error':response.message}));
      }
  
    } catch (error) {
        yield put(updateStoreStatusDataError(error));
    }
  }


// Watcher Saga

function* fetchFilterWatcherSaga() {
  yield takeLatest(FETCH_FILTER_DATA, fetchFilterWorkerSaga);
}

function* fetchStoreStatusWatcherSaga() {
    yield takeLatest(FETCH_STORE_STATUS_DATA, fetchStoreStatusWorkerSaga);
}

function* updateStoreStatusWatcherSaga(){
    yield takeLatest(UPDATE_STORE_STATUS_DATA, updateStoreStatusWorkerSaga);
}


export function* productStoreStatusSaga() {
  yield all([ 
              fetchFilterWatcherSaga(),
              fetchStoreStatusWatcherSaga(),
              updateStoreStatusWatcherSaga(),
            ]);
}
