// This HOC is used to provide plugins to react-table dynamically based on `keyRT`

import React from "react";
import { useSticky, stickyRow } from "react-table-sticky";
import {
  useTable,
  useGroupBy,
  usePagination,
  useExpanded,
  useBlockLayout,
  useRowSelect,
  useRowState,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
} from "react-table";

import {
  useCheckBoxColumn,
  useCheckBoxLastColumn,
  useGetInstance,
  useRadioColumn,
  useRowSpan,
} from "./CustomHooks/UtilityHooks";

import { useColumnExapander } from "./CustomHooks/useColumnExapander";

export const defaultConfig = {
  plugins: [
    useGroupBy,
    useSortBy,
    useExpanded,
    useBlockLayout,
    usePagination,
    useRowSelect,
    useGetInstance,
    useSticky,
  ],
};

const WithDynamicPlugins = (Comp) => (props) => {
  const { keyRT } = props;

  const tablePluginsArgs = {
    StrategyPageTable: {
      plugins: [
        useFilters,
        useSortBy,
        useBlockLayout,
        usePagination,
        useRowSelect,
        useGetInstance,
        useCheckBoxColumn,
        useSticky,
      ],
    },
    toBeReleased: {
      plugins: [
          useFilters,
          useSortBy,
          useBlockLayout,
          usePagination,
          useRowSelect,
          useGetInstance,
          useSticky,
      ]
    },
    sortAndSearch:{
      plugins: [
        useFilters,
        ...defaultConfig.plugins,
      ]
    },
    productDetailView:{
      plugins: [
        useFilters,
        useSortBy,
        useBlockLayout,
        usePagination,
        useRowSelect,
        useGetInstance,
        useCheckBoxColumn,
        useSticky,
      ]
    },
    storeMapping:{
      plugins: [
        useFilters,
        useCheckBoxColumn,
        ...defaultConfig.plugins,
      ]
    },
    productProfileMapping:{
      plugins: [
        useFilters,
        useSortBy,
        useBlockLayout,
        usePagination,
        useRowSelect,
        useGetInstance,
        useRadioColumn,
        useSticky,
      ]
    },
    finalize:{
      plugins: [
        useFilters,
        useRadioColumn,
        ...defaultConfig.plugins,
      ]
    },
    storeView:{
      plugins: [
        useFilters,
        useCheckBoxColumn,
        useSortBy,
        useBlockLayout,
        usePagination,
        useRowSelect,
        useGetInstance,
        useSticky,
      ]
    },
    constraintsPageTable: {
      plugins: [
        useFilters,
        useSortBy,
        useBlockLayout,
        usePagination,
        useRowSelect,
        useGetInstance,
        useCheckBoxColumn,
        useSticky,
      ],
    },
    StyleInvTable: {
      plugins: [
        useFilters,
        useSortBy,
        useBlockLayout,
        usePagination,
        useRowSelect,
        useGetInstance,
        useCheckBoxColumn,
        useSticky,
      ],
    },
    deepDive: {
      plugins: [
        useFilters,
        useSortBy,
        useBlockLayout,
        usePagination,
        useRowSelect,
        useGetInstance,
        useSticky,
      ]
    },
    RecommendedActionTable: {
      plugins: [
        // useFilters,
        useSortBy,
        useBlockLayout,
        usePagination,
        useGetInstance,
        useSticky,
      ]
    },
    ProductProfileTable: {
      plugins: [
        useFilters,
        useSortBy,
        useBlockLayout,
        usePagination,
        useRowSelect,
        useGetInstance,
        useCheckBoxColumn,
        useSticky,
      ],
    },
    DashboardPlans: {
      plugins: [
        useSortBy,
        useBlockLayout,
        useRowSelect,
        useCheckBoxColumn,
        useRowState,
        useGetInstance,
      ],
    },
    DashboardPopupPlans: {
      plugins: [useBlockLayout, useGetInstance],
    },
    PerformanceAttributeCluster: {
      plugins: [
        //useBlockLayout,
        useRowSelect,
        useCheckBoxLastColumn,
        useGetInstance,
      ],
    },
    ProductAttributeCluster: {
      plugins: [
        //useBlockLayout,
        useCheckBoxLastColumn,
        useExpanded,
        useRowSelect,
        useRowSpan,
        useGetInstance,
      ],
    },
    ClusterBreakdown: {
      plugins: [useGroupBy, useSortBy, useBlockLayout, useExpanded],
    },
    TransferTableRecords: {
      plugins: [
        useBlockLayout,
        useCheckBoxColumn,
        useGetInstance,
        useFilters,
        usePagination,
        useRowSelect,
      ],
    },
    PlanCategoryLevel: {
      plugins: [useBlockLayout, useGetInstance, useGlobalFilter],
    },
    PlanOptimizeConstraint: {
      plugins: [useGlobalFilter, useBlockLayout, useGetInstance],
    },
    PlanClusterLevel: {
      plugins: [
        // useBlockLayout,
        useExpanded,
        useRowSpan,
        useGetInstance,
      ],
    },
    ReviewSizeTable: {
      plugins: [
        // useBlockLayout,
        useExpanded,
        useRowSpan,
        useGetInstance,
        usePagination,
      ],
    },
    WedgeSecondTable: {
      plugins: [
        useGlobalFilter,
        useSortBy,
        useExpanded,
        useRowSpan,
        useGetInstance,
        usePagination,
        useBlockLayout,
      ],
    },
    BudgetClusterLevel: {
      plugins: [
        // useBlockLayout,
        useGlobalFilter,
        useRowSpan,
        useExpanded,
        useGetInstance,
      ],
    },
    SubcatModal: {
      plugins: [
        useCheckBoxColumn,
        useBlockLayout,
        useGlobalFilter,
        useAsyncDebounce,
        useGetInstance,
        useRowState,
        usePagination,
        useRowSelect,
      ],
    },
    ReviewByAttribute: {
      plugins: [
        useExpanded,
        useRowSpan,
        useGetInstance,
        useRowSelect,
        useCheckBoxLastColumn,
      ],
    },
    promotionpipeline: {
      plugins: [
        useExpanded,
        useRowSpan,
        useGetInstance,
        useRowSelect,
        //useCheckBoxLastColumn,
        usePagination,
      ],
    },
    BudgetLevelOneComponent: {
      plugins: [useBlockLayout],
    },
    BudgetLevelThreeComponent: {
      plugins: [useGetInstance, useGlobalFilter],
    },
    setUpDrops: {
      plugins: [useGetInstance, useBlockLayout],
    },
    PlanDepth: {
      plugins: [
        // useBlockLayout,
        useGlobalFilter,
        useExpanded,
        useRowSpan,
        useGetInstance,
        useColumnExapander,
      ],
    },
    PlanChoice: {
      plugins: [
        // useBlockLayout,
        useGlobalFilter,
        useExpanded,
        useRowSpan,
        useGetInstance,
        useColumnExapander,
      ],
    },
    PlanOptimization: {
      plugins: [
        useBlockLayout,
        useGlobalFilter,
        // useExpanded,
        // useRowSpan,
        useGetInstance,
        useColumnExapander,
      ],
    },
  };

  const config =
    keyRT || tablePluginsArgs[keyRT] ? tablePluginsArgs[keyRT] : defaultConfig;

  // Finally return component with plugins based on keyRT
  return <Comp tablePluginsArgs={config} {...props} />;
};

export default WithDynamicPlugins;
