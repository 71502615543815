import React, { useEffect } from "react";
import XLSX from 'xlsx'
import XML from 'xml-js'
import { saveFile } from '../../utils/fileSave'

const ExportFile = React.memo((props) => {
    const { fileName, text, data, type, callFunc, sheetName, downloadExcelData } = props

    const downloadExcel = () => {
        if (data.length) {
            const worksheet = XLSX.utils.json_to_sheet(data)
            const workbook = {}
            if(sheetName){
                workbook.Sheets = {
                    [sheetName] : worksheet
                }
                workbook.SheetNames = [sheetName]
            }
            else {
                workbook.Sheets = {
                    "Excess Inventory Report": worksheet
                }
                workbook.SheetNames = ["Excess Inventory Report"]
            }
            // const workbook = {
            //     Sheets: {
            //         "Excess Inventory Report": worksheet
            //     },
            //     SheetNames: ["Excess Inventory Report"]
            // }
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
            saveFile(excelBuffer, `${fileName}.xlsx`)
        }
    }

    const downloadXml = () => {
        var result = XML.json2xml(data, { compact: true });
        saveFile(result, `${fileName}.xml`)
    }

    const options = {
        "1": downloadExcel,
        "2": downloadXml
    }

    useEffect(() => {
        if(props.downloadExcelData) {
            downloadExcel()
        }
    }, [props.downloadExcelData])

    return (
        <button className="btn btn-primary px-2" onClick={callFunc ? callFunc : options[type]}>
            <i
                className="fa fa-download mr-2"
                title="Configure"
                aria-hidden="true"
            ></i>
            {text}
        </button>
    )
})

export default ExportFile;
