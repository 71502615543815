import React, { useState, useCallback } from 'react';
import moment from "moment";
import Select from "../../../../components/Filters/SingleSelect";
import * as Notify from "../../../../components/Notification/Notifications"
import Notification from '../../../../components/Notification/Notifications';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from "primereact/calendar";
import { Checkbox } from 'primereact/checkbox';
import { debounce } from 'lodash';
import {
    STRATEGY_CONFIG_EDIT_HEADER_PRE_SEASON, STRATEGY_CONFIG_TABLE_COL_DEMAND_TYPE, STRATEGY_CONFIG_TABLE_COL_APS,
    STRATEGY_CONFIG_TABLE_COL_WOS, STRATEGY_CONFIG_TABLE_COL_STORE_INV, STRATEGY_CONFIG_TABLE_COL_TARGET,
    STRATEGY_CONFIG_TABLE_COL_EXECUTION_DATE, STRATEGY_CONFIG_TABLE_COL_FREQUENCY, PRE_SEASON_DEMAND_TYPE_OPTIONS,
    PRE_SEASON_FREQUENCY_TYPE_OPTIONS,
} from '../../StrategyConfigConstants';
import "../../StrategyConfiguration.css";

/**
 * Functional view component for Pre Season Section
 * @param {*} props 
 * @returns 
 */
const EditPreSeason = (props) => {
    const { strategyConfigData, strategyConfigActionsProp, editedConfigReq } = props;
    const { pre_season_aps, pre_season_wos, pre_season_store_inv_push,
        pre_season_execution_date, pre_season_demand_type } = editedConfigReq//strategyConfigData;

    const [demandType, setDemandTypeSelected] = useState({
        value: pre_season_demand_type,
        label: pre_season_demand_type
    });
    const [frequencyType, setFrequencyTypeSelected] = useState(null);
    const onDemandTypeChange = (val) => {
        setDemandTypeSelected(val);
        if (val?.value === 'APS') {
            strategyConfigActionsProp.setEditedStrategyData({
                key: 'pre_season_store_inv_push',
                value: null,
            });
            
        }
        strategyConfigActionsProp.setEditedStrategyData({
            key: "pre_season_demand_type",
            value: val.value,
        });
    };

    const onFrequencyTypeChange = (val) => {
        setFrequencyTypeSelected(val);
        strategyConfigActionsProp.setEditedStrategyData({
            key: "pre_season_frequency",
            value: val.value,
        });
    };

    const onInputNumberChange = useCallback(debounce((data, fieldName, type) => {
        if (type?.value === 'Fixed') {
            // strategyConfigActionsProp.setEditedStrategyData({
            //     key: 'pre_season_aps',
            //     value: null,
            // });
            // strategyConfigActionsProp.setEditedStrategyData({
            //     key: 'pre_season_wos',
            //     value: null,
            // });
        }
        else if (type?.value === 'APS') {
            strategyConfigActionsProp.setEditedStrategyData({
                key: 'pre_season_store_inv_push',
                value: null,
            });
            
        }
        strategyConfigActionsProp.setEditedStrategyData({
            key: fieldName,
            value: data,
        });


    }, 700), []);

    
    const handleDateChange = (e) => {
        if (e) {
            const changedDate = moment(e.value).format("MM-DD-YYYY");
            strategyConfigActionsProp.setEditedStrategyData({
                key: "pre_season_execution_date",
                value: changedDate,
            });
        } else {
            //No code here
        }
    };

   

    return (
        <><Notification />
            <div>
                <h3 className="fnt-bold">{STRATEGY_CONFIG_EDIT_HEADER_PRE_SEASON}</h3>
                <div className="p-field p-grid">
                    <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_DEMAND_TYPE}
                    </label>
                    <div className="p-col" >
                        <Select
                            id="PRE_DEMAND_TYPE_SELECTOR"
                            name="demandTypeSelector"
                            value={demandType}
                            onChange={onDemandTypeChange}
                            placeHolder="Select"
                            options={PRE_SEASON_DEMAND_TYPE_OPTIONS}
                            className="p-col"
                        // isDisabled={editModeDetails?.isEditMode}
                        />
                    </div>
                </div>

                <div className="p-field p-grid">
                    <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_APS}
                    </label>
                    <div className="p-col">
                        <InputNumber inputId="PRE_APS" min={0} value={pre_season_aps === "" ? null : pre_season_aps}
                            onChange={(e) => onInputNumberChange(e.value, "pre_season_aps",demandType)}
                            mode="decimal" useGrouping={false} className="editable-text-field" disabled={demandType?.value === null} showButtons />
                    </div>

                    <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_EXECUTION_DATE}
                    </label>
                    <div className="p-col">
                        <Calendar
                            id="EXECUTION DATE"
                            className="p-mb-2 execution-date-cal"
                            dateFormat="mm-dd-yy"
                            value={pre_season_execution_date === null ? "" : new Date(pre_season_execution_date)}
                            onChange={(e) => handleDateChange(e)}
                            readOnlyInput
                            showIcon
                        />
                    </div>
                </div>

                <div className="p-field p-grid">
                    <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_WOS}
                    </label>
                    <div className="p-col">
                        <InputNumber inputId="PRE_WOS" min={0} value={pre_season_wos === "" ? null : pre_season_wos}
                            onChange={(e) => onInputNumberChange(e.value, "pre_season_wos", demandType)}
                            mode="decimal" useGrouping={false} className="editable-text-field" disabled={demandType?.value === null} showButtons />
                    </div>

                    {/* <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_FREQUENCY}
                    </label>
                    <div className="p-col" >
                        <Select
                            id="PRE_FREQUENCY_SELECTOR"
                            name="frequency"
                            value={frequencyType}
                            onChange={onFrequencyTypeChange}
                            placeHolder="Select Store"
                            options={PRE_SEASON_FREQUENCY_TYPE_OPTIONS}
                            className="p-col"
                        // isDisabled={editModeDetails?.isEditMode}
                        />
                    </div> */}
                </div>

                <div className="p-field p-grid">
                    <label className="non-editable-labels p-col-fixed">
                        {STRATEGY_CONFIG_TABLE_COL_STORE_INV}
                    </label>
                    <div className="p-col">
                        <InputNumber inputId="STOREINV" min={0} max={100} value={demandType?.value === "APS" ? null : pre_season_store_inv_push === "" ? null : pre_season_store_inv_push} disabled={demandType?.value !== "Fixed"}
                            onChange={(e) => onInputNumberChange(e.value, "pre_season_store_inv_push", demandType)}
                            mode="decimal" useGrouping={false} className="editable-text-field" suffix="%" />
                    </div>
                </div>

            </div>
        </>
    );
}

export default EditPreSeason;