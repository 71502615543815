import React, { useState, useEffect } from "react";
import "../Filters/Filters.css"
import Filter from "../../containers/ProductProfile/ProductProfileTable/Filter";
import * as Notify from "../Notification/Notifications";
import Notification from "../Notification/Notifications";
import { intersectionWith, isEmpty, isEqual } from "lodash";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import { levelFiltersLabelFormatter } from "../../utils/filterLevelMapping"
import { shouldHideForCurrentClient } from "../../utils/commonUtilities"
import { connect } from "react-redux";
import { createStoreGroupFiltersAction } from "../../containers/StoreGroup/StoreGroupAction";

const hide = shouldHideForCurrentClient("region","district") 

const ClusterFiltersInModal = props => {
  const { storeFilterData } = props;

const [l1_name, setLevel1] = useState(null);
const [l2_name, setLevel2] = useState(null);
const [l3_name, setLevel3] = useState(null);
const [l4_name, setLevel4] = useState(null);
const [l5_name, setLevel5] = useState(null);

const [age, setAge] = useState(null);
const [channel, setChannel] = useState(null);
const [climate, setClimate] = useState(null);
const [district, setDistrict] = useState(null);
const [layout, setLayout] = useState(null);
const [name, setName] = useState(null);
const [stores, setStore] = useState(null)
const [region, setRegion] = useState(null);
const [size, setSize] = useState(null);
const [request,setRequest] = useState(null)
const [disable, setDisable] = useState(false)

let filterOptions = {}
let filterData = storeFilterData
// storeFilterData?.data?.length && storeFilterData.data[0]

console.log(storeFilterData,'sssssd')

for(let i in filterData) {
  filterOptions[i] = filterData[i]?.map(val => {
    return {
      label : val,
      value: val
    }
  })
}

useEffect(() => {
  if(props.disableAll){
    !disable && setDisable(true)
    // setLevel1({'label':props.department, 'value':props.department})
  }
  else{
    disable && setDisable(false)
    // setLevel1(null)
  }
},[props.disableAll])

useEffect(() => {
  if(!isEmpty(props.filterValues)){
    for(let i in props.filterValues){
      let selector = props.filterValues[i];
      switch (i){
        case 'l1_name':
            setLevel1(selector?.map(val => {
            return {label:val, value:val}
          }))
        break;
        case 'l2_name':
            setLevel2(selector?.map(val => {
            return {label:val, value:val}
          }))
        break;
        case 'l3_name':
            setLevel3(selector?.map(val => {
            return {label:val, value:val}
          }))
        break;
        case 'l4_name':
            setLevel4(selector?.map(val => {
            return {label:val, value:val}
          }))
        break;
        case 'l5_name':
            setLevel5(selector?.map(val => {
            return {label:val, value:val}
          }))
        break;
        case 'climate':
          setClimate(selector?.map(val => {
            return {label:val, value:val}
          }))
          break;
        case 'region':
          setRegion(selector?.map(val => {
            return {label:val, value:val}
          }))
          break;
        case 'district':
          setDistrict(selector?.map(val => {
            return {label:val, value:val}
          }))
          break;
        case 'size':
          setSize(selector?.map(val => {
            return {label:val, value:val}
          }))
          break;
        case 'name':
          setName(selector?.map(val => {
            return {label:val, value:val}
          }))
          break;
        case 'stores':
            setStore(selector?.map(val => {
              return {label:val, value:val}
            }))
            break;
      }
    }
  }
},[props.filterValues])

useEffect(() => {
  if(!isEmpty(props.filterValue)){
    for(let i in props.filterValue){
      let selector = props.filterValue[i];
      console.log(selector,'selector')
      switch (i){
        case 'l1_name':
          setLevel1(selector[0])
          setRequest(old => {
            return {
              ...old,
              'l1_name': selector[0]
            }
          })
          break;
        case 'l2_name':
          setLevel2(selector[0])
          setRequest(old => {
            return {
              ...old,
              'l2_name': selector[0]
            }
          })
          break;
        case 'l3_name':
          setLevel3(selector[0])
          setRequest(old => {
            return {
              ...old,
              'l3_name': selector[0]
            }
          })
          break;
        case 'l4_name':
          setLevel4(selector[0])
          setRequest(old => {
            return {
              ...old,
              'l4_name': selector[0]
            }
          })
          break;
        case 'l5_name':
          setLevel5(selector[0])
          setRequest(old => {
            return {
              ...old,
              'l5_name': selector[0]
            }
          })
        break;
        case 'climate':
          setClimate(selector[0])
          setRequest(old => {
            return {
              ...old,
              'climate': selector[0]
            }
          })
          break;
        case 'region':
          setRegion(selector[0])
          setRequest(old => {
            return {
              ...old,
              'region': selector[0]
            }
          })
          break;
        case 'district':
          setDistrict(selector[0])
          setRequest(old => {
            return {
              ...old,
              'district': selector[0]
            }
          })
          break;
        case 'size':
          setSize(selector[0])
          setRequest(old => {
            return {
              ...old,
              'size': selector[0]
            }
          })
          break;
        case 'name':
          setName(selector[0])
          setRequest(old => {
            return {
              ...old,
              'name': selector[0]
            }
          })
          break;
        case 'stores':
          setStore(selector[0])
            setRequest(old => {
              return {
                ...old,
                'stores': selector[0]
              }
            })
            break;
      }
    }
  }
},[props.filterValue])

const resetOtherFilters = (p_index) => {
  l5_name && setLevel5(null);
  if(p_index === 0){
    l2_name && setLevel2(null);
  }
  if(p_index === 0 || p_index === 1){
    l3_name && setLevel3(null);
  }
  if(p_index === 0 || p_index === 1 || p_index === 2){
    l4_name && setLevel4(null);
  }
}


const onLevel1Change = (value,event,that) => {
  let  {l1_name} = filterOptions
  let valuesArray = handleSelectAll(value,event,that)
  console.log(valuesArray,'gvjbh')
  setRequest(old => {
    return {
      ...old,
      'l1_name': valuesArray[0]
    }
  })
  setLevel1(valuesArray[1])
  resetOtherFilters(0)
  let req_val = intersectionWith(valuesArray[0], l1_name , isEqual);
  !shouldHideForCurrentClient("l1_name", "storeEligibilityGroup") && props.getStoreFiltersApi({l1_name:req_val});
};

const onLevel2Change = (value) => {
  setRequest(old => {
    return {
      ...old,
      'l2_name': value
    }
  })
  setLevel2(value);
  resetOtherFilters(1)
  if(!shouldHideForCurrentClient("l2_name", "storeEligibilityGroup")) {
    let req = { 'l2_name': [value] }
    if(l1_name || request?.l1_name) {
      req.l1_name = l1_name || request.l1_name
    }
    props.getStoreFiltersApi(req);
  } 
};

const onLevel3Change = (value) => {
  setRequest(old => {
    return {
      ...old,
      'l3_name': value
    }
  })
  setLevel3(value);
  resetOtherFilters(2)
  if(!shouldHideForCurrentClient("l3_name", "storeEligibilityGroup")) {
    let req = { 'l3_name': [value] }
    if(l1_name || request?.l1_name) {
      req.l1_name = l1_name || request.l1_name
    }
    if(l2_name || request?.l2_name) {
      req.l2_name = [l2_name || request.l2_name]
    }
    props.getStoreFiltersApi(req);
  } 
}

const onLevel4Change = (value) => {
  setRequest(old => {
    return {
      ...old,
      'l4_name': value
    }
  })
  setLevel4(value);
  resetOtherFilters()
  if(!shouldHideForCurrentClient("l3_name", "storeEligibilityGroup")) {
    let req = { 'l4_name': [value] }
    if(l1_name || request?.l1_name) {
      req.l1_name = l1_name || request.l1_name
    }
    if(l2_name || request?.l2_name) {
      req.l2_name = [l2_name || request.l2_name]
    }
    if(l3_name || request?.l3_name) {
      req.l3_name = [l3_name || request.l3_name]
    }
    // props.getStoreFiltersApi(req);
  } 
};

const onLevel5Change = (value,event,that) => {
  setRequest(old => {
    return {
      ...old,
      'l5_name': value
    }
  })
  setLevel5(value);
};

const onAgeChange = (value,event,that) => {
  let valuesArray = handleSelectAll(value,event,that)
  setRequest(old => {
    return {
      ...old,
      'age': valuesArray[0]
    }
  })
  setAge(valuesArray[1])
  // let value = intersectionWith(val, filterOptions.age, isEqual);
};
const onChannelChange = (value,event,that) => {
  let valuesArray = handleSelectAll(value,event,that)
  setRequest(old => {
    return {
      ...old,
      'channel': valuesArray[0]
    }
  })
  setChannel(valuesArray[1])
  // let value = intersectionWith(value,event,that, filterOptions.channel, isEqual);
};
const onClimateChange = (value,event,that) => {
  let valuesArray = handleSelectAll(value,event,that)
  setRequest(old => {
    return {
      ...old,
      'climate': valuesArray[0]
    }
  })
  setClimate(valuesArray[1])
  // let value = intersectionWith(value,event,that, filterOptions.climate, isEqual);
};
const onSizeChange = (value,event,that) => {
  let valuesArray = handleSelectAll(value,event,that)
  setRequest(old => {
    return {
      ...old,
      'size': valuesArray[0]
    }
  })
  setSize(valuesArray[1])
  // let value = intersectionWith(value,event,that, filterOptions.size, isEqual);
};
const onRegionChange = (value,event,that) => {
  let valuesArray = handleSelectAll(value,event,that)
  setRequest(old => {
    return {
      ...old,
      'region': valuesArray[0]
    }
  })
  setRegion(valuesArray[1])
  // let value = intersectionWith(value,event,that, filterOptions.region, isEqual);
};

const onDistrictChange = (value,event,that) => {
  let valuesArray = handleSelectAll(value,event,that)
  setRequest(old => {
    return {
      ...old,
      'district': valuesArray[0]
    }
  })
  setDistrict(valuesArray[1])
  // let value = intersectionWith(value,event,that, filterOptions.district, isEqual);
};
const onNameChange = (value,event,that) => {
  let valuesArray = handleSelectAll(value,event,that)
  setRequest(old => {
    return {
      ...old,
      'name': valuesArray[0]
    }
  })
  setName(valuesArray[1])
  // let value = intersectionWith(value,event,that, filterOptions.name, isEqual);
};

const onStoreChange = (value,event,that) => {
  let valuesArray = handleSelectAll(value,event,that)
  setRequest(old => {
    return {
      ...old,
      'stores': valuesArray[0]
    }
  })
  setStore(valuesArray[1])
  // let value = intersectionWith(value,event,that, filterOptions.name, isEqual);
};

const onLayoutChange = (val) => {
  let value = intersectionWith(val, filterOptions.layout, isEqual);
  setLayout(value)
};

console.log(storeFilterData)



const prepReq = (...rest) => {
  let req = {};
  rest.forEach((val) => {
    for (let i in val) {
      if (val[i]) {
        if (Array.isArray(val[i])) {
          if (val[i].length) req[i] = [val[i]];
        } else {
          req[i] = [val[i]];
        }
      }
    }
  });
  return req;
};

const applyFilters = () => {
  if(props.storeConfig) {
    let req = prepReq({
      age: request?.age,
      climate: request?.climate,
      district: request?.district,
      name: request?.name,
      layout,
      region: request?.region
    });
    props.getStores(req)
  } else if (l1_name) {
    let req = prepReq({
      l1_name: request?.l1_name || l1_name,
      l2_name: request?.l2_name || l2_name,
      l3_name: request?.l3_name || l3_name,
      l4_name: request?.l4_name || l4_name,
      l5_name: request?.l5_name || l5_name,
      age: request?.age || age,
      // channel: request?.channel,
      climate: request?.climate || climate,
      size: request?.size || size,
      district: request?.district || district,
      name: request?.name || name,
      stores: request?.stores || stores,
      layout,
      region: request?.region || region
    });
    props.getStores(req)
  } else {
    Notify.error("Please Select Mandatory Option!!");
  }
};

const resetFilters = () => {
  if(!disable){
    setLevel1(null)
  }
  setLevel2(null)
  setLevel3(null)
  setLevel4(null)
  setLevel5(null)
  props.resetTableData(true)
  setRequest(null);
  setLayout(null)
  setAge(null)
  setClimate(null)
  setRegion(null)
  setDistrict(null)
  setName(null)
  setStore(null)
  setChannel(null)
  setSize(null)
};

const getl1Name = (p_level) => {
  let l1Options = []
  if(p_level)
    l1Options =  p_level
  return l1Options;
}

const prep = [
  {
    id: 1,
    isRequired: true,
    label: levelFiltersLabelFormatter("level1"),
    name: "l1_name",
    dropdownValue: l1_name,
    onDropDownChange: onLevel1Change,
    placeHolder: `Select ${levelFiltersLabelFormatter("level1")}`,
    isDisabled: disable,
    optionsArray: filterOptions.l1_name ? filterOptions.l1_name : getl1Name(l1_name),
    isMultiSelect: true,
    style: {"marginBottom": "1rem"},
  },
  {
    id: 10,
    label: levelFiltersLabelFormatter("level2"),
    name: "l2_name",
    value: l2_name,
    onChange: onLevel2Change,
    placeHolder: `Select ${levelFiltersLabelFormatter("level2")}`,
    options: filterOptions.l2_name ? filterOptions.l2_name : [],
    style: { "marginBottom": "10px" },
    hide: shouldHideForCurrentClient("l2_name", "storeEligibilityGroup"),
  },
  {
    id: 11,
    label: levelFiltersLabelFormatter("level3"),
    name: "l3_name",
    value: l3_name,
    onChange: onLevel3Change,
    placeHolder: `Select ${levelFiltersLabelFormatter("level3")}`,
    options: filterOptions.l3_name ? filterOptions.l3_name : [],
    style: { "marginBottom": "10px" },
    hide: shouldHideForCurrentClient("l3_name", "storeEligibilityGroup"),
  },
  {
    id: 12,
    label: levelFiltersLabelFormatter("level4"),
    name: "l4_name",
    value: l4_name,
    onChange: onLevel4Change,
    placeHolder: `Select ${levelFiltersLabelFormatter("level4")}`,
    options: filterOptions.l4_name ? filterOptions.l4_name : [],
    style: { "marginBottom": "10px" },
    hide: shouldHideForCurrentClient("l4_name", "storeEligibilityGroup"),
  },
  {
    id: 13,
    label: levelFiltersLabelFormatter("level5"),
    name: "l5_name",
    value: l5_name,
    onChange: onLevel5Change,
    placeHolder: `Select ${levelFiltersLabelFormatter("level5")}`,
    options: filterOptions.l5_name ? filterOptions.l5_name : [],
    style: { "marginBottom": "10px" },
    hide: shouldHideForCurrentClient("l5_name", "storeEligibilityGroup"),
  },
  {
    id: 4,
    label: levelFiltersLabelFormatter("climate"),
    name: "climate",
    dropdownValue: climate,
    onDropDownChange: onClimateChange,
    placeHolder: `Select ${levelFiltersLabelFormatter("climate")}`,
    optionsArray: filterOptions.climate ? filterOptions.climate : [],
    isMultiSelect: true,
    // isDisabled: disable,
    style: {"marginBottom": "1rem"},

  },
  {
    id: 8,
    label: "Region",
    name: "region",
    dropdownValue: region,
    onDropDownChange: onRegionChange,
    placeHolder: "Select Region",
    optionsArray:filterOptions.region ? filterOptions.region : [],
    isMultiSelect: true,
    hide: shouldHideForCurrentClient("region"),
    // isDisabled: disable,
    style: {"marginBottom": "1rem"},

  },
  {
    id: 5,
    label: "District",
    name: "district",
    dropdownValue: district,
    onDropDownChange: onDistrictChange,
    placeHolder: "Select District",
    optionsArray: filterOptions.district ? filterOptions.district : [],
    isMultiSelect: true,
    hide: shouldHideForCurrentClient("district"),
    style: {"marginBottom": "1rem"},   
    // isDisabled: disable,
  },
  {
    id: 9,
    label: "Size (Sq Ft)",
    name: "size",
    dropdownValue: size,
    onDropDownChange: onSizeChange,
    placeHolder: "Select Size",
    optionsArray: filterOptions.size ? filterOptions.size : [],
    isMultiSelect: true,
    // isDisabled: disable,
  },
  {
    id: 6,
    label: "Name",
    name: "name",
    dropdownValue: name,
    onDropDownChange: onNameChange,
    placeHolder: "Select Name",
    optionsArray:filterOptions.name ? filterOptions.name : [],
    isMultiSelect: true,
    // isDisabled: disable,
  },
  {
    id: 7,
    label: "Store",
    name: "stores",
    dropdownValue: stores,
    onDropDownChange: onStoreChange,
    placeHolder: "Select Store",
    optionsArray:filterOptions.stores ? filterOptions.stores : [],
    isMultiSelect: true,
    // isDisabled: disable,
  },
];

const storeConfigPrep = [
  {
    id: 4,
    label: levelFiltersLabelFormatter("climate"),
    name: "climate",
    dropdownValue: climate,
    onDropDownChange: onClimateChange,
    placeHolder: `Select ${levelFiltersLabelFormatter("climate")}`,
    optionsArray: filterOptions.climate ? filterOptions.climate : [],
    isMultiSelect: true,
    style: {"marginBottom": "1rem"},
  },
  {
    id: 8,
    label: "Region",
    name: "region",
    dropdownValue: region,
    onDropDownChange: onRegionChange,
    placeHolder: "Select Region",
    optionsArray:filterOptions.region ? filterOptions.region : [],
    isMultiSelect: true,
    hide: shouldHideForCurrentClient("region"),
    style: {"marginBottom": "1rem"},

  },
  {
    id: 5,
    label: "District",
    name: "district",
    dropdownValue: district,
    onDropDownChange: onDistrictChange,
    placeHolder: "Select District",
    optionsArray: filterOptions.district ? filterOptions.district : [],
    isMultiSelect: true,
    hide: shouldHideForCurrentClient("district"),
    style: {"marginBottom": "1rem"},
  },
  {
    id: 6,
    label: "Name",
    name: "name",
    dropdownValue: name,
    onDropDownChange: onNameChange,
    placeHolder: "Select Name",
    optionsArray:filterOptions.name ? filterOptions.name : [],
    isMultiSelect: true,
  },
];

return (
  <>
   <Notification />
    <Filter data={props.storeConfig ? storeConfigPrep :prep} />
    <div className="col-md-3 mt-3">
      <button
        onClick={applyFilters}
        className="btn btn-primary filter-constraints-mode"
        title="Apply filters"
        // disabled={disable}
      >
        <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
      </button>
      <button
        onClick={() => resetFilters("All")}
        className="btn undo-constraints-mode"
        title="Reset filters"
        // disabled={disable}
      >
        <i className="fa fa-undo mr-2"></i>Reset
      </button>
    </div>
    {!props.storeConfig && <div className="row section">
        <div className="required fnt-md pl-4" style={{ color: "red" }}>
          <label>Fields are mandatory</label>
        </div>
    </div>}
  </>
);

}

const mapDispatchToProps = (dispatch) => ({
  getStoreFiltersApi: (payload) => dispatch(createStoreGroupFiltersAction(payload)),
});

export default connect(null, mapDispatchToProps)(ClusterFiltersInModal);
