export const FETCH_FILTER_DATA = "@@STORESTATUS/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@STORESTATUS/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@STORESTATUS/FETCH_FILTER_ERROR";

export const FETCH_STORE_STATUS_DATA = "@@STORESTATUS/FETCH_STORE_STATUS_DATA";
export const FETCH_STORE_STATUS_SUCCESS = "@@STORESTATUS/FETCH_STORE_STATUS_SUCCESS";
export const FETCH_STORE_STATUS_ERROR = "@@STORESTATUS/FETCH_STORE_STATUS_ERROR";

export const UPDATE_STORE_STATUS_DATA = "@@STORESTATUS/UPDATE_STORE_STATUS_DATA";
export const UPDATE_STORE_STATUS_SUCCESS = "@@STORESTATUS/UPDATE_STORE_STATUS_SUCCESS";
export const UPDATE_STORE_STATUS_ERROR = "@@STORESTATUS/UPDATE_STORE_STATUS_ERROR";

export const RESET_ALL = "@@STORESTATUS/RESET_ALL";

export const resetAll = () => ({
  type: RESET_ALL,
});


export const fetchFilterData = (payload) => ({
    type: FETCH_FILTER_DATA,
    payload,
  });
  
export const fetchFilterDataSuccesss = (payload) => ({
  type: FETCH_FILTER_SUCCESS,
  payload,
});

export const fetchFilterDataError = (payload) => ({
  type: FETCH_FILTER_ERROR,
  payload,
});

export const fetchStoreStatusData = (payload) => ({
  type: FETCH_STORE_STATUS_DATA,
  payload,
});

export const fetchStoreStatusDataSuccesss = (payload) => ({
  type: FETCH_STORE_STATUS_SUCCESS,
  payload,
});

export const fetchStoreStatusDataError = (payload) => ({
  type: FETCH_STORE_STATUS_ERROR,
  payload,
});

export const updateStoreStatusData = (payload) => ({
  type: UPDATE_STORE_STATUS_DATA,
  payload,
});

export const updateStoreStatusDataSuccesss = (payload) => ({
  type: UPDATE_STORE_STATUS_SUCCESS,
  payload,
});

export const updateStoreStatusDataError = (payload) => ({
  type: UPDATE_STORE_STATUS_ERROR,
  payload,
});