import { debounce } from 'lodash'
import React, { useState } from 'react'
import { useCallback } from 'react'

function ColumnFilterServerSide({
   ...instance
  }) {
    const {
      column : { id },
      placeholder,
      changeHandler,
      searchTermValue,
    } = instance

    console.log(id,'sdcsdds')

    const[filter, setFilterValue] = useState(null) 

    const onClickHandler = () => {
      if(filter || filter?.trim() ? filter?.trim()!= 0 : false)
        changeHandler(filter)
    }

    const debouncedHandleChange = useCallback(debounce((data) => {
      changeHandler(data,id)
    },3000),[])

    const handleChange = (e) => {
        setFilterValue(e.target.value)
        debouncedHandleChange(e.target.value)
    }

    return ( 
        <div>
            <input
                  style={{width:"80%", margin:"4px", borderRadius:"2rem", paddingLeft:'5px', outline:'none', borderColor:'#343a40'}}                
                  value={filter != null ? filter : '' || searchTermValue || ''}
                  onChange={e => {
                      handleChange(e)
                  }}
                  placeholder={placeholder || "Search..."}
            />
            {/* <div onClick={() => onClickHandler()} style={{cursor: 'pointer', color:'black', position:'relative', right:'2rem'}}>
             <i class="fa fa-search" aria-hidden="true"></i>
            </div> */}
        </div> 
      )
}

export default ColumnFilterServerSide
