import React, { useState, useEffect, createRef } from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import './ProductView.css'
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import { editAllocatedUnits, setDcAvailableError, resetEditAllocationError } from '../FinalizeAction'
import { updateArticleName } from "../../../utils/filterLevelMapping"
import { cloneDeep } from "lodash-es";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const EditProductAllocationModal = (props) => {
    const [inputFields, setInputFields] = useState(props.data.sizeData)
    const [isDisabled, setIsDisabled] = useState(true)
    const [RTInstance, setRTInstance] = useState(null);
    const [inputRefs, setInputRefs] = useState([])


    const tableColumns = React.useMemo(() => [
        {
            Header: 'OH',
            width: 200,
            columns: []
        },
        {
            Header: 'DC Available',
            width: 200,
            columns: []
        },
        {
            Header: 'Allocated Units',
            width: 200,
            columns: []
        }
    ])

    useEffect(() => {
        setInputRefs(inputRefs => (
            Array(props.data.sizeData[0].size.length).fill().map((_, i) => inputRefs[i] || createRef())
        ));
    }, [props.data.sizeData.length])

    const handleInputChange = (rowIndex, colId, value, refIndex) => {
        let copyInputData = cloneDeep(inputFields)
        let index = copyInputData[0].size.indexOf(colId)
        let dcAvailableSum = parseInt(copyInputData[0].size_dc_available[index]) + (parseInt(copyInputData[0].size_value[index]) - parseInt(value))

        copyInputData[0].size_value[index] = value > parseInt(copyInputData[0].size_dc_available[index]) + (parseInt(copyInputData[0].size_value[index]))
            ? parseInt(copyInputData[0].size_dc_available[index]) + (parseInt(copyInputData[0].size_value[index]))
            : value
        copyInputData[0].size_dc_available[index] = dcAvailableSum < 0 ? 0 : dcAvailableSum
        setInputFields(copyInputData)
        setTimeout(() => {
            inputRefs[refIndex].current.focus();
        }, 0)
    }

    const setSizeColumns = () => {
        if (inputFields.length) {
            let data = inputFields[0]
            data.size.map((s, index) => {
                tableColumns[0].columns.push({
                    Header: ' ' + s,
                    accessor: row => row.oh_value[index],
                    width: 100,
                })
                tableColumns[1].columns.push({
                    Header: s + ' ',
                    accessor: row => row.size_dc_available[index],
                    width: 100,
                })
                tableColumns[2].columns.push({
                    Header: s,
                    Cell: (instance) => <InputCell {...instance} type="number"
                        changeHandler={(rowIdx, columnId, values) => {
                            handleInputChange(rowIdx, columnId, values, index)
                        }}
                        ref={inputRefs[index]}
                        value={Number(instance?.row?.original?.size_value[index])}
                        max={Number(instance?.row?.original?.size_dc_available[index]) + Number(instance?.row?.original?.size_value[index])}
                        min={0}

                    />,
                    width: 100
                })
            })
            // setTableColumns(columns)
            return true
        }
        return false
    }

    const onSave = () => {
        let data = RTInstance.data[0]
        let units = []

        data.size.map((val, index) => {
            units.push(data[val] ? data[val] : data.size_value[index])
        })

        let req_body = {
            allocation_code: props.data.allocationcode,
            store: props.data.store,
            article: props.data.article,
            size: data.size,
            units,

        }
        props.editAllocatedUnits(req_body)

    }

    const closeAlert = () => {
        props.setDcAvailableError(false)
        props.closeAlert()
    }

    useEffect(() => {
        if (!props.editAllocatedUnitError || !props.dcAvailableError) {
            props.resetEditAllocationError()
        }
    }, [props.editAllocatedUnitError, props.dcAvailableError])

    // useEffect(() => {
    //     if (props.dcAvailableError) {
    //         Notify.error("Please enter the units lesser than dc availability !")
    //     }

    // }, [props.dcAvailableError])

    useEffect(() => {
        if (props.editAllocatedUnitSuccess) {
            props.setDcAvailableError(false)
            props.closeAlert(true);
            Notify.success("Allocated Units Edited Sucessfully!!")
        }
    }, [props.editAllocatedUnitSuccess])

    useEffect(() => {
        if (props.editAllocatedUnitError) {
            props.setDcAvailableError(false)
            Notify.error("Allocated Units Became greater than available inventory")
        }
    }, [props.editAllocatedUnitError])

    return (
        setSizeColumns() &&
        <Modal
            visible={props.showAlert}
            width="80%"
            height="50%"
            effect="fadeInDown"
            onClickAway={closeAlert}
            alignItems="flexStart"
        >
            <Notification />
            <div className="modal-container">
                <div className="modal-header">
                    <h5 className="page-heading text-center m-0">
                        Edit Allocated Units
                    </h5>
                    <button type="button" className="close" data-dismiss="modal">
                        <span style={{ cursor: "pointer" }}>
                            <i
                                className="fa fa-times text-right m-2 "
                                aria-hidden="true"
                                onClick={closeAlert}
                            />
                        </span>
                    </button>
                </div>
                <div className="modal-middle-container">
                    <div className="mx-2 pr-4 pl-4 row justify-content-center mb-4">
                        <div className="detail__div mr-4">
                            <span className="fnt-bold mr-2">Store :</span>
                            <span>{props.data.store}</span>
                        </div>

                        <div className="detail__div mr-4">
                            <span className="fnt-bold mr-2">{updateArticleName(false)} : </span>
                            <span>{props.data.article}</span>
                        </div>

                        <div className="detail__div mr-4">
                            <span className="fnt-bold mr-2">Color : </span>
                            <span>{props.data.color}</span>
                        </div>
                    </div>

                    <div className="mx-2 p-2 row justify-content-center">
                        <ReactTableWithPlugins
                            hideColumnsFilter
                            getInstanceOnMount={(instance) => {
                                setRTInstance(instance)
                            }}
                            style={{maxWidth:'100%', margin:'auto'}}
                            columns={tableColumns}
                            data={inputFields}
                            renderMarkup='TableMarkup'
                            setIsDisabled={setIsDisabled}
                            tableId="edit_allocation"
                        />
                    </div>
                </div>
                <div className="modal-footer" style={{ padding: "10px" }}>
                    <div className="center">
                        <button
                            onClick={onSave}
                            className="btn btn-primary"
                        // disabled={isDisabled}
                        >
                            Save
                        </button>

                    </div>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = ({ finalize }) => {
    return {
        editAllocatedUnitSuccess: finalize.editAllocatedUnitSuccess,
        editAllocatedUnitError: finalize.editAllocatedUnitError,
        dcAvailableError: finalize.dcAvailableError
    }
}

const mapDispatchToProps = (dispatch) => ({
    editAllocatedUnits: (reqBody) => dispatch(editAllocatedUnits(reqBody)),
    setDcAvailableError: (payload) => dispatch(setDcAvailableError(payload)),
    resetEditAllocationError: () => dispatch(resetEditAllocationError())
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProductAllocationModal)