import { intersectionWith, isEqual } from 'lodash'
import { useState } from 'react'
import { handleSelectAll } from '../utils/SelectAllHandler/SelectAllHander'

function useSelectFilters(fetchFilterData, dynamicHeaderForTable = false, level2Options, level3Options, level4Options, setlevel2Options, setlevel3Options, setlevel4Options, dcOptions, setDcOptions,
  level5Options, setlevel5Options) {
  const [level1, setLevel1] = useState(null)
  const [level2, setLevel2] = useState(null)
  const [level3, setLevel3] = useState(null)
  const [level4, setLevel4] = useState(null)
  const [level5, setLevel5] = useState(null)
  const [request, setRequest] = useState(null)
  const [dynamicHeader, setDynamicHeader] = useState('DCS')
  const [dc, setDc] = useState(null);

  const resetRestFilters = (p_index) => {
    if (p_index == 0) {
      level2 && setLevel2(null);
      level2Options && setlevel2Options(null)
    }
    if (p_index == 1 || p_index == 0) {
      level3 && setLevel3(null);
      level3Options && setlevel3Options(null)
    }
    if (p_index == 1 || p_index == 0 || p_index === 2) {
      level4 && setLevel4(null)
      level4Options && setlevel4Options(null)
    }
    if (p_index == 1 || p_index == 0 || p_index === 2 || p_index === 3) {
      level5 && setLevel5(null)
      level5Options && setlevel5Options(null)
    }
  }

  const onLevel1Change = (val) => {
    if (dynamicHeaderForTable) {
      // if(val.value === "Apparel"){
      //     setDynamicHeader('DCS')
      // }
      // else{
      setDynamicHeader('DCS')
      // }
    }
    setLevel1(val)
    resetRestFilters(0)
    fetchFilterData({ level1: [val] }, { key: "l2" })
    setRequest(old => {
      return {
        ...old,
        'level2': null,
        'level3': null,
        'level4': null,
        "level5": null
      }
    })
  }
  const onLevel2Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that)
      let req_val = intersectionWith(valuesArray[0], level2Options, isEqual);
      setLevel2(valuesArray[1])
      fetchFilterData({ level1: [level1], level2: [req_val] }, { key: "l3" })
      setRequest(old => {
        return {
          ...old,
          'level2': valuesArray[0],
          'level3': null,
          'level4': null,
          'level5': null,
        }
      })
    }
    else {
      setLevel2(val)
      fetchFilterData({ level1: [level1], level2: [val] }, { key: "l3" })
      setRequest(old => {
        return {
          ...old,
          'level2': val,
          'level3': null,
          'level4': null,
          'level5': null,
        }
      })
    }
    resetRestFilters(1)
    //API call to get next filter  
  }
  const onLevel3Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that)
      let req_val = intersectionWith(valuesArray[0], level3Options, isEqual);
      // let value = intersectionWith(val, level3Options, isEqual);
      setLevel3(valuesArray[1])
      fetchFilterData({ level1: [level1], level2: [request?.level2], level3: req_val }, { key: "l4" })
      setRequest(old => {
        return {
          ...old,
          'level3': valuesArray[0],
          'level4': null,
          'level5': null,
        }
      })
    }
    else {
      setLevel3(val)
      fetchFilterData({ level1: [level1], level2: [level2], level3: [val] }, { key: "l4" })
    }
    resetRestFilters(2)

  }

  const onLevel4Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that)
      let req_val = intersectionWith(valuesArray[0], level4Options, isEqual);
      // let value = intersectionWith(val, level4Options, isEqual);
      setLevel4(valuesArray[1])
      // fetchFilterData({ level1: [level1], level2: [request?.level2], level3: request?.level3, level4: req_val }, { key: "l6" })
      setRequest(old => {
        return {
          ...old,
          'level4': valuesArray[0],
          'level5': null,
        }
      })
    }
    else {
      setLevel4(val)
    //   fetchFilterData({ level1: [level1], level2: [level2], level3: [level3], level4: [val] }, { key: "l6" })
    }
    resetRestFilters(3);
  }

  const onLevel5Change = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that)
      // let req_val = intersectionWith(valuesArray[0], level4Options, isEqual);
      // let value = intersectionWith(val, level4Options, isEqual);
      setLevel5(valuesArray[1])
      setRequest(old => {
        return {
          ...old,
          'level5': valuesArray[0],
        }
      })
    }
    else {
      setLevel5(val)
    }
  }

  const onDcChange = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      setDc(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'dc': valuesArray[0]
        }
      })
    }
    else {
      setDc(val);
    }
  }

  const resestRequest = () => {
    setRequest(null)
  }

  return [level1, level2, level3, level4, setLevel1, setLevel2, setLevel3, setLevel4,
    onLevel1Change, onLevel2Change, onLevel3Change, onLevel4Change, dynamicHeader, request,
    resestRequest, dc, setDc, onDcChange, level5, setLevel5, onLevel5Change]
}

export default useSelectFilters
