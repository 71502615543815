export const GET_REPORT = "@@reporting/GET_REPORT";
export const GET_REPORT_SUCCESS = "@@reporting/GET_REPORT_SUCCESS";
export const GET_REPORT_ERROR = "@@reporting/GET_REPORT_ERROR";

export const GET_POPUP = "@@reporting/GET_POPUP";
export const GET_POPUP_SUCCESS = "@@reporting/GET_POPUP_SUCCESS";
export const GET_POPUP_ERROR = "@@reporting/GET_POPUP_ERROR";

export const FETCH_FILTER_DATA = "@@reporting/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@reporting/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@reporting/FETCH_FILTER_ERROR";

export const RESET_FILTERS_DATA = "@@reporting/RESET_FILTERS_DATA";
export const RESET_ALL = "@@reporting/RESET_ALL";


export const getReport = (payload) => ({
    type: GET_REPORT,
    payload,
});

export const getReportingPopupData = (payload) => ({
  type: GET_POPUP,
  payload,
})

export const getReportingPopupDataSuccess = (payload) => ({
  type: GET_POPUP_SUCCESS,
  payload,
})

export const getReportingPopupDataError = (payload) => ({
  type: GET_POPUP_ERROR,
  payload,
})

export const getReportSuccess = (payload) => ({
    type: GET_REPORT_SUCCESS,
    payload,
});

export const getReportError = (payload) => ({
    type: GET_REPORT_ERROR,
    payload,
});

export const fetchFilterData = (payload, filterKey) => ({
    type: FETCH_FILTER_DATA,
    payload,
    filterKey
  });
  
  export const fetchFilterDataSuccesss = (payload) => ({
    type: FETCH_FILTER_SUCCESS,
    payload,
  });
  
  export const fetchFilterDataError = (payload) => ({
    type: FETCH_FILTER_ERROR,
    payload,
  });

export const resetFiltersOptions = (payload) => ({
    type: RESET_FILTERS_DATA,
    payload,
  })
  
export const resetAll = () => ({
    type: RESET_ALL,
});