import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { HIERARCHY_ID_HEADER } from './AdaVisualConstants';
import "./TableStyle.css";

/**
 * Hierarchy Table View
 * @param {article level data} props 
 * @returns 
 */
const HierarchyTable = (props) => {
    const { data, handleHierarchyChange, currentWeek } = props;
    const tableData = data ? data.reduce((acc, obj) => {
        const key = obj['article'];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {}) : null;

    const tableHeader = data ? Object.keys(data?.reduce((acc, obj) => {
        const key = obj['fw'];
        if (!acc[key]) {
            acc[key] = [];
        }
        return acc;
    }, {})) : null;

    return (
        data && data.length ?
            <div style={{ padding: "2px" }}>
                <table className="hierarchy-table table-set">
                    <thead className="sticky-header">
                        <tr>
                            <th className="th-set sticky-col" key={0}>{HIERARCHY_ID_HEADER}</th>
                            {tableHeader.map((week, index) => (
                                <th className="th-set" scope="col" key={index + 1}>{`W${week}`}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(tableData).map((k, rowIndex) => (
                            <tr className="tr-set" key={rowIndex}>
                                <td className="td-set sticky-col" key={0}>{k}</td>
                                {tableData[k].map((item, i) => (
                                    item.fy_fw < currentWeek ?
                                        <td className="td-set data_disabled" key={i + 1}>
                                            <InputNumber
                                                value={item['adjusted_forecast']}
                                                key={i + 1}
                                                name={`${item.fy_fm}-${item.fy_fw}`}
                                                id={item.article}
                                                onBlur={handleHierarchyChange}
                                                className="span-data"
                                                format={false}
                                                disabled
                                            />
                                        </td>
                                        :
                                        <td className="td-set data_input" key={i + 1}>
                                            <InputNumber
                                                value={item['adjusted_forecast']}
                                                key={i + 1}
                                                name={`${item.fy_fm}-${item.fy_fw}`}
                                                id={item.article}
                                                onBlur={handleHierarchyChange}
                                                className="edit-span"
                                                format={false}
                                            />
                                        </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            : null
    );
};

export default HierarchyTable;