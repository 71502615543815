import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import {
  FETCH_FILTER_SUCCESS,
  GET_CHART_DATA_ERROR,
  GET_CHART_DATA_SUCCESS,
  RESET_FILTERS_DATA,
  CLEAR_CHART_DATA,
  GET_STYLE_INV_POPUP_DATA_SUCCESS,
  GET_STYLE_INV_POPUP_DATA_ERROR,
  GET_REVIEW_RECOMMEND_DATA_PROGRESS,
  GET_REVIEW_RECOMMEND_DATA_SUCCESS,
  GET_REVIEW_RECOMMEND_DATA_ERROR,
  GET_CHART_DATA,
  FETCH_FILTER_ERROR,
  FETCH_FILTER_DATA,
  GET_TRANSACTION_DATA,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_ERROR,
  GET_RECOMMENDED_ACTIONS_DATA,
  GET_RECOMMENDED_ACTIONS_DATA_SUCCESS,
  GET_RECOMMENDED_ACTIONS_DATA_ERROR,
} from "./DashboardActions";

export const dashboardState = {
  weekSupply: null,
  storeStock: null,
  overallStock: null,
  pieLegends: null,
  styleInvData: null,
  recommendedData: null,
  reviewRecommendedData: null,
  styleInvPopupData: null,
  error: null,
  level1Options: [],
  level2Options: [],
  level3Options: [],
  level4Options: [],
  level5Options: [],
  dcOptions: [],
  filterlabels: {},
  loading: false,
  recommendedActionsloading: false,
  recommendedActionserror: null,
  refreshDetails : null,
};

const reducer = {
  dashboard: handleActions(
    {
      [FETCH_FILTER_DATA]: (state, { payload }) => {
        return immutable(state, {
          error: { $set: null },
        });
      },
      [FETCH_FILTER_ERROR]: (state, { payload }) => {
        return immutable(state, {
          // error: { $set: (payload && payload.error) || null },
        });
      },
      [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
        switch (payload.key) {
          case "l1":
            return immutable(state, {
              level1Options: {
                $set:
                  payload && payload.data.level1Options
                    ? payload.data.level1Options
                    : state.level1Options,
              },
              level2Options: [],
              level3Options: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l2":
            return immutable(state, {
              level2Options: {
                $set:
                  payload && payload.data.level2Options
                    ? payload.data.level2Options
                    : state.level2Options,
              },
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l3":
            return immutable(state, {
              level3Options: {
                $set:
                  payload && payload.data.level3Options
                    ? payload.data.level3Options
                    : state.level3Options,
              },
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "l4":
            return immutable(state, {
              level4Options: {
                $set:
                  payload && payload.data.level4Options
                    ? payload.data.level4Options
                    : state.level4Options,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
            case "l6":
            return immutable(state, {
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          case "dc":
            return immutable(state, {
              dcOptions: {
                $set:
                  payload && payload.data.data
                    ? payload.data.data
                    : state.dcOptions,
              },

              // subdepartments: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
          default:
            return immutable(state, {
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping,
              },
            });
        };
      },
      [CLEAR_CHART_DATA]: (state, { payload }) => {
        return immutable(state, {
          weekSupply: { $set: null },
          storeStock: { $set: null },
          overallStock: { $set: null },
          pieLegends: { $set: null },
          styleInvData: { $set: null },
          recommendedData: { $set: null },
          reviewRecommendedData: { $set: null },
          styleInvPopupData: { $set: null },
          error: { $set: null },
          level1Options: { $set: [] },
          level2Options: { $set: [] },
          level3Options: { $set: [] },
          level4Options: { $set: [] },
          level5Options: { $set: [] },
          dcOptions: { $set: [] },
          filterlabels: { $set: {} },
          loading: { $set: false },
          recommendedActionsloading: { $set: false },
          recommendedActionserror: { $set: null },
          refreshDetails : { $set: null}
        });
      },
      [RESET_FILTERS_DATA]: (state, { payload }) => {
        return immutable(state, {
          level2Options: { $set: [] },
          level3Options: { $set: [] },
          level4Options: { $set: [] },
          level5Options: { $set: [] },
          // dcOptions: { $set: [] },
        });
      },
      [GET_CHART_DATA]: (state, { }) => {
        return immutable(state, {
          loading: { $set: true },
          error: { $set: null },
        })
      },
      [GET_CHART_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          weekSupply: { $set: (payload && payload.weekSupply) || null },
          storeStock: { $set: (payload && payload.storeStock) || null },
          overallStock: { $set: (payload && payload.overallStock) || null },
          pieLegends: { $set: (payload && payload.pieLegends) || null },
          styleInvData: {
            $set: (payload && payload.styleInventoryData) || null,
          },
          // recommendedData: {
          //   $set: (payload && payload.recommendedData) || null,
          // },
          error: { $set: null },
          loading: { $set: false },
        });
      },
      [GET_CHART_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          weekSupply: { $set: null },
          storeStock: { $set: null },
          overallStock: { $set: null },
          styleInvData: { $set: null },
          // recommendedData: { $set: null },
          error: { $set: (payload && payload.error) || null },
          loading: { $set: false },
        });
      },
      [GET_RECOMMENDED_ACTIONS_DATA]: (state, { }) => {
        return immutable(state, {
          recommendedActionsloading: { $set: true },
          recommendedActionserror: { $set: null },
        })
      },
      [GET_RECOMMENDED_ACTIONS_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          recommendedData: {
            $set: (payload && payload.recommendedData) || null,
          },
          recommendedActionserror: { $set: null },
          recommendedActionsloading: { $set: false },
        });
      },
      [GET_RECOMMENDED_ACTIONS_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          recommendedData: { $set: null },
          recommendedActionserror: { $set: (payload && payload.error) || null },
          recommendedActionsloading: { $set: false },
        });
      },
      [GET_TRANSACTION_DATA]: (state, { }) => {
        return immutable(state, {
          refreshDetails : { $set: null}
        })
      },
      [GET_TRANSACTION_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          refreshDetails : { $set: (payload && payload.data) || null}
        });
      },
      [GET_TRANSACTION_ERROR]: (state, { payload }) => {
        return immutable(state, {
          refreshDetails : { $set: null}
        });
      },
      [GET_STYLE_INV_POPUP_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          styleInvPopupData: { $set: (payload && payload.data) || null },
          error: { $set: null },
        });
      },
      [GET_STYLE_INV_POPUP_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          styleInvPopupData: { $set: null },
          error: { $set: (payload && payload.error) || null },
        });
      },
      [GET_REVIEW_RECOMMEND_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          reviewRecommendedData: { $set: (payload && payload.data) || null },
          error: { $set: null },
        });
      },
      [GET_REVIEW_RECOMMEND_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          reviewRecommendedData: { $set: null },
          error: { $set: (payload && payload.error) || null },
        });
      },
      [GET_REVIEW_RECOMMEND_DATA_PROGRESS]: (state, { payload }) => {
        return immutable(state, {
          reviewRecommendedData: { $set: null },
          error: { $set: null },
        });
      },
    },
    dashboardState
  ),
};

export default reducer;
