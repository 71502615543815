import React from "react";
import MultiSelect from "../../../components/Filters/MultiSelect";
import Select from "../../../components/Filters/SingleSelect";

function Filter(props) {
  return props.data.map((val) => (
    !val.hide &&
    <div
      className={val.isRequired ? val.className ? val.className : "required col-md-3" : val.className ? val.className : "fill col-md-3"}
      key={val.id}
      style={val?.style}
    >
      <label className={"fnt-md fnt-bold fnt-bold " + (val.labelClass ? val.labelClass : "")}>{val.label}</label>
      { val.input ? (val.Comp ? val.Comp : <input {...val}/>) :(!val.isMultiSelect ? <Select {...val} /> : <MultiSelect {...val} />)}
    </div>
  ));
}

export default Filter;
