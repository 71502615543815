import { isEmpty } from "lodash";
import { takeLatest, takeEvery, put, all, call } from "redux-saga/effects";
import {
    FETCH_FILTER_DATA,
    GET_REPORT,
    GET_POPUP,
    fetchFilterDataSuccesss,
    fetchFilterDataError,
    getReportSuccess,
    getReportError,
    getReportingPopupDataSuccess,
    getReportingPopupDataError,
} from './ReportingAction'
import { getReportingFilters, getReporting } from "../../routes/api";

function* fetchFilterDataWorker(action) {
    try {
        let storeGradeSource = null;
        const { payload, filterKey } = action;
        let req = {};
        if (!isEmpty(payload)) {
            for (const key in payload) {
                if (key == "store_grade_source") {
                    storeGradeSource = payload[key] == "Department" ? null : payload[key]
                }
                else {
                    req[key] = payload[key]?.map((ele) => ele && ele.value)
                }
            }
        }
        const res = yield call(getReportingFilters, req);
        if (res.data.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping
            // let topObject = Object.keys(res.data.filterData[0])[0]
            for (const key in res.data.filterData[0]) {
                let k = "";
                if (key === "l1_name") {
                    k = "departmentOptions";
                } else if (key === "l2_name") {
                    k = "genderOptions";
                } else if (key === "l3_name") {
                    k = "rbuOptions";
                } else if (key === "l4_name") {
                    k = "dcsOptions";
                } else if (key === "l5_name") {
                    k = "level5Options";
                } else if (key === "article_status") {
                    k = "articleStatusOptions";
                } else if (key === "store_id") {
                    k = "storeOptions";
                } else if (key === "store_grade") {
                    k = "storeGradeOptions";
                } else if (key === "region") {
                    k = "regionOptions";
                } else if (key === "climate") {
                    k = "climateOptions";
                } else if (key === "store_status") {
                    k = "storeStatusOptions";
                } else if (key === "article_id") {
                    k = "articleIdOptions"
                } else if (key === "store_type") {
                    k = "channelOptions"
                }

                data[k] = res.data.filterData[0][key == "store_grade" && storeGradeSource ? storeGradeSource : key]?.filter(val => val)?.map((element) => ({
                    value: element,
                    label: element,
                }));
            }
            yield put(fetchFilterDataSuccesss({ data: data, key: filterKey }));
        }
    } catch (error) {
        yield put(fetchFilterDataError({ error: "Something went wrong!" }));
    }
}

function* getReportWorker(action) {
    try {
        const { payload } = action
        const filters = payload.filters
        let req = {}
        for (const key in filters) {
            if (filters[key]) {
                req[key] = filters[key]?.map((ele) => ele.value)
            }
        }
        req["rowIndex"] = payload.rowIndex
        req["rowCount"] = payload.rowCount
        if (payload.clearance != null) {
            req["clearance"] = [payload.clearance]
        }
        if (!isEmpty(payload?.searchTermReq)) {
            req["searchColumns"] = { ...payload?.searchTermReq }
        }
        if (!isEmpty(payload?.sortReq)) {
            req["sortColumn"] = { ...payload?.sortReq[0] }
        }
        const res = yield call(getReporting, req)
        if (res.data.status) {
            let data = res.data.reportingData
            const { dcs } = data[0]

            data.map(item => {
                dcs?.forEach(dcName => {
                    item[dcName] = item.dcs_value[dcName]
                })
            })
            // data.map(report => {
            //     report.clearance = report.clearance ? "Yes": "No"
            // })
            yield put(getReportSuccess({ data, totalCount: res.data.totalCount, nextIndex: payload.rowIndex + 100 }))
        }
        else {
            yield put(getReportError({ 'error': res.message }));
        }

    } catch (error) {
        yield put(getReportError(error));
    }
}

function* getReportingPopUpWorker(action) {
    try {
        const { payload } = action
        const filters = payload.filters
        let req = {}
        for (const key in filters) {
            if (filters[key]) {
                req[key] = filters[key].map((ele) => ele.value)
            }
        }
        if (payload.clearance != null) {
            req["clearance"] = payload.clearance
        }
        let request = { ...req, articleid: [payload?.row?.articleid], store_id: [payload?.row?.storeid] }
        const response = yield call(getReporting, request)
        if (response.data.status) {
            let retailSize = response?.data?.reportingData?.[0]?.retail_size
            let res = response?.data?.reportingData?.[0]
            let sizeColumns = []
            let data = {}
            for (let i in retailSize) {
                if (payload.accessor === "total_inventory") {
                    try {
                        let total_inventory = Number(res['oh_upc'][i]) + Number(res['oo_upc'][i]) + Number(res['it_upc'][i])
                        data[retailSize[i]] = total_inventory
                    }
                    catch {
                        data[retailSize[i]] = 0
                    }
                }
                else if (payload.accessor === "bulk_remaining") {
                    try {
                        let bulk_remaining = 0
                        // let bulk_remaining = Number(res['in07_upc'][i]) + Number(res['pna17_upc'][i]) + Number(res['in01_upc'][i]) + Number(res['ul01_upc'][i]) + Number(res['pna27_upc'][i]) + Number(res['pna31_upc'][i])
                        for (const [key, value] of Object.entries(res["dcs_upc_value"])) {
                            bulk_remaining += value[i]
                        }
                        data[retailSize[i]] = bulk_remaining
                    }
                    catch(e) {
                        data[retailSize[i]] = 0
                    }
                }
                else if (payload.accessor === "oo_upc" || payload.accessor === "oh_upc" || payload.accessor === "it_upc")
                    data[retailSize[i]] = res[payload.accessor][i] ? res[payload.accessor][i] : 0
                else
                    data[retailSize[i]] = res["dcs_upc_value"][payload.accessor][i] ? res["dcs_upc_value"][payload.accessor][i] : 0
            }
            for (let val of retailSize) {
                sizeColumns.push({
                    Header: val,
                    accessor: (data) => data[val],
                })
            }
            yield put(getReportingPopupDataSuccess({ popupData: [data], popupColumn: sizeColumns }))
        }
        else {
            yield put(getReportingPopupDataError({ 'error': 'Something Went Wrong!!' }));
        }

    } catch (error) {
        yield put(getReportingPopupDataError(error));
    }


    // try {
    //     const { payload } = action
    //     const res = payload?.row
    //     if (!isEmpty(res)) {
    //         let retailSize = res?.retail_size
    //         let sizeColumns = []
    //         let data = {}
    //         for(let i in retailSize){
    //             if(payload.accessor === "total_inventory"){
    //                 try{
    //                     let total_inventory = Number(res['oh_upc'][i]) + Number(res['oo_upc'][i]) + Number(res['it_upc'][i])
    //                     data[retailSize[i]] = total_inventory
    //                 }
    //                 catch{
    //                     data[retailSize[i]] = 0
    //                 }
    //             }
    //             else if(payload.accessor === "bulk_remaining"){ 
    //                 try{
    //                     let bulk_remaining = Number(res['in07_upc'][i]) + Number(res['pna17_upc'][i]) + Number(res['in01_upc'][i]) + Number(res['ul01_upc'][i]) + Number(res['pna27_upc'][i]) + Number(res['pna31_upc'][i])
    //                     data[retailSize[i]] = bulk_remaining
    //                 }
    //                 catch{
    //                     data[retailSize[i]] = 0
    //                 }
    //             }
    //             else
    //                 data[retailSize[i]] = res[payload.accessor][i] ? res[payload.accessor][i] : 0
    //         }
    //         for (let val of retailSize) {
    //             sizeColumns.push({
    //               Header: val,
    //               accessor: (data) => data[val],
    //             })
    //         }
    //         yield put(getReportingPopupDataSuccess({ popupData: [data], popupColumn: sizeColumns }))
    //     }
    //     else {
    //         yield put(getReportingPopupDataError({ 'error': 'Something Went Wrong!!' }));
    //     }

    // } catch (error) {
    //     yield put(getReportingPopupDataError(error));
    // }
}

function* fetchFilterDataWatcher() {
    yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* getReportWatcher() {
    yield takeLatest(GET_REPORT, getReportWorker);
}

function* getReportingPopUpWatcher() {
    yield takeEvery(GET_POPUP, getReportingPopUpWorker)
}

export function* reportingSaga() {
    yield all([
        getReportWatcher(),
        fetchFilterDataWatcher(),
        getReportingPopUpWatcher()
    ])
}