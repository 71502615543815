/**
 * Function returns evaluated weekly graph data
 * @param {adaVisualData} data 
 * @param {currentFiscalYearWeek} current_fw 
 * @returns Week Level Graph Data
 */
export const getWeeklyGraphData = (data, current_fw) => {
    const adaGraphData = {
        labels_data: [],
        actual_data: [],
        ia_data: [],
        adjusted_data: [],
    };

    data.forEach((item) => {
        adaGraphData.labels_data.push(`W${item.week}`);
        adaGraphData.ia_data.push(item.ia_forecast);
        if (item.fy_fw <= current_fw) {
            adaGraphData.actual_data.push(item.actual_sales);
            item.fy_fw == current_fw ?
                adaGraphData.adjusted_data.push(item.adjusted_forecast)
                : adaGraphData.adjusted_data.push(undefined);
        } else {
            adaGraphData.adjusted_data.push(item.adjusted_forecast);
        }
    });

    return adaGraphData;
};

/**
 * Function returns list of Edited Ada Data
 * @param {*} weeklyAda 
 * @param {*} weeklyAdaCopy 
 * @param {*} current_fw 
 * @returns 
 */
export const getEditedWeeklyAdaData = (weeklyAda, weeklyAdaCopy, current_fw) =>
    weeklyAda.filter((data, index) => data.fy_fw >= current_fw &&
        (data.adjusted_forecast !== weeklyAdaCopy[index].adjusted_forecast || data.forecast_multiplier !== weeklyAdaCopy[index].forecast_multiplier));

/**
 * Function returns object list of edited hierarchy data
 * @param {*} weeklyHierarchy 
 * @param {*} weeklyHierarchyCopy 
 * @param {*} current_fw 
 * @returns 
 */
export const getEditedWeeklyHierarchyData = (weeklyHierarchy, weeklyHierarchyCopy, current_fw) => {
    const editedList = weeklyHierarchy.filter((data, index) =>
        data.fy_fw >= current_fw && data.adjusted_forecast !== weeklyHierarchyCopy[index].adjusted_forecast);
    let articleValues = {};

    editedList.forEach(i => {
        const { article, fy_fw, fy_fm, adjusted_forecast } = i;
        if (articleValues[article]) {
            articleValues = {
                ...articleValues,
                [article]: [
                    ...articleValues[article],
                    {
                        "fy_fm": fy_fm,
                        "fy_fw": fy_fw,
                        "input": adjusted_forecast,
                    },
                ],
            };
        }
        else {
            articleValues = {
                ...articleValues,
                [article]: [
                    {
                        "fy_fm": fy_fm,
                        "fy_fw": fy_fw,
                        "input": adjusted_forecast,
                    },
                ],
            };
        }
    });

    return articleValues;
};

/**
 * Function returns evaluated month ada data
 * @param {adaVisual} data 
 * @returns adaDataMonthlyLevel
 */
export const getMonthlyAdaData = (data) => {
    let monthlyGroupedData = data.reduce((acc, obj) => {
        const key = obj['month'];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});

    let adaDataMonth = [];

    Object.keys(monthlyGroupedData).forEach((m) => {
        adaDataMonth.push({
            adjusted_forecast: monthlyGroupedData[m].reduce((acc, obj) => acc + obj['adjusted_forecast'], 0),
            forecast_multiplier: monthlyGroupedData[m].reduce((acc, obj) => acc + obj['forecast_multiplier'], 0),
            actual_sales: monthlyGroupedData[m].reduce((acc, obj) => acc + obj['actual_sales'], 0),
            ia_forecast: monthlyGroupedData[m].reduce((acc, obj) => acc + obj['ia_forecast'], 0),
            fy_fw: monthlyGroupedData[m].map(week => week['fy_fw']),
            fy_fm: monthlyGroupedData[m][0].fy_fm,
            month: monthlyGroupedData[m][0].month,
            year: monthlyGroupedData[m][0].year,
        });
    });

    return adaDataMonth;
};

/**
 * Function returns evaluated monnthly graph data
 * @param {adaVisualData} data 
 * @param {currentFiscalYearWeek} current_fw 
 * @returns Month Level Graph Data
 */
export const getMonthlyGraphData = (data, current_fw) => {
    const adaGraphData = {
        labels_data: [],
        actual_data: [],
        ia_data: [],
        adjusted_data: [],
    };

    data.forEach((item) => {
        adaGraphData.labels_data.push(`W${item.month}`);
        adaGraphData.ia_data.push(item.ia_forecast);
        if (item.fy_fm <= current_fw) {
            adaGraphData.actual_data.push(item.actual_sales);
            item.fy_fw == current_fw ?
                adaGraphData.adjusted_data.push(item.adjusted_forecast)
                : adaGraphData.adjusted_data.push(undefined);
        } else {
            adaGraphData.adjusted_data.push(item.adjusted_forecast);
        }
    });

    return adaGraphData;
};