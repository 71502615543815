import React, { useEffect, useState } from "react";
import "./Finalize.css";
import { connect } from "react-redux";
import { Tab, Tabs } from 'react-bootstrap';
import PageLoader from '../../components/Loader/PageLoader';
import { finalizeAllocation, downloadExcel, downloadXml, resetAll, updateReserveQuantity, resetFinaliseData, downloadCsv, uploadFile, 
  updateFinalizeInfo, disableEdit, getPastAllocationPO } from './FinalizeAction'
import StoreView from './StoreView/StoreView'
import ProductView from './ProductView/ProductView'
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications";
import XML from 'xml-js'
import XLSX from 'xlsx'
import { saveFile } from '../../utils/fileSave'
import { cond, isEmpty } from "lodash";
import { clientEnvId, isAshley } from "../../utils/commonUtilities"
import { CSVLink } from "react-csv";
import ExportFile from '../../components/ExportFile/ExportFile'
import ConfirmationAlert from "../../components/ConfirmationAlert/ConfirmationAlert";
import axios from "axios";


const Finalize = (props) => {
  let csvRef;
  const [activeTab, setActiveTab] = useState("fpv")
  const [planDetails, setPlanDetails] = useState(props.planDetails ? props.planDetails : props.history?.location?.state?.planDetails)
  const [viewAllocationCall, setViewAllocationCall] = useState(props.planDetails?.plan_id ? true : false)
  const [allocationCode, setAllocationCode] = useState(props.planDetails?.plan_id ? props.planDetails?.plan_id : (props.history?.location?.state?.planDetails?.allocationCode))
  const [originalAllocationCode, setOriginalAllocationCode] = useState((props.planDetails?.allocationCode ? props.planDetails?.alloacationCode : (props.history?.location?.state?.planDetails?.allocationCode)))

  // const [allocationName, setAllocationName] = useState((props.planDetails?.plan_id ? props.planDetails?.allocationName : (props.history?.location?.state?.allocationName)))
  const [articles, setArticles] = useState([])
  const [downloadLoaderText, setDownloadLoaderText] = useState(null)
  const [finalized, setFinalized] = useState(false)
  const [retry, setRetry] = useState(false)
  const [tableInstance, setTableInstance] = useState(null)
  const [isPOASNFlow, setIsPOASNFlow] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [isFinalizeBtnClicked, setFinalizeBtn] = useState(false)

  const handleTabSelect = (f_tab) => {
    if (!viewAllocationCall) {
      props.resetAll()
    }
    setActiveTab(f_tab)
  }

  useEffect(() => {
    if(viewAllocationCall && !props.retransitButton) {
      let req = {
        allocationcode: allocationCode, 
        articles: planDetails?.articles,
        rerelease_flag: props.retransitButton ? props.retransitButton : false
      }
      props.getPastAllocationPO(req)
    }
    window.scrollTo(0, 0)
    if(props?.location?.state?.isPOASNFlow) {
      // setIsPOASNFlow(true)
      // setArticles(props?.location?.state?.articles)
    }
  }, [])

  useEffect(() => {
    if(props.alloacationCode) {
      setIsUpdated(true)
      setAllocationCode(props.alloacationCode)
    }
  },[props.alloacationCode])

  useEffect(() => {
    setFinalized(false)
    setRetry(false)
    return () => {
      props.resetAll()
      props.resetFinaliseData()
    }
  }, [])

  const downloadXml = () => {
    props.downloadXml(props.finalizeData)
  }

  const downloadExcel = () => {
    props.downloadExcel(props.finalizeData)
  }

  const downloadCsv = () => {
    props.downloadCsv(props.finalizeData)
  }
  
  const finalizeAllocation = () => {
    // props.finalizeAllocation({
    //   allocation_code: originalAllocationCode, data: tableInstance?.data ? tableInstance?.data : tableInstance,
    //   retail_pro_id: localStorage?.getItem("retail_pro_id"),
    //   articles: props?.location?.state?.articles
    // })
    setFinalizeBtn(true)
  }

  const preFinalizeCheck = () => {
    if(isUpdated) {
      props.disableEdit({
        allocation_code: allocationCode,
        original_allocation_code: originalAllocationCode
      })
    }
    else {
      finalizeAllocation();
    }
  } 

  useEffect(() => {
    const source = axios.CancelToken.source();
    if(isFinalizeBtnClicked) {
      props.finalizeAllocation({
        allocation_code: originalAllocationCode, data: tableInstance?.data ? tableInstance?.data : tableInstance,
        retail_pro_id: localStorage?.getItem("retail_pro_id"),
        articles:  props?.location?.state?.planDetails?.articles
      }, source)
      }
    return () => {
      if (source) {
        source.cancel("Landing Component got unmounted");
      }
  }
  }, [isFinalizeBtnClicked])


  const retryFileUpload = () => {
    props.uploadFile(props.finalizeData)
  }

  useEffect(() => {
    let req = {
      "allocation_code": originalAllocationCode,
      "user_id": localStorage.getItem("user_id"),
      "user_email": localStorage?.getItem("email"),
    }
    if (!props.allocationStatus && !isEmpty(props.finalizeData?.PO) && !viewAllocationCall) { 
      if(isAshley()) { 
        if(props?.location?.state?.planDetails?.isPOASNFlow) {
          req["article"] = props?.location?.state?.planDetails?.articles
        }
        props.uploadFile(props.finalizeData)
        props.updateFinalizeInfo(req)
      }
      props.updateReserveQuantity(props.finalizeData)
    }

    else if (!isEmpty(props.finalizeData) && viewAllocationCall && props.retransitButton) {
      if(isAshley()) {
        req["article"] = planDetails?.articles;
        props.uploadFile(props.finalizeData)
        props.updateFinalizeInfo(req)
      }
    }

  }, [props.finalizeData])

  useEffect(() => {
    if (props.disableEditData && !viewAllocationCall){
      finalizeAllocation(originalAllocationCode)
    }
    if(props.disableEditError && !viewAllocationCall){
      setFinalizeBtn(false)
      Notify.error("Error in Finalizing the Alocation !!")
    }
  }, [props.disableEditData, props.disableEditError])

  useEffect(() => {
    if (!props.allocationStatus && props.finalizeData?.status) {
        setFinalizeBtn(false)
        setFinalized(true)
        !viewAllocationCall && Notify.success("Allocation Finalized Successfully!!")
      }
      if (!props.finalizeData?.status && props?.finalizeData?.message) {
        setFinalizeBtn(false)
        viewAllocationCall ? Notify.error("You Cannot Download This Allocation!!") : Notify.error(props?.finalizeData?.message)
      }
  }, [props.finalizeData, viewAllocationCall])

  useEffect(() => {
    if (props.uploadError) {
      setRetry(true)
      Notify.error("Failed to upload File, please retry!!")
    }
  }, [props.uploadError])

  useEffect(() => {
    if (props.uploadSuccess) {
      Notify.success("File Uploaded Successfully!!")
      setRetry(false)
    }
  }, [props.uploadSuccess])

  useEffect(() => {
    if (Object.keys(props.downloadXmlData).length) {
      var result = XML.json2xml(props.downloadXmlData, { compact: true });
      saveFile(result, `${planDetails?.allocationName ? planDetails?.allocationName : planDetails?.plan_description}.xml`)
    }
  }, [props.downloadXmlData])

  useEffect(() => {
    if (props.downloadExcelData.length) {
      const worksheet = XLSX.utils.json_to_sheet(props.downloadExcelData)
      const workbook = {
        Sheets: {
          "Allocation Plan": worksheet
        },
        SheetNames: ["Allocation Plan"]
      }
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
      saveFile(excelBuffer, `${planDetails?.allocationName ? planDetails?.allocationName : planDetails?.plan_description}.xlsx`)
    }

  }, [props.downloadExcelData])

  useEffect(() => {
    if (props.downloadCsvData.length) {
      setTimeout(() => {
        csvRef.link.click()
      }, 200)
    }
  }, [props.downloadCsvData])

  useEffect(() => {
    if(props.finalizeError) {
      setFinalizeBtn(false)
      Notify.error("Something Went Wrong")
    }
  },[props.finalizeError])

  const handleAlertOk = () => {
    preFinalizeCheck()
    setIsShowAlert(false)
  }
  const handleAlertCancel = () => {
    setIsShowAlert(false)
  }

  const handleAlertClose = () => {
    setIsShowAlert(false)
  }

  const handleReTransmit = () => {
    let req = {
      allocationcode: allocationCode, 
      articles: planDetails?.articles,
      rerelease_flag: props.retransitButton ? props.retransitButton : false
    }
    props.getPastAllocationPO(req)
  }


  // useEffect(() => {
  //   if (!isEmpty(props.finalizeData) && viewAllocationCall && props.retransitButton) {
  //     if(isAshley()) {
  //       let req = {
  //         "allocation_code": allocationCode,
  //         "user_id": localStorage.getItem("user_id"), 
  //         "user_email": localStorage?.getItem("email"),
  //         "article": planDetails?.articles
  //       }
  //       props.uploadFile(props.finalizeData)
  //       props.updateFinalizeInfo(req)
  //     }
  //   }
  // }, [props.finalizeData])

  const isShowFinalizeButton = () => {
    return !props.allocationStatus && !finalized && !viewAllocationCall && (!props?.productDataError || !props?.storeDataError)
  }

  return (
    <PageLoader loader={props.finalizeLoading || props.uploadLoading || props.disableEditLoading || props.storeDataLoading || props.productDataLoading}>
      {
        (!props.editAllocatedUnitError) &&
        <Notification />
      }
      {isShowAlert && <ConfirmationAlert
        modalStyle={{ width: '45rem' }}
        message="You will not be able to modify the allocation plan post Finalize. Are you sure you want to continue ?"
        buttonLabel="Yes"
        handleOk={handleAlertOk}
        handleCancel={handleAlertCancel}
        handleClose={() => handleAlertClose()} />}
      <div className="finalize__container">
        <div className="container__header">
          <h1 className="fnt-lg fnt-bold">{viewAllocationCall ? "Detailed View" : "Finalize"}</h1>
        </div>
        {
          // viewAllocationCall &&
          <div className="row justify-content-center mb-4 plan__details">
            {
              planDetails.po_number &&
              <>
              <div className="mr-4">
                <span className="fnt-bold mr-2">PO ID : </span>
                <span>{planDetails.po_number}</span>
              </div>
              {
                planDetails.asn &&
                <div className="mr-4">
                  <span className="fnt-bold mr-2">ASN ID : </span>
                  <span>{planDetails.asn}</span>
                </div>
              }
              </>
            }
            {planDetails.allocationName ? 
            <div className="mr-4">
              <span className="fnt-bold mr-2">Plan Name : </span>
              <span>{planDetails.allocationName || "-"}</span>
            </div>
            :
            <div className="mr-4">
            <span className="fnt-bold mr-2">Plan Name : </span>
            <span>{planDetails.allocationCode || "-"}</span>
          </div>
            }

            <div className="mr-4">
              <span className="fnt-bold mr-2">Created on : </span>
              <span>{planDetails.creation_date}</span>
            </div>
          </div>
        }
        <div className="container__body finalize__tabs__body">
          {
            viewAllocationCall &&
            <button className="btn btn-primary back__btn" onClick={() => { props.goBack(false) }}>
              {"<< Back"}
            </button>
          }
          <div className="container__tabs">
            <Tabs defaultActiveKey="fpv" activeKey={activeTab}
              onSelect={handleTabSelect}>
                 <Tab eventKey="fpv" title="Product View">
                {
                  (activeTab === "fpv" || viewAllocationCall) &&
                  <ProductView activeTab={activeTab} allocationCode={allocationCode} releasedDate={planDetails?.released_date}
                    viewAllocationCall={viewAllocationCall} isFinalized={finalized || props.allocationStatus} retransmit={props.retransitButton}
                    isPOASNFlow = {planDetails?.isPOASNFlow} articles={planDetails?.articles}
                    setTableInstance={setTableInstance} tableInstance={tableInstance}
                    originalAllocationCode={originalAllocationCode}/>
                }
              </Tab>
              <Tab eventKey="fsv" title="Store View">
                {
                  (activeTab === "fsv" || viewAllocationCall) &&
                  <StoreView activeTab={activeTab} allocationCode={allocationCode} releasedDate={planDetails?.released_date}
                    rowArticles={planDetails?.articles} originalAllocationCode={originalAllocationCode}
                    viewAllocationCall={viewAllocationCall} isFinalized={finalized || props.allocationStatus} retransmit={props.retransitButton}
                    setTableInstance={setTableInstance} tableInstance={tableInstance}
                    isPOASNFlow = {planDetails?.isPOASNFlow} isPOASN = {planDetails?.isPOASN} 
                    articles={planDetails?.articles}/>
                }
              </Tab>
            </Tabs>
          </div>

          {
            <div className="finalize__btn text-center">
              {
                isShowFinalizeButton() &&
                <button className="btn btn-primary" onClick={() => setIsShowAlert(true)}>
                  Finalize
                </button>
              }
              {
                props.retransitButton &&
                <button className="btn btn-primary" style={{margin: '2rem'}} onClick={() => handleReTransmit()}>
                   Retransmit
                </button>
              }
              {
                finalized && retry && !viewAllocationCall &&
                <button className="btn btn-primary" style={{margin: '2rem'}} onClick={retryFileUpload}>
                  Retry
                </button>
              }
              {
                (finalized || props.allocationStatus) && (props?.finalizeData?.Output?.length || props?.finalizeData?.PO?.length) &&
                <>
                  {
                    !isAshley() &&
                    <>
                      <button className="btn btn-primary mr-4 px-2" onClick={downloadExcel}>
                        <i
                          className="fa fa-download mr-2"
                          title="Configure"
                          aria-hidden="true"
                        ></i>
                        Download Allocation in Excel
                      </button>
                      <button className="btn btn-primary px-2" onClick={downloadXml}>
                        <i
                          className="fa fa-download mr-2"
                          title="Configure"
                          aria-hidden="true"
                        ></i>
                        Download Allocation in XML
                      </button>
                    </>
                  }
                  {
                    ((isAshley() && props.uploadSuccess) || !props.retransitButton) && 
                    <>
                      <CSVLink data={props.finalizeData?.Output? props.finalizeData?.Output : props.finalizeData?.PO} headers={props.downloadCsvData} filename={`${planDetails?.allocationName || planDetails?.plan_description}.csv`}
                        ref={(r) => csvRef = r}>
                      </CSVLink>
                      <ExportFile text={"Download Allocation in CSV"} callFunc={downloadCsv} type={3} />
                    </>
                  }
                </>
              }
            </div>
          }
        </div>
      </div>
    </PageLoader>
  )
}


const mapStateToProps = ({ finalize }) => {
  return {
    editAllocatedUnitError: finalize.editAllocatedUnitError,
    dcAvailableError: finalize.dcAvailableError,
    finalizeLoading: finalize.finalizeLoading,
    finalizeError: finalize.finalizeError,
    finalizeData: finalize.finalizeData,
    downloadXmlData: finalize.downloadXmlData,
    downloadExcelData: finalize.downloadExcelData,
    downloadCsvData: finalize.downloadCsvData,
    uploadError: finalize.uploadError,
    uploadLoading: finalize.uploadLoading,
    uploadSuccess: finalize.uploadSuccess,
    productDataError: finalize.productDataError,
    storeDataError: finalize.storeDataError,
    alloacationCode: finalize.alloacationCode,
    disableEditLoading: finalize.disableEditLoading,
    disableEditData: finalize.disableEditData,
    disableEditError: finalize.disableEditError,
    storeDataLoading: finalize.storeDataLoading,
    productDataLoading: finalize.productDataLoading,
    allocationStatus: finalize.allocationStatus
  }
};

const mapDispatchToProps = (dispatch) => ({
  finalizeAllocation: (payload,source) => dispatch(finalizeAllocation(payload,source)),
  updateReserveQuantity: (payload) => dispatch(updateReserveQuantity(payload)),
  downloadXml: (payload) => dispatch(downloadXml(payload)),
  downloadExcel: (payload) => dispatch(downloadExcel(payload)),
  downloadCsv: (payload) => dispatch(downloadCsv(payload)),
  resetFinaliseData: () => dispatch(resetFinaliseData()),
  uploadFile: (payload) => dispatch(uploadFile(payload)),
  updateFinalizeInfo: (payload) => dispatch(updateFinalizeInfo(payload)),
  disableEdit: (payload) => dispatch(disableEdit(payload)),
  getPastAllocationPO: (payload) => dispatch(getPastAllocationPO(payload)),
  resetAll: () => dispatch(resetAll())
});

export default connect(mapStateToProps, mapDispatchToProps)(Finalize);