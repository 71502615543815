import React, { useEffect, useState, useRef } from "react"
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import { PO_ALLOCATION_TABLE_HEADERS } from "./POAllocationConstants"
import { cloneDeep } from "lodash";
import StylesPopup from "./StylesPopup"
import RecommendedActionItemModal from "./RecommendedActionItemModal"
import { dateFormat } from "../../../utils/commonUtilities";


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const hidableColumnsForPopUp = ["action","asn_id","po_id", "dc_code", "inventory_date", "latest_receipt_date", "inventory_source", "count", "status", "vendor_id", "vendor_name","creation_date"]

const POAllocationTable = (props) => {
    const { data, allocatePO, toRelease, isPopup, setTableInstance, buttonLabel, type, status } = props

    const [showStylesPopup, setShowStyles] = useState(false)
    const [selectedData, setStylesData] = useState({})
    const [initialHiddenColumns, setHiddenColumns] = useState([])
    const [showActionItemModal, setShowModal] = useState(false)
    const [showActionItemModalData, setShowActionItemModalData] = useState([])

    const getColumnBehaviour = (canExpand, value) => {
        return value

        // if(toRelease){
        //     return value
        // }
        // return canExpand ? value : <span style={{ backgroundColor: "rgb(224,224,224)", minHeight: "100%", minWidth: "100%" }}> </span>
    }

    const getFilterBehaviour = () => {
        return  { Filter: ColumnFilter }

        // if(toRelease){
        //     return  { Filter: ColumnFilter }
        // }
        // return  { disableFilters: true }
    }

    useEffect(() => {
        if (toRelease) {
            setHiddenColumns(["article", "description", "count", "latest_receipt_date","creation_date"])
        }
        if (isPopup) {
            setHiddenColumns(hidableColumnsForPopUp)
        }
        if (type === "POASN" && status === "Processed") {
            setHiddenColumns(["packs","asn_id","quantity_variance", "latest_receipt_date", "article", "description", "packs"])
        }
        if (type === "ASN" && status === "Pending") {
            setHiddenColumns(["packs","article", "description","quantity_variance", "latest_receipt_date","creation_date"])
        }
        if (type === "POASN" && status === "Mismatch") {
            setHiddenColumns(["packs","article", "description","asn_id","creation_date"])
        }
        if (type === "PO" && status === "Pending") {
            setHiddenColumns(["packs","article", "description","asn_id","quantity_variance", "latest_receipt_date","creation_date"])
        }
        if (type === "POASN" && status === "Error") {
            setHiddenColumns(["packs","article", "description","asn_id","creation_date"])
        }
    }, [toRelease, isPopup, type, status])

    const columns = [
        {
            Header: " ",
            sticky: "left",
            columns: [{
                Header: PO_ALLOCATION_TABLE_HEADERS.ASN_ID,
                sticky: "left",
                accessor: 'asn_id',
                // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
                // Filter: (p_instance) => <ColumnFilter {...p_instance} placeholder="Search in bulk..." />,
                Filter: ColumnFilter
            },
            {
                Header: PO_ALLOCATION_TABLE_HEADERS.PO_ID,
                sticky: "left",
                accessor: 'po_id',
                // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
                // Filter: (p_instance) => <ColumnFilter {...p_instance} placeholder="Search in bulk..." />,
                Filter: ColumnFilter
            }]
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.DC,
            accessor: 'dc_code',
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            Filter: ColumnFilter
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.INVENTORY,
            accessor: 'inventory_source',
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            Filter: ColumnFilter
        },
        {
            // id: isPopup ? '': 'expander', // Make sure it has an ID
            Header: PO_ALLOCATION_TABLE_HEADERS.STYLE_COUNT,
            accessor: 'count',
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            // Cell: ({ value, row }) =>
            //     // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            //     // to build the toggle for expanding a row
            //     !toRelease ? (
            //         row.canExpand ? (
            //             <div className="row"
            //                 {...row.getToggleRowExpandedProps()}
            //             >
            //                 <span className="expand__text" style={{ width: "60px" }}>
            //                     {value}
            //                 </span>
            //                 {
            //                     row.isExpanded ? (
            //                         <div
            //                             className="cursor-pointer"
            //                             style={{ color: "#50778e" }}
            //                         >
            //                             <i className="fa fa-angle-down fa-lg ml-2 expand-collapse" title="Collpase" ></i>
            //                         </div>) :
            //                         <div
            //                             className="cursor-pointer"
            //                             style={{ color: "#50778e" }}
            //                         >
            //                             <i className="fa fa-angle-right fa-lg ml-2 expand-collapse" title="Expand" ></i>
            //                         </div>
            //                 }
            //             </div>
            //         ) : <span style={{ backgroundColor: "rgb(224,224,224)", minHeight: "100%", minWidth: "100%" }}> </span>)
            //         : <div>
            //             <div>{value}</div>
            //         </div>,
            // width: 200,
            Filter: ColumnFilter

        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.STYLE,
            accessor: 'article',
            Filter: ColumnFilter

        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.DESC,
            accessor: 'description',
            ...getFilterBehaviour()
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.PACK_NAME,
            accessor: 'packs',
            ...getFilterBehaviour(),
            Filter: ColumnFilter

        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.CREATION_DATE,
            accessor: 'creation_date',
            // Cell: ({ value, row }) => dateFormat(value),
            Filter: ColumnFilter
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.EXPECTED_DATE,
            accessor: "inventory_date",
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            Filter: ColumnFilter
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.RECEIPT_DATE,
            accessor: "latest_receipt_date",
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value ? dateFormat(value) : " "),
            Filter: ColumnFilter
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.TOTAL_QTY,
            accessor: "total_quantity",
            Filter: ColumnFilter
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.RECEIPT_QTY,
            accessor: "receipt_quantity",
            Filter: ColumnFilter

        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.QTY_VARIANCE,
            accessor: "quantity_variance",
            Filter: ColumnFilter

        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.ALLOCATION_STATUS,
            accessor: "status",
            // Cell: ({ value, row }) => getColumnBehaviour(row.canExpand, value),
            Filter: ColumnFilter
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.VENDOR_ID,
            accessor: "vendor_id",
            Filter: ColumnFilter
        },
        {
            Header: PO_ALLOCATION_TABLE_HEADERS.VENDOR_NAME,
            accessor: 'vendor_name',
            Filter: ColumnFilter,
            width: 200
        },
        {
            Header: " ",
            sticky: "right",
            columns: [{
                Header: "Action",
                accessor: "action",
                Cell: ({row}) => (
                    <div>
                        <button
                            className="btn btn-outline-secondary btn-sm px-2 rounded-md text-body"
                            onClick={() => showPOStyles(row)}
                        >
                            Review
                            <i
                                className="fa fa-angle-right ml-2"
                                title="Configure"
                                aria-hidden="true"
                            ></i>
                        </button>
                    </div>
                ),
                width: 150,
                disableFilters: true
            }]
        },
    ]

    const showPOStyles = (row) => {
        if (type === "POASN" && status === "Processed") {
            setStylesData({data: row.originalSubRows, poDetails: row.original})
            setShowStyles(true)
        }
        else {
            setShowActionItemModalData(row.originalSubRows)
            setShowModal(true)
        }
    }

    const createDynamicColumnsForErrorFlow = () => {
        if(status === "Error") {
            let columnIndices = [10, 11, 12]
            const subColNames = [PO_ALLOCATION_TABLE_HEADERS.EACHES, PO_ALLOCATION_TABLE_HEADERS.PACKS, PO_ALLOCATION_TABLE_HEADERS.TOTAL]
            let acc = {
                10: ["total_eaches_nos", "total_pack_nos", "total_quantity"],
                11: ["eaches_nos", "pack_nos", "receipt_quantity"],
                12: ["eaches_variance", "pack_variance", "quantity_variance"],

            }
            columnIndices.forEach(id => {
                columns[id].columns = []
                acc[id].forEach((subAcc, index) => {
                    columns[id].columns.push({
                        Header: subColNames[index],
                        accessor: subAcc,
                        Filter: ColumnFilter
                    })
                })

            })  
        }
    }

    return (
        <>
            {createDynamicColumnsForErrorFlow()}
            <ReactTableWithPlugins
                shouldPagination
                hideColumnsFilter={isPopup}
                data={(data ? data : [])}
                columns={columns}
                renderMarkup='TableMarkup'
                containSubRow={true}
                keyRT={toRelease ? "toBeReleased" : (isPopup ? "StyleInvTable" : "finalize")}
                features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                initialHiddenColumns={initialHiddenColumns}
                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: 4 }}
                getInstanceOnMount={(instance) => {
                    setTableInstance(instance)
                }}
                isHideCount = {true}
                tableId={`${type}_${status}`}
            />
            {
                !toRelease && !isPopup &&
                <div className="text-center">
                    <button
                        className="btn btn-primary fnt-md px-2"
                        onClick={allocatePO}
                    >
                        {buttonLabel}
                    </button>
                </div>
            }
            {
                showStylesPopup &&
                <StylesPopup poData={selectedData} closeModal={() => {setShowStyles(false)}}
                    showModal={showStylesPopup} />
            }
            {
                showActionItemModal && 
                <RecommendedActionItemModal 
                    closeModal={() => {setShowModal(false)}}
                    showModal={showActionItemModal}
                    data= {showActionItemModalData}
                    columns = {columns}
                    type= {type}
                    status = {status}
                    hidableColumnsForPopUp= {hidableColumnsForPopUp}
                />
            }
        </>
    )
}

export default POAllocationTable