export const FETCH_FILTER_DATA = "@@excessReport/FETCH_FILTER_DATA";
export const FETCH_FILTER_SUCCESS = "@@excessReport/FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "@@excessReport/FETCH_FILTER_ERROR";

export const GET_EXCESS_DATA = "@@excessReport/GET_EXCESS_DATA";
export const GET_EXCESS_DATA_SUCCESS = "@@excessReport/GET_EXCESS_DATA_SUCCESS";
export const GET_EXCESS_DATA_ERROR = "@@excessReport/GET_EXCESS_DATA_ERROR";

export const GENERATE_EXCEL = "@@excessReport/GENERATE_EXCEL";
export const GENERATE_EXCEL_SUCCESS = "@@excessReport/GENERATE_EXCEL_SUCCESS";
export const GENERATE_EXCEL_ERROR = "@@excessReport/GENERATE_EXCEL_ERROR";

export const GET_EXCESS_GRAPH_DATA = "@@excessReport/GET_EXCESS_GRAPH_DATA";
export const GET_EXCESS_GRAPH_DATA_SUCCESS = "@@excessReport/GET_EXCESS_GRAPH_DATA_SUCCESS";
export const GET_EXCESS_GRAPH_DATA_ERROR = "@@excessReport/GET_EXCESS_GRAPH_DATA_ERROR";

export const RESET_ALL = "@@excessReport/RESET_ALL";
export const RESET_FILTERS_DATA = "@@excessReport/RESET_FILTERS_DATA";