import React from "react";
import AdaVisualView from './AdaVisualView';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty, isEqual } from "lodash";
import moment from "moment";
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications";
import * as adaVisualActions from './actions/AdaVisualActions';
import { NETWORK_ERROR_MSG, NO_REVIEW_MSG, NO_SAVE_MSG } from './AdaVisualConstants';
import { getEditedWeeklyAdaData, getEditedWeeklyHierarchyData } from './AdaUtilities';

/**
 * Ada Visual container maintaining api requests
 * on mounting with allocation section and on chnages of dates selection
 */
class AdaVisualContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleAllocRevRequest = this.handleAllocRevRequest.bind(this);
        this.handleAllocSaveRequest = this.handleAllocSaveRequest.bind(this);
    }

    componentDidMount() {
        const { adaVisualActions, adaAllocationReqBody } = this.props;
        adaVisualActions.getAdaAllocationData(adaAllocationReqBody);
    }

    componentDidUpdate() {
        const { adaVisualActions, adaAllocationReqBody } = this.props;
        adaVisualActions.getAdaAllocationData(adaAllocationReqBody);
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.adaAllocationReqBody, this.props.adaAllocationReqBody);
    }

    componentWillUnmount() {
        const { adaVisualActions } = this.props;
        adaVisualActions.resetAdaVisual();
    }

    /**
     * Function to handle Review Allocation Ada Visual Request
     * @param {*} e 
     */
    handleAllocRevRequest(e) {
        if (e) {
            const { adaVisualActions, startFiscalYearWeek, endFiscalYearWeek,
                isForecastModified, isHierarchyModified, adaAllocationReqBody, adaVisualData,
                adaVisualDataCopy, hierarchyData, hierarchyDataCopy, isReviewSuccess } = this.props;

            const { selectedArticles, adaReq, level1, level2, level3, level4 } = adaAllocationReqBody;
            const { current_fw, articles } = adaReq;

            const updatedAdaWeekData = getEditedWeeklyAdaData(adaVisualData, adaVisualDataCopy, current_fw);
            const updtedHierarchyWeekData = getEditedWeeklyHierarchyData(hierarchyData, hierarchyDataCopy, current_fw);

            let reviewReq = {};
            if (!isReviewSuccess && ((isHierarchyModified && !isEmpty(updtedHierarchyWeekData)) || (isForecastModified && !isEmpty(updatedAdaWeekData)))) {
                const tabValue = isForecastModified ? 'all' : (isHierarchyModified ? 'individual' : '');
                reviewReq = {
                    user_id: localStorage.getItem("user_id"),
                    screen: "create allocation",
                    tab: tabValue,
                    review_save_tag: "review",
                    month_or_week: "week",
                    time_stamp: moment().toISOString(),
                    start_fw: startFiscalYearWeek,
                    end_fw: endFiscalYearWeek,
                    current_fw: current_fw,
                    level1: [...level1],
                    level2: level2 ? [...level2] : null,
                    level3: level3 ? [...level3] : null,
                    level4: level4 ? [...level4] : null,
                    selected_articles: [...selectedArticles],
                    article_store_combination: { ...articles, },
                    changed_combinations_lower_hierarchy_week: (isHierarchyModified && !isEmpty(updtedHierarchyWeekData)) ?
                        { ...updtedHierarchyWeekData } : null,
                    changed_combinations_adjusted_forecast_week: isForecastModified && !isEmpty(updatedAdaWeekData) ? updatedAdaWeekData : null,
                };

                adaVisualActions.reviewAdaVisual(reviewReq);
            } else {
                Notify.error(NO_REVIEW_MSG);
            }
        }
    }

    /**
     * Function to handle Save Allocation Ada Visual Request
     * @param {event} e
     */
    handleAllocSaveRequest(e) {
        if (e) {
            const {
                adaVisualActions, startFiscalYearWeek, endFiscalYearWeek,
                adaAllocationReqBody, isReviewSuccess, isForecastModified, isHierarchyModified,
            } = this.props;

            let saveReq = {};

            if (isReviewSuccess) {
                const { selectedArticles, adaReq, level1, level2, level3, level4 } = adaAllocationReqBody;
                const { current_fw, articles } = adaReq;
                const tabValue = isForecastModified ? 'all' : (isHierarchyModified ? 'individual' : '');
                saveReq = {
                    user_id: localStorage.getItem("user_id"),
                    screen: "create allocation",
                    tab: tabValue,
                    review_save_tag: "save",
                    month_or_week: "week",
                    time_stamp: moment().toISOString(),
                    start_fw: startFiscalYearWeek,
                    end_fw: endFiscalYearWeek,
                    current_fw: current_fw,
                    level1: [...level1],
                    level2: level2 ? [...level2] : null,
                    level3: level3 ? [...level3] : null,
                    level4: level4 ? [...level4] : null,
                    selected_articles: [...selectedArticles],
                    article_store_combination: { ...articles },
                    changed_combinations_lower_hierarchy_week: null,
                    changed_combinations_adjusted_forecast_week: null,
                };

                adaVisualActions.saveAdaVisual(saveReq);
            } else {
                Notify.error(NO_SAVE_MSG);
            }
        }
    }

    render() {
        const { isAdaReqFailed } = this.props;
        let content = isAdaReqFailed ?
            <div className="error">{NETWORK_ERROR_MSG}</div> :
            (<AdaVisualView handleReviewRequest={this.handleAllocRevRequest}
                handleSaveRequest={this.handleAllocSaveRequest}
            />);

        return (
            <div>
                <Notification />
                {content}
            </div>)

    }
}

const mapStateToProps = ({ adaVisual }) => ({
    adaAllocationReqBody: adaVisual.adaAllocationReqBody,
    isAdaReqFailed: adaVisual.isAdaReqFailed,
    startFiscalYearWeek: adaVisual.startFiscalYearWeek,
    endFiscalYearWeek: adaVisual.endFiscalYearWeek,
    isReviewSuccess: adaVisual.isReviewSuccess,
    isForecastModified: adaVisual.isForecastModified,
    isHierarchyModified: adaVisual.isHierarchyModified,
    adaVisualData: adaVisual.adaVisualData,
    adaVisualDataCopy: adaVisual.adaVisualDataCopy,
    hierarchyData: adaVisual.hierarchyData,
    hierarchyDataCopy: adaVisual.hierarchyDataCopy,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators({ ...adaVisualActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdaVisualContainer);