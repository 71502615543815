import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import {
    addDollar,
    addPer,
    twoDecimal,
} from "../../../utils/formatters/valueFormatters";
import LinkCell from "../../../components/Table/Cellrenderer/LinkCell";

import {
    getStyleInvPopupData,
} from "../DashboardActions";
import { getStrategyTableDataFromDashboard } from "../../Strategy/actions";

import * as Notify from '../../../components/Notification/Notifications'
import Notification from '../../../components/Notification/Notifications'

import { DashboardModal } from "../DashboardModal";
import { STRATEGY_PAGE } from "../../../constants/routeConstants";
import PageLoader from '../../../components/Loader/PageLoader';
import ColumnFilter from '../../UserManagement/ColumnFilter';
import { isEmpty } from 'lodash';
import NumberRangeColumnFilter from '../../../components/Filters/NumberRangeColumnFilter';
import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping"
import ReactTollTip from '../../../components/Table/Cellrenderer/ReactToolTip';



const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StyleInventory = (props) => {

    const [openStyleInvPopup, setopenStyleInvPopup] = useState(false)
    const [popupTitle, setpopupTitle] = useState(null)
    const [articleId, setarticleId] = useState(null)
    const [styleInvTableInst, setstyleInvTableInst] = useState(null)



    const styleInvetoryCols = [
       {
        Header: " ",
        sticky: "left",
        columns: [
            {
                Header: "Product Type",
                accessor: "article_status_tag",
                Filter: ColumnFilter,
            },
            {
                Header: `${updateArticleName(false)} ID`,
                accessor: "article",
                width: 120,
                Filter: ColumnFilter,
                // Filter:(instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
                // filter: 'bulkFilterSpaceSeperated',
                // sticky: "left"
            },
            {
                Header: "Style Description",
                accessor: "style_name",
                width:300,
                Filter: ColumnFilter,
                // sticky: "left"
            },
            {
                Header: "Style Color",
                accessor: "color_desc",
                width:200,
                Filter: ColumnFilter,
                // sticky: "left"
            },
    ]},
    {
        Header: "Pack ID",
        accessor: "pack_id",
        Cell: (instance) => <ReactTollTip {...instance} />,
        Filter: ColumnFilter
    },
    {
        Header: "Filters",
        columns: [
            {
                Header: levelFiltersLabelFormatter("level1"),
                accessor: "l1_name",
                Filter: ColumnFilter,
            },
            {
                Header: levelFiltersLabelFormatter("level2"),
                accessor: "l2_name",
                Filter: ColumnFilter,
            },
            {
                Header: levelFiltersLabelFormatter("level3"),
                accessor: "l3_name",
                Filter: ColumnFilter,
            },
            {
                Header: levelFiltersLabelFormatter("level4"),
                accessor: "l4_name",
                Filter: ColumnFilter,
            },
            {
                Header: levelFiltersLabelFormatter("level5"),
                accessor: "l5_name",
                Filter: ColumnFilter,
            },
        ],
    },
        {
            Header: "LW Units",
            accessor: "lw_qty",
            Cell: (inst) => (
                <div>
                    {inst.value || inst.value === 0
                        ? ((inst.value))
                        : null}
                </div>
            ),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            defaultCanSort: true

        },
        {
            Header: "LW Revenue",
            accessor: "lw_revenue",
            Cell: (inst) => (
                <div>
                    {inst.value || inst.value === 0
                        ? addDollar((inst.value))
                        : null}
                </div>
            ),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
            defaultCanSort: true

        },
        {
            Header: "LW Margin",
            accessor: "lw_margin",
            Cell: (inst) => (
                <div>
                    {inst.value || inst.value === 0
                        ? addDollar((inst.value))
                        : null}
                </div>
            ),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "Promo %",
            accessor: "promo",
            Cell: (inst) => (
                <div>
                    {inst.value || inst.value === 0
                        ? addPer((inst.value))
                        : null}
                </div>
            ),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: (inst) => (
                <div>
                    {inst.value || inst.value === 0
                        ? addDollar((inst.value))
                        : null}
                </div>
            ),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "Inventory",
            columns: [
                {
                    Header: "Bulk Remaining",
                    accessor: "bulk_remaining",
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: "User Reserved",
                    accessor: "user_reserve",
                    Filter: NumberRangeColumnFilter,
                },
                {
                    Header: "PO/ASN Reserved",
                    accessor: "po_reserve",
                    Filter: NumberRangeColumnFilter,
                },
                {
                    Header: "Allocated Units",
                    accessor: "allocated_units",
                    Filter: NumberRangeColumnFilter,
                },
                {
                    Header: "Net Available Inventory",
                    accessor: "net_available",
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: "On Hand",
                    accessor: "oh",
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: "On Order",
                    accessor: "oo",
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: "In Transit",
                    accessor: "it",
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: "WOS",
                    accessor: "wos",
                    Cell: (inst) => (
                        <div>
                            {/* {inst.value}  */}
                            {inst.value || inst.value === 0 ? (inst.value) : null}
                        </div>
                    ),
                    // Filter: ColumnFilter
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    // width: 200
                },
                {
                    Header: "Size Integrity (On Hand)",
                    accessor: "si",
                    Cell: (inst) => (
                        <div>
                            {inst.value || inst.value === 0
                                ? addPer((inst.value))
                                : null}
                        </div>
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    width: 180
                    // Filter: NumberRangeColumnFilter,
                    // filter: 'between',
                    // width: 200
                },
            ],
        },
        {
            Header: "Store Count",
            columns: [
                {
                    Header: "Stock Out",
                    accessor: "stock_out",
                    Cell: (inst) => (
                        <LinkCell
                            {...inst}
                            onClick={() =>
                                onStyleInvPopupHandler(
                                    "Stock Out",
                                    inst.row.values.article,
                                    "stockOut"
                                )
                            }
                        />
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: "Shortfall",
                    accessor: "shortfall",
                    Cell: (inst) => (
                        <LinkCell
                            {...inst}
                            onClick={() =>
                                onStyleInvPopupHandler(
                                    "Shortfall",
                                    inst.row.values.article,
                                    "shortFall"
                                )
                            }
                        />
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    // Filter: NumberRangeColumnFilter,
                    // filter: 'between',
                    // width: 200
                },
                {
                    Header: "Normal",
                    accessor: "normal",
                    Cell: (inst) => (
                        <LinkCell
                            {...inst}
                            onClick={() =>
                                onStyleInvPopupHandler(
                                    "Normal",
                                    inst.row.values.article,
                                    "normal"
                                )
                            }
                        />
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: "Excess",
                    accessor: "excess",
                    Cell: (inst) => (
                        <LinkCell
                            {...inst}
                            onClick={() =>
                                onStyleInvPopupHandler(
                                    "Excess",
                                    inst.row.values.article,
                                    "excess"
                                )
                            }
                        />
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
            ],
        },
    ];

    const onStyleInvPopupHandler = (popupTitle, articleId, storesFilter) => {
        setopenStyleInvPopup(true)
        setpopupTitle(popupTitle)
        setarticleId(articleId)

        props.getStyleInvPopupData({ storesFilter, articleId });
    }

    const closeAlert = () => {
        setopenStyleInvPopup(false)
    };

    const onStyleInventoryMount = (inst) => {
        console.log(inst.state.selectedRowIds)
        setstyleInvTableInst(inst)
    }


    const onCreateAllocationTableHandler = () => {
        console.log(styleInvTableInst )
        if (
            Object.keys(styleInvTableInst.state.selectedRowIds).length > 0
        ) {
            const styles = [];
            Object.keys(styleInvTableInst.state.selectedRowIds).forEach(
                (rowId) => {
                    console.log(
                        "weofho82hrnfw",
                        styleInvTableInst
                    );
                    styles.push(
                        styleInvTableInst.preFilteredRowsById[rowId].original.article
                    );
                }
            );

            let dc = props.filters?.dc ? Array.isArray(props.filters?.dc?.[0]) ? props.filters?.dc?.[0]?.map(a => a.value) :  props.filters?.dc?.map(a => a.value) : []  
            let payload = {
                level1: [props.filters.level1[0].value],
                level2: props.filters?.level2?.[0]?.map(a => a.value),
                level3: props.filters?.level3?.[0]?.map(a => a.value),
                level4: props.filters?.level4?.[0]?.map(a => a.value),
                level5: props.filters?.level5?.[0]?.map(a => a.value),
                dc: dc ,
                style: styles
            }
            // const request = {
            //     style: styles,
            //     Attributes: {
            //         style: styles,
            //     },
            //     Operators: {
            //         NonAttributeColumn: ["style"],
            //     },
            // };

            let request = {};
            if (!isEmpty(payload)) {
              request.Attributes = {};
              request.Operators = { NonAttributeColumn: [] };
              for (const key in payload) {
                if(!isEmpty(payload[key])){
                  request[key] = payload[key];
                  request.Attributes[key] = request[key];
                  request.Operators.NonAttributeColumn.push(key);
                }
              }
            }
            request["dc"] = payload.dc
            props.getStrategyTableDataFromDashboard(request);
            console.log(props.filters,'strategy')
            props.history.push(STRATEGY_PAGE,{filters : props.filters});
        }
        else{
            Notify.error(`Select Atleast one ${updateArticleName(false)} Inventory!!`);
        }
    };


    console.log("style inventory-------------------------------", props)

    return (

        <div>
         <Notification />
            <PageLoader
                loader={props.data ? false : true}
                gridLoader={true}
                loaderMessage="Loading..."
            >
                {
                    props.data ?
                        (
                            <>
                                <ReactTableWithPlugins
                                    getInstanceOnMount={(inst) => onStyleInventoryMount(inst)}
                                    columns={styleInvetoryCols}
                                    sortBy = {[{ id: 'lw_qty', desc: true }]}
                                    data={props.data ? props.data : []}
                                    renderMarkup="TableMarkup"
                                    keyRT="StyleInvTable"
                                    shouldPagination
                                    totalRecordsLen={props.data ? props.data.length : 0}
                                    features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                                    tableId="style_inv"
                                />
                                {props.data.length ?
                                    (<div className="text-center">
                                        <button
                                            className="btn btn-primary fnt-md px-2"
                                            onClick={() => 
                                                onCreateAllocationTableHandler()
                                            
                                            }
                                        >
                                            Create Allocation
                                        </button>
                                    </div>) :
                                    ''
                                }

                                {props.styleInvPopupData && (
                                    <DashboardModal
                                        openStyleInvPopup={openStyleInvPopup}
                                        closeAlert={closeAlert}
                                        popupTitle={popupTitle}
                                        articleId={articleId}
                                        styleInvPopupData={props.styleInvPopupData}
                                    />
                                )}

                            </>) :
                        ''
                }

            </PageLoader>


        </div>

    )
}

const mapStateToProps = ({ dashboard }) => ({
    styleInvPopupData: dashboard.styleInvPopupData,
})

const mapDispatchToProps = (dispatch) => ({
    getStyleInvPopupData: (payload) => dispatch(getStyleInvPopupData(payload)),
    getStrategyTableDataFromDashboard: (payload) => dispatch(getStrategyTableDataFromDashboard(payload)),
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StyleInventory))
