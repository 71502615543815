export const initState = {
    departmentOptions: [],
    genderOptions: [],
    rbuOptions: [],
    dcsOptions: [],
    level5Options: [],
    styleOptions: [],
    colorOptions: [],
    articleOptions: [],
    storeOptions: [],
    filterlabels: {},
    excessTableData: [],
    excessGraphData: {},
    excelData: [],
    tableDataSuccess: false,
    tableDataLoading: false,
    tableDataError: null,
    graphDataSuccess: false,
    graphDataLoading: false,
    graphDataError: null,
    nextIndex: 0,
    totalCount: 0,
    weeks: [],
    sum: {}
}