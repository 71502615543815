import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { newStoreInitialState } from './initialState';
import storeConfigReducer from './StoreDetailsConfigReducers/StoreDetailsConfigReducers';
import inventoryDropReducer from './InventoryDropReducers/InventoryDropReducers';
import demandReducer from "./DemandReducer/DemandReducer";

const newStoreSetUpReducer = {
    newStoreSetUp: handleActions(
        {

        },
        newStoreInitialState,
    ),
    ...inventoryDropReducer,
    ...storeConfigReducer,
    ...demandReducer
};

export default newStoreSetUpReducer;