import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import '../ProductStoreStatus.css'
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import { getProductStatusData, getArticleConfigData, deleteProductConfig, resetAll, resetCreateConfigData } from "./Actions/ProductStatusAction"
import ProductStatusFilters from "./ProductStatusFilters"
import ArticleConfigTable from "./ArticleConfigTable"
import ColumnFilter from "../../UserManagement/ColumnFilter";
import EditIconCell from "../../../components/Table/Cellrenderer/EditIconCell";
import PageLoader from '../../../components/Loader/PageLoader';
import { CREATE_CONFIGURATION } from "../../../constants/routeConstants";
import * as Notify from '../../../components/Notification/Notifications'
import Notification from '../../../components/Notification/Notifications'
import MissingConfigTable from "./MissigConfigTable"
import { hideToProd } from '../../../utils/commonUtilities';
import { levelFiltersLabelFormatter } from '../../../utils/filterLevelMapping';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

let selectedFilters = {}

const ProductStatus = (props) => {
    const history = useHistory();
    const [isPopupOpened, setIsPopupOpened] = useState(false)
    const [filters, setFilters] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [showArticleConfig, setShowArticleConfig] = useState(false)
    const [showCreateConfig, setShowCreateConfig] = useState(false)
    const [selectedConfigId, setSelectedConfigId] = useState(null)
    const [articleData, setArticleData] = useState([])
    const [selectedConfigName, setConfigName] = useState("")
    const [selectedConfigFilters, setSelectedConfigFilters] = useState({})

    const columns = React.useMemo(
        () => [
            {
                Header: "Configuration Profile",
                accessor: "configuration_name",
                Cell: (instance) => (
                    <div>
                        <div className="clickable" onClick={() => getArticleConfiguration(instance)}>{instance.value}</div>
                    </div>
                ),
                Filter: ColumnFilter,
                sticky: "left"
            },
            {
                Header: levelFiltersLabelFormatter("level1"),
                accessor: "l1_name",
                Filter: ColumnFilter

            },
            {

                Header: levelFiltersLabelFormatter("level2"),
                accessor: "l2_name",
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level3"),
                accessor: "l3_name",
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level4"),
                accessor: "l4_name",
                Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level5"),
                accessor: "l5_name",
                Filter: ColumnFilter
            },
            {
                Header: "Style",
                accessor: "style",
                Filter: ColumnFilter
            },
            {
                Header: "Color",
                accessor: "color",
                Filter: ColumnFilter
            },
            {
                Header: "Product Type",
                accessor: "product_type",
                Filter: ColumnFilter
            },
            {
                Header: "Created By",
                accessor: "created_by",
                Filter: ColumnFilter,
                width: 250
            },
            {
                Header: "Created On",
                accessor: "created_on",
                Filter: ColumnFilter
            },
            {
                Header: "Last Modified",
                accessor: "last_modified",
                Filter: ColumnFilter
            },
            {
                Header: "Actions",
                accessor: "actions",
                sticky: "right",
                Cell: (instance) => (
                    <div>
                        <EditIconCell
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            instance={instance}
                        ></EditIconCell>
                    </div>
                ),
                disableFilters: true,
                resizable: true,
                width: 100,
            },
        ])

    const handleEdit = (instance) => {
        let filters = {
            l1_name: instance.l1_name,
            configuration_id: instance.configuration_id,
            auto_apply: instance.auto_apply
        }
        let remainingFilters = ["l2_name", "l3_name", "l4_name", "l5_name", "color", "style", "product_type"]
        remainingFilters.forEach(key => {
            if(instance[key]) {
                filters[key] = instance[key]
            }
        })
        history.push(`${CREATE_CONFIGURATION}`, { filters });
    };

    const handleDelete = (instance) => {
        if (window.confirm("Are you sure you wish to delete this configuration?")) {
            props.deleteProductConfig({ configuration_id: instance.configuration_id })
        }
    };

    const getProductStatusData = (filters) => {
        setShowArticleConfig(false)
        setFilters(filters)
        if (filters) {
            selectedFilters = filters
        }
        props.getProductStatusData(selectedFilters)
    }

    useEffect(() => {
        return () => {
            props.resetAll()
        }
    }, [])


    const getArticleConfiguration = (instance) => {
        setArticleData([])
        let rowData = instance?.row?.original
        let selectedRowFilters = {}
        if (rowData?.l1_name) {
            selectedRowFilters.l1_name = rowData?.l1_name
        }
        if (rowData?.l2_name) {
            selectedRowFilters.l2_name = rowData?.l2_name
        }
        if (rowData?.l3_name) {
            selectedRowFilters.l3_name = rowData?.l3_name
        }
        if (rowData?.l4_name) {
            selectedRowFilters.l4_name = rowData?.l4_name
        }
        if (rowData?.style) {
            selectedRowFilters.style = rowData?.style
        }
        if (rowData?.color) {
            selectedRowFilters.color = rowData?.color
        }
        let configuration_id
        if (instance) {
            setSelectedConfigFilters(selectedRowFilters)
            configuration_id = instance?.row?.original?.configuration_id
            setSelectedConfigId(configuration_id)
            setConfigName(instance?.row?.original?.configuration_name)
        }
        if (configuration_id || selectedConfigId) {
            setShowArticleConfig(true)
            props.getArticleConfigData({ configuration_id: configuration_id ? configuration_id : selectedConfigId })
        }
    }

    const goToCreateConfig = () => {
        history.push(`${CREATE_CONFIGURATION}`);
    }

    useEffect(() => {
        if (props.deleteConfigData?.status) {
            getProductStatusData()
            setArticleData([])
            Notify.success(`Configuration Deleted Successfully!!`)
            props.resetCreateConfigData()
        }
        else if (props.deleteConfigDataError) {
            Notify.error("Something Went Wrong!!")
            props.resetCreateConfigData()

        }
    }, [props.deleteConfigData, props.deleteConfigDataError])

    useEffect(() => {
        if (props.articleData?.length) {
            setArticleData(props.articleData)
        }
    }, [props.articleData])

    useEffect(() => {
        if (props.selectedFilters) {
            setFilters(props.selectedFilters)
            getProductStatusData(props.selectedFilters)
        }
    }, [props.selectedFilters])



    return (

        <div className="product__store__status__container">
            <div className="container__header__with__button config-header">
                <h1 className="fnt-lg fnt-bold">Product Status</h1>
                <div className="row config-btn-container" onClick={goToCreateConfig}>
                    <button className="btn-primary-rounded create-config-btn mr-2">
                        <i
                            className="fa fa-plus"
                            title="Create Allocation"
                            aria-hidden="true"
                        ></i>
                    </button>
                    <p className="fnt-sm m-0">Create Configuration</p>
                </div>
            </div>
            <div className="row">
                <ProductStatusFilters applyFilters={getProductStatusData}
                    selectedFilters={filters} isPopupOpened={isPopupOpened} />
            </div>
            {
                hideToProd() &&
                <MissingConfigTable filters = {filters} />
            }
            <section className="row section">
                {
                    !isPopupOpened &&
                    <Notification />
                }
                {(props.dataError) ? (<div className="error">{props.dataError}</div>) :
                    <PageLoader loader={props.dataLoading || props.deleteConfigDataLoading} gridLoader={true}>
                        <div className="storesStore__heading mb-4">
                            <h1 className="fnt-md fnt-bold">Product Configurations</h1>
                        </div>
                        <ReactTableWithPlugins
                            shouldPagination
                            totalRecordsLen={props.data?.length}
                            data={props.data}
                            columns={columns}
                            renderMarkup='TableMarkup'
                            keyRT="sortAndSearch"
                            tableId="product_config"
                        />
                    </PageLoader>
                }
            </section>
            {
                showArticleConfig &&
                <section className="row section">
                    {(props.articleDataError) ? (<div className="error">{props.articleDataError}</div>) :
                        <ArticleConfigTable data={articleData} dataLoading={props.articleDataLoading} filters={filters}
                            setIsPopupOpened={setIsPopupOpened} configuration_id={selectedConfigId} config_name={selectedConfigName}
                            getProductStatusData={getProductStatusData} selectedConfigFilters={selectedConfigFilters}
                            getArticleConfigData={getArticleConfiguration} />
                    }
                </section>
            }
        </div>
    )
}

const mapStateToProps = ({ productStatus }) => ({
    data: productStatus.data,
    dataError: productStatus.dataError,
    dataLoading: productStatus.dataLoading,
    articleData: productStatus.articleData,
    articleDataLoading: productStatus.articleDataLoading,
    articleDataError: productStatus.articleDataError,
    deleteConfigDataLoading: productStatus.deleteConfigDataLoading,
    deleteConfigData: productStatus.deleteConfigData,
    deleteConfigDataError: productStatus.deleteConfigDataError,
    selectedFilters: productStatus.selectedFilters
})

const mapDispatchToProps = (dispatch) => ({
    getProductStatusData: (payload) => dispatch(getProductStatusData(payload)),
    getArticleConfigData: (payload) => dispatch(getArticleConfigData(payload)),
    deleteProductConfig: (payload) => dispatch(deleteProductConfig(payload)),
    resetCreateConfigData: () => dispatch(resetCreateConfigData()),
    resetAll: () => dispatch(resetAll())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductStatus)
