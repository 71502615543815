import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { adaVisualInitialState } from './initialState';
import { cloneDeep } from 'lodash';
import * as adaVisualActionTypes from "../actions/actionTypes";

const adaVisualReducer = {
    adaVisual: handleActions(
        {
            [adaVisualActionTypes.SET_ADA_ALLOCATION_REQBODY]: (state, { payload }) => {
                return immutable(state, {
                    adaAllocationReqBody: { $set: payload },
                    isAdaAllocationModeSelected: { $set: true },
                });
            },
            [adaVisualActionTypes.GET_ADA_ALLOCATION_DATA_REQUEST]: (state, { payload }) => {
                return immutable(state, {
                    isAdaDataLoading: { $set: true },
                    isAdaReqFailed: { $set: false },
                    isAdaLoadingSuccess: { $set: false },
                    isAdaAllocationModeSelected: { $set: true }
                })
            },
            [adaVisualActionTypes.GET_ADA_ALLOCATION_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    adaVisualData: { $set: [...response.adaData] },
                    adaVisualDataCopy: { $set: [...response.adaData] },
                    adaGraphData: { $set: response.adaGraphData },
                    isAdaDataLoading: { $set: false },
                    isAdaReqFailed: { $set: false },
                    isAdaAllocationModeSelected: { $set: true },
                    isAdaLoadingSuccess: { $set: true },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: false },
                });
            },
            [adaVisualActionTypes.GET_ADA_ALLOCATION_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isAdaReqFailed: { $set: true },
                    isAdaDataLoading: { $set: false },
                    errorMsg: { $set: error },
                    isAdaLoadingSuccess: { $set: false },
                    isAdaAllocationModeSelected: { $set: true },
                });
            },
            [adaVisualActionTypes.GET_ADA_STANDALONE_DATA_REQUEST]: (state, { payload }) => {
                return immutable(state, {
                    isAdaDataLoading: { $set: true },
                    isAdaReqFailed: { $set: false },
                    isAdaLoadingSuccess: { $set: false },
                    isAdaAllocationModeSelected: { $set: false },
                })
            },
            [adaVisualActionTypes.GET_ADA_STANDALONE_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    adaVisualData: { $set: [...response.adaData] },
                    adaVisualDataCopy: { $set: [...response.adaData] },
                    adaGraphData: { $set: response.adaGraphData },
                    isAdaDataLoading: { $set: false },
                    isAdaReqFailed: { $set: false },
                    isAdaLoadingSuccess: { $set: true },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: false },
                });
            },
            [adaVisualActionTypes.GET_ADA_STANDALONE_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isAdaReqFailed: { $set: true },
                    isAdaDataLoading: { $set: false },
                    isAdaLoadingSuccess: { $set: false },
                    errorMsg: { $set: error },
                });
            },
            [adaVisualActionTypes.SET_STARTEND_FISCAL_YEARWEEK]: (state, { payload }) => {
                return immutable(state, {
                    startFiscalYearWeek: { $set: payload.start_date },
                    endFiscalYearWeek: { $set: payload.end_date },
                    startDateStamp: { $set: payload.start_stamp },
                    endDateStamp: { $set: payload.end_stamp },
                })
            },
            [adaVisualActionTypes.RESET_SELECTED_DATES]: (state) => {
                return immutable(state, {
                    startFiscalYearWeek: { $set: null },
                    endFiscalYearWeek: { $set: null },
                    startDateStamp: { $set: null },
                    endDateStamp: { $set: null },
                });
            },
            [adaVisualActionTypes.SET_CURR_FISCAL_YEARWEEK]: (state, { payload }) => {
                return immutable(state, {
                    currentFiscalYearWeek: { $set: payload },
                })
            },
            [adaVisualActionTypes.SET_ADA_DATA_TIME_CONSTRAINT]: (state, { payload }) => {
                return immutable(state, {
                    timeConstraint: { $set: payload },
                })
            },
            [adaVisualActionTypes.GET_ADA_DRIVERS_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isDriversDataLoading: { $set: true },
                    isDriversDataFailed: { $set: false },
                })
            },
            [adaVisualActionTypes.GET_ADA_DRIVERS_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isDriversDataLoading: { $set: false },
                    isDriversDataFailed: { $set: false },
                    adaDriversData: { $set: { ...response.data } },
                })
            },
            [adaVisualActionTypes.GET_ADA_DRIVERS_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isDriversDataLoading: { $set: false },
                    isDriversDataFailed: { $set: true },
                })
            },
            [adaVisualActionTypes.UPDATE_FORECAST_DATA]: (state, { payload }) => {
                const { date, updatedValue, fieldName } = payload;
                const updateAdaData = cloneDeep(state.adaVisualData);
                updateAdaData.forEach(item => {
                    if (item.fy_fw === date) {
                        if (fieldName === 'adjusted_forecast') {
                            item['adjusted_forecast'] = updatedValue;
                            item['forecast_multiplier'] = updatedValue / item['ia_forecast'];
                        } else if (fieldName === 'forecast_multiplier') {
                            item['forecast_multiplier'] = updatedValue;
                            item['adjusted_forecast'] = Number((updatedValue * item['ia_forecast']).toFixed(0));
                        } else {
                            //No code here
                        }
                    }
                });

                return immutable(state, {
                    adaVisualData: { $set: updateAdaData },
                    isForecastModified: { $set: true },
                    isHierarchyModified: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewSuccess: { $set: false },
                    isSaveSuccess: { $set: false },
                })
            },
            [adaVisualActionTypes.GET_ADA_FILTER_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isAdaFilterLoading: { $set: true },
                    isAdaFilterFailed: { $set: false },
                })
            },
            [adaVisualActionTypes.GET_ADA_FILTER_DATA_SUCCESS]: (state, { data }) => {
                return immutable(state, {
                    isAdaFilterLoading: { $set: false },
                    isAdaFilterFailed: { $set: false },
                    departmentOptions: { $set: data && data.departmentOptions ? data.departmentOptions : state.departmentOptions, },
                    genderOptions: { $set: data && data.genderOptions ? data.genderOptions : state.genderOptions },
                    subCatOptions: { $set: data && data.subCatOptions ? data.subCatOptions : state.subCatOptions, },
                    dcsOptions: { $set: data && data.dcsOptions ? data.dcsOptions : state.dcsOptions },
                    articleIdOptions: { $set: data && data.articleIdOptions ? data.articleIdOptions : state.articleIdOptions },
                    filterLabels: { $set: data && data.filterMapping ? data.filterMapping : state.filterMapping },
                })
            },
            [adaVisualActionTypes.GET_ADA_FILTER_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isAdaFilterLoading: { $set: false },
                    isAdaFilterFailed: { $set: true },
                })
            },
            [adaVisualActionTypes.SET_STANDALONE_REQBODY]: (state, { payload }) => {
                return immutable(state, {
                    adaStandAloneReqBody: { $set: payload },
                    isAdaAllocationModeSelected: { $set: false },
                });
            },
            [adaVisualActionTypes.RESET_ADA_VISUAL]: (state) => {
                return immutable(state, {
                    isAdaDataLoading: { $set: false },
                    isAdaReqFailed: { $set: false },
                    isAdaLoadingSuccess: { $set: false },
                    errorMsg: { $set: '' },
                    adaAllocationReqBody: { $set: {} },
                    isAdaAllocationModeSelected: { $set: false },
                    adaVisualData: { $set: [] },
                    adaVisualDataCopy: { $set: [] },
                    adaDriversData: { $set: {} },
                    isDriversDataLoading: { $set: false },
                    isDriversDataFailed: { $set: false },
                    timeConstraint: { $set: 'week' },
                    currentFiscalYearWeek: { $set: null },
                    startFiscalYearWeek: { $set: null },
                    endFiscalYearWeek: { $set: null },
                    startDateStamp: { $set: null },
                    endDateStamp: { $set: null },
                    adaGraphData: { $set: null },
                    isAdaFilterLoading: { $set: false },
                    isAdaFilterFailed: { $set: false },
                    filterLabels: { $set: {} },
                    departmentOptions: { $set: [] },
                    genderOptions: { $set: [] },
                    subCatOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    articleIdOptions: { $set: [] },
                    adaStandAloneReqBody: { $set: {} },
                    isHierarchyLoading: { $set: false },
                    isHierarchyFailed: { $set: false },
                    hierarchyData: { $set: [] },
                    hierarchyDataCopy: { $set: [] },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                });
            },
            [adaVisualActionTypes.RESET_ADA_FILTER_DATA]: (state) => {
                return immutable(state, {
                    isAdaFilterLoading: { $set: false },
                    isAdaFilterFailed: { $set: false },
                    filterLabels: { $set: {} },
                    departmentOptions: { $set: [] },
                    genderOptions: { $set: [] },
                    subCatOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    articleIdOptions: { $set: [] },
                    adaStandAloneReqBody: { $set: {} },
                    isAdaLoadingSuccess: { $set: false },
                    isAdaDataLoading: { $set: false },
                    isAdaReqFailed: { $set: false },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                });
            },
            [adaVisualActionTypes.GET_ADA_HIERARCHY_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isHierarchyLoading: { $set: true },
                    isHierarchyFailed: { $set: false },
                });
            },
            [adaVisualActionTypes.GET_ADA_HIERARCHY_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    isHierarchyLoading: { $set: false },
                    isHierarchyFailed: { $set: false },
                    hierarchyData: { $set: [...response.data.AdaData.lower_heirarchy_forecast_data] },
                    hierarchyDataCopy: { $set: [...response.data.AdaData.lower_heirarchy_forecast_data] },
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: false },
                });
            },
            [adaVisualActionTypes.GET_ADA_HIERARCHY_DATA_FAILED]: (state) => {
                return immutable(state, {
                    isHierarchyLoading: { $set: false },
                    isHierarchyFailed: { $set: true },
                });
            },
            [adaVisualActionTypes.UPDATE_FORECAST_MULTIPLIER]: (state, { payload }) => {
                const { strt_dt, end_dt, forecastValue } = payload;
                const updateAdaData = cloneDeep(state.adaVisualData);
                const updatedGraphData = {
                    labels_data: [],
                    actual_data: [],
                    ia_data: [],
                    adjusted_data: [],
                };

                updateAdaData.forEach(item => {
                    if (item.fy_fw >= strt_dt && item.fy_fw <= end_dt) {
                        item['forecast_multiplier'] = forecastValue;
                        item['adjusted_forecast'] = forecastValue * item['ia_forecast'];
                    } else {
                        //No code here
                    }
                });

                updateAdaData.forEach((item) => {
                    updatedGraphData.labels_data.push(`W${item.week}`);
                    updatedGraphData.ia_data.push(item.ia_forecast);
                    if (item.fy_fw <= payload.current_fw) {
                        updatedGraphData.actual_data.push(item.actual_sales);
                        item.fy_fw == payload.current_fw ?
                            updatedGraphData.adjusted_data.push(item.adjusted_forecast)
                            : updatedGraphData.adjusted_data.push(undefined);
                    } else {
                        updatedGraphData.adjusted_data.push(item.adjusted_forecast);
                    }
                });

                return immutable(state, {
                    adaVisualData: { $set: updateAdaData },
                    adaGraphData: { $set: updatedGraphData },
                    isForecastModified: { $set: true },
                    isHierarchyModified: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewSuccess: { $set: false },
                    isSaveSuccess: { $set: false },
                });
            },
            [adaVisualActionTypes.CHANGE_HIERARCHY]: (state, { payload }) => {
                const updateHierarchy = cloneDeep(state.hierarchyData);
                updateHierarchy.forEach(item => {
                    if (item['article'] === payload.art && item['fy_fw'] === payload.date) {
                        item['adjusted_forecast'] = payload.input;
                    } else {
                        //No code here
                    }
                });

                return immutable(state, {
                    hierarchyData: { $set: updateHierarchy },
                    isReviewFailed: { $set: false },
                    isReviewSuccess: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: true },
                    isSaveSuccess: { $set: false },
                });
            },
            [adaVisualActionTypes.REVIEW_ADA_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: true },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                });
            },
            [adaVisualActionTypes.REVIEW_ADA_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    hierarchyData: { $set: [...response.indHierarchyData.AdaData.lower_heirarchy_forecast_data] },
                    hierarchyDataCopy: { $set: [...response.indHierarchyData.AdaData.lower_heirarchy_forecast_data] },
                    adaVisualData: { $set: [...response.adaData] },
                    adaVisualDataCopy: { $set: [...response.adaData] },
                    adaGraphData: { $set: response.adaGraphData },
                    isReviewSuccess: { $set: true },
                    isReviewFailed: { $set: false },
                    isReviewReq: { $set: false },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                });
            },
            [adaVisualActionTypes.REVIEW_ADA_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isReviewSuccess: { $set: false },
                    isReviewFailed: { $set: true },
                    isReviewReq: { $set: false },
                    errorMsg: { $set: error },
                });
            },
            [adaVisualActionTypes.SAVE_ADA_DATA_REQUEST]: (state) => {
                return immutable(state, {
                    isSaveReq: { $set: true },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: false },
                });
            },
            [adaVisualActionTypes.SAVE_ADA_DATA_SUCCESS]: (state, { response }) => {
                return immutable(state, {
                    hierarchyData: { $set: [...response.indHierarchyData.AdaData.lower_heirarchy_forecast_data] },
                    hierarchyDataCopy: { $set: [...response.indHierarchyData.AdaData.lower_heirarchy_forecast_data] },
                    adaVisualData: { $set: [...response.adaData] },
                    adaVisualDataCopy: { $set: [...response.adaData] },
                    adaGraphData: { $set: response.adaGraphData },
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: true },
                    isSaveFailed: { $set: false },
                    isReviewSuccess: { $set: false },
                    isForecastModified: { $set: false },
                    isHierarchyModified: { $set: false },
                });
            },
            [adaVisualActionTypes.SAVE_ADA_DATA_FAILED]: (state, { error }) => {
                return immutable(state, {
                    isSaveReq: { $set: false },
                    isSaveSuccess: { $set: false },
                    isSaveFailed: { $set: true },
                    errorMsg: { $set: error },
                });
            },
            [adaVisualActionTypes.RESTORE_ADA_DATA]: (state) => {
                const { adaVisualDataCopy } = state;
                return immutable(state, {
                    adaVisualData: { $set: adaVisualDataCopy },
                    isForecastModified: { $set: false },
                });
            },
            [adaVisualActionTypes.RESTORE_HIERARCHY_DATA]: (state) => {
                const { hierarchyDataCopy } = state;
                return immutable(state, {
                    hierarchyData: { $set: hierarchyDataCopy },
                    isHierarchyModified: { $set: false },
                });
            },
        },
        adaVisualInitialState
    ),
};

export default adaVisualReducer;