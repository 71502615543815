import React, { useEffect, useState } from "react";
import "../../../components/Filters/Filters.css";
import MultiSelect from "../../../components/Filters/MultiSelect";
import Select from "../../../components/Filters/SingleSelect";
import { connect } from "react-redux";
import {
    resetFiltersOptions,
    fetchFilterData
} from "./Actions/ProductStatusAction";
import {
    resetTableData
} from "./Actions/CreateConfigAction";
import * as Notify from "../../../components/Notification/Notifications";
import Notification from "../../../components/Notification/Notifications";
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping"
import { intersectionWith, isEqual } from "lodash";
import { getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter } from "../../../utils/commonUtilities";
import AsyncSingle from "../../../components/Filters/AsyncSingle";


const ProductStatusFilters = React.memo(
    ({
        ...props
    }) => {
        const [request, setRequest] = useState(null)

        const [department, setDepartment] = useState(null);
        const [gender, setGender] = useState(null);
        const [rbu, setRbu] = useState(null);
        const [dcs, setDcs] = useState(null);
        const [level5, setLevel5] = useState(null);
        const [style, setStyle] = useState(null)
        const [color, setColor] = useState(null)
        const [size, setSize] = useState(null)
        const [productType, setProductType] = useState(null)

        const [departmentOptions, setDepartmentOptions] = useState(null);
        const [genderOptions, setGenderOptions] = useState(null);
        const [rbuOptions, setRbuOptions] = useState(null);
        const [dcsOptions, setDcsOptions] = useState(null);
        const [level5Options, setLevel5Options] = useState(null);
        const [styleOptions, setStyleOptions] = useState(null);
        const [colorOptions, setColorOptions] = useState(null);
        const [sizeOptions, setSizeOptions] = useState(null)
        const [productTypeOptions, setProductTypeOptions] = useState(null)

        const [applyToNewProducts, setApplyToNewProducts] = useState(false)

        useEffect(() => {
            setDepartmentOptions(() => props.department);
            setGenderOptions(() => props.gender);
            setRbuOptions(() => props.rbu);
            setDcsOptions(() => props.dcs)
            setLevel5Options(() => props.level5)
            setStyleOptions(() => props.style)
            setColorOptions(() => props.color)
            setProductTypeOptions(() => props.produtType)

        },
            [props.department, props.dcs, props.level5, props.gender, props.rbu, props.style,
            props.color, props.produtType]);


        useEffect(() => {
            props.fetchFilterData({}, "department");
        }, []);

        const resetRestFilters = (p_index) => {
            // props.resetTableData()
            if (p_index == 0) {
                gender && setGender(null);
                genderOptions && setGenderOptions(null)
            }
            if (p_index == 0 || p_index == 1) {
                rbu && setRbu(null);
                rbuOptions && setRbuOptions(null)
            }
            if (p_index == 0 || p_index == 1 || p_index == 2) {
                dcs && setDcs(null);
                dcsOptions && setDcsOptions(null)
            }
            if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3) {
                level5 && setLevel5(null);
                level5Options && setLevel5Options(null);
                color && setColor(null)
                colorOptions && setColorOptions(null)
                style && setStyle(null)
                styleOptions && setStyleOptions(null)
            }
            if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4 || p_index == 5) {
                color && setColor(null)
                colorOptions && setColorOptions(null)
                productType && setProductType(null)
                productTypeOptions && setProductTypeOptions(null)
            }
        };

        useEffect(() => {
            if (props.selectedFilters?.l1_name) {
                setDepartment({ value: props.selectedFilters.l1_name, label: props.selectedFilters.l1_name })
            }
            if (props.selectedFilters?.l2_name) {
                setGender({ value: props.selectedFilters.l2_name, label: props.selectedFilters.l2_name })
            }
            if (props.selectedFilters?.l3_name) {
                setRbu({ value: props.selectedFilters.l3_name, label: props.selectedFilters.l3_name })
            }
            if (props.selectedFilters?.l4_name) {
                setDcs({ value: props.selectedFilters.l4_name, label: props.selectedFilters.l4_name })
            }
            if (props.selectedFilters?.l5_name) {
                setLevel5({ value: props.selectedFilters.l5_name, label: props.selectedFilters.l5_name })
            }
            if (props.selectedFilters?.style) {
                setStyle({ value: props.selectedFilters.style, label: props.selectedFilters.style })
            }
            if (props.selectedFilters?.color) {
                setColor({ value: props.selectedFilters.color, label: props.selectedFilters.color })
            }
            if (props.selectedFilters?.product_type) {
                setProductType({ value: props.selectedFilters.product_type, label: props.selectedFilters.product_type })
            }
        }, [props.selectedFilters])

        const onDepartmentChange = (val) => {
            setDepartment(val);
            resetRestFilters(0);
            props.fetchFilterData({ l1_name: [val.value] }, "gender");
            props.fetchFilterData({ l1_name: [val.value] }, "style_name");
            props.fetchFilterData({ l1_name: [val.value] }, "color");
            props.fetchFilterData({ l1_name: [val.value] }, "product_type");

        };
        const onGenderChange = (val) => {
            setGender(val);
            setRequest(old => {
                return {
                    ...old,
                    'gender': val
                }
            })
            resetRestFilters(1)
            // API call
            // if (valuesArray[0].length) {
            props.fetchFilterData({
                l1_name: [department.value],
                l2_name: [val.value]
            },
                "sub_category"
            );
            props.fetchFilterData({
                l1_name: [department.value],
                l2_name: [val.value]
            }, "style_name");
            props.fetchFilterData({
                l1_name: [department.value],
                l2_name: [val.value]
            }, "color");
            props.fetchFilterData({
                l1_name: [department.value],
                l2_name: [val.value]
            }, "product_type");
            // }
        };
        const onRbuChange = (val) => {
            setRbu(val);
            setRequest(old => {
                return {
                    ...old,
                    'rbu': val
                }
            })
            resetRestFilters(2);
            //API call to get next filter
            // if (valuesArray[0].length) {
            props.fetchFilterData({ l1_name: [department.value], l2_name: gender?.value && [gender?.value], l3_name: [val.value] }, "dcs");
            props.fetchFilterData({ l1_name: [department.value], l2_name: gender?.value && [gender?.value], l3_name: [val.value] }, "style_name");
            props.fetchFilterData({ l1_name: [department.value], l2_name: gender?.value && [gender?.value], l3_name: [val.value] }, "color");
            props.fetchFilterData({ l1_name: [department.value], l2_name: gender?.value && [gender?.value], l3_name: [val.value] }, "product_type");

            // }
        };

        const onDCSChange = (val) => {
            setDcs(val);
            setRequest(old => {
                return {
                    ...old,
                    'dcs': val
                }
            })
            resetRestFilters(3);
            //API call to get next filter
            // if (valuesArray[0].length) {
            // }
            props.fetchFilterData({ l1_name: [department.value], l2_name: gender?.value && [gender?.value], l3_name: rbu?.value && [rbu?.value], l4_name: [val.value] }, "level5");
            props.fetchFilterData({ l1_name: [department.value], l2_name: gender?.value && [gender?.value], l3_name: rbu?.value && [rbu?.value], l4_name: [val.value] },
                "style_name");
            props.fetchFilterData({ l1_name: [department.value], l2_name: gender?.value && [gender?.value], l3_name: rbu?.value && [rbu?.value], l4_name: [val.value] },
                "color");
            props.fetchFilterData({ l1_name: [department.value], l2_name: gender?.value && [gender?.value], l3_name: rbu?.value && [rbu?.value], l4_name: [val.value] },
                "product_type");

        };

        const onLevel5Change = (val) => {
            setLevel5(val);
            setRequest(old => {
                return {
                    ...old,
                    'level5': val
                }
            })
            resetRestFilters(5);
            //API call to get next filter
            // if (valuesArray[0].length) {
            // }
            props.fetchFilterData({ l1_name: [department.value], l2_name: gender?.value && [gender?.value], l3_name: rbu?.value && [rbu?.value], l4_name: [val.value] },
                "style_name");
            props.fetchFilterData({ l1_name: [department.value], l2_name: gender?.value && [gender?.value], l3_name: rbu?.value && [rbu?.value], l4_name: [val.value] },
                "color");
            props.fetchFilterData({ l1_name: [department.value], l2_name: gender?.value && [gender?.value], l3_name: rbu?.value && [rbu?.value], l4_name: [val.value] },
                "product_type");

        };

        const onStyleChange = (val) => {
            setStyle({ value: val.label, label: val.label });
            setRequest(old => {
                return {
                    ...old,
                    'style': val
                }
            })
            resetRestFilters(4);
            // API call to get next filter
            // if (valuesArray[0].length) {
            props.fetchFilterData(
                {
                    l1_name: [department.value],
                    l2_name: gender?.value && [gender?.value],
                    l3_name: rbu?.value && [rbu?.value],
                    l4_name: dcs?.value && [dcs?.value],
                    l5_name: level5?.value && [level5?.value],
                    "style_name": [val.label]
                },
                "color"
            );
            props.fetchFilterData(
                {
                    l1_name: [department.value],
                    l2_name: gender?.value && [gender?.value],
                    l3_name: rbu?.value && [rbu?.value],
                    l4_name: dcs?.value && [dcs?.value],
                    l5_name: level5?.value && [level5?.value],
                    "style_name": [val.label]
                },
                "product_type"
            );
            // }
        }

        const onColorChange = (val) => {
            setColor(val);
            setRequest(old => {
                return {
                    ...old,
                    'color': val
                }
            })
            props.fetchFilterData(
                {
                    l1_name: [department.value],
                    l2_name: gender?.value && [gender?.value],
                    l3_name: rbu?.value && [rbu?.value],
                    l4_name: dcs?.value && [dcs?.value],
                    l5_name: level5?.value && [level5?.value],
                    "style_name": style?.value && [style?.value],
                    "color": [val.value]
                },
                "product_type"
            );
        }

        const onProductTypeChange = (val) => {
            setProductType(val)
        }

        const applyFilters = () => {
            if (department) {
                let req = {
                    "l1_name": department.value,
                    "l2_name": gender?.value,
                    "l3_name": rbu?.value,
                    "l4_name": dcs?.value,
                    "l5_name": level5?.value,
                    "style_name": style?.value,
                    "color": color?.value,
                    "product_type": productType?.value
                }
                props.applyFilters(req);
                setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department })
            } else {
                Notify.error("Please Select All Mandatory Options!!");
            }
        };

        const resetFilters = () => {
            setRequest(null)
            setDepartment(null);
            setGender(null);
            setRbu(null);
            setDcs(null);
            setLevel5(null);
            setStyle(null);
            setColor(null);
            setSize(null);
            setProductType(null)
            props.resetFiltersOptions([]);
        };

        useEffect(() => {
            if (props.selectedFilters?.l1_name) {
                setDepartment({ value: props.selectedFilters.l1_name, label: props.selectedFilters.l1_name })
            }
            else {
                getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange)
            }
        }, [])

        const setApply = (e) => {
            props.setAutoApply(e.target.checked)
        }


        return (
            <>
                {
                    !props.isPopupOpened &&
                    <Notification />
                }
                <section className="row section" style={{ flexDirection: "column" }}>

                    <div className="row">
                        <div className="col-md-3 col-md-3-dd required mb-3">
                            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
                            <Select
                                isDisabled={props.isDisabled}
                                name="department"
                                value={department}
                                onChange={onDepartmentChange}
                                placeHolder={`Select ${props.filterLabels?.level1}`}
                                options={departmentOptions?.length ? departmentOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd mb-3">
                            <label className="fnt-md fnt-bold">{props.filterLabels?.level2 || levelFiltersLabelFormatter("level2")}</label>
                            <Select
                                isDisabled={props.isDisabled}
                                name="gender"
                                value={gender}
                                onChange={onGenderChange}
                                placeHolder={`Select ${props.filterLabels?.level2 ? props.filterLabels?.level2 : levelFiltersLabelFormatter("level2")}`}
                                options={genderOptions?.length ? genderOptions : []}
                            />
                        </div>
                        {
                            !shouldHideForCurrentClient("l3_name") &&
                            <>
                                <div className="col-md-3 col-md-3-dd mb-3">
                                    <label className="fnt-md fnt-bold">{props.filterLabels?.level3 || levelFiltersLabelFormatter("level3")}</label>
                                    <Select
                                        isDisabled={props.isDisabled}
                                        name="rbu"
                                        value={rbu}
                                        onChange={onRbuChange}
                                        placeHolder={`Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : levelFiltersLabelFormatter("level3")}`}
                                        options={rbuOptions?.length ? rbuOptions : []}
                                    />
                                </div>
                                <div className="col-md-3 col-md-3-dd mb-3">
                                    <label className="fnt-md fnt-bold">{props.filterLabels?.level4 || levelFiltersLabelFormatter("level4")}</label>
                                    <Select
                                        isDisabled={props.isDisabled}
                                        name="dcs"
                                        value={dcs}
                                        onChange={onDCSChange}
                                        placeHolder={`Select ${props.filterLabels?.level4 ? props.filterLabels?.level4 : levelFiltersLabelFormatter("level4")}`}
                                        options={dcsOptions?.length ? dcsOptions : []}
                                    />
                                </div>
                            </>
                        }
                        {
                            !shouldHideForCurrentClient("l5_name") &&
                            <div className="col-md-3 col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                                <Select
                                    isDisabled={props.isDisabled}
                                    name="level5"
                                    value={level5}
                                    onChange={onLevel5Change}
                                    placeHolder={`Select ${props.filterLabels?.level5 ? props.filterLabels?.level5 : levelFiltersLabelFormatter("level5")}`}
                                    options={level5Options?.length ? level5Options : []}
                                />
                            </div>
                        }
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">Style Name</label>
                            <AsyncSingle
                                isDisabled={props.isDisabled}
                                name="Style Name"
                                dropdownValue={style}
                                onDropDownChange={onStyleChange}
                                placeHolder={'Select Style'}
                                optionsArray={styleOptions?.length ? styleOptions : []}
                            />
                            {/* <Select
                                isDisabled={props.isDisabled}
                                name="Style Name"
                                value={style}
                                onChange={onStyleChange}
                                placeHolder={"Select Style"}
                                options={styleOptions?.length ? styleOptions : []}
                            /> */}
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">Color</label>
                            <Select
                                isDisabled={props.isDisabled}
                                name="Color"
                                value={color}
                                onChange={onColorChange}
                                placeHolder={"Select Color"}
                                options={colorOptions?.length ? colorOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">Product Type</label>
                            <Select
                                isDisabled={props.isDisabled}
                                name="Product Type"
                                value={productType}
                                onChange={onProductTypeChange}
                                placeHolder={"Select Product Type"}
                                options={productTypeOptions?.length ? productTypeOptions : []}
                            />
                        </div>
                        {
                            props.isCreateConfig &&
                            <div className="checkbox__group align-items-end mb-2 mr-4">
                                <div className="checkbox">
                                    <input checked={props?.selectedFilters?.auto_apply} id="applyToNewProducts" onClick={setApply} type="checkbox" />
                                    <label>Apply this configuration to New Products</label>
                                </div>
                            </div>
                        }

                        <div style={{ marginTop: "1rem" }}>
                            <button
                                disabled={props.isDisabled}
                                onClick={applyFilters}
                                className="btn btn-primary filter-constraints-mode"
                                title="Apply filters"
                            >
                                <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                            </button>
                            <button
                                onClick={() => resetFilters("All")}
                                className="btn undo-constraints-mode"
                                title="Reset filters"
                            >
                                <i className="fa fa-undo mr-2"></i>Reset
                            </button>
                        </div>
                        <div className="required fnt-md pt-5" style={{ color: "red", marginTop: "1rem" }}>
                            <label></label>Fields are mandatory
                        </div>
                    </div>
                </section>
            </>
        );
    }
);

const mapStateToProps = ({ productStatus }) => ({
    filterLabels: productStatus.filterlabels,
    department: productStatus.departmentOptions,
    gender: productStatus.genderOptions,
    rbu: productStatus.rbuOptions,
    dcs: productStatus.dcsOptions,
    level5: productStatus.level5Options,
    style: productStatus.styleOptions,
    color: productStatus.colorOptions,
    produtType: productStatus.productTypeOptions
});

const mapDispatchToProps = (dispatch) => ({
    resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
    fetchFilterData: (payload, filterKey, filterType) =>
        dispatch(fetchFilterData(payload, filterKey, filterType)),
    resetTableData: () => dispatch(resetTableData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductStatusFilters);
