import React, { useEffect, useCallback, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce, isEmpty } from 'lodash';
import HierarchyTable from './HierarchyTable';
import PageLoader from "../../components/Loader/PageLoader";
import { NETWORK_ERROR_MSG, EMPTY_DATA_HELPER_MSG, EMPTY_HIERARCHY_MSG } from './AdaVisualConstants';
import * as adaVisualActions from './actions/AdaVisualActions';

/**
 * Hierarchy Forecast View component
 * @param {*} props 
 * @returns 
 */
const ForecastHierarchyView = (props) => {
    const [indArticle, setIndArticle] = useState({});
    const { adaVisualActions, startFiscalYearWeek, endFiscalYearWeek, currentFiscalYearWeek,
        adaAllocationReqBody, adaStandAloneReqBody, isAdaAllocationModeSelected,
        hierarchyData, isHierarchyFailed, isHierarchyLoading, isReviewSuccess } = props;

    useEffect(() => {
        if (!isReviewSuccess) {
            if (isAdaAllocationModeSelected) {
                const { adaReq } = adaAllocationReqBody;
                const hierarchyReqBody = {
                    start_date: startFiscalYearWeek ? startFiscalYearWeek : adaReq.current_fw,
                    end_date: endFiscalYearWeek ? endFiscalYearWeek : adaReq.current_fw,
                    articles: {
                        ...adaReq?.articles,
                    },
                };
                adaVisualActions.getAdaHierarchyData(hierarchyReqBody);
            } else {
                const { current_fw, level1, level2, level3, level4, articles } = adaStandAloneReqBody;
                const hierarchyReqBody = {
                    start_date: startFiscalYearWeek ? startFiscalYearWeek : current_fw,
                    end_date: endFiscalYearWeek ? endFiscalYearWeek : current_fw,
                    level1: [...level1],
                    level2: [...level2],
                    level3: level3 ? [...level3] : null,
                    level4: level4 ? [...level4] : null,
                    article: [...articles],
                };
                adaVisualActions.getAdaHierarchyData(hierarchyReqBody);
            };
        } else {
            //No code here
        }
    }, []);

    const debouncedHandleChange = useCallback(debounce((data) => {
        let dates = data.getAttribute('name').split('-');
        setIndArticle({
            art: data.parentElement.getAttribute('id'),
            date: Number(dates[1]),
            input: Number(data.value),
        });
    }, 200), []);

    useEffect(() => {
        if (!isEmpty(indArticle)) {
            adaVisualActions.changeHierarchy(indArticle);
        } else {
            // No code here
        }
    }, [indArticle]);

    function handleHierarchyChange(e) {
        if (e) {
            debouncedHandleChange(e.target);
        }
    }

    return (
        <>
            {
                isHierarchyFailed
                    ? <div className="error">{NETWORK_ERROR_MSG}</div>
                    : <PageLoader loader={isHierarchyLoading}>
                        {!isEmpty(hierarchyData) ? <HierarchyTable data={hierarchyData}
                            currentWeek={currentFiscalYearWeek}
                            handleHierarchyChange={handleHierarchyChange} />
                            : <div className="empty">{EMPTY_HIERARCHY_MSG}
                                <p>{EMPTY_DATA_HELPER_MSG}</p>
                            </div>}
                    </PageLoader>
            }
        </>
    )
}

const mapStateToProps = ({ adaVisual }) => ({
    startFiscalYearWeek: adaVisual.startFiscalYearWeek,
    endFiscalYearWeek: adaVisual.endFiscalYearWeek,
    currentFiscalYearWeek: adaVisual.currentFiscalYearWeek,
    adaAllocationReqBody: adaVisual.adaAllocationReqBody,
    isAdaAllocationModeSelected: adaVisual.isAdaAllocationModeSelected,
    adaStandAloneReqBody: adaVisual.adaStandAloneReqBody,
    hierarchyData: adaVisual.hierarchyData,
    hierarchyDataCopy: adaVisual.hierarchyDataCopy,
    isHierarchyLoading: adaVisual.isHierarchyLoading,
    isHierarchyFailed: adaVisual.isHierarchyFailed,
    isReviewSuccess: adaVisual.isReviewSuccess,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForecastHierarchyView);