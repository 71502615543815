import { set } from 'lodash';
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ErrorModal = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(true);

  const toggle = () => {
    setModal(!modal);
  }

  const handleCancel = () => {
    if(props.handleCancel)
    props.handleCancel()
    setModal(!modal)
  }

  const handleOk = () => {
    if(props.handleOk)
    props.handleOk()
    setModal(!modal)
  }

  const handleClose = () => {
    if(props.handleClose)
    props.handleClose()
}

  return (
    <div>
      <Modal onClosed={handleClose} scrollable={true} isOpen={modal} modalTransition={{ timeout: 600 }} backdropTransition={{ timeout: 700 }}
        toggle={toggle} className={className}>
        <ModalHeader toggle={toggle} className="modalHeader">Error</ModalHeader>
        <ModalBody>
        {props.message || 'This is sample Messages'}
        </ModalBody>
        <ModalFooter className="modalFooter">
          <Button color="primary" onClick={handleOk}>{props.buttonLabel || "OK"}</Button>{' '}
          <Button color="secondary" onClick={handleCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ErrorModal