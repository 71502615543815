import React, { useState } from 'react';
import ReactTable from '../../../../components/Table/ReactTable';
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from '../../../UserManagement/ColumnFilter';
import {
    INV_TABLE_COL_DROP_LABEL, INV_TABLE_COL_COLOR_COUNT_LABEL, INV_TABLE_COL_QUANTITY_LABEL,
    INV_TABLE_COL_DELIVERY_LABEL
} from './IndividualDropConstants';

/**
 * Table Plugin
 */
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

/**
 * DC source Data Section View Component
 * @param {*} props 
 * @returns 
 */
const InventoryDropTableView = (props) => {
    const [invDrpTableInst, setInvDrpTableInst] = useState(null);

    const onInventoryDropSourceMount = (inst) => {
        setInvDrpTableInst(inst);
    }

    const invDropTableColumns = React.useMemo(
        () => [
            {
                Header: INV_TABLE_COL_DROP_LABEL,
                accessor: "dc",
                width: 120,
                Filter: ColumnFilter,
            },
            {
                Header: INV_TABLE_COL_COLOR_COUNT_LABEL,
                accessor: "dc_name",
                width: 150,
                Filter: ColumnFilter,
            },
            {
                Header: INV_TABLE_COL_QUANTITY_LABEL,
                accessor: "dc_state",
                width: 150,
                Filter: ColumnFilter,
            },
            {
                Header: INV_TABLE_COL_DELIVERY_LABEL,
                accessor: "dc_country",
                width: 150,
                Filter: ColumnFilter,
            },
        ],
        [invDrpTableInst]
    );

    const invDropTableData = [];

    return (
        <div id="INVENTORY_DROP_TABLE" className="inventory-drop-table">
            <ReactTableWithPlugins
                getInstanceOnMount={(inst) => onInventoryDropSourceMount(inst)}
                shouldPagination
                pageSize={8}
                totalRecordsLen={invDropTableData.length}
                data={invDropTableData}
                columns={invDropTableColumns}
                renderMarkup='TableMarkup'
                keyRT="StrategyPageTable"
                initialSelectedRows={0}
                features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                tableId="newstore_invdrop"
            />
        </div>
    );
}

export default InventoryDropTableView;