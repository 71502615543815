import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from '../../../components/Table/ReactTable';
import WithDynamicPlugins from '../../../components/Table/WithPlugins';
import ColumnFilter from "../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import ExportFile from "../../../components/ExportFile/ExportFile";
import WeekFilter from './WeekFilter';
import PageLoader from "../../../components/Loader/PageLoader";
import { addDollar, twoDecimal } from "../../../utils/formatters/valueFormatters";
import {
    LOST_SALES_DETAILS_TABLE_HEADERS, LOST_SALES_REVENUE_LABEL, LOST_SALES_UNITS_LABEL,
    LOST_SALES_DETAILS_TABLE_HEADER, LOST_SALES_NETWORK_ERROR_MSG, LOST_SALES_NO_RECORDS_HELPER_MSG,
    LOST_SALES_NO_RECORDS_MSG,
} from './LostSalesConstants';
import * as lostSalesActions from './actions/LostSalesReportActions';
import './LostSalesReport.css';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const LostSalesReportDetailsTable = (props) => {
    const [lostSalesTableInst, setLostSalesTableInst] = useState(null)
    const handleLostSalesMount = (inst) => {
        setLostSalesTableInst(inst);
    }

    const lostSalesTableColumns = React.useMemo(
        () => [
            {
                sticky: "left",
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.FISCAL_WEEK,
                accessor: 'fiscal_week',
                disableFilters: true,
                width: 160,
            },
            {
                sticky: "left",
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.STORE_ID,
                accessor: 'store',
                Filter: (instance) => <ColumnFilter {...instance} placeholder="Search..." />,
                filter: 'bulkFilterSpaceSeperated',
                width: 80,
            },
            {
                sticky: "left",
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.ARTICLE_ID,
                accessor: 'article',
                Filter: ColumnFilter,
                width: 140,
            },
            {
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.STORE_NAME,
                accessor: 'store_name',
                Filter: ColumnFilter,
                width: 180,
            },
            {
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.STYLE_DESC,
                accessor: 'style_desc',
                Filter: ColumnFilter,
                width: 300,
            },
            {
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.STYLE_COLOR,
                accessor: 'style_color',
                Filter: ColumnFilter,
                width: 150,
            },
            {
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.COLOR,
                accessor: 'color',
                Filter: ColumnFilter,
                width: 150,
            },
            {
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.UNITS_SOLD,
                accessor: 'units',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                width: 150,
            },
            {
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.AVG_STORE_CLUSTER_UNITS,
                accessor: 'cluster_avg_sales',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                width: 150,
            },
            {
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.LOST_OPPORTUNITY_SALES,
                accessor: 'lost_sales',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                Cell: (inst) => (
                    <div>
                        {inst.value || inst.value === 0
                            ? addDollar(twoDecimal(inst.value))
                            : null}
                    </div>),
                width: 150,
            },
            {
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.LOST_OPPORTUNITY_UNITS,
                accessor: 'lost_units',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                width: 150,
            },
            {
                Header: LOST_SALES_DETAILS_TABLE_HEADERS.OPENING_INVENTORY,
                accessor: 'opening_inventory',
                Filter: NumberRangeColumnFilter,
                filter: 'between',
                width: 150,
            },
        ],
        []
    );

    const { lostSalesDetailsTableData, lostSalesUnitsValue, lostSalesRevenueValue, fiscalWeekOptions,
        lostSalesActionsProp, lostSalesRequestBody, isLostSalesAllDataFailed,
        isLostSalesDetailsDataLoading, isLostSalesDetailsDataFailed, latestWeek, lostSalesExcelData } = props;
    const { getLostSalesReportDetailsTableData } = lostSalesActionsProp;

    useEffect(() => {
        fiscalWeekOptions.sort(sortFiscalWeekToDesc)
    }, [fiscalWeekOptions])

    const sortFiscalWeekToDesc = (a, b) => {
        return parseInt(b.value) - parseInt(a.value)
    }

    const handleWeekSelect = (weekValue) => {
        const request = {
            ...lostSalesRequestBody,
            'week': [weekValue],
            'year': [new Date().getFullYear()],
        }
        getLostSalesReportDetailsTableData(request);
    }

    return (
        <PageLoader loader={isLostSalesDetailsDataLoading} loaderMessage='Loading...'>
            {lostSalesDetailsTableData && lostSalesDetailsTableData.length > 0
                ?
                (<div id="LOST_SALES_REPORT_DETAILS_TABLE_CONTAINER" className="lost_sales_details_table_container shadow-sm">
                    <h4 className="line-chart__heading">{LOST_SALES_DETAILS_TABLE_HEADER}</h4>
                    <span className="total_lost_opportunity">
                        <h5 className="line-chart__heading">{LOST_SALES_UNITS_LABEL}: {lostSalesUnitsValue}</h5>
                        <h5 className="line-chart__heading">{LOST_SALES_REVENUE_LABEL}: {`$${lostSalesRevenueValue.toFixed(0)}`}</h5>
                    </span>
                    <div className="d-flex justify-content-between mb-2">
                        <div className="mr-4" style={{ display: "flex" }}>
                            <WeekFilter fiscalWeekOptions={fiscalWeekOptions} handleWeekSelect={handleWeekSelect} latestWeek={latestWeek} />
                        </div>
                        <ExportFile text={"Excel"} data={lostSalesExcelData} fileName={"Lost_Sales_Report"} type={1} />
                    </div>
                    <ReactTableWithPlugins
                        getInstanceOnMount={(inst) => handleLostSalesMount(inst)}
                        shouldPagination
                        hideDropdown
                        pageSize={8}
                        totalRecordsLen={lostSalesDetailsTableData.length}
                        data={lostSalesDetailsTableData}
                        columns={lostSalesTableColumns}
                        renderMarkup='TableMarkup'
                        keyRT="sortAndSearch"
                        tableId="lost_sales"
                    />
                </div>)
                : ((isLostSalesAllDataFailed || isLostSalesDetailsDataFailed) ?
                    <div className="error">{LOST_SALES_NETWORK_ERROR_MSG}</div>
                    : <div className="empty">{LOST_SALES_NO_RECORDS_MSG}
                        <p>{LOST_SALES_NO_RECORDS_HELPER_MSG}</p>
                    </div>)
            }
        </PageLoader>
    );
}

const mapStateToProps = ({ lostSalesReport }) => ({
    lostSalesDetailsTableData: lostSalesReport.lostSalesDetailsTableData,
    lostSalesUnitsValue: lostSalesReport.lostSalesUnitsValue,
    lostSalesRevenueValue: lostSalesReport.lostSalesRevenueValue,
    fiscalWeekOptions: lostSalesReport.fiscalWeekOptions,
    lostSalesRequestBody: lostSalesReport.lostSalesRequestBody,
    isLostSalesAllDataFailed: lostSalesReport.isLostSalesAllDataFailed,
    isLostSalesDetailsDataLoading: lostSalesReport.isLostSalesDetailsDataLoading,
    isLostSalesDetailsDataFailed: lostSalesReport.isLostSalesDetailsDataFailed,
    latestWeek: lostSalesReport.latestWeek,
    lostSalesExcelData: lostSalesReport.lostSalesExcelData,
});

const mapDispatchToProps = (dispatch) => ({
    lostSalesActionsProp: bindActionCreators(lostSalesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LostSalesReportDetailsTable);