import React from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { Chart } from "primereact/chart";
import { isEmpty } from "lodash";

const BarChartHorizontal = (props) => {

  const splitMyString = (str, splitLength) => {
    let a = str.split(' '), b = [];
    while(a.length) b.push(a.splice(0,splitLength).join(' '));
    return b;
  }

  const options = {
    legend: {
      labels: {
        fontColor: "#495057",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: "#495057",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: "#495057",
            fontSize: 9,
            callback: function(label) {
              if (/\s/.test(label)) {
                return splitMyString(label,3)
              }else{
                return label;
              } 
        
          }
          },
        },
      ],
    },
  };

  return  (!isEmpty(props?.data?.datasets)) ? (<><Chart type={props.chartType} data={props.data} options={options} /> <div className="charts__axes"> <span><b>{props.text}</b></span> </div> </>) : <div class="overlay"><div>No data available</div></div>;
};

export default BarChartHorizontal;
