import React from 'react'

function ColumnFilter({
   ...instance
    //column: { filterValue, preFilteredRows, setFilter },
  }) {
    const {
      column : { filterValue, preFilteredRows, setFilter },
      resetInitalPageIndex,
      placeholder
    } = instance
    return ( 
      <input
        style={{width:"80%", margin:"4px", borderRadius:"2rem", paddingLeft:'5px', outline:'none', borderColor:'#343a40'}}
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          e.target.value && resetInitalPageIndex && resetInitalPageIndex(0)
        }}
        placeholder={placeholder || "Search..."}
      />
      )
}

export default ColumnFilter