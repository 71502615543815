export const storeConfigInitialSate = {
    isStoreConfigDetailsLoading: false,
    isStoreConfigDetailsFailed: false,
    isStoreConfigDetailsSuccess: false,
    newStoreDetailsData: [],
    newStoreOptionsData: [],
    selectedNewStore: [],
    selectedNewStoreCode: {},
    newStoreOpeningDate: null,
    sisterStoreDetailsData: [],
    sisterStoreOptionsData: [],
    selectedSisterStore: [],
    selectedSisterStoreCode: {},
    isDcSourceDataLoading: false,
    isDcSourceDataFailed: false,
    isDcSourceDataSuccess: false,
    dcSourceData: [],
    dcSourceRowsSelected: {},
    dcSourceSelectedRowsData: [],
    editedDcSourceRowsTime: [],
    isUpdateDcLeadTimeRequested: false,
    isUpdateDcLeadTimeFailed: false,
    isUpdateDcLeadTimeSuccess: false,
    editModeDetails: {
        isEditMode: false,
    },
};