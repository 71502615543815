import React, { useState } from 'react';
import { useEffect } from 'react';
import Select from "../../../components/Filters/SingleSelect";
import { LOST_SALES_WEEK_FILTER_LABEL } from './LostSalesConstants';
import "./LostSalesReport.css";

/**
 * Week Level Filetring Component
 * @param {*} props 
 * @returns 
 */
const WeekFilter = (props) => {
    const { fiscalWeekOptions, latestWeek, handleWeekSelect } = props;
    const [week, setWeek] = useState(fiscalWeekOptions && fiscalWeekOptions.length > 0 && latestWeek
        ? latestWeek : null);

    const onWeekChange = (week) => {
        setWeek(week);
        handleWeekSelect(week.value);
    };

    return (
        <div id="WEEK_FILTER_CONTAINER" style={{ display: "inline-flex" }}>
            <label className="fnt-md fnt-bold fnt-bold" style={{ marginTop: "2%" }}>{LOST_SALES_WEEK_FILTER_LABEL}</label>
            <Select
                id="WEEK_SELECTOR"
                name="week"
                value={week}
                onChange={onWeekChange}
                placeHolder="Select Week"
                options={fiscalWeekOptions?.length ? fiscalWeekOptions : []}
                className="week_selector"
            />
        </div>
    );
}

export default WeekFilter;