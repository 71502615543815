import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import { rowsCount } from "../Constraints/Constraints"
import {
  FETCH_FILTER_DATA,
  fetchFilterDataSuccesss,
  fetchFilterDataError,
  FETCH_STORE_GROUP_DATA,
  fetchStoreGroupDataSuccess,
  fetchStoreGroupDataError,
  FETCH_STORE_GRADE_DATA,
  fetchStoreGradeDataSuccess,
  fetchStoreGradeDataError,
  FETCH_STORE_DATA,
  fetchStoreDataSuccess,
  fetchStoreDataError,
  FETCH_CONSTRAINTS_POPUP,
  fetchPopUpDataSuccess,
  fetchPopUpDataError,
  UPDATE_TABLEDATA,
  updateTableDataSuccess,
  updateTableDataError,
  FETCH_MIN_PER_STORE_ERROR,
  FETCH_MIN_PER_STORE,
  fetchMinPerStoreSuccess,
  fetchMinPerStoreError,
} from "./ConstraintsAction";

import {
  getConstraintsFilterData,
  getConstraintsTableData,
  getPopUpData,
  updateTableData,
} from "../../routes/api";

import { cloneDeep, filter, isEmpty } from "lodash";

let numOr0 = n => isNaN(n) ? 0 : n

function* fetchStoreGroupDataWorker(action) {
  let l_payload = action.payload.filters
  let arrayProperty = {};
  let req_body = {}
  if(action.payload?.screen_type) {
    req_body = action.payload
  } 
  else{
    for (var key in l_payload) {
      if(Array.isArray(l_payload[key])){
        arrayProperty[key] = l_payload[key].map((ele) => ele.value)
      }
    }
       req_body = {
         "screen_type":"store_group_code",
         "level1":[l_payload?.department?.value],
        //  "level2":[l_payload?.gender?.value],
        //   "level3": [l_payload?.rbu?.value],
          // "Attributes":{
          //     "level1":[l_payload?.department?.value],
          //     "level2":[l_payload?.gender?.value],
          //     "level3":[l_payload?.rbu?.value],
          // },
          "Operators":{
              "NonAttributeColumn":["level1","level2","level3"]
          },
          "rowCount": action.payload.rowCount,
          "rowIndex": action.payload.rowIndex,
          "styleIndex": action.payload.styleIndex,
      }
  
      if(l_payload?.gender?.value){
        req_body["level2"]= [l_payload?.gender?.value]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level2"]
      }
      if(l_payload?.rbu?.value){
        req_body["level3"]= [l_payload?.rbu?.value]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level3"]
      }
  
      if(l_payload?.dcs?.value){
        req_body["level4"]= [l_payload?.dcs?.value]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level4"]
      }

      if(l_payload?.level5?.value){
        req_body["level5"]= [l_payload?.level5?.value]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level5"]
      }
  
      if(arrayProperty['colour']?.length > 0) {
        req_body["color"] = arrayProperty['colour']
      }
      if(arrayProperty['size']?.length > 0) {
        req_body["size"] = arrayProperty['size']
      }
      if(arrayProperty['store_grade']?.length > 0) {
        req_body["store_grade"] = arrayProperty['store_grade']
      }
      if(arrayProperty['style_name']?.length > 0) {
        req_body["style_name"] = arrayProperty['style_name']
        // req_body["style_name"] = arrayProperty['style_name']
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],'style_name']
      }
  }

  try {
    const response = yield call(getConstraintsTableData,req_body)
    if(response.status) {
      if(response?.data?.data?.Value?.length) {
    const func = (p_subRows=[]) => {
       return p_subRows?.map(val => {
         if(val.min_store){
          return {
            ...val,
            req: req_body,
            batchID: action.payload.pageIndex,
            min_store: Math.round(val?.min_store),
            min_store_sum: Math.round(val?.min_store_sum),
            max_store: Math.round(val?.max_store || 0),
            max_store_sum: Math.round(val?.max_store_sum || 0)
            // min_store: val?.min_store?.reduce((a, b) => 
            // (parseInt(numOr0(a)) + parseInt(numOr0(b))))
          }
         }
         return {
           ...val,
           req: req_body,
           batchID: action.payload.pageIndex
          }
        })
    }

    const responseWithAggregatedMin = response?.data?.data?.Value?.map(val => {
      return{
        ...val,
        batchID: action.payload.pageIndex,
        min_store: Math.round(val?.min_store),
        min_store_sum: Math.round(val?.min_store_sum),
        max_store: Math.round(val?.max_store),
        max_store_sum: Math.round(val?.max_store_sum),
        req: req_body,
        // min_store: val?.min_store?.reduce((a, b) => 
        // (parseInt(numOr0(a)) + parseInt(numOr0(b)))),
        ['subRows']:func(val.subRows)
      }
    })

    const { dcs } = responseWithAggregatedMin[0]

    responseWithAggregatedMin.map(item => {
      dcs?.forEach(dcName => {
        item[dcName] = item.dcs_time?.[dcName]
      })
    })

    const resp = {
      data: responseWithAggregatedMin,
      // data: response?.data?.data,
      status: {...response.status},
      error: {...response.error}
    }
      // console.log()
      yield put(fetchStoreGroupDataSuccess({ data: resp.data, styleIndex: response?.data?.data?.styleIndex, count: response?.data?.data?.Count, index:Number(response?.data?.data?.Index) + rowsCount, "mapping" : {[action.payload.pageIndex]:{...req_body}} }));
    }
    else {
      yield put(fetchStoreGroupDataSuccess({ data: [], styleIndex: 0, count: 0, index:0, "mapping" : {[action.payload.pageIndex]:{...req_body}} }));
    }
  }
    else{
      yield put(fetchStoreGroupDataError({ error: response.error }));
    }

  } catch (error) {
    console.log(error,'bjhbu')
      yield put(fetchStoreGroupDataError(error));
  }
    
}

function* fetchStoreGradeDataWorker(action) {
  let l_payload = action.payload.filters
  let arrayProperty = {};
  for (var key in l_payload) {
    if(Array.isArray(l_payload[key])){
      arrayProperty[key] = l_payload[key].map((ele) => ele.value)
    }
  }
    let req_body = {
       "screen_type":"store_grade",
       "level1":[l_payload?.department?.value],
      //  "level2":[l_payload?.gender?.value],
      //  "level3": [l_payload?.rbu?.value],
        // "Attributes":{
        //     "level1":[l_payload?.department?.value],
        //     "level2":[l_payload?.gender?.value],
        //     "level3":[l_payload?.rbu?.value],
        // },
        "Operators":{
            "NonAttributeColumn":["level1","level2","level3"]
        },
        "rowCount": action.payload.rowCount,
        "rowIndex": action.payload.rowIndex,
        "styleIndex": action.payload.styleIndex,
    }

    if(l_payload?.gender?.value){
      req_body["level2"]= [l_payload?.gender?.value]
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level2"]
    }
    if(l_payload?.rbu?.value){
      req_body["level3"]= [l_payload?.rbu?.value]
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level3"]
    }

    if(l_payload?.dcs?.value){
      req_body["level4"]= [l_payload?.dcs?.value]
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level4"]
    }

    if(l_payload?.level5?.value){
      req_body["level5"]= [l_payload?.level5?.value]
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level5"]
    }

    if(arrayProperty['colour']?.length > 0) {
      req_body["color"] = arrayProperty['colour']
    }
    if(arrayProperty['size']?.length > 0) {
      req_body["size"] = arrayProperty['size']
    }
    if(arrayProperty['store_grade']?.length > 0) {
      req_body["store_grade"] = arrayProperty['store_grade']
    }
    if(arrayProperty['style_name']?.length > 0) {
      // req_body["style_name"] = arrayProperty['style_name']
      req_body["style_name"] = arrayProperty['style_name']
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],'style_name']
    }

  try {
    const response = yield call(getConstraintsTableData,req_body)
    if(response.status) {
      if(response?.data?.data?.Value?.length) {
    const func = (p_subRows) => {
       return p_subRows.map(val => {
         if(val.min_store){
          return {
            ...val,
            min_store: Math.round(val?.min_store),
            min_store_sum: Math.round(val?.min_store_sum),
            max_store: Math.round(val?.max_store || 0),
            max_store_sum: Math.round(val?.max_store_sum || 0),
            // (parseInt(numOr0(a)) + parseInt(numOr0(b))))
          }
         }
         return val
        })
    }

    const responseWithAggregatedMin = response?.data?.data?.Value?.map(val => {
      return{
        ...val,
        min_store: Math.round(val?.min_store),
        min_store_sum: Math.round(val?.min_store_sum), 
        max_store: Math.round(val?.max_store),
        max_store_sum: Math.round(val?.max_store_sum),
        // (parseInt(numOr0(a)) + parseInt(numOr0(b)))),
        ['subRows']:func(val.subRows)
      }
    })

    const { dcs } = responseWithAggregatedMin[0]

    responseWithAggregatedMin.map(item => {
      dcs?.forEach(dcName => {
        item[dcName] = item.dcs_time?.[dcName]
      })
    })

    const resp = {
      data: responseWithAggregatedMin,
      // data: response?.data?.data,
      status: {...response.status},
      error: {...response.error}
    }

      yield put(fetchStoreGradeDataSuccess({ data: resp.data, styleIndex: response?.data?.data?.styleIndex, count: response?.data?.data?.Count, index:Number(response?.data?.data?.Index) + rowsCount}));
    }
    else {
      yield put(fetchStoreGradeDataSuccess({ data: [], styleIndex: 0, count: 0, index:0}));
    }
  }
    else{
      yield put(fetchStoreGradeDataError({ error: response.error }));
    }

  } catch (error) {
    console.log("store grade error", error)
      yield put(fetchStoreGradeDataError(error));
  }
}

function* fetchStoreDataWorker(action) {
  let l_payload = action.payload.filters
  let arrayProperty = {};
  for (var key in l_payload) {
    if(Array.isArray(l_payload[key])){
      arrayProperty[key] = l_payload[key].map((ele) => ele.value)
    }
  }
    let req_body = {
       "level1":[l_payload?.department?.value],
        "Operators":{
            "NonAttributeColumn":["level1","level2","level3"]
        },
        "rowCount": action.payload.rowCount,
        "rowIndex": action.payload.rowIndex,
        "styleIndex": action.payload.styleIndex,
    }
    if(l_payload?.gender?.value){
      req_body["level2"]= [l_payload?.gender?.value]
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level2"]
    }
    if(l_payload?.rbu?.value){
      req_body["level3"]= [l_payload?.rbu?.value]
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level3"]
    }

    if(l_payload?.dcs?.value){
      req_body["level4"]= [l_payload?.dcs?.value]
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level4"]
    }

    if(l_payload?.level5?.value){
      req_body["level5"]= [l_payload?.level5?.value]
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level5"]
    }

    if(arrayProperty['colour']?.length > 0) {
      req_body["color"] = arrayProperty['colour']
    }
    if(arrayProperty['size']?.length > 0) {
      req_body["size"] = arrayProperty['size']
    }
    if(arrayProperty['store_grade']?.length > 0) {
      req_body["store_grade"] = arrayProperty['store_grade']
    }
    if(arrayProperty['style_name']?.length > 0) {
      // req_body["style_name"] = arrayProperty['style_name']
      req_body["style_name"] = arrayProperty['style_name']
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],'style_name']
    }
    if(!isEmpty(action?.payload?.searchTermReq)) {
      req_body["searchColumns"] = {...action?.payload?.searchTermReq}
    }
    if(!isEmpty(action?.payload?.sortReq)) {
      req_body["sortColumn"] = {...action?.payload?.sortReq[0]}
    }

  try {
    const response = yield call(getConstraintsTableData,req_body)
    if (response.status) {

    if(response?.data?.data?.Value?.length) {
    const dataWithUniqueKey = response?.data?.data?.Value?.map(val => {
      return {
        ...val,
        'unique' : val.style_id+val.store_code
      }
    })

    const { dcs } = dataWithUniqueKey[0]
    console.log(dataWithUniqueKey,dcs,'csdsdcscs')

    dataWithUniqueKey.map(item => {
      dcs?.forEach(dcName => {
        item[dcName] = item.dcs_time?.[dcName]
      })
    })
    
    let resp = {
      data: dataWithUniqueKey,
      status: {...response.status},
      error: {...response.error}
    }

      yield put(fetchStoreDataSuccess({ data: resp.data, out_of_data: response?.data?.data?.out_of_data, styleIndex: response?.data?.data?.styleIndex,count: response?.data?.data?.Count, index: Number(response?.data?.data?.Index) + rowsCount, req:req_body }));
  }
    else{
      yield put(fetchStoreDataSuccess({ data: [], out_of_data: false, styleIndex: 0,count: 0, index: 0, req:req_body }));
    }
    }
    else {
      yield put(fetchStoreDataError({ error: response.error }));
    }

  } catch (error) {
    console.log(error,'sdcsdcs')
      yield put(fetchStoreDataError(error));
  }
}

function* fetchFilterDataWorker(action) {
  try {
    const { payload,filterType } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        payload[key] && (req[key] = payload[key].map((ele) => ele.value))
      }
    }
    if(filterType){
        req["filter_type"] = filterType;
    }
    const res = yield call(getConstraintsFilterData, req);
    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data?.filterMapping
      // let topObject = Object.keys(res.data.data[0])[0]
      for (const key in res.data.data[0]) {
      let k = "";
        if (key === "level1") {
          k = "departmentOptions";
        } else if (key === "level2") {
          k = "genderOptions";
        } else if (key === "level3") {
          k = "rbuOptions";
        } else if (key === "level4") {
          k = "dcsOptions";
        } else if (key === "level5") {
          k = "level5Options";
        } 
        else if(key === "style_name") {
          k = "styleOptions";
        } 
        else if(key === "color") {
            k = "colourOptions";
        } else if(key === "size") {
            k = "sizeOptions";
          }

        if(res?.data?.sentFilters?.filter_type == "store_grade"){
          data["storeGradeOptions"] = res.data.data.map((element) => ({
            value: element,
            label: element,
          }));
        }
        else{
          // if(res?.data?.sentFilters?.filter_type == "style_name"){
          //   data["styleOptions"] = res.data?.data
          // }
          // else{
            if(key==="style_name" || key==="color" || key==="size") {
                data[k] = res.data.data.map((element) => ({
                    value: element[key],
                    label: element[key],
                  }));
            }
            else{
                data[k] = res.data.data[0][key]?.filter(val => val).map((element) => ({
                    value: element,
                    label: element,
                  }));
            }
          // }
        }
      } 
      yield put(fetchFilterDataSuccesss({ data: data, key: action?.filterKey?.key   }));
    }
  } catch (error) {
    console.log(error,'sdchv')
    yield put(fetchFilterDataError({ error: "Something went wrong!" }));
  }
}
// TODO: Add colour and size in req body if in case of duplicates

function* fetchPopUpDataWorker(action) {
  let l_payload = {...action.payload?.rowData}
  let arrayProperty = {};
  for (var key in l_payload) {
    if(Array.isArray(l_payload[key])){
      arrayProperty[key] = l_payload[key].map((ele) => ele.value)
    }
  }
    let req_body = {
       "screen_type": action?.payload?.screen_type,
       "level1":[l_payload?.l1_name],
       "level2":[l_payload?.l2_name],
       "level3": [l_payload?.l3_name],
       "level4": [l_payload?.l4_name],
       "level5": [l_payload?.l5_name],
       "store_group_code": l_payload?.store_group_code,
       "store_grade": l_payload?.store_grade,
       "style": [l_payload?.style_id],
        "Attributes":{
            "level1":[l_payload?.l1_name],
            "level2":[l_payload?.l2_name],
            "level3":[l_payload?.l3_name],
            "level4": [l_payload?.l4_name],
            "level5": [l_payload?.l5_name],
            "style":[l_payload?.style_id]
        },
        "Operators":{
          "NonAttributeColumn":["level1","level2","level3","style"]
        }
    }

    // if(action.payload?.filters?.['store_grade']?.length > 0) {
    //   req_body["store_grade"] = action.payload?.filters?.['store_grade']?.map(ele => ele.value)
    // }

    // if(arrayProperty['colour']?.length > 0) {
    //   req_body["Attributes"]["color_description"] = arrayProperty['colour']
    // }
    // if(arrayProperty['size']?.length > 0) {
    //   req_body["Attributes"]["size"] = arrayProperty['size']
    // }
    // if(arrayProperty['style_name']?.length > 0) {
    //   req_body["style_name"] = arrayProperty['style_name']
    //   req_body["Attributes"]["style_name"] = arrayProperty['style_name']
    //   req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],'style_name']
    // }

  try {
    const response = yield call(getPopUpData,req_body)
    let refStores = response?.data?.data?.map(val => {
      return{
        value: val?.store_code,
        label: val?.store_name
      }
  })
  let responseWithRefStores = response?.data?.data?.map(val => {
    return {
      ...val,
      min_store: Math.round(val?.min_store),
      max_store: Math.round(val?.max_store),
      transit_time_sum: Math.round(val?.transit_time_sum),
      wos_sum: Math.round(val?.wos_sum),
      ['ref_store']: refStores,
      referStore: val.store_code
    }
  })

  const { dcs } = responseWithRefStores[0]

  responseWithRefStores.map(item => {
    dcs.forEach(dcName => {
      item[dcName] = item.dcs_time?.[dcName]
    })
  })
  
  let resp = {
    data: responseWithRefStores,
    status: {...response.status},
    error: {...response.error}
  }
    if (response.status) {
      yield put(fetchPopUpDataSuccess({ data: resp.data }));
    }
    else{
      yield put(fetchPopUpDataError({ error: resp.error }));
    }

  } catch (error) {
      yield put(fetchPopUpDataError(error));
  }
}

function* updateTableDataWorker(action) {
  let dataWithSubRows = []
  let req_body = {}
  let data = []
  let screen_type = action?.payload?.screen_type

  if(screen_type == "set_all") {
    req_body = action?.payload
  }
  else{
    dataWithSubRows = action?.payload?.data
    data = dataWithSubRows.map(({subRows,uniqueKey,l1_name,l2_name,l3_name,l4_name,color_desc,style_color_desc,size_desc,style_desc,store_name,store_grade,min_stock,max_stock,transit_time,wos,dc_store_1,dc_store_2,dc_store_3,dc_store_4,dc_store_5,dc_store_6,...keepAttrs}) => keepAttrs)
    req_body = {
      data,
      screen_type
    }
  }
  try {
    const response = yield call(updateTableData,req_body)
    if (response.status) {
      yield put(updateTableDataSuccess({ data: response.data }));
    }
    else{
      yield put(updateTableDataError({ error: response.error, screen_type }));
    }

  } catch (error) {
      yield put(updateTableDataError(error, screen_type ));
  }
}

function* fetchMinPerStoreWorker(action) {
  try {
    let l_payload = action.payload
    let arrayProperty = {};
    for (var key in l_payload) {
      if(Array.isArray(l_payload[key])){
        arrayProperty[key] = l_payload[key].map((ele) => ele.value)
      }
    }
      let req_body = {
        "screen_type":"min_store",
         "level1":[l_payload?.department?.value],
         "level2":[l_payload?.gender?.value],
          "level3": [l_payload?.rbu?.value],
          "Attributes":{
              "level1":[l_payload?.department?.value],
              "level2":[l_payload?.gender?.value],
              "level3":[l_payload?.rbu?.value],
          },
          "Operators":{
              "NonAttributeColumn":["level1","level2","level3"]
          }
      }

      if(l_payload?.dcs?.value){
        req_body["level4"]= [l_payload?.dcs?.value]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level4"]
      }

      if(l_payload?.level5?.value){
        req_body["level5"]= [l_payload?.level5?.value]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level5"]
      }
  
      if(arrayProperty['colour']?.length > 0) {
        req_body["color"] = arrayProperty['colour']
        req_body["Attributes"]["color_description"] = arrayProperty['colour']
      }
      if(arrayProperty['size']?.length > 0) {
        req_body["size"] = arrayProperty['size']
        req_body["Attributes"]["size"] = arrayProperty['size']
      }
      if(arrayProperty['store_grade']?.length > 0) {
        req_body["store_grade"] = arrayProperty['store_grade']
      }
      if(arrayProperty['style_name']?.length > 0) {
        req_body["style_name"] = arrayProperty['style_name']
        req_body["Attributes"]["style_name"] = arrayProperty['style_name']
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],'style_name']
      }

    const res = yield call(getConstraintsTableData, req_body);
    if (res.data.status) {
      yield put(fetchMinPerStoreSuccess({ data: Math.round(res?.data?.data[0].min_store) }));
    }
  } catch (error) {
    yield put(fetchMinPerStoreError({ error: "Something went wrong!" }));
  }
}

function* fetchStoreGroupData() {
  yield takeLatest(FETCH_STORE_GROUP_DATA, fetchStoreGroupDataWorker);
}

function* fetchStoreGradeData() {
  yield takeLatest(FETCH_STORE_GRADE_DATA, fetchStoreGradeDataWorker);
}

function* fetchStoreData() {
  yield takeLatest(FETCH_STORE_DATA, fetchStoreDataWorker);
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* fetchPopUpDataWatcher() {
  yield takeLatest(FETCH_CONSTRAINTS_POPUP, fetchPopUpDataWorker);
}

function* updateTableDataWatcher() {
  yield takeLatest(UPDATE_TABLEDATA, updateTableDataWorker);
}

function* fetchMinPerStoreWathcer() {
  yield takeLatest(FETCH_MIN_PER_STORE, fetchMinPerStoreWorker)
}

export function* constraintsSaga() {
  yield all([
    fetchStoreGroupData(),
    fetchStoreGradeData(),
    fetchStoreData(),
    fetchFilterDataWatcher(),
    fetchPopUpDataWatcher(),
    fetchMinPerStoreWathcer(),
    updateTableDataWatcher(),
  ]);
}
