import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import { demandSaga } from "./DemandSaga"
import { storeConfigSaga } from './StoreDetailsConfigSagas/StoreConfigSagas';

/**
 * Root saga
 */
export function* newStoreSetUpSagas() {
    yield all([
        storeConfigSaga(),
        demandSaga()
    ]);
}