import React from 'react';
import EnterNewStoreSectionView from './EnterNewStoreSectionView';
import SisterStoreSectionView from './SisterStoreSectionView';
import DCSourcingContainer from './DCSourcingContainer';
import "./Storedetailsconfig.css";

/**
 * Dc Source View Component
 * @returns DC Source Component
 */
const StoreDetailsDcConfigView = (props) => {
    return (
        <React.Fragment>
            <div className="section_container shadow-sm">
                <EnterNewStoreSectionView />
            </div>
            <div className="section_container shadow-sm">
                <SisterStoreSectionView />
            </div>
            <React.Fragment>
                <DCSourcingContainer setDcSrcRowsSelection={props?.setDcSrcRowsSelection} />
            </React.Fragment>
        </React.Fragment >
    )
}


export default StoreDetailsDcConfigView;