import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginUser, fetchLoginApi } from './LoginActions';
import PageLoader from "../../components/Loader/PageLoader"
import _ from 'lodash';
import './Login.css';
import jwt_decode from 'jwt-decode'
import { getLoginPageImage } from "../../utils/filterLevelMapping"
// import posthog from 'posthog-js';
import { isApplicationLogin, isSSOLogin } from "../../utils/commonUtilities"
import SSOLogin from "./SSOLogin"

import {
  STORE_PAGE,
  DASHBOARD_PAGE,
  USER_MANAGEMENT_PAGE,
  CONTACT_US,
} from "../../constants/routeConstants"

class Login extends Component {
  state = {
    username: '',
    password: '',
    errors: {},
    errorMessage: false,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEmpty(this.props.loginData) && !this.props.errors && this.props.loginData !== prevProps.loginData) {
      localStorage.setItem('access_token', this.props.loginData.token);

      var decoded = jwt_decode(this.props.loginData.token)
      localStorage.setItem('group_id', decoded.group_id)
      localStorage.setItem('userName', decoded.name)
      localStorage.setItem('email', decoded.email)
      localStorage.setItem('user_id', decoded.user_id)
      localStorage.setItem('retail_pro_id', decoded.retail_pro_id)
      localStorage.setItem("column_preferences", JSON.stringify(decoded?.column_preferences?.[0] || {}))
      sessionStorage.setItem('isActive', true)
      this.props.startInterval()
      // localStorage.setItem('group_name',decoded.group_name)
      if (decoded.group_id === 2) {
        this.props.history.push(USER_MANAGEMENT_PAGE);
      }
      else {
        this.props.history.push(DASHBOARD_PAGE);
      }
    }
    // if (prevProps.errors) {
    //   this.setState({ errors: prevProps.errors });
    // }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    if (this.state.email && this.state.password) {
      this.props.fetchLoginApi(userData);
      // posthog.people.set({ email: this.state.email });
      // posthog.identify(this.state.email);
    } else {
      this.setState({
        errors: {
          message: 'Email and password required',
          password: '',
          email: '',
        },
      });
    }
  };

  keyPress = (e) => {
    if (e.keyCode === 13) {
      const userData = {
        email: this.state.email,
        password: this.state.password,
      };
      this.props.fetchLoginApi(userData);
    }
  };

  onContactUsHandler = () => {
    window.location.assign(CONTACT_US);
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="d-md-flex h-md-100 align-items-center" style={{ overflowY: 'hidden' }}>
        <div
          id="leftImgdiv"
          className="col-md-4 p-0 bg-indigo h-md-100"
          style={{ backgroundImage: getLoginPageImage() }}>
          <div className="divleftContent">
            <div
              id="subdiv-login"
              className="text-white d-md-flex align-items-center p-5 text-center ">
              <div className="logoarea pt-5 pb-5" style={{ width: '100%' }}>
                <h5 className="mb-4 mt-5" style={{ color: 'white', fontSize: "2.5rem" }}>
                  Allocate Smart
                </h5>
                <div id="btndiv-login">
                  <button
                    onClick={() => this.onContactUsHandler()}
                    className="btn btn-primary btn-square shadow-sm btncontact">
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
            <footer className="page-footer">
              <div className="footer-copyright text-center py-3 text-white" style={{ fontSize: "1rem" }}>
                ©copyright@impactanalytics{new Date().getFullYear()}
              </div>
            </footer>
          </div>
        </div>
        <div id="divRight" className="col-md-8 h-md-100 loginarea">
              <PageLoader loader={this.props.loading}>
                <div id="signinform">
                {
                  isApplicationLogin() && (
                    <form
                    className="formWidth"
                    onSubmit={this.onSubmit}
                    onKeyUp={(e) => this.keyPress(e)}>
                    <h2 id="signIntext">
                      Sign in to <span id="promoColor1">Allocate Smart</span>
                    </h2>
                    <p style={{ fontSize: "10px" }}>Enter your details below.</p>
                    <div className="form-group" style={{ width: '100%' }}>
                      <label className="form-label" htmlFor="email">
                        EMAIL
                      </label>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        id="loginInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="E-mail"
                        required=""
                        value={this.state.email}
                        onChange={this.onChange}
                        error={!_.isEmpty(errors) && errors.email ? errors.email : ''}
                      />
                    </div>
                    <div className="form-group" style={{ width: '100%' }}>
                      <label className="form-label" htmlFor="password">
                        PASSWORD
                      </label>

                      <input
                        name="password"
                        type="password"
                        className="form-control"
                        id="loginPassword"
                        placeholder="Password"
                        required=""
                        value={this.state.password}
                        onChange={this.onChange}
                        error={!_.isEmpty(errors) && errors.password ? errors.password : ''}
                      />
                      {/* temporary dummy link */}
                      {/* <span id="forgtpas">
                    <div className="text-cyan" style={{ cursor: 'pointer' }}>
                      Forgot your password?
                    </div>
                  </span> */}
                    </div>
                    {this.props.errors ? (
                      <span style={{ color: 'red', paddingBottom: "5px" }}>
                        No active account found with the given credentials!
                      </span>
                    ) : (
                      <span></span>
                    )}
                    {/* <p>{errors && errors.message}</p> */}
                    <div id="btndiv2">
                      <button type="submit" className="btn btn-primary btn-square shadow-sm btnsignin">
                        Sign in
                      </button>
                    </div>
                  </form>
                  )
                }
                {
                  isSSOLogin() && 
                    <SSOLogin />
                }
                </div>
              </PageLoader>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  errors: PropTypes.object.isRequired,
  success: PropTypes.object.isRequired,
};

const mapStateToProps = ({ login }) => ({
  errors: login.error,
  success: login.success,
  loading: login.loading,
  loginData: login.loginData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLoginApi: (payload) => dispatch(fetchLoginApi(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
