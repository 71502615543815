import React from "react";
import ReactTooltip from "react-tooltip";

const ReactTollTip =  React.memo(({ ...instance }) => {

  const { row, value, column }  = instance

  console.log(instance,'lbjhv')

  return (
    <>
        <div data-tip data-for={row.id + column.id} className="text__ellipsis">
            {value}
        </div>
        <ReactTooltip id={row.id + column.id} className="react__tool__tip__custom__class" place="bottom" effect="float">
            {value}
        </ReactTooltip>
    </>
  );
});

export default ReactTollTip;
