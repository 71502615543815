import { takeLatest, takeEvery, put, call, all, delay } from "redux-saga/effects";
import { getStoreListDetailsConfig, getDcSourceDetails, updateEditedDcLeadTime } from "../../../../routes/api";
import { cloneDeep } from 'lodash';
import * as actionTypes from "../../actions/StoreDetailsConfigActions/actionTypes";

/**
 * Saga Function to update dc lead time
 * @param  action 
 */
function* updateDcLeadTime(action) {
    try {
        const { payload } = action;
        const res = yield call(updateEditedDcLeadTime, { "changes": [...payload] })

        if (res.status) {
            yield put({ type: actionTypes.UPDATE_DC_SOURCE_LEAD_TIME_SUCCESS });
        }
    } catch (error) {
        yield put({ type: actionTypes.UPDATE_DC_SOURCE_LEAD_TIME_FAILED, error: "Something went wrong!" });

    }
}

/**
 * Saga Function to get dc source details
 * @param  action 
 */
function* getDCSourceDetails(action) {
    try {
        const { payload } = action;
        const res = yield call(getDcSourceDetails, payload);

        if (res.status && res?.data?.dc_details) {
            let response = {};
            const { dc_details, selected_dcs } = res.data;
            response['dc_details'] = dc_details;
            let initDcSourceSelectedRows = {};
            if (dc_details && selected_dcs && selected_dcs.length === 0) {
                dc_details.forEach((item, index) => {
                    initDcSourceSelectedRows[index] = true;
                });
                response['selectedRows'] = initDcSourceSelectedRows;
            } else {
                dc_details.forEach((item, index) => {
                    if (selected_dcs.includes(item?.dc)) {
                        initDcSourceSelectedRows[index] = true;
                    }
                });
                response['selectedRows'] = initDcSourceSelectedRows;
            }
            //selectedrowData.push(item)
            yield put({ type: actionTypes.GET_DC_SOURCE_DATA_SUCCESS, response });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_DC_SOURCE_DATA_FAILED, error: "Something went wrong!" });
    }
}

/**
 * Saga Function to get store details list
 * @param  action 
 */
function* getStoreConfigData(action) {
    try {
        const { payload } = action;

        const res = yield call(getStoreListDetailsConfig, payload);

        if (res.status) {
            let response = {};
            const { new_store_details, sister_store_details } = res?.data;
            response['new_store'] = cloneDeep(new_store_details);
            response['sister_store'] = cloneDeep(sister_store_details);
            response['new_store_options'] = new_store_details.sort(
                (one, two) => one.store_code - two.store_code).map((element) => ({
                    value: element.store_code,
                    label: element.store_code,
                }));
            response['sister_store_options'] = sister_store_details.sort(
                (one, two) => one.store_code - two.store_code).map((element) => ({
                    value: element.store_code,
                    label: element.store_code,
                }));

            yield put({ type: actionTypes.GET_STORE_CONFIG_DETAILS_SUCCESS, response });
        }

    } catch (error) {
        yield put({ type: actionTypes.GET_STORE_CONFIG_DETAILS_FAILED, error: "Something went wrong!" });
    }
}

/**
 * Update Dc Source Lead Time watcher
 */
function* updateDcSourceLeadTimeWatcher() {
    yield takeLatest(actionTypes.UPDATE_DC_SOURCE_LEAD_TIME_REQ, updateDcLeadTime);
}


/**
 * Store Details Request Watcher
 */
function* getDCSourceDetailsWatcher() {
    yield takeLatest(actionTypes.GET_DC_SOURCE_DATA_REQUEST, getDCSourceDetails);
}

/**
 * Store Details Request Watcher
 */
function* getStoreConfigDataWatcher() {
    yield takeLatest(actionTypes.GET_STORE_CONFIG_DETAILS_REQUEST, getStoreConfigData);
}

export function* storeConfigSaga() {
    yield all([
        getStoreConfigDataWatcher(),
        getDCSourceDetailsWatcher(),
        updateDcSourceLeadTimeWatcher(),
    ]);
}