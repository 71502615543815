export const demandInitialState = {
    demandData: [],
    demandDataLoading: false,
    demandDataError: null,
    reviewData: [],
    reviewDataLoading: false,
    reviewDataError: null,
    addProductsData: [],
    addProductsDataLoading: false,
    addProductsDataError: null,
    selectedDemandRows: [],
    newStoreDataLoading: false,
    newStoreDataError: null,
    newStoreData: [],
    saveLoading: false,
    saveNewStoreSuccess: false,
    saveNewStoreError: null,
    newAllocationLoading: false,
    newAllocationData: [],
    newAllocationError: null,
    newAllocationSuccess: false,
    articleAllocationData: [],
    articleAllocationLoading: false,
    articleAllocationError: null,
    updateArticleAllocationSuccess: false,
    updateArticleAllocationLoading: false,
    updateArticleAllocationError: false

    // ppDataLoading: false,
    // ppData: ppData,
    // ppDataError: ppDataError
};