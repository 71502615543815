import * as actionTypes from "./DemandActionTypes"

/*
Function to dispatch filter options
*/

export const getDemandData = (payload) => ({
    type: actionTypes.GET_DEMAND_DATA,
    payload
})

/*
Function to set selected table rows
*/

export const setSelectedDemandTableRows = (payload) => ({
    type: actionTypes.SET_SELECTED_DEMAND_TABLE_ROWS,
    payload
})

/*
Function to get review data
*/

export const getReviewData = (payload) => ({
    type: actionTypes.GET_REVIEW_ALLOCATION_DATA,
    payload
})

/*
Function to get add products data
*/

export const getAddProductsData = (payload) => ({
    type: actionTypes.GET_ADD_PRODUCTS_DATA,
    payload
})

/*
Function to dispatch the action to save new store allocation
*/

export const saveNewStoreAllocation = (payload) => ({
    type: actionTypes.SAVE_NEW_STORE_ALLOCATION,
    payload
})

export const getNewStoreList = (payload) => ({
    type: actionTypes.GET_NEW_STORE_LIST,
    payload
})

export const reviewNewStoreAllocation = (payload) => ({
    type: actionTypes.REVIEW_NEW_STORE_ALLOCATION,
    payload
})

export const getArticleAllocationDetails = (payload) => ({
    type: actionTypes.GET_ARTICLE_ALLOCATION,
    payload
})

export const updateArticleAllocation = (payload) => ({
    type: actionTypes.UPDATE_ARTICLE_ALLOCATION,
    payload
})

/*
Action to reset the state to initial
 */

export const resetDemandData = () => ({
    type: actionTypes.RESET_ALL
})

export const resetReservedData = () => ({
    type: actionTypes.RESET_RESERVED_DATA
})

export const resetAddProductsData = () => ({
    type: actionTypes.RESET_ADD_PRODUCTS_DATA
})