import React, { useState, useEffect } from "react";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import "../Finalize.css"
import { connect } from "react-redux";
import { getProductData, getProductDetails,getStoreData } from '../FinalizeAction'
import PageLoader from '../../../components/Loader/PageLoader';
import ProductListTable from './ProductListTable'
import ProductDetailsTable from './ProductDetailsTable'

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ProductView = (props) => {
    const [selectedArticle, setSelectedArticle] = useState(null)
    const [RTInstance, setRTinstance] = useState({})

    const getProductDetails = (instance) => {
        if (instance) {
            setSelectedArticle(instance.article)
            setRTinstance({
                allocationcode: props.allocationCode,
                article: instance.article,
                color: instance.color
            })
        }
        let req = { allocationcode: props.allocationCode, article: instance ? instance.article : selectedArticle }
        if (props.viewAllocationCall) {
            req["page"] = "viewPastAllocation"
        }
        if (props.releasedDate) {
            req["released_date"] = props.releasedDate
        }
        props.getProductDetails(req)

    }

    useEffect(() => {
        if(props.activeTab == "fpv" && props.isPOASNFlow){
            let req = {
                allocationcode: props.allocationCode
            }
            if (props.isPOASNFlow) {
                req["article"] = props.articles
            }
            props.getProductData(req)
            props.getStoreData(req)
        }
        if (props.activeTab == "fpv" && !(props.viewAllocationCall && props.productData?.data?.length) && !props.isPOASNFlow) {
            let req = {
                allocationcode: props.allocationCode
            }
            if (props.viewAllocationCall) {
                req["page"] = "viewPastAllocation"
            }
            if (props.releasedDate) {
                req["released_date"] = props.releasedDate
            }
            req["rerelease_flag"] = props.retransmit ? props.retransmit : false
            if (!props?.is_new_store) {
                props.getProductData(req)
                props.getStoreData(req)
            }
        }
    }, [props.activeTab,props.isPOASNFlow])

    useEffect(()=>{
        if(!props.tableInstance?.data){
            let req = {
                allocationcode: props.allocationCode
            }
            if (props.isPOASNFlow) {
                req["article"] = props.articles
            }
            if (props.viewAllocationCall) {
                req["page"] = "viewPastAllocation"
                if (props.releasedDate) {
                    req["released_date"] = props.releasedDate
                }
            }
            req["rerelease_flag"] = props.retransmit ? props.retransmit : false
            props.getStoreData(req)
        }
    },[props.isPOASNFlow,props.viewAllocationCall,props.releasedDate])

    useEffect(() => {
        props.setTableInstance(props.storeData);
    }, [props?.storeData])

    const callAllProductApis = () => {
        let req = { allocationcode: props.allocationCode }
        if (props.isPOASNFlow) {
            req["article"] = props.articles
        }
        req["rerelease_flag"] = props.retransmit ? props.retransmit : false
        props.getProductData(req)
        getProductDetails(false)
    }

    const allocationDataColumns = React.useMemo(() => [
        {
            Header: '# Style Color',
            accessor: "style_color",
        },
        {
            Header: '# Allocated Stores',
            accessor: "store_cnt"
        },
        {
            Header: 'Allocated Stores per StyleColor',
            accessor: "stores_per_style_color",
            width: 250
        },
        {
            Header: 'Allocated Quantity',
            accessor: "allocated_qty"
        },
        {
            Header: 'DC Available',
            accessor: "non_allocated_qty"
        },
        {
            Header: 'Allocation Quantity (by Size)',
            columns: []
        },
        {
            Header: 'Allocation % (by Size)',
            columns: []
        }
    ])

    const prepareStoreGradeColumns = () => {
        let data = props.productAllocationData[0]
        console.log(data)
        data?.sizes?.map((s, index) => {
            allocationDataColumns[5].columns.push({
                Header: s + " ",
                accessor: row => row.sizes_allocated_qty[index],
                width: data?.sizes?.length == 1 ? 200 : 100

            })
            allocationDataColumns[6].columns.push({
                Header: s,
                accessor: row => row.sizes_allocated_perc[index],
                width: data?.sizes?.length == 1 ? 200 : 100

            })
        })
    }

    return (
        <div className="product__view__tables">
            <div className="f__table mb-4" >
                <PageLoader loader={props.productDataLoading} gridLoader={true}>
                    <div className="storesStore__heading">
                        <h1 className="fnt-md fnt-bold">Allocation Summary</h1>
                    </div>
                    {prepareStoreGradeColumns()}
                    {(props.productDataError) ? (<div className="error">{props.productDataError}</div>) :
                        (props.productAllocationData ? (
                            <ReactTableWithPlugins
                                data={(props.productAllocationData ? props.productAllocationData : [])}
                                columns={allocationDataColumns}
                                renderMarkup='TableMarkup'
                                tableId="finalize_product_view"
                            />
                        ) : null)}
                </PageLoader>
            </div>
            <div className="f__table mt-4">

                {/* <PageLoader loader={props.productDataLoading} gridLoader={true}> */}
                {(props.productDataError) ? (<div className="error">{props.productDataError}</div>) :
                    (props.productData.data.length ? (
                        <>
                            <div className="storesStore__heading">
                                <h1 className="fnt-md fnt-bold">Products Details</h1>
                            </div>
                            <ProductListTable
                                data={props.productData.data} sizes={props.productData.sizes}
                                getProductDetails={getProductDetails} />
                        </>
                    ) : null)}
                {/* </PageLoader> */}
            </div>
            <hr />
            <div className="f__table">
                <PageLoader loader={props.productDetailsDataLoading && !props.productDataLoading} gridLoader={true}>
                    {(props.productDetailsDataError) ? (<div className="error">{props.productDetailsDataError}</div>) :
                        (props.productDetails.data.length ? (
                            <ProductDetailsTable
                                isFinalized={props.isFinalized}
                                viewAllocationCall={props.viewAllocationCall}
                                data={props.productDetails.data}
                                callAllApis={callAllProductApis}
                                selectedRow={RTInstance}
                                sizes={props.productDetails.sizes} 
                                productDetails = {props.productDetails}  
                                originalAllocationCode={props.originalAllocationCode}  
                                allocationcode={props.allocationCode}
                                />
                        ) : null)}
                </PageLoader>
            </div>

        </div>
    )
}

const mapStateToProps = ({ finalize }) => {
    return {
        productDataLoading: finalize.productDataLoading,
        productData: finalize.productData,
        storeData: finalize.storeData,
        productDataError: finalize.productDataError,
        productDetailsDataLoading: finalize.productDetailsDataLoading,
        productDetails: finalize.productDetailsData,
        productDetailsDataError: finalize.productDetailsDataError,
        productAllocationData: finalize.productAllocationData
    }
}

const mapDispatchToProps = (dispatch) => ({
    getStoreData: (payload) => dispatch(getStoreData(payload)),
    getProductData: (payload) => dispatch(getProductData(payload)),
    getProductDetails: (payload) => dispatch(getProductDetails(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductView)