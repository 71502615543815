export const strategyConfigInitialState = {
    isStrategyConfigFilterLoading: false,
    isStrategyConfigFilterFailed: false,
    isStrategyConfigFilterSuccess: false,
    level1Options: [],
    level2Options: [],
    level3Options: [],
    level4Options: [],
    level5Options: [],
    styleOptions: [],
    colorOptions: [],
    seasonOptions: [],
    filterLabels: {},
    isStrategyConfigTableLoading: false,
    isStrategyConfigTableFailed: false,
    isStrategyConfigTableSuccess: false,
    strategyTableData: [],
    tableRowsSelected: {},
    filterClicked: false,
    isStrategyConfigDataLoading: false,
    isStrategyConfigDataFailed: false,
    isStrategyConfigDataSuccess: false,
    isStrategyConfigDeleteLoading: false,
    isStrategyConfigDeleteFailed: false,
    isStrategyConfigDeleteSuccess: false,
    strategyConfigData: [],
    editedConfigReq: {},
    isStrategyConfigSaveLoading: false,
    isStrategyConfigSaveFailed: false,
    isStrategyConfigSaveSuccess: false,
    totalCount: 0,
    nextIndex: 0,
    searchTermRequest:{}
}