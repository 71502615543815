import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../Reporting.css";
// import Filters from "./Filters"
import ExcessFilters from './ExcessFilters';
import ExcessReportGraph from "./ExcessReportGraph"
import ExcessTable from "./ExcessTable"
import { resetExcessInv } from "./Actions/ExcessAction"

const ExcessReport = (props) => {

    const [filters, setFilters] = useState({})
    const [call, setCall] = useState(false)

    const getExcessInvData = (filters) => {
        let req = {
            level1: [filters.department.value],
            level2: filters.gender && filters.gender.length > 0 ? filters.gender.map(item => item.value) : null,
            level3: filters.subCat && filters.subCat.length > 0 ? filters.subCat.map(item => item.value) : null,
            level4: filters.dcs && filters.dcs.length > 0 ? filters.dcs.map(item => item.value) : null,
            level5: filters.level5 && filters.level5.length > 0 ? filters.level5.map(item => item.value) : null,
            article: filters.articleId && filters.articleId.length > 0 ? filters.articleId.map(item => item.value) : null,
            style: filters.style && filters.style.length > 0 ? filters.style.map(item => item.value) : null,
            store: filters.storeId && filters.storeId.length > 0 ? filters.storeId.map(item => item.value) : null,
            color: filters.color && filters.color.length > 0 ? filters.color.map(item => item.value) : null,
            selected_fw: null
        }
        setFilters(req)
        setCall(true)
    }


    useEffect(() => {
        return () => {
            props.resetExcessInv()
        }
    }, [])

    useEffect(() => {
        if (props.activeTab !== "excess") {
            setCall(false)
            props.resetExcessInv()
        }
    }, [props.activeTab])

    useEffect(() => {
        setCall(false)
    }, [])

    return (
        <>
            {
                props.activeTab == "excess" &&
                <>
                    <ExcessFilters handleFilterRequest={getExcessInvData} />

                    <div className="excess-report-container">
                        <ExcessReportGraph filters={filters} call={call} />
                        <ExcessTable filters={filters} call={call} />
                    </div>
                </>
            }
        </>
    )
}

const mapStateToProps = ({ excessReport }) => {
    return {}
};

const mapDispatchToProps = (dispatch) => ({
    resetExcessInv: () => dispatch(resetExcessInv()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExcessReport);