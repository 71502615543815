export const adaVisualInitialState = {
    isAdaDataLoading: false,
    isAdaReqFailed: false,
    isAdaLoadingSuccess: false,
    errorMsg: '',
    adaAllocationReqBody: {},
    isAdaAllocationModeSelected: false,
    adaVisualData: [],
    adaVisualDataCopy: [],
    adaDriversData: {},
    isDriversDataLoading: false,
    isDriversDataFailed: false,
    timeConstraint: 'week',
    currentFiscalYearWeek: null,
    startFiscalYearWeek: null,
    endFiscalYearWeek: null,
    startDateStamp: null,
    endDateStamp: null,
    adaGraphData: null,
    isAdaFilterLoading: false,
    isAdaFilterFailed: false,
    filterLabels: {},
    departmentOptions: [],
    genderOptions: [],
    subCatOptions: [],
    dcsOptions: [],
    articleIdOptions: [],
    adaStandAloneReqBody: {},
    isHierarchyLoading: false,
    isHierarchyFailed: false,
    hierarchyData: [],
    hierarchyDataCopy: [],
    isReviewSuccess: false,
    isReviewFailed: false,
    isReviewReq: false,
    isForecastModified: false,
    isHierarchyModified: false,
    isSaveReq: false,
    isSaveSuccess: false,
    isSaveFailed: false,
};