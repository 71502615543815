import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import * as AdaActionsTypes from '../actions/actionTypes';
import { getWeeklyGraphData } from '../AdaUtilities';
import {
    getAdaVisualFilterData, getAdaAllocationData, getAdaStandAloneData,
    getAdaDriversData, getAdaHierarchyData, postAdaReviewData, postAdaSaveData,
} from "../../../routes/api";

function* postAdaSaveReq(action) {
    const { payload } = action;

    try {
        const res = yield call(postAdaSaveData, payload);
        const { data } = res;

        let response = {
            adaData: data.overall_data.AdaData,
            adaGraphData: getWeeklyGraphData(data.overall_data.AdaData, payload.current_fw),
            indHierarchyData: { ...data.individual_data },
        };

        yield put({ type: AdaActionsTypes.SAVE_ADA_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.SAVE_ADA_DATA_FAILED, error: "Network Request Failed!!!" }));
    }
}

function* postAdaReviewReq(action) {
    const { payload } = action;

    try {
        const res = yield call(postAdaReviewData, payload);
        const { data } = res;

        let response = {
            adaData: data.overall_data.AdaData,
            adaGraphData: getWeeklyGraphData(data.overall_data.AdaData, payload.current_fw),
            indHierarchyData: { ...data.individual_data },
        };

        yield put({ type: AdaActionsTypes.REVIEW_ADA_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.REVIEW_ADA_DATA_FAILED, error: "Network Request Failed!!!" }));
    }
}

function* fetchAdaHierarchy(action) {
    const { payload } = action;

    try {
        const response = yield call(getAdaHierarchyData, payload);
        yield put({ type: AdaActionsTypes.GET_ADA_HIERARCHY_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.GET_ADA_HIERARCHY_DATA_FAILED, error: "Something went wrong!" }));
    }
}

function* fetchAdaFilters(action) {
    try {
        const { payload, filterType } = action;
        let req = {};
        if (payload) {
            for (const key in payload) {
                req[key] = payload[key].map((ele) => ele.value);
            }
        }
        if (filterType) {
            req["filter_type"] = filterType;
        }
        const res = yield call(getAdaVisualFilterData, req);

        if (res.data.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping;
            let topObject = Object.keys(res.data.data[0])[0];
            let k = "";
            if (topObject === "level1") {
                k = "departmentOptions";
            } else if (topObject === "level2") {
                k = "genderOptions";
            } else if (topObject === "level3") {
                k = "subCatOptions";
            } else if (topObject === "level4") {
                k = "dcsOptions";
            }

            data[k] = res.data.data[0][topObject]?.filter(val => val).map((element) => ({
                value: element,
                label: element,
            }));

            data["articleIdOptions"] = res.data.data[0]["article"]?.filter(val => val).map((element) => ({
                value: element,
                label: element,
            }));

            yield put({ type: AdaActionsTypes.GET_ADA_FILTER_DATA_SUCCESS, data: data });
        }
    } catch (error) {
        yield put({ type: AdaActionsTypes.GET_ADA_FILTER_DATA_FAILED, error: "Something went wrong!" });
    }
}

function* fetchAdaDriversData(action) {
    const { payload } = action;
    const { driversReq } = payload;

    try {
        const response = yield call(getAdaDriversData, driversReq);
        yield put({ type: AdaActionsTypes.GET_ADA_DRIVERS_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.GET_ADA_DRIVERS_DATA_FAILED, error: "Something went wrong!" }));
    }
}

function* fetchAdaAllocationData(action) {
    const { payload } = action;
    const { adaReq } = payload;

    try {
        const res = yield call(getAdaAllocationData, adaReq);

        let response = {
            adaData: res.data.AdaData,
            adaGraphData: getWeeklyGraphData(res.data.AdaData, payload.current_fw),
        };

        yield put({ type: AdaActionsTypes.GET_ADA_ALLOCATION_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.GET_ADA_ALLOCATION_DATA_FAILED, error: "Something went wrong!" }));
    }
}

function* fetchAdaStandAloneData(action) {
    const { payload } = action;

    try {
        const res = yield call(getAdaStandAloneData, payload);
        let response = {
            adaData: res.data.AdaData,
            adaGraphData: getWeeklyGraphData(res.data.AdaData, payload.current_fw),
        };

        yield put({ type: AdaActionsTypes.GET_ADA_STANDALONE_DATA_SUCCESS, response });
    } catch (error) {
        yield put(({ type: AdaActionsTypes.GET_ADA_STANDALONE_DATA_FAILED, error: "Something went wrong!" }));
    }
}

function* watchAdaSaveRequest() {
    yield takeLatest(AdaActionsTypes.SAVE_ADA_DATA_REQUEST, postAdaSaveReq);
}

function* watchAdaReviewRequest() {
    yield takeLatest(AdaActionsTypes.REVIEW_ADA_DATA_REQUEST, postAdaReviewReq);
}

function* watchAdaHierarchyDataRequest() {
    yield takeLatest(AdaActionsTypes.GET_ADA_HIERARCHY_DATA_REQUEST, fetchAdaHierarchy);
}

function* watchAdaDriversDataRequest() {
    yield takeLatest(AdaActionsTypes.GET_ADA_DRIVERS_DATA_REQUEST, fetchAdaDriversData);
}

function* getAdaFiltersData() {
    yield takeEvery(AdaActionsTypes.GET_ADA_FILTER_DATA_REQUEST, fetchAdaFilters);
}

function* watchAdaAllocationDataRequest() {
    yield takeEvery(AdaActionsTypes.GET_ADA_ALLOCATION_DATA_REQUEST, fetchAdaAllocationData);
}

function* watchAdaStandAloneDataRequest() {
    yield takeLatest(AdaActionsTypes.GET_ADA_STANDALONE_DATA_REQUEST, fetchAdaStandAloneData);
}

export function* adaVisualSagas() {
    yield all([
        watchAdaAllocationDataRequest(),
        watchAdaStandAloneDataRequest(),
        getAdaFiltersData(),
        watchAdaDriversDataRequest(),
        watchAdaHierarchyDataRequest(),
        watchAdaReviewRequest(),
        watchAdaSaveRequest(),
    ]);
}