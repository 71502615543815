import React, { useState, useEffect, createRef } from "react";
import Modal from "react-awesome-modal";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import { isNull } from "lodash-es";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import { connect } from "react-redux";
import * as InventoryReservationActions from './Actions/InventoryReservationActions';
import PageLoader from "../../../components/Loader/PageLoader";
import { InputNumber } from "primereact/inputnumber";
import { bindActionCreators } from "redux";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const EditInventoryReservePacks=(props) =>{
    const [eachesValue,setEachesValue] = useState(null)
    const [eachesPercent,setEachesPercent] = useState(null)
    const [packValue,setPackValue] = useState(null)
    const [minPackValue,setMinPackValue] = useState(null)
    const [minEachesValue,setMinEachesValue] = useState(null)
    const [error,setError] = useState(null)
    const [errorMessage,setErrorMessage] = useState(null)
    const {data,InventoryReservationActionsProp,filter,dataRefresh}=props

    const closeAlert = () => {
        // props.setDcAvailableError(false)
        props.closeAlert()
    }

    
    const onInputNumberChange=(val,type)=>{
        if(type==="pack")
        {
            setPackValue(val)
        }
        if(type==="eaches"){
            setEachesValue(val)
        }
        if(type==="percentage"){
            setEachesPercent(val)
        } 
    }

    const onSave=()=>{
     var dataSend = data.reqData
     if(data.is_pack[0])
     {
         if(packValue===null)
         {
            return setErrorMessage("Enter the pack value")
         }
        if(packValue > minPackValue)
        {
            setErrorMessage(null)
           return setError(minPackValue)
        }
        dataSend.map(row=>{
            row.reserve_quantity=Array(row.size.length).fill(packValue)
        })
     }
     else{
         if(eachesPercent > 100)
         {
            
           return setErrorMessage("Maximum value can be 100")
         }
        else if(eachesPercent ===null)
         {
            return setErrorMessage("Enter the value")
         }
        else if(eachesPercent!==null){
         dataSend.map(row=>{
             row.reserve_quantity=Array(row.size.length).fill(eachesPercent)
             row.is_percentage=true
         })
        }
        else{
            if(eachesValue > minEachesValue)
            {
                setErrorMessage(null)
               return setError(minEachesValue)
            }
            dataSend.map(row=>{
                row.reserve_quantity=Array(row.size.length).fill(eachesValue)
                row.is_percentage=false
            })
        }
     }
        let req ={
            "data":dataSend,
            "is_bulk":true
        }
        InventoryReservationActionsProp.saveInventoryReservationData(req) 
       
    }

    useEffect(()=>{
        if(data.is_pack[0])
        {
            var min = Math.min(...data?.packs)
            setMinPackValue(min)
        }
        else{
            var min = Math.min(...data?.eaches)
            setMinEachesValue(min)
        }
    },[data])

    useEffect(()=>{
        if(props.saveSuccess)
        {
            dataRefresh(filter)
            closeAlert()
            InventoryReservationActionsProp.resetSaveInventoryReservationScreen()
        }
    },[props.saveSuccess])


    return (
       <>
       
        <Modal
            visible={props.showAlert}
            width="80%"
            // height="90%"
            effect="fadeInDown"
            onClickAway={closeAlert}
            alignItems="flexStart"
        >
          <Notification/>
        <div className="modal-container">
            <div className="modal-header">
                <h5 className="page-heading text-center m-0">
                    Edit Inventory Reserve Units
                </h5>
                <button type="button" className="close" data-dismiss="modal">
                    <span style={{ cursor: "pointer" }}>
                        <i
                            className="fa fa-times text-right m-2 "
                            aria-hidden="true"
                            onClick={closeAlert}
                        />
                    </span>
                </button>
            </div>
            <div className="modal-middle-container">
                <div className="mx-3 p-3 row justify-content-center">
                    {data.is_pack[0] ? 
                     <div className="p-field p-grid">
                     <label className="non-editable-labels p-col-fixed" style={{marginRight:"5px"}}>
                     Reserve #Packs
                     </label>
                     <div className="p-col">
                         <InputNumber inputId="pack_unit" min={0} max={minPackValue} value={null} 
                             onChange={(e) => onInputNumberChange(e.value,"pack")}
                             mode="decimal" useGrouping={false} className="editable-text-field" showButtons />
                     </div>
                     </div>
                    :
                    <>
                  {/* <div className="p-field p-grid">
                    <label className="non-editable-labels p-col-fixed" style={{marginRight:"5px"}}>
                        Reserve as Units
                    </label>
                    <div className="p-col">
                        <InputNumber inputId="eaches_unit" min={0} max={minEachesValue} value={null} 
                            onChange={(e) => onInputNumberChange(e.value,"eaches")}
                            mode="decimal" useGrouping={false} className="editable-text-field" showButtons />
                    </div>
                    </div> */}
                    <div className="p-field p-grid">
                    <label style={{fontWeight:"600",fontSize:"1.2rem",width:"36%"}}>
                       Reserve as % of Total available
                    </label>
                    <div className="p-col">
                        <InputNumber inputId="eaches_percent" min={0}  value={null} 
                             onChange={(e) => onInputNumberChange(e.value,"percentage")}
                            mode="decimal" useGrouping={false} className="editable-text-field" suffix="%"  showButtons />
                    </div>
                    </div>
                    </>
                     } 
                </div>
                {error!==null ? <div className="errorClass" style={{marginLeft:"35%"}}>Value must be less than or equals to {error}</div>:""}
                {errorMessage!==null ? <div className="errorClass" style={{marginLeft:"43%"}}>{errorMessage}</div>:""}
            </div>
                <div className="text-center">
                    <button
                        style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                        onClick={onSave}
                        className="btn btn-primary"
                    // disabled={isDisabled}
                    >
                        Save
                    </button>
                </div>
        </div>
    </Modal>
 
      
      
       </>
    )
}
const mapStateToProps = ({ InventoryReservation }) => ({
    saveSuccess:InventoryReservation.isInventoryReservationSaveSuccess
 });
 
 const mapDispatchToProps = (dispatch) => ({
     InventoryReservationActionsProp: bindActionCreators(InventoryReservationActions, dispatch),
 });

export default connect(mapStateToProps, mapDispatchToProps)(EditInventoryReservePacks)