import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { CREATE_USER, CREATE_USER_ERROR, CREATE_USER_SUCCESS, DELETE_USER_ERROR, DELETE_USER_SUCCESS, EDIT_USER, EDIT_USER_ERROR, EDIT_USER_SUCCESS, FETCH_USER_DATA, FETCH_USER_DATA_ERROR, FETCH_USER_DATA_SUCCESS, GET_FORM_DATA_SUCCESS, RESET_ALL } from "./UserManagementActions";


export const userManagementState = {
  userData: [],
  level1Options: [],
  roleOptions: [],
  error: null,
  createEditSuccess: false,
  createEditError: null,
  deleteSuccess: false,
  deleteError: null,
  userResult : null,
  loading: false,
};

const reducer = {
    userManagement: handleActions(
    {
      [RESET_ALL]: (state, {payload }) => {
        return immutable(state, {
          userData: {$set: []},
          level1Options: {$set:  []},
          roleOptions: {$set:  []},
          error: {$set:  null},
          createEditSuccess: {$set:  false},
          createEditError: {$set:  null},
          deleteSuccess: {$set:  false},
          deleteError: {$set:  null},
          userResult : {$set:  null},
          loading: {$set:  false},
        });
       },
      [FETCH_USER_DATA] : (state , {}) => {
            return immutable(state, {
                userData: {$set: []},
                level1Options: {$set: []},
                roleOptions: {$set: []},
                error: {$set: null},
                createEditError: { $set: null},
                createEditSuccess: { $set: false},
                deleteError: { $set: null},
                deleteSuccess: { $set: false},
                userResult: { $set: null },
                loading: { $set: false},
            })
      },
      [FETCH_USER_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          userData: {
            $set:
              payload && payload.data
                ? payload.data
                : state.userData,
          },
          error :{
              $set: null
          }
        });
      },
      [FETCH_USER_DATA_ERROR]: (state, {payload}) => {
          return immutable(state, {
              userData: [],
              error: { $set: (payload && payload.error) || null },
          })
      },
      [GET_FORM_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          level1Options: {
            $set:
              payload && payload.data
                ? payload.data.level1Options
                : state.level1Options,
          },
          roleOptions: {
            $set:
              payload && payload.data
                ? payload.data.roleOptions
                : state.roleOptions,
          },
          userResult: {
              $set: payload && payload.data ? payload.data.getUserResult : null
          }
        });
      },
      [CREATE_USER]: (state, {}) => {
        return immutable(state, {
          loading: { $set: true}
        })
      },
      [EDIT_USER]: (state, {}) => {
        return immutable(state, {
          loading: { $set: true}
        })
      },
      [CREATE_USER_SUCCESS]: (state, { }) => {
        return immutable(state, {
          createEditSuccess: { $set: true },
          loading: { $set: false},
          createEditError: { $set: null } ,
        });
      },
      [CREATE_USER_ERROR]: (state, { payload }) => {
        return immutable(state, {
            createEditSuccess: { $set: false },
            createEditError: { $set: (payload && payload.error) || null } ,
            loading: { $set: false},
        });
      },
      [EDIT_USER_SUCCESS]: (state, { }) => {
        return immutable(state, {
          createEditSuccess: { $set: true },
          createEditError: { $set: null } ,
          loading: { $set: false},
        });
      },
      [EDIT_USER_ERROR]: (state, { payload }) => {
        return immutable(state, {
            createEditSuccess: { $set: false },
            createEditError: { $set: (payload && payload.error) || null } ,
            loading: { $set: false},
        });
      },
      [DELETE_USER_SUCCESS]: (state, { }) => {
        return immutable(state, {
          deleteSuccess: { $set: true },
          deleteError: { $set: null } ,
        });
      },
      [DELETE_USER_ERROR]: (state, { payload }) => {
        return immutable(state, {
            deleteSuccess: { $set: false },
            deleteError: { $set: (payload && payload.error) || null } ,
        });
      },
    },
    userManagementState
  ),
};

export default reducer;
