import React, { useCallback, useEffect, useState } from "react";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import EditIconCell from '../../../components/Table/Cellrenderer/EditIconCell';
import EditProductAllocationModal from './EditProductAllocationModal'
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import ColumnFilter from '../../UserManagement/ColumnFilter'
import { oneDecimal } from "../../../utils/formatters/valueFormatters";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import { connect } from "react-redux";
import { getPackDetails, updatePacksEachesForMultipleStores } from "../FinalizeAction";
import EditPackDetails from "./EditPackDetails";
import { currentClientName, isAshley, scrollIntoView } from "../../../utils/commonUtilities";
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import { cloneDeep, isEmpty } from "lodash";
import PageLoader from "../../../components/Loader/PageLoader";
import BulkEditPacksAndEaches from "./BulkEditPacksAndEaches";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ProductDetailsTable = (props) => {
    const [RTinstance, setRTinstance] = useState(null)
    const [isDisabled, setIsDisabled] = useState(true)
    const [showEditModal, setShowEditModal] = useState(false)
    const [editData, setEditData] = useState({})
    const [initialHiddenColumns, setInitialHiddenColumns] = useState([props.viewAllocationCall ? "actions" : ''])
    // const [keyRT, setKeyRT] = useState(props.viewAllocationCall ? "sortAndSearch" : "productDetailView")
    const [keyRT, setKeyRT] = useState(null)
    const [packsAvailable, setPacksAvailable] = useState(0)
    const [eachesAvailable, setEachesAvailable] = useState({})
    const [request1, setRequest1] = useState({})
    const [updatesEachesSizeMapping, setUpdatesEachesSizeMapping] = useState({})
    const [buttonLabel, setButtonLabel] = useState('')
    const [selectedRows, setSelectedRows] = useState([])
    const [availableColumns, setAvailableColumns] = useState([])
    const [availableData, setAvailableData] = useState([])
    const [showAvailable, setShowAvailable] = useState(false)
    const [showButtons, setShowButtons] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)

    const {is_eaches,is_pack,pack_config,is_multiple_packs} = props.productDetails

    const pdtRef = React.useRef();

    useEffect(() => {
      setButtonLabel(is_eaches ? 'Eaches' : 'Packs')
    }, [props.productDetails,is_eaches])

    useEffect(() => {
        if (props.viewAllocationCall) {
            setShowButtons(false)
            setKeyRT("sortAndSearch")
        }
        else if(!props.viewAllocationCall && (is_pack && is_eaches)) {
            setShowButtons(false)
            setKeyRT("sortAndSearch")
        }
        else if(!props.viewAllocationCall && (!is_pack && !is_eaches)) {
            setShowButtons(false)
            setKeyRT("sortAndSearch")
        }
        else if(!props.viewAllocationCall && (is_pack || is_eaches)) {
            setShowButtons(true)
            setKeyRT("productDetailView")
        }
    }, [is_pack,is_eaches,props.viewAllocationCall])

    const productDetailsDatacolumns = React.useMemo(() => [
        {
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: "Store ID",
                    accessor: 'store',
                    Filter:(instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
                    filter: 'bulkFilterSpaceSeperated',
                }
            ],
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: "Store Name",
                    accessor: "store_name",
                    Filter: ColumnFilter,
                }
            ],
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: "Store Grade",
                    accessor: "store_grade",
                    Filter: ColumnFilter,
                    filter: 'exactText',
                }
            ],
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: "Climate",
                    accessor: "climate",
                    Filter: ColumnFilter,
                }
            ],
        },
        {
            Header: "Original APS",
            accessor: row => row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Actual APS',
            accessor: row => row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {

            Header: 'OH',
            accessor: 'oh',
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Min Constraint',
            accessor: 'min',
            Filter: NumberRangeColumnFilter,
            filter: 'between'

        },
        {
            Header: 'Allocated Total Quantity',
            columns: [
                {
                    Header: 'Pack ID',
                    accessor: 'packs',
                    Filter: ColumnFilter
                },
                {
                    Header: '# Packs Allocated',
                    accessor: 'packs_allocated',
                    Cell: (instance) => (
                    is_pack && selectedRows.includes(instance.row.id) && !buttonClicked ?
                    <InputCell {...instance} style={{ textAlign: "center" }} min={0} type="number" step={1}
                        blurHandler={(rowIdx, columnId, values,row_id,temp_id,row,focusedValue) => {
                                setAvailableData((old) => {
                                    return [{
                                        ...old[0],
                                        'packs_available':  (Number(old[0]['packs_available']) || 0) - (Number(values) - Number(focusedValue))
                                    }]
                                })
                        }}
                        changeHandler={(rowIdx, columnId, values) => {
                            setRequest1((old) => {
                                return {
                                    ...old,
                                    [instance.row.original['store']]: `${values}+-+-${instance.row.original['packs']}`,
                                }
                            })
                        }}
                    /> : 
                    <div>
                        {instance.row.original?.packs_allocated}
                    </div>
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: '# Eaches Allocated',
                    accessor: 'loose_units_allocated',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: 'Total Allocated Units',
                    accessor: 'allocated_quantity',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    width: 200
                }
            ]
        },
        {
            Header: ' ',
            columns: [
                {
                    Header: "DC Available",
                    accessor: "dc_avai_art",
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                }
            ]
        },
        {
            Header: ' ',
            columns: [
                {
                    Header: "Size Integrity in %",
                    accessor: "size_integrity",
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                }
            ]
        },
        {
            Header: "Allocated Quantity",
            columns: []
        },
        {
            Header: ' ',
            sticky: "right",
            columns: [
                {
                    Header: "Actions",
                    accessor: "actions",
                    Cell: (instance) => (
                        <div className={props.isFinalized ? "disable__column" : ""}>
                            <EditIconCell
                                handleEdit={editAllocateQty}
                                instance={instance}
                            ></EditIconCell>
                        </div>
                    ),
                    width: 100,
                    resizable: true,
                    disableFilters: true,
                }
            ]
        },
    ],[selectedRows,is_pack,props.isFinalized])

    const editAllocateQty = (p_instance) => {
        let { selectedRow } = props
        let req = {
            "allocationcode": props.allocationcode,
            "article": selectedRow.article,
            "store": p_instance.store
        }
        if (props.viewAllocationCall) {
            req["page"] = "viewPastAllocation"
        }
        if (props.releasedDate) {
            req["released_date"] = props.releasedDate
        }
        setEditData(req)
        props.getPackDetails(req);
        setShowEditModal(true)
    }

    const closeEditModal = (callAllApi) => {
        setShowEditModal(false)
        if (callAllApi) {
            props.callAllApis()
        }
    }

    const onEdit = () => {
        
        if(isEmpty(Object.keys(RTinstance.state.selectedRowIds))) {
            Notify.error("Please select atleast 1 store to edit!!")
            showAvailable && setShowAvailable(false)
            return;
        }

        if(is_pack) {
            let max_pack_available = 0;
            Object.keys(RTinstance.state.selectedRowIds).forEach((rowId) => {
                max_pack_available += Number(props.data?.[rowId]?.packs_allocated)
            });
            max_pack_available += Number(props?.productDetails?.packs_available);
            setPacksAvailable(max_pack_available)
            let column = props.sizes?.map(val => {
                return {
                    Header: val,
                    accessor: val
                }
            })
            column.push({
                Header: '# Packs Avaialble',
                accessor: 'packs_available'
            })
            setAvailableColumns(column)
                        
            let data = {
                ...pack_config,
                'packs_available': props?.productDetails?.packs_available
            }

            setAvailableData([data])
        }
        if(is_eaches) {
            let max_eaches_available = cloneDeep(props?.productDetails?.eaches_available) || {};
            let max_eaches_available_data = cloneDeep(props?.productDetails?.eaches_available) || {};
            let sizes = props.sizes
            Object.keys(RTinstance.state.selectedRowIds).forEach((rowId) => {
                sizes.forEach((s,index) => {
                    max_eaches_available_data[s] = (Number(max_eaches_available_data[s]) || 0) + (Number(props.data?.[rowId]?.["size_value"]?.[index]) || 0)
                })
            });
            setEachesAvailable(max_eaches_available) 
            setUpdatesEachesSizeMapping({})
            let column = props.sizes?.map(val => {
                return {
                    Header: val,
                    accessor: val
                }
            })
            setAvailableColumns(column)
            setAvailableData([max_eaches_available])
        }
        setRequest1({})
        setShowAvailable(true)
        setSelectedRows(Object.keys(RTinstance.state.selectedRowIds))
    }

    const onBulkEdit = () => {
        if(isEmpty(Object.keys(RTinstance.state.selectedRowIds))) {
            Notify.error("Please select atleast 1 store to edit!!")
            return;
        }
        setSelectedRows(Object.keys(RTinstance.state.selectedRowIds))
        setButtonClicked(true);
    }

    const resetButtonClick = () => {
        setButtonClicked(false)
        setTimeout(() => {
            scrollIntoView(pdtRef)
        }, 200)
    }

    const onSave = () => {
        if(isEmpty(request1)) {
            Notify.error("Please Edit before saving!!")
            return;
        }

        let stores_details = []
        let l_pack_id 
        let request = {}
        let l_available_eaches = {}
        const {article} = props.selectedRow
        const {allocationcode} = props
        
        if(is_pack) {
            Object.keys(request1).forEach(val => {
                l_pack_id = request1[val].split("+-+-")[1]
                stores_details.push({
                    "store": val,
                    "updated_packs": {
                        [request1[val].split("+-+-")[1]] : request1[val].split("+-+-")[0]
                    }
                })
            })

            if(availableData[0]?.["packs_available"] < 0) {
                Notify.error("The allocated packs are more than the available units!!") 
                return;
            }

            request = {
                "allocation_code": allocationcode,
                "available_packs": {
                    [l_pack_id]: availableData[0]?.["packs_available"]

                },
                "article": article,
                "stores": stores_details
            }
        }

        if(is_eaches) {
            for(let i in updatesEachesSizeMapping) {
                if(updatesEachesSizeMapping[i] > eachesAvailable[i]) {
                    Notify.error(`The allocated eaches are more than the available units for size ${i}!!`) 
                    return;
                } 
            }

            Object.keys(request1).forEach(val => {
                stores_details.push({
                    "store": val,
                    "updated_eaches": request1[val]
                })
            })

            for(let i in updatesEachesSizeMapping) {
                l_available_eaches[i] = (Number(eachesAvailable[i]) || 0) - (Number(updatesEachesSizeMapping[i]) || 0 )
            }

            request = {
                "allocation_code": allocationcode,
                "available_eaches": l_available_eaches,
                "article": article,
                "stores": stores_details
            }

        }

        let { originalAllocationCode } = props
        let l_original_allocation_code = allocationcode === originalAllocationCode ? "" : originalAllocationCode
        request["original_allocation_code"] = l_original_allocation_code

        setTimeout(() => {
            scrollIntoView(pdtRef)
        }, 200)
        props.updatePacksEachesForMultipleStores(request)
    }

    useEffect(() => {
        if (props.updatePackEachesError) {
            setRequest1({})
            setSelectedRows([])
            Notify.error("Error in Editing Allocated Units")
        }
    }, [props.updatePackEachesError])


    useEffect(() => {
        if (props.updatePackEachesData) {
            setRequest1({})
            setSelectedRows([])
            Notify.success("Allocated Units Edited Sucessfully!!")
            props.callAllApis()
        }
    }, [props.updatePackEachesData])

    const setSizeColumns = useCallback(() => {
        if (props.sizes.length) {
            props.sizes.forEach((s, index) => {
                productDetailsDatacolumns[9].columns.push({
                    Header: s,
                    accessor: row => row.size_value[index] ? Number(row.size_value[index]).toFixed(0) : 0,
                    Cell: (instance) => (
                        is_eaches && selectedRows.includes(instance.row.id) && !buttonClicked ? <InputCell {...instance} style={{ textAlign: "center" }} min={0} step={1}type="number" 
                        blurHandler={(rowIdx, columnId, values,row_id,temp_id,row,focusedValue) => {
                            setUpdatesEachesSizeMapping((old) => {
                                return {
                                    ...old,
                                    [s]: (Number(old[s]) || 0) + Number(values) - Number(focusedValue)
                                }
                            })
                            setAvailableData((old) => {
                                return [{
                                    ...old[0],
                                    [s]:  (Number(old[0][s]) || 0) - (Number(values) - Number(focusedValue))
                                }]
                            })
                        }}
                        changeHandler={(rowIdx, columnId, values) => {
                            setRequest1((old) => {
                                return {
                                    ...old,
                                    [instance.row.original['store']]: {
                                        ...old?.[instance.row.original['store']],
                                        [s] : values
                                    },
                                }
                            })
                        }}
                    /> : 
                    <div> {instance.row.original.size_value[index] ? Number(instance.row.original.size_value[index]).toFixed(0) : 0} </div>
                    ),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                })
            })
            // setTableColumns(columns)
            return true
        }
        return false
    },[selectedRows,is_eaches,props.sizes])

    return (
        keyRT &&
        setSizeColumns() &&
        <>
            {
                !showEditModal &&
                <Notification />
            } 
            {
                selectedRows.length > 0 &&
                <BulkEditPacksAndEaches 
                    selectedFlatRows={RTinstance?.selectedFlatRows} 
                    buttonClicked={buttonClicked} 
                    resetButtonClick={resetButtonClick}
                    packConfig={pack_config}
                    sizes={props.sizes}
                    buttonLabel={buttonLabel}
                    packsAvailable = {props?.productDetails?.packs_available}
                    eachesAvailable = {props?.productDetails?.eaches_available}
                    article={props.selectedRow.article}
                    allocationCode={props.selectedRow.allocationcode}
                    allocationCodeAfterUpdate= {props.allocationcode}
                    originalAllocationCode={props.originalAllocationCode}
                /> 
            }
            {
                showAvailable &&
                <div style={{margin:"3rem 0"}}>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <h1 className="fnt-md fnt-bold">Available {buttonLabel}</h1>
                    </div>
                    <ReactTableWithPlugins
                        hideColumnsFilter
                        data={availableData.length ? availableData : []}
                        columns={availableColumns.length ? availableColumns : []}
                        renderMarkup='TableMarkup'
                    /> 
                </div>
            }
             
            <div className="storesStore__heading" ref={pdtRef}>
                <h1 className="fnt-md fnt-bold">{props.selectedRow.article}</h1>
                {
                    props.data.length > 1 &&
                    <h1 className="fnt-md fnt-bold">Stores</h1>
                }
            </div>
            <PageLoader loader={props.updatePackEachesLoading} gridLoader={true}>
                <ReactTableWithPlugins
                    topScrollBar
                    // tableWrapperStyle={{height:'fit-content', maxHeight:'60rem'}}
                    // headerWrapperStyle={{position:'sticky', top:0, zIndex: 10}}
                    hideOptions={props.sizes}
                    // shouldPagination
                    totalRecordsLen={props.data ? props.data.length : 0}
                    data={props.data ? props.data : []}
                    columns={productDetailsDatacolumns}
                    sortBy={[{ id: 'store', desc: false }]}
                    renderMarkup='TableMarkup'
                    prepareRequest={true}
                    setIsDisabled={setIsDisabled}
                    getInstanceOnMount={(instance) => {
                        setRTinstance(instance)
                    }}
                    keyRT={keyRT}
                    features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                    initialHiddenColumns={[...initialHiddenColumns, "packs"]}
                    tableId="finalize_product_details"
                    pageSize={props.data.length}
                /> 
            {
                isAshley() && showButtons && !props.viewAllocationCall && !props.isFinalized &&
                <div style={{ textAlign: "center", margin: '2rem' }}>
                    <button
                        style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                        onClick={onBulkEdit}
                        className="btn btn-primary"
                    // disabled={isDisabled}
                    >
                        Bulk Edit {buttonLabel}
                    </button>
                     {isAshley() && showButtons && !is_multiple_packs && !props.viewAllocationCall && !props.isFinalized &&
                        <>
                        <button
                                style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                                onClick={onEdit}
                                className="btn btn-primary"
                            // disabled={isDisabled}
                            >
                                Edit {buttonLabel}
                            </button>
                            <button
                                style={{ margin: '2.5rem 1.5rem 1.5rem' }}
                                onClick={onSave}
                                className="btn btn-primary"
                            // disabled={isDisabled}
                            >
                                Save
                        </button>
                        </>
                     }
                </div>
            } 
            </PageLoader>

            {showEditModal ? (
                currentClientName !== "ashley" ? 
                <EditProductAllocationModal 
                    fields={productDetailsDatacolumns[5].columns}
                    showAlert={true}
                    closeAlert={closeEditModal}
                    data={editData}
                /> :
                <EditPackDetails
                    showAlert={true}
                    closeAlert={closeEditModal}
                    data={editData}
                    originalAllocationCode={props.originalAllocationCode}
                />
            ) : null}
        </>
    )

}


const mapStateToProps = ({ finalize }) => {
    return {
        updatePackEachesLoading: finalize.updatePackEachesLoading,
        updatePackEachesData: finalize.updatePackEachesData,
        updatePackEachesError: finalize.updatePackEachesError
    }
}

const mapDispatchToProps = (dispatch) => ({
    getPackDetails: (payload) => dispatch(getPackDetails(payload)),
    updatePacksEachesForMultipleStores: (payload) => dispatch(updatePacksEachesForMultipleStores(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsTable)