import * as actionTypes from "../Actions/ActionTypes"
import { productStatusInitialState } from "./InitialState"
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

export default {
    productStatus: handleActions(
        {
            [actionTypes.FETCH_FILTER_SUCCESS]: (state, payload) => {
                switch (payload.key) {
                    case "department":
                        return immutable(state, {
                            departmentOptions: {
                                $set:
                                    payload && payload.data.departmentOptions
                                        ? payload.data.departmentOptions
                                        : state.departmentOptions,
                            },
                            genderOptions: [],
                            rbuOptions: [],
                            dcsOptions: [],
                            level5Options: [],
                            styleOptions: [],
                            colorOptions: [],
                            sizeOptions: [],
                            productTypeOptions: [],
                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "gender":
                        return immutable(state, {
                            genderOptions: {
                                $set:
                                    payload && payload.data.genderOptions
                                        ? payload.data.genderOptions
                                        : state.genderOptions,
                            },
                            rbuOptions: {
                                $set:
                                    payload && payload.data.rbuOptions
                                        ? payload.data.rbuOptions
                                        : state.rbuOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            sizeOptions: {
                                $set:
                                    payload && payload.data.sizeOptions
                                        ? payload.data.sizeOptions
                                        : state.sizeOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "sub_category":
                        return immutable(state, {
                            rbuOptions: {
                                $set:
                                    payload && payload.data.rbuOptions
                                        ? payload.data.rbuOptions
                                        : state.rbuOptions,
                            },
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            sizeOptions: {
                                $set:
                                    payload && payload.data.sizeOptions
                                        ? payload.data.sizeOptions
                                        : state.sizeOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "dcs":
                        return immutable(state, {
                            dcsOptions: {
                                $set:
                                    payload && payload.data.dcsOptions
                                        ? payload.data.dcsOptions
                                        : state.dcsOptions,
                            },
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            sizeOptions: {
                                $set:
                                    payload && payload.data.sizeOptions
                                        ? payload.data.sizeOptions
                                        : state.sizeOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "level5":
                        return immutable(state, {
                            level5Options: {
                                $set:
                                    payload && payload.data.level5Options
                                        ? payload.data.level5Options
                                        : state.level5Options,
                            },
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },
                            sizeOptions: {
                                $set:
                                    payload && payload.data.sizeOptions
                                        ? payload.data.sizeOptions
                                        : state.sizeOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "style_name":
                        return immutable(state, {
                            styleOptions: {
                                $set:
                                    payload && payload.data.styleOptions
                                        ? payload.data.styleOptions
                                        : state.styleOptions,
                            },
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },


                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "color":
                        return immutable(state, {
                            colorOptions: {
                                $set:
                                    payload && payload.data.colorOptions
                                        ? payload.data.colorOptions
                                        : state.colorOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                    case "product_type":
                        return immutable(state, {
                            productTypeOptions: {
                                $set:
                                    payload && payload.data.productTypeOptions
                                        ? payload.data.productTypeOptions
                                        : state.productTypeOptions,
                            },

                            filterlabels: {
                                $set:
                                    payload && payload.data.filterMapping
                                        ? payload.data.filterMapping
                                        : state.filterMapping
                            }
                        });
                }
            },

            [actionTypes.GET_PRODUCT_STATUS_DATA]: (state) => {
                return immutable(state, {
                    dataLoading: { $set: true },
                    data: { $set: [] },
                    dataError: { $set: null },
                    // articleData: { $set: [] },
                    // articleDataLoading: { $set: false },
                    // articleDataError: { $set: null }
                })
            },
            [actionTypes.GET_PRODUCT_STATUS_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    data: { $set: (payload.data && payload.data) || [] },
                    dataError: { $set: null },
                })
            },
            [actionTypes.GET_PRODUCT_STATUS_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    data: { $set: [] },
                    dataError: { $set: (payload && payload.message) || null },
                })
            },

            [actionTypes.GET_ARTICLE_CONFIG_DATA]: (state) => {
                return immutable(state, {
                    articleDataLoading: { $set: true },
                    articleData: { $set: [] },
                    articleDataError: { $set: null },
                })
            },
            [actionTypes.GET_ARTICLE_CONFIG_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    articleDataLoading: { $set: false },
                    articleData: { $set: (payload.data && payload.data) || [] },
                    articleDataError: { $set: null },
                })
            },
            [actionTypes.GET_ARTICLE_CONFIG_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    articleDataLoading: { $set: false },
                    articleData: { $set: [] },
                    articleDataError: { $set: (payload && payload.message) || null },
                })
            },

            [actionTypes.GET_STORE_GROUP_DATA]: (state) => {
                return immutable(state, {
                    storeGroupDataLoading: { $set: true },
                    storeGroupData: { $set: [] },
                    storeGroupDataError: { $set: null },
                })
            },
            [actionTypes.GET_STORE_GROUP_DATA_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    storeGroupDataLoading: { $set: false },
                    storeGroupData: { $set: (payload.data && payload.data) || [] },
                    storeGroupDataError: { $set: null },
                })
            },
            [actionTypes.GET_STORE_GROUP_DATA_ERROR]: (state, payload) => {
                return immutable(state, {
                    storeGroupDataLoading: { $set: false },
                    storeGroupData: { $set: [] },
                    storeGroupDataError: { $set: (payload && payload.message) || null },
                })
            },

            [actionTypes.DELETE_CONFIG]: (state, payload) => {
                return immutable(state, {
                    deleteConfigDataLoading: { $set: true },
                    deleteConfigData: { $set: {} },
                    deleteConfigDataError: { $set: null },
                })
            },
            [actionTypes.DELETE_CONFIG_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    deleteConfigDataLoading: { $set: false },
                    deleteConfigData: { $set: (payload.data && payload.data) || {} },
                    deleteConfigDataError: { $set: null },
                })
            },
            [actionTypes.DELETE_CONFIG_ERROR]: (state, payload) => {
                return immutable(state, {
                    deleteConfigDataLoading: { $set: false },
                    deleteConfigData: { $set: {} },
                    deleteConfigDataError: { $set: (payload && payload.error) || null },
                })
            },

            [actionTypes.GET_MISSING_CONFIG]: (state, payload) => {
                return immutable(state, {
                    noConfigLoading: { $set: true },
                    noConfigData: { $set: [] },
                    noConfigError: { $set: null },
                })
            },
            [actionTypes.GET_MISSING_CONFIG_SUCCESS]: (state, payload) => {
                return immutable(state, {
                    noConfigLoading: { $set: false },
                    noConfigData: { $set: (payload.data && payload.data) || [] },
                    noConfigError: { $set: null },
                })
            },
            [actionTypes.GET_MISSING_CONFIG_ERROR]: (state, payload) => {
                return immutable(state, {
                    noConfigLoading: { $set: false },
                    noConfigData: { $set: [] },
                    noConfigError: { $set: (payload && payload.error) || null },
                })
            },


            [actionTypes.SAVE_FILTER]: (state, payload) => {
                return immutable(state, {
                    selectedFilters: { $set: payload.payload },
                })
            },

            [actionTypes.RESET_FILTERS_DATA]: (state) => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    data: { $set: [] },
                    dataError: { $set: null },
                    articleData: { $set: [] },
                    articleDataLoading: { $set: false },
                    articleDataError: { $set: null },
                    storeGroupData: { $set: {} },
                    storeGroupDataLoading: { $set: false },
                    storeGroupDataError: { $set: null },
                    genderOptions: { $set: [] },
                    rbuOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    level5Options: { $set: [] },
                    styleOptions: { $set: [] },
                    colorOptions: { $set: [] },
                    sizeOptions: { $set: [] },
                    productTypeOptions: { $set: [] },
                })
            },

            [actionTypes.RESET_CREATE_CONFIG_DATA]: (state) => {
                return immutable(state, {
                    createConfigData: { $set: {} },
                    createConfigDataLoading: { $set: false },
                    createConfigDataError: { $set: null },
                    deleteConfigDataLoading: { $set: false },
                    deleteConfigData: { $set: {} },
                    deleteConfigDataError: { $set: null }
                })
            },

            [actionTypes.RESET_ALL]: (state) => {
                return immutable(state, {
                    dataLoading: { $set: false },
                    data: { $set: [] },
                    dataError: { $set: null },
                    articleData: { $set: [] },
                    articleDataLoading: { $set: false },
                    articleDataError: { $set: null },
                    storeGroupData: { $set: {} },
                    storeGroupDataLoading: { $set: false },
                    storeGroupDataError: { $set: null },
                    departmentOptions: { $set: [] },
                    genderOptions: { $set: [] },
                    rbuOptions: { $set: [] },
                    dcsOptions: { $set: [] },
                    level5Options: { $set: [] },
                    styleOptions: { $set: [] },
                    colorOptions: { $set: [] },
                    sizeOptions: { $set: [] },
                    productProfilesData: { $set: {} },
                    productProfilesDataLoading: { $set: false },
                    productProfilesDataError: { $set: null },
                    storeSizeContributionData: { $set: {} },
                    storeSizeContributionDataLoading: { $set: false },
                    storeSizeContributionDataError: { $set: null },
                    dcData: { $set: {} },
                    dcDataLoading: { $set: false },
                    dcDataError: { $set: null },
                    createConfigData: { $set: {} },
                    createConfigDataLoading: { $set: false },
                    createConfigDataError: { $set: null },
                    deleteConfigDataLoading: { $set: false },
                    deleteConfigData: { $set: {} },
                    deleteConfigDataError: { $set: null },
                    selectedFilters: { $set: null },
                    // noConfigLoading: { $set: true },
                    // noConfigData: { $set: [] },
                    // noConfigError: { $set: null },
                })
            }
        },
        productStatusInitialState
    )
}