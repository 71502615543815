import React, { useState } from 'react';
import { useEffect } from 'react';
import Select from "../../../components/Filters/SingleSelect";
import "../LostSalesReport/LostSalesReport.css";

/**
 * Week Level Filetring Component
 * @param {*} props 
 * @returns 
 */
const ExcessWeekFilter = (props) => {
    const { fiscalWeekOptions, handleWeekSelect, latestWeek } = props;
    const [week, setWeek] = useState(null);

    const onWeekChange = (weekVal) => {
        setWeek(weekVal);
        setTimeout(() => {
            handleWeekSelect(weekVal?.value);
        }, 300)
    };

    useEffect(() => {
        if (latestWeek) {
            setWeek(latestWeek)
        }
    }, [latestWeek])

    const reset = () => {
        if (week) {
            onWeekChange(null)
        }
    }


    return (

        <div style={{ display: "inline-flex" }}>
            <label className="fnt-md fnt-bold fnt-bold mr-4">Week</label>
            <Select
                id="Week-filter"
                name="week"
                value={week}
                onChange={onWeekChange}
                placeHolder="Select Week"
                options={fiscalWeekOptions?.length ? fiscalWeekOptions : []}
                className="week_selector"
            />
            {/* <div className="ml-4" style={{ marginTop: "-3rem" }}>
                <button
                    onClick={() => reset()}
                    className="btn undo-constraints-mode"
                    title="Reset filters"
                >
                    <i className="fa fa-undo mr-2"></i>Reset
                </button>
            </div> */}
        </div>

    );
}

export default ExcessWeekFilter;