import * as lostSalesActions from './actionTypes';

/**
 * Fucntion to dispatch lost sales filters loading request
 * 
 */
export const getLostSalesFiltersData = (payload, filterType) => ({
    type: lostSalesActions.GET_LOST_SALES_FILTER_REQUEST,
    payload,
    filterType,
});

/**Action function reset Lost Sales Report data
 * @returns 
 */
export const resetLostSalesReport = () => ({
    type: lostSalesActions.RESET_LOST_SALES_REPORT,
});

/**
 * Action funtion to fetch All Report Data
 */
export const getLostSalesReportAllData = (payload) => ({
    type: lostSalesActions.GET_LOST_SALES_REPORT_ALL_REQUEST,
    payload,
});

/**
 * Action funtion to fetch All Report Data
 * @returns 
 */
export const getLostSalesReportDetailsTableData = (payload) => ({
    type: lostSalesActions.GET_LOST_SALES_REPORT_DETAILS_REQUEST,
    payload,
});

/**
 * Action funtion to set lost Sales Request Body
 * @returns 
 */
export const setLostSalesReqBody = (payload) => ({
    type: lostSalesActions.SET_LOST_SALES_REQ_BODY,
    payload,
});

/**
 * Action function to reset filter options
 */
export const resetFilterOptions = (payload) => ({
    type: lostSalesActions.RESET_LOST_SALES_FILTER,
    payload,
});