import { takeLatest, takeEvery, put, call, all, delay } from "redux-saga/effects";
import * as actionTypes from "./Actions/ActionTypes"
import {
    getExcessTableData, getExcessGraphData, getExcessFilters
} from "../../../routes/api";
import { isEmpty } from "lodash";
import { updateArticleName } from "../../../utils/filterLevelMapping";

function* fetchFilterDataWorker(action) {
    try {
        const { payload, filterKey } = action;
        let req = {};
        if (!isEmpty(payload)) {
            for (const key in payload) {
                if (payload[key] && payload[key].length > 0)
                    req[key] = payload[key]?.map((ele) => ele.value)
            }
        }
        const res = yield call(getExcessFilters, req);
        if (res.data.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping

            for (const key in res.data.data[0]) {
                let k = "";
                if (key === "level1") {
                    k = "departmentOptions";
                } else if (key === "level2") {
                    k = "genderOptions";
                } else if (key === "level3") {
                    k = "rbuOptions";
                } else if (key === "level4") {
                    k = "dcsOptions";
                } else if (key === "level5") {
                    k = "level5Options";
                } else if (key === "store") {
                    k = "storeOptions";
                } else if (key === "article") {
                    k = "articleOptions"
                } else if (key === "color") {
                    k = "colorOptions"
                } else if (key === "style") {
                    k = "styleOptions"
                }

                // data["departmentOptions"] = [{ label: "Accessories", value: "Accessories" }, { label: "Apparel", value: "Apparel" }, { label: "Footwear", value: "Footwear" }]

                data[k] = res.data.data[0][key]?.filter(val => val)?.map((element) => ({
                    value: element,
                    label: element,
                }));
            }

            yield put({ type: actionTypes.FETCH_FILTER_SUCCESS, data: data, key: filterKey });
        }
    } catch (error) {
        yield put({ type: actionTypes.FETCH_FILTER_ERROR, error: "Something went wrong!" });
    }
}

function* getExcessInvDataWorker(action) {
    try {
        const { payload } = action;
        let req = {}
        for (let key in payload) {
            if (payload[key]) {
                req[key] = payload[key]
            }
        }
        req.row_index = payload.row_index


        const res = yield call(getExcessTableData, req);
        if (res.data.status) {
            const { count, inv_cost_sum, inv_sum } = res.data.global_data

            yield put({ type: actionTypes.GET_EXCESS_DATA_SUCCESS, tableData: res.data.data, totalCount: count, sum: isEmpty(payload.searchColumns) && isEmpty(payload.sortColumn) ? { inv_cost_sum, inv_sum } : {}, nextIndex: payload.row_index + 100 });
        }
        else {
            yield put({ type: actionTypes.GET_EXCESS_DATA_ERROR, error: "Something went wrong!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_EXCESS_DATA_ERROR, error: "Something went wrong!" });
    }
}

function* generateExcelWorker(action) {
    try {
        const { payload } = action;
        let req = {}
        for (let key in payload) {
            if (payload[key]) {
                req[key] = payload[key]
            }
        }
        const res = yield call(getExcessTableData, req);
        if (res.data.status) {
            let excelData = []
            res.data.data.map(item => {
                excelData.push({
                    "Store ID": item.store_credited,
                    "Store Name": item.store_name,
                    "Fiscal Week": item.week,
                    [`${updateArticleName(false)} ID`]: item.article,
                    "Style Description": item.erp_style_desc,
                    "Style Color": item.erp_color_desc,
                    "Color": item.std_color,
                    "Inventory Closing Balance": item.inv,
                    "ROS": item.ros,
                    "Units Sold": item.units_sold,
                    "WOS": item.wos_predicted,
                    "WOS Threshold": item.target_wos,
                    "Excess Inventory (Units)": item.excess_inv,
                    "Excess Inventory (Cost)": item.excess_inv_cost
                })
            })
            yield put({ type: actionTypes.GENERATE_EXCEL_SUCCESS, excelData });

        }
        else {
            yield put({ type: actionTypes.GENERATE_EXCEL_ERROR, error: "Something went wrong!" });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GENERATE_EXCEL_ERROR, error: "Something went wrong!" });
    }
}

function* getExcessGraphDataWorker(action) {
    try {
        const { payload } = action;
        let req = {}
        for (let key in payload) {
            if (payload[key]) {
                req[key] = payload[key]
            }
        }
        const res = yield call(getExcessGraphData, req);
        if (res.data.status) {

            let graphData = {
                series: [{ name: "Excess Inventory", data: [] },
                { name: "Inventory", data: [] },
                { name: "Units Sold", data: [] }],

                labels: []
            }

            res.data.data.map(item => {
                graphData.series[0].data.push(item.excess_inv_sum)
                graphData.series[1].data.push(item.inv_sum)
                graphData.series[2].data.push(item.unit_sold_sum)

                if (!graphData.labels.includes(item.week)) {
                    graphData.labels.push(item.week)
                }
            })


            yield put({ type: actionTypes.GET_EXCESS_GRAPH_DATA_SUCCESS, graphData, weeks: graphData.labels });
        }
        else {
            yield put({ type: actionTypes.GET_EXCESS_GRAPH_DATA_ERROR, error: "Something went wrong!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_EXCESS_GRAPH_DATA_ERROR, error: "Something went wrong!" });
    }
}

// function* fetchFilterDataWatcher() {
//     yield takeLatest(actionTypes.FETCH_FILTER_DATA, fetchFilterDataWorker)
// }

function* getExcessInvDataWatcher() {
    yield takeLatest(actionTypes.GET_EXCESS_DATA, getExcessInvDataWorker)
}

function* getExcessGraphDataWatcher() {
    yield takeLatest(actionTypes.GET_EXCESS_GRAPH_DATA, getExcessGraphDataWorker)
}

function* fetchFilterDataWatcher() {
    yield takeEvery(actionTypes.FETCH_FILTER_DATA, fetchFilterDataWorker)
}

function* generateExcelWatcher() {
    yield takeLatest(actionTypes.GENERATE_EXCEL, generateExcelWorker)
}


export function* excessSaga() {
    yield all([
        // fetchFilterDataWatcher(),
        getExcessInvDataWatcher(),
        getExcessGraphDataWatcher(),
        fetchFilterDataWatcher(),
        generateExcelWatcher()
    ])
}