import React, { useEffect, useState } from 'react'
import Notification from '../../../components/Notification/Notifications'
import { connect } from 'react-redux'
import { fetchFilterData, fetchStoresData, fetchUserCreatedData, resetFiltersOptions } from '../ProductProfileAction'
import ProfileTable from './ProfileTable';
import * as Notify from '../../../components/Notification/Notifications'
import Select from '../../../components/Filters/SingleSelect'
import MultiSelect from '../../../components/Filters/MultiSelect';
import { intersectionWith, isEqual } from "lodash";
import ColumnFilter from '../../UserManagement/ColumnFilter';
import { handleSelectAll } from '../../../utils/SelectAllHandler/SelectAllHander';
import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping"
import { getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter } from '../../../utils/commonUtilities';
import AsyncMulti from '../../../components/Filters/AsyncMulti';

function IARecommendedProfile({
    // level1Options,level2Options,level3Options,level4Options,styleOptions,colourOptions,
    ...props}) {

    const [level1, setLevel1] = useState(null)
    const [level2, setLevel2] = useState(null)
    const [level3, setLevel3] = useState(null)
    const [level4, setLevel4] = useState(null)
    const [level5, setLevel5] = useState(null)
    const [style_name, setStyle] = useState(null)
    const [colour, setColour] = useState(null)
    const [isRowClicked, setIsRowClicked] = useState(false)
    const [column, setColumn] = useState([])
    const [dynamicHeader, setDynamicHeader] = useState('DCS')
    const [request,setRequest] = useState(null)
    const maxWidth = '100%'

    const [level1Options, setlevel1Options] = useState(null);
    const [level2Options, setlevel2Options] = useState(null);
    const [level3Options, setlevel3Options] = useState(null);
    const [level4Options, setlevel4Options] = useState(null);
    const [level5Options, setlevel5Options] = useState(null);
    const [styleOptions, setStyleOptions] = useState(null);
    const [colourOptions, setColourOptions] = useState(null);
    const [articleId, setArticleId] = useState('');

    useEffect(() => {
        setlevel1Options(() => props.level1Options);
      }, [props.level1Options]);
      
    
      useEffect(() => {
        setlevel2Options(() => props.level2Options);
      }, [props.level2Options]);
      
      useEffect(() => {
        setlevel3Options(() => props.level3Options);
      }, [props.level3Options]);
      
      useEffect(() => {
        setlevel4Options(() => props.level4Options);
      }, [props.level4Options]);
    
      useEffect(() => {
        setlevel5Options(() => props.level5Options);
      }, [props.level5Options]);
    
    useEffect(() => {
      setStyleOptions(() => props.styleOptions);
    }, [props.styleOptions]);
    
    useEffect(() => {   
      setColourOptions(() => props.colourOptions);
    }, [ props.colourOptions]);

    useEffect(() => {
        if(props.activeTab == "ppi")
        getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, onLevel1Change)
        props.resetFiltersOptions([]);
        setIsRowClicked(false);
        // resetFilters()
    },[props.activeTab])

    useEffect(() => {
        setColumn(props.userCreatedProfileTabsColumns)
    },[props.userCreatedProfileTabsColumns])

    const getStoresData = (p_instance) => {
        setArticleId(p_instance.row?.original?.article)
        setIsRowClicked(true)
        props.fetchStoresData({"product_profile_code":p_instance.row?.original?.product_profile_code,"page":"ia-recommended","level1":[level1.value]})   
    }

    const userCreatedProfileColumns = React.useMemo(() => [
        {
            Header: `${updateArticleName(false)} ID`,
            accessor: 'article',
            Cell: (instance) => (
                <div>
                  <div className="clickable" onClick = {() => getStoresData(instance)}>{instance.row?.original?.article}</div>
                </div>
              ),
            Filter: ColumnFilter
        },
        {
            Header: 'Style Description',
            accessor: 'style_name',
            width:300,
            Filter: ColumnFilter
            
        },
        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: 'l1_name',
            disableFilters: true
            
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: 'l2_name',
            Filter: ColumnFilter
        },
        {
            Header: props.filterLabels?.level3 ? props.filterLabels?.level3 : levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            Filter: ColumnFilter
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: 'l4_name',
            Filter: ColumnFilter
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: 'l5_name',
            Filter: ColumnFilter
        },
        {
            Header: 'Similar Mapping',
            accessor: 'description',
            width:250,
            Filter: ColumnFilter
        }
    ])

    const resetRestFilters = (p_index) => {
        colour && setColour(null);
        style_name && setStyle(null);
        colourOptions && setColourOptions(null)
        styleOptions && setStyleOptions(null)
        if(p_index == 0){
            level2 && setLevel2(null);
            level2Options && setlevel2Options(null);
        }
        if(p_index == 0 || p_index == 1){
            level3 && setLevel3(null);
            level3Options && setlevel3Options(null)
        } 
        if(p_index == 0 || p_index == 1 || p_index == 2){
            level4 && setLevel4(null)
            level4Options && setlevel4Options(null)
        }
        if(p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3){
            level5 && setLevel5(null)
            level5Options && setlevel5Options(null)
        }   
    }

    const onLevel1Change = (val) => {
        // if(val.value === "Apparel"){
            // setDynamicHeader('DCS')
        // }
        // else{
        //     setDynamicHeader('Sub Category')
        // }
       setLevel1(val)
       resetRestFilters(0)
       props.fetchFilterData({level1:[val]},{key:'l2'})
       props.fetchFilterData({level1:[val]},{key:'l2'},"style_name")
       props.fetchFilterData({level1:[val]},{key:'l2'},"color")
    }

    const onLevel2Change = (val) => {
        setLevel2(val)
        resetRestFilters(1)
        //API call to get next filter
        props.fetchFilterData({level1:[level1],level2:[val]},{key:'l3'})
        props.fetchFilterData({level1:[level1],level2:[val]},{key:'l3'},"style_name")
        props.fetchFilterData({level1:[level1],level2:[val]},{key:'l3'},"color")
        
    }
    const onLevel3Change = (value,event,that) => {
        let valuesArray = handleSelectAll(value,event,that)
          setRequest(old => {
              return {
              ...old,
              'level3': valuesArray[0],
              'level4': null,
              'level5': null,
              'color': null,
              'style_name': null
              }
          })
          setLevel3(valuesArray[1])
          resetRestFilters(2)
          //API call to get next filter
          let req_val = intersectionWith(valuesArray[0], level3Options, isEqual);
          props.fetchFilterData({level1:[level1],level2:[level2],level3:req_val},{key:'l4'})
          props.fetchFilterData({level1:[level1],level2:[level2],level3:req_val},{key:'l4'},"style_name")
          props.fetchFilterData({level1:[level1],level2:[level2],level3:req_val},{key:'l4'},"color")
    }

    const onLevel4Change = (value,event,that) => {

        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'level4': valuesArray[0],
            "level5": null,
            'color': null,
            'style_name': null
            }
        })
        setLevel4(valuesArray[1])
        resetRestFilters(3)
        //API call to get next filter
        let req_val = intersectionWith(valuesArray[0], level4Options, isEqual);
        // props.fetchFilterData({level1:[level1],level2:[level2],level3:request?.level3,level4:req_val},{key:'l6'})
        props.fetchFilterData({level1:[level1],level2:[level2],level3:request?.level3,level4:req_val},{key:'l5'},"style_name")
        props.fetchFilterData({level1:[level1],level2:[level2],level3:request?.level3,level4:req_val},{key:'l5'},"color")
    }

    const onLevel5Change = (value,event,that) => {

        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'level5': valuesArray[0],
            'color': null,
            'style_name': null
            }
        })
        setLevel5(valuesArray[1])
        resetRestFilters(4)
        //API call to get next filter
        let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
        props.fetchFilterData({level1:[level1],level2:[level2],level3:request?.level3,level4:request?.level4, level5: req_val},{key:'l5'},"style_name")
        props.fetchFilterData({level1:[level1],level2:[level2],level3:request?.level3,level4:request?.level4, level5: req_val},{key:'l5'},"color")
    }

    // const onStyleChange = (value,event,that) => {
    //     let valuesArray = handleSelectAll(value,event,that)
    //   // let value = intersectionWith(val, sizeOptions, isEqual);
    //     setRequest(old => {
    //         return {
    //         ...old,
    //         'style_name': valuesArray[0]
    //         }
    //     })
    //     // let value = intersectionWith(val, styleOptions, isEqual);
    //     setStyle(valuesArray[1])
    //     //API call to get next filter
        
    // }

    const onStyleChange = (val, event, that) => {
        // let valuesArray = handleSelectAll(val, event, that);
        let req_val = intersectionWith(val, styleOptions, isEqual);
        setStyle(req_val);
        setRequest(old => {
            return {
                ...old,
                'style_name': req_val,
            }
        });
    };

    
    const onColourChange = (value,event,that) => {
        let valuesArray = handleSelectAll(value,event,that)
          setRequest(old => {
              return {
              ...old,
              'color': valuesArray[0]
              }
          })
        // let value = intersectionWith(val, colourOptions, isEqual);
        setColour(valuesArray[1])
        //API call to get next filter
    }

    const applyFilters = () => {
        setIsRowClicked(false)
        if(level1){
            setUserPreferenceFilter({"user_id":localStorage.getItem("user_id"),"department":level1})
            props.fetchUserCreatedData({
                level1,
                level2,
                level3: request?.level3,
                level4: request?.level4,
                level5: request?.level5,
                style_name: request?.style_name,
                colour: request?.color,
                "page":"ia-recommended"
            })
        }
        else{
            Notify.error("Please Select Mandatory Option!!");
        }
        
    }

    const resetFilters = () => {
        setRequest(null)
        setIsRowClicked(false)
        setLevel1(null);
        setLevel2(null);
        setLevel3(null);
        setLevel4(null);
        setLevel5(null);
        setStyle(null);
        setColour(null);
        props.resetFiltersOptions([]);
    }
    
    return (
        <div className="userCreatedProfile__container">
        <section className="row section">
        <Notification/>
        <div className="col-md-3 required">
                <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
                <Select
                    name="level1"
                    value={level1}
                    onChange={onLevel1Change}
                    placeHolder={`Select ${props.filterLabels?.level1}`}
                    options={(level1Options?.length) ? level1Options : []}
                />
            </div>
            <div className="col-md-3 fill">
                <label className="fnt-md fnt-bold">{props.filterLabels?.level2}</label>
                <Select
                    name="level2"
                    value={level2}
                    onChange={onLevel2Change}
                    placeHolder={`Select ${props.filterLabels?.level2}`}
                    options={(level2Options?.length) ? level2Options : []}
                />
            </div>
            {
                !shouldHideForCurrentClient("l3_name") &&
                <>
                    <div className="col-md-3 fill">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level3}</label>
                        <MultiSelect
                            name="level3"
                            dropdownValue={level3}
                            onDropDownChange={onLevel3Change}
                            placeHolder={`Select ${props.filterLabels?.level3}`}
                            optionsArray={(level3Options?.length) ? level3Options : []}
                        />
                    </div>
                    <div className="col-md-3 fill">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level4}</label>
                        <MultiSelect
                            name="level4"
                            dropdownValue={level4}
                            onDropDownChange={onLevel4Change}
                            placeHolder={`Select ${props.filterLabels?.level4}`}
                            optionsArray={(level4Options?.length) ? level4Options : []}
                        />
                    </div>
                    {
                    !shouldHideForCurrentClient("l5_name") &&
                    <div className="col-md-3 fill">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                        <MultiSelect
                            name="level5"
                            dropdownValue={level5}
                            onDropDownChange={onLevel5Change}
                            placeHolder={`Select ${props.filterLabels?.level5}`}
                            optionsArray={(level5Options?.length) ? level5Options : []}
                        />
                    </div>
                    }
                </>
                }
            {/* <div className="col-md-3 fill">
                <label className="fnt-md fnt-bold">Style</label>
                <MultiSelect
                    name="style_name"
                    dropdownValue={style_name}
                    onDropDownChange={onStyleChange}
                    placeHolder={"Select Style"}
                    optionsArray={(styleOptions?.length) ? styleOptions : []}
                />
            </div> */}
            <div className="col-md-3  fill">
              <label className="fnt-md fnt-bold">Style</label>
              <AsyncMulti
                  name="style"
                  dropdownValue={style_name}
                  onDropDownChange={onStyleChange}
                  placeHolder={'Select Style'}
                  optionsArray={styleOptions?.length ? styleOptions : []}
              />
            </div>
            <div className="col-md-3 fill">
                <label className="fnt-md fnt-bold">Color</label>
                <MultiSelect
                    name="colour"
                    dropdownValue={colour}
                    onDropDownChange={onColourChange}
                    placeHolder={"Select Color"}
                    optionsArray={(colourOptions?.length) ? colourOptions : []}
                />
            </div>
        <div className="col-md-3">
        <button onClick={applyFilters} className="btn btn-primary filter-constraints-mode" title="Apply filters"><i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter</button>
        <button onClick={() => resetFilters('All')} className="btn undo-constraints-mode" title="Reset filters"><i className="fa fa-undo mr-2"></i>Reset</button>
        </div>
    </section>
        <div className="row section">
        <div className="required fnt-md pl-4" style={{ color: "red" }}>
          <label></label>Fields are mandatory
        </div>
      </div>
        <ProfileTable
                header = {articleId}
                style={{margin:'4rem 0 2rem'}}
                baseTableColumns = {userCreatedProfileColumns}
                baseTableData = {props.userCreatedData}
                baseTableError = {props.userCreatedError}
                baseTableloading = {props.userCreatedLoading}
                // tabColumns = {userCreatedProfileTabsColumns}
                tabData = {props.storeData}  
                tabDataError = {props.storeDataError} 
                tabDataLoading = {props.storeLoading}
                isShowTabs= {isRowClicked}
                maxWidth = {maxWidth}
                tabColumns = {column}
        />
    </div>
    )
}

const mapStateToProps = ({ productProfile }) => ({
    userCreatedProfileTabsColumns: productProfile.storeColumn,
    level1Options: productProfile.level1Options,
    level2Options: productProfile.level2Options,
    level3Options: productProfile.level3Options,
    level4Options: productProfile.level4Options,
    level5Options: productProfile.level5Options,
    styleOptions: productProfile.styleOptions,
    colourOptions: productProfile.colourOptions,
    filterLabels: productProfile.filterlabels,
    userCreatedData: productProfile.userCreatedData,
    userCreatedError: productProfile.userCreatedError,
    userCreatedLoading: productProfile.userCreatedloading,
    storeData: productProfile.storeData,
    storeDataError: productProfile.storeError,
    storeLoading: productProfile.storeloading
  });

  const mapDispatchToProps = (dispatch) => ({
    resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
    fetchUserCreatedData: (payload) => dispatch(fetchUserCreatedData(payload)),
    fetchFilterData: (payload,filterKey, filterType) => dispatch(fetchFilterData(payload,filterKey,filterType)),
    fetchStoresData: (payload) => dispatch(fetchStoresData(payload))
  });

export default connect(mapStateToProps, mapDispatchToProps)(IARecommendedProfile)
