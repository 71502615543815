import React from "react";
import Chart from "react-apexcharts";

const BarChartWithDataPercentLabels = (props) => {
    const { data, height, width, text, labels } = props;

    let options = {
        chart: {
            id: "basic-bar",
            width: "100%",
            toolbar: {
                show: false,
            },
        },
        // colors: data.colorOption,
        grid: {
            show: false,
        },
        plotOptions: {
            bar: {
                rangeBarOverlap: false,
                columnWidth: '70%',
                dataLabels: {
                    position: 'center', // top, center, bottom
                },
            }
        },
        xaxis: {
            categories: labels,
            position: 'bottom',
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: true,
                style: {
                    fontWeight: 550,
                }
            },
            title: {
                text: data.xAxisLabel,
                style: {
                    fontWeight: 600,
                },
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
            }
        },
        yaxis: {
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
                style: {
                    fontWeight: 550,
                },
                formatter: function (val) {
                    return val.toFixed(2) + "%";
                }
            },
            title: {
                text: data.yAxisLabel,
                style: {
                    color: '#212529',
                    fontWeight: 700,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
    };

    return (
        <>
            <Chart
                options={options}
                series={data.series}
                type="bar"
                height={height}
                width={width}
            />
        </>
    );
}

export default BarChartWithDataPercentLabels;