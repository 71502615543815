import React, { useState, useEffect } from "react";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import ColumnFilter from "../../UserManagement/ColumnFilter"
import { oneDecimal, twoDecimal } from "../../../utils/formatters/valueFormatters";
import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping"
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import ReactTollTip from "../../../components/Table/Cellrenderer/ReactToolTip";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ProductListTable = (props) => {
    const [tableColums, setTableColumns] = useState([])

    const productDatacolumns = React.useMemo(() => [
        {
            Header: " ",
            sticky: "left",
            columns: [
                {
                    Header: `${updateArticleName(false)} ID`,
                    accessor: 'article',
                    Cell: (instance) => (
                        <div>
                            <div className="clickable" onClick={() => props.getProductDetails(instance.row?.original)}>{instance.row?.original?.article}</div>
                        </div>
                    ),
                    Filter: ColumnFilter,
                },
                {
                    Header: 'Description',
                    accessor: 'description',
                    width: 200,
                    Filter: ColumnFilter,
                },
                {
                    Header: 'Style Color',
                    accessor: 'color_desc',
                    Filter: ColumnFilter,
                }
            ]
        },
        {
            Header: 'Pack ID',
            accessor: 'packs',
            Filter: ColumnFilter
        },
        {
            Header: 'Demand Type',
            accessor: 'demand_type',
            Filter: ColumnFilter,
        },
        {
            Header: 'Store Group',
            accessor: 'store_group',
            Cell: (instance) => <ReactTollTip {...instance} />,
            width: 300,
            Filter: ColumnFilter,
        },
        {
            Header: '# Stores',
            accessor: 'store_group_store_count',
            Filter: ColumnFilter,
        },

        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: "l1_name",
            Filter: ColumnFilter
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: "l2_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: "l4_name",
            Filter: ColumnFilter,
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: "l5_name",
            Filter: ColumnFilter,
        },
        {
            Header: '# Allocated Stores',
            accessor: 'store',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Min Constraint',
            accessor: 'min',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "Allocated Total Quantity",
            columns: [
                {
                    Header: '# Packs Allocated',
                    accessor: 'packs_allocated',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: '# Eaches Allocated',
                    accessor: 'loose_units_allocated',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: 'Total Allocated Units',
                    accessor: 'allocated_quantity',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    width: 200
                }
            ]
        },
        {
            Header: 'DC Available',
            accessor: 'dc_available',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Original APS',
            accessor: row => row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Forecasted APS',
            accessor: row => row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Target WOS',
            accessor: row => row.target_wos ? twoDecimal(Number(row.target_wos)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Forecasted WOS',
            accessor: row => row.actual_wos ? twoDecimal(Number(row.actual_wos)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Size',
            columns: []
        },
    ])

    const setSizeColumns = () => {
        if (props.columns) {
            return true
        }
        // let columns = props.columns ? props.columns : productDatacolumns
        else if (props.sizes.length) {
            props.sizes.forEach((s, index) => {
                productDatacolumns[18].columns.push({
                    Header: s,
                    accessor: row => row.size_value[index] ? Number(row.size_value[index]).toFixed(0) : 0,
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                })
            })
            // setTableColumns(columns)
            return true
        }
        return false
    }

    return (

        setSizeColumns() &&
        // setSizeColumns() &&
        <ReactTableWithPlugins
            hideOptions={props.sizes}
            shouldPagination
            totalRecordsLen={props.data ? props.data.length : 0}
            data={props.data ? props.data : []}
            sortBy={[{ id: 'allocated_quantity', desc: true }]}
            columns={props.columns ? props.columns : productDatacolumns}
            renderMarkup='TableMarkup'
            keyRT="sortAndSearch"
            tableId="finalize_product_list"
        />
    )

}

export default ProductListTable