import React from 'react';
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css"
import { Chart } from 'primereact/chart';

const LineChart = (props) => {

    let basicOptions = {
        legend: {
            position: 'bottom',
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        tooltips: {
            mode: 'index',
            intersect: false
        },
        scales: {
            xAxes: [{
                ticks: {
                    fontColor: '#212529',
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                },
                gridLines: {
                    color: "transparent",
                    display: true,
                    drawBorder: false,
                    zeroLineColor: "#343a40",
                    zeroLineWidth: 2
                },
            }],
            yAxes: [{
                ticks: {
                    fontColor: '#212529',
                },
                gridLines: {
                    color: "transparent",
                    display: true,
                    drawBorder: false,
                    zeroLineColor: "#343a40",
                    zeroLineWidth: 2
                }
            }]
        }
    };

    const { height, width, data } = props;

    return (
        <>
            <Chart type="line" height={height} width={width} data={data} options={basicOptions} />
            <div className="charts__axes"> <span><b>{props.text}</b></span> </div>
        </>

    )
}

export default LineChart;