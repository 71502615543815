import React, { useEffect, useState } from "react";
import "./Filters.css";
import MultiSelect from "./MultiSelect";
import Select from "./SingleSelect";
import { intersectionWith, isEmpty, isEqual } from "lodash";
import { connect } from "react-redux";
import {
  resetFiltersOptions,
  fetchFilterData,
} from "../../containers/Constraints/ConstraintsAction";
import * as Notify from "../Notification/Notifications";
import Notification from "../Notification/Notifications";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import { levelFiltersLabelFormatter } from  "../../utils/filterLevelMapping"
import { getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter } from "../../utils/commonUtilities";
import AsyncMulti from "./AsyncMulti";

const ConstraintsFilter = React.memo(
  ({
    // departmentOptions,
    // genderOptions,
    // rbuOptions,
    // dcsOptions,
    // styleOptions,
    // colourOptions,
    // sizeOptions,
    ...props
  }) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [rbu, setRbu] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [level5, setLevel5] = useState(null);
    const [style_name, setStyle] = useState(null);
    const [colour, setColour] = useState(null);
    const [size, setSize] = useState(null);
    const [storeGrade, setStoreGrade] = useState(null)
    const [request,setRequest] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [rbuOptions, setRbuOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [styleOptions, setStyleOptions] = useState(null);
    const [colourOptions, setColourOptions] = useState(null);
    const [sizeOptions, setSizeOptions] = useState(null);
    const [storeGradeOptions, setStoreGradeOptions] = useState(null);
    const [level5Options, setLevel5Options] = useState(null);

    useEffect(() => {
      console.log('fvfvfd',props.departmentOptions, props.genderOptions, props.rbuOptions,props.dcsOptions, props.styleOptions, props.colourOptions,props.sizeOptions)
      setDepartmentOptions(() => props.departmentOptions);
    }, [props.departmentOptions]);
    

    useEffect(() => {
      setGenderOptions(() => props.genderOptions);
    }, [props.genderOptions]);
    
    useEffect(() => {
     setRbuOptions(() => props.rbuOptions);
    }, [props.rbuOptions]);
    
    useEffect(() => {
      setDCSOptions(() => props.dcsOptions);
    }, [props.dcsOptions]);

    useEffect(() => {
      setLevel5Options(() => props.level5Options);
    }, [props.level5Options]);
    
    useEffect(() => {
      setStyleOptions(() => props.styleOptions);
    }, [props.styleOptions]);
    
    useEffect(() => {   
      setColourOptions(() => props.colourOptions);
    }, [ props.colourOptions]);
    
    useEffect(() => {
      setSizeOptions(() => props.sizeOptions);
    }, [props.sizeOptions]);

    useEffect(() => {
      setStoreGradeOptions(() => props.storeGradeOptions);
    }, [props.storeGradeOptions]);
    
    useEffect(() => {
      getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, onDepartmentChange)
      props.fetchFilterData({},{key:'l1'});
    }, []);

    const resetRestFilters = (p_index) => {
      colour && setColour(null);
      style_name && setStyle(null);
      size && setSize(null);
      storeGrade && setStoreGrade(null);
      colourOptions && setColourOptions(null)
      styleOptions && setStyleOptions(null)
      sizeOptions && setSizeOptions(null)
      storeGradeOptions && setStoreGradeOptions(null)
      if (p_index == 0) {
        gender && setGender(null);
        genderOptions && setGenderOptions(null)
      }
      if (p_index == 0 || p_index == 1) {
        rbu && setRbu(null);
        rbuOptions && setRbuOptions(null)
      }
      if (p_index == 0 || p_index == 1 || p_index == 2) {
        dcs && setDCS(null);
        dcsOptions && setDCSOptions(null)
      }
      if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3) {
        level5 && setLevel5(null);
        level5Options && setLevel5Options(null)
      }
    };

    const onDepartmentChange = (val) => {
      setRequest(old => {
      return {
        ...old,
        'level2': null,
        'level3': null,
        'level4': null,
        'level5': null,
        'style_name': null,
        'color': null,
        'size': null,
        'storeGrade': null,
      }
      })
      setDepartment(val);
      resetRestFilters(0);
      props.fetchFilterData({ level1: [val] },{key:'l2'});
      props.fetchFilterData(
        { level1: [val] },
        {key:'l5'},"style_name"
      );
      props.fetchFilterData(
        { level1: [val] },
        {key:'l5'},"color"
      );
      props.fetchFilterData(
        { level1: [val] },
        {key:'l5'},"size"
      );
      props.fetchFilterData(
        { level1: [val] },
        {key:'l5'},"store_grade"
      );
    };
    const onGenderChange = (val) => {
      setGender(val);
      setRequest(old => {
        return {
          ...old,
          'level2': val,
          'level3': null,
          'level4': null,
          'level5': null,
          'style_name': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      resetRestFilters(1);
      //API call to get next filter
      props.fetchFilterData({ level1: [department], level2: [val] },{key:'l3'});
      props.fetchFilterData(
        { level1: [department], level2: [val] },
        {key:'l5'},"style_name"
      );
      props.fetchFilterData(
        { level1: [department], level2: [val] },
        {key:'l5'},"color"
      );
      props.fetchFilterData(
        { level1: [department], level2: [val] },
        {key:'l5'},"size"
      );
      props.fetchFilterData(
        { level1: [department], level2: [val] },
        {key:'l5'},"store_grade"
      );
    };
    const onRbuChange = (val) => {
      setRbu(val);
      setRequest(old => {
        return {
          ...old,
          'level3': val,
          'level4': null,
          'level5': null,
          'style_name': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      resetRestFilters(2);
      //API call to get next filter
      let req = {
        level1: [department],
        level3: [val]
      }
      if(gender) {
        req['level2'] = [gender]
      }
      props.fetchFilterData(req,{key:'l4'});
      props.fetchFilterData(
        req,
        {key:'l5'},"style_name"
      );
      props.fetchFilterData(
        req,
        {key:'l5'},"color"
      );
      props.fetchFilterData(
        req,
        {key:'l5'},"size"
      );
      props.fetchFilterData(
        req,
        {key:'l5'},"store_grade"
      );
    };

    const onDCSChange = (val) => {
      setDCS(val);
      setRequest(old => {
        return {
          ...old,
          'level4': val,
          'level5': null,
          'style_name': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      resetRestFilters(3);
      let req = {
        level1: [department],
        level4: [val]
      }
      if(gender) {
        req['level2'] = [gender]
      }
      if(rbu) {
        req['level3'] = [rbu]
      }

      //API call to get next filter
      // props.fetchFilterData(req,{key:'l6'});

      props.fetchFilterData(
        req,
        {key:'l5'},"style_name"
      );
      props.fetchFilterData(
        req,
        {key:'l5'},"color"
      );
      props.fetchFilterData(
        req,
        {key:'l5'},"size"
      );
      props.fetchFilterData(
        req,
        {key:'l5'},"store_grade"
      );
    };

    const onLevel5Change = (val) => {
      setLevel5(val);
      setRequest(old => {
        return {
          ...old,
          'level5': val,
          'style_name': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      resetRestFilters(4);
      let req = {
        level1: [department],
        level5: [val]
      }
      if(gender) {
        req['level2'] = [gender]
      }
      if(rbu) {
        req['level3'] = [rbu]
      }
      if(dcs) {
        req['level4'] = [dcs]
      }

      //API call to get next filter
      props.fetchFilterData(
        req,
        {key:'l5'},"style_name"
      );
      props.fetchFilterData(
        req,
        {key:'l5'},"color"
      );
      props.fetchFilterData(
        req,
        {key:'l5'},"size"
      );
      props.fetchFilterData(
        req,
        {key:'l5'},"store_grade"
      );
    };

    // const onStyleChange = (value, event, that) => {
    //   let valuesArray = handleSelectAll(value, event, that)
    //   // let value = intersectionWith(val, styleOptions, isEqual);
    //   setRequest(old => {
    //     return {
    //       ...old,
    //       'style_name': valuesArray[0]
    //     }
    //   })
    //   setStyle(valuesArray[1]);
    //   //API call to get next filter
    // };

    const onStyleChange = (val, event, that) => {
      // let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(val, styleOptions, isEqual);
      setStyle(req_val);
      setRequest(old => {
          return {
              ...old,
              'style_name': req_val,
          }
      });
  };


    const onColourChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      // let value = intersectionWith(val, colourOptions, isEqual);
      setRequest(old => {
        return {
          ...old,
          'color': valuesArray[0]
        }
      })
      setColour(valuesArray[1]);
      //API call to get next filter
    };
    const onSizeChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      // let value = intersectionWith(val, sizeOptions, isEqual);
      setRequest(old => {
        return {
          ...old,
          'size': valuesArray[0]
        }
      })
      setSize(valuesArray[1]);
    };
    const onStoreGradeChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      // let value = intersectionWith(val, sizeOptions, isEqual);
      setRequest(old => {
        return {
          ...old,
          'storeGrade': valuesArray[0]
        }
      })
      setStoreGrade(valuesArray[1]);
    };

    const applyFilters = () => {
      if (department 
        // && gender && rbu
        ) {
        console.log(request,'sdfwef')
        setUserPreferenceFilter({"user_id":localStorage.getItem("user_id"),"department":department})
        props.applyFilters({
          department,
          gender: request?.level2,
          rbu: request?.level3,
          dcs: request?.level4,
          level5: request?.level5,
          style_name: request?.style_name,
          colour: request?.color,
          size: request?.size,
          store_grade: request?.storeGrade
        });
      } else {
        Notify.error("Please Select All Mandatory Options!!");
      }
    };

    const resetFilters = () => {
      setRequest(null)
      setDepartment(null);
      setGender(null);
      setRbu(null);
      setDCS(null);
      setStyle(null);
      setColour(null);
      setSize(null);
      setLevel5(null);
      setStoreGrade(null);
      props.resetTableData();
      props.resetFiltersOptions([]);
      props.setFilters(null)
    };

    return (
      <>
        <section className="row section">
          <Notification />
          <div className="col-md-3 required">
            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
            <Select
              name="department"
              value={department}
              onChange={onDepartmentChange}
              placeHolder={`Select ${props.filterLabels?.level1}`}
              options={departmentOptions?.length ? departmentOptions : []}
            />
          </div>
          <div className="col-md-3 fill">
            <label className="fnt-md fnt-bold">{props.filterLabels?.level2}</label>
            <Select
              name="gender"
              value={gender}
              onChange={onGenderChange}
              placeHolder={`Select ${props.filterLabels?.level2}`}
              options={genderOptions?.length ? genderOptions : []}
            />
          </div>
          {
            !shouldHideForCurrentClient("l3_name") &&
            <>
            <div className="col-md-3 fill">
              <label className="fnt-md fnt-bold">{props.filterLabels?.level3}</label>
              <Select
                name="rbu"
                value={rbu}
                onChange={onRbuChange}
                placeHolder={`Select ${props.filterLabels?.level3}`}
                options={rbuOptions?.length ? rbuOptions : []}
              />
            </div>
            <div className="col-md-3 fill">
              <label className="fnt-md fnt-bold">{props.filterLabels?.level4}</label>
              <Select
                name="dcs"
                value={dcs}
                onChange={onDCSChange}
                placeHolder={`Select ${props.filterLabels?.level4}`}
                options={dcsOptions?.length ? dcsOptions : []}
              />
            </div>
            {
              !shouldHideForCurrentClient("l5_name") &&
              <div className="col-md-3 fill">
                <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                <Select
                  name="level5"
                  value={level5}
                  onChange={onLevel5Change}
                  placeHolder={`Select ${props.filterLabels?.level5}`}
                  options={level5Options?.length ? level5Options : []}
                />
              </div>
            }
          </>
          }
          {/* <div className="col-md-3">
            <label className="fnt-md fnt-bold">Style</label>
            <MultiSelect
              name="style_name"
              dropdownValue={style_name}
              onDropDownChange={onStyleChange}
              placeHolder={"Select Style"}
              optionsArray={styleOptions?.length ? styleOptions : []}
            />
          </div> */}
          <div className="col-md-3  fill">
              <label className="fnt-md fnt-bold">Style</label>
              <AsyncMulti
                  name="style"
                  dropdownValue={style_name}
                  onDropDownChange={onStyleChange}
                  placeHolder={'Select Style'}
                  optionsArray={styleOptions?.length ? styleOptions : []}
              />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Color</label>
            <MultiSelect
              name="colour"
              dropdownValue={colour}
              onDropDownChange={onColourChange}
              placeHolder={"Select Color"}
              optionsArray={colourOptions?.length ? colourOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Size</label>
            <MultiSelect
              name="size"
              dropdownValue={size}
              onDropDownChange={onSizeChange}
              placeHolder={"Select Size"}
              optionsArray={sizeOptions?.length ? sizeOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Store Grade</label>
            <MultiSelect
              name="storeGrade"
              dropdownValue={storeGrade}
              onDropDownChange={onStoreGradeChange}
              placeHolder={"Select Store Grade"}
              optionsArray={storeGradeOptions?.length ? storeGradeOptions : []}
            />
          </div>
          <div className="col-md-3">
            <button
              onClick={applyFilters}
              className="btn btn-primary filter-constraints-mode"
              title="Apply filters"
            >
              <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
            </button>
            <button
              onClick={() => resetFilters("All")}
              className="btn undo-constraints-mode"
              title="Reset filters"
            >
              <i className="fa fa-undo mr-2"></i>Reset
            </button>
          </div>
          <div className="col-md-3 required fnt-md pt-5" style={{ color: "red" }}>
            <label></label>Fields are mandatory
        </div>
        </section>
      </>
    );
  }
);

const mapStateToProps = ({ constraints }) => ({
  filterLabels: constraints.filterlabels,
  departmentOptions: constraints.departmentOptions,
  genderOptions: constraints.genderOptions,
  rbuOptions: constraints.rbuOptions,
  dcsOptions: constraints.dcsOptions,
  level5Options: constraints.level5Options,
  styleOptions: constraints.styleOptions,
  colourOptions: constraints.colourOptions,
  sizeOptions: constraints.sizeOptions,
  storeGradeOptions : constraints.storeGradeOptions
});

const mapDispatchToProps = (dispatch) => ({
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  fetchFilterData: (payload,filterKey,filterType) =>
    dispatch(fetchFilterData(payload,filterKey,filterType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConstraintsFilter);
