import * as InventoryReservationActions from './ActionTypes';

/**
 * Function to dispatch strategy configuration filters loading request
 * 
 */
export const getInventoryReservationFiltersData = (payload, filterType) => ({
    type: InventoryReservationActions.GET_INVENTORY_RESERVATION_FILTER_REQUEST,
    payload,
    filterType,
});

/**
 * Function to dispatch strategy configuration table data loading request
 * 
 */
export const getInventoryReservationTableDetails = (payload) => ({
    type: InventoryReservationActions.GET_INVENTORY_RESERVATION_TABLE_REQUEST,
    payload,
});


/**
 * Function to dispatch strategy configuration data loading request
 */
export const getInventoryReservationData = (payload) => ({
    type: InventoryReservationActions.GET_INVENTORY_RESERVATION_DATA_REQUEST,
    payload,
});

/**
 * Function to dispatch selected rows
 * @param {*} payload 
 * @returns 
 */
export const setRowsSelected = (payload) => ({
    type: InventoryReservationActions.SET_ROWS_SELECTED,
    payload,
});

/**
 * Function to dispatch updated strategy configuration data request
 * 
 */
export const setEditedInventoryReservationData = (payload) => ({
    type: InventoryReservationActions.SET_EDITED_INVENTORY_RESERVATION,
    payload,
});

/**
 * Function to dispatch strategy configuration data saving request
 * 
 */
export const saveInventoryReservationData = (payload) => ({
    type: InventoryReservationActions.SAVE_INVENTORY_RESERVATION_DATA_REQUEST,
    payload,
});

/**
 * Action function reset strategy configuration data
 * @returns 
 */
export const resetInventoryReservationScreen = () => ({
    type: InventoryReservationActions.RESET_INVENTORY_RESERVATION,
});

/**
 * Action function reset save strategy data
 * @returns 
 */
export const resetSaveInventoryReservationScreen = () => ({
    type: InventoryReservationActions.RESET_SAVE_INVENTORY_RESERVATION,
});
