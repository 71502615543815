import React, { useEffect, useState } from "react"
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import PageLoader from "../../../../components/Loader/PageLoader";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ProductProfileDetailsTable = (props) => {
    const { data, loading, error, store, ppName } = props

    const [tableData, setTableData] = useState([])

    const columns = []

    useEffect(() => {
        if (data.length) {
            let row = data.filter(item => item.store_code === store)
            if (row.length) {
                setTableData(row)
            }
        }
    }, [data])

    const getSizes = () => {
        if (tableData.length) {
            Object.keys(tableData[0].size_data).map(size => {
                columns.push({
                    Header: size,
                    accessor: row => row.size_data[size],
                })
            })
        }

        return true
    }

    return (
        getSizes() &&
        <section className="section mr-4 mt-4">

            <PageLoader loader={loading} gridLoader={true}>
                {/* <div className="flex-box-column"> */}
                <div className="storesStore__heading mb-4" style={{ width: "100%" }}>
                    <h1 className="fnt-md fnt-bold text-center" style={{ margin: "auto" }}>Product Profile : {ppName}</h1>
                </div>
                {(error) ? (<div className="error">{error}</div>) :
                    (tableData ? (
                        <ReactTableWithPlugins
                            data={(tableData ? tableData : [])}
                            columns={columns}
                            renderMarkup='TableMarkup'
                            style = {{ maxWidth:'fit-content', margin:'auto'}}
                            tableId="newstore_product_profile"
                        />
                    ) : null)}
                {/* </div> */}
            </PageLoader>
        </section>
    )
}

export default ProductProfileDetailsTable