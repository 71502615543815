import React,{ useEffect, useState } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InventoryReservationFilters from './InventoryReservationFilters'
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import EditIconCell from "../../../components/Table/Cellrenderer/EditIconCell";
import EditInventoryReservation from './EditInventoryReservation';
import * as InventoryReservationActions from './Actions/InventoryReservationActions';
import * as Notify from "../../../components/Notification/Notifications";
import EditInventoryReservePacks from './EditInventoryReservePacks';
import PageLoader from '../../../components/Loader/PageLoader';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

let selectedFilters = {}


const InventoryReservation =(props) =>{
    const [filters, setFilters] = useState({})
    const [showEditModal, setShowEditModal] = useState(false)
    const [editData,setEditData] = useState(null)
    const [editPackData,setEditPackData] = useState(null)
    const [showEditPackModal, setShowEditPackModal] = useState(false)
    const [inventoryReservationTabInst, setInventoryReservationTabInst] = useState(null);
    const [inventoryReservationTableData,setInventoryReservationTableData] = useState([])
    const {InventoryReservationActionsProp} = props

    const handleFilterRequest = (filters) => {
        // setShowArticleConfig(false)
        setFilters(filters)
        if (filters) {
            selectedFilters = filters
        }
        setInventoryReservationTableData([])
        InventoryReservationActionsProp.getInventoryReservationTableDetails(selectedFilters)
    }


    useEffect(() => {
            InventoryReservationActionsProp.resetSaveInventoryReservationScreen()
    }, []);

    useEffect(() => {
        setInventoryReservationTableData(props.data)
    }, [props.data]);

    const coloumn = React.useMemo(
        () => [
            {
                Header: "StyleColor ID",
                accessor: "article",
                Filter: ColumnFilter,
               
            },
            {
                Header: "Style Description",
                accessor: "description",
                width:200,
                Filter: ColumnFilter,
               
            },
            {
                Header: "Color",
                accessor: "color",
                Filter: ColumnFilter,
               
            },
            {
                Header: "Dept",
                accessor: "l1_name",
                Filter: ColumnFilter,
               
            },
            {
                Header: "Sub Dept",
                accessor: "l2_name",
                Filter: ColumnFilter,
               
            },
            {
                Header: "Class",
                accessor: "l3_name",
                Filter: ColumnFilter,
               
            },
            {
                Header: "Sub Class",
                accessor: "l4_name",
                Filter: ColumnFilter,
               
               
            },
            {
                Header: "DC OH",
                columns: [
                    {
                        Header:"Pack Id",
                        accessor: "dc_pack_id",
                        width: 100,
                        Filter:ColumnFilter
                    },
                    {
                        Header:"#Pack",
                        accessor: "dc_pack_nos",
                        width: 100,
                        Filter:ColumnFilter
                    },
                    {
                        Header:"#Eaches",
                        accessor: "dc_eaches_nos",
                        width: 100,
                        Filter:ColumnFilter
                    },
                    {
                        Header:"Total",
                        accessor: "dc_total",
                        width: 100,
                        Filter:ColumnFilter
                    },
                ]
            },
            {
                Header: "Incoming PO/ASN",
                columns: [
                    {
                        Header:"Pack Id",
                        accessor: "po_asn_pack_id",
                        width: 100,
                        Filter:ColumnFilter
                    },
                    {
                        Header:"#Pack",
                        accessor: "po_asn_pack_nos",
                        width: 100,
                        Filter:ColumnFilter
                    },
                    {
                        Header:"#Eaches",
                        accessor: "po_asn_eaches_nos",
                        width: 100,
                        Filter:ColumnFilter
                    },
                    {
                        Header:"Total",
                        accessor: "po_asn_total",
                        width: 100,
                        Filter:ColumnFilter
                    },
                ]
            },
            {
                Header: "User Reserve Units",
                columns: [
                    {
                        Header:"Pack Id",
                        accessor: "user_pack_id",
                        width: 100,
                        Filter:ColumnFilter
                    },
                    {
                        Header:"#Pack",
                        accessor: "user_pack_nos",
                        width: 100,
                        Filter:ColumnFilter
                    },
                    {
                        Header:"#Eaches",
                        accessor: "user_eaches_nos",
                        width: 100,
                        Filter:ColumnFilter
                    },
                    {
                        Header:"Total",
                        accessor: "user_total",
                        width: 100,
                        Filter:ColumnFilter
                    },
                ]
            },
            {
                Header: "Actions",
                accessor: "actions",
                Cell: (instance) => (
                  <div>
                    <EditIconCell
                    handleEdit={editAllocateQty}
                    instance={instance}
                    ></EditIconCell>
                  </div>
                ),
                resizable: true,
                disableFilters: true,
              },  
            
        ]
    )

    const editAllocateQty = (p_instance) => {
        let req ={
            "article":p_instance.article,
            "packColoumn":p_instance?.sizes,
            "availableQuantity":p_instance?.inv_available_dc,
            "reserveQuantity":p_instance?.reserve_quantity_dc,
            "reserveQuantityPack":0,
            "is_pack":p_instance?.is_pack,
            "store_code":p_instance?.store_code,
            "pack_config":p_instance?.pack_config,
            "dc_pack_id":p_instance?.dc_pack_id,
            "units_in_pack":p_instance?.units_in_pack_dc,
            "dc_pack_max":p_instance?.dc_pack_nos
        }
        setEditData(req)
        setShowEditModal(true)
    }

    const closeEditModal = (callAllApi) => {
        setShowEditModal(false)
        setShowEditPackModal(false)
        // if (callAllApi) {
        //     props.callAllApis()
        // }
    }

    const onInventoryReservationMount = (inst) => {
        setInventoryReservationTabInst(inst);
    }

    const handleSet =()=>{
        var req=[]
        let request = {
            article: [...inventoryReservationTabInst.selectedFlatRows.map(row => row.original.article)],
            is_pack: [...inventoryReservationTabInst.selectedFlatRows.map(row => row.original.is_pack)],
            data:[...inventoryReservationTabInst.selectedFlatRows.map(row => {
                req.push({
                    "article":row.original?.article,
                    "size":row.original?.sizes,
                    "inv_available":row.original?.inv_available_dc,
                    "store_code":row.original?.store_code,
                    "pack_id":row.original?.dc_pack_id,
                    "units_in_pack":row.original?.units_in_pack_dc,
                })
                
            })],
            reqData:[...req],
            eaches:[...inventoryReservationTabInst.selectedFlatRows.map(row => row.original.dc_eaches_nos)],
            packs:[...inventoryReservationTabInst.selectedFlatRows.map(row => row.original.dc_pack_nos)]
        };
        setEditPackData(request)
        if (request.article.length === 0) {
            Notify.error("Select atleast one coloumn")
        }
        else if(inventoryReservationTabInst.selectedFlatRows.length!==0){
            let checker = arr => arr.every(v => v === true);
            let checkerData = arr =>arr.every(v => v === false);
            if(!checker(request.is_pack) && !checkerData(request.is_pack)){
                Notify.error("Only Eaches and Packs can be reserved")
            }
            else{
                setShowEditPackModal(true)
            }
        }
        else{
        setShowEditPackModal(true)
        }
        
    }
    console.log('ss',props.dataError)

    return (
        <>
        <InventoryReservationFilters applyFilters={handleFilterRequest}/>
        <section className="row section">
                {(props.dataError) ? (<div className="error" style={{marginLeft:"419px",marginTop:"49px"}}>Something went wrong</div>) :
                    <PageLoader loader={props.dataLoading} gridLoader={true}>
                        <ReactTableWithPlugins
                            shouldPagination
                            // totalRecordsLen={inventoryReservationTableData?.length}
                            getInstanceOnMount={(inst) => onInventoryReservationMount(inst)}
                            data={inventoryReservationTableData.length!==0 ? inventoryReservationTableData:[]}
                            columns={coloumn}
                            renderMarkup='TableMarkup'
                            keyRT="StrategyPageTable"
                            features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                            tableId="inventory_research"
                            
                        /> 
                     </PageLoader>
                } 
            </section>
            {props.dataError ? " ": 
            <div style={{ textAlign: "center", margin: '2%' }}>
                <button
                    className="btn btn-primary"
                    onClick={handleSet}
                // disabled={saveIsDisabled}
                >
                    Reserve Units
                </button>
            </div>
}
            {showEditModal ? 
            <EditInventoryReservation 
            showAlert={true}
            closeAlert={closeEditModal}
            data={editData}
            filter={filters}
            dataRefresh={handleFilterRequest}
            />:null}
            {showEditPackModal ? <EditInventoryReservePacks showAlert={true}
            closeAlert={closeEditModal}
            filter={filters}
            dataRefresh={handleFilterRequest}
            data={editPackData}/>:null}
        </>
    )
}

const mapStateToProps = ({ InventoryReservation }) => ({
    data:InventoryReservation.isInventoryReservationTableData,
    dataLoading:InventoryReservation.isInventoryReservationTableLoading,
    dataError:InventoryReservation.isInventoryReservationTableFailed
});

const mapDispatchToProps = (dispatch) => ({
    InventoryReservationActionsProp: bindActionCreators(InventoryReservationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReservation)