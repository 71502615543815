import React, { useEffect, useMemo, useRef, useState } from 'react'
import './StoreGroup.css'
import WithDynamicPlugins from "../../components/Table/WithPlugins";
import ReactTable from "../../components/Table/ReactTable"
import { Tab, Tabs } from 'react-bootstrap';
import CreateStoreGroupModal from '../../components/Clustering/CreateStoreGroupModal';
import EditIconCell from '../../components/Table/Cellrenderer/EditIconCell';
import { connect } from 'react-redux';
import { storeGroupAction, storeDetailsAction, setstoreDataInFirstOrSecondTable, resetStoreGroup, deleteStoreGroup, resetAll, storeGroupActionForEdit } from './StoreGroupAction'
import PageLoader from '../../components/Loader/PageLoader';
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications"
import ColumnFilter from '../UserManagement/ColumnFilter';
import { scrollIntoView, getStoreGradeColumnNamesByClient, isAshley } from '../../utils/commonUtilities';
import NumberRangeColumnFilter from '../../components/Filters/NumberRangeColumnFilter';
import CreateEditStoreGroup from './CreateEditStoreGroup/CreateEditStoreGroup';
import { levelFiltersLabelFormatter } from '../../utils/filterLevelMapping';
import ReactTollTip from '../../components/Table/Cellrenderer/ReactToolTip';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const Store =  (props) => {

const tableRef = useRef(null);

const [showStoreTable,setShowStoreTable] = useState(false)
const [storeGradeColumns, setStoreGradeColumns]=  useState()


const storecolumns = React.useMemo(() => [
    {
        id: "store_code",
        Header: 'Store Number',
        accessor: 'store_code',
        Filter: ColumnFilter,
    },
    {
        Header: 'Store Name',
        accessor: 'store_name',
        width: 250,
        Filter: ColumnFilter,
    },
    // {
    //     Header: 'Store Grade',
    //     accessor: 'store_grade',
    //     Filter: ColumnFilter,
    // },
    storeGradeColumns,
    {
        Header: 'Region Description',
        accessor: 'region',
        Filter: ColumnFilter,
    },

])

const storeGroupColumn = React.useMemo(
    () => 
     [
        {
            Header: levelFiltersLabelFormatter("level1"),
            id: "l1_name",
            accessor: data => data?.l1_name[0] ? data.l1_name.join(", ") : data?.description,
            // Cell: (instance) => <ReactTollTip {...instance} />,
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 <ReactTollTip {...instance} />         
              </span>
            </div>,
            Filter: ColumnFilter,
            width: 250,
          },
          {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: 'l2_name',
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 {instance.value}        
              </span>
            </div>,
            Filter: ColumnFilter,
            width: 200,
          }, 
          {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: 'l3_name',
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 {instance.value}        
              </span>
            </div>,
            Filter: ColumnFilter,
            width: 200,
          }, 
          {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: 'l4_name',
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 {instance.value}        
              </span>
            </div>,
            Filter: ColumnFilter,
            width: 200,
          }, 
          {
            Header: 'Store Eligibility Group Name',
            accessor: 'title',
            Filter: ColumnFilter,
            Cell: (instance) => (
                <div className="row min-100">
                  <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                    <div className="clickable" onClick = {() => getStoreDetails(instance)}>{instance.row?.original?.title}</div>
                  </span>
                </div>
              ),
              width: 250,
          },
          {
            Header: '# Stores',
            accessor: 'no_of_stores',
            width: 150,
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 {instance.value}        
              </span>
            </div>,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Created By',
            accessor: 'created_by',
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 {instance.value}        
              </span>
            </div>,
            width: 250,
            Filter: ColumnFilter,
          },
          {
            Header: "Actions",
            accessor: "actions",
            sticky: "right",
            Cell: (instance) => (
              <div>
                <EditIconCell
                  handleEdit={editStoreGroup}
                  handleDelete={instance?.row?.original?.is_default ? false : deleteStoreGroup}
                  instance={instance}
                ></EditIconCell>
              </div>
            ),
            resizable: true,
            disableFilters: true,
          },
    ],
  )

 const [existingStoreCode, setExistingStoreCode] = useState({})
 const [showCreateStoreGroupModal, setShowCreateStoreGroupModal] = useState(false)
 const [storeGroupTableData, setStoreGroupTableData] = useState([])
 const [storesStoreData, setStoresStoreData] = useState([])
 const [storeGroupName, setStoreGroupName] = useState()
 const [isCreateStoreGroup, setIsCreateStoreGroup] = useState()
 

 const getStoreGrade = (p_storeGrade) => {
    const gradeMapping = {
        Apparel : 'store_grade_ap',
        Accessories : 'store_grade_ac',
        Footwear : 'store_grade_ft'
    }
        return gradeMapping[p_storeGrade]
 }

 const getStoreDetails = (p_instance) => {
    setStoreGroupName('')
    let l_instance = {...p_instance}
    let l_reqBody =   {
        "store_group_code":[l_instance.row?.original?.store_group_code],
        "Attributes":{
            "store_group_code":[l_instance.row?.original?.store_group_code]
        },
        "Operators":{
            "NonAttributeColumn":["store_group_code"]
        }
    }
    props.storeDetailsAction(l_reqBody)
 }

 const getStoreGroupData = (p_storeGroupData) => {
    let l_storeGroupData = p_storeGroupData.map(val => {
        return { 
            ...val,
            'no_of_stores' : val['stores_codes'].length,
        }
    })

    l_storeGroupData = l_storeGroupData.sort(function(a,b){return b.is_default-a.is_default});
    setStoreGroupTableData(l_storeGroupData)
 }

 const getStoresStoreData = (p_storesStoreData) => {
     let l_storesStoreData = p_storesStoreData.map(val => {
         return {
            ...val,
            'region': val.region?.[0],
            'store_grade': val[getStoreGrade(val.description)]?.[0],
         }
     })
     setStoresStoreData(l_storesStoreData)
 }

 const closeCreateStoreGroupModal = () => {
    setShowCreateStoreGroupModal(false);
    props.resetStoreGroup()
 };

 const createStoreGroup = () => {
    showStoreTable &&  setShowStoreTable(false)
    setIsCreateStoreGroup(true);
    setShowCreateStoreGroupModal(true);
    setExistingStoreCode({})
    props.setstoreDataInFirstOrSecondTable("firstTable")
 }

 const editStoreGroup = (p_instanceData) => {
     let req = {
        storeGroupCode: p_instanceData?.store_group_code,
        storeCode: p_instanceData?.stores_codes,
        storeGroupName: p_instanceData?.title,
        description: p_instanceData?.description,
     }
    showStoreTable && setShowStoreTable(false)
    setIsCreateStoreGroup(false);
    setShowCreateStoreGroupModal(true);
    setExistingStoreCode(req)
    props.storeGroupActionForEdit({
        store_group_code: [p_instanceData?.store_group_code],
    });
 }

 const deleteStoreGroup = (p_instance) => {
    if (window.confirm("Are you sure you wish to delete this store eligibility group?")){
        setShowStoreTable(false)
        props.deleteStoreGroup({"store_group_code":p_instance?.store_group_code})
    }
 }

 useEffect(() => {
    if (props.deleteSuccess) {
      Notify.success("Store Eligibility Group deleted Successfully!!");
      props.storeGroupAction({});
    }
    if (props.deleteError) {
      Notify.error("Error in deleting Store Eligibility Group!!");
    }
  }, [props.deleteError, props.deleteSuccess]);

    useEffect(() => {
        if(Object.keys(existingStoreCode).length){
            props.setstoreDataInFirstOrSecondTable("secondTable")
        }
    }, [existingStoreCode])   

    useEffect(() => { 
        if(props.storeGroupData.length)
          getStoreGroupData(props.storeGroupData);
    }, [props.storeGroupData])

    useEffect(() => { 
        if(props.storeGroupData.length && props.storesStoreData?.length)
        {
            getStoresStoreData(props.storesStoreData)
            let l_storeGroupName = props.storeGroupData.find(value => value.store_group_code == props.storesStoreData[0]['store_group_code'])['title']
            setShowStoreTable(true)
            setStoreGroupName(l_storeGroupName)
            scrollIntoView(tableRef)
        }
    }, [props.storesStoreData])

    useEffect(() => {
        props.storeGroupAction({});
        return () => {
            props.resetAll()
        }
    }, [])

    useEffect(() => {
        let storeGradeColumn = getStoreGradeColumnNamesByClient()
        storeGradeColumn && setStoreGradeColumns(storeGradeColumn)
    }, [])

    return (
        <>
        <div className="store__container">
        {!showCreateStoreGroupModal && <Notification />}
            <div className="container__header__with__button">
                <h1 className="fnt-lg fnt-bold">Store Eligibility Groups</h1>
                <div className="container__button">
                    <button className="btn-primary-rounded" onClick={createStoreGroup}>
                        <i className="fa fa-plus" title="Create store group" aria-hidden="true"></i>
                    </button>
                    <p className="fnt-sm">Create Store Eligibility Group</p>
                </div>
            </div>
            <div className="container__body">
                <div className="container__tabs">
                    <Tabs defaultActiveKey="ucss">
                        <Tab eventKey="ucss" title="User Created Store Eligibility Groups">
                            <div className="store__tables">
                                <div className="store__group__table">
                                <PageLoader loader={props.storeGroupLoading} gridLoader={true}>
                                    {(props.storeGroupError) ?  (<div className="error">{props.storeGroupError}</div>) :
                                        (storeGroupTableData.length ? (
                                        <ReactTableWithPlugins
                                        shouldPagination
                                        totalRecordsLen={storeGroupTableData.length}
                                        data={storeGroupTableData}
                                        columns={storeGroupColumn}
                                        renderMarkup='TableMarkup'
                                        keyRT="sortAndSearch"
                                        tableId="store_group1"
                                       />
                                    ):null)}
                                    <div className="mt-3 d-flex align-items-center">
                                        <div className = "indicate-div sister-store__map"></div>
                                        <span className = "ml-2 font-italic mb-0"> Default Store Eligibility Group</span>
                                    </div>
                                </PageLoader>
                                </div>
                                <hr/>
                                <div ref={tableRef} className="stores__table">
                                <PageLoader loader={props.storesStoreLoading} gridLoader={true}>
                                    {(props.storesStoreError) ?  (<div className="error">{props.storesStoreError}</div>) :
                                        (storesStoreData.length && showStoreTable ?  (
                                        <>
                                        <div className="storesStore__heading">
                                            <h1 class="fnt-md fnt-bold">{storeGroupName}</h1>
                                            <h1 class="fnt-md fnt-bold">Stores</h1>
                                        </div>  
                                       
                                            <ReactTableWithPlugins
                                            shouldPagination
                                            totalRecordsLen={storesStoreData.length}
                                            data={storesStoreData}
                                            columns={storecolumns}
                                            renderMarkup='TableMarkup'
                                            keyRT="sortAndSearch"
                                            tableId="store_group2"
                                            />
                                        </>
                                    ):null)}
                                </PageLoader>
                            </div>
                            </div>
                        </Tab>
                        {
                            !isAshley() &&
                            <Tab eventKey="ai" title="Assortment Input" disabled></Tab>
                        }
                          
                    </Tabs>
                </div>
            </div>
        </div>
         {showCreateStoreGroupModal ? (
            <CreateEditStoreGroup
              showAlert={true}
              closeAlert={closeCreateStoreGroupModal}
              existingStoreCode={existingStoreCode}
              isCreateStoreGroup = {isCreateStoreGroup}
            />
          ) : null}
    </>
    )
}


    const mapStateToProps = (store) => {
        return {
        storeGroupLoading: store.storeGroup?.storeGroupLoading,
        storesStoreLoading: store.storeGroup?.storesStoreLoading,
        storeGroupData: store.storeGroup?.storeGroupData,
        storeGroupError: store.storeGroup?.storeGroupError,
        storesStoreData: store.storeGroup?.storesStoreData,
        storesStoreError: store.storeGroup?.storesStoreError,
        deleteSuccess: store.storeGroup?.deleteSuccess,
        deleteError: store.storeGroup?.deleteError,
        };
    };

    const mapDispatchToProps = (dispatch) => ({
        storeGroupAction: (payload) => dispatch(storeGroupAction(payload)),
        storeGroupActionForEdit:  (payload) => dispatch(storeGroupActionForEdit(payload)),
        storeDetailsAction: (payload) => dispatch(storeDetailsAction(payload)),
        setstoreDataInFirstOrSecondTable: (payload) => dispatch(setstoreDataInFirstOrSecondTable(payload)),
        deleteStoreGroup: (payload) => dispatch(deleteStoreGroup(payload)),
        resetStoreGroup: () => dispatch(resetStoreGroup()),
        resetAll: () => dispatch(resetAll()),
    });

export default connect(mapStateToProps, mapDispatchToProps)(Store);