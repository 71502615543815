import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Calendar } from "primereact/calendar";
import { isEmpty } from 'lodash';
import moment from "moment";
import ReactTable from '../../../../components/Table/ReactTable';
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import Select from "../../../../components/Filters/SingleSelect";
import * as storeConfigDetailsActions from '../../actions/StoreDetailsConfigActions/StoreConfigActions';
import * as StoreDetailsConsts from './StoreDetailsDcConfigConstants';
import "./Storedetailsconfig.css";
import { getStoreGradeColumnNamesByClient, getCapacityColumnsByClient } from "../../../../utils/commonUtilities"

/**
 * Table Plugin
 */
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

/**
 * New Store Filetring Component
 * @param {*} props 
 * @returns 
 */
const EnterNewStoreSectionView = (props) => {
    const { storeConfigDetailsActionsProp, newStoreDetailsData, newStoreOptionsData,
        selectedNewStoreCode, selectedNewStore, newStoreOpeningDate, editModeDetails } = props;

    const newStoreTableColumns = React.useMemo(
        () => [
            {
                Header: StoreDetailsConsts.STORE_NAME_FIELD_LABEL,
                accessor: 'store_name',
                width: 120,
            },
            getStoreGradeColumnNamesByClient(),
            {
                Header: StoreDetailsConsts.REGION_FIELD_LABEL,
                accessor: 'region',
                width: 150,
            },
            getCapacityColumnsByClient()
        ],
        []
    );

    useEffect(() => {
        if (!isEmpty(editModeDetails) && editModeDetails?.isEditMode) {
            storeConfigDetailsActionsProp.setSelectedNewStoreCode(
                {
                    value: editModeDetails?.editModeNewStoreCode,
                    label: editModeDetails?.editModeNewStoreCode
                }
            );
            const selectedStore = newStoreDetailsData.filter(detail => detail.store_code === editModeDetails?.editModeNewStoreCode);
            storeConfigDetailsActionsProp.setSelectedNewStoreDetail(selectedStore);
        }
    }, []);

    const onStoreNumberChange = (storeCode) => {
        storeConfigDetailsActionsProp.setSelectedNewStoreCode(storeCode);
        const selectedStore = newStoreDetailsData.filter(detail => detail.store_code === storeCode.value);
        storeConfigDetailsActionsProp.setSelectedNewStoreDetail(selectedStore);
    };

    const handleOpeningDateChange = (e) => {
        if (e) {
            const changedOpeningDate = moment(e.value).format("YYYY-MM-DD");
            storeConfigDetailsActionsProp.editOpeningDate(changedOpeningDate);
        } else {
            //No code here
        }
    };

    return (
        <div id="STORE_SEARCH_CONTAINER" className="store-search-container">
            <div className="p-field p-grid required">
                <label className="non-editable-labels p-col-fixed">
                    {StoreDetailsConsts.STORE_SEARCH_FIELD_LABEL}
                </label>
                <div className="p-col" style={{ marginTop: "1%" }}>
                    <Select
                        id="NEW_STORE_SELECTOR"
                        name="storeNumber"
                        value={selectedNewStoreCode}
                        onChange={onStoreNumberChange}
                        placeHolder="Select Store"
                        options={newStoreOptionsData}
                        className="p-col"
                        isDisabled={editModeDetails?.isEditMode}
                    />
                </div>

            </div>
            {selectedNewStoreCode && !isEmpty(selectedNewStoreCode.value) && !isEmpty(selectedNewStore) ?
                <>
                    <div className="p-field p-grid" style={{ marginTop: "2%" }}>
                        <label className="non-editable-labels p-col-fixed">
                            {StoreDetailsConsts.EXPECTED_OPENING_DATE_FIELD_LABEL}
                        </label>
                        <div className="p-col">
                            <Calendar
                                id="OPENING_DATE"
                                className="p-mb-2 opening-date-cal"
                                dateFormat="mm-dd-yy"
                                value={editModeDetails?.isEditMode && editModeDetails?.editModeOpeningDate ? new Date(editModeDetails.editModeOpeningDate) : new Date(newStoreOpeningDate)}
                                onChange={(e) => handleOpeningDateChange(e)}
                                readOnlyInput
                                showIcon
                            />
                        </div>
                    </div>
                    <ReactTableWithPlugins
                        shouldPagination={false}
                        data={selectedNewStore}
                        columns={newStoreTableColumns}
                        renderMarkup='TableMarkup'
                        tableId="newstore_enter_new"
                    />
                </>
                : null}
        </div>
    );
}

const mapStateToProps = ({ newStoreDetailConfig }) => ({
    newStoreDetailsData: newStoreDetailConfig.newStoreDetailsData,
    newStoreOptionsData: newStoreDetailConfig.newStoreOptionsData,
    selectedNewStoreCode: newStoreDetailConfig.selectedNewStoreCode,
    selectedNewStore: newStoreDetailConfig.selectedNewStore,
    newStoreOpeningDate: newStoreDetailConfig.newStoreOpeningDate,
    editModeDetails: newStoreDetailConfig?.editModeDetails,
});

const mapDispatchToProps = (dispatch) => ({
    storeConfigDetailsActionsProp: bindActionCreators(storeConfigDetailsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnterNewStoreSectionView);