import * as actionTypes from "./ActionTypes"

/*
Function to dispatch filter options
*/

export const fetchFilterData = (payload, filterKey) => ({
    type: actionTypes.FETCH_FILTER_DATA,
    payload,
    filterKey
})

/*
Function to dispatch fetching the product status data
*/

export const getProductStatusData = (payload) => ({
    type: actionTypes.GET_PRODUCT_STATUS_DATA,
    payload
})

/*
Function to dispatch resetting filter options
*/

export const resetFiltersOptions = (payload) => ({
    type: actionTypes.RESET_FILTERS_DATA,
    payload,
})

export const resetCreateConfigData = () => ({
    type: actionTypes.RESET_CREATE_CONFIG_DATA
})

/*
Function to dispatch article level configuration data
*/ 

export const getArticleConfigData = (payload) => ({
    type: actionTypes.GET_ARTICLE_CONFIG_DATA,
    payload
})

/*
Function to dispatch resetting product status state
*/

export const deleteProductConfig = (payload) => ({
    type: actionTypes.DELETE_CONFIG,
    payload
})

export const saveSelectedFilters = (payload) => ({
    type: actionTypes.SAVE_FILTER,
    payload
})

export const resetAll = () => ({
    type: actionTypes.RESET_ALL,
});

export const getMissingConfigData = () => ({
    type: actionTypes.GET_MISSING_CONFIG
})