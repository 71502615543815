import { takeLatest, put, call, all } from 'redux-saga/effects';
import { FETCH_LOGIN_API, fetchLoginApiSuccess, fetchLoginApiError } from './LoginActions';

// apis
import {
  loginApiData
} from "../../routes/api";

// Worker Saga

function* loginApiDataFetch(action) {
  //

  try {
    const res = yield call(loginApiData, action.payload);
    if(res.data.status){
      yield put(fetchLoginApiSuccess(res.data));
    }
    else{
      yield put(fetchLoginApiError('Error!'));  
    }
  } catch (err) {
    yield put(fetchLoginApiError('Error!'));
  }
}

function* watchLoginApi() {
  yield takeLatest(FETCH_LOGIN_API, loginApiDataFetch);
}

export function* loginSaga() {
  yield all([watchLoginApi()]);
}
