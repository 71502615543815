import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import * as lostSalesActionsTypes from '../actions/actionTypes';
import {
    getLostSalesFilterData, getLostSalesReportAll,
    getLostSalesReportDetailsTable
} from "../../../../routes/api";
import { updateArticleName } from "../../../../utils/filterLevelMapping";

/**
 * Sagas to fetch Lost Sales Filters Data
 * @param {*} action 
 */
function* fetchLostSalesFilters(action) {
    try {
        const { payload, filterType } = action;
        let req = {};
        if (payload) {
            for (const key in payload) {
                if (payload[key] && payload[key].length > 0)
                    req[key] = payload[key]?.map((ele) => ele.value);
            }
        }

        // if (filterType) {
        //     req["filter_type"] = filterType;
        // }

        const res = yield call(getLostSalesFilterData, req);

        if (res.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping;
            // let topObject = Object.keys(res.data.data[0])[0];
            for (const key in res.data.data[0]) {
                let k = "";
                if (key === "level1") {
                    k = "departmentOptions";
                } else if (key === "level2") {
                    k = "genderOptions";
                } else if (key === "level3") {
                    k = "subCatOptions";
                } else if (key === "level4") {
                    k = "dcsOptions";
                } else if (key === "level5") {
                    k = "level5Options";
                } else if (key === "style") {
                    k = "styleOptions";
                } else if (key === "color") {
                    k = "colorOptions";
                } else if (key === "article") {
                    k = "articleIdOptions";
                } else if (key === "store") {
                    k = "storeIdOptions";
                }

                data[k] = res.data.data[0][key]?.filter(val => val).map((element) => ({
                    value: element,
                    label: element,
                }));
            }

            let payload = {
                data: data,
                key: filterType,
            };

            yield put({ type: lostSalesActionsTypes.GET_LOST_SALES_FILTER_SUCCESS, payload });
        }
    } catch (error) {
        yield put({ type: lostSalesActionsTypes.GET_LOST_SALES_FILTER_FAILED, error: "Something went wrong!" });
    }
}

/**
 * Sagas to fetch Lost Sales Table Details Data
 * @param {*} action 
 */
function* fetchLostSalesReportDetailsData(action) {
    const { payload } = action;

    try {
        const res = yield call(getLostSalesReportDetailsTable, payload);
        if (res.data.status) {
            let response = res.data;
            response['total_lost_units'] = res.data.lost_sales_details.reduce((acc, item) => { return acc + item.lost_units }, 0);
            response['total_lost_revenue'] = res.data.lost_sales_details.reduce((acc, item) => { return acc + item.lost_sales }, 0);
            response['excel_data'] = res.data.lost_sales_details.map(detail => ({
                "Store": detail.store,
                "Store Name": detail.store_name,
                "Fiscal Week": detail.fiscal_week,
                [`${updateArticleName(false)} ID`]: detail.article,
                "Color": detail.color,
                "Style Color": detail.style_color,
                "Units Sold": detail.units,
                "Avg Store Cluster Units": detail.cluster_avg_sales,
                "Opening Inventory": detail.opening_inventory,
                "Lost Opportunity (Units)": detail.lost_units,
                "Lost Opportunity (Revenue)": detail.lost_sales,
                "Style Description": detail.style_desc,
            }));

            yield put({ type: lostSalesActionsTypes.GET_LOST_SALES_REPORT_DETAILS_SUCCESS, response });
        }
    } catch (error) {
        yield put(({ type: lostSalesActionsTypes.GET_LOST_SALES_REPORT_DETAILS_FAILED, error: "Something went wrong!" }));
    }
}

/**
 * Sagas to fetch Lost Sales All Data
 * @param {*} action 
 */
function* fetchLostSalesReportAllData(action) {
    const { payload } = action;
    try {
        const res = yield call(getLostSalesReportAll, payload);
        if (res.data.status) {
            let response = res.data;
            response['total_lost_units'] = res.data.lost_sales_details.reduce((acc, item) => { return acc + item.lost_units }, 0);
            response['total_lost_revenue'] = res.data.lost_sales_details.reduce((acc, item) => { return acc + item.lost_sales }, 0);
            response['fiscal_weeks_data'] = res.data.weeks_available.sort((one, two) => one - two).map((element) => ({
                value: element,
                label: element,
            }));
            response['latest_week_option'] = [res.data.latest_week].map((element) => ({
                value: element,
                label: element,
            }));
            response['excel_data'] = res.data.lost_sales_details.map(detail => ({
                "Store": detail.store,
                "Store Name": detail.store_name,
                "Fiscal Week": detail.fiscal_week,
                [`${updateArticleName(false)} ID`]: detail.article,
                "Color": detail.color,
                "Style Color": detail.style_color,
                "Units Sold": detail.units,
                "Avg Store Cluster Units": detail.cluster_avg_sales,
                "Opening Inventory": detail.opening_inventory,
                "Lost Opportunity (Units)": detail.lost_units,
                "Lost Opportunity (Revenue)": detail.lost_sales,
                "Style Description": detail.style_desc,
            }));

            yield put({ type: lostSalesActionsTypes.GET_LOST_SALES_REPORT_ALL_SUCCESS, response });
        }
    } catch (error) {
        yield put(({ type: lostSalesActionsTypes.GET_LOST_SALES_REPORT_ALL_FAILED, error: "Something went wrong!" }));
    }
}

/**
 * Watcher for getting lost sales filters
 */
function* watchLostSalesFiltersRequest() {
    yield takeEvery(lostSalesActionsTypes.GET_LOST_SALES_FILTER_REQUEST, fetchLostSalesFilters);
}

/**
 * Watcher for getting latest lost sales table details
 */
function* watchLostSalesReportTableDetailsRequest() {
    yield takeLatest(lostSalesActionsTypes.GET_LOST_SALES_REPORT_DETAILS_REQUEST, fetchLostSalesReportDetailsData);
}

/**
 * Watcher for getting latest lost sales all data
 * 
 */
function* watchLostSalesReportAllRequest() {
    yield takeLatest(lostSalesActionsTypes.GET_LOST_SALES_REPORT_ALL_REQUEST, fetchLostSalesReportAllData);
}

/**
 * Root saga
 */
export function* lostSalesReportSagas() {
    yield all([
        watchLostSalesReportAllRequest(),
        watchLostSalesReportTableDetailsRequest(),
        watchLostSalesFiltersRequest(),
    ]);
}