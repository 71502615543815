import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { intersectionWith, isEqual } from 'lodash';
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import MultiSelect from "../../../components/Filters/MultiSelect";
import Select from "../../../components/Filters/SingleSelect";
import * as InventoryReservationActions from './Actions/InventoryReservationActions';
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping";
import { getUserPreferenceFilter, setUserPreferenceFilter } from "../../../utils/commonUtilities";

/**
 * View Component with Ada Stand Alone Filters
 * 
 */
const InventoryReservationFilters = React.memo((props) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [subCat, setSubCat] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [reservationFilter, setReservationFilter] = useState();
    const [request, setRequest] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [subCatOptions, setSubCatOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [reservationOptions, setReservationOptions] = useState(null);
    // const [checkboxdata, setCheckedItems] = useState({"PrepackStyleColors":true});
    const {InventoryReservationActionsProp} = props

    useEffect(() => {
        InventoryReservationActionsProp.getInventoryReservationFiltersData({}, "department");
    }, []);

    useEffect(()=>{
        if(props.reservationOptions.length){
            setReservationFilter({value:"packs",label:"Packs"})
        }
    },[props.reservationOptions])

    useEffect(() => {
        setDepartmentOptions(() => props.departmentOptions);
        setGenderOptions(() => props.genderOptions);
        setSubCatOptions(() => props.subCatOptions);
        setDCSOptions(() => props.dcsOptions);
        setReservationOptions(()=> props.reservationOptions)
    }, [props.departmentOptions, props.genderOptions, props.subCatOptions, props.dcsOptions,props.reservationOptions]);

    const resetRestFilters = (p_index) => {
        if (p_index === 0) {
            gender && setGender(null);
            genderOptions && setGenderOptions(null);

        }
        if (p_index === 0 || p_index === 1) {
            subCat && setSubCat(null);
            subCatOptions && setSubCatOptions(null);
            
        }
        if (p_index === 0 || p_index === 1 || p_index === 2) {
            dcs && setDCS(null);
            dcsOptions && setDCSOptions(null);

        }
    };

    const onDepartmentChange = (val) => {
        setDepartment(val);
        InventoryReservationActionsProp.getInventoryReservationFiltersData({ level1: [val] }, "gender");
        resetRestFilters(0);
    };

    const onGenderChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
            setGender(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level2': valuesArray[0],
                }
            });

            InventoryReservationActionsProp.getInventoryReservationFiltersData({ level1: [department], level2: req_val }, "sub_category");
        }
        else {
            setGender(val);
            InventoryReservationActionsProp.getInventoryReservationFiltersData({ level1: [department], level2: [val] }, "sub_category");
        }
        resetRestFilters(1);
    };

    const onSubCatChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], subCatOptions, isEqual);
            setSubCat(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level3': valuesArray[0],
                }
            });
            InventoryReservationActionsProp.getInventoryReservationFiltersData({ level1: [department], level2: gender, level3: req_val }, "dcs");
        }
        else {
            setSubCat(val);
            InventoryReservationActionsProp.getInventoryReservationFiltersData({ level1: [department], level2: gender?.value && [gender], level3:[val] }, "dcs");
        }
        resetRestFilters(2);
    };

    const onDCSChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
            setDCS(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level4': valuesArray[0],
                }
            });

            InventoryReservationActionsProp.getInventoryReservationFiltersData({
                level1: [department], level2: gender, level3: subCat,
                level4: req_val,
            });
        }
        else {
            setDCS(val);
            InventoryReservationActionsProp.getInventoryReservationFiltersData(
                { level1: [department], level2: gender?.value && [gender], level3:  subCat?.value && [subCat], level4: [val] }
            );
        }
        resetRestFilters(3);
    };

    const onReservationFilterChange = (val, event ,that)=>{
        setReservationFilter(val)
    }
    console.log('sss',reservationFilter,reservationOptions)


    const applyFilters = () => {
        if (department) {
           props.applyFilters({
                "level1": [department.value],
                "level2":  gender?.value && [gender.value],
                "level3": subCat?.value && [subCat.value],
                "level4": dcs?.value && [dcs.value],
                "filter_type" : reservationFilter?.value
            });
            setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department });
        } else {
            Notify.error("Please Select All Mandatory Options!!");
        }
    };

    const resetFilters = () => {
        setRequest(null);
        setDepartment(null);
        setGender(null);
        setSubCat(null);
        setDCS(null);
        setReservationFilter(null)
        InventoryReservationActionsProp.getInventoryReservationFiltersData({}, "department");
    };

    useEffect(() => {
        getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
    }, []);

    // const Checkbox = ({ type = "checkbox", name, checked = false, onChange, id }) => {
    //     return (
    //       <input id={id} type={type} name={name} checked={checked} onChange={onChange} />
    //     );
    //   };

    //   const checkboxes = [
    //     {
    //       name: "PrepackStyleColors",
    //       key: "checkBox1",
    //       label: "PrepackStyleColors"
    //     }
    //   ];

    //   const handleChange = event => {
    //     // if(props.isEdit){
    //         // setIsEditing(true)
    //         // props.onChanged('setAttributesFilter')
    //     // }
    //   setCheckedItems((old) => {
    //    return{ 
    //         ...old,
    //         [event.target.name]: event.target.checked
    //     }
    //   });
    // };


    const getDDLabel = (label) => label ? label : "";

    return (
        <>
            <Notification />
            <section className="row section" style={{ flexDirection: "column" }}>
                <div className="row" style={{ flexDirection: "column", marginTop: "0" }}>
                    <div className="row">
                        <div className="col-md-3 col-md-3-dd required">
                            <label className="fnt-md fnt-bold fnt-bold">{levelFiltersLabelFormatter("level1")}</label>
                            <Select
                                name="department"
                                value={department}
                                onChange={onDepartmentChange}
                                placeHolder={`Select ${levelFiltersLabelFormatter("level1")}`}
                                options={departmentOptions?.length ? departmentOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{levelFiltersLabelFormatter("level2")}</label>
                            <Select
                                name="gender"
                                value={gender}
                                onChange={onGenderChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level2)}`}
                                options={genderOptions?.length ? genderOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{levelFiltersLabelFormatter("level3")}</label>
                            <Select
                                name="subCat"
                                value={subCat}
                                onChange={onSubCatChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level3)}`}
                                options={subCatOptions?.length ? subCatOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{levelFiltersLabelFormatter("level4")}</label>
                            <Select
                                name="dcs"
                                value={dcs}
                                onChange={onDCSChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level4)}`}
                                options={dcsOptions?.length ? dcsOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">Inventory Configuration</label>
                            <Select
                                name="reservationFilter"
                                value={reservationFilter}
                                onChange={onReservationFilterChange}
                                placeHolder={`Select Reservation Filter`}
                                options={reservationOptions?.length ? reservationOptions : []}
                            />
                        </div>
                        <hr/> 
                        <div style={{ marginTop: "10px" }} className="col-md-3 mt-3">
                            <button
                                onClick={applyFilters}
                                className="btn btn-primary filter-constraints-mode"
                                title="Apply filters"
                            >
                                <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                            </button>
                            <button
                                onClick={() => resetFilters()}
                                className="btn undo-constraints-mode"
                                title="Reset filters"
                            >
                                <i className="fa fa-undo mr-2"></i>Reset
                            </button>
                        </div>
                        <div className="col-md-3 required fnt-md pt-5" style={{ color: "red" }}>
                            <label></label>Fields are mandatory
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
);
const reservationFillter =[{ value:"eaches",label:"Eaches"},{value:"packs",label:"Packs"}]

const mapStateToProps = ({ InventoryReservation }) => ({
    departmentOptions: InventoryReservation.level1Options ,
    genderOptions: InventoryReservation.level2Options ,
    subCatOptions: InventoryReservation.level3Options,
    dcsOptions: InventoryReservation.level4Options,
    reservationOptions : reservationFillter,
    filterLabels: InventoryReservation.filterLabels,
});

const mapDispatchToProps = (dispatch) => ({
    InventoryReservationActionsProp: bindActionCreators(InventoryReservationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReservationFilters);