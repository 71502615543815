import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import * as strategyConfigActionTypes from '../actions/actionTypes';
import * as Notify from "../../../components/Notification/Notifications"
import { rowCount } from "../views/StrategyConfigurationTable/StrategyConfigurationTableView"
import { getStrategyConfigFiltersData, getStrategyConfigTableData, getStrategyConfigData, saveStrategyConfigData, deleteStrategyConfigApi } from "../../../routes/api";

/**
 * Sagas to fetch Strategy Configuration Filters Data
 * @param {*} action 
 */
function* fetchStrategyConfigFilters(action) {
    try {
        const { payload, filterType } = action;
        let req = {};

        if (payload) {
            for (const key in payload) {
                if (payload[key] && payload[key].length > 0)
                    req[key] = payload[key]?.map((ele) => ele.value);
            }
        }

        if (filterType) {
            req["filter_type"] = filterType;
        }

        const res = yield call(getStrategyConfigFiltersData, req);

        if (res?.data.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping;
            for (const key in res.data.levels[0]) {
                let k = "";
                if (key === "l1_name") {
                    k = "level1Options";
                } else if (key === "l2_name") {
                    k = "level2Options";
                } else if (key === "l3_name") {
                    k = "level3Options";
                } else if (key === "l4_name") {
                    k = "level4Options";
                } else if (key === "l5_name") {
                    k = "level5Options";
                } else if (key === "color") {
                    k = "colorOptions";
                }

                data[k] = res.data.levels[0][key]?.filter(val => val).map((element) => ({
                    value: element,
                    label: element,
                }));
            }

            if (res.data?.style_name && res.data.style_name.length > 0) {
                data["styleOptions"] = res.data.style_name.map((ele) => ({label: ele.style_name, value: ele.style_name}));
            }

            if (res.data?.season && res.data.season[0]?.season_code.length > 0) {
                data["seasonOptions"] = res.data.season[0].season_code?.filter(val => val).map((element) => ({
                    value: element,
                    label: element,
                }));
            }

            let payload = {
                data: data,
                key: filterType,
            };

            yield put({ type: strategyConfigActionTypes.GET_STRATEGY_CONFIG_FILTER_SUCCESS, payload });
        }
    } catch (error) {
        yield put({ type: strategyConfigActionTypes.GET_STRATEGY_CONFIG_FILTER_FAILED, error: "Something went wrong!" });
    }
}

/**
 * Sagas to fetch Strategy Config Table Details Data
 * @param {*} action 
 */
function* fetchStrategyConfigTableData(action) {
    try {
        const { payload } = action;
        let req = {};


        // for (const key in payload) {
        //     console.log('ssss',payload[key])
        //     if (payload[key])
        //         req[key] = payload[key]?.map((ele) => ele.value);
        // }
        // console.log('ss',req)

        const res = yield call(getStrategyConfigTableData, payload);
        if (res.data.status) {
            let response = res.data;
            response.nextIndex = payload.row_index + rowCount// nextIndex: payload.rowIndex + rowCount,
            response.searchColumns = payload.searchColumns
            yield put({ type: strategyConfigActionTypes.GET_STRATEGY_CONFIG_TABLE_SUCCESS, response });
        }
    } catch (error) {
        yield put(({ type: strategyConfigActionTypes.GET_STRATEGY_CONFIG_TABLE_FAILED, error: "Something went wrong!" }));
    }
}

function* fetchStrategyConfigData(action) {
    const { payload } = action;
    if (payload?.article) {
        var req = {
            article: [...payload?.article],
        };
    }
    else{
        var req = payload
    }

    try {
        const res = yield call(getStrategyConfigData, req);
        if (res.data.status) {
            let response = res.data;
            response["configBody"] = {
                ...res.data.data[0],
                article: req?.article ? req?.article : res.data.data[0].articles,
                color_desc: "pink",
                in_season_target: ["DCS"],
                clearance_season_target: ["DCS"],
                pre_season_target: [
                    "DCS"
                ],
                // pre_season_date: "2021-09-29T15:17:32",
                // in_season_date: "2021-11-29T15:17:32",
                // clearance_date: "2021-12-29T15:17:32",
            };

            yield put({ type: strategyConfigActionTypes.GET_STRATEGY_CONFIG_DATA_SUCCESS, response });
        }
    } catch (error) {
        yield put(({ type: strategyConfigActionTypes.GET_STRATEGY_CONFIG_DATA_FAILED, error: "Something went wrong!" }));
    }
}

function* postStrategyConfigData(action) {
    const { payload } = action;

    try {
        const res = yield call(saveStrategyConfigData, payload);
        if (res.data.status) {
            let response = res.data;
            Notify.success("Changed Successfully")
            yield put({ type: strategyConfigActionTypes.SAVE_STRATEGY_CONFIG_DATA_SUCCESS, response });
        }
    } catch (error) {
        Notify.error("Something went wrong")
        yield put(({ type: strategyConfigActionTypes.GET_STRATEGY_CONFIG_DATA_FAILED, error: "Something went wrong!" }));
    }
}

function* deleteStrategyConfig(action) {
    const { payload } = action;

    try {
        const res = yield call(deleteStrategyConfigApi, payload);
        if (res.data.status) {
            let response = res.data;
            yield put({ type: strategyConfigActionTypes.DELETE_STRATEGY_CONFIG_SUCCESS, response });
        }
    } catch (error) {
        yield put(({ type: strategyConfigActionTypes.DELETE_STRATEGY_CONFIG_ERROR, error: "Something went wrong!" }));
    }
}

/**
 * Watcher for getting strategy configuration filters
 */
function* watchStrategyConfigFiltersRequest() {
    yield takeEvery(strategyConfigActionTypes.GET_STRATEGY_CONFIG_FILTER_REQUEST, fetchStrategyConfigFilters);
}

/**
 * Watcher for getting strategy configuration table details
 */
function* watchStrategyConfigTableDetailsRequest() {
    yield takeLatest(strategyConfigActionTypes.GET_STRATEGY_CONFIG_TABLE_REQUEST, fetchStrategyConfigTableData);
}

/**
 * Watcher for getting strategy configuration data
 */
function* watchStrategyConfigDataRequest() {
    yield takeLatest(strategyConfigActionTypes.GET_STRATEGY_CONFIG_DATA_REQUEST, fetchStrategyConfigData);
}

/**
 * Watcher for saving strategy configuration data
 */
function* watchStrategyConfigSaveRequest() {
    yield takeLatest(strategyConfigActionTypes.SAVE_STRATEGY_CONFIG_DATA_REQUEST, postStrategyConfigData);
}

/**
 * Watcher for saving strategy configuration data
 */
function* watchStrategyConfigDeleteRequest() {
    yield takeLatest(strategyConfigActionTypes.DELETE_STRATEGY_CONFIG, deleteStrategyConfig);
}

/**
 * Root saga
 */
export function* strategyConfigSagas() {
    yield all([
        watchStrategyConfigFiltersRequest(),
        watchStrategyConfigTableDetailsRequest(),
        watchStrategyConfigDataRequest(),
        watchStrategyConfigSaveRequest(),
        watchStrategyConfigDeleteRequest(),
    ]);
}