import React, { useEffect, useState } from "react"
import Modal from "react-awesome-modal";
import PageLoader from "../../../../components/Loader/PageLoader";
import Notification from "../../../../components/Notification/Notifications";
import DemandConstraintsTable from "./DemandConstraintsTable"
import * as Notify from "../../../../components/Notification/Notifications";
import Filters from "./Filters"


const AddProducts = (props) => {
    const { resetAddProductsData, getAddProducts, data, dataLoading, dataError, closeModal,
        showModal, addProducts, store, sisterStore, dcSelected } = props

    const [RTInstance, setRTInstance] = useState({})

    const handleAddProducts = () => {
        if (RTInstance?.getSelectedRows?.length) {
            addProducts(RTInstance.data, RTInstance.getSelectedRows)
        }
        else {
            Notify.error(`Please select atleast 1 product!!`)
        }
    }

    const applyFilters = (filters) => {
        getAddProducts({
            "store_id": store,
            "sister_store_id": sisterStore,
            "inventory_source": { "dc": dcSelected.map(item => item.dc) },
            ...filters
        })
    }

    useEffect(() => {
        return () => {
            resetAddProductsData()
        }
    }, [])

    return (
        <div className="modal__div">
            <Modal
                visible={showModal}
                width="80%"
                effect="fadeInDown"
                onClickAway={closeModal}
            >
                <Notification />
                <div className="modal-container">
                    <div className="modal-header">
                        <h5 className="page-heading text-center m-0">
                            Add Products
                        </h5>
                        <button type="button" className="close" data-dismiss="modal">
                            <span style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-times text-right m-2 "
                                    aria-hidden="true"
                                    onClick={closeModal}
                                />
                            </span>
                        </button>
                    </div>
                    <div className="modal-middle-container modal-body">
                        <Filters handleFilterRequest={applyFilters} />

                        <PageLoader loader={dataLoading} gridLoader={true}>

                            {(dataError) ? (<div className="error">{dataError}</div>) :
                                (data ? (
                                    <div className="mx-2 p-2">
                                        <DemandConstraintsTable data={data ? data : []}
                                            isAddProduct={true} setRTInstance={setRTInstance} />
                                    </div>
                                ) : null)}
                        </PageLoader>

                    </div>

                    <div className="text-center" style={{ padding: "1rem" }}>
                        <div className="center">
                            <button
                                className="btn btn-primary store-grp-modal__btn"
                                onClick={closeModal}
                            >
                                Close
                            </button>

                            <button
                                className="btn btn-primary fn__btn"
                                onClick={handleAddProducts}
                            >
                                Add Products

                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}


export default AddProducts