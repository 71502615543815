import React, { useEffect, useState } from "react";
import "../Finalize.css"
import { connect } from "react-redux";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import { getPastAllocationPO, getStoreData, getStoreDetails, getStoreProductDetails } from '../FinalizeAction'
import PageLoader from '../../../components/Loader/PageLoader';
import ProductListTable from '../ProductView/ProductListTable'
import ProductDetailsTable from '../ProductView/ProductDetailsTable'
import ColumnFilter from "../../UserManagement/ColumnFilter";
import { oneDecimal, twoDecimal } from "../../../utils/formatters/valueFormatters";
import { updateArticleName } from "../../../utils/filterLevelMapping"
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import DateInput from "../../../components/Table/Cellrenderer/DateInput"
import SetAllModal from "../../../components/ErrorModal/SetAllModal";
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import DatePicker from "../../../components/DatePicker/DatePicker";
import moment from "moment"
import { cloneDeep } from "lodash";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);


const StoreView = (props) => {
    const [selectedStore, setStore] = useState(null)
    const [RTInstance, setRTinstance] = useState({})
    const [showSetAll, setShowSetAll] = useState(false)
    const [dcSA, setDcSA] = useState(null);
    const [storeData, setStoreData] = useState([])
    const [dateSA, setDateSA] = useState(null)
    const [dcSAReq, setDcSAReq] = useState(null)
    const [keyRT, setKeyRT] = useState("")

    const setTableInstance = (instance) => {
        props.setTableInstance(instance);
    }

    useEffect(() => {
        if (props.viewAllocationCall) {
            setKeyRT("sortAndSearch")
        }
        else {
            setKeyRT("storeView")
        }

    }, [props.viewAllocationCall])

    useEffect(() => {
        setStoreData(props?.storeData)
    }, [props?.storeData])

    useEffect(() => {
        if(props.activeTab == "fsv" && props.isPOASNFlow){
            let req = {
                allocationcode: props.allocationCode
            }
            if (props.isPOASNFlow) {
                req["article"] = props.articles
            }
            props.getStoreData(req)
        }
        if (props.activeTab == "fsv" && !(props.viewAllocationCall && storeData.length) && !props.isPOASNFlow) {
            let req = {
                allocationcode: props.allocationCode
            }
            if (props.viewAllocationCall) {
                req["page"] = "viewPastAllocation"
                if (props.releasedDate) {
                    req["released_date"] = props.releasedDate
                }
                // props.getPastAllocationPO({ allocationcode: props.allocationCode, articles:props.rowArticles})
            }
            props.getStoreData(req)
        }
    }, [props.activeTab,props.isPOASNFlow])

    const getStoreDetails = (isStore, store) => {
        if (isStore) {
            setStore(store)
        }
        let req = { allocationcode: props.allocationCode, store: isStore ? store : selectedStore }
        if(props.isPOASNFlow) {
            req['article'] = props.articles
        }
        if (props.viewAllocationCall) {
            req["page"] = "viewPastAllocation"
        }
        if (props.releasedDate) {
            req["released_date"] = props.releasedDate
        }
        props.getStoreDetails(req)
    }

    const getStoreProductDetails = (isSetInstance, instance) => {

        let req = {
            allocationcode: props.allocationCode,
            article: isSetInstance ? instance.article : RTInstance.article,
            store: selectedStore
        }
        if (props.viewAllocationCall) {
            req["page"] = "viewPastAllocation"
        }
        if (props.releasedDate) {
            req["released_date"] = props.releasedDate
        }
        props.getStoreProductDetails(req)
        if (isSetInstance) {
            setRTinstance({
                allocationcode: props.allocationCode,
                store: selectedStore,
                article: instance.article,
                color: instance.color
            })
        }
    }


    const storeDatacolumns = React.useMemo(() => [
        {
            Header: ' ',
            sticky: "left",
            columns: [{
                Header: 'Store ID',
                accessor: 'store',
                Cell: (instance) => (
                    <div>
                        <div className="clickable" onClick={() => getStoreDetails(true, instance.row?.original?.store)}>{instance.row?.original?.store}</div>
                    </div>
                ),
                width: 200,
                Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
                filter: 'bulkFilterSpaceSeperated',
            }]
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [{
                Header: "Store Name",
                accessor: "store_name",
                Filter: ColumnFilter
            }]
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: "Store Grade",
                    accessor: "store_grade",
                    Filter: ColumnFilter,
                    filter: 'exactText',
                }
            ],
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: "Climate",
                    accessor: "climate",
                    Filter: ColumnFilter,
                }
            ],
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [{
                Header: '# Style Color',
                accessor: 'articles',
                Filter: ColumnFilter
            }]
        },
        {
            Header: 'Original APS',
            accessor: row => row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Actual APS',
            accessor: row => row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'OH',
            accessor: 'oh',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'On Order',
            accessor: 'oo',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'In Transit',
            accessor: 'it',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Min Constraint',
            accessor: 'min',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "Allocated Total Quantity",
            columns: [
                {
                    Header: '# Packs Allocated',
                    accessor: 'packs_allocated',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: '# Eaches Allocated',
                    accessor: 'loose_units_allocated',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: 'Total Allocated Units',
                    accessor: 'allocated_total',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    width: 200
                }
            ]
        },
        {
            Header: 'Target WOS',
            accessor: row => row.target_wos ? twoDecimal(row.target_wos) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Forecasted WOS',
            accessor: row => row.actual_wos ? twoDecimal(row.actual_wos) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "Shipping Dates",
            columns: []
        }
    ])


    const storeProductDatacolumns = React.useMemo(() => [
        {
            Header: " ",
            sticky: "left",
            columns: [{
                Header: `${updateArticleName(false)} ID`,
                accessor: 'article',
                Cell: (instance) => (
                    <div>
                        <div className="clickable" onClick={() => getStoreProductDetails(true, instance.row?.original)}>{instance.row?.original?.article}</div>
                    </div>
                ),
                Filter: ColumnFilter,
            },
            {
                Header: 'Description',
                accessor: 'description',
                width: 200,
                Filter: ColumnFilter,
            },
            {
                Header: 'Style Color',
                accessor: 'color_desc',
                Filter: ColumnFilter,
            },
            ]
        },
        {
            Header: 'Min Constraint',
            accessor: 'min',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "Allocated Total Quantity",
            columns: [
                {
                    Header: 'Pack ID',
                    accessor: 'packs',
                    Filter: ColumnFilter
                },
                {
                    Header: '# Packs Allocated',
                    accessor: 'packs_allocated',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: '# Eaches Allocated',
                    accessor: 'loose_units_allocated',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: 'Total Allocated Units',
                    accessor: 'allocated_quantity',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                    width: 200
                }
            ]
        },
        {
            Header: 'DC Available',
            accessor: 'dc_available',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Original APS',
            accessor: row => row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Forecasted APS',
            accessor: row => row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Target WOS',
            accessor: row => row.target_wos ? twoDecimal(Number(row.target_wos)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Forecasted WOS',
            accessor: row => row.actual_wos ? twoDecimal(Number(row.actual_wos)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Size',
            columns: []
        },
    ])

    const allocationDataColumns = React.useMemo(() => [
        {
            Header: '# Style Color',
            accessor: "style_color",
        },
        {
            Header: '# Allocated Stores',
            accessor: "store_cnt"
        },
        {
            Header: 'StyleColor depth per store',
            accessor: "style_color_depth_per_store",
            width: 200
        },
        {
            Header: 'Allocated Quantity',
            accessor: "allocated_units_total"
        },
        {
            Header: 'DC Available',
            accessor: "inventory_available"
        },
        {
            Header: 'Store Grade - Allocation Qty',
            columns: [],
        },
        {
            Header: 'Store Grade - Allocation Index',
            columns: [],
        }
    ])

    const setProductListSizeColumns = () => {
        // let columns = props.columns ? props.columns : productDatacolumns
        if (props.storeDetails.sizes.length) {
            props.storeDetails.sizes.forEach((s, index) => {
                storeProductDatacolumns[8].columns.push({
                    Header: s,
                    accessor: row => row.size_value[index] ? Number(row.size_value[index]).toFixed(0) : 0,
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                })
            })
            // setTableColumns(columns)
            return true
        }
        return false
    }

    const callAllStoreApis = () => {
        props.getStoreData({ allocationcode: props.allocationCode })
        getStoreDetails(false)
        getStoreProductDetails(false)
    }

    const setDateColumns = () => {
        if (storeData.length) {
            let dc_names = storeData[0].dc_names
            dc_names.map((dc, index) => {
                storeDatacolumns[14].columns.push({
                    Header: `DC - ${dc}`,
                    // accessor: `DC - ${dc}`,
                    Cell: (instance) => props.viewAllocationCall ? <>{instance.row.original?.[`DC - ${dc}`]}</>
                        : <DateInput {...instance}
                            value={instance.row.original?.[`DC - ${dc}`]
                                // || instance.row.original.dates[index]
                            }
                            dateFormat={"MM-DD-YYYY"}
                            isDisabled={props.isFinalized} />,
                    width: 200,
                    disableFilters: true
                })
            })
            return true
        }
        return false
    }

    const onDcSAChange = (value, event, that) => {
        let valuesArray = handleSelectAll(value, event, that)
        setDcSA(valuesArray[1]);
        setDcSAReq(valuesArray[0]);
    }

    const getIntersectionOptions = () => {
        let prepOptions = []
        if (storeData.length) {
            storeData[0].dc_names.forEach((val, indx) => {
                prepOptions.push({
                    label: `DC - ${val}`,
                    value: storeData[0].dc_codes[indx]
                })
            })
        }
        return prepOptions;
    }

    const prepInput = React.useMemo(() => [
        {
            id: 1,
            isRequired: false,
            label: "DC",
            name: "dc",
            className: "setAll__filter",
            dropdownValue: dcSA,
            onDropDownChange: onDcSAChange,
            placeHolder: `Select DC`,
            optionsArray: getIntersectionOptions(),
            isMultiSelect: true,
        },
        {
            id: 1,
            isRequired: false,
            label: "Calendar",
            name: "calendar",
            className: "setAll__filter",
            input: true,
            Comp: <DatePicker onChange={(val) => setDateSA(moment(val).format("MM/DD/YYYY"))} dateFormat="MM/DD/yyyy" />
        },
    ], [dcSA, props.tableInstance?.state?.selectedRowIds])

    const handleSetAllOk = () => {
        let setAllRows = Object.keys(props.tableInstance.state.selectedRowIds)
        if (dcSAReq?.length && dateSA) {
            setStoreData((oldData) => {
                let newData = cloneDeep(oldData);
                setAllRows.forEach(setAllRow => {
                    dcSAReq.forEach(dcReq => {
                        newData[setAllRow][dcReq.label] = dateSA
                    })
                })
                return newData;
            });
        }
    }

    const handleSetAllCancel = () => {
        setShowSetAll(false);
    }

    const handleClose = () => {
        showSetAll && setShowSetAll(false);
    }

    const setAllHandler = (p_instance) => {
        if (Object.keys(p_instance.state.selectedRowIds).length > 0) {
            setShowSetAll(true);
            setDcSA(null)
            setDcSAReq(null)
            setDateSA(null)
        }
        else {
            Notify.error(`Please select atleast 1 store to use Set All functionality!!`)
        }
    }

    const prepareStoreGradeColumns = () => {
        let data = props.storeAllocationData[0]
        console.log(data)
        data?.store_grades?.map((sg, index) => {
            allocationDataColumns[5].columns.push({
                Header: sg + " ",
                accessor: row => row.store_allocated_qty[index],
                width: data?.store_grades?.length == 1 ? 200 : 100
            })
            allocationDataColumns[6].columns.push({
                Header: sg,
                accessor: row => row.store_allocated_index[index],
                width: data?.store_grades?.length == 1 ? 200 : 100
            })
        })
    }

    return (

        <div className="store__view__tables">
            <Notification />
            {showSetAll && !props.viewAllocationCall && <SetAllModal
                modalBodyStyle={{ height: '33rem' }}
                heading="Set Dates"
                filter={prepInput} handleOk={handleSetAllOk} handleCancel={handleSetAllCancel} handleClose={() => handleClose()} />}
            <div className="f__table">
                <PageLoader loader={props.storeDataLoading} gridLoader={true}>
                    <div className="storesStore__heading">
                        <h1 className="fnt-md fnt-bold">Allocation Summary</h1>
                    </div>
                    {prepareStoreGradeColumns()}
                    {(props.storeDataError) ? (<div className="error">{props.storeDataError}</div>) :
                        (props.storeAllocationData ? (
                            <ReactTableWithPlugins
                                data={(props.storeAllocationData ? props.storeAllocationData : [])}
                                columns={allocationDataColumns}
                                renderMarkup='TableMarkup'
                                tableId="finalize_store_view"
                            />
                        ) : null)}
                </PageLoader>
            </div>
            <div className="f__table" >

                {/* <PageLoader loader={props.storeDataLoading} gridLoader={true}> */}
                {setDateColumns()}
                {(props.storeDataError) ? (<div className="error">{props.storeDataError}</div>) :
                    (storeData.length ? (
                        <>
                            <div className="storesStore__heading">
                                <h1 className="fnt-md fnt-bold">Stores Details</h1>
                            </div>
                            <ReactTableWithPlugins
                                tableWrapperStyle={{ height: 'fit-content', maxHeight: '60rem' }}
                                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: 4 }}
                                // shouldPagination
                                totalRecordsLen={storeData ? storeData.length : 0}
                                data={
                                    // props.tableInstance?.data.length ? props.tableInstance?.data : 
                                    (storeData ? storeData : [])}
                                sortBy={[{ id: 'store', desc: false }]}
                                columns={storeDatacolumns}
                                features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                                renderMarkup='TableMarkup'
                                keyRT={keyRT}
                                getInstanceOnMount={(instance) => {
                                    setTableInstance(instance)
                                }}
                                tableId="finalize_store_details"
                                pageSize={storeData.length}
                            />
                        </>
                    ) : null)}
                {/* </PageLoader> */}
                {!props.viewAllocationCall && !props.isFinalized &&
                <div div style={{ textAlign: "center", margin: '2rem' }}>
                    <button
                        style={{ margin: '2px 1rem' }}
                        className="btn btn-primary"
                        onClick={() => setAllHandler(props.tableInstance)}
                    >
                        Set Dates
                    </button>
                </div>}
            </div>
            <hr />
            <div className="store__products__table f__table">
                <PageLoader loader={props.storeDetailsDataLoading && !props.storeDataLoading} gridLoader={true}>
                    {(props.storeDetailsDataError) ? (<div className="error">{props.storeDetailsDataError}</div>) :
                        (props.storeDetails.data.length ? (
                            setProductListSizeColumns() &&
                            <>
                                <div className="storesStore__heading">
                                    <h1 className="fnt-md fnt-bold">{selectedStore}</h1>
                                    <h1 className="fnt-md fnt-bold">Articles</h1>
                                </div>
                                <ProductListTable data={props.storeDetails.data} sizes={props.storeDetails.sizes}
                                    columns={storeProductDatacolumns} />
                            </>
                        ) : null)}
                </PageLoader>
            </div>

            <div className="f__table">
                <PageLoader loader={props.storeProductDetailsLoading && !props.storeDetailsDataLoading} gridLoader={true}>
                    {(props.storeProductDetailsError) ? (<div className="error">{props.storeProductDetailsError}</div>) :
                        (props.storeProductDetails.data.length ? (
                            <ProductDetailsTable
                                isFinalized={props.isFinalized}
                                viewAllocationCall={props.viewAllocationCall}
                                releasedDate={props.releasedDate}
                                callAllApis={callAllStoreApis}
                                data={props.storeProductDetails.data}
                                selectedRow={RTInstance}
                                getProductDetails={getStoreProductDetails}
                                sizes={props.storeProductDetails.sizes}
                                productDetails = {props.storeProductDetails} 
                                allocationcode={props.allocationCode}   
                                originalAllocationCode={props.originalAllocationCode}  
                            />
                        ) : null)}
                </PageLoader>
            </div>

        </div>
    )
}

const mapStateToProps = ({ finalize }) => {
    return {
        storeDataLoading: finalize.storeDataLoading,
        storeData: finalize.storeData,
        storeDataError: finalize.storeDataError,
        storeDetailsDataLoading: finalize.storeDetailsDataLoading,
        storeDetails: finalize.storeDetailsData,
        storeDetailsDataError: finalize.storeDetailsDataError,
        storeProductDetailsLoading: finalize.storeProductDetailsLoading,
        storeProductDetails: finalize.storeProductDetails,
        storeProductDetailsError: finalize.storeProductDetailsError,
        storeAllocationData: finalize.storeAllocationData
    }
}

const mapDispatchToProps = (dispatch) => ({
    getStoreData: (payload) => dispatch(getStoreData(payload)),
    getStoreDetails: (payload) => dispatch(getStoreDetails(payload)),
    getStoreProductDetails: (payload) => dispatch(getStoreProductDetails(payload)),
    getPastAllocationPO: (payload) => dispatch(getPastAllocationPO(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StoreView)