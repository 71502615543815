import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { intersectionWith, isEqual } from 'lodash';
import { handleSelectAll } from "../../../../utils/SelectAllHandler/SelectAllHander";
import MultiSelect from "../../../../components/Filters/MultiSelect";
import Select from "../../../../components/Filters/SingleSelect";
import Notification from "../../../../components/Notification/Notifications";
import * as Notify from "../../../../components/Notification/Notifications";
import { fetchFilterData, resetAll, resetFiltersOptions } from '../../../ProductStoreStatus/ProductStatus/Actions/ProductStatusAction'
import { levelFiltersLabelFormatter } from "../../../../utils/filterLevelMapping";
import { getUserPreferenceFilter, setUserPreferenceFilter } from "../../../../utils/commonUtilities";

/**
 * View Component with Ada Stand Alone Filters
 */
const ExcessFilters = React.memo((props) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [subCat, setSubCat] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [style, setStyle] = useState(null);
    const [color, setColor] = useState(null);
    const [request, setRequest] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [subCatOptions, setSubCatOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [styleOptions, setStyleOptions] = useState(null);
    const [colorOptions, setColorOptions] = useState(null);

    useEffect(() => {
        if (props.selectedFilters?.l1_name) {
            setDepartment(props.selectedFilters.l1_name)
        }
        if (props.selectedFilters?.l2_name) {
            setGender(props.selectedFilters?.l2_name)
        }
        if (props.selectedFilters?.l3_name) {
            setSubCat(props.selectedFilters?.l3_name)
        }
        if (props.selectedFilters?.l4_name) {
            setDCS(props.selectedFilters?.l4_name)
        }
        if (props.selectedFilters?.style) {
            setStyle(props.selectedFilters?.style)
        }
        if (props.selectedFilters?.color) {
            setColor(props.selectedFilters?.color)
        }
    }, [props.selectedFilters])


    useEffect(() => {
        props.fetchFilterData({}, "department");
    }, []);

    useEffect(() => {
        setDepartmentOptions(() => props.departmentOptions);
        setGenderOptions(() => props.genderOptions);
        setSubCatOptions(() => props.subCatOptions);
        setDCSOptions(() => props.dcsOptions);
        setStyleOptions(() => props.styleOptions);
        setColorOptions(() => props.colorOptions);
    }, [props.departmentOptions, props.genderOptions, props.subCatOptions, props.dcsOptions,
    props.styleOptions, props.colorOptions]);

    const resetRestFilters = (p_index) => {
        if (p_index == 0) {
            gender && setGender(null);
            genderOptions && setGenderOptions(null);
        }
        if (p_index == 0 || p_index == 1) {
            subCat && setSubCat(null);
            subCatOptions && setSubCatOptions(null);
        }
        if (p_index == 0 || p_index == 1 || p_index == 2) {
            dcs && setDCS(null);
            dcsOptions && setDCSOptions(null);
        }

        if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3) {
            style && setStyle(null);
            styleOptions && setStyleOptions(null);
        }

        if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4) {
            color && setColor(null);
            colorOptions && setColorOptions(null);
        }

    };

    const onDepartmentChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], departmentOptions, isEqual);
            setDepartment(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level1': valuesArray[0],
                }
            });
            let level1 = req_val.map(dep => dep.value)
            props.fetchFilterData({ level1 }, "gender");
            props.fetchFilterData({ level1 }, "style");
            props.fetchFilterData({ level1 }, "color");
        }
        else {
            setDepartment([val]);
            props.fetchFilterData({ level1: [val.value] }, "gender");
            props.fetchFilterData({ level1: [val.value] }, "style");
            props.fetchFilterData({ level1: [val.value] }, "color");

        }
        resetRestFilters(0);
    };

    const onGenderChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
            setGender(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level2': valuesArray[0],
                }
            });
            let level1 = department.map(dep => dep.value)
            let level2 = req_val.map(dep => dep.value)
            props.fetchFilterData({ level1, level2 }, "sub_category");
            props.fetchFilterData({ level1, level2 }, "style");
            props.fetchFilterData({ level1, level2 }, "color");

        }
        else {
            setGender(val);
            props.fetchFilterData({ level1: [department], level2: [val] }, "sub_category");
            props.fetchFilterData({ level1: [department], level2: [val] }, "style");
            props.fetchFilterData({ level1: [department], level2: [val] }, "color");

        }
        resetRestFilters(1);
    };

    const onSubCatChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], subCatOptions, isEqual);
            setSubCat(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level3': valuesArray[0],
                }
            });
            let level1 = department.map(dep => dep.value)
            let level2 = gender.map(dep => dep.value)
            let level3 = req_val.map(dep => dep.value)
            props.fetchFilterData({ level1, level2, level3 }, "dcs");
            props.fetchFilterData({ level1, level2, level3 }, "style");
            props.fetchFilterData({ level1, level2, level3 }, "color");

        }
        else {
            setSubCat(val);
            props.fetchFilterData({ level1: [department], level2: [...gender], level3: [val] }, "dcs");
            props.fetchFilterData({ level1: [department], level2: [...gender], level3: [val] }, "style");
            props.fetchFilterData({ level1: [department], level2: [...gender], level3: [val] }, "color");

        }
        resetRestFilters(2);
    };

    const onDCSChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
            setDCS(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level4': valuesArray[0],
                }
            });
            let level1 = department.map(dep => dep.value)
            let level2 = gender.map(dep => dep.value)
            let level3 = subCat.map(dep => dep.value)
            let level4 = req_val.map(dep => dep.value)

            props.fetchFilterData({ level1, level2, level3, level4 }, "style");
            props.fetchFilterData({ level1, level2, level3, level4 }, "color");
        }
        else {
            setDCS(val);
            props.fetchFilterData(
                { level1: [department], level2: gender, level3: subCat, level4: [val] }, "style"
            );
            props.fetchFilterData(
                { level1: [department], level2: gender, level3: subCat, level4: [val] }, "color"
            );
        }
        resetRestFilters(3);
    };

    const onStyleChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], styleOptions, isEqual);
            setStyle(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'style': valuesArray[0],
                }
            });
            let level1 = department.map(dep => dep.value)
            let level2 = gender.map(dep => dep.value)
            let level3 = subCat.map(dep => dep.value)
            let level4 = dcs.map(dep => dep.value)
            let style = req_val.map(dep => dep.value)

            props.fetchFilterData({ level1, level2, level3, level4, style }, "color");
        }
        else {
            setStyle(val);
            props.fetchFilterData(
                { level1: [department], level2: [gender], level3: [subCat], level4: [dcs], style: [val] }, "color"
            );
        }
        resetRestFilters(4);
    };

    const onColorChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], colorOptions, isEqual);
            setColor(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'color': valuesArray[0],
                }
            });

            // props.fetchFilterData({
            //     level1: [department], level2: gender, level3: subCat,
            //     level4: dcs, style: style, color: req_val,
            // }, "article");
        }
        else {
            setColor(val);
            // props.fetchFilterData(
            //     {
            //         level1: [department], level2: [gender], level3: [subCat], level4: [dcs],
            //         style: [style], color: [val],
            //     },
            //     "article"
            // );
        }
        resetRestFilters(5);
    };

    const applyFilters = () => {
        if (department) {
            props.handleFilterRequest({
                l1_name: department && Array.isArray(department) ? department : [department],
                l2_name: gender,
                l3_name: subCat,
                l4_name: dcs,
                style,
                color
            });
            // setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department });
        } else {
            Notify.error("Please Select All Mandatory Options!!");
        }
    };

    const resetFilters = () => {
        setRequest(null)
        setDepartment(null);
        setGender(null);
        setSubCat(null);
        setDCS(null);
        setStyle(null);
        setColor(null);

        props.resetFiltersOptions([]);
    };

    useEffect(() => {
        getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
    }, []);

    const getDDLabel = (label) => label ? label : ""
    return (
        <>
            <Notification />

            <section className="row section">
                <div className="row" style={{ flexDirection: "column", marginTop: "0" }}>
                    <div className="row">
                        <div className="col-md-3 col-md-3-dd required">
                            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1 || levelFiltersLabelFormatter("level1")}</label>
                            <MultiSelect
                                name="department"
                                dropdownValue={department}
                                onDropDownChange={onDepartmentChange}
                                placeHolder={`Select ${props.filterLabels?.level2 || levelFiltersLabelFormatter("level1")}`}
                                optionsArray={departmentOptions?.length ? departmentOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{props.filterLabels?.level2 || levelFiltersLabelFormatter("level2")}</label>
                            <MultiSelect
                                name="gender"
                                dropdownValue={gender}
                                onDropDownChange={onGenderChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level2)}`}
                                optionsArray={genderOptions?.length ? genderOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{props.filterLabels?.level3 || levelFiltersLabelFormatter("level4")}</label>
                            <MultiSelect
                                name="subCat"
                                dropdownValue={subCat}
                                onDropDownChange={onSubCatChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level3)}`}
                                optionsArray={subCatOptions?.length ? subCatOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{props.filterLabels?.level4 || levelFiltersLabelFormatter("level3")}</label>
                            <MultiSelect
                                name="dcs"
                                dropdownValue={dcs}
                                onDropDownChange={onDCSChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level4)}`}
                                optionsArray={dcsOptions?.length ? dcsOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">Style</label>
                            <MultiSelect
                                name="style"
                                dropdownValue={style}
                                onDropDownChange={onStyleChange}
                                placeHolder={'Select Style'}
                                optionsArray={styleOptions?.length ? styleOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd mt-3">
                            <label className="fnt-md fnt-bold">Color</label>
                            <MultiSelect
                                name="dcs"
                                dropdownValue={color}
                                onDropDownChange={onColorChange}
                                placeHolder={'Select Color'}
                                optionsArray={colorOptions?.length ? colorOptions : []}
                            />
                        </div>

                        <div style={{ marginTop: "10px" }}>
                            <button
                                onClick={applyFilters}
                                className="btn btn-primary filter-constraints-mode"
                                title="Apply filters"
                            >
                                <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                            </button>
                            <button
                                onClick={() => resetFilters()}
                                className="btn undo-constraints-mode"
                                title="Reset filters"
                            >
                                <i className="fa fa-undo mr-2"></i>Reset
                            </button>
                        </div>
                        <div className="col-md-3 required fnt-md pt-5" style={{ color: "red" }}>
                            <label></label>Fields are mandatory
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
);

const mapStateToProps = ({ productStatus }) => ({
    departmentOptions: productStatus.departmentOptions,
    genderOptions: productStatus.genderOptions,
    subCatOptions: productStatus.rbuOptions,
    dcsOptions: productStatus.dcsOptions,
    filterLabels: productStatus.filterlabels,
    styleOptions: productStatus.styleOptions,
    colorOptions: productStatus.colorOptions,
});

const mapDispatchToProps = (dispatch) => ({
    resetAll: () => dispatch(resetAll()),
    resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
    fetchFilterData: (payload, filterKey, filterType) =>
        dispatch(fetchFilterData(payload, filterKey, filterType))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExcessFilters);