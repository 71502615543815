import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { DEMAND_HEADER } from "./DemandConstants"
import DemandConstraintsTable from "./DemandConstraintsTable"
import PageLoader from "../../../../components/Loader/PageLoader";
import { getDemandData, getAddProductsData, resetAddProductsData, setSelectedDemandTableRows } from "../../actions/DemandAction/DemandAction"
import AddProducts from "./AddProducts"
import Filters from "./Filters"
import { cloneDeep } from "lodash";

let demandSelectedRows = []
let sisterStore = ""
let store = ""
let selectedFilters = {}

const DemandConstraints = (props) => {
    const { demandData, demandDataLoading, getDemandData, getAddProductsData, demandDataError,
        setDemandSelectedRows, addProductsData, addProductsDataLoading, setIsAddProduct,
        selectedSisterStore, selectedNewStore, setIsMaxError, addProductsDataError,
        selectedDemandRows, resetAddProductsData, allocation_id, store_code,
        sister_store_code, dcSelected, setSelectedDemandTableRows, loading } = props

    // if (store_code) {
    //     store = store_code
    // }
    // if (sister_store_code) {
    //     sisterStore = sister_store_code
    // }

    const [showAddProducts, setShowAddProducts] = useState(false)
    const [tableData, setTableData] = useState([])
    const [addTableData, setAddTableData] = useState([])
    const [capacity_ap, setApCapacity] = useState(null)
    const [capacity_acc, setAccCapacity] = useState(null)
    const [capacity_ft, setFtCapacity] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [isStoreChanged, setIsStoreChanged] = useState(selectedSisterStore != sisterStore || selectedNewStore != store)

    useEffect(() => {
        if ((selectedSisterStore != sisterStore || selectedNewStore != store) || !demandData?.length) {
            demandSelectedRows = []
            selectedFilters = {}
            setTableData([])
            sisterStore = selectedSisterStore
            store = selectedNewStore
            if (allocation_id && sisterStore == sister_store_code && store == store_code && !demandData.length) {
                setIsEditMode(true)

                getDemandData({
                    allocation_id,
                    "store_id": store,
                    "sister_store_id": sisterStore,
                    "inventory_source": { "dc": dcSelected.map(item => item.dc) },
                    "l1_name": [{ value: "Accessories" }, { value: "Apparal" }, { value: "Footwear" }]
                })
                setIsStoreChanged(false)
            }
        }

    }, [selectedNewStore, selectedSisterStore])

    useEffect(() => {
        if (!isStoreChanged) {
            let data = cloneDeep(demandData)
            let selectedIds = []
            demandSelectedRows = cloneDeep(selectedDemandRows)
            demandSelectedRows?.map(row => selectedIds.push(row?.article))
            let remainingRows = []
            data.map(item => {
                if (item.selected && !(selectedIds.includes(item.article)) && !selectedDemandRows.length) {
                    selectedIds.push(item.article)
                    demandSelectedRows.push(item)
                }
                else if (!(selectedIds.includes(item.article))) {
                    remainingRows.push(item)
                }
            })
            let allData = []
            demandSelectedRows.map(row => allData.push(row))
            allData = allData.concat(remainingRows)
            setTableData(allData?.length ? allData : [])
            if (isEditMode) {
                setSelectedDemandTableRows(demandSelectedRows)
            }
            // }
            // else {
            //     demandSelectedRows = cloneDeep(selectedDemandRows)
            //     setTableData(demandData)
            // }
        }
    }, [demandData])

    const setSelectedRows = (data, selectedRows) => {
        if (data?.length) {
            setIsMaxError("")
            let rows = []
            for (let i = 0; i < selectedRows.length; i++) {
                rows.push(data[Number(selectedRows[i])])
            }
            maxCheck(rows)
            demandSelectedRows = rows
            setDemandSelectedRows(rows)
        }
    }

    const maxCheck = (selectedRows) => {
        let apSum = 0, accSum = 0, ftSum = 0

        selectedRows.forEach(row => {
            if (row?.l1_name === "Accessories") {
                accSum += Number(row?.demand_estimated)
            }
            else if (row?.l1_name === "Footwear") {
                ftSum += Number(row?.demand_estimated)
            }
            else {
                apSum += Number(row?.demand_estimated)
            }
        });
        setAccCapacity(accSum)
        setApCapacity(apSum)
        setFtCapacity(ftSum)

        let errors = []
        if (accSum > Number(selectedNewStore?.ac_capacity)) {
            errors.push(<li style={{ marginBottom: "5px" }}>Accessories: Capacity - {selectedNewStore?.ac_capacity}&nbsp;&nbsp;&nbsp;&nbsp;Demand Estimated - {accSum}</li>)
        }
        if (apSum > Number(selectedNewStore?.ap_capacity)) {
            errors.push(<li style={{ marginBottom: "5px" }}>Apparal: Capacity - {selectedNewStore?.ap_capacity}&nbsp;&nbsp;&nbsp;&nbsp;Demand Estimated - {apSum}</li>)
        }
        if (ftSum > Number(selectedNewStore?.ft_capacity)) {
            errors.push(<li style={{ marginBottom: "5px" }}>Footwear: Capacity - {selectedNewStore?.ft_capacity}&nbsp;&nbsp;&nbsp;&nbsp;Demand Estimated - {ftSum}</li>)
        }
        if (errors.length) {
            errors = [<h4>Demand Estimated Units crossing the store capacity for below department(s)</h4>, <h4 style={{ margin: '5px 0 1.5rem' }}>Please Adjust to Continue</h4>, ...errors]
            setIsMaxError(errors)
        }
    }

    const addProductsClickHandler = () => {
        setIsAddProduct(true)
        setShowAddProducts(true)
    }

    const addProducts = (allData, selectedProducts) => {
        setIsAddProduct(false)
        setShowAddProducts(false)
        let selectedIds = demandSelectedRows.map(row => row?.article)
        let remainingRows = []
        tableData.map(item => {
            if (!(selectedIds.includes(item.article))) {
                remainingRows.push(item)
            }
        })
        let data = []
        let selectedRows = []
        demandSelectedRows.map(row => data.push(row))
        for (let i = 0; i < selectedProducts.length; i++) {
            selectedRows.push(allData[Number(selectedProducts[i])])
        }
        data = data.concat(selectedRows)
        data = data.concat(remainingRows)
        demandSelectedRows = demandSelectedRows.concat(selectedRows)
        setSelectedDemandTableRows(demandSelectedRows)
        setTableData(data)
    }

    const closeAddProductsModal = () => {
        setIsAddProduct(false)
        setShowAddProducts(false)
    }

    useEffect(() => {
        let existingProducts = tableData?.map(item => item.article)
        let addData = []
        addProductsData?.map(item => {
            if (!existingProducts.includes(item.article)) {
                addData.push(item)
            }
        })
        setAddTableData(addData)
    }, [addProductsData])

    const applyFilters = (filters) => {
        selectedFilters = filters
        if (!isEditMode) {
            demandSelectedRows = []
            setSelectedDemandTableRows([])
        }
        else {
            setSelectedDemandTableRows(demandSelectedRows)
        }
        getDemandData({
            "store_id": store,
            "sister_store_id": sisterStore,
            "inventory_source": { "dc": dcSelected.map(item => item.dc) },
            ...filters
        })
        setIsStoreChanged(false)
    }


    useEffect(() => {
        return () => {
            setSelectedDemandTableRows(demandSelectedRows)
            // demandSelectedRows = []
        }
    }, [])

    return (
        <div>
            <section className="section mb-4">
                <div className="container__header m-0">
                    <h3 className="fnt-bold">{DEMAND_HEADER}</h3>
                </div>
                <hr />

                <Filters selectedFilters={selectedFilters} handleFilterRequest={applyFilters} />
            </section>

            <section className="section mr-4 mt-4">
                {
                    !!tableData.length &&
                    <div className="row add-pro-btn config-btn-container mr-2" onClick={addProductsClickHandler}>
                        <button className="btn-primary-rounded create-config-btn mr-2">
                            <i
                                className="fa fa-plus"
                                title="Create Allocation"
                                aria-hidden="true"
                            ></i>
                        </button>
                        <p className="fnt-sm m-0">Add Products</p>
                    </div>
                }
                <PageLoader loader={demandDataLoading || loading} gridLoader={true}>
                    {(demandDataError) ? (<div className="error">{demandDataError}</div>) :
                        (tableData ? (
                            <DemandConstraintsTable
                                demandSelectedRows={demandSelectedRows} store={sisterStore}
                                data={tableData} setSelectedRows={setSelectedRows} />
                        ) : null)}
                </PageLoader>
            </section>
            {
                showAddProducts &&
                <AddProducts getAddProducts={getAddProductsData} data={addTableData}
                    dataLoading={addProductsDataLoading} dataError={addProductsDataError}
                    addProducts={addProducts} showModal={showAddProducts}
                    store={store} sisterStore={sisterStore} dcSelected={dcSelected}
                    resetAddProductsData={resetAddProductsData} closeModal={closeAddProductsModal} />
            }
        </div>
    )
}

const mapStateToProps = (newStoreSetup) => {
    const { demandConstraints, newStoreDetailConfig } = newStoreSetup
    return {
        demandDataLoading: demandConstraints.demandDataLoading,
        demandData: demandConstraints.demandData,
        demandDataError: demandConstraints.demandDataError,
        addProductsDataLoading: demandConstraints.addProductsDataLoading,
        addProductsData: demandConstraints.addProductsData,
        addProductsDataError: demandConstraints.addProductsDataError,
        selectedSisterStore: newStoreDetailConfig?.selectedSisterStore[0]?.store_code,
        selectedNewStore: newStoreDetailConfig?.selectedNewStore[0]?.store_code,
        dcSelected: newStoreDetailConfig?.dcSourceSelectedRowsData,
        selectedDemandRows: demandConstraints.selectedDemandRows
    }
}

const mapDispatchToProps = (dispatch) => ({
    getDemandData: (payload) => dispatch(getDemandData(payload)),
    getAddProductsData: (payload) => dispatch(getAddProductsData(payload)),
    setSelectedDemandTableRows: (payload) => dispatch(setSelectedDemandTableRows(payload)),
    resetAddProductsData: () => dispatch(resetAddProductsData())
})

export default connect(mapStateToProps, mapDispatchToProps)(DemandConstraints)