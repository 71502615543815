import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DriversTableView from './DriversTableView';
import PageLoader from "../../components/Loader/PageLoader";
import { DRIVERS_ROW_HEADERS, NETWORK_ERROR_MSG } from './AdaVisualConstants';
import * as adaVisualActions from './actions/AdaVisualActions';
import { isEmpty } from 'lodash';

/**
 * Forecast Drivers data View component
 * @param {adaDriversData, startFiscalYearWeek, endFiscalYearWeek} props 
 * @returns 
 */
const ForecastDriversView = (props) => {
  const { adaVisualActions, adaDriversData, startFiscalYearWeek, endFiscalYearWeek,
    adaAllocationReqBody, adaStandAloneReqBody, isAdaAllocationModeSelected, isAdaLoadingSuccess,
    isDriversDataLoading, isDriversDataFailed } = props;

  useEffect(() => {
    if (isAdaLoadingSuccess) {
      if (isAdaAllocationModeSelected) {
        const { adaReq } = adaAllocationReqBody;
        const driversReqBody = {
          driversReq: {
            start_date: startFiscalYearWeek ? startFiscalYearWeek : adaReq.current_fw,
            end_date: endFiscalYearWeek ? endFiscalYearWeek : adaReq.current_fw,
            articles: {
              ...adaReq?.articles,
            },
          },
        };
        adaVisualActions.getAdaDriversData(driversReqBody);
      } else {
        const { current_fw, level1, level2, level3, level4, articles } = adaStandAloneReqBody;

        const driversReqBody = {
          driversReq: {
            start_date: startFiscalYearWeek ? startFiscalYearWeek : current_fw,
            end_date: endFiscalYearWeek ? endFiscalYearWeek : current_fw,
            level1: [...level1],
            level2: [...level2],
            level3: level3 ? [...level3] : null,
            level4: level4 ? [...level4] : null,
            article: [...articles],
          },
        };
        adaVisualActions.getAdaDriversData(driversReqBody);
      };
    }
  }, [isAdaLoadingSuccess]);

  let content = !isEmpty(adaDriversData) ? <DriversTableView impData={adaDriversData.AdaData.ia_forecast_drivers_importance_data[0]}
    driverData={adaDriversData.AdaData.ia_forecast_drivers_weeks_data}
    header={DRIVERS_ROW_HEADERS} keys={Object.keys(DRIVERS_ROW_HEADERS)} /> : null;

  return (
    <>
      {isDriversDataFailed
        ? <div className="error">{NETWORK_ERROR_MSG}</div>
        :
        <PageLoader loader={isDriversDataLoading}>
          {content}
        </PageLoader>
      }
    </>
  );
}

const mapStateToProps = ({ adaVisual }) => ({
  adaDriversData: adaVisual.adaDriversData,
  startFiscalYearWeek: adaVisual.startFiscalYearWeek,
  endFiscalYearWeek: adaVisual.endFiscalYearWeek,
  adaAllocationReqBody: adaVisual.adaAllocationReqBody,
  isAdaAllocationModeSelected: adaVisual.isAdaAllocationModeSelected,
  adaStandAloneReqBody: adaVisual.adaStandAloneReqBody,
  isAdaLoadingSuccess: adaVisual.isAdaLoadingSuccess,
  isDriversDataLoading: adaVisual.isDriversDataLoading,
  isDriversDataFailed: adaVisual.isDriversDataFailed,
});

const mapDispatchToProps = (dispatch) => ({
  adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForecastDriversView);