import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Calendar } from "primereact/calendar";
import { Tab, Tabs } from "react-bootstrap";
import LineChart from "../../components/LineChart/LineChart";
import BarChartHorizontal from "../../components/BarchartHorizontal/BarchartHorizontal";
import ForecastMultiplierModalView from "./ForecastMultiplierModalView";
import * as adaVisualActions from './actions/AdaVisualActions';
import * as AdaConst from './AdaVisualConstants';
import moment from "moment";
import "./AdaVisual.css";

/**
 * Sales View Component with Sales, Drivers and Forecast Adjustment section
 * @param {*} props 
 * @returns 
 */
const SalesView = (props) => {
    const [showForecastDialog, setShowForecastDialog] = useState(false);
    const [datesValue, setDatesValue] = useState(null);
    const [tabSelectionKey, setTabSelectionKey] = useState(AdaConst.ADA_DATA_WEEKLY);
    const [disableDaysList, setDisableDaysList] = useState([0, 2, 3, 4, 5, 6]);

    const { adaGraphData, adaAllocationReqBody, adaVisualActions, isAdaAllocationModeSelected,
        adaStandAloneReqBody, startDateStamp, endDateStamp, adaVisualData } = props;

    if (adaGraphData) {
        AdaConst.ADA_LINE_CHART_PARAMS.labels = [...adaGraphData.labels_data];
        AdaConst.ADA_LINE_CHART_PARAMS.datasets[0].data = [...adaGraphData.actual_data];
        AdaConst.ADA_LINE_CHART_PARAMS.datasets[1].data = [...adaGraphData.ia_data];
        AdaConst.ADA_LINE_CHART_PARAMS.datasets[2].data = [...adaGraphData.adjusted_data];
        AdaConst.ADA_BAR_CHART_PARAMS.labels = [...adaGraphData.labels_data];
        AdaConst.ADA_BAR_CHART_PARAMS.datasets[0].data = [...adaGraphData.actual_data];
        AdaConst.ADA_BAR_CHART_PARAMS.datasets[1].data = [...adaGraphData.ia_data];
        AdaConst.ADA_BAR_CHART_PARAMS.datasets[2].data = [...adaGraphData.adjusted_data];
    }

    function handleDateChange(e) {
        setDatesValue(e.value);
        if (!e.value[1]) {
            let tempList = [1, 2, 3, 4, 5, 6];
            setDisableDaysList(tempList);
        } else if (e.value[1]) {
            let tempList = [0, 2, 3, 4, 5, 6];
            setDisableDaysList(tempList);
        } else {
            //No code here
        }
    }

    useEffect(() => {
        if (datesValue && datesValue[0] && datesValue[1]) {
            adaVisualActions.setAdaStartEndDate({
                start_date: moment(datesValue[0]).format("YYYYWW"),
                end_date: moment(datesValue[1]).format("YYYYWW"),
                start_stamp: datesValue[0],
                end_stamp: datesValue[1],
            });

            let req = {};
            if (isAdaAllocationModeSelected) {
                req = {
                    adaReq: {
                        start_fw: moment(datesValue[0]).format("YYYYWW"),
                        end_fw: moment(datesValue[1]).format("YYYYWW"),
                        current_fw: adaAllocationReqBody.adaReq.current_fw,
                        articles: {
                            ...adaAllocationReqBody.adaReq.articles,
                        },
                    }
                };
                adaVisualActions.getAdaAllocationData(req);
            } else {
                req = {
                    start_fw: moment(datesValue[0]).format("YYYYWW"),
                    end_fw: moment(datesValue[1]).format("YYYYWW"),
                    current_fw: adaStandAloneReqBody?.current_fw,
                    level1: [...adaStandAloneReqBody?.level1],
                    level2: [...adaStandAloneReqBody?.level2],
                    level3: adaStandAloneReqBody.level3 ? [...adaStandAloneReqBody?.level3] : null,
                    level4: adaStandAloneReqBody.level4 ? [...adaStandAloneReqBody?.level4] : null,
                    article: adaStandAloneReqBody.articles ? [...adaStandAloneReqBody?.articles] : null,
                };
                adaVisualActions.getAdaStandAloneData(req);
            }
        } else {
            //No code here
        }
    }, [datesValue]);

    return (
        <div id="SALES_DATA_CONTAINER" className="sales_data_container">
            <div className="ada__tabs">
                <Calendar
                    id="DATE_RANGE"
                    value={datesValue}
                    onChange={(e) => handleDateChange(e)}
                    selectionMode="range"
                    style={{ height: "35px" }}
                    disabledDays={disableDaysList}
                    readOnlyInput
                    showIcon
                />
                <Tabs activeKey={tabSelectionKey} className="calendar-tab" onSelect={(key) => {
                    adaVisualActions.setAdaDataTimeConstraint(key);
                    setTabSelectionKey(key);
                }}>
                    <Tab eventKey={AdaConst.ADA_DATA_WEEKLY} title={AdaConst.GRAPH_WEEK} />
                    <Tab eventKey={AdaConst.ADA_DATA_MONTHLY} title={AdaConst.GRAPH_MONTH} disabled={true} />
                    <Tab eventKey={AdaConst.ADA_DATA_QUARTERLY} title={AdaConst.GRAPH_QUARTER} disabled={true} />
                </Tabs>
                <hr />
                <div>
                    <div style={{ margin: "5% 1% 0% 0%" }}>
                        {adaVisualData.length === 0
                            ?
                            <div className="empty">{AdaConst.EMPTY_FORECAST_MSG}
                                <p>{AdaConst.EMPTY_DATA_HELPER_MSG}</p>
                            </div>
                            :
                            <Tabs defaultActiveKey="lineChart" className="graph-types-tab">
                                <Tab eventKey="lineChart" title="Line">
                                    <h2 className="line-chart__heading">{AdaConst.SALES_DISPLAY_HEADER}</h2>
                                    <LineChart data={AdaConst.ADA_LINE_CHART_PARAMS} height="6" width="20"
                                        showForecastDialog={setShowForecastDialog} />
                                </Tab>
                                <Tab eventKey="barChart" title="Bar">
                                    <h2 className="line-chart__heading">{AdaConst.SALES_DISPLAY_HEADER}</h2>
                                    <BarChartHorizontal chartType="bar" data={AdaConst.ADA_BAR_CHART_PARAMS} height="6" width="20" text="" />

                                </Tab>
                                {/* <Tab eventKey="pieChart" title="Pie">
                            </Tab> */}
                            </Tabs>
                        }
                    </div>
                    {adaVisualData.length !== 0 ?
                        <div className="forecast-button" hidden={!adaGraphData}>
                            <button className="btn btn-primary" style={{ width: "15vw" }}
                                disabled={!startDateStamp && !endDateStamp}
                                onClick={() => setShowForecastDialog(true)}>
                                {AdaConst.FORECAST_MULTIPLIER}
                            </button>
                        </div>
                        : null}
                </div>
            </div>
            {showForecastDialog ?
                (<ForecastMultiplierModalView
                    showAlert={true} setShowForecastDialog={setShowForecastDialog} />)
                : null}
        </div>
    );
}

const mapStateToProps = ({ adaVisual }) => ({
    adaGraphData: adaVisual.adaGraphData,
    adaAllocationReqBody: adaVisual.adaAllocationReqBody,
    isAdaAllocationModeSelected: adaVisual.isAdaAllocationModeSelected,
    adaStandAloneReqBody: adaVisual.adaStandAloneReqBody,
    startDateStamp: adaVisual.startDateStamp,
    endDateStamp: adaVisual.endDateStamp,
    adaVisualData: adaVisual.adaVisualData,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesView);